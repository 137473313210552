import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { KIND, Notification } from "baseui/notification"
import { getErrorMessage } from "../helpers/errors"

interface Props {
	messageOrPayload?: any
	message?: string
	closeable?: boolean
	kind?: KIND[keyof KIND]
}

export const ErrorNotification = ({ message, messageOrPayload, closeable, kind }: Props) => {
	const errorMessage = React.useMemo(() => {
		return message || getErrorMessage(messageOrPayload)
	}, [message, messageOrPayload])

	const [css] = useStyletron()
	const iconStyle = css({
		marginRight: "5px",
	})

	return (
		<Notification
			closeable={closeable}
			kind={kind || "negative"}
			overrides={{
				Body: {
					style: {
						width: "100%",
						display: "flex",
					},
				},
				InnerContainer: {
					style: {
						whiteSpace: "pre-line",
					},
				},
			}}
		>
			<FontAwesomeIcon icon={["fas", "exclamation-circle"]} className={iconStyle} />
			<>{errorMessage}</>
		</Notification>
	)
}
