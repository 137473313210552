import * as React from "react"
import { Value } from "baseui/select"
import { useQuery } from "react-fetching-library"
import { Divider, SearchAndFilter, ZenCard } from "../../components/common"
import { fetching } from "../../fetching"
import { ClientRelatedNote } from "../../types/types"
import { useStyletron } from "baseui"
import { UserLabel } from "../../components/callLogs/subComponents"
import { LabelSmall } from "baseui/typography"
import { DisplaySessionNoteJSONcontent, RevisionListModal } from "../../components/sessions/sessionNoteComponent"
import { TooltipButton } from "../../components/tooltipButton"
import { useHistory } from "react-router-dom"
import { routes } from "../../routes"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { ErrorNotification } from "../../components/errorBox"

enum RelatedNoteFilter {
	All = "All",
	Session = "Session",
	CallLog = "CallLog",
	Client = "Client",
}

export const ClientNotebook = (props: { clientID: string }) => {
	const { clientID } = props
	const [css, theme] = useStyletron()
	const history = useHistory()
	const searchArgs = new URLSearchParams(history.location.search)
	const [rows, setRows] = React.useState<ClientRelatedNote[]>([])
	const [total, setTotal] = React.useState<number>(0)

	const [search, setSearch] = React.useState(searchArgs.get("search") || "")

	let defFilter = searchArgs.get("filter") || RelatedNoteFilter.All
	const index: number = Object.keys(RelatedNoteFilter).indexOf(defFilter)
	if (index === -1) {
		defFilter = RelatedNoteFilter.All
	}

	const [filter, setFilter] = React.useState<Value>([{ label: defFilter, id: defFilter }])

	let defaultOffset = parseInt(searchArgs.get("offset") || "0")
	if (isNaN(defaultOffset)) defaultOffset = 0
	const [offset, setOffset] = React.useState(defaultOffset || 0)

	const limit = 20
	const clientRelatedNotes = useQuery(
		fetching.query.getClientRelatedNotesMany({
			search: {
				search,
				filterBy: filter[0].id?.toString(),
			},
			limit,
			offset,
			clientID,
		}),
	)

	React.useEffect(() => {
		let changed = false
		if (`${offset}` !== searchArgs.get("offset")) {
			searchArgs.set("offset", `${offset}`)
			changed = true
		}
		if (`${search}` !== searchArgs.get("search")) {
			if (`${search}` === "null" || search === "") {
				searchArgs.delete("search")
			} else {
				searchArgs.set("search", `${search}`)
				searchArgs.delete("offset")
				setOffset(0)
			}
			changed = true
		}
		if (filter.length > 0 && searchArgs.get("filter") !== filter[0].id) {
			searchArgs.set("filter", `${filter[0].id}`)
			searchArgs.delete("offset")
			setOffset(0)
		}
		if (changed) {
			history.push({ pathname: history.location.pathname, hash: "#notebook", search: searchArgs.toString() })
		}
	}, [filter, offset, search]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (clientRelatedNotes.loading || clientRelatedNotes.error || !clientRelatedNotes.payload) return
		setRows(clientRelatedNotes.payload.relatedNotes)
		setTotal(clientRelatedNotes.payload.total)
	}, [clientRelatedNotes.payload, clientRelatedNotes.loading, clientRelatedNotes.error])

	const [showHistoryNotes, setShowHistoryNotes] = React.useState<string>("")

	const container = css({
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const noteListStyle = css({
		flex: "unset !important",
		height: "100%",
		minHeight: 0,
	})

	const noteList = css({
		marginBottom: "12px",
		padding: "12px",
		fontSize: "14px",
		display: "flex",
		flexDirection: "column",
		borderRadius: "3px",
		backgroundColor: theme.colors.backgroundSecondary,
	})
	const headerStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",
	})

	const dateStyle = css({
		...theme.typography.LabelSmall,
		color: theme.colors.contentTertiary,
		marginLeft: "4px",
	})

	const group = css({
		display: "flex",
		alignItems: "center",
	})

	const notesContainer = css({
		height: "100%",
		maxHeight: "100%",
		overflow: "auto",
		paddingRight: "8px",
	})
	return (
		<div className={container}>
			<ZenCard className={noteListStyle}>
				<SearchAndFilter
					search={search}
					setSearch={setSearch}
					filter={filter}
					setFilter={setFilter}
					filterOptions={Object.values(RelatedNoteFilter).map((rnf) => ({ id: rnf, label: rnf }))}
				/>
				{clientRelatedNotes.error && <ErrorNotification messageOrPayload={clientRelatedNotes.payload} />}
				<Divider />
				<div className={notesContainer}>
					{rows.map((r, i) => (
						<div className={noteList} key={i}>
							<div className={headerStyle}>
								<div className={group}>
									<UserLabel data={r.poster} />
									<LabelSmall marginLeft="3px">{`- ${r.noteTypeLabel} Note`}</LabelSmall>
								</div>
								<div className={group}>
									<div className={group}>
										<TooltipButton
											onClick={(e) => {
												e.stopPropagation()
												setShowHistoryNotes(r.id)
											}}
											tooltip="view entire note and its history"
											iconName="search-plus"
										/>
										<TooltipButton
											onClick={(e) => {
												e.stopPropagation()
												switch (r.sourceName) {
													case RelatedNoteFilter.Session:
														history.push(`${routes.sessions.root}/${r.sourceID}`)
														break
													case RelatedNoteFilter.Client:
														history.push(`${routes.clients.root}/${r.sourceID}/notes`)
														break
													case RelatedNoteFilter.CallLog:
														history.push(`${routes.callLogs.root}/${r.sourceID}`)
														break
												}
											}}
											tooltip="go to the page"
											iconName="sign-in"
										/>
									</div>

									<div className={dateStyle}>{new Date(r.revisionNotes[r.revisionNotes.length - 1].createdAt).toLocaleDateString()}</div>
								</div>
							</div>
							<DisplaySessionNoteJSONcontent contentJSON={r.revisionNotes[0].contentJSON} contentText={r.revisionNotes[0].content} padding="4px" />
							<RevisionListModal isOpen={showHistoryNotes === r.id} setIsOpen={() => setShowHistoryNotes("")} revisions={r.revisionNotes} />
						</div>
					))}
				</div>
				<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			</ZenCard>
		</div>
	)
}
