import * as React from "react"
import { useEffect } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"

import { ItemMask, NodeFNProps } from ".."
import { Alerts } from "../../../components/admin/dashboard/alerts"
import { Loading } from "../../../components/loading"
import { AssignedClients } from "../../../components/workers/assignedClient"
import { Supervisors } from "../../../components/workers/supervisors"
import { WorkerSession } from "../../../components/workers/workerSession"
import { WorkerTravel } from "../../../components/workers/workerTravel"
import { Claims } from "../../workers/claims"
import { getLayouts } from "../layout"
import * as layout from "./layout"

const ResponsiveGridLayout = WidthProvider(Responsive)

export const MemberDashboard = ({
	editing,
	onLayoutChange,
	currentUser,
	setLayouts,
	itemCls,
	breakpoint,
	onWidthChange,
	onBreakpointChange,
	layouts,
}: NodeFNProps) => {
	const skey = layout.storageKey(breakpoint)

	useEffect(() => {
		setLayouts(getLayouts(skey, editing, layout.layouts, layout.panels))
	}, [editing, breakpoint, skey, setLayouts])

	if (!layouts) return <Loading />

	return (
		<ResponsiveGridLayout
			onLayoutChange={onLayoutChange(skey, breakpoint)}
			onWidthChange={onWidthChange}
			onBreakpointChange={onBreakpointChange}
			className="layout"
			useCSSTransforms={false}
			layouts={layouts}
			rowHeight={30}
		>
			<div className={itemCls} key={"worker-session"}>
				{editing && <ItemMask />}
				<WorkerSession userId={currentUser.id} />
			</div>
			<div className={itemCls} key={"claims"}>
				{editing && <ItemMask />}
				<Claims workerID={currentUser.id} />
			</div>
			<div className={itemCls} key={"supervisors"}>
				{editing && <ItemMask />}
				<Supervisors />
			</div>
			<div className={itemCls} key={"assigned"}>
				{editing && <ItemMask />}
				<AssignedClients {...currentUser} />
			</div>
			<div className={itemCls} key={"worker-travel"}>
				{editing && <ItemMask />}
				<WorkerTravel {...currentUser} />
			</div>
			<div className={itemCls} key={"alerts"}>
				{editing && <ItemMask />}
				<Alerts />
			</div>
		</ResponsiveGridLayout>
	)
}
