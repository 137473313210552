import * as React from "react"
import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"
import { CancelAndSaveButtons } from "../../components/cancelSaveButtons"
import { AvailableCheck, ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { useZenToast } from "../../components/zenComponents/useZenToast"
import { ZenTextArea } from "../../components/zenComponents/zenInput"
import { ZenSelect, ZenTimezoneSelect, ZenUserSelect } from "../../components/zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../../components/zenComponents/zenTime"
import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { useCheckWorkerAvailability } from "../../helpers/useCheckWorkerAvailability"
import { combineDateTime, setTimezoneToTime } from "../../helpers/utils"
import { RolePermission } from "../../types/types"
import { routes } from "routes"

interface FormFields {
	worker: Value
	date: Date
	startTime: string
	endTime: string
	supportType: Value
	subSupportType?: Value
	subSubSupportType?: Value
	note: string
}

export const CreateNonBillable = () => {
	const [css] = useStyletron()
	const timeRangeContainer = css({
		marginTop: "10px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	})
	const timeInput = css({
		width: "49%",
	})

	const scrollingDiv = css({
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
		paddingRight: "8px",
	})

	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
	})

	const history = useHistory()
	const { showToast } = useZenToast()
	const { handleSubmit, control, errors, setValue, watch, formState } = useForm()
	const { hasPermission } = AuthContainer.useContainer()
	const supportTypeQuery = useQuery(fetching.query.getNonBillableSupportTypes())
	const subSupportTypeGet = useParameterizedQuery(fetching.query.getNonBillableSubSupportTypes)
	const subSubSupportTypeGet = useParameterizedQuery(fetching.query.getNonBillableSubSubSupportTypes)
	const createSession = useMutation(fetching.mutation.sessionNonBillableCreate)
	const { currentUser } = AuthContainer.useContainer()

	const watchDate = watch("date")
	const watchTimezone = watch("timezone")
	const watchStartTime = watch("startTime")
	const watchEndTime = watch("endTime")
	const watchWorker = watch("worker")

	const [subSupportTypeOptions, setSubSupportTypeOptions] = React.useState<Option[]>([])
	const [subSubSupportTypeOptions, setSubSubSupportTypeOptions] = React.useState<Option[]>([])

	const { check: checkWorkerAvailability, state: workerAvailabilityState } = useCheckWorkerAvailability({ timezone: watchTimezone })

	// check date/time is available when related values change
	React.useEffect(() => {
		// Wait for a time frame to be set
		if (!watchWorker || watchWorker.length < 1 || !watchStartTime || !watchEndTime || !watchTimezone || !watchTimezone.length) return

		// then check for availability
		checkWorkerAvailability({
			workerIDs: [watchWorker[0].id],
			timezone: watchTimezone[0],
			startTime: combineDateTime(watchDate, watchStartTime),
			endTime: combineDateTime(watchDate, watchEndTime),
		})
	}, [watchDate, watchStartTime, watchEndTime, watchWorker, checkWorkerAvailability, watchTimezone])

	React.useEffect(() => {
		// if current user is not loaded
		if (!currentUser) return
		// set the worker field to current user
		setValue("worker", [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }])
	}, [currentUser, setValue])

	const onSubmit = async (data: FormFields) => {
		if (!workerAvailabilityState) return
		const startTime = setTimezoneToTime(combineDateTime(data.date, data.startTime).toDate(), watchTimezone[0].id)
		const endTime = setTimezoneToTime(combineDateTime(data.date, data.endTime).toDate(), watchTimezone[0].id)
		const resp = await createSession.mutate({
			workerID: data.worker[0].id as string,
			timezoneID: watchTimezone[0].id,
			startTime: moment(startTime),
			endTime: moment(endTime),
			supportTypeID: data.supportType[0].id as string,
			subSupportTypeID: data.subSupportType && data.subSupportType.length > 0 ? (data.subSupportType[0].id as string) : undefined,
			subSubSupportTypeID: data.subSubSupportType && data.subSubSupportType.length > 0 ? (data.subSubSupportType[0].id as string) : undefined,
			note: data.note || undefined,
		})

		if (resp.payload) {
			control.updateFormState({ isDirty: false })
			showToast("Non Billable Session created successfully.", "positive")
			history.push(routes.sessions.root)
		}
	}

	if (supportTypeQuery.error) {
		return (
			<ZenCard>
				<ErrorNotification messageOrPayload={supportTypeQuery.payload} />
			</ZenCard>
		)
	}

	return (
		<ZenCard style={{ height: "100%" }}>
			<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
				<div className={scrollingDiv}>
					<ZenUserSelect
						label="Worker"
						formName="worker"
						formRef={control}
						inputError={errors.worker}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
							},
						}}
						disabled={!hasPermission(RolePermission.SessionUpdate)}
					/>
					<ZenDatePicker label="Date" formName="date" formRef={control} inputError={errors.date} formRules={{ required: "You must enter a date." }} />
					<ZenTimezoneSelect
						formRef={control}
						formName="timezone"
						label="Timezone"
						clearable={false}
						actionOnChange={(v) => {
							const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
							if (watchStartTime) setValue("startTime", moment(watchStartTime).add(offsetDifferent, "minute"))
							if (watchEndTime) setValue("endTime", moment(watchEndTime).add(offsetDifferent, "minute"))
						}}
					/>
					<div className={timeRangeContainer}>
						<div className={timeInput}>
							<ZenTimePicker
								inputError={errors.startTime}
								label="Start Time"
								formName="startTime"
								date={watch("date")}
								creatable
								nullable
								formRef={control}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "Start time is required"
											}
											if (watchEndTime && moment(value).isSameOrAfter(watchEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
							/>
						</div>
						<div className={timeInput}>
							<ZenTimePicker
								inputError={errors.endTime}
								label="End Time"
								formName="endTime"
								date={watch("date")}
								creatable
								nullable
								formRef={control}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "End time is required"
											}
											if (watchStartTime && moment(value).isSameOrBefore(watchStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
							/>
						</div>
					</div>
					{!!workerAvailabilityState && <AvailableCheck isAvailable={workerAvailabilityState} />}

					<ZenSelect
						inputError={errors.supportType}
						label="Activity Type"
						formName="supportType"
						formRef={control}
						options={supportTypeQuery.payload}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Activity type is required",
							},
						}}
						actionOnChange={(v) => {
							setSubSupportTypeOptions([])
							setValue("subSupportType", [])
							if (v.length === 0) {
								return
							}
							const supportTypeID = v[0].id?.toString() || ""
							subSupportTypeGet.query({ supportTypeID }).then((resp) => {
								if (resp.error || !resp.payload) return
								setSubSupportTypeOptions(resp.payload)
							})
						}}
						/>
					{subSupportTypeOptions.length > 0 && (
						<ZenSelect 
							label="Sub Activity Type" 
							formName="subSupportType" 
							formRef={control} 
							options={subSupportTypeOptions}
							actionOnChange={(v) => {
								setSubSubSupportTypeOptions([])
								setValue("subSubSupportType", [])
								if (v.length === 0) {
									return
								}
								const subSupportTypeID = v[0].id?.toString() || ""
								subSubSupportTypeGet.query({ subSupportTypeID }).then((resp) => {
									if (resp.error || !resp.payload) return
									setSubSubSupportTypeOptions(resp.payload)
								})
							}} />
					)}
					{subSubSupportTypeOptions.length > 0 && (
						<ZenSelect 
							label="Support Type" 
							formName="subSubSupportType" 
							formRef={control} 
							options={subSubSupportTypeOptions} />
					)}

					<ZenTextArea label={"Note"} formRef={control} nameRef="note" fontSize={13} value="" resizable />
				</div>
				{createSession.error && <ErrorNotification messageOrPayload={createSession.payload} />}
				<CancelAndSaveButtons
					cancelLabel="Back"
					cancelFn={() => {
						history.goBack()
					}}
					saveLabel="Submit"
				/>
			</form>
			<Prompt when={formState.isDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
		</ZenCard>
	)
}
