import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge } from "baseui/typography"
import { FormControl, FormControlOverrides } from "baseui/form-control"
import { Controller, Control, FieldError, useWatch } from "react-hook-form"
import { HazardRiskIdentificationFormData } from "./hazardRiskIdentification"
import { Textarea } from "baseui/textarea"
import { ZenInputResizableOverrides, ZenInputBottomOverrides, ZenDatepickerOverrides } from "../../../themeOverrides"
import { RiskCriteria, RiskCriteriaAcronym } from "../../../types/enums"
import { Input } from "baseui/input"
import { DatePicker } from "baseui/datepicker"
import { DeepMap } from "react-hook-form/dist/types/utils"
import { FormValuePreview, YesNoSelection } from "../common"

interface HazardRiskStep3Props {
	control: Control<HazardRiskIdentificationFormData>
	errors: DeepMap<HazardRiskIdentificationFormData, FieldError>
	disabled?: boolean
}

const required = { required: { value: true, message: "Required" } }

const HazardRiskStep3 = (props: HazardRiskStep3Props) => {
	const { control, errors, disabled } = props

	const sqCommitteeNotified = useWatch({
		control,
		name: "assessment.sqCommitteeNotified",
		defaultValue: false,
	})
	const riskRegisterToBeUpdated = useWatch({
		control,
		name: "assessment.riskRegisterToBeUpdated",
		defaultValue: false,
	})

	const [css, theme] = useStyletron()
	const containerStyle = css({
		paddingRight: "20px",
	})
	const labelStyle = css({
		fontWeight: 600,
	})
	const helpStyle = css({
		color: "#BEBEBE",
		marginLeft: "7px",
	})
	const titleStyle = css({
		fontWeight: "bold",
		marginBottom: "20px",
	})
	const tableStyle = css({
		width: "100%",
		borderCollapse: "collapse",
		tableLayout: "fixed",
		fontSize: "14px",
		whiteSpace: "nowrap",
		marginBottom: "12px",
		minWidth: "715px",
	})
	const tableHeaderRowStyle = css({
		height: "28px",
		verticalAlign: "top",
		textAlign: "left",
		borderBottom: theme.colors.backgroundSecondary + " solid 2px",
	})
	const thStyle = css({
		color: "#747675",
		fontWeight: 600,
		paddingLeft: "10px",
	})
	const th1Style = css({
		width: "35%",
		fontWeight: 600,
		paddingLeft: "20px",
	})
	const bottomFormStyle = css({
		display: "grid",
		gridTemplateColumns: "48% 48%",
		columnGap: "4%",
		maxWidth: "500px",
		marginLeft: "2px",
	})

	return (
		<div className={containerStyle}>
			<LabelLarge className={titleStyle}>Level of Risk to be Completed by Executive Corporate Services</LabelLarge>

			<table className={tableStyle}>
				<tbody>
					<tr className={tableHeaderRowStyle}>
						<th className={th1Style}>Risk Criteria</th>
						<th className={thStyle}>Consequence 1-5</th>
						<th className={thStyle}>Likelihood 1-5</th>
						<th className={thStyle}>Level of Risk</th>
						<th className={thStyle}>Risk Rank</th>
					</tr>
					{Object.values(RiskCriteria).map((c, i) => (
						<Row key={`risk-criteria-row-${c}`} criteria={c as RiskCriteria} index={i} control={control} disabled={disabled} errors={errors} />
					))}
				</tbody>
			</table>

			<LabelLarge className={titleStyle}>Action Plans (TAP)</LabelLarge>

			<FormControl
				label={
					<span>
						<span className={labelStyle}>Proposed Action</span>
						<span className={helpStyle}>S.M.A.R.T. Specific, Measurable, Achievable, Relevant & Time-lined</span>
					</span>
				}
				error={errors.assessment?.proposedAction && errors.assessment.proposedAction.message}
			>
				<Controller
					name={`assessment.proposedAction`}
					control={control}
					as={disabled ? undefined : Textarea}
					render={FormValuePreview(disabled)}
					placeholder={!disabled ? "Enter Description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					defaultValue=""
					error={errors.assessment?.proposedAction !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl
				label={
					<span>
						<span className={labelStyle}>Resource Requirements</span>
						<span className={helpStyle}>People, time, consumables, expenses, policy, documents, commissioning, training etc. (existing or additional)</span>
					</span>
				}
				error={errors.assessment?.resourceRequirements && errors.assessment.resourceRequirements.message}
			>
				<Controller
					name={`assessment.resourceRequirements`}
					control={control}
					as={disabled ? undefined : Textarea}
					render={FormValuePreview(disabled)}
					placeholder={!disabled ? "Enter Description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					defaultValue=""
					error={errors.assessment?.resourceRequirements !== undefined}
					rules={required}
				/>
			</FormControl>

			<div className={bottomFormStyle}>
				<div>
					<FormControl label="Implementation completion date" overrides={ControlOverrides}>
						<Controller
							name={`assessment.implementationCompletionDate`}
							control={control}
							render={({ value, onChange }) => (
								<DatePicker
									onChange={({ date }) => onChange(date)}
									value={value ? new Date(value) : undefined}
									disabled={disabled}
									overrides={ZenDatepickerOverrides}
									formatString={"dd/MM/yyyy"}
									placeholder={"DD/MM/YYYY"}
									minDate={new Date("1900-01-01")}
								/>
							)}
						/>
					</FormControl>
				</div>
				<div>
					<FormControl label="Recommendation due date" overrides={ControlOverrides}>
						<Controller
							name={`assessment.recommendationDueDate`}
							control={control}
							render={({ value, onChange }) => (
								<DatePicker
									onChange={({ date }) => onChange(date)}
									value={value ? new Date(value) : undefined}
									disabled={disabled}
									overrides={ZenDatepickerOverrides}
									formatString={"dd/MM/yyyy"}
									placeholder={"DD/MM/YYYY"}
									minDate={new Date("1900-01-01")}
								/>
							)}
						/>
					</FormControl>
				</div>

				<div>
					<FormControl label="S&Q Committee notified" overrides={ControlOverrides}>
						<YesNoSelection name="assessment.sqCommitteeNotified" control={control} disabled={disabled} />
					</FormControl>
				</div>

				<div>
					{sqCommitteeNotified && (
						<FormControl label="S&Q Committee notified date" overrides={ControlOverrides}>
							<Controller
								name={`assessment.sqCommitteeNotifiedDate`}
								control={control}
								render={({ value, onChange }) => (
									<DatePicker
										onChange={({ date }) => onChange(date)}
										value={value ? new Date(value) : undefined}
										disabled={disabled}
										overrides={ZenDatepickerOverrides}
										formatString={"dd/MM/yyyy"}
										placeholder={"DD/MM/YYYY"}
										minDate={new Date("1900-01-01")}
									/>
								)}
							/>
						</FormControl>
					)}
				</div>

				<div>
					<FormControl label="Risk Register to be updated" overrides={ControlOverrides}>
						<YesNoSelection name="assessment.riskRegisterToBeUpdated" control={control} disabled={disabled} />
					</FormControl>
				</div>
				<div>
					{riskRegisterToBeUpdated && (
						<div>
							<FormControl label="Risk Register updated date" overrides={ControlOverrides}>
								<Controller
									name={`assessment.riskRegisterUpdatedDate`}
									control={control}
									render={({ value, onChange }) => (
										<DatePicker
											onChange={({ date }) => onChange(date)}
											value={value ? new Date(value) : undefined}
											disabled={disabled}
											overrides={ZenDatepickerOverrides}
											formatString={"dd/MM/yyyy"}
											placeholder={"DD/MM/YYYY"}
											minDate={new Date("1900-01-01")}
										/>
									)}
								/>
							</FormControl>{" "}
							<FormControl label="Advise risk number" overrides={ControlOverrides}>
								<Controller name={`assessment.adviseRiskNumber`} control={control} disabled={disabled} as={<Input overrides={InputOverrides} />} />
							</FormControl>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

interface RowProps {
	criteria: RiskCriteria
	index: number
	control: Control<HazardRiskIdentificationFormData>
	disabled?: boolean
	errors: DeepMap<HazardRiskIdentificationFormData, FieldError>
}

const Row = (props: RowProps) => {
	const { criteria, index, control, disabled, errors } = props

	const [css, theme] = useStyletron()
	const rowStyle = css({
		fontWeight: 600,
		backgroundColor: index % 2 === 0 ? "unset" : theme.colors.backgroundSecondary,
		borderRadius: "3px",
		verticalAlign: "middle",
	})
	const headerStyle = css({
		fontWeight: 600,
		textAlign: "left",
	})
	const flexStyle = css({
		display: "flex",
	})
	const acronymStyle = css({
		color: "#FF0000",
		fontWeight: "bold",
		marginLeft: "20px",
		marginRight: "12px",
		width: "24px",
	})
	const inputStyle = css({
		width: "75px",
		marginLeft: "10px",
	})

	return (
		<tr className={rowStyle}>
			<th className={headerStyle}>
				<div className={flexStyle}>
					<div className={acronymStyle}>{RiskCriteriaAcronym(criteria)}</div>
					<div>{criteria}</div>
				</div>
			</th>
			<td>
				<div className={inputStyle}>
					<Controller
						name={`assessment.risks[${index}].consequence`}
						control={control}
						disabled={disabled}
						rules={{
							validate: {
								withInRange: (value: string) => {
									if (value === "") return true
									const degree = parseInt(value)
									if (isNaN(degree) || degree < 1 || degree > 5) return "Please input a integer between 1 and 5"
									return true
								},
							},
						}}
						as={
							<Input
								data-lpignore="true"
								autoComplete="off"
								overrides={InputOverrides}
								placeholder="1-5"
								type="number"
								min={1}
								max={5}
								error={!!errors.assessment?.risks && !!errors.assessment.risks[index]?.consequence}
							/>
						}
					/>
				</div>
			</td>
			<td>
				<div className={inputStyle}>
					<Controller
						name={`assessment.risks[${index}].likelihood`}
						control={control}
						disabled={disabled}
						rules={{
							validate: {
								withInRange: (value: string) => {
									if (value === "") return true
									const degree = parseInt(value)
									if (isNaN(degree) || degree < 1 || degree > 5) return "Please input a integer between 1 and 5"
									return true
								},
							},
						}}
						as={
							<Input
								data-lpignore="true"
								autoComplete="off"
								overrides={InputOverrides}
								placeholder="1-5"
								type="number"
								min={1}
								max={5}
								error={!!errors.assessment?.risks && !!errors.assessment.risks[index]?.likelihood}
							/>
						}
					/>
				</div>
			</td>
			<td>
				<div className={inputStyle}>
					<Controller
						name={`assessment.risks[${index}].levelOfRisk`}
						control={control}
						disabled={disabled}
						as={<Input data-lpignore="true" autoComplete="off" overrides={InputOverrides} placeholder="Level" />}
					/>
				</div>
			</td>
			<td>
				<div className={inputStyle}>
					<Controller
						name={`assessment.risks[${index}].riskRank`}
						control={control}
						disabled={disabled}
						as={<Input data-lpignore="true" autoComplete="off" overrides={InputOverrides} placeholder="Rank" />}
					/>
				</div>
			</td>
		</tr>
	)
}

const InputOverrides = {
	...ZenInputBottomOverrides,
	Input: {
		style: {
			paddingTop: "6px",
			paddingBottom: "6px",
		},
	},
}
const ControlOverrides: FormControlOverrides = {
	ControlContainer: {
		style: {
			marginBottom: "10px",
		},
	},
}

export default HazardRiskStep3
