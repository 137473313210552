import * as React from "react"
import { useStyletron } from "baseui"
import { ModalBody, ModalButton, ModalFooter } from "baseui/modal"
import { StatefulTooltip } from "baseui/tooltip"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import ReactDiffViewer from "react-diff-viewer"
import { useQuery } from "react-fetching-library"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { routes } from "routes"
import { Divider, SearchAndFilter, ZenCard } from "../../components/common"
import { ListTable } from "../../components/listTable"
import { Loading } from "../../components/loading"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { friendlyDate, friendlyTime } from "../../helpers/utils"
import { FilterBy, SortDir } from "../../types/enums"
import { AuditLog, SearchTextMinLength } from "../../types/types"

enum SortBy {
	DateCreated = "DateCreated",
	DateUpdated = "DateUpdated",
	Action = "Action",
	User = "User",
}

export default function AuditLogPage() {
	return (
		<Switch>
			<Route path={routes.auditLogs.root} component={AuditLogList} />
		</Switch>
	)
}

export const AuditLogList = () => {
	const history = useHistory()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const [rows, setRows] = React.useState<AuditLog[]>([])
	const [total, setTotal] = React.useState<number>(0)
	const { timezone } = PortalContainer.useContainer()

	// Get data options
	let defaultOffset = parseInt(searchParams.get("offset") || "0")
	if (isNaN(defaultOffset)) defaultOffset = 0

	const [offset, setOffset] = React.useState(defaultOffset)
	const [search, setSearch] = React.useState(searchParams.get("search") || "")
	const [sortColumn, setSortColumn] = React.useState<string>(searchParams.get("sortColumn") || SortBy.DateCreated)
	const [sortAsc, setSortAsc] = React.useState(searchParams.get("sortAsc") === "true")
	const limit = 20

	// Audit log query
	const { payload, loading } = useQuery<{ auditLogs: AuditLog[]; total: number }>(
		fetching.query.getAuditLogMany({
			search: {
				search: search.length >= SearchTextMinLength ? search : undefined,
				filterBy: FilterBy.All,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortDir.Ascending : SortDir.Descending,
			},
			limit: 20,
			offset,
		}),
	)

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	// Changes modal open / close
	const [changesModalOpen, setChangesModalOpen] = React.useState(false)

	// Selected audit log
	const [selectedRow, setSelectedRow] = React.useState<AuditLog | null>(null)

	React.useEffect(() => {
		let changed = false
		if (`${sortAsc}` !== searchParams.get("sortAsc")) {
			searchParams.set("sortAsc", `${sortAsc}`)
			changed = true
		}
		if (sortColumn !== searchParams.get("sortColumn")) {
			searchParams.set("sortColumn", `${sortColumn}`)
			changed = true
		}

		if (`${offset}` !== searchParams.get("offset")) {
			searchParams.set("offset", `${offset}`)
			changed = true
		}
		if (`${search}` !== searchParams.get("search")) {
			if (search === "") {
				searchParams.delete("search")
			} else {
				searchParams.set("search", `${search}`)
				searchParams.delete("offset")
				setOffset(0)
			}
			changed = true
		}
		if (changed) {
			history.push({ pathname: location.pathname, search: searchParams.toString() })
		}
	}, [sortAsc, offset, sortColumn, search]) // eslint-disable-line react-hooks/exhaustive-deps

	// Set rows
	React.useEffect(() => {
		if (loading || !payload) return
		setRows(payload.auditLogs)
		setTotal(payload.total)
	}, [payload, loading])

	// Styling
	const [css] = useStyletron()

	const containerStyle = css({
		height: "100%",
	})
	const top = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})
	const columnCellStyle = css({
		color: "#343434",
	})

	return (
		<ZenCard className={containerStyle}>
			<div className={top}>
				{/* Search/ filter bar */}
				<SearchAndFilter search={search} setSearch={setSearch} filter={[]} setFilter={() => {}} hideFilter />
			</div>
			<ListTable
				handleSort={handleSort}
				sortColumn={sortColumn}
				isLoading={loading}
				sortAsc={sortAsc}
				rows={rows}
				onRowClick={(row: AuditLog) => {
					setSelectedRow(row)
					setChangesModalOpen(!changesModalOpen)
				}}
				columns={[
					{
						id: SortBy.User,
						header: "Name",
						resolver: (row: AuditLog) => `${row.workers[0].firstName} ${row.workers[0].lastName}`,
						sortable: false,
					},
					{
						id: "",
						header: "Client",
						resolver: (row: AuditLog) => {
							if (!row.clients || row.clients.length === 0) return <></>
							const clientList: string[] = []
							row.clients.forEach((c) => {
								if (!c.firstName && !c.lastName) {
									clientList.push("N/A")
								} else {
									clientList.push(`${c.firstName} ${c.lastName}`)
								}
							})
							return (
								<div className={columnCellStyle}>
									<StatefulTooltip content={() => <div>{clientList.join(", ")}</div>} returnFocus autoFocus>
										<div>{row.clients.length > 1 ? "Multi-Client" : clientList}</div>
									</StatefulTooltip>
								</div>
							)
						},
						sortable: false,
					},
					{
						id: SortBy.Action,
						header: "Action",
						resolver: (row: AuditLog) => row.action,
						sortable: false,
					},
					{
						id: SortBy.DateCreated,
						sortable: true,
						header: "Date/Time",
						resolver: (row: AuditLog) => `${friendlyDate(row.createdAt, timezone)} - ${friendlyTime(timezone, row.createdAt)}`,
					},
				]}
			/>

			{changesModalOpen && selectedRow && <ChangesModal closeModal={() => setChangesModalOpen(false)} isOpen={changesModalOpen} auditLogID={selectedRow.id} />}
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
		</ZenCard>
	)
}

// Changes modal, modal to show more audit log/ user activity details
const ChangesModal = ({ isOpen, closeModal, auditLogID }: { closeModal: () => void; isOpen: boolean; auditLogID: string }) => {
	const [css] = useStyletron()
	const top = css({
		marginTop: "20px",
		marginBottom: "5px",
		minWidth: "200px",
	})
	const bottom = css({
		paddingBottom: "12px",
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
	})
	const diffContainer = css({
		height: "450px",
		minWidth: "1000px",
		overflow: "scroll",
	})
	const { timezone } = PortalContainer.useContainer()
	const [data, setData] = React.useState<AuditLog>()

	const auditLogGet = useQuery(fetching.query.AuditLogGet(auditLogID))
	React.useEffect(() => {
		if (auditLogGet.loading || auditLogGet.error || !auditLogGet.payload) return
		setData(auditLogGet.payload)
	}, [auditLogGet.payload, auditLogGet.error, auditLogGet.loading])

	const content = () => {
		if (!data)
			return (
				<div className={top}>
					<LabelMedium>User Activity</LabelMedium>
					<Divider />
					<Divider style={{ background: "transparent", height: "20px" }} />
					<Loading />
				</div>
			)
		return (
			<div>
				<div className={top}>
					<LabelMedium>User Activity</LabelMedium>
					<Divider />
					<Divider style={{ background: "transparent", height: "20px" }} />

					{/* user / action / time */}
					<div>
						<LabelSmall>User: </LabelSmall> {`${data.workers[0].firstName} ${data.workers[0].lastName} | ${data.workers[0].email}`}
					</div>
					<Divider />
					<div>
						<LabelSmall>Action: </LabelSmall> {data.action}
					</div>
					<Divider />
					{data.clients && data.clients.length > 0 && (
						<>
							<div>
								<LabelSmall>Involved client{data.clients.length > 1 ? "s" : ""}: </LabelSmall>
							</div>
							{data.clients.map((c) => (
								<div>{`${c.firstName} ${c.lastName}`}</div>
							))}
							<Divider />
						</>
					)}
					<div>
						<LabelSmall>Date/Time: </LabelSmall>
						{moment(data.createdAt).tz(timezone.id).format("DD/MM/YY")} | {moment(data.createdAt).tz(timezone.id).format("hh:mm a")}
					</div>
				</div>

				<Divider style={{ background: "transparent", height: "20px" }} />
				{/* Diffs / changes */}
				{(JSON.stringify(data.beforeMeta) !== JSON.stringify({}) || JSON.stringify(data.afterMeta) !== JSON.stringify({})) && (
					<div className={bottom}>
						<div>
							<LabelMedium>Changes</LabelMedium>
							<Divider />
						</div>
						<Divider />
						<div className={diffContainer} style={{}}>
							<ReactDiffViewer
								styles={{ diffContainer: { height: "400px", minWidth: "600px" } }}
								oldValue={jsonPrettyPrint(data.beforeMeta || "")}
								newValue={jsonPrettyPrint(data.afterMeta || "")}
								showDiffOnly={true}
							/>
						</div>
					</div>
				)}

				<ModalFooter>
					<ModalButton onClick={() => closeModal()}>Close</ModalButton>
				</ModalFooter>
			</div>
		)
	}

	// prettify Json
	const jsonPrettyPrint = (jsonData: any) => {
		if (!jsonData) {
			return ""
		}
		return JSON.stringify(jsonData, null, 2)
	}

	return (
		<ZenModal
			onClose={() => closeModal()}
			isOpen={isOpen}
			overrides={{
				Dialog: {
					style: {
						width: "auto",
					},
				},
				Backdrop: {
					style: {
						opacity: 0.2,
					},
				},
			}}
		>
			<ModalBody>{content()}</ModalBody>
		</ZenModal>
	)
}
