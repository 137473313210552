import * as React from "react"
import { IconName } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { BlockProps } from "baseui/block"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"

import { fetching } from "../../../fetching"
import { alignDate, setTimezoneToTime, snakeToTitle } from "../../../helpers/utils"
import { routes } from "../../../routes"
import { ZenTheme } from "../../../themeOverrides"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { Divider } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { useZenToast } from "../../zenComponents/useZenToast"
import { SessionFormData } from "./singleSessionBaseForm"
import { SessionMeetingType } from "./singleSessionGeneralForm"

export const AppointmentConfirmation = (props: SessionFormData) => {
	const { data, pageChange, setIsDirty } = props
	const [css] = useStyletron()
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])
	const { showToast } = useZenToast()
	const [imageLoading, setImageLoading] = React.useState<boolean>(true)
	const { loading, mutate, error, payload } = useMutation(fetching.mutation.sessionSingleCreate)
	const checkLoading = () => {
		setImageLoading(false)
	}
	const [displayedAddress, setDisplayedAddress] = React.useState<string>("")
	const officeWithAddressGet = useParameterizedQuery(fetching.query.getOfficeWithAddress)
	React.useEffect(() => {
		if (data.meetingTypeKey !== SessionMeetingType.InPerson) return
		if (!data.office || data.office.length === 0 || !data.office[0].id || data.office[0].id === "other") return
		officeWithAddressGet.query(data.office[0].id.toString())
	}, [data.office]) // eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		if (data.meetingTypeKey !== SessionMeetingType.InPerson) return
		if (officeWithAddressGet.payload) {
			setDisplayedAddress(officeWithAddressGet.payload.address.fullAddress)
			return
		}
		if (data.sessionLocation && data.sessionLocation[0].label) {
			setDisplayedAddress(data.sessionLocation[0].label?.toString())
			return
		}
	}, [officeWithAddressGet.payload, data.sessionLocation, data.meetingTypeKey])

	const onSubmit = async () => {
		if (!data.sessionFundingSources || data.sessionFundingSources.length === 0) return

		let sessionLocation = data.otherMeetingMethod
		if (data.meetingTypeKey === SessionMeetingType.InPerson && !!data.sessionLocation && data.sessionLocation.length > 0 && !!data.sessionLocation[0].label) {
			sessionLocation = data.sessionLocation[0].label.toString()
		}

		let clientID: string[] | undefined = undefined
		if (data.client && data.client.length > 0) {
			clientID = data.client.map((c) => c.id?.toString() || "")
		}

		let timezoneID = ""
		let startTime = alignDate(data.appointmentDate, data.startTime)
		let endTime = alignDate(data.appointmentDate, data.endTime)
		if (data.timezone && data.timezone.length > 0) {
			startTime = setTimezoneToTime(startTime, data.timezone[0].id)
			endTime = setTimezoneToTime(endTime, data.timezone[0].id)
			timezoneID = data.timezone[0].id
		}

		const resp = await mutate({
			workerID: data.worker && data.worker.length > 0 && data.worker[0].id ? data.worker[0].id.toString() : "",
			clientID: clientID || [],
			timezoneID,
			startTime,
			endTime,
			meetingType: data.meetingTypeKey || "",
			sessionLocation,
			sessionFundingSourcesInput: data.sessionFundingSources.map((sfs) => ({
				fundingSourceID: sfs.fundingSource[0].id,
				contractAreaID: sfs.contractArea && sfs.contractArea.length > 0 ? sfs.contractArea[0].id : undefined,
				supportTypeID: sfs.supportType && sfs.supportType.length > 0 ? sfs.supportType[0].id : undefined,
				subSupportTypeID: sfs.subSupportType && sfs.subSupportType.length > 0 ? sfs.subSupportType[0].id : undefined,
			})),
			notes: data.notes,
			officeID: data.office && data.office.length > 0 && data.office[0].id !== "other" ? data.office[0].id?.toString() : undefined,
			buddySessionWithMentor: data.buddySessionWithMentor,
			buddySessionMentorID: data.buddySessionMentor && data.buddySessionMentor.length > 0 ? data.buddySessionMentor[0].id : undefined,
			clientType: data.clientType && data.clientType.length > 0 ? data.clientType[0].id?.toString() : undefined,
		})

		// return
		if (resp.error) return

		if (resp.payload) {
			showToast("Single Session created successfully.", "positive")
			if (setIsDirty) setIsDirty(false)
			// if it is processed through a modal, close the modal
			if (pageChange) {
				pageChange(1)
				return
			}

			const returnClientID = searchArgs.get("client_id")

			if (returnClientID) {
				// otherwise redirect to session list
				history.push({
					pathname: routes.withID(returnClientID, routes.clients.client.root),
					hash: "sessions",
				})
				return
			}

			// otherwise redirect to session list
			history.push(routes.sessions.root)
		}
	}

	const displayLocation = () => {
		if (!data.meetingTypeKey) return null

		switch (data.meetingTypeKey) {
			case SessionMeetingType.InPerson:
				if (!displayedAddress) return null
				return (
					<>
						{officeWithAddressGet.payload ? (
							<>
								<LabelMedium>{`Office: ${officeWithAddressGet.payload.name}`}</LabelMedium>
								<LabelMedium marginLeft="8px">{officeWithAddressGet.payload.address.fullAddress}</LabelMedium>
							</>
						) : (
							<LabelMedium>{displayedAddress}</LabelMedium>
						)}
						{imageLoading && (
							<div className={imageLoadingContainer}>
								<LabelSmall>Loading image...</LabelSmall>
								<StyledSpinnerNext />
							</div>
						)}
						<img width="100%" alt={`Map of ${displayedAddress}`} src={`/api/maps/static_map?address=${displayedAddress}`} onLoad={checkLoading} />
					</>
				)
			case SessionMeetingType.Online:
			case SessionMeetingType.Telephone:
				return (
					<DisplayLabel
						label={data.meetingTypeKey === SessionMeetingType.Online ? "Link" : "Telephone"}
						icon={data.meetingTypeKey === SessionMeetingType.Online ? "map-marker-alt" : "phone-alt"}
					>
						<LabelMedium>{data.otherMeetingMethod || "N/A"}</LabelMedium>
					</DisplayLabel>
				)
			default:
				return (
					<DisplayLabel label="Other">
						<LabelMedium>{data.otherMeetingMethod || "N/A"}</LabelMedium>
					</DisplayLabel>
				)
		}
	}

	const displayMeetingType = () => {
		switch (data.meetingTypeKey) {
			case SessionMeetingType.InPerson:
			case SessionMeetingType.Online:
			case SessionMeetingType.Telephone:
				return snakeToTitle(data.meetingTypeKey)
			case SessionMeetingType.ServiceProviderOther:
				return "Service Provider / Other"
			default:
				return "N/A"
		}
	}

	const container = css({
		position: loading ? "relative" : "unset",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
		maxWidth: "600px",
		padding: "20px",
		backgroundColor: ZenTheme.colors.lightGrey,
		opacity: loading ? 0.5 : 1,
	})
	const groupContainer = css({
		display: "flex",
	})
	const imageLoadingContainer = css({
		width: "100%",
		height: "150px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		backgroundColor: ZenTheme.colors.primaryGrey,
		transform: "translate(-50%, -50%)",
	})
	const body = css({
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	const scrollDiv = css({
		maxHeight: "100%",
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "8px",
	})

	return (
		<div className={container}>
			{loading && (
				<div className={loadingIcon}>
					<StyledSpinnerNext />
				</div>
			)}
			<LabelLarge>Appointment Confirmation</LabelLarge>
			<div className={body}>
				<div className={scrollDiv}>
					<div className={groupContainer}>
						<DisplayLabel label="Worker" icon="briefcase">
							{data.worker && data.worker.length > 0 && (
								<DisplayPersonCard firstName={data.worker[0].firstName} lastName={data.worker[0].lastName} avatarURL={data.worker[0].avatarURL || ""} />
							)}
						</DisplayLabel>
						<DisplayLabel label="Client" icon="user">
							{data.client &&
								data.client.length > 0 &&
								data.client.map((c) => <DisplayPersonCard key={c.id} firstName={c.firstName} lastName={c.lastName} avatarURL={c.avatarURL || ""} />)}
						</DisplayLabel>
					</div>
					<div className={groupContainer}>
						<div style={{ width: "100%" }} />
						{data.clientType && data.clientType.length > 0 && (
							<DisplayLabel label="Client Type" icon="user">
								<LabelMedium>{data.clientType[0].label}</LabelMedium>
							</DisplayLabel>
						)}
					</div>
					<div className={groupContainer}>
						<DisplayLabel label="Date" icon="calendar-alt">
							{data.appointmentDate && <LabelMedium>{moment(data.appointmentDate).format("DD MMMM YYYY")}</LabelMedium>}
						</DisplayLabel>
						<DisplayLabel label={`Time ${data.timezone && data.timezone.length > 0 ? `(${data.timezone[0].label})` : ""}`} icon="clock">
							{data.startTime && data.endTime && (
								<LabelMedium>{`${moment(data.startTime).format("hh:mm a")} - ${moment(data.endTime).format("hh:mm a")} `}</LabelMedium>
							)}
						</DisplayLabel>
					</div>
					<Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />
					<DisplayLabel label="Meeting Type" icon="map-marker-alt">
						<LabelMedium>{displayMeetingType()}</LabelMedium>
					</DisplayLabel>

					{displayLocation()}

					<Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />
					{data.sessionFundingSources?.map((sfs, i) => (
						<div key={i}>
							{i !== 0 && <Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />}
							<DisplayLabel label="Funding source">
								<LabelMedium>{sfs.fundingSource[0].label || "N/A"}</LabelMedium>
							</DisplayLabel>
							<DisplayLabel label="Contract Area">
								<LabelMedium>{sfs.contractArea && sfs.contractArea.length > 0 ? sfs.contractArea[0].label : "N/A"}</LabelMedium>
							</DisplayLabel>
							<DisplayLabel label="Support Type">
								<LabelMedium>{sfs.supportType && sfs.supportType.length > 0 ? sfs.supportType[0].label : "N/A"}</LabelMedium>
							</DisplayLabel>
							<DisplayLabel label="Sub Support Type">
								<LabelMedium>{sfs.subSupportType && sfs.subSupportType.length > 0 ? sfs.subSupportType[0].label : "N/A"}</LabelMedium>
							</DisplayLabel>
						</div>
					))}

					<Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />
					<DisplayLabel label="Pre-Session Note">
						<LabelMedium
							overrides={{
								Block: {
									style: {
										whiteSpace: "pre-line",
									},
								},
							}}
						>
							{data.notes || "N/A"}
						</LabelMedium>
					</DisplayLabel>
				</div>
				{error && <ErrorNotification messageOrPayload={payload} />}
				<CancelAndSaveButtons
					buttonWidths={"fit-content"}
					disabled={loading}
					cancelLabel="Back"
					cancelFn={() => {
						if (pageChange) {
							pageChange(-1)
							return
						}
						history.goBack()
					}}
					saveLabel="Create Appointment"
					saveFn={onSubmit}
				/>
			</div>
		</div>
	)
}

interface DisplayLabelProps extends BlockProps {
	icon?: IconName
	label: string
}
export const DisplayLabel = (props: DisplayLabelProps) => {
	const { icon, label, children, marginTop, marginBottom } = props
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginTop: marginTop?.toString() || "10px",
		marginBottom: children ? "5px" : "30px",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
		marginBottom: marginBottom?.toString() || "8px",
	})
	return (
		<div className={container}>
			<div className={title}>
				{icon && <FontAwesomeIcon color={ZenTheme.colors.iconColour} icon={["fal", icon]} />}
				<LabelMedium marginLeft={icon ? "8px" : 0} color={ZenTheme.colors.primaryGrey}>
					{label}
				</LabelMedium>
			</div>
			{children}
		</div>
	)
}

interface DisplayPersonCardProps {
	firstName: string
	lastName: string
	avatarURL?: string
	marginBottom?: string
}
export const DisplayPersonCard = (props: DisplayPersonCardProps) => {
	const { firstName, lastName, avatarURL, marginBottom } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		alignItems: "center",
		marginBottom: marginBottom || 0,
	})

	return (
		<div className={container}>
			<Avatar
				name={`${firstName} ${lastName}`}
				size="scale1000"
				overrides={{
					Root: {
						style: {
							minWidth: ZenTheme.sizing.scale1000,
						},
					},
				}}
				src={avatarURL}
			/>
			<LabelMedium marginLeft="20px" marginRight="10px">{`${firstName} ${lastName}`}</LabelMedium>
		</div>
	)
}
