import React, {useEffect, useState} from 'react';
import YouthCareerContext from './context';
import { useForm } from "react-hook-form";
import { Option, Value } from "baseui/select";
import { FormValues, Props, Values, Actions, FormType } from './types';
import { capitalize, getErrorFromBlob, snakeToTitle, useDebounce } from 'helpers/utils';
import { useParameterizedQuery, useQuery, } from "react-fetching-library";
import { fetching } from 'fetching';
import moment from 'moment';


const YouthCareerProvider = (props: Props) => {

    const { children } = props ?? {};
    const { control, handleSubmit, errors,  getValues } = useForm<FormValues>();
    const { setValue } = control;
	const { query: YouthCarerQuery, loading, error: YouthCarerPayloadError } = useParameterizedQuery(fetching.query.youthCarerReport);
    
	const [searchKey, setSearchKey] = React.useState<any>("");
	const [lineItemDisplayOptions, setLineItemDisplayOptions] = React.useState<string>("");
	const debouncedSearchTerm = useDebounce(lineItemDisplayOptions, 500);
	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm]);
    
    const {query: searchFundingTypeOptions, payload: fundingTypeItems, loading: fundingTypeLoading} = useQuery(
        fetching.query.getFundingTypeOptions(searchKey, )
    );

    React.useEffect(() => {
		if (searchKey === "") return
        searchFundingTypeOptions()
        
	}, [searchKey, searchFundingTypeOptions]);

    const [data, setData] = useState<Option[]>();
    const [fundingTypeOpt, setFundingTypeOpt] = useState<Option[]>();
    const [errorMsg, setErrorMsg] = useState<Record<string, any>>({});
	const [durationWarning, setDurationWarning] = React.useState<string>();
    const formTypeOpt = [{id: "YOUTH_STAR", label: "Youth Star"}, {id: "CARER_STAR", label: "Carer Star"}];
    const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));
    
    const handleOnSubmit = async (data: FormValues) => {
        const formTypes = data?.form_type?.map<string>((item) => item.id?.toString() || "");
        const { client } = data ?? {}
        const { firstName='', lastName='', id: clientID=""} = client?.[0] ?? {}
		setDurationWarning(undefined);
        setErrorMsg({});

        const downloadStarReport = async (formType: string, fileName?: string) => {

            const response = await YouthCarerQuery({
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate),
                client: {id: clientID as string, fullName: clientID ? `${capitalize(firstName)} ${capitalize(lastName)}`: ''},
                fundingType: data?.funding_type?.map<string>((item) => item.label?.toString() || ""),
                formType: formType,
            });
    
            if (response.error || !response.payload) {
                const err_msg = await getErrorFromBlob(response.payload)
                err_msg && setErrorMsg(
                    (prev: Record<string, any>) => ({
                        ...prev,
                        eblobErr: err_msg
                    })
                )
                return;
            };
    
            // Create link and initiate download when response is received
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(response.payload);
            downloadLink.download = `Pre and Post Outcomes -${ fileName ? fileName : formTypes?.[0]} Metric Report-${moment(data.startDate).format("YYYYMMDD")}_${moment(data.endDate).format("YYYYMMDD")}.xlsx`;
            downloadLink.click();
        };
        if (!formTypes?.length) {
            const allFormType = Object.values(FormType);
            for (const formType of allFormType) {
                await downloadStarReport(formType, `${formType === "YOUTH_STAR" ? "Youth Star" : "Carer Star"}`);
            }
        } else {
            for (const formType of formTypes) {
                await downloadStarReport(formType, `${formType === "YOUTH_STAR" ? "Youth Star" : "Carer Star"}`);
                // await delay(10000);
            }
        }
    };

    const handleInputChange = (value: Option[]) => {
        const newValue = value[value?.length - 1];
    };

    useEffect(() => {
        setFundingTypeOpt(fundingTypeItems)
    },[ fundingTypeItems])

    const values = {
        data,
        fundingTypeOpt,
        fundingTypeLoading,
        formTypeOpt,
        durationWarning,
        loading,
        YouthCarerPayloadError,
    } as Values;

    const actions = {
        handleOnSubmit,
        setData,
        setLineItemDisplayOptions,
        // handleInputChange,
    } as Actions;

    const useFormHooks = {
        control,
        handleSubmit,
        errors,
        getValues
    };

    return (
        <YouthCareerContext.Provider
            value={{
                errorMsg,
                values,
                actions,
                useFormHooks,
            }}
        >
            {children}
        </YouthCareerContext.Provider>
    )
};

export default YouthCareerProvider;
