import * as React from "react"
import { useStyletron } from "baseui"
import { ZenCard } from "../../components/common"
import { HeadingXSmall } from "baseui/typography"
import { ReportItem } from "./reports"
import { routes } from "../../routes"

export const SelectableOptionManagement = () => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const scrollableDiv = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		maxHeight: "100%",
		overflow: "auto",
	})
	return (
		<>
			<ZenCard className={container}>
				<HeadingXSmall marginTop={0} marginBottom={"10px"}>
					Selectable Options Management
				</HeadingXSmall>
				<div className={scrollableDiv}>
					<ReportItem label={"Alert Types"} url={routes.admin.selectableOptionManagement.alertTypes} />
					<ReportItem label={"Call Purposes"} url={routes.admin.selectableOptionManagement.callPurposes} />
					<ReportItem label={"Cars"} url={routes.admin.selectableOptionManagement.cars} />
					<ReportItem label={"Delivered Programs"} url={routes.admin.selectableOptionManagement.deliveredPrograms} />
					<ReportItem label={"Departments - Positions"} url={routes.admin.selectableOptionManagement.departmentsPositions} />
					<ReportItem label={"Funding Sources"} url={routes.admin.selectableOptionManagement.fundingSources} />
					<ReportItem label={"Genders"} url={routes.admin.selectableOptionManagement.genders} />
					<ReportItem label={"Languages"} url={routes.admin.selectableOptionManagement.languages} />
					<ReportItem label={"NDIS Plan Managers"} url={routes.admin.selectableOptionManagement.ndisPlanManagers} />
					<ReportItem label={"Note Types"} url={routes.admin.selectableOptionManagement.noteTypes} />
					<ReportItem label={"Offices"} url={routes.admin.selectableOptionManagement.offices} />
					<ReportItem label={"Referrers"} url={routes.admin.selectableOptionManagement.referrers} />
					<ReportItem label={"Related Services"} url={routes.admin.selectableOptionManagement.relatedServices} />
					<ReportItem label={"Service Providers"} url={routes.admin.selectableOptionManagement.serviceProviders} />
				</div>
			</ZenCard>
		</>
	)
}
