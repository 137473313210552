import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { LabelMedium } from "baseui/typography"

interface DisplayOrgCardProps {
	name: string
	marginBottom?: string
}
export const DisplayOrgCard = (props: DisplayOrgCardProps) => {
	const { name, marginBottom } = props
	const [css, theme] = useStyletron()
	const container = css({
		display: "flex",
		alignItems: "center",
		marginBottom: marginBottom || 0,
	})
	return (
		<div className={container}>
			<Avatar
				name={name}
				size="scale1000"
				overrides={{
					Root: {
						style: {
							minWidth: theme.sizing.scale1000,
						},
					},
				}}
			/>
			<LabelMedium marginLeft="20px" marginRight="10px">
				{name}
			</LabelMedium>
		</div>
	)
}
