import React from "react";
import NDISUtilisationProvider from "./ndisUtilisationProvider";
import NDISUtilisationView from "./ndisUtilisationView";

export const NDISUtilisation = () => {
    return (
        <NDISUtilisationProvider>
            <NDISUtilisationView/>
        </NDISUtilisationProvider>
    )
};