import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Value } from "baseui/select"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { PortalContainer } from "../../controllers/portal"

import { fetching } from "../../fetching"
import { genericError } from "../../helpers/errors"
import { friendlyDate, truncate } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { FilterBy, FormType, FormTypeURL, SortBy } from "../../types/enums"
import { Form, SearchTextMinLength, SortOrder } from "../../types/types"
import { CommonFilterOptions, SearchAndFilter, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { ZenFileUploader } from "../fileUploader"
import { ListTable } from "../listTable"
import { ZenArchiveModal } from "../zenComponents/zenArchiveDialog"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenPagination } from "../zenComponents/zenPagination"

interface FormListProps {
	clientID?: string
	canAttachFile?: boolean
	/** Page Title (default: `"Documents"`) */
	title?: string
	/** 1 = Only show forms that require action, 2 = Only show forms that don't require action */
	requiresAction?: number
	className?: string
	style?: React.CSSProperties | undefined
	onItemClick?: () => void
	filter?: FilterBy
	hideFilter?: boolean
	omitAction?: boolean
	isOwnActionableForms?: boolean
}

export const FormList = (props: FormListProps) => {
	const { clientID, requiresAction, canAttachFile, onItemClick, filter: customFilter, style, hideFilter, omitAction, isOwnActionableForms } = props
	const title = props.title || "Documents"

	const history = useHistory()

	// files upload
	const [isOpen, setIsOpen] = React.useState<boolean>(false)

	// Get Data
	const [search, setSearch] = React.useState("")
	const [filter, setFilter] = React.useState<Value>([{ id: FilterBy.Active, label: FilterBy.Active }])
	const [offset, setOffset] = React.useState(0)
	const [sortColumn, setSortColumn] = React.useState<string>(SortBy.DateUpdated)
	const [sortAsc, setSortAsc] = React.useState(false)
	const [rows, setRows] = React.useState<Form<any>[]>([])
	const limit = 20
	const [total, setTotal] = React.useState<number>(0)

	const getForms = useParameterizedQuery(fetching.query.getFormsMany)
	const { fetchActionableForms, timezone } = PortalContainer.useContainer()
	const reFetchList = React.useCallback(() => {
		const getFormsParams = {
			search: {
				filterBy: customFilter ? customFilter : filter[0]?.id?.toString(),
				search: search.length >= SearchTextMinLength ? search : undefined,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
				clientID,
			},
			limit,
			offset: offset,
			requiresAction,
			isOwnActionableForms,
		}

		getForms.query(getFormsParams).then((resp) => {
			if (resp.error || !resp.payload) return
			setRows(resp.payload.formList)
			setTotal(resp.payload.total)
			fetchActionableForms()
		})
	}, [filter, sortAsc, search, offset, clientID]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		reFetchList()
	}, [reFetchList])

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	const [css, theme] = useStyletron()
	const headerStyle = css({
		marginBottom: "12px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const firstColumnCellStyle = css({
		color: "#343434",
		paddingLeft: "6px",
		display: "flex",
	})
	const iconStyle = css({
		color: theme.colors.primary,
		marginRight: "10px",
		alignSelf: "center",
	})
	const notificationCircle = css({
		minHeight: "10px",
		minWidth: "10px",
		background: theme.colors.negative,
		borderRadius: "10px",
		alignSelf: "center",
		marginLeft: "10px",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		marginRight: "12px",
		width: "100%",
	})

	const filterOptions: Value = requiresAction ? CommonFilterOptions : [...CommonFilterOptions, { label: "Requires Action", id: FilterBy.RequiresAction }]

	// original columns
	const columns: {
		id: string
		header: JSX.Element | string
		resolver: (row: Form<any>) => JSX.Element | string
		sortable: boolean
	}[] = [
		{
			id: clientID ? "name" : SortBy.Alphabetical,
			header: (
				<div className={firstColumnCellStyle}>
					<FontAwesomeIcon icon={["fal", "folder"]} className={iconStyle} />
					<div>Name</div>
				</div>
			),
			resolver: (row: Form<any>) => {
				const type = row.type.split("_")
				return (
					<div className={firstColumnCellStyle}>
						<FontAwesomeIcon icon={["fal", "file"]} className={iconStyle} />
						<div>{`${row.name} ${type[0] === FormType.Intake && row.requiresAction ? "- Incomplete" : ""}`}</div>
						{row.requiresAction && <div className={notificationCircle} title="Action is required on this form" />}
					</div>
				)
			},
			sortable: true,
		},
		{
			id: clientID ? "updatedAt" : SortBy.DateUpdated,
			header: "Modified",
			resolver: (row: Form<any>) => friendlyDate(row.updatedAt, timezone),
			sortable: false,
		},
		{
			id: SortBy.UpdatedByName,
			header: "Modified By",
			resolver: (row: Form<any>) => truncate(row.updatedByName || "", 20),
			sortable: false,
		},
		{
			id: clientID ? "createdAt" : SortBy.DateCreated,
			header: "Created",
			resolver: (row: Form<any>) => friendlyDate(row.createdAt, timezone),
			sortable: true,
		},
		{
			id: SortBy.CreatedByName,
			header: "Created By",
			resolver: (row: Form<any>) => truncate(row.createdByName || "", 20),
			sortable: false,
		},
	]

	return (
		<ZenCard className={props.className} style={style}>
			<div className={headerStyle}>
				<div className={group}>
					<LabelMedium marginRight="8px">{title}</LabelMedium>
					{canAttachFile && clientID && (
						<ZenButton width="90px" onClick={() => setIsOpen(true)}>
							Attach File
						</ZenButton>
					)}
				</div>
				<SearchAndFilter
					hideFilter={hideFilter}
					search={search}
					setSearch={setSearch}
					filter={filter}
					setFilter={setFilter}
					alignRight
					filterOptions={filterOptions}
				/>
			</div>
			<ListTable
				handleSort={handleSort}
				sortColumn={sortColumn}
				isLoading={getForms.loading}
				sortAsc={sortAsc}
				rows={rows}
				onRowClick={(row: Form<any>) => {
					if (onItemClick) {
						onItemClick()
					}

					const type = row.type.split("_")
					if (type[0] === "DOCUMENT") {
						window.open(`/api/files/client/document_display/${row.id}`, "_blank")
						return
					}

					if (type[0] === FormType.Intake) {
						if (type.length !== 2) return

						// go to client intake type page
						const intakeType = type[1].toLowerCase()
						history.push({
							pathname: `/portal/clients/${clientID || row.clientID}/intake/${intakeType}`,
							search: `formID=${row.id}${intakeType === "ndis" ? "&&ndis=true" : ""}`,
						})
						return
					}
					// show forms
					history.push({
						pathname: `/portal/forms/${FormTypeURL(row.type as FormType)}`,
						search: `formID=${row.id}`,
					})
					return
				}}
				columns={
					omitAction
						? columns
						: columns.concat({
								id: "archived",
								header: "Action",
								resolver: (row: Form<any>) => (
									<ArchiveButton id={row.id} isForm={!!row.type} archived={!!row.deletedAt || false} reFetchList={reFetchList} rowName={row.name} />
								),
								sortable: false,
						  })
				}
			/>
			<ZenPagination total={total} limit={20} offset={offset} setOffset={setOffset} />
			{clientID && <ClientFileUploadModal clientID={clientID} isOpen={isOpen} setIsOpen={setIsOpen} reFetch={reFetchList} />}
		</ZenCard>
	)
}

export const ArchiveButton = (props: { rowName: string; id: string; isForm: boolean; archived: boolean; reFetchList: () => void }) => {
	const [isOpen, setIsOpen] = React.useState(false)
	const { reFetchList } = props

	const fileArchive = useMutation(fetching.mutation.clientFileArchive)
	const fileUnarchive = useMutation(fetching.mutation.clientFileUnarchive)
	const formArchive = useMutation(fetching.mutation.clientFormArchive)
	const formUnarchive = useMutation(fetching.mutation.clientFormUnarchive)

	const documentToggleArchived = (id: string, isForm: boolean, archived: boolean) => {
		// Unarchive
		if (archived) {
			if (isForm) {
				formUnarchive.mutate(id)
				return
			}
			fileUnarchive.mutate(id)
			return
		}
		// Archive
		if (isForm) {
			formArchive.mutate(id)
			return
		}
		fileArchive.mutate(id)
	}

	React.useEffect(() => {
		if (fileArchive.payload || fileUnarchive.payload || formArchive.payload || formUnarchive.payload) {
			reFetchList()
		}
	}, [fileArchive.payload, fileUnarchive.payload, formArchive.payload, formUnarchive.payload, reFetchList])

	const loading = fileArchive.loading || fileUnarchive.loading || formArchive.loading || formUnarchive.loading

	if (fileArchive.error) return <ErrorNotification messageOrPayload={fileArchive.payload} />
	if (fileUnarchive.error) return <ErrorNotification messageOrPayload={fileUnarchive.payload} />
	if (formArchive.error) return <ErrorNotification messageOrPayload={formArchive.payload} />
	if (formUnarchive.error) return <ErrorNotification messageOrPayload={formUnarchive.payload} />

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<Button
				kind="minimal"
				isLoading={loading}
				onClick={() => {
					if (!props.archived) {
						setIsOpen(true)
						return
					}
					documentToggleArchived(props.id, props.isForm, props.archived)
				}}
			>
				<FontAwesomeIcon
					color={props.archived ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
					size={"1x"}
					icon={["fal", props.archived ? "trash-restore-alt" : "trash-alt"]}
				/>
			</Button>
			{!props.archived && (
				<ZenArchiveModal
					open={isOpen}
					loading={fileArchive.loading || fileUnarchive.loading}
					message={props.rowName}
					onClose={() => setIsOpen(false)}
					confirmArchive={() => {
						documentToggleArchived(props.id, props.isForm, props.archived)
						setIsOpen(false)
					}}
				/>
			)}
		</div>
	)
}

interface ClientFileUploadModalProps {
	clientID: string
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	reFetch: () => void
}
export const ClientFileUploadModal = (props: ClientFileUploadModalProps) => {
	const { isOpen, setIsOpen, clientID } = props

	const [css] = useStyletron()
	const container = css({
		width: "600px",
		maxHeight: "400px",
		display: "flex",
		flexDirection: "column",
	})

	const [file, setFile] = React.useState<File>()
	const [fileSelectError, setFileSelectError] = React.useState<string>("")
	const fileUpload = useMutation<{ fileID: string }>(fetching.mutation.fileUpload)
	const addFile = useMutation<Form<any>>(fetching.mutation.clientFilesAdd)

	const clearData = () => {
		setFile(undefined)
		setFileSelectError("")
	}

	const onClose = () => {
		if (loading) return
		clearData()
		setIsOpen(false)
	}

	const handleUpload = async () => {
		const uploadResponse = await fileUpload.mutate({ file: file })
		if (!uploadResponse.payload || !uploadResponse.payload.fileID) {
			return
		}
		const addResponse = await addFile.mutate({
			clientID: clientID,
			fileID: uploadResponse.payload.fileID,
		})
		if (!addResponse.payload) {
			return
		}
		props.reFetch()
		onClose()
	}

	const handleFileSelected = (file: File) => {
		setFile(file)
		setFileSelectError("")
	}

	const handleFileSelectError = (error: string) => {
		setFile(undefined)
		setFileSelectError(error)
	}

	const loading = fileUpload.loading || addFile.loading
	const errorMessage = fileSelectError || genericError

	return (
		<ZenModal onClose={onClose} isOpen={isOpen} role={ROLE.dialog} size={SIZE.auto}>
			<ModalHeader>
				<LabelMedium>Client Files Upload</LabelMedium>
			</ModalHeader>
			<ModalBody>
				<div className={container}>
					<ZenFileUploader onError={handleFileSelectError} onFileAccepted={handleFileSelected} />
					<LabelSmall marginTop="8px" marginBottom="8px">
						Selected File:
					</LabelSmall>
					{file && (
						<LabelMedium>
							{file.name} ({(file.size / (1 << 20)).toFixed(2)}MB)
						</LabelMedium>
					)}
					{fileUpload.error && <ErrorNotification messageOrPayload={fileUpload.payload} />}
					{addFile.error && <ErrorNotification messageOrPayload={addFile.payload} />}
					<FormControl error={(fileUpload.error || addFile.error || fileSelectError) && errorMessage}>
						<ZenButton marginTop="10px" width="100%" onClick={handleUpload} isLoading={loading} disabled={!file}>
							Upload
						</ZenButton>
					</FormControl>
				</div>
			</ModalBody>
		</ZenModal>
	)
}
