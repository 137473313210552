import * as React from "react"
import { AssignedClient, RemovedAssignedClient, RolePermission, SortOrder, UserDetail } from "../../types/types"
import { useStyletron } from "baseui"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { SearchAndFilter, ZenCard } from "../common"
import { OptionProfile } from "baseui/menu"
import { Avatar } from "baseui/avatar"
import { useHistory } from "react-router-dom"
import { ZenTheme } from "themeOverrides"
import { AuthContainer } from "../../controllers/auth"
import { TooltipButton } from "../tooltipButton"
import { useZenToast } from "../zenComponents/useZenToast"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Value } from "baseui/select"
import { FilterBy } from "../../types/enums"
import { fetching } from "../../fetching"
import { ZenModal } from "../zenComponents/zenModal"
import { ModalBody, ModalHeader } from "baseui/modal"
import { ZenTextArea } from "../zenComponents/zenInput"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { ErrorNotification } from "../errorBox"
import { StyledSpinnerNext } from "baseui/spinner"
import { PortalContainer } from "../../controllers/portal"
import { DisplayLabel } from "../sessions/newClientAppointment/singleSessionConfirmation"
import moment from "moment"
import { Block } from "baseui/block"

interface Props {
	assignedClients: AssignedClient[]
	user?: UserDetail
	workerRefetch?: () => Promise<QueryResponse<UserDetail>>
}

export const AssignedClients = (props: Props) => {
	const { user, assignedClients, workerRefetch } = props
	const [css] = useStyletron()
	const { hasPermission } = AuthContainer.useContainer()
	const [openAssignModal, setOpenAssignModal] = React.useState<boolean>(false)
	const [openHistoryModal, setOpenHistoryModal] = React.useState<boolean>(false)
	const [onRemove, setOnRemove] = React.useState<boolean>(false)
	const history = useHistory()
	const container = css({
		height: "100%",
		width: "100%",
		padding: "15px",
	})
	const title = css({
		display: "flex",
		height: "fit-content",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const scrollingDiv = css({
		maxHeight: "100%",
		overflowY: "auto",
		marginTop: "8px",
	})

	let lastWorkerName: string | undefined = undefined

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>Assigned Clients</LabelMedium>
				{user && workerRefetch && hasPermission(RolePermission.ClientUpdate) && (
					<div>
						<TooltipButton
							onClick={() => {
								setOnRemove(false)
								setOpenAssignModal(true)
							}}
							tooltip={"Assign clients"}
							iconName={"plus"}
						/>
						<TooltipButton
							onClick={() => {
								setOnRemove(true)
								setOpenAssignModal(true)
							}}
							tooltip={"Remove clients"}
							iconName={"trash-alt"}
						/>
						<TooltipButton onClick={() => setOpenHistoryModal(true)} tooltip={"View assignment history"} iconName={"history"} />
					</div>
				)}
			</div>
			<div className={scrollingDiv}>
				{assignedClients &&
					assignedClients.length > 0 &&
					assignedClients.map((c, i) => {
						let showName = false
						if (c.workerName !== lastWorkerName) {
							lastWorkerName = c.workerName
							showName = true
						}
						return (
							<React.Fragment key={i}>
								{lastWorkerName && showName && <h4>{lastWorkerName}</h4>}
								<div onClick={() => history.push(`/portal/clients/${c.id}`)}>
									<OptionProfile
										item={c}
										getProfileItemImg={(t: AssignedClient) => () =>
											(
												<Avatar
													name={`${t.firstName} ${t.lastName}`}
													overrides={{
														Root: {
															style: {
																minWidth: ZenTheme.sizing.scale1000,
															},
														},
													}}
													src={t.avatarURL}
												/>
											)}
										getProfileItemImgText={(t: AssignedClient) => `${t.firstName} ${t.lastName}`}
										getProfileItemLabels={(t: AssignedClient) => ({ title: `${t.firstName} ${t.lastName}` })}
										overrides={{
											ListItemProfile: {
												style: {
													paddingTop: "3px",
													paddingBottom: "3px",
													paddingLeft: "1px",
													paddingRight: "2px",
												},
											},
											ProfileImgContainer: {
												style: {
													height: "40px",
													width: "40px",
												},
											},
											ProfileLabelsContainer: {
												style: {
													marginLeft: "8px",
												},
											},
											ProfileTitle: {
												style: {
													marginTop: "8px",
												},
											},
										}}
									/>
								</div>
							</React.Fragment>
						)
					})}
			</div>
			{user && workerRefetch && (
				<AssignRemoveClientModal isOpen={openAssignModal} setIsOpen={setOpenAssignModal} user={user} onRemove={onRemove} refetch={workerRefetch} />
			)}
			{user && openHistoryModal && <AssignClientHistory isOpen={openHistoryModal} setIsOpen={setOpenHistoryModal} workerID={user.id} />}
		</ZenCard>
	)
}

interface AssignRemoveWorkerModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	user: UserDetail
	onRemove: boolean
	refetch: () => Promise<QueryResponse<UserDetail>>
}
// AssignRemoveClientModal show the model for user to select clients or workers to assign
const AssignRemoveClientModal = (props: AssignRemoveWorkerModalProps) => {
	const { isOpen, setIsOpen, user, onRemove, refetch } = props
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const { handleSubmit, control, errors, reset } = useForm()
	const [idList, setIDList] = React.useState<string[]>([])
	const [idListError, setIDListError] = React.useState<boolean>(false)
	const [searchKey, setSearchKey] = React.useState<string>("")
	const [filter, setFilter] = React.useState<Value>([{ label: FilterBy.Active, id: FilterBy.Active }])
	const [dataList, setDataList] = React.useState<AssignedClient[]>([])
	const {
		payload: clientData,
		loading: clientLoading,
		query,
		error,
	} = useQuery(
		fetching.query.getClientMany({
			search: {
				search: searchKey,
				filterBy: filter[0]?.id ? filter[0].id.toString() : FilterBy.Active,
				sortBy: "Name",
				sortDir: SortOrder.Ascending,
			},
			limit: 20,
			offset: 0,
			excludedID: user.assignedClients?.map<string>((ac) => ac.id),
		}),
		!onRemove,
	)
	const assignClientToWorker = useMutation(fetching.mutation.assignClientToWorker)
	const removeAssignedClient = useMutation(fetching.mutation.removeAssignedClient)

	const clearData = React.useCallback(() => {
		setSearchKey("")
		reset({
			reason: "",
		})
		setIDListError(false)
		setFilter([])
		setIDList([])
	}, [reset])

	const onClose = React.useCallback(() => {
		if (assignClientToWorker.loading || removeAssignedClient.loading) return
		clearData()
		setIsOpen(false)
	}, [setIsOpen, assignClientToWorker.loading, removeAssignedClient.loading, clearData])

	const displayTitle = () => {
		if (onRemove) {
			return "Remove Client"
		}

		return "Assign Clients"
	}

	const onSave = async (formData: any) => {
		if (idList.length === 0) {
			setIDListError(true)
			return
		}

		// remove worker
		if (onRemove) {
			await removeAssignedClient.mutate({
				workerID: user.id,
				removeClientID: idList[0],
				reason: formData.reason,
			})

			// refetch client data
			await refetch()
			showToast("client removed successfully.", "positive")
			return
		}

		// assign workers
		await assignClientToWorker.mutate({
			workerID: user.id,
			clientIDList: idList,
		})
		// refetch client data
		await refetch()
		showToast("Client(s) assigned to worker successfully.", "positive")
	}

	// handle toggle selection card
	const onSelect = (uid: string) => {
		if (onRemove) {
			setIDList([uid])
			return
		}
		if (idList.includes(uid)) {
			setIDList((list) => list.filter((id) => id !== uid))
			return
		}
		setIDList(idList.concat(uid))
	}
	React.useEffect(() => {
		if (onRemove) {
			if (searchKey) {
				setDataList([...user.assignedClients].filter((l) => `${l.firstName} ${l.lastName}`.toLowerCase().includes(searchKey.toLowerCase())))
				return
			}
			setDataList(user.assignedClients || [])
			return
		}
		if (clientLoading || !clientData) return
		setDataList(clientData.clients)
	}, [clientData, isOpen, onRemove, user.assignedClients, clientLoading, searchKey])

	React.useEffect(() => {
		if (!(assignClientToWorker.payload || removeAssignedClient.payload)) return
		query()
		onClose()
	}, [assignClientToWorker.payload, removeAssignedClient.payload, query, onClose])

	const isLoading = removeAssignedClient.loading || assignClientToWorker.loading

	const container = css({
		width: "500px",
		height: "700px",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
		opacity: isLoading ? 0.5 : 1,
		position: isLoading ? "relative" : "unset",
	})
	const functionBar = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})
	const scrollingDiv = css({
		height: "100%",
		maxHeight: "100%",
		paddingRight: "16px",
		overflowX: "hidden",
		overflowY: "auto",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "10px",
	})

	const clientCard = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		cursor: "pointer",
		borderRadius: "5px",
		padding: "5px 3px 5px 4px",
		marginBottom: "1px",
		":hover": {
			backgroundColor: ZenTheme.colors.lightGrey,
		},
	})

	return (
		<ZenModal onClose={onClose} isOpen={isOpen} role="dialog" size="auto">
			<ModalHeader>
				<div className={group}>
					<LabelLarge>{displayTitle()}</LabelLarge>
					{idListError && (
						<LabelLarge color={ZenTheme.colors.warning600} marginLeft="5px">
							{"- Please pick at least one client"}
						</LabelLarge>
					)}
				</div>
				<div className={functionBar}>
					<SearchAndFilter search={searchKey} setSearch={setSearchKey} filter={filter} setFilter={setFilter} hideFilter />
				</div>
			</ModalHeader>
			<ModalBody>
				<form autoComplete="off" className={container} onSubmit={handleSubmit(onSave)}>
					<div className={scrollingDiv}>
						{dataList.length > 0 &&
							dataList.map((c: AssignedClient, i) => (
								<div key={i} onClick={() => onSelect(c.id)}>
									<div
										className={clientCard}
										style={{
											border: idList.includes(c.id) ? "1px solid " + ZenTheme.colors.primaryGreen : undefined,
										}}
									>
										<Block display="flex" alignItems="center">
											<Avatar
												name={`${c.firstName} ${c.lastName}`}
												overrides={{
													Root: {
														style: {
															minWidth: ZenTheme.sizing.scale1000,
														},
													},
												}}
												src={c.avatarURL || ""}
											/>
											<LabelMedium marginLeft="8px">
												{c.firstName} {c.lastName}
											</LabelMedium>
										</Block>
									</div>
								</div>
							))}
					</div>
					{onRemove && (
						<ZenTextArea label="Reason" formRef={control} nameRef="reason" inputError={errors.reason} formRules={{ required: "Reason is required" }} />
					)}
					<CancelAndSaveButtons disabled={isLoading} isLoading={isLoading} cancelFn={onClose} saveLabel={onRemove ? "Remove" : "Assign"} />
				</form>
				{assignClientToWorker.error && <ErrorNotification messageOrPayload={assignClientToWorker.payload} />}
				{removeAssignedClient.error && <ErrorNotification messageOrPayload={removeAssignedClient.error} />}
				{error && <ErrorNotification messageOrPayload={clientData} />}
			</ModalBody>
		</ZenModal>
	)
}

interface AssignClientHistoryProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	workerID: string
}
const AssignClientHistory = (props: AssignClientHistoryProps) => {
	const { isOpen, setIsOpen, workerID } = props
	const [css] = useStyletron()
	const history = useHistory()
	const { payload, loading, error, query } = useQuery<RemovedAssignedClient[]>(fetching.query.getClientWorkerHistoryByWorkerID(workerID), false)

	const onOpen = React.useCallback(() => {
		if (!isOpen) return
		query()
	}, [isOpen, query])
	React.useEffect(() => {
		onOpen()
	}, [onOpen])

	const displayResult = () => {
		if (loading || !payload) return <StyledSpinnerNext />
		if (error) return <ErrorNotification messageOrPayload={payload} />
		if (payload.length === 0) return <LabelMedium>No remove history</LabelMedium>
		return payload.map((h, i) => (
			<RemovedClientCard
				{...h}
				key={i}
				index={i}
				onClientClick={(id: string) => {
					setIsOpen(false)
					history.push(`/portal/clients/${id}`)
				}}
			/>
		))
	}
	const container = css({
		height: "500px",
		width: "400px",
		maxHeight: "500px",
		overflowY: "auto",
		paddingRight: "5px",
	})
	return (
		<ZenModal onClose={() => setIsOpen(false)} isOpen={isOpen} role="dialog" size="auto">
			<ModalHeader>Removed workers history</ModalHeader>
			<ModalBody>
				<div className={container}>{displayResult()}</div>
			</ModalBody>
		</ZenModal>
	)
}

interface RemovedClientCardProps extends RemovedAssignedClient {
	onClientClick: (id: string) => void
	index: number
}
const RemovedClientCard = (props: RemovedClientCardProps) => {
	const { client, reason, deletedAt, archivedByUser, onClientClick, index } = props
	const { timezone } = PortalContainer.useContainer()
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		borderRadius: "3px",
		backgroundColor: index % 2 === 0 ? ZenTheme.colors.divider : "white",
		paddingLeft: "8px",
		paddingRight: "8px",
	})
	const group = css({
		display: "flex",
	})
	const clientAvatar = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		cursor: "pointer",
		margin: "4px",
	})
	return (
		<div className={container}>
			{/* worker info */}
			<DisplayLabel label="Client" marginTop={0} marginBottom={0}>
				<div onClick={() => onClientClick(client.id)}>
					<div className={clientAvatar}>
						<Block display="flex" alignItems="center">
							<Avatar
								name={`${client.firstName} ${client.lastName}`}
								overrides={{
									Root: {
										style: {
											minWidth: ZenTheme.sizing.scale1000,
										},
									},
								}}
								src={client.avatarURL || ""}
							/>
							<LabelMedium marginLeft="8px">
								{client.firstName} {client.lastName}
							</LabelMedium>
						</Block>
					</div>
				</div>
			</DisplayLabel>
			{/* Archived time */}
			<div className={group}>
				<DisplayLabel label="Removed By" marginTop={0}>
					<div onClick={() => onClientClick(archivedByUser.id)} style={{ cursor: "pointer" }}>
						<LabelMedium>{`${archivedByUser.firstName} ${archivedByUser.lastName}`}</LabelMedium>
					</div>
				</DisplayLabel>
				<DisplayLabel label="Removed At" marginTop={0}>
					<LabelMedium>{moment(deletedAt).tz(timezone.id).format("hh:mm a, DD/MM/YYYY")}</LabelMedium>
				</DisplayLabel>
			</div>
			{/* Reason */}
			<DisplayLabel label="Reason">
				<LabelMedium>{reason}</LabelMedium>
			</DisplayLabel>
		</div>
	)
}
