export const routes = {
	root: "/",
	portal: "/portal",
	withID: (id: string, route: string, identifier?: string): string => {
		if (identifier) {
			return route.replace(new RegExp(`:${identifier}`, "g"), id)
		}
		return route.replace(/:id/g, id)
	},
	restrictedClients: {
		root: "/portal/restricted-clients",
	},
	travels: {
		root: "/portal/travels",
		view: "/portal/travels/:id",
		create: "/portal/travels/create",
		createConfirm: "/portal/travels/create_confirm",
	},
	clients: {
		root: "/portal/clients",
		create: "/portal/clients/create",
		client: {
			root: "/portal/clients/:id",
			intake: {
				root: "/portal/clients/:id/intake",
				adult: {
					root: "/portal/clients/:id/intake/adult",
					presenceOfRiskConcerns: "/portal/clients/:id/intake/adult/presenceOfRiskConcerns",
					emergencyContactDetails: "/portal/clients/:id/intake/adult/emergencyContactDetails",
					challengesForClient: "/portal/clients/:id/intake/adult/challengesForClient",
					clientContext: "/portal/clients/:id/intake/adult/clientContext",
					carerStar: "/portal/clients/:id/intake/adult/carerStar",
					youthStar: "/portal/clients/:id/intake/adult/youthStar",
					supports: "/portal/clients/:id/intake/adult/supports",
					actionPlan: "/portal/clients/:id/intake/adult/actionPlan",
				},
				ndis: {
					root: "/portal/clients/:id/intake/ndis",
					emergencyContactDetails: "/portal/clients/:id/intake/ndis/emergencyContactDetails",
					challengesForClient: "/portal/clients/:id/intake/ndis/challengesForClient",
					serviceDetail: "/portal/clients/:id/intake/ndis/serviceDetail",
					mentalHealthTreatingTeam: "/portal/clients/:id/intake/ndis/mentalHealthTreatingTeam",
					ndisSupports: "/portal/clients/:id/intake/ndis/ndisSupports",
				},
				youth: {
					root: "/portal/clients/:id/intake/youth",
					presenceOfRiskConcerns: "/portal/clients/:id/intake/youth/presenceOfRiskConcerns",
					parentGuardianDetails: "/portal/clients/:id/intake/youth/parentGuardianDetails",
					challengesForTheClient: "/portal/clients/:id/intake/youth/challengesForTheClient",
					mentalHealthContext: "/portal/clients/:id/intake/youth/mentalHealthContext",
					relationship: "/portal/clients/:id/intake/youth/relationship",
					workAndSchool: "/portal/clients/:id/intake/youth/workAndSchool",
					supports: "/portal/clients/:id/intake/youth/supports",
					actionPlan: "/portal/clients/:id/intake/youth/actionPlan",
				},
			},
			calendar: "/portal/clients/:id/calendar",
			notes: "/portal/clients/:id/notes",
			alerts: "/portal/clients/:id/alerts",
			update: "/portal/clients/:id/update",
		},
	},
	workers: {
		root: "/portal/workers",
		edit: "/portal/workers/:workerID/edit/",
		view: "/portal/workers/:workerID",
		calendar: "/portal/workers/:workerID/calendar",
		timesheets: "/portal/workers/:workerID/timesheets",
		mileageClaims: "/portal/workers/:workerID/mileage_claims",
		create: {
			root: "/portal/workers/create",
			contact: "/portal/workers/create/contact",
			role: "/portal/workers/create/role",
			emergencyContact: "/portal/workers/create/emergencyContact",
			confirmation: "/portal/workers/create/confirmation",
		},
	},
	forms: {
		root: "/portal/forms",
		k10: "/portal/forms/k10",
		wellbeingPlan: "/portal/forms/wellbeing_plan",
		hazardRiskIdentification: "/portal/forms/hazard~risk_identification",
		incidentAndAccidentReport: "/portal/forms/incident_and_accident_report",
		intake: "/portal/forms/intake",
		outreachVisitationRiskAssessment: "/portal/forms/outreach_visitation_risk_assessment",
		riskAssessment: "/portal/forms/risk_assessment",
		carersStar: "/portal/forms/carers_star",
		youthStar: "/portal/forms/youth_star",
		dass21: "/portal/forms/dass21",
		safetyPlan: "/portal/forms/safety_plan",
	},
	callLogs: {
		root: "/portal/call-logs",
		create: "/portal/call-logs/create",
		view: "/portal/call-logs/:callLogID",
	},
	schedule: {
		root: "/portal/schedule",
	},
	sessions: {
		root: "/portal/sessions",
		create: {
			group: "/portal/sessions/create_group_session",
			single: {
				root: "/portal/sessions/create_single_session",
				view: "/portal/sessions/create_single_session/:clientID",
				note: "/portal/sessions/create_single_session/note",
				confirmation: "/portal/sessions/create_single_session/confirmation",
			},
		},
		view: "/portal/sessions/:identifier",
		logNonBillable: "/portal/sessions/log_non_billable",
	},
	team: {
		root: "/portal/team",
		add: "/portal/team/add",
	},
	admin: {
		root: "/portal/admin",
		mileage: { root: "/portal/admin/mileage", create: "/portal/admin/mileage/create" },
		ndisInvoice: "/portal/admin/ndis_invoice",
		staffHours: "/portal/admin/staff_hours",
		regenerateCharges: "/portal/admin/recalculate_charges",
		recalculateLimits: "/portal/admin/recalculate_limits",
		changeLogs: "/portal/admin/change_logs",
		smsReminder: "/portal/admin/sms_reminder",
		ndisManagement: { root: "/portal/admin/ndis_management", import: "/portal/admin/ndis_management/import" },
		lockDateManagement: "/portal/admin/lock_date",
		demographics: "/portal/admin/demographics",
		contractHours: "/portal/admin/contract-hours",
		callLogs: "/portal/admin/call_logs",
		timesheets: { root: "/portal/admin/timesheets", create: "/portal/admin/timesheets/create" },
		clientHours: "/portal/admin/client_hours",
		ndisUlilisation: "/portal/admin/ndis_utilisation",
		youthCareer: "/portal/admin/pre_and_post_outcomes",
		selectableOptionManagement: {
			root: "/portal/admin/selectable_option_management",
			alertTypes: "/portal/admin/selectable_option_management/alert_types",
			callPurposes: "/portal/admin/selectable_option_management/call_purposes",
			cars: "/portal/admin/selectable_option_management/cars",
			deliveredPrograms: "/portal/admin/selectable_option_management/delivered_programs",
			departmentsPositions: "/portal/admin/selectable_option_management/departments-positions",
			fundingSources: "/portal/admin/selectable_option_management/funding_sources",
			genders: "/portal/admin/selectable_option_management/genders",
			languages: "/portal/admin/selectable_option_management/languages",
			ndisPlanManagers: "/portal/admin/selectable_option_management/ndis_plan_managers",
			noteTypes: "/portal/admin/selectable_option_management/note_types",
			offices: "/portal/admin/selectable_option_management/offices",
			referrers: "/portal/admin/selectable_option_management/referrers",
			relatedServices: "/portal/admin/selectable_option_management/related_services",
			serviceProviders: "/portal/admin/selectable_option_management/service_providers",
		},
		generateFundingSourceReport: {
			root: "/portal/admin/generate_funding_source_report",
		}
	},
	auditLogs: {
		root: "/portal/audit-logs",
	},
}
