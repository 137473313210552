import * as React from "react"
import { useStyletron } from "baseui"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { ListTable } from "../../../components/listTable"
import { Car } from "../../../types/types"
import { Button } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "../../../themeOverrides"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { useDebounce } from "../../../helpers/utils"
import { Value } from "baseui/select"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { useForm } from "react-hook-form"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { ErrorNotification } from "../../../components/errorBox"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const CarOptionManagement = () => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButton = css({
		textAlign: "right",
		marginRight: "8%",
	})
	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [cars, setCars] = React.useState<Car[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(20)
	const carMany = useQuery(
		fetching.query.carMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (carMany.error || !carMany.payload) return
		setTotal(carMany.payload.total)
		setCars(carMany.payload.cars)
	}, [carMany.payload, carMany.error])

	const carArchive = useMutation(fetching.mutation.carArchive)
	const carUnarchive = useMutation(fetching.mutation.carUnarchive)

	const [targetedCarID, setTargetedCarID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedCar, setSelectedCar] = React.useState<Car>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={setFilter}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Car</ZenButton>}
			</div>

			<Divider style={{ backgroundColor: "transparent" }} />

			{carMany.error && <ErrorNotification messageOrPayload={carMany.payload} />}
			{carArchive.error && <ErrorNotification messageOrPayload={carArchive.payload} />}
			{carUnarchive.error && <ErrorNotification messageOrPayload={carUnarchive.payload} />}

			<ListTable
				isLoading={carMany.loading || carArchive.loading || carUnarchive.loading}
				rows={cars}
				onRowClick={(row: Car) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedCarID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedCar(row)
				}}
				columns={[
					{
						id: "regoNumber",
						header: "Rego Number",
						resolver: (row: Car) => row.regoNumber,
					},
					{
						id: "location",
						header: "Location",
						resolver: (row: Car) => row.location,
					},
					{
						id: "make",
						header: "Make",
						resolver: (row: Car) => row.make,
					},
					{
						id: "model",
						header: "Model",
						resolver: (row: Car) => row.model,
					},
					{
						id: "Action",
						header: <div className={actionButton}>Action</div>,
						resolver: (row: Car) => (
							<div className={actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedCarID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedCarID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={carMany.loading || carArchive.loading}
												message={row.label}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													carArchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														carMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={carMany.loading || carUnarchive.loading}
												message={row.label}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													carUnarchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														carMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedCar && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<CarForm
						car={selectedCar}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedCar(undefined)
						}}
						queryCars={carMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<CarForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryCars={carMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface CarFormProps {
	car?: Car
	onClose: () => void
	queryCars: () => Promise<
		QueryResponse<{
			cars: Car[]
			total: number
		}>
	>
}
const CarForm = (props: CarFormProps) => {
	const { car, onClose, queryCars } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const labelPreview = css({
		marginTop: "10px",
		marginLeft: "16px",
		color: "grey",
		wordBreak: "break-word",
		whiteSpace: "pre-line",
	})

	const carCreate = useMutation(fetching.mutation.carCreate)
	const carUpdate = useMutation(fetching.mutation.carUpdate)

	const { control, errors, setValue, handleSubmit, watch } = useForm()

	const location = watch("location")
	const make = watch("make")
	const model = watch("model")
	const regoNumber = watch("regoNumber")

	React.useEffect(() => {
		if (!car) return
		setValue("location", car.location)
		setValue("make", car.make)
		setValue("model", car.model)
		setValue("regoNumber", car.regoNumber)
	}, [car, setValue])

	const onSubmit = (formData: any) => {
		// update existing car
		if (car) {
			carUpdate
				.mutate({
					id: car.id,
					...formData,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return
					queryCars()
					onClose()
				})
			return
		}

		// create new car
		carCreate.mutate(formData).then((resp) => {
			if (resp.error || !resp.payload) return
			queryCars()
			onClose()
		})
	}

	return (
		<ZenCard className={container}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!car ? "Update" : "Create"} Car</LabelMedium>
				<LabelSmall marginTop="10px">Label Preview</LabelSmall>
				<LabelSmall className={labelPreview}>{`${location || "N/A"} - ${make || "N/A"} - ${model || "N/A"} - ${regoNumber || "N/A"}`}</LabelSmall>
				<ZenInput label="Location" formRef={control} nameRef="location" inputError={errors.location} required />
				<ZenInput label="Make" formRef={control} nameRef="make" inputError={errors.make} required />
				<ZenInput label="Model" formRef={control} nameRef="model" inputError={errors.model} required />
				<ZenInput label="Rego Number" formRef={control} nameRef="regoNumber" inputError={errors.regoNumber} required />
				<CancelAndSaveButtons cancelFn={onClose} saveLabel={!!car ? "Save" : "Submit"} isLoading={carCreate.loading || carUpdate.loading} />
				{carCreate.error && <ErrorNotification messageOrPayload={carCreate.payload} />}
				{carUpdate.error && <ErrorNotification messageOrPayload={carUpdate.payload} />}
			</form>
		</ZenCard>
	)
}
