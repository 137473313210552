import * as React from "react"
import { getErrorMessage } from "../../../helpers/errors"
import { NDISPriceGuide, ZenDateTimeFormat } from "../../../types/types"
import moment from "moment-timezone"
import { ListTable } from "../../../components/listTable"
import { useQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { Button, KIND } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "../../../themeOverrides"
import { PortalContainer } from "../../../controllers/portal"

export const PriceGuidesList = (props: { selectedRow?: NDISPriceGuide; setSelectedRow: (row: NDISPriceGuide) => void }) => {
	const { setSelectedRow, selectedRow } = props
	const { timezone } = PortalContainer.useContainer()

	const { payload: priceGuidesPayload, loading: priceGuidesLoading, error: priceGuidesError } = useQuery(fetching.query.getNDISPriceGuides())

	// Automatically select the first (most recent) price guide once results are loaded
	React.useEffect(() => {
		if (priceGuidesPayload && !priceGuidesError && priceGuidesPayload.length > 0 && !selectedRow) {
			setSelectedRow(priceGuidesPayload[0])
		}
	}, [priceGuidesPayload, priceGuidesError, selectedRow, setSelectedRow])

	let rows: NDISPriceGuide[] = []
	if (priceGuidesPayload && !priceGuidesError) {
		rows = priceGuidesPayload
	}

	return (
		<>
			<ListTable
				error={priceGuidesError ? getErrorMessage(priceGuidesPayload) : undefined}
				isLoading={priceGuidesLoading}
				rows={rows}
				onRowClick={(row: NDISPriceGuide) => setSelectedRow(row)}
				selectedID={selectedRow ? selectedRow.id : undefined}
				columns={[
					{
						id: "Version",
						header: "Version",
						resolver: (row: NDISPriceGuide) => row.version,
					},
					{
						id: "CreatedAt",
						header: "Created At",
						resolver: (row: NDISPriceGuide) => moment(row.createdAt).tz(timezone.id).format(ZenDateTimeFormat),
					},
					{
						id: "Count",
						header: "Count",
						resolver: (row: NDISPriceGuide) => row.count,
					},
					{
						id: "Status",
						header: "Status",
						resolver: (row: NDISPriceGuide) => (row.deletedAt ? "Archived" : "Active"),
					},
					{
						id: "Download",
						header: "Download",
						resolver: (row: NDISPriceGuide) => (
							<Button
								kind={KIND.minimal}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									window.open(`/api/files/${row.blobID}`, "_blank")
								}}
							>
								<FontAwesomeIcon color={selectedRow && selectedRow.id === row.id ? undefined : ZenTheme.colors.primaryGreen} icon={["fal", "download"]} />
							</Button>
						),
					},
				]}
			/>
		</>
	)
}
