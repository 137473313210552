import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled, useStyletron } from "baseui"
import { Alert } from "baseui/icon"
import { KIND, Notification } from "baseui/notification"
import { Select, Value } from "baseui/select"
import { useDebounce } from "../helpers/utils"
import { FilterBy } from "../types/enums"
import { CheckAvailability } from "../types/types"
import { EmptyPrompt } from "./emptyPrompt"
import { ZenSearchInput } from "./zenComponents/zenInput"
import { ZenButton } from "./zenComponents/zenButtons"

export const Divider = styled("div", ({ $theme }) => ({
	width: "100%",
	height: "2px",
	backgroundColor: $theme.colors.backgroundSecondary,
	marginTop: "10px",
	borderRadius: "3px",
}))

// adds horizontal space
export const Spacer = styled("div", () => ({
	width: "5px",
	backgroundColor: "transparent",
	display: "inline-block",
	marginTop: "10px",
	borderRadius: "3px",
}))

export const ZenCard = styled("div", ({ $theme }) => ({
	display: "flex",
	flexDirection: "column",
	backgroundColor: $theme.colors.backgroundPrimary,
	boxShadow: "#0000001A 0px 0px 6px",
	borderRadius: "3px",
	padding: "11px 14px 14px 14px",
	flex: 1,
	maxWidth: "calc(min(1280px, 100%))",
	width: "100%",
	[`@media print`]: {
		padding: 0,
		boxShadow: "unset",
		backgroundColor: "white",
	},
}))

export const ZenScrollingDiv = styled("div", () => ({
	overflowY: "auto",
	overflowX: "hidden",
}))

interface SearchAndFilterProps {
	search: string
	setSearch: (value: string) => void
	filter?: Value
	setFilter?: (value: Value) => void
	alignRight?: boolean
	filterOptions?: Value
	hideFilter?: boolean
}
export const SearchAndFilter = (props: SearchAndFilterProps) => {
	const { search, setSearch, filter, setFilter, alignRight, filterOptions } = props

	const [displayKey, setDisplayKey] = React.useState<string>(search)

	const debouncedSearchTerm = useDebounce(displayKey, 500)

	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm, setSearch])

	const [css] = useStyletron()
	const containerStyle = css({
		display: "flex",
		width: "100%",
		paddingLeft: alignRight ? "20px" : "2px",
		paddingRight: "2px",
		paddingTop: "2px",
		paddingBottom: "2px",
		alignItems: "center",
	})
	const searchContainerStyle = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: "300px",
		marginLeft: alignRight ? "auto" : "unset",
	})
	return (
		<div className={containerStyle}>
			<div className={searchContainerStyle}>
				<ZenSearchInput placeholder="Search" value={displayKey} onChange={(event) => setDisplayKey(event.currentTarget.value)} />
			</div>
			{!props.hideFilter && (
				<Select
					searchable={false}
					options={
						filterOptions
							? filterOptions
							: [
									{ label: FilterBy.All, id: FilterBy.All },
									{ label: FilterBy.Active, id: FilterBy.Active },
									{ label: FilterBy.Archived, id: FilterBy.Archived },
							  ]
					}
					value={filter}
					placeholder="Filter"
					onChange={(params) => {
						if (setFilter) setFilter(params.value)
					}}
					clearable={false}
					backspaceRemoves={false}
					overrides={{
						Root: {
							style: {
								maxWidth: "165px",
								marginLeft: "14px",
								borderTopColor: "#D7DAE2",
								borderBottomColor: "#D7DAE2",
								borderLeftColor: "#D7DAE2",
								borderRightColor: "#D7DAE2",
								borderWidth: "1px",
								borderRadius: "3px",
								boxShadow: "#00000029 0px 2px 3px",
							},
						},
						InputContainer: {
							style: {
								width: "fit-content",
							},
						},
						ValueContainer: {
							style: {
								height: "24px",
								fontSize: "14px",
								fontWeight: 600,
								lineHeight: "16px",
								paddingTop: "0",
								paddingBottom: "0",
								paddingLeft: "6px",
							},
						},
						SelectArrow: {
							component: () => <FontAwesomeIcon fontWeight="bold" color="#A4AFB7" icon={["fal", "chevron-down"]} size="xs" />,
						},
						OptionContent: {
							style: {
								textTransform: "capitalize",
								whiteSpace: "nowrap",
							},
						},
						DropdownContainer: {
							style: {
								width: "unset",
								minWidth: "122px",
							},
						},
					}}
				/>
			)}
		</div>
	)
}

export const NoDataText = (props: { label?: string }) => {
	const { label } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
		backgroundColor: "transparent",
	})
	return (
		<div className={container}>
			<EmptyPrompt
				style={{
					color: "rgba(0,0,0,0.3)",
				}}
				icon={<Alert size={32} />}
				title={label || "No results"}
				titleSize="s"
			/>
		</div>
	)
}
interface AvailableCheckProps {
	isAvailable: CheckAvailability

	// following parameter is for single session create form only
	isForceCreate?: boolean
	SetIsForceCreate?: React.Dispatch<React.SetStateAction<boolean>>
	question?: string
	confirmMsg?: string
}
export const AvailableCheck = (props: AvailableCheckProps) => {
	const { isAvailable, isForceCreate, SetIsForceCreate, question, confirmMsg } = props
	const notificationKind = isForceCreate ? KIND.warning : isAvailable.available ? KIND.positive : KIND.negative

	const forceCreateMsg = () => {
		if (isForceCreate === undefined || !SetIsForceCreate || !question || !confirmMsg) return null

		// display question with checkbox
		if (!isForceCreate) {
			return (
				<>
					<br />
					<br />
					<ZenButton
						altKind="tertiary"
						onClick={() => {
							SetIsForceCreate(true)
						}}
					>
						{question}
					</ZenButton>
				</>
			)
		}
		return (
			<>
				<br />
				<br />
				{confirmMsg}
			</>
		)
	}

	return (
		<div>
			<Notification
				kind={notificationKind}
				overrides={{
					Body: {
						style: {
							width: "100%",
						},
					},
				}}
			>
				{isAvailable.message}
				{forceCreateMsg()}
			</Notification>
		</div>
	)
}

interface SubSectionProps {
	children: JSX.Element | JSX.Element[]
}

export const SubSection = ({ children }: SubSectionProps) => {
	const [css] = useStyletron()
	const subSection = css({
		marginLeft: "20px",
		marginTop: "15px",
		marginBottom: "15px",
	})

	return <div className={subSection}>{children}</div>
}

export const CommonFilterOptions: Value = [
	{ label: FilterBy.All, id: FilterBy.All },
	{ label: FilterBy.Active, id: FilterBy.Active },
	{ label: FilterBy.Archived, id: FilterBy.Archived },
]
