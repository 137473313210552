import * as React from "react"
import { useStyletron } from "baseui"
import { Switch, Route } from "react-router-dom"
import { SessionUpcomingTable } from "../../components/sessions/sessionUpcomingTable"
import { SingleSessionBaseForm } from "../../components/sessions/newClientAppointment/singleSessionBaseForm"
import { GroupSessionBaseForm } from "../../components/sessions/newGroupAppointment/groupSessionBaseForm"
import { routes } from "routes"

import { Responsive, WidthProvider } from "react-grid-layout"

const ResponsiveGridLayout = WidthProvider(Responsive)

export const SessionCreateAppointment = () => {
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		maxHeight: "100%",
		width: "100%",
		overflowY: "auto",
	})

	return (
		<div className={container}>
			<ResponsiveGridLayout style={{ width: "100%" }} layouts={layouts}>
				<div key="session-form">
					<Switch>
						<Route path={routes.sessions.create.group} component={GroupSessionBaseForm} />
						<Route path={routes.sessions.create.single.root} component={SingleSessionBaseForm} />
					</Switch>
				</div>
				<div key="session-upcoming-table">
					<SessionUpcomingTable width="100%" />
				</div>
			</ResponsiveGridLayout>
		</div>
	)
}

const layouts = {
	lg: [
		{
			w: 7,
			h: 6,
			x: 0,
			y: 0,
			i: "session-form",
			moved: false,
			static: true,
		},
		{
			w: 5,
			h: 6,
			x: 7,
			y: 0,
			i: "session-upcoming-table",
			moved: false,
			static: true,
		},
	],
	md: [
		{
			w: 6,
			h: 5,
			x: 0,
			y: 0,
			i: "session-form",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 6,
			y: 0,
			i: "session-upcoming-table",
			moved: false,
			static: true,
		},
	],
	sm: [
		{
			w: 6,
			h: 5,
			x: 0,
			y: 3,
			i: "session-form",
			moved: false,
			static: true,
		},
		{
			w: 6,
			h: 3,
			x: 0,
			y: 0,
			i: "session-upcoming-table",
			moved: false,
			static: true,
		},
	],
}
