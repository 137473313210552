import * as React from "react"
import { useStyletron } from "baseui"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { useDebounce } from "../../../helpers/utils"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { NDISLineItemManager } from "../../../types/types"
import { Value } from "baseui/select"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ListTable } from "../../../components/listTable"
import { Button } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "../../../themeOverrides"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { LabelMedium } from "baseui/typography"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { ErrorNotification } from "../../../components/errorBox"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { useForm } from "react-hook-form"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { ZenModal } from "../../../components/zenComponents/zenModal"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const NDISPlanManagerOptionManagement = () => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [planManagers, setPlanManagers] = React.useState<NDISLineItemManager[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(20)

	const planManagerMany = useQuery(
		fetching.query.getNDISPlanManagerMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			childOnly: true,
		}),
	)
	React.useEffect(() => {
		if (planManagerMany.error || !planManagerMany.payload) return
		setTotal(planManagerMany.payload.total)
		setPlanManagers(planManagerMany.payload.planManagers)
	}, [planManagerMany.payload, planManagerMany.error])

	const planManagerArchive = useMutation(fetching.mutation.ndisPlanManagerArchive)
	const planManagerUnarchive = useMutation(fetching.mutation.ndisPlanManagerUnarchive)

	const [targetedPlanManagerID, setTargetedPlanManagerID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedPlanManager, setSelectedPlanManager] = React.useState<NDISLineItemManager>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={setFilter}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New NDIS Plan Manager</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			<ListTable
				isLoading={planManagerMany.loading || planManagerArchive.loading || planManagerUnarchive.loading}
				rows={planManagers}
				onRowClick={(row: NDISLineItemManager) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedPlanManagerID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedPlanManager(row)
				}}
				columns={[
					{
						id: "label",
						header: "NDIS Plan Manager",
						resolver: (row: NDISLineItemManager) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButton}>Action</div>,
						resolver: (row: NDISLineItemManager) => (
							<div className={actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedPlanManagerID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedPlanManagerID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={planManagerArchive.loading || planManagerMany.loading}
												message={row.label}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													planManagerArchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														planManagerMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={planManagerUnarchive.loading || planManagerMany.loading}
												message={row.label}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													planManagerUnarchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														planManagerMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedPlanManager && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<PlanManagerForm
						planManager={selectedPlanManager}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedPlanManager(undefined)
						}}
						queryPlanManagers={planManagerMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<PlanManagerForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryPlanManagers={planManagerMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface PlanManagerFormProps {
	planManager?: NDISLineItemManager
	onClose: () => void
	queryPlanManagers: () => Promise<
		QueryResponse<{
			planManagers: NDISLineItemManager[]
			total: number
		}>
	>
}
const PlanManagerForm = (props: PlanManagerFormProps) => {
	const { planManager, onClose, queryPlanManagers } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedPlanManager, setDuplicatedPlanManager] = React.useState<NDISLineItemManager>()

	const planManagerCreate = useMutation(fetching.mutation.ndisPlanManagerCreate)
	const planManagerUpdate = useMutation(fetching.mutation.ndisPlanManagerUpdate)
	const planManagerUnarchive = useMutation(fetching.mutation.ndisPlanManagerUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!planManager) return
		setValue("label", planManager.label)
	}, [planManager, setValue])

	const onSubmit = (formData: any) => {
		// update existing planManager
		if (planManager) {
			// close modal if the planManager name is unchanged
			if (planManager.label === formData.label) {
				onClose()
				return
			}

			planManagerUpdate
				.mutate({
					id: planManager.id,
					...formData,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated planManager
					if (typeof resp.payload === "object") {
						setDuplicatedPlanManager(resp.payload)
						return
					}

					queryPlanManagers()
					onClose()
				})
			return
		}

		// create new planManager
		planManagerCreate.mutate(formData).then((resp) => {
			if (resp.error || !resp.payload) return
			// if contain duplicated planManager
			if (typeof resp.payload === "object") {
				setDuplicatedPlanManager(resp.payload)
				return
			}

			queryPlanManagers()
			onClose()
		})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated planManager after submit
		if (duplicatedPlanManager && !!duplicatedPlanManager.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The NDIS plan manager "{duplicatedPlanManager.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedPlanManager(undefined)}
						saveFn={() =>
							planManagerUnarchive.mutate(duplicatedPlanManager.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryPlanManagers()
								onClose()
							})
						}
						isLoading={planManagerUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{planManagerUnarchive.error && <ErrorNotification messageOrPayload={planManagerUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!planManager ? "Rename" : "Create"} NDIS Plan Manager</LabelMedium>
				<ZenInput label="Name" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!planManager ? "Save" : "Submit"}
					isLoading={planManagerCreate.loading || planManagerUpdate.loading}
				/>
				{duplicatedPlanManager && <ErrorNotification message={`The planManager "${duplicatedPlanManager.label}" is already exists`} />}
				{planManagerCreate.error && <ErrorNotification messageOrPayload={planManagerCreate.payload} />}
				{planManagerUpdate.error && <ErrorNotification messageOrPayload={planManagerUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
