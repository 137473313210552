import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Value } from "baseui/select"
import { LabelLarge, LabelMedium } from "baseui/typography"
import * as React from "react"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { fetching } from "../../../fetching"
import { useDebounce } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { AlertType } from "../../../types/types"
import { PlaceholderPanel } from "./placeholderPanel"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const AlertTypeOptionManagement = () => {
	const [css] = useStyletron()
	const outer = css({
		display: "flex",
		flexDirection: "column",
		maxWidth: "calc(min(1280px, 100%))",
		width: "100%",
		height: "100%",
	})
	const [selectedParentAlertType, setSelectedParentAlertType] = React.useState<AlertType>()

	const displayAlertSubtype = () => {
		if (!selectedParentAlertType) return <PlaceholderPanel height="50%" text="Please select a alert type to view alert subtypes" />
		return <AlertSubTypes selectedParentAlertType={selectedParentAlertType} />
	}
	return (
		<div className={outer}>
			<AlertTypes selectedParentAlertType={selectedParentAlertType} setSelectedParentAlertType={setSelectedParentAlertType} />
			<Divider />
			{displayAlertSubtype()}
		</div>
	)
}

interface AlertTypeProps {
	selectedParentAlertType: AlertType | undefined
	setSelectedParentAlertType: React.Dispatch<React.SetStateAction<AlertType | undefined>>
}
const AlertTypes = (props: AlertTypeProps) => {
	const { selectedParentAlertType, setSelectedParentAlertType } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [alertTypes, setAlertTypes] = React.useState<AlertType[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, name: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const alertTypeMany = useQuery(
		fetching.query.getAlertTypeMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (alertTypeMany.error || !alertTypeMany.payload) return
		setTotal(alertTypeMany.payload.total)
		setAlertTypes(alertTypeMany.payload.alertTypes)
	}, [alertTypeMany.payload, alertTypeMany.error])

	const alertTypeArchive = useMutation(fetching.mutation.alertTypeArchive)
	const alertTypeUnarchive = useMutation(fetching.mutation.alertTypeUnarchive)

	const [targetedAlertTypeID, setTargetedAlertTypeID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedAlertType, setSelectedAlertType] = React.useState<AlertType>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						setSelectedParentAlertType(undefined)
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Alert Type</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{alertTypeMany.error && <ErrorNotification messageOrPayload={alertTypeMany.payload} />}
			{alertTypeArchive.error && <ErrorNotification messageOrPayload={alertTypeArchive.payload} />}
			{alertTypeUnarchive.error && <ErrorNotification messageOrPayload={alertTypeUnarchive.payload} />}
			<ListTable
				isLoading={alertTypeMany.loading || alertTypeArchive.loading || alertTypeUnarchive.loading}
				rows={alertTypes}
				selectedID={selectedParentAlertType?.id}
				onRowClick={(row: AlertType) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedAlertTypeID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setSelectedParentAlertType(row)
				}}
				columns={[
					{
						id: "name",
						header: "Alert Type",
						resolver: (row: AlertType) => row.name,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: AlertType) => {
							const isHighlighted = selectedParentAlertType?.id === row.id
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									{!row.deletedAt && (
										<Button
											kind="minimal"
											onClick={(e) => {
												e.stopPropagation()
												setOpenUpdateModal(true)
												setSelectedAlertType(row)
											}}
										>
											<FontAwesomeIcon color={isHighlighted ? "white" : ZenTheme.colors.primaryGreen} size={"1x"} icon={["fal", "edit"]} />
										</Button>
									)}
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedAlertTypeID(row.id)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={isHighlighted ? "white" : row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedAlertTypeID === row.id && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={alertTypeMany.loading || alertTypeArchive.loading}
													message={row.name}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														alertTypeArchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															if (selectedParentAlertType?.id === row.id) {
																setSelectedParentAlertType(undefined)
															}
															alertTypeMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={alertTypeMany.loading || alertTypeUnarchive.loading}
													message={row.name}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														alertTypeUnarchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															alertTypeMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedAlertType && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<AlertTypeForm
						alertType={selectedAlertType}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedAlertType(undefined)
						}}
						queryAlertTypes={alertTypeMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<AlertTypeForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryAlertTypes={alertTypeMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface AlertSubTypeProps {
	selectedParentAlertType: AlertType
}
const AlertSubTypes = (props: AlertSubTypeProps) => {
	const { selectedParentAlertType } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [alertSubtypes, setAlertSubtypes] = React.useState<AlertType[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, name: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const alertSubtypeMany = useQuery(
		fetching.query.getAlertSubtypeMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			alertTypeID: selectedParentAlertType.id,
		}),
	)
	React.useEffect(() => {
		if (alertSubtypeMany.error || !alertSubtypeMany.payload) return
		setTotal(alertSubtypeMany.payload.total)
		setAlertSubtypes(alertSubtypeMany.payload.alertSubtypes)
	}, [alertSubtypeMany.payload, alertSubtypeMany.error])

	const alertSubtypeArchive = useMutation(fetching.mutation.alertSubtypeArchive)
	const alertSubtypeUnarchive = useMutation(fetching.mutation.alertSubtypeUnarchive)

	const [targetedAlertTypeID, setTargetedAlertTypeID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedAlertType, setSelectedAlertType] = React.useState<AlertType>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelLarge>{selectedParentAlertType.name} - Alert Subtypes</LabelLarge>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Alert Subtype</ZenButton>}
			</div>
			<SearchAndFilter
				search={displayKey}
				setSearch={setDisplayKey}
				filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
				filter={filter}
				setFilter={setFilter}
			/>
			<Divider style={{ backgroundColor: "transparent" }} />
			{alertSubtypeMany.error && <ErrorNotification messageOrPayload={alertSubtypeMany.payload} />}
			{alertSubtypeArchive.error && <ErrorNotification messageOrPayload={alertSubtypeArchive.payload} />}
			{alertSubtypeUnarchive.error && <ErrorNotification messageOrPayload={alertSubtypeUnarchive.payload} />}
			<ListTable
				isLoading={alertSubtypeMany.loading || alertSubtypeArchive.loading || alertSubtypeUnarchive.loading}
				rows={alertSubtypes}
				onRowClick={(row: AlertType) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedAlertTypeID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedAlertType(row)
				}}
				columns={[
					{
						id: "name",
						header: "Alert Subtype",
						resolver: (row: AlertType) => row.name,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: AlertType) => (
							<div className={row.deletedAt ? actionButtonHeader : actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedAlertTypeID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedAlertTypeID === row.id && (
									<>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={alertSubtypeMany.loading || alertSubtypeArchive.loading}
												message={row.name}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													alertSubtypeArchive.mutate({ id: row.id, parentAlertTypeID: selectedParentAlertType.id }).then((resp) => {
														if (resp.error || !resp.payload) return
														alertSubtypeMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={alertSubtypeMany.loading || alertSubtypeUnarchive.loading}
												message={row.name}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													alertSubtypeUnarchive.mutate({ id: row.id, parentAlertTypeID: selectedParentAlertType.id }).then((resp) => {
														if (resp.error || !resp.payload) return
														alertSubtypeMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedAlertType && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<AlertTypeForm
						alertType={selectedAlertType}
						parentAlertTypeID={selectedParentAlertType.id}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedAlertType(undefined)
						}}
						queryAlertTypes={alertSubtypeMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<AlertTypeForm
						parentAlertTypeID={selectedParentAlertType.id}
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryAlertTypes={alertSubtypeMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface AlertTypeFormProps {
	alertType?: AlertType
	parentAlertTypeID?: string
	onClose: () => void
	queryAlertTypes: () =>
		| Promise<
				QueryResponse<{
					alertTypes: AlertType[]
					total: number
				}>
		  >
		| Promise<
				QueryResponse<{
					alertSubtypes: AlertType[]
					total: number
				}>
		  >
}
const AlertTypeForm = (props: AlertTypeFormProps) => {
	const { alertType, parentAlertTypeID, onClose, queryAlertTypes } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedAlertType, setDuplicatedAlertType] = React.useState<AlertType>()

	const alertTypeCreate = useMutation(fetching.mutation.alertTypeCreate)
	const alertTypeUpdate = useMutation(fetching.mutation.alertTypeUpdate)
	const alertTypeUnarchive = useMutation(fetching.mutation.alertTypeUnarchive)

	const alertSubtypeCreate = useMutation(fetching.mutation.alertSubtypeCreate)
	const alertSubtypeUpdate = useMutation(fetching.mutation.alertSubtypeUpdate)
	const alertSubtypeUnarchive = useMutation(fetching.mutation.alertSubtypeUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!alertType) return
		setValue("name", alertType.name)
	}, [alertType, setValue])

	const onSubmit = (formData: any) => {
		if (alertType?.name === formData.name) {
			onClose()
			return
		}

		if (parentAlertTypeID) {
			handleAlertSubType(parentAlertTypeID, formData)
			return
		}

		handleAlertType(formData)
	}

	// handle create/update alert type
	const handleAlertType = (formData: any) => {
		// update existing alertType
		if (alertType) {
			alertTypeUpdate
				.mutate({
					id: alertType.id,
					name: formData.name,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated alertType
					if (typeof resp.payload === "object") {
						setDuplicatedAlertType(resp.payload)
						return
					}

					queryAlertTypes()
					onClose()
				})
			return
		}

		// create new alertType
		alertTypeCreate
			.mutate({
				name: formData.name,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated alertType
				if (typeof resp.payload === "object") {
					setDuplicatedAlertType(resp.payload)
					return
				}

				queryAlertTypes()
				onClose()
			})
	}

	// handle create/update alert subtype
	const handleAlertSubType = (parentAlertTypeID: string, formData: any) => {
		// update existing alertSubtype
		if (alertType) {
			alertSubtypeUpdate
				.mutate({
					id: alertType.id,
					name: formData.name,
					parentAlertTypeID,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated alertSubtype
					if (typeof resp.payload === "object") {
						setDuplicatedAlertType(resp.payload)
						return
					}

					queryAlertTypes()
					onClose()
				})
			return
		}

		// create new alertSubtype
		alertSubtypeCreate
			.mutate({
				name: formData.name,
				parentAlertTypeID,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated alertSubtype
				if (typeof resp.payload === "object") {
					setDuplicatedAlertType(resp.payload)
					return
				}

				queryAlertTypes()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated alertType after submit
		if (duplicatedAlertType && !!duplicatedAlertType.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The alert {parentAlertTypeID ? "subtype" : "type"} "{duplicatedAlertType.name}" is already exists, but it had been archived. Do you want to restore
						it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedAlertType(undefined)}
						isLoading={alertSubtypeUnarchive.loading || alertTypeUnarchive.loading}
						saveFn={() => {
							// handle alert subtype
							if (parentAlertTypeID) {
								alertSubtypeUnarchive.mutate({ id: duplicatedAlertType.id, parentAlertTypeID }).then((resp) => {
									if (resp.error || !resp.payload) return
									queryAlertTypes()
									onClose()
								})
								return
							}

							// handle alert type
							alertTypeUnarchive.mutate(duplicatedAlertType.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryAlertTypes()
								onClose()
							})
						}}
						saveLabel={"Confirm"}
					/>
					{alertTypeUnarchive.error && <ErrorNotification messageOrPayload={alertTypeUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>
					{!!alertType ? "Update" : "Create"} Alert {parentAlertTypeID ? " Subtype" : "Type"}
				</LabelMedium>
				<ZenInput label="Name" formRef={control} nameRef="name" inputError={errors.name} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!alertType ? "Save" : "Submit"}
					isLoading={alertTypeCreate.loading || alertTypeUpdate.loading || alertSubtypeCreate.loading || alertSubtypeUpdate.loading}
				/>
				{duplicatedAlertType && (
					<ErrorNotification message={`The alert ${parentAlertTypeID ? "subtype" : "type"} "${duplicatedAlertType.name}" is already exists`} />
				)}
				{alertTypeCreate.error && <ErrorNotification messageOrPayload={alertTypeCreate.payload} />}
				{alertTypeUpdate.error && <ErrorNotification messageOrPayload={alertTypeUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
