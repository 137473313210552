import * as React from "react"
import { LabelLarge } from "baseui/typography"
import { useForm } from "react-hook-form"
import { useStyletron } from "styletron-react"

import { Divider, Spacer, ZenCard } from "../../components/common"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenSelect } from "../../components/zenComponents/zenSelectBox"
import { Option } from "baseui/select"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { fetching } from "fetching"
import { ZenDatePicker } from "components/zenComponents/zenTime"
import moment from "moment"



enum FilterByOptions {
	// AllClients = "ALL_CLIENTS",
	ActiveClientsOnly = "ACTIVE_CLIENTS_ONLY",
	ArchivedClientsOnly = "ARCHIVE_CLIENTS_ONLY",
	Sessions = "SESSIONS",
}

enum FormFields {
	FilterBy = "filterBy",
	FundingSource = "fundingSource",
	ContractArea = "contractArea",
	StartDate = "startDate",
	EndDate = "endDate",
}

export const Demographics = () => {
	const { control, handleSubmit, watch, setValue, getValues, errors } = useForm()

	const [css] = useStyletron()
	const card = css({
		height: "fit-content",
		width: "fit-content",
	})
	const title = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	})
	const heading = css({
		flexGrow: 1,
	})
	const filter = css({
		width: "400px",
	})
	const buttonRow = css({
		display: "flex",
		justifyContent: "flex-end",
		padding: "10px",
	})
	const group = css({
		display: "flex",
		justifyContent: "space-between",
	})

	const fundingSourceAll = useQuery(fetching.query.getFundingSourceAll(false, true))
	const contractAreaList = useParameterizedQuery(fetching.query.getContractAreasByFundingSourceID)
	const [contractAreaOptions, setContractAreaOptions] = React.useState<Option[]>([])
	const getContractAreaOptions = (fundingSourceID: string) => {
		contractAreaList.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setContractAreaOptions(resp.payload)
		})
	}

	const filterBy: any | undefined = watch(FormFields.FilterBy)
	const fundingSource: any | undefined = watch(FormFields.FundingSource)
	const contractArea: any | undefined = watch(FormFields.ContractArea)
	const startDate: any | undefined = watch(FormFields.StartDate)
	const endDate: any | undefined = watch(FormFields.EndDate)

	const submitHandler = handleSubmit((input) => {
		if (!input.filterBy || input.filterBy.length === 0) return

		let bodyRequest = {
			filterBy: input.filterBy[0].id,
			fundingSource: null,
			contractArea: null,
			startDate: startDate,
			endDate: endDate,
		}
		if (filterBy && (filterBy as Option[]).some((f) => f?.id !== FilterByOptions.Sessions)) {
			if (fundingSource && (fundingSource as Option[]).length === 1) {
				bodyRequest.fundingSource = fundingSource[0].id
			}
			if (contractArea && (contractArea as Option[]).length === 1) {
				bodyRequest.contractArea = contractArea[0].id
			}
		}

		fetch("/api/clients/generate_demographics_xlsx", {
			method: "post",
			body: JSON.stringify(bodyRequest),
		})
			.then((response) => {
				response.blob().then((blob) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement("a")
					a.href = url
					a.download = `Demographics_${input.filterBy[0].label.replace(/ /g, "_")}.xlsx`
					a.click()
				})
			})
			.catch((e) => {
				console.error(e)
			})
	})

	return (
		<ZenCard className={card}>
			<form autoComplete="off" className={title} onSubmit={submitHandler}>
				<div className={heading}>
					<LabelLarge>Demographics</LabelLarge>
					<Divider />
					<div className={filter}>
						<ZenSelect
							formName={FormFields.FilterBy}
							label="Demographics for"
							formRef={control}
							required
							options={[
								//{ id: FilterByOptions.AllClients, label: "All clients" },
								{ id: FilterByOptions.Sessions, label: "All clients" },
								{ id: FilterByOptions.ActiveClientsOnly, label: "Active clients only" },
								{ id: FilterByOptions.ArchivedClientsOnly, label: "Archived clients only" },
							]}
							actionOnChange={(v) => {
								if (v && v?.length === 1 && v[0]?.id === FilterByOptions.Sessions) {
									setValue(FormFields.FundingSource, [])
									setValue(FormFields.ContractArea, [])
									setContractAreaOptions([])
									setValue(FormFields.StartDate, null)
									setValue(FormFields.EndDate, null)
								}
							}}
						/>
						<div className={group}>
							<div>
								<ZenDatePicker
									inputError={errors.startDate}
									nullDefaultValue={true}
									formName={FormFields.StartDate}
									label="Start Date"
									formRef={control}
									formRules={{
										required: "You must select a start date.",
									}}
								/>
							</div>
							<Spacer style={{ width: 15 }} />
							<div>
								<ZenDatePicker
									inputError={errors.endDate}
									nullDefaultValue={true}
									formName={FormFields.EndDate}
									label="End Date"
									formRef={control}
									formRules={{
										required: "You must select an end date.",
										validate: (value: string) => {
											if (moment(value).isBefore(moment(getValues("startDate")))) {
												return "End date must be after start date."
											}
											return null
										},
									}}
								/>
							</div>
						</div>
						{
							(filterBy && (filterBy as Option[]).some((f) => f?.id !== FilterByOptions.Sessions)) && (
								<>
									<ZenSelect
										options={fundingSourceAll.payload || []}
										label="Funding Source (Optional)"
										formName={FormFields.FundingSource}
										formRef={control}
										actionOnChange={(v) => {
											// clear up all the option
											setContractAreaOptions([])
											setValue(FormFields.ContractArea, [])
											if (v.length === 0 || !v[0].id) return
											const fundingSourceID = v[0].id.toString()
											getContractAreaOptions(fundingSourceID)
										}}
									/>
									<ZenSelect
										options={contractAreaOptions}
										label="Contract Area (Optional)"
										formName={"contractArea"}
										formRef={control}
										disabled={contractAreaOptions.length === 0}
									/>
								</>
							)
						}
					</div>
				</div>
				<div className={buttonRow}>
					<ZenButton type="submit">Export</ZenButton>
				</div>
			</form>
		</ZenCard>
	)
}
