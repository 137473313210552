import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Value } from "baseui/select"
import { LabelMedium } from "baseui/typography"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { fetching } from "../../../fetching"
import { useDebounce } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { RelatedService } from "../../../types/types"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const RelatedServiceOptionManagement = () => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [relatedServices, setRelatedServices] = React.useState<RelatedService[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, name: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(20)

	const relatedServiceMany = useQuery(
		fetching.query.getRelatedServiceMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (relatedServiceMany.error || !relatedServiceMany.payload) return
		setTotal(relatedServiceMany.payload.total)
		setRelatedServices(relatedServiceMany.payload.relatedServices)
	}, [relatedServiceMany.payload, relatedServiceMany.error])

	const relatedServiceArchive = useMutation(fetching.mutation.relatedServiceArchive)
	const relatedServiceUnarchive = useMutation(fetching.mutation.relatedServiceUnarchive)

	const [targetedRelatedServiceID, setTargetedRelatedServiceID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedRelatedService, setSelectedRelatedService] = React.useState<RelatedService>()
	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={setFilter}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Related Service</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{relatedServiceMany.error && <ErrorNotification messageOrPayload={relatedServiceMany.payload} />}
			{relatedServiceArchive.error && <ErrorNotification messageOrPayload={relatedServiceArchive.payload} />}
			{relatedServiceUnarchive.error && <ErrorNotification messageOrPayload={relatedServiceUnarchive.payload} />}
			<ListTable
				isLoading={relatedServiceMany.loading || relatedServiceArchive.loading || relatedServiceUnarchive.loading}
				rows={relatedServices}
				onRowClick={(row: RelatedService) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedRelatedServiceID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedRelatedService(row)
				}}
				columns={[
					{
						id: "name",
						header: "Related Service",
						resolver: (row: RelatedService) => row.name,
					},
					{
						id: "Action",
						header: <div className={actionButton}>Action</div>,
						resolver: (row: RelatedService) => (
							<div className={actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedRelatedServiceID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedRelatedServiceID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={relatedServiceArchive.loading || relatedServiceMany.loading}
												message={row.name}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													relatedServiceArchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														relatedServiceMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={relatedServiceUnarchive.loading || relatedServiceMany.loading}
												message={row.name}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													relatedServiceUnarchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														relatedServiceMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedRelatedService && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<RelatedServiceForm
						relatedService={selectedRelatedService}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedRelatedService(undefined)
						}}
						queryRelatedServices={relatedServiceMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<RelatedServiceForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryRelatedServices={relatedServiceMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface RelatedServiceFormProps {
	relatedService?: RelatedService
	onClose: () => void
	queryRelatedServices: () => Promise<
		QueryResponse<{
			relatedServices: RelatedService[]
			total: number
		}>
	>
}
const RelatedServiceForm = (props: RelatedServiceFormProps) => {
	const { relatedService, onClose, queryRelatedServices } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedRelatedService, setDuplicatedRelatedService] = React.useState<RelatedService>()

	const relatedServiceCreate = useMutation(fetching.mutation.relatedServiceCreate)
	const relatedServiceUpdate = useMutation(fetching.mutation.relatedServiceUpdate)
	const relatedServiceUnarchive = useMutation(fetching.mutation.relatedServiceUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!relatedService) return
		setValue("name", relatedService.name)
	}, [relatedService, setValue])

	const onSubmit = (formData: any) => {
		// update existing relatedService
		if (relatedService) {
			// close modal if the relatedService name is unchanged
			if (relatedService.name === formData.name) {
				onClose()
				return
			}

			relatedServiceUpdate
				.mutate({
					id: relatedService.id,
					...formData,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated relatedService
					if (typeof resp.payload === "object") {
						setDuplicatedRelatedService(resp.payload)
						return
					}

					queryRelatedServices()
					onClose()
				})
			return
		}

		// create new relatedService
		relatedServiceCreate.mutate(formData).then((resp) => {
			if (resp.error || !resp.payload) return
			// if contain duplicated relatedService
			if (typeof resp.payload === "object") {
				setDuplicatedRelatedService(resp.payload)
				return
			}

			queryRelatedServices()
			onClose()
		})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated relatedService after submit
		if (duplicatedRelatedService && !!duplicatedRelatedService.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The related service "{duplicatedRelatedService.name}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedRelatedService(undefined)}
						saveFn={() =>
							relatedServiceUnarchive.mutate(duplicatedRelatedService.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryRelatedServices()
								onClose()
							})
						}
						isLoading={relatedServiceUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{relatedServiceUnarchive.error && <ErrorNotification messageOrPayload={relatedServiceUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!relatedService ? "Rename" : "Create"} Related Service</LabelMedium>
				<ZenInput name="Name" formRef={control} nameRef="name" inputError={errors.name} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!relatedService ? "Save" : "Submit"}
					isLoading={relatedServiceCreate.loading || relatedServiceUpdate.loading}
				/>
				{duplicatedRelatedService && <ErrorNotification message={`The relatedService "${duplicatedRelatedService.name}" is already exists`} />}
				{relatedServiceCreate.error && <ErrorNotification messageOrPayload={relatedServiceCreate.payload} />}
				{relatedServiceUpdate.error && <ErrorNotification messageOrPayload={relatedServiceUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
