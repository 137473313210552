import * as React from "react"
import { BlockProps } from "baseui/block"
import { useStyletron } from "baseui"
import { LabelSmall } from "baseui/typography"
import { ZenTheme } from "../themeOverrides"

interface FieldDisplayProps extends BlockProps {
	label: string
}
export const FieldDisplay = (props: FieldDisplayProps) => {
	const { label, marginTop, children } = props
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginTop: marginTop?.toString() || 0,
	})
	return (
		<div className={container}>
			<LabelSmall marginBottom="5px" color={ZenTheme.colors.primaryGrey}>
				{label}
			</LabelSmall>
			{children}
		</div>
	)
}
