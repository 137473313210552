import * as React from "react"
import { useStyletron } from "baseui"
import { AuthContainer } from "controllers/auth"
import { useQuery } from "react-fetching-library"
import { Responsive, WidthProvider } from "react-grid-layout"
import { RouteComponentProps } from "react-router-dom"
import { ErrorNotification } from "../../components/errorBox"
import { Loading } from "../../components/loading"
import { AssignedClients } from "../../components/workers/assignedClient"
import { WorkerDetail } from "../../components/workers/workerDetail"
import { WorkerSession } from "../../components/workers/workerSession"
import { WorkerTravel } from "../../components/workers/workerTravel"
import { query } from "../../fetching/query"
import { RolePermission, UserDetail } from "../../types/types"
import { Claims } from "./claims"

const ResponsiveGridLayout = WidthProvider(Responsive)

export const WorkerView = (props: RouteComponentProps<{ workerID: string }>) => {
	const id = props.match.params.workerID
	const [css] = useStyletron()

	const { error, loading, payload, query: workerRefetch } = useQuery<UserDetail>(query.getUser(id))

	const { hasPermission } = AuthContainer.useContainer()

	const container = css({
		display: "flex",
		maxHeight: "100%",
		width: "100%",
		overflowY: "auto",
	})

	if (!payload || loading) return <Loading />
	if (error) return <ErrorNotification messageOrPayload={payload} />

	return (
		<div className={container}>
			<ResponsiveGridLayout style={{ width: "100%" }} layouts={layouts}>
				<div key="worker-detail">
					<WorkerDetail {...payload} />
				</div>
				<div key="worker-session">
					<WorkerSession userId={payload.id} />
				</div>
				<div key="claims">{hasPermission(RolePermission.ReportRead) && <Claims workerID={id} />}</div>
				<div key="assigned-clients">
					<AssignedClients user={payload} assignedClients={payload.assignedClients} workerRefetch={workerRefetch} />
				</div>
				<div key="worker-travel">
					<WorkerTravel {...payload} />
				</div>
			</ResponsiveGridLayout>
		</div>
	)
}

const layouts = {
	lg: [
		{
			w: 3,
			h: 4,
			x: 0,
			y: 0,
			i: "worker-detail",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 4,
			x: 3,
			y: 0,
			i: "worker-session",
			moved: false,
			static: true,
		},
		{
			w: 5,
			h: 4,
			x: 6,
			y: 0,
			i: "claims",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 3,
			x: 0,
			y: 4,
			i: "assigned-clients",
			moved: false,
			static: true,
		},
		{
			w: 7,
			h: 3,
			x: 4,
			y: 4,
			i: "worker-travel",
			moved: false,
			static: true,
		},
	],
	md: [
		{
			w: 5,
			h: 4,
			x: 0,
			y: 0,
			i: "worker-detail",
			moved: false,
			static: true,
		},
		{
			w: 5,
			h: 4,
			x: 6,
			y: 0,
			i: "worker-session",
			moved: false,
			static: true,
		},
		{
			w: 10,
			h: 4,
			x: 0,
			y: 4,
			i: "claims",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 3,
			x: 0,
			y: 8,
			i: "assigned-clients",
			moved: false,
			static: true,
		},
		{
			w: 7,
			h: 3,
			x: 4,
			y: 8,
			i: "worker-travel",
			moved: false,
			static: true,
		},
	],
	sm: [
		{
			w: 3,
			h: 3,
			x: 0,
			y: 0,
			i: "worker-detail",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 3,
			x: 6,
			y: 0,
			i: "worker-session",
			moved: false,
			static: true,
		},
		{
			w: 6,
			h: 3,
			x: 0,
			y: 3,
			i: "claims",
			moved: false,
			static: true,
		},
		{
			w: 2,
			h: 3,
			x: 0,
			y: 6,
			i: "assigned-clients",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 3,
			x: 4,
			y: 6,
			i: "worker-travel",
			moved: false,
			static: true,
		},
	],
	xs: [
		{
			w: 4,
			h: 3,
			x: 0,
			y: 0,
			i: "worker-detail",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 3,
			x: 0,
			y: 3,
			i: "worker-session",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 3,
			x: 0,
			y: 6,
			i: "claims",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 3,
			x: 0,
			y: 9,
			i: "assigned-clients",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 3,
			x: 0,
			y: 12,
			i: "worker-travel",
			moved: false,
			static: true,
		},
	],
}
