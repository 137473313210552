import * as React from "react"
import { useStyletron } from "baseui"
import { ZenCard } from "../../components/common"
import { TravelForm } from "../../components/travels/travelForm"
import { useHistory } from "react-router-dom"
import { routes } from "../../routes"

export const TravelCreate = () => {
	const [css] = useStyletron()
	const container = css({
		width: "fit-content",
		height: "100%",
	})

	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])

	const onSuccess = (id: string) => {
		// return back to client session tab, if client id exists
		const returnClientID = searchArgs.get("client_id")
		if (returnClientID) {
			history.push({
				pathname: routes.withID(returnClientID, routes.clients.client.root),
				hash: "travels",
			})
			return
		}

		history.push(`/portal/travels/${id}`)
	}

	return (
		<ZenCard className={container}>
			<TravelForm onSuccess={onSuccess} />
		</ZenCard>
	)
}
