import * as React from "react"
import { useStyletron } from "baseui"
import { Notification } from "baseui/notification"
import { LabelSmall } from "baseui/typography"
import helping_minds_logo from "../assets/images/HM_TM_RGB_COLOUR_with_byline_COLOUR_with_byline.svg"
import helping_minds_logo_bg from "../assets/images/helping_minds_logo_bg.png"
import { Redirect, useLocation } from "react-router-dom"

import { ZenButton } from "../components/zenComponents/zenButtons"
import { AuthContainer } from "../controllers/auth"
import { ZenLinkStyle } from "../themeOverrides"
import { InfoBorder } from "./portal"

export default function Login() {
	const [css, theme] = useStyletron()
	const isTrainingMode = process.env.REACT_APP_TRAINING_HOST_URL === window.location.host
	const version = process.env.REACT_APP_VERSION
	const build_start = new Date(process.env.REACT_APP_BUILD_EPOCH ? Number(process.env.REACT_APP_BUILD_EPOCH) * 1000 : 0).toJSON()
	const container = css({
		minHeight: "100vh",
		width: "100%",
		background: `url(${helping_minds_logo_bg}) no-repeat center center fixed`,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "left",
		marginTop: "-100px",
		[`@media only screen and (max-width: 500px)`]: {
			marginTop: 0,
		},
	})
	const logoStyle = css({
		width: "500px",
		[`@media only screen and (max-width: 500px)`]: {
			width: "300px",
		},
	})
	const versionStyle = css({
		...theme.typography.LabelSmall,
		position: "absolute",
		right: "25px",
		bottom: "25px",
		color: theme.colors.primaryA,
		textAlign: "right",
	})
	const buildTimeStyle = css({
		color: "grey",
	})
	const link = css({
		...ZenLinkStyle,
		marginTop: "20px",
	})

	const [loading, setLoading] = React.useState(false)
	const location = useLocation()
	const searchArgs = new URLSearchParams(location.search)
	const sessionExpired = searchArgs.get("sessionExpired")
	const loginFailed = searchArgs.get("failed")
	const email = searchArgs.get("email")
	const archived = searchArgs.get("archived")

	const { currentUser, initialLoading } = AuthContainer.useContainer()
	if (currentUser && !initialLoading) {
		return <Redirect to={"/portal/"} />
	}

	const handleLogin = () => {
		setLoading(true)
		window.open("/api/auth/microsoft/login", "_self")
	}

	let loginError = "Login failed. Please try again later. If the problem persists contact your system administrator."
	if (sessionExpired) {
		loginError = "Your session has expired. Please login to continue."
	}
	if (email) {
		loginError = `Could not find user ${email}. Please contact your system administrator to set up an account.`
	}
	if (email && archived) {
		loginError = `User account ${email} has been disabled. Please contact your system administrator.`
	}

	return (
		<>
			{isTrainingMode && <InfoBorder label="TRAINING MODE" />}

			<div className={container}>
				<img className={logoStyle} src={helping_minds_logo} alt="HelpingMinds Logo" />
				{(loginFailed || sessionExpired) && (
					<Notification kind="negative" overrides={{ Body: { style: { width: "90%", maxWidth: "450px" } } }}>
						{loginError}
					</Notification>
				)}
				<ZenButton isLoading={initialLoading || loading} marginTop="20px" width={"150px"} onClick={handleLogin}>
					Login
				</ZenButton>
				<div onClick={() => window.open("https://myaccount.microsoft.com/")}>
					<LabelSmall className={link}>Change Account</LabelSmall>
				</div>

				<div className={versionStyle}>
					v{version}
					<br />
					<div className={buildTimeStyle}>{build_start || "-"}</div>
				</div>
			</div>
		</>
	)
}
