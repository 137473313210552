import React, { useContext } from "react";
import NDISUtilisationContext from "./context";
import { Spacer, ZenCard } from "components/common";
import { LabelLarge, ParagraphSmall } from "baseui/typography";
import { useStyletron } from "baseui"
import { ZenDatePicker } from "components/zenComponents/zenTime";
import moment from "moment";
import { ZenButton } from "components/zenComponents/zenButtons";
import { ZenClientSelect } from "components/zenComponents/zenSelectBox";
import { ZenSelect } from "./components/ndisUtilisationSelect"
import { FormControl } from "baseui/form-control";
import { Notification, KIND } from "baseui/notification"
import { ErrorNotification } from "components/errorBox";
import { Value } from "baseui/select"

const NDISUtilisationView = () => {
	const [css] = useStyletron()
	const card = css({
		width: "fit-content",
	});
	const dates = css({
		display: "flex",
		width: "555px",
	});
	const buttons = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	});
	const cssDiv = css({
		width: "300%",
		height: "fit-content",
	})
    const { errorMsg, values, actions, useFormHooks } = useContext(NDISUtilisationContext);
    const { 
        data, 
        contractAreaOpt, 
        prcOpt,
        coreSupportOpt,
        supportCoordOpt,
		clientOpt,
        durationWarning, 
        loading, 
		prcSupportLoading,
		coreSupportItemsLoading, 
		supportCoordinationItemsLoading,
        ndisUtilisationPayloadError 
    } = values;
    const { handleOnSubmit, setLineItemDisplayOptions, setContractArea } = actions;
    const { control, handleSubmit, errors, getValues } = useFormHooks;

    return (
        <ZenCard className={card}>
			<LabelLarge>NDIS Utilisation Report</LabelLarge>
			<ParagraphSmall maxWidth={"400px"}>Create NDIS utilisation report here</ParagraphSmall>
            <form autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
            {/* <form autoComplete="off" > */}
                <div className={dates}>
					<div className={cssDiv}>
						<ZenDatePicker
							nullDefaultValue={true}
							formRef={control}
							label={"Start Date"}
							formName={"startDate"}
							inputError={errors.startDate}
							formRules={{
								required: "You must select a start date.",
							}}
						/>
					</div>
					<Spacer style={{ width: "15px" }} />
					<div className={cssDiv}>
						{/* <ZenDatePicker
							nullDefaultValue={true}
							formRef={control}
							label={"End Date"}
							formName={"endDate"}
							inputError={errors.endDate}
							formRules={{
								required: "You must select an end date.",
								validate: (value: string) => {
									if (moment(value).isBefore(moment(getValues("startDate")))) {
										return "End date must be after start date."
									}
									return null
								},
							}}
						/> */}
					</div>
				</div>
				<ZenSelect
					multi={false}
					options={contractAreaOpt}
					label="Contract Area (Optional)"
					formName={"contact_area"}
					formRef={control}
                    inputError={errors?.contact_area}
                    // formRules={{
                    //     validate: {
                    //         required: (value: Value) => (!!value && value.length > 0) || `Contract Area is required`,
                    //     },
                    // }}
					actionOnChange={(e) => {
						return setContractArea(e?.[0]?.label)
					}}
				/>
				<ZenClientSelect 
					label={"Client"} 
					formName={"client"} 
					formRef={control} 
					inputError={errors?.client}
					actionOnChange={(e) => {
						const {firstName, lastName} = e?.[0] ?? {};
						return setLineItemDisplayOptions(`${firstName || ''} ${lastName || ''}`);
					}}
					options={clientOpt}
				/>
				<ZenSelect
					multi={true}
					options={prcOpt}
					isLoading={prcSupportLoading}
					label="PRC Support Code"
					formName={"prc_support_code"}
					formRef={control}
                    // inputError={errors?.prc_support_code}
                    // formRules={{
                    //     validate: {
                    //         required: (value: Value) => (!!value && value.length > 0) || `PRC Support Code is required`,
                    //     },
                    // }}
				/>
				<ZenSelect
					multi={true}
					options={coreSupportOpt}
					isLoading={coreSupportItemsLoading}
					label="Core Supports"
					formName={"core_supports"}
					formRef={control}
                    // inputError={errors?.core_supports}
                    // formRules={{
                    //     validate: {
                    //         required: (value: Value) => (!!value && value.length > 0) || `Core Supports is required`,
                    //     },
                    // }}
				/>
				<ZenSelect
					multi={true}
					options={supportCoordOpt}
					isLoading={supportCoordinationItemsLoading}
					label="Support Coordination"
					formName={"support_coordination"}
					formRef={control}
                    // inputError={errors?.support_coordination}
                    // formRules={{
                    //     validate: {
                    //         required: (value: Value) => (!!value && value.length > 0) || `Support Coordination is required`,
                    //     },
                    // }}
				/>
				<FormControl error={errors.client}>
					<div />
				</FormControl>
				{durationWarning && <Notification kind={KIND.warning}>{durationWarning}</Notification>}
                {ndisUtilisationPayloadError && <ErrorNotification message={errorMsg['eblobErr']} />}
                <div className={buttons}>
					<ZenButton type={"submit"} isLoading={loading}>
						Create Report
					</ZenButton>
				</div>
            </form>

        </ZenCard>
    )
};

export default NDISUtilisationView;