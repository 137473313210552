import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { Button, KIND } from "baseui/button"
import { Alert } from "baseui/icon"
import { ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Value } from "baseui/select"
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip"
import { LabelLarge, LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Route } from "react-router"
import { useHistory } from "react-router-dom"
import { routes } from "routes"
import { CancelAndSaveButtons } from "../components/cancelSaveButtons"
import { Divider, SearchAndFilter, Spacer, ZenCard } from "../components/common"
import { EmptyPrompt } from "../components/emptyPrompt"
import { ErrorNotification } from "../components/errorBox"
import EventCalendar from "../components/eventCalendar"
import { ListTable, useListTable } from "../components/listTable"
import { Loading } from "../components/loading"
import { SortBy } from "../components/workers/workerList"
import { ZenArchiveModal } from "../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../components/zenComponents/zenButtons"
import { ZenPagination } from "../components/zenComponents/zenPagination"
import { ZenUserSelect } from "../components/zenComponents/zenSelectBox"
import { AuthContainer } from "../controllers/auth"
import { fetching } from "../fetching"
import { snakeToTitle, truncate } from "../helpers/utils"
import { ZenTheme } from "../themeOverrides"
import { FilterBy } from "../types/enums"
import { RolePermission, SearchTextMinLength, SortOrder, UserWithRole } from "../types/types"

export default function TeamRoot() {
	const [css] = useStyletron()
	const history = useHistory()

	const { currentUser, hasPermission } = AuthContainer.useContainer()

	const { mutate: removeSupervisor, error: remError, loading: remLoading, payload: remPayload } = useMutation(fetching.mutation.userSupervisorRemove)

	const [existingTeamMembersId, setExistingTeamMembersId] = React.useState<string[]>([])

	// modal states
	const [showViewModal, setShowViewModal] = React.useState<"SCHEDULE" | "OFFICES" | undefined>()
	const [archiveModalOpen, setArchiveModalOpen] = React.useState(false)
	const [selectedUser, setSelectedUser] = React.useState<UserWithRole>()

	const [rows, setRows] = React.useState<UserWithRole[]>([])
	const { sortColumn, sortAsc, limit, offset, setTotal, total, setOffset, handleSort, filter, setFilter, search, setSearch, listView, setListView } =
		useListTable({
			sortColumn: SortBy.Name,
		})

	const { error, payload, loading, query } = useQuery<{
		users: UserWithRole[]
		total: number
	}>(
		fetching.query.getTeamUsers({
			search: {
				filterBy: filter[0]?.id?.toString() || FilterBy.Active,
				search: search.length >= SearchTextMinLength ? search : undefined,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
			},
			limit,
			offset,
		}),
	)

	const onAdd = () => {
		query()
	}

	const removeTeamMember = async (id: string) => {
		if (!currentUser) return
		removeSupervisor({ workerID: id, supervisorID: currentUser.id })
	}
	React.useEffect(() => {
		// When the removeSupervisor mutation is run
		if (!remPayload) return
		// re-load the team list
		query()
		// and close the modal
		setArchiveModalOpen(false)
	}, [remPayload, query])

	React.useEffect(() => {
		// Wait for loading to finish or for payload not to be empty
		if (loading || !payload || !payload.users) return
		// Then set the ui state
		setRows(payload.users)
		setTotal(payload.total)
		setExistingTeamMembersId(
			payload.users.map((u) => {
				return u.id
			}),
		)
	}, [payload, loading, error, setTotal])

	const top = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})

	const cardStyle = css({
		minWidth: "400px",
		display: "flex",
		flexDirection: "row",
		paddingLeft: 0,
		paddingBottom: "0px",
		cursor: "pointer",
		height: "auto",

		":hover": {
			backgroundColor: "#eef0f9",
		},
	})

	const col1 = css({
		width: "20%",
		paddingTop: "10px",
		marginLeft: "10px",
		paddingLeft: 0,
		marginRight: "10px",
	})
	const col2 = css({
		width: "100%",
		paddingTop: "10px",
	})
	const col3 = css({
		paddingTop: "10px",
	})
	const eventBody = css({
		height: "85%",
		fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
		padding: "20px",
	})

	const CardResolver = (row: UserWithRole) => {
		const { firstName, lastName, avatarURL, mobileNumber, deletedAt, email } = row

		return (
			<>
				<ZenCard
					className={cardStyle}
					onClick={() => {
						if (!hasPermission(RolePermission.UserRead) || deletedAt) {
							return
						}

						history.push(`/portal/workers/${row.id}`)
					}}
					style={{
						backgroundColor: deletedAt ? "#eef0f9" : "white",
						borderLeft: deletedAt ? "#eef0f9" : "4px solid " + ZenTheme.colors.primaryGreen,
						cursor: !hasPermission(RolePermission.UserRead) || deletedAt ? "default" : "pointer",
					}}
				>
					<div className={col1}>
						<Avatar
							overrides={{
								Root: {
									style: {
										minWidth: ZenTheme.sizing.scale1000,
									},
								},
								Initials: { style: { fontSize: "12px" } },
							}}
							size={"37px"}
							name={`${firstName} ${lastName}`}
							src={avatarURL || ""}
						/>
					</div>
					<div className={col2}>
						{deletedAt && (
							<>
								<LabelMedium>[ARCHIVED]</LabelMedium>
								<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />
							</>
						)}

						<LabelLarge>{`${firstName} ${lastName}`}</LabelLarge>
						<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />

						{hasPermission(RolePermission.UserRead) && (
							<>
								<LabelSmall>{mobileNumber}</LabelSmall>
								<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />
							</>
						)}

						<StatefulTooltip content={() => <LabelSmall color="white">{email}</LabelSmall>} placement={PLACEMENT.top} returnFocus autoFocus>
							<LabelSmall>{truncate(email, 20)}</LabelSmall>
						</StatefulTooltip>

						<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />
						<ZenButton
							paddingLeft="0"
							onClick={() => {
								setSelectedUser(row)
								setShowViewModal("OFFICES")
							}}
							altKind="tertiary"
						>
							View Offices
						</ZenButton>
						<ZenButton
							paddingLeft="0"
							onClick={() => {
								setSelectedUser(row)
								setShowViewModal("SCHEDULE")
							}}
							altKind="tertiary"
						>
							View Schedule
						</ZenButton>
					</div>
					<div className={col3}>
						<Button
							kind={KIND.minimal}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								setArchiveModalOpen(true)
								setSelectedUser(row)
							}}
						>
							<FontAwesomeIcon
								color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
								icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
							/>
						</Button>
					</div>
				</ZenCard>
			</>
		)
	}

	if (!currentUser) return <Loading />
	if (error) return <ErrorNotification messageOrPayload={payload} />
	if (remError) return <ErrorNotification messageOrPayload={remPayload} />

	return (
		<ZenCard>
			<div className={top}>
				{/* Search/ filter bar */}
				<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} hideFilter />

				{hasPermission(RolePermission.UserRead) && (
					<>
						{/* Toggle buttons for list / cards view */}
						<Button kind={KIND.tertiary} onClick={() => setListView(false)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
							<FontAwesomeIcon size={"lg"} color={!listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "th"]} />
						</Button>

						<Spacer />

						<Button kind={KIND.tertiary} onClick={() => setListView(true)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
							<FontAwesomeIcon size={"lg"} color={listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "bars"]} />
						</Button>
					</>
				)}
			</div>
			<ListTable
				isLoading={loading || remLoading}
				handleSort={handleSort}
				cardView={!hasPermission(RolePermission.UserRead) || !listView}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				rows={rows}
				cardViewResolver={(row: UserWithRole) => {
					return CardResolver(row)
				}}
				onRowClick={(row) => history.push(`/portal/workers/${row.id}`)}
				columns={[
					{
						id: SortBy.Name,
						header: "Name",
						resolver: (row: UserWithRole) => (
							<div>
								<div>
									{`${row.firstName} ${row.lastName} `}
									{row.deletedAt && <LabelXSmall marginLeft="10px">[ARCHIVED]</LabelXSmall>}
								</div>
							</div>
						),
						sortable: true,
					},
					{
						id: SortBy.Role,
						header: "Role",
						resolver: (row: UserWithRole) => snakeToTitle(row.role.name),
						sortable: false,
					},
					{
						id: SortBy.Number,
						header: "Number",
						resolver: (row: UserWithRole) => row.mobileNumber,
					},

					{
						id: SortBy.Email,
						sortable: false,
						header: "Email",
						resolver: (row: UserWithRole) => row.email,
					},
					{
						id: "",
						header: "",
						resolver: (row: UserWithRole) => (
							<Button
								kind={KIND.minimal}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setArchiveModalOpen(true)
									setSelectedUser(row)
								}}
							>
								<FontAwesomeIcon
									color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
									icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
								/>
							</Button>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{currentUser && (
				<Route path={routes.team.add}>
					<AddWorker supervisorID={currentUser.id} onAdd={onAdd} existingTeamMembersId={existingTeamMembersId} />
				</Route>
			)}

			{/* Schedule/ Offices modal */}
			<ZenModal
				overrides={{
					Dialog: {
						style: {
							maxWidth: showViewModal === "OFFICES" ? "400px" : "80%",
							height: showViewModal === "OFFICES" ? "400px" : "600px",
							overflowY: "auto",
							width: "100%",
						},
					},
				}}
				isOpen={!!showViewModal}
				onClose={() => setShowViewModal(undefined)}
				size={SIZE.auto}
				autoFocus={false}
			>
				<ModalHeader>
					<LabelLarge>
						{selectedUser && `${selectedUser.firstName} ${selectedUser.lastName}'s ${showViewModal === "OFFICES" ? "Offices" : "Schedule"}`}
					</LabelLarge>
				</ModalHeader>
				<div className={eventBody}>
					{selectedUser && showViewModal === "SCHEDULE" && <EventCalendar workerID={selectedUser.id} defaultView="agenda" />}
					{selectedUser && showViewModal === "OFFICES" && (
						<>
							{(!selectedUser.offices || selectedUser.offices.length === 0) && (
								<EmptyPrompt
									style={{
										color: "rgba(0,0,0,0.3)",
									}}
									icon={<Alert size={32} />}
									title={"No results"}
									titleSize="s"
								/>
							)}
							{selectedUser.offices &&
								selectedUser.offices.map((o, i) => {
									return (
										<div key={i} style={{ marginTop: "5px", padding: "5px", backgroundColor: i % 2 === 0 ? "#00000011" : "unset" }}>
											<LabelSmall>{o.day}</LabelSmall>
											<LabelSmall>{o.office.name}</LabelSmall>
										</div>
									)
								})}
						</>
					)}
				</div>
			</ZenModal>

			{/* Confirm Archive / Unarchive modal */}
			{selectedUser && (
				<ZenArchiveModal
					confirmButtonText="Remove"
					hideHeader
					message={`Confirm removal of ${selectedUser.firstName} ${selectedUser.lastName} from this team?`}
					open={archiveModalOpen}
					confirmArchive={() => removeTeamMember(selectedUser.id)}
					onClose={() => {
						setArchiveModalOpen(false)
						setSelectedUser(undefined)
					}}
					restoreMode={!!selectedUser.deletedAt}
					loading={remLoading}
				/>
			)}
		</ZenCard>
	)
}

interface AddWorkerProps {
	supervisorID: string
	onAdd: () => void
	existingTeamMembersId: string[]
}

const AddWorker = ({ supervisorID, onAdd, existingTeamMembersId }: AddWorkerProps) => {
	const { control, errors, getValues, handleSubmit } = useForm()

	const { mutate, error, loading, payload } = useMutation(fetching.mutation.userSupervisorAdd)

	const history = useHistory()

	const handleClick = () => {
		const { worker } = getValues()
		mutate({ supervisorID, workerID: worker[0].id })
	}

	React.useEffect(() => {
		// Wait for mutation to finish
		if (loading || !payload || error) return
		// Then call onAdd and return to team page
		onAdd()
		history.push(`/portal/team`)
	}, [error, history, loading, onAdd, payload])

	return (
		<ZenModal onClose={() => history.push(`/portal/team`)} closeable isOpen={true} animate autoFocus size={SIZE.default} role={ROLE.dialog}>
			<ModalHeader>Add worker to your team</ModalHeader>
			<ModalBody>
				{loading && <Loading />}
				{!loading && (
					<ZenUserSelect
						label="Worker"
						formName="worker"
						formRef={control}
						inputError={errors.worker}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
							},
						}}
						excludedID={existingTeamMembersId.concat(supervisorID)}
					/>
				)}
				{error && <ErrorNotification messageOrPayload={payload} />}
			</ModalBody>
			<ModalFooter>
				<CancelAndSaveButtons cancelFn={() => history.push(`/portal/team`)} saveFn={handleSubmit(handleClick)} saveLabel={"Confirm"} />
			</ModalFooter>
		</ZenModal>
	)
}
