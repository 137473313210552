import * as React from "react"
import { Loading } from "./loading"
import { useStyletron } from "baseui"

export const LoadingOverlay = () => {
	const [css] = useStyletron()
	const container = css({
		position: "absolute",
		height: "100%",
		width: "100%",
		backgroundColor: "rgba(255,255,255,0.9)",
		zIndex: 99,
		left: 0,
		top: 0,
	})

	return (
		<div className={container}>
			<Loading />
		</div>
	)
}
