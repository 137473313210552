import * as React from "react"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { Control, Controller, FieldError } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"

import { ZenFormControlOverrides, ZenInputBottomOverrides } from "../../../themeOverrides"
import { ZenDatePicker } from "../../zenComponents/zenTime"
import { ButtonSelection, ExtremeToLowOptions, FormValuePreview, YesNoSelection } from "../common"
import { IncidentAccidentReportPartData } from "./incidentAccidentReport"

interface IncidentAccidentReportStep4Props {
	control: Control<IncidentAccidentReportPartData>
	errors: DeepMap<IncidentAccidentReportPartData, FieldError>
	disabled?: boolean
	infoOnly?: boolean
}

const required = { required: { value: true, message: "Required" } }

const IncidentAccidentReportStep4 = (props: IncidentAccidentReportStep4Props) => {
	const { control, errors, disabled, infoOnly } = props

	const [css] = useStyletron()
	const titleStyle = css({
		fontWeight: "bold",
		marginBottom: "20px",
	})
	const riskControlStyle = css({
		fontSize: "14px",
		fontWeight: "bold",
		marginBottom: "10px",
		marginTop: "4px",
	})
	const signStyle = css({
		display: "flex",
	})
	const signatureStyle = css({
		marginLeft: "30px",
		marginRight: "30px",
		width: "30%",
	})

	return (
		<div>
			{!infoOnly && <LabelLarge className={titleStyle}>Part C: To be completed by Executive Corporate</LabelLarge>}

			<FormControl label="Risk Assessment Undertaken if Required" overrides={ZenFormControlOverrides}>
				<YesNoSelection name="partC.riskAssessmentUndertaken" control={control} disabled={disabled} incNA />
			</FormControl>

			<FormControl label="Risk Rating" overrides={ZenFormControlOverrides} error={errors.partC?.riskRating && errors.partC.riskRating.message}>
				<ButtonSelection name="partC.riskRating" control={control} disabled={disabled} options={ExtremeToLowOptions} required />
			</FormControl>

			<div className={riskControlStyle}>Risk Control</div>

			<ReportToSection control={control} disabled={disabled} infoOnly={infoOnly} label="Report to CEO" value="reportToCEO" error={errors.partC?.reportToCEO} />

			<ReportToSection
				control={control}
				disabled={disabled}
				infoOnly={infoOnly}
				label="Report to Funding Contact Manager"
				value="reportToFundingContactManager"
				error={errors.partC?.reportToFundingContactManager}
			/>

			<FormControl
				label="Risk Re-evaluated? New Risk Rating"
				overrides={ZenFormControlOverrides}
				error={errors.partC?.newRiskRating && errors.partC.newRiskRating.message}
			>
				<ButtonSelection name="partC.newRiskRating" control={control} disabled={disabled} options={ExtremeToLowOptions} />
			</FormControl>

			<FormControl label="Has Feedback Been Provided to the person who Reported The Incident / Accident" overrides={ZenFormControlOverrides}>
				<YesNoSelection name="partC.feedbackProvidedToReporter" control={control} disabled={disabled} incNA />
			</FormControl>

			<div className={signStyle}>
				<div>
					<FormControl label="Executive Corporate Name" overrides={ZenFormControlOverrides}>
						<Controller
							name={`partC.executiveCorporateName`}
							control={control}
							disabled={disabled}
							as={infoOnly ? undefined : <Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} />}
							render={FormValuePreview(infoOnly)}
						/>
					</FormControl>
				</div>

				<div className={signatureStyle}>
					<FormControl label="Signature" overrides={ZenFormControlOverrides}>
						<Controller
							name={`partC.executiveSignature`}
							control={control}
							disabled={disabled}
							as={infoOnly ? undefined : <Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} />}
							render={FormValuePreview(infoOnly)}
						/>
					</FormControl>
				</div>

				<div style={{ paddingTop: "12px" }}>
					<ZenDatePicker
						label="Date"
						nullDefaultValue
						formName={`partC.executiveDate`}
						formRef={control}
						minDate={new Date("1900-01-01")}
						maxDate={new Date()}
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	)
}

interface ReportToSectionProps {
	control: Control<IncidentAccidentReportPartData>
	disabled?: boolean
	infoOnly?: boolean
	label: string
	value: string
	error?: any
}

const ReportToSection = (props: ReportToSectionProps) => {
	const { control, disabled, infoOnly, label, value, error } = props
	const [css] = useStyletron()
	const paddedSectionStyle = css({
		padding: "10px",
	})
	const dateCompletedStyle = css({
		display: "grid",
		gridTemplateColumns: "48% 48%",
		columnGap: "4%",
	})

	return (
		<div className={paddedSectionStyle}>
			<LabelSmall>{label}</LabelSmall>
			<div className={paddedSectionStyle}>
				<FormControl label="Responsibility" overrides={ZenFormControlOverrides} error={error?.responsibility && error.responsibility.message}>
					<Controller
						name={`partC.${value}.responsibility`}
						control={control}
						as={infoOnly ? undefined : <Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} placeholder="Enter responsibility" />}
						render={FormValuePreview(infoOnly)}
						disabled={disabled}
						error={error?.responsibility !== undefined}
						rules={required}
					/>
				</FormControl>

				<div className={dateCompletedStyle}>
					<div>
						<ZenDatePicker
							label="Target Completion Date"
							nullDefaultValue
							formName={`partC.${value}.targetCompletionDate`}
							formRef={control}
							minDate={new Date("1900-01-01")}
							maxDate={new Date()}
							disabled={disabled}
						/>
					</div>
					<div>
						<ZenDatePicker
							label="Date Completed"
							nullDefaultValue
							formName={`partC.${value}.completionDate`}
							formRef={control}
							minDate={new Date("1900-01-01")}
							maxDate={new Date()}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IncidentAccidentReportStep4
