import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { FormControl } from "baseui/form-control"
import {
	ZenFormControlOverrides,
	ZenInputResizableOverrides,
	ZenInputBottomOverrides,
	ZenCheckBoxOverrides,
	ZenFormControlWrapOverrides,
} from "../../../themeOverrides"
import { Controller, Control, FieldError } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"
import { IncidentAccidentReportPartData } from "./incidentAccidentReport"
import { Textarea } from "baseui/textarea"
import { YesNoSelection, FormValuePreview } from "../common"
import { Input } from "baseui/input"
import { Checkbox } from "baseui/checkbox"

interface IncidentAccidentReportStep3Props {
	control: Control<IncidentAccidentReportPartData>
	errors: DeepMap<IncidentAccidentReportPartData, FieldError>
	disabled?: boolean
	infoOnly?: boolean
	types: string[]
	changeTypes: React.Dispatch<React.SetStateAction<string[]>>
}

const required = { required: { value: true, message: "Required" } }

interface IncidentType {
	name: string
	children?: string[]
}

interface typeList {
	typeName: string
	subTypeName: string[]
}

const incidentTypes: IncidentType[] = [
	{ name: "Client Escalation" },
	{ name: "Client Incident", children: ["Open Disclosure Process", "ATSI Client", "Follow Process"] },
	{ name: "General Incident / Accident (WHS)" },
	{ name: "Identified Risk Situation" },
	{ name: "Reportable Incident or Accident", children: ["Contractual Requirement", "WorkSafe Notification", "Insurance Matter / Claim"] },
]

const IncidentAccidentReportStep3 = (props: IncidentAccidentReportStep3Props) => {
	const { control, errors, disabled, infoOnly, types, changeTypes } = props

	const [css, theme] = useStyletron()
	const titleStyle = css({
		fontWeight: "bold",
		marginBottom: "20px",
	})
	const typeContainerStyle = css({
		border: "2px solid #D6DBF0",
		borderRadius: "3px",
		padding: "10px",
	})
	const checkBoxStyle = css({
		padding: "5px",
	})
	const offsetStyle = css({
		paddingLeft: "20px",
	})
	const checkBoxGroupStyle = css({
		backgroundColor: theme.colors.backgroundSecondary,
	})
	const valuePreview = css({
		color: "#545454",
		padding: "10px 14px",
	})

	const displayCheckBoxes = () => {
		// get part b type value
		if (infoOnly) {
			// build list
			let list: typeList[] = []
			incidentTypes.forEach((it) => {
				let tl: typeList = {
					typeName: "",
					subTypeName: [],
				}

				// get type name
				if (!types.includes(it.name)) return

				tl.typeName = it.name

				if (!it.children || it.children.length === 0) {
					list.push(tl)
					return
				}

				tl.subTypeName.push(...it.children.filter((tc) => types.includes(tc)))
				list.push(tl)
			})

			return (
				<div className={valuePreview}>
					{list.map((l, i) => (
						<LabelSmall color="#545454" key={i}>{`${l.typeName}${l.subTypeName.length > 0 ? ` - (${l.subTypeName.join(", ")})` : ""}`}</LabelSmall>
					))}
				</div>
			)
		}
		return (
			<div className={typeContainerStyle}>
				{incidentTypes.map((t, index) => (
					<div className={index % 2 !== 0 ? checkBoxGroupStyle : ""} key={`type-checkbox-${index}-${t.name}`}>
						<div className={checkBoxStyle}>
							<Checkbox
								onChange={(e) => {
									if (e.currentTarget.checked) {
										changeTypes((currentTypes) => currentTypes.concat(t.name))
										return
									}

									let checkList = [t.name]
									if (t.children) {
										checkList.push(...t.children)
									}
									// remove title and its children
									changeTypes((currentTypes) => currentTypes.filter((currentType) => !checkList.some((cl) => cl === currentType)))
								}}
								checked={types.includes(t.name) || (t.children !== undefined && t.children.some((c) => types.includes(c)))}
								overrides={ZenCheckBoxOverrides}
								disabled={disabled}
							>
								{t.name}
							</Checkbox>
						</div>
						{t.children && (
							<div className={offsetStyle}>
								{t.children.map((c) => (
									<div className={checkBoxStyle} key={`type-checkbox-${index}-${t.name}-${c}`}>
										<Checkbox
											onChange={(e) => {
												if (e.currentTarget.checked) {
													let addList = [c]
													if (!types.includes(t.name)) {
														addList.push(t.name)
													}

													changeTypes((currentTypes) => currentTypes.concat(...addList))
													return
												}
												// remove children
												changeTypes((currentTypes) => currentTypes.filter((currentType) => currentType !== c))
											}}
											checked={types.includes(c)}
											overrides={ZenCheckBoxOverrides}
											disabled={disabled}
										>
											{c}
										</Checkbox>
									</div>
								))}
							</div>
						)}
					</div>
				))}
			</div>
		)
	}

	return (
		<div>
			{!infoOnly && <LabelLarge className={titleStyle}>Part B: To be completed by Relevant Executive</LabelLarge>}

			<LabelSmall marginBottom="5px">Type of Incident or Accident</LabelSmall>
			{displayCheckBoxes()}

			<FormControl
				label="Comment / Action Taken and Risk Mitigation"
				overrides={ZenFormControlOverrides}
				error={errors.partB?.comment && errors.partB.comment.message}
			>
				<Controller
					name="partB.comment"
					control={control}
					as={infoOnly ? undefined : Textarea}
					render={FormValuePreview(infoOnly)}
					placeholder={!disabled ? "Enter description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					defaultValue=""
					error={errors.partB?.comment !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl label="Has feedback been provided to the person who reported the Incident / Accident?" overrides={ZenFormControlWrapOverrides}>
				<YesNoSelection name="partA.feedbackProvidedToReporter" control={control} disabled={disabled} />
			</FormControl>

			<FormControl
				label="The following Policies / Procedures / Forms may be relevant"
				overrides={ZenFormControlWrapOverrides}
				error={errors.partB?.relevant && errors.partB.relevant.message}
			>
				<Controller
					name={`partB.relevant`}
					control={control}
					as={infoOnly ? undefined : <Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} />}
					render={FormValuePreview(infoOnly)}
					disabled={disabled}
					error={errors.partB?.relevant !== undefined}
					rules={required}
				/>
			</FormControl>
		</div>
	)
}

export default IncidentAccidentReportStep3
