import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Button } from "baseui/button"
import { StatefulTooltip } from "baseui/tooltip"
import { LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useQuery } from "react-fetching-library"
import { RouteComponentProps, useHistory } from "react-router-dom"

import { CallLogUpdateForm } from "../../components/callLogs/callLogUpdateForm"
import { CallLogNotes, LabelField, UserLabel } from "../../components/callLogs/subComponents"
import { Divider, ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { NotFound } from "../../components/notFound"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { query } from "../../fetching/query"
import { snakeToTitle, timeDuration } from "../../helpers/utils"
import { CallLog, RolePermission } from "../../types/types"

import { routes } from "routes"
import { AuthContainer } from "../../controllers/auth"
import { Responsive, WidthProvider } from "react-grid-layout"
import { CallFromClientOptions, CallTypeOptions } from "components/callLogs/callLogCreateForm"
import { PortalContainer } from "../../controllers/portal"

const ResponsiveGridLayout = WidthProvider(Responsive)

export const CallLogView = (props: RouteComponentProps<{ callLogID: string }>) => {
	const callLogID = props.match.params.callLogID
	const [css] = useStyletron()

	const history = useHistory()
	const urlParams = new URLSearchParams(history.location.search)
	const isEditMode = urlParams.get("edit") === "true"
	const { hasPermission, currentUser } = AuthContainer.useContainer()
	const { timezone } = PortalContainer.useContainer()
	const [canEdit, setCanEdit] = React.useState<boolean>(false)

	const { error, loading, payload, query: reFetch } = useQuery<CallLog>(query.getCallLog(callLogID))
	React.useEffect(() => {
		if (!payload || !payload.worker || !currentUser) return
		setCanEdit(hasPermission(RolePermission.CallLogUpdate) || payload.worker.id === currentUser.id)
	}, [payload, currentUser, hasPermission])

	React.useEffect(() => {
		reFetch()
	}, [isEditMode, reFetch])

	const outer = css({
		display: "flex",
		maxHeight: "100%",
		width: "100%",
		overflowY: "auto",
	})

	const groupDisplay = css({
		display: "flex",
		justifyContent: "space-between",
	})
	const inner = css({
		height: "100%",
		width: "100%",
		minHeight: 0,
		display: "flex",
		flexDirection: "column",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const updateFormStyle = css({
		maxHeight: "100%",
		width: "100%",
		maxWidth: "800px !important",
	})
	const columnStyle = css({
		height: "100%",
	})

	if (loading || !payload) return null
	if (error) return <NotFound />

	// Editing?
	if (isEditMode) {
		return (
			<ZenCard className={updateFormStyle}>
				<CallLogUpdateForm callLog={payload} />
			</ZenCard>
		)
	}

	const onExitPage = () => {
		// return back to client session tab, if client id exists
		const returnClientID = urlParams.get("client_id")
		if (returnClientID) {
			history.push({
				pathname: routes.withID(returnClientID, routes.clients.client.root),
				hash: "callLogs",
			})
			return
		}

		history.push(routes.callLogs.root)
	}

	const dateDisplay = (): React.ReactNode => {
		let startTime = moment(payload.startTime)
		let endTime = moment(payload.endTime)

		if (startTime.isAfter(endTime)) {
			const t = endTime
			endTime = startTime
			startTime = t
		}

		if (startTime.date() !== endTime.date()) {
			return `${moment(startTime).tz(timezone.id).format("DD/MM/YYYY")} - ${moment(endTime).tz(timezone.id).format("DD/MM/YYYY")}`
		}

		return moment(payload.startTime).tz(timezone.id).format("DD/MM/YYYY")
	}

	return (
		<div className={outer}>
			<ResponsiveGridLayout style={{ width: "100%" }} layouts={layouts}>
				<div key="call-log-detail">
					<ZenCard className={columnStyle}>
						<div className={inner}>
							<div className={title}>
								<LabelMedium>{`Call Log #${payload.identifier}`}</LabelMedium>
								{canEdit && (
									<StatefulTooltip content="Edit Call Log" returnFocus autoFocus placement="top">
										<Button onClick={() => history.push({ search: "edit=true" })} kind="minimal" size="compact">
											<FontAwesomeIcon icon={["fal", "pencil"]} />
										</Button>
									</StatefulTooltip>
								)}
							</div>
							<Divider />
							{error && <ErrorNotification messageOrPayload={payload} />}
							<LabelField label="Call Type">
								<LabelSmall>{snakeToTitle(payload.type)}</LabelSmall>
							</LabelField>
							<LabelField label="Worker">
								<UserLabel data={payload.worker} />
							</LabelField>
							<LabelField label="Date">
								<LabelSmall>{dateDisplay()}</LabelSmall>
							</LabelField>
							<div className={groupDisplay}>
								<LabelField label="Start time">
									<LabelSmall>{moment(payload.startTime).tz(timezone.id).format("hh:mm a")}</LabelSmall>
								</LabelField>
								<LabelField label="End time">
									<LabelSmall>{moment(payload.endTime).tz(timezone.id).format("hh:mm a")}</LabelSmall>
								</LabelField>
								<LabelField label="Duration">
									<LabelSmall>{timeDuration(payload.startTime, payload.endTime)}</LabelSmall>
								</LabelField>
							</div>
							<LabelField label={payload.type === CallTypeOptions.IncomingCall ? "Call From" : "Call To"}>
								<LabelSmall>
									{payload.type === CallTypeOptions.OutgoingCall && payload.callFrom === CallFromClientOptions.CallFromExistingClient
										? "Call To Existing Client"
										: payload.type === CallTypeOptions.OutgoingCall && payload.callFrom === CallFromClientOptions.CallFromNewClient
										? "Call To New Client"
										: snakeToTitle(payload.callFrom)}
								</LabelSmall>
							</LabelField>
							<div className={groupDisplay}>
								{payload.callerName && (
									<LabelField label="Caller">
										<LabelSmall>{payload.callerName}</LabelSmall>
									</LabelField>
								)}
								{payload.client && (
									<LabelField label={payload.callerName ? "About Client" : "Client"}>
										<UserLabel data={payload.client} />
									</LabelField>
								)}
							</div>
							<LabelField label="Number">
								<LabelSmall>{payload.phoneNumber}</LabelSmall>
							</LabelField>
							<div className={groupDisplay}>
								<LabelField label="Purpose of Call">
									<LabelSmall>{payload.callPurpose.label}</LabelSmall>
								</LabelField>
								<LabelField label={`Purpose Type${!!payload.callPurposeTypes && payload.callPurposeTypes.length > 0 ? "s" : ""}`}>
									{!!payload.callPurposeTypes &&
										payload.callPurposeTypes.length > 0 &&
										payload.callPurposeTypes.map((cpt, i) => <LabelSmall key={`call-purpose-type-${i}`}>{cpt.label}</LabelSmall>)}
								</LabelField>
							</div>

							{(payload.transferredToDepartment || payload.transferredToWorker) && (
								<div className={groupDisplay}>
									<LabelField label="Transferred">
										{payload.transferredToDepartment && payload.transferredToDepartment.name && <LabelSmall>{payload.transferredToDepartment.name}</LabelSmall>}
										{payload.transferredToWorker && (
											<LabelSmall>
												<UserLabel data={payload.transferredToWorker} />
											</LabelSmall>
										)}
									</LabelField>
								</div>
							)}
						</div>
						<Block backgroundColor="white" marginTop="15px">
							<ZenButton altKind={"secondary"} type="button" onClick={onExitPage} width="100px">
								Back
							</ZenButton>
						</Block>
					</ZenCard>
				</div>
				<div key="call-log-notes">
					<ZenCard className={columnStyle}>
						<CallLogNotes callLogID={payload.id} reFetch={() => reFetch()} />
					</ZenCard>
				</div>
			</ResponsiveGridLayout>
		</div>
	)
}

const layouts = {
	lg: [
		{
			w: 4,
			h: 5,
			x: 0,
			y: 0,
			i: "call-log-detail",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 4,
			y: 0,
			i: "call-log-notes",
			moved: false,
			static: true,
		},
	],
	sm: [
		{
			w: 4,
			h: 5,
			x: 0,
			y: 0,
			i: "call-log-detail",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 0,
			y: 5,
			i: "call-log-notes",
			moved: false,
			static: true,
		},
	],
}
