import * as React from "react"
import { LabelLarge } from "baseui/typography"
import moment from "moment-timezone"
import { useForm } from "react-hook-form"
import { useStyletron } from "styletron-react"

import { Spacer, ZenCard } from "../../components/common"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import { ErrorNotification } from "../../components/errorBox"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { fetching } from "../../fetching"
import { getErrorFromBlob, snakeToTitle } from "../../helpers/utils"
import { Option } from "baseui/select"
import { ZenSelect, ZenUserSelect } from "../../components/zenComponents/zenSelectBox"

interface FormData {
	startDate: string
	endDate: string
	user?: Option[]
	fundingSource?: Option[]
	contractArea?: Option[]
	supportType?: Option[]
	subSupportType?: Option[]
	subSubSupportType?: Option[]
	attendanceStatus?: Option[]
}

export const StaffHoursReport = () => {
	const [css] = useStyletron()
	const card = css({
		height: "fit-content",
		width: "fit-content",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		flexDirection: "column",
	})
	const buttonRow = css({
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px",
	})
	const group = css({
		display: "flex",
		justifyContent: "space-between",
	})

	const { control, handleSubmit, getValues, errors, setValue } = useForm<FormData>()
	const { query, loading, error } = useParameterizedQuery(fetching.query.getStaffHourReport)
	const fundingSourceAll = useQuery(fetching.query.getFundingSourceAll(false, true))
	const contractAreaList = useParameterizedQuery(fetching.query.getContractAreasByFundingSourceID)
	const [contractAreaOptions, setContractAreaOptions] = React.useState<Option[]>([])
	const getContractAreaOptions = (fundingSourceID: string) => {
		contractAreaList.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setContractAreaOptions(resp.payload)
		})
	}
	const supportTypeList = useParameterizedQuery(fetching.query.getSupportTypesByFundingSourceID)
	const [supportTypeOptions, setSupportTypeOptions] = React.useState<Option[]>([])
	const getSupportTypeOptions = (fundingSourceID: string) => {
		supportTypeList.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setSupportTypeOptions(resp.payload)
		})
	}
	const subSupportTypeList = useParameterizedQuery(fetching.query.getSubSupportTypesBySupportTypeID)
	const [subSupportTypeOptions, setSubSupportTypeOptions] = React.useState<Option[]>([])

	const subSubSupportTypeList = useParameterizedQuery(fetching.query.getSubSubSupportTypesBySubSupportTypeID)
	const [subSubSupportTypeOptions, setSubSubSupportTypeOptions] = React.useState<Option[]>([])

	const getSubSupportTypeOptions = (fundingSourceID: string, supportTypeID: string) => {
		subSupportTypeList.query({ fundingSourceID, supportTypeID }).then((resp) => {
			if (resp.error || !resp.payload) return
			setSubSupportTypeOptions(resp.payload)
		})
	}

	const getSubSubSupportTypeOptions = (fundingSourceID: string, supportTypeID: string, subSupportTypeID: string) => {
		subSubSupportTypeList.query({ fundingSourceID, supportTypeID, subSupportTypeID }).then((resp) => {
			if (resp.error || !resp.payload) return
			setSubSubSupportTypeOptions(resp.payload)
		})
	}

	const [errorMsg, setErrorMsg] = React.useState<string>()

	const onSubmit = async (data: FormData) => {
		setErrorMsg(undefined)

		const resp = await query({
			startDate: new Date(data.startDate),
			endDate: new Date(data.endDate),
			workerIDs: data.user ? data.user.map<string>((u) => u.id?.toString() || "") : undefined,
			fundingSourceIDs: data.fundingSource ? data.fundingSource.map<string>((f) => f.id?.toString() || "") : undefined,
			contractAreaIDs: data.contractArea ? data.contractArea.map<string>((f) => f.id?.toString() || "") : undefined,
			supportTypeIDs: data.supportType ? data.supportType.map<string>((f) => f.id?.toString() || "") : undefined,
			subSupportTypeIDs: data.subSupportType ? data.subSupportType.map<string>((f) => f.id?.toString() || "") : undefined,
			subSubSupportTypeIDs: data.subSubSupportType ? data.subSubSupportType.map<string>((f) => f.id?.toString() || "") : undefined,
			attendanceStatus: data.attendanceStatus ? data.attendanceStatus.map<string>((f) => f.id?.toString() || "") : undefined,
		})

		if (resp.error || !resp.payload) {
			setErrorMsg(await getErrorFromBlob(resp.payload))
			return
		}

		// Create link and initiate download when response is received
		const downloadLink = document.createElement("a")
		downloadLink.href = URL.createObjectURL(resp.payload)
		downloadLink.download = `staff_hours_${moment(data.startDate).format("YYYYMMDD")}_${moment(data.endDate).format("YYYYMMDD")}.xlsx`
		downloadLink.click()
	}

	return (
		<ZenCard className={card}>
			<form autoComplete="off" className={title} onSubmit={handleSubmit(onSubmit)}>
				<LabelLarge marginBottom="20px">Export Staff Hours Report</LabelLarge>
				<div className={group}>
					<div>
						<ZenDatePicker
							inputError={errors.startDate}
							nullDefaultValue={true}
							formName="startDate"
							label="Start Date"
							formRef={control}
							formRules={{
								required: "You must select a start date.",
							}}
						/>
					</div>
					<Spacer style={{ width: 15 }} />
					<div>
						<ZenDatePicker
							inputError={errors.endDate}
							nullDefaultValue={true}
							formName="endDate"
							label="End Date"
							formRef={control}
							formRules={{
								required: "You must select an end date.",
								validate: (value: string) => {
									if (moment(value).isBefore(moment(getValues("startDate")))) {
										return "End date must be after start date."
									}
									return null
								},
							}}
						/>
					</div>
				</div>
				<ZenSelect
					multi={true}
					options={[
						{ id: "ATTENDED", label: snakeToTitle("ATTENDED") },
						{ id: "DNA", label: snakeToTitle("DNA") },
						{ id: "SHORT_NOTICE_CANCELLATION", label: snakeToTitle("SHORT_NOTICE_CANCELLATION") },
						{ id: "ENDED_EARLY", label: snakeToTitle("ENDED_EARLY") },
						{ id: "CANCELLED", label: snakeToTitle("CANCELLED") },
					]}
					label="Attendance Status (Optional)"
					formName={"attendanceStatus"}
					formRef={control}
				/>
				<ZenUserSelect label={"Worker (Optional)"} formName={"user"} formRef={control} />
				<ZenSelect
					options={fundingSourceAll.payload || []}
					label="Funding Source (Optional)"
					formName={"fundingSource"}
					formRef={control}
					actionOnChange={(v) => {
						// clear up all the option
						setContractAreaOptions([])
						setValue("contractArea", [])
						setSupportTypeOptions([])
						setValue("supportType", [])
						setSubSupportTypeOptions([])
						setValue("subSupportType", [])
						if (v.length === 0 || !v[0].id) return
						const fundingSourceID = v[0].id.toString()
						getContractAreaOptions(fundingSourceID)
						getSupportTypeOptions(fundingSourceID)
					}}
				/>
				<ZenSelect
					options={contractAreaOptions}
					label="Contract Area (Optional)"
					formName={"contractArea"}
					formRef={control}
					disabled={contractAreaOptions.length === 0}
				/>
				<ZenSelect
					options={supportTypeOptions}
					label="Support Type (Optional)"
					formName={"supportType"}
					formRef={control}
					disabled={supportTypeOptions.length === 0}
					actionOnChange={(v) => {
						setSubSupportTypeOptions([])
						setValue("subSupportType", [])
						if (v.length === 0 || !v[0].id) return
						const supportTypeID = v[0].id.toString()
						const currentFundingSource = getValues().fundingSource
						if (!currentFundingSource || currentFundingSource.length === 0 || !currentFundingSource[0].id) return
						const fundingSourceID = currentFundingSource[0].id.toString()
						getSubSupportTypeOptions(fundingSourceID, supportTypeID)
					}}
				/>
				<ZenSelect
					options={subSupportTypeOptions}
					label="Sub Support Type (Optional)"
					formName={"subSupportType"}
					formRef={control}
					disabled={subSupportTypeOptions.length === 0}
					actionOnChange={(v) => {
						setSubSubSupportTypeOptions([])
						setValue("subSubSupportType", [])
						if (v.length === 0 || !v[0].id) return
						const supportTypeIDs = getValues("supportType")
						let supportTypeID: string = ""
						if (supportTypeIDs) supportTypeID = supportTypeIDs[0]?.id as string

						const subSupportTypeID = v[0].id.toString()
						const currentFundingSource = getValues().fundingSource
						if (!currentFundingSource || currentFundingSource.length === 0 || !currentFundingSource[0].id) return
						const fundingSourceID = currentFundingSource[0].id.toString()
						getSubSubSupportTypeOptions(fundingSourceID, supportTypeID, subSupportTypeID)
					}}
				/>
				<ZenSelect
					options={subSubSupportTypeOptions}
					label="Support Type (Optional)"
					formName={"subSubSupportType"}
					formRef={control}
					disabled={subSubSupportTypeOptions.length === 0}
				/>
				{error && <ErrorNotification message={errorMsg} />}
				<div className={buttonRow}>
					<ZenButton type="submit" isLoading={loading}>
						Export
					</ZenButton>
				</div>
			</form>
		</ZenCard>
	)
}
