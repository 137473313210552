import * as React from "react"
import { Switch, Route } from "react-router-dom"
import { SessionCreateAppointment } from "./sessionCreateAppointment"
import { SessionView } from "./sessionView"
import { SessionList } from "./sessionList"
import { CreateNonBillable } from "./CreateNonBillable"
import { routes } from "../../routes"

export default function SessionRoot() {
	return (
		<Switch>
			<Route exact path={routes.sessions.logNonBillable} component={CreateNonBillable} />
			<Route exact path={routes.sessions.create.single.root} component={SessionCreateAppointment} />
			<Route exact path={routes.sessions.create.single.view} component={SessionCreateAppointment} />
			<Route exact path={routes.sessions.create.group} component={SessionCreateAppointment} />
			<Route exact path={routes.sessions.view} component={SessionView} />
			<Route exact path={routes.sessions.root} component={SessionList} />
		</Switch>
	)
}
