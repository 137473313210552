import * as React from "react"
import { NDISPlan, ZenDateFormat } from "../../../types/types"
import { useStyletron } from "baseui"
import { useMutation } from "react-fetching-library"
import { fetching } from "../../../fetching"
import moment from "moment-timezone"
import { LabelMedium } from "baseui/typography"
import { borderRadiusStyle, ZenTheme } from "../../../themeOverrides"
import { Textarea } from "baseui/textarea"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ErrorNotification } from "../../../components/errorBox"

export const PlanNote = (props: { plan: NDISPlan; onNoteUpdate: (plan: NDISPlan) => void }) => {
	const [css] = useStyletron()

	const [canEdit, setCanEdit] = React.useState(false)
	const [saving, setSaving] = React.useState(false)
	const [text, setText] = React.useState("")
	const updatePlan = useMutation(fetching.mutation.NDISPlanNoteUpdate)

	React.useEffect(() => {
		if (!props.plan) return

		setText(props.plan.note)
	}, [props.plan])

	const handleSave = async () => {
		if (!props.plan) return
		const resp = await updatePlan.mutate({
			planID: props.plan.id,
			note: text,
		})

		if (!resp.payload) {
			// reset note if failed
			setText(props.plan.note)
			return
		}
		setCanEdit(false)
	}

	const planText = props.plan ? `Plan: ${moment(props.plan.planStartDate).format(ZenDateFormat)} - ${moment(props.plan.planEndDate).format(ZenDateFormat)}` : ""
	const buttons = css({
		marginTop: "10px",
		width: "100%",
		display: "flex",
		justifyContent: canEdit ? "space-between" : "flex-end",
	})
	const heading = css({
		marginBottom: "5px",
		display: "flex",
		justifyContent: "space-between",
	})
	const container = css({
		height: "100%",
		display: "flex",
		flexDirection: "column",
	})
	return (
		<div className={container}>
			<div className={heading}>
				<LabelMedium marginRight={"10px"}>{"Notes"}</LabelMedium>
				<LabelMedium color={ZenTheme.colors.primaryGrey}>{planText}</LabelMedium>
			</div>
			<Textarea
				value={text}
				onChange={(e) => setText(e.currentTarget.value)}
				overrides={{
					Input: {
						style: {
							backgroundColor: "transparent",
							paddingBottom: "0",
							height: "100%",
							fontSize: "12px",
							minHeight: "500px",
						},
					},
					InputContainer: {
						style: ({ $theme, $disabled, $error }: any) => ({
							...borderRadiusStyle,
							borderTopWidth: $disabled ? 0 : "2px",
							borderTopColor: $error ? $theme.colors.negative : $theme.colors.black,
							borderBottomWidth: $disabled ? 0 : "2px",
							borderBottomColor: $error ? $theme.colors.negative : $theme.colors.black,
							borderLeftWidth: $disabled ? 0 : "2px",
							borderLeftColor: $error ? $theme.colors.negative : $theme.colors.black,
							borderRightWidth: $disabled ? 0 : "2px",
							borderRightColor: $error ? $theme.colors.negative : $theme.colors.black,
							borderTopStyle: $disabled ? "unset" : "solid",
							borderBottomStyle: $disabled ? "unset" : "solid",
							borderLeftStyle: $disabled ? "unset" : "solid",
							borderRightStyle: $disabled ? "unset" : "solid",
							backgroundColor: "transparent",
							flex: 1,
							minHeight: "500px",
						}),
					},
				}}
				disabled={!canEdit || updatePlan.loading}
			/>
			{updatePlan.error && <ErrorNotification messageOrPayload={updatePlan.payload} />}
			<div className={buttons}>
				{canEdit && (
					<ZenButton width={"100px"} onClick={() => setCanEdit(false)} altKind={canEdit ? "secondary" : "primary"} disabled={!props.plan || updatePlan.loading}>
						Cancel
					</ZenButton>
				)}

				<ZenButton
					isLoading={saving}
					width={"100px"}
					onClick={async () => {
						if (canEdit) {
							setSaving(true)
							await handleSave()
							setSaving(false)
							return
						}
						setCanEdit(true)
					}}
					disabled={!props.plan || updatePlan.loading}
				>
					{canEdit ? "Save" : "Edit Note"}
				</ZenButton>
			</div>
		</div>
	)
}
