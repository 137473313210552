import * as React from "react"
import {useEffect, useState} from "react"
import { useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Button, KIND as ButtonKind } from "baseui/button"
import { Checkbox } from "baseui/checkbox"
import { FormControl } from "baseui/form-control"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { Notification } from "baseui/notification"
import { ProgressBar } from "baseui/progress-bar"
import { OnChangeParams, Value } from "baseui/select"
import { KIND } from "baseui/toast"
import { HeadingMedium, HeadingXSmall, LabelMedium, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../components/cancelSaveButtons"
import { Divider, SearchAndFilter, ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { FieldDisplay } from "../../components/fieldDisplay"
import { ListTable, useListTable } from "../../components/listTable"
import { Loading } from "../../components/loading"
import { useZenToast } from "../../components/zenComponents/useZenToast"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenConfirmModal } from "../../components/zenComponents/zenConfirmModal"
import { ZenDivider, ZenTextArea } from "../../components/zenComponents/zenInput"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { ZenUserSelect } from "../../components/zenComponents/zenSelectBox"
import { ZenUserDropdownSelect } from "components/dropdownSelect"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"
import { AuthContainer } from "../../controllers/auth"
import { ReportsContainer } from "../../controllers/reports"
import { fetching } from "../../fetching"
import { genericError } from "../../helpers/errors"
import { getTimesheetStatus, friendlyDate, friendlyTime, getMileageClaimStatus, setTimezoneToTime, snakeToTitle, getMileageClaimLatestStatusUpdateDate, getMileageClaimLatestReviewer, emailNotifTemplate } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { ReviewStatus } from "../../types/enums"
import { MileageClaim, MileageClaimResult, RolePermission, SearchTextMinLength, SortOrder, Travel, TZString, ZenDateFormat, UserWithRole } from "../../types/types"
import { TravelView } from "../travels/travelView"
import { MileageClaimPDFPreview } from "./pdfExports/mileageClaimExport"
import { ReviewStamp } from "../../components/reviewStamp"
import { StatefulTooltip } from "baseui/tooltip"
import { DropdownSelect } from "components/dropdownSelect"
import { ErrorMessage } from "components/errorMessage"
import { base_host as host } from "../../host"
import { MileageNoteArea } from "./mileageClaimsNotes"

interface MileageClaimInput {
	periodStart: Date
	periodEnd: Date
	workers: Value
}

interface MileageClaimManageProps {
	onSuccess: () => void
	onCancel: () => void
	worker?: Value
	milageClaim?: MileageClaim
}

export const MileageClaimManage = (props: MileageClaimManageProps) => {
	const { onSuccess, onCancel, worker } = props
	const { showToast } = useZenToast()
	const [css] = useStyletron()
	const card = css({
		width: "60%",
		minWidth: "680px",
		padding: "20px",
	})

	const [validationErrors, setValidationErrors] = React.useState<string[]>([])
	const createMileageClaim = useMutation<MileageClaimResult>(fetching.mutation.mileageClaimCreate)
	const updateMileageClaim = useMutation<MileageClaimResult>(fetching.mutation.mileageClaimUpdate)

	const { control, handleSubmit, getValues, setValue, errors } = useForm()
	const { hasPermission } = AuthContainer.useContainer()

	// excluded IDs
	const [excludedUserID, setExcludedUserID] = React.useState<string[]>([])

	const onSubmit = async (data: MileageClaimInput) => {
		const userIDs: string[] = []
		for (const selection of data.workers) {
			if (selection.id) {
				userIDs.push(`${selection.id}`)
			}
		}

		const variables = {
			periodStart: setTimezoneToTime(moment(data.periodStart).startOf("day").toDate(), TZString[0].id),
			periodEnd: setTimezoneToTime(moment(data.periodEnd).endOf("day").toDate(), TZString[0].id),
			userIDs,
			mileageClaimID: props.milageClaim?.id,
		}

		// update
		if (props.milageClaim) {
			const resp = await updateMileageClaim.mutate(variables)
			if (resp.error || validationErrors) return
			showToast("Mileage Claim updated successfully.", "positive")
			return
		}

		// create
		const resp = await createMileageClaim.mutate(variables)
		if (resp.error || validationErrors) return
		showToast("Mileage Claim created successfully.", "positive")
	}

	// set initial state
	React.useEffect(() => {
		setValue("workers", worker || [])

		if (props.milageClaim) {
			setValue("workers", [props.milageClaim.user])
			setValue("periodStart", props.milageClaim.periodStart)
			setValue("periodEnd", props.milageClaim.periodEnd)
		}
	}, [setValue, worker, props.milageClaim])

	React.useEffect(() => {
		if (!createMileageClaim.payload) return
		if (createMileageClaim.payload.validationErrors && createMileageClaim.payload.validationErrors.length > 0) {
			setValidationErrors(createMileageClaim.payload.validationErrors)
			return
		}
		onSuccess()
	}, [createMileageClaim.payload]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (!updateMileageClaim.payload) return
		if (updateMileageClaim.payload.validationErrors && updateMileageClaim.payload.validationErrors.length > 0) {
			setValidationErrors(updateMileageClaim.payload.validationErrors)
			return
		}
		onSuccess()
	}, [updateMileageClaim.payload]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ZenCard className={card}>
			<HeadingXSmall marginTop={0}>{props.milageClaim ? "Edit Mileage Claim" : "Create Mileage Claims"}</HeadingXSmall>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<ZenDatePicker
					formRef={control}
					label={"From"}
					formName={"periodStart"}
					inputError={errors.periodStart}
					formRules={{
						validate: {
							required: (value: string) => {
								if (!value) {
									return "you must select a start date"
								}
								const periodEnd = getValues("periodEnd")
								if (moment(value).isAfter(periodEnd)) {
									return "start date must be before end date"
								}
								return null
							},
						},
					}}
				/>
				<ZenDatePicker
					formRef={control}
					label={"To"}
					formName={"periodEnd"}
					inputError={errors.periodEnd}
					formRules={{
						validate: {
							required: (value: string) => {
								if (!value) {
									return "you must select an end date"
								}
								const periodStart = getValues("periodStart")
								if (moment(value).isBefore(periodStart)) {
									return "end date must be after start date"
								}
								return null
							},
						},
					}}
				/>
				<ZenUserSelect
					label="Search"
					formName="workers"
					formRef={control}
					inputError={errors.worker}
					multi={true}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
						},
					}}
					disabled={!hasPermission(RolePermission.ReportCreate)}
					actionOnChange={(v) => {
						// check null value
						if (!v || v.length === 0 || !v[0].id) {
							setExcludedUserID([])
							return
						}
						setExcludedUserID([v[0].id.toString()])
						setValue("worker", v)
					}}
					excludedID={excludedUserID}
				/>
				{validationErrors.length > 0 && (
					<Notification kind={KIND.negative} overrides={{ Body: { style: { width: "100%" } } }}>
						<div>There was a problem creating mileage claims</div>
						<br />
						{validationErrors.map((value, key) => (
							<li key={key}>{value}</li>
						))}
					</Notification>
				)}

				{createMileageClaim.error && <ErrorNotification messageOrPayload={createMileageClaim.payload} />}
				{updateMileageClaim.error && <ErrorNotification messageOrPayload={updateMileageClaim.payload} />}

				<FormControl error={createMileageClaim.error || updateMileageClaim.error}>
					<CancelAndSaveButtons cancelFn={() => onCancel()} isLoading={createMileageClaim.loading || updateMileageClaim.loading} />
				</FormControl>
			</form>
		</ZenCard>
	)
}

export const MileageClaims = () => {
	const { getMileageClaimsReady } = ReportsContainer.useContainer()

	const [css] = useStyletron()
	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})

	const filterOptions: Value = [
		{ id: "All", label: "All" },
		{ id: "Draft", label: "Draft" },
		{ id: "Declined", label: "Declined" },
		{ id: "Pending", label: "Pending" },
		{ id: "Checked", label: "Checked" },
		{ id: "Approved", label: "Approved" },
		{ id: "Complete", label: "Complete" },
	]

	const [filter, setFilter] = React.useState<Value>([filterOptions[0]])
	const [search, setSearch] = React.useState<string>("")
	const [selectedReportID, setSelectedReportID] = React.useState("")
	const [selectedRows, setSelectedRows] = React.useState<MileageClaim[]>([])
	const [showBulkPDFModel, setShowBulkPDFModel] = React.useState(false)

	const [selectAll, setSelectAll] = React.useState(false)
	const onSelectAll = () => {
		setSelectAll(!selectAll)
		if (selectAll) {
			setSelectedRows([])
			return
		}
		setSelectedRows(rows)
	}

	const handleReadyClaimsClick = () => {
		setFilter([{ id: "PayrollReady", label: "Payroll Ready" }])
	}

	const [rows, setRows] = React.useState<MileageClaim[]>([])
	const { sortColumn, sortAsc, limit, offset, setTotal, total, setOffset, handleSort } = useListTable({
		sortColumn: "PeriodStart",
		sortAsc: false,
	})
	const { payload, loading, error, query } = useQuery<{ mileageClaims: MileageClaim[]; total: number }>(
		fetching.query.getMileageClaimMany({
			search: {
				search: search.length >= SearchTextMinLength ? search : undefined,
				filterBy: filter[0].id?.toString(),
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
			},
			limit,
			offset,
		}),
	)

	React.useEffect(() => {
		if (!payload) return
		setRows(payload.mileageClaims)
		setTotal(payload.total)
	}, [payload, setTotal])

	React.useEffect(() => {
		setOffset(0)
	}, [search, setOffset])

	React.useEffect(() => {
		getMileageClaimsReady()
	}, [getMileageClaimsReady])

	return (
		<ZenCard style={{ height: "100%" }}>
			<div className={title}>
				<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} filterOptions={filterOptions} />
				<PayrollReadyClaims onClick={handleReadyClaimsClick} />
			</div>
			{selectedRows.length > 0 && (
				<ZenButton type={"button"} width={"150px"} onClick={() => setShowBulkPDFModel(true)}>
					Preview &amp; Export
				</ZenButton>
			)}
			<ListTable
				error={error ? genericError : ""}
				isLoading={loading}
				rows={rows}
				onRowClick={(row: MileageClaim) => setSelectedReportID(row.id)}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				handleSort={handleSort}
				columns={[
					{
						id: "bulk",
						header: <Checkbox checked={selectAll} onChange={onSelectAll} />,
						resolver: (row: MileageClaim) => (
							<div
								onClick={(e) => {
									e.stopPropagation()
								}}
							>
								<Checkbox
									checked={selectAll || selectedRows.some((r) => r.id === row.id)}
									onChange={(e) => {
										setSelectedRows((prev) => {
											if (e.currentTarget.checked) {
												return prev.concat(row)
											}
											return prev.filter((r) => r.id !== row.id)
										})
									}}
								/>
							</div>
						),
					},
					{
						id: "PeriodStart",
						header: "From",
						sortable: true,
						resolver: (row: MileageClaim) => moment(row.periodStart).tz(TZString[0].id).format(ZenDateFormat),
					},
					{
						id: "PeriodEnd",
						header: "To",
						sortable: true,
						resolver: (row: MileageClaim) => moment(row.periodEnd).tz(TZString[0].id).format(ZenDateFormat),
					},
					{
						id: "User",
						header: "User",
						resolver: (row: MileageClaim) => `${row.user.firstName} ${row.user.lastName}`,
					},
					{
						id: "Status",
						header: "Status",
						resolver: (row: MileageClaim) => snakeToTitle(getMileageClaimStatus(!!row.completedAt, !!row.readyAt, !!row.checkedAt, row.reviewStatus)),
					},
					{
						id: "ReviewedAt",
						header: "Status Updated",
						resolver: (row: MileageClaim) => {
							const latestDate = getMileageClaimLatestStatusUpdateDate(row);
							return latestDate ? friendlyDate(latestDate, TZString[0]) : "";
						} // Correctly closed function block
					},	
					{
						id: "ReviewedBy",
						header: "Reviewed By",
						resolver: (row: MileageClaim) => getMileageClaimLatestReviewer(row)
					},
					{
						id: "CreatedAt",
						header: "Created",
						sortable: true,
						resolver: (row: MileageClaim) => moment(row.createdAt).format(ZenDateFormat),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{error && <ErrorNotification messageOrPayload={payload} />}
			<Divider style={{ backgroundColor: "white" }} />
			{selectedReportID && (
				<MileageClaimReview
					id={selectedReportID}
					isOpen={!!selectedReportID}
					onClose={() => {
						setSelectedReportID("")
						query()
					}}
					reQuery={() => query()}
				/>
			)}
			<BulkPreviewExportModel show={showBulkPDFModel} mileages={showBulkPDFModel ? selectedRows : []} onClose={() => setShowBulkPDFModel(false)} />
		</ZenCard>
	)
}

const PayrollReadyClaims = (props: { onClick: () => void }) => {
	const [css] = useStyletron()
	const container = css({
		width: "100%",
		marginLeft: "10px",
		cursor: "pointer",
	})
	const { mileageClaimsReady } = ReportsContainer.useContainer()

	let message = ""
	if (mileageClaimsReady === 0) return null
	else if (mileageClaimsReady === 1) {
		message = `There is ${mileageClaimsReady} mileage claim ready to complete.`
	} else {
		message = `There are ${mileageClaimsReady} mileage claims ready to complete.`
	}

	return (
		<div className={container} onClick={props.onClick}>
			<Notification
				kind={KIND.positive}
				overrides={{
					Body: { style: { width: "100%" } },
					InnerContainer: { style: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" } },
				}}
			>
				<Block>{message}</Block>
				<ZenButton altKind="tertiary">View all</ZenButton>
			</Notification>
		</div>
	)
}

interface MileageClaimReviewProps {
	id: string
	isOpen: boolean
	onClose: () => void
	reQuery: () => void
}

interface Params {
	workerID: string;
}

export const MileageClaimReview = (props: MileageClaimReviewProps) => {
	const { id, isOpen, onClose, reQuery } = props

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		height: "70vh",
		width: "95vw",
		flexWrap: "wrap",
		justifyContent: "center",
		position: "relative",
		overflow: "auto",
	})
	const footerContainer = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const totalsContainer = css({
		display: "flex",
		marginBottom: "10px",
	})

	const declinedReasonButton = css({
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
	})
	const footerSubmit = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "10px",
	})
	const userField = css({
		margin: "0 30px 0 0"
	})
	const { hasPermission, currentUser } = AuthContainer.useContainer()
	const { firstName, lastName } = currentUser ?? {}
	const { getMileageClaimsReady } = ReportsContainer.useContainer()
	const { showToast } = useZenToast()

	const [showDeleteModal, setShowDeleteModal] = React.useState(false)
	const [openCompleteConfirmation, setOpenCompleteConfirmation] = React.useState(false)
	const [openUncompleteConfirmation, setOpenUncompleteConfirmation] = React.useState(false)
	const [openCheckConfirmation, setOpenCheckConfirmation] = React.useState(false)
	const [openDeclineReasonModal, setOpenDeclineReasonModal] = React.useState(false)
	const [openUnapproveModal, setOpenUnapproveModal] = React.useState(false)
	const [workerValue, setWorkerValue] = useState<Value>([])
	const [ fieldErrors, setFieldErrors] = useState<Record<string, any>>({});
	const [ approvalErr, setApprovalErr] = useState<boolean>(false)
	const { workerID } = useParams<Params>();

	const { query: mileageClaimQ, loading: mileageClaimQueryLoading, payload: mileageClaimQueryPayload } = useQuery(fetching.query.getMileageClaim(id), false)
	const { periodEnd, periodStart, user } = mileageClaimQueryPayload ?? {}
	const { timezoneID, email: mileageClaimUserEmail, firstName: mileageClaimFirstName, lastName: mileageClaimLastName, id: mileageUserId } = user ?? {}
	const mileageClaimCallback = React.useCallback(() => mileageClaimQ(), [mileageClaimQ])

	const {
		query: travelsClaimQ,
		loading: travelsClaimQueryLoading,
		payload: travelsClaimQueryPayload,
		error: travelsClaimQueryError,
	} = useQuery<Travel[]>(fetching.query.getMileageClaimTravels(id), false)
	
	const travelClaimCallback = React.useCallback(() => travelsClaimQ(), [travelsClaimQ])

	const reviewClaim = useMutation(fetching.mutation.reviewMileageClaim)
	const completeClaim = useMutation(fetching.mutation.completeMileageClaim)
	const uncompleteClaim = useMutation(fetching.mutation.uncompleteMileageClaim)
	const unapproveClaim = useMutation(fetching.mutation.unapproveMileageClaim)
	const readyClaim = useMutation(fetching.mutation.mileageClaimReady)
	const deleteClaim = useMutation(fetching.mutation.mileageClaimDelete)
	const checkClaim = useMutation(fetching.mutation.mileageClaimCheck)

	const { setValue, control, errors, handleSubmit } = useForm()
	const onSubmitDeclinedReason = async (formData: { declinedReason: string }) => {
		setOpenDeclineReasonModal(false)
		await handleDecision(ReviewStatus.Declined, formData.declinedReason)
	}

	const status = snakeToTitle(getTimesheetStatus(!!mileageClaimQueryPayload?.completedAt, !!mileageClaimQueryPayload?.readyAt, !!mileageClaimQueryPayload?.checkedAt, mileageClaimQueryPayload?.reviewStatus as ReviewStatus))
	const handleSendEmail = async (data: Value, mileageClaimUserEmail?: string) => {
		try {
			const mileageClaimStatus = 
				(status === "Draft" && mileageClaimQueryPayload?.reviewStatus === "PENDING") ? "Pending" : 
				status === "Pending" ? "Checked" : 
				status === "Checked" ? "Approved" : 
				status === "Approved" ? "Complete" : 
				''
			const { firstName: workerFirstName, lastName: workerLastName, email: workerEmail } = data?.[0] ?? [];
			const workerDetails = {
				firstName: workerFirstName,
				lastName: workerLastName
			};
			const isMileageClaimUserEmail = !!mileageClaimUserEmail;
			const testemail = "byatong@aquip.io"; // will remove this part
			const email = isMileageClaimUserEmail ? mileageClaimUserEmail : workerEmail;
			// const emailDetails = {
			// 	formName: 'Mileage',
			// 	status: mileageClaimStatus,
			// 	receiverDetails: isMileageClaimUserEmail ? 
			// 		{firstName: mileageClaimFirstName, lastName: mileageClaimLastName} 
			// 			: workerDetails,
			// 	startDate: `${moment(periodStart).tz(timezoneID as string).format("MMMM Do YYYY")}`,
			// 	endDate: `${moment(periodEnd).tz(timezoneID as string).format("MMMM Do YYYY")}`,
			// 	...(!mileageClaimUserEmail && {url: `${host.uri}/portal/workers/${workerID}`}),
			// 	bodyMsg: isMileageClaimUserEmail ? 
			// 		"The mileage is approved and sent to payroll for processing." 
			// 			: `There is a mileage record for ${mileageClaimFirstName} ${mileageClaimLastName}, waiting for your review/approval.`,
			// };
			// const emailTemplate = emailNotifTemplate(emailDetails);
			const emailBody = {
				to: email,
				cc: [
					"cc1@example.com",
					"cc2@example.com"
				],
				bcc: [
					"bcc1@example.com",
					"bcc2@example.com"
				],
				subject: isMileageClaimUserEmail ? "Mileage Approved for Payroll Processing" : "Mileage Review/Approval Reminder",
				// body: emailTemplate,
				condition: !isMileageClaimUserEmail,
				formName: 'Mileage',
				status: mileageClaimStatus,
				firstName: isMileageClaimUserEmail ? mileageClaimFirstName?.trim() : workerFirstName?.trim(),
				lastName: isMileageClaimUserEmail ? mileageClaimLastName?.trim() : workerLastName?.trim(),
				fromDate: `${moment(periodStart).tz(timezoneID as string).format("DD MMMM YYYY")}`,
				toDate: `${moment(periodEnd).tz(timezoneID as string).format("DD MMMM YYYY")}`,
				...(!mileageClaimUserEmail && {url: `${host.uri}/portal/workers/${workerID ? workerID : mileageUserId}`}),
				bodyMsg: isMileageClaimUserEmail ? 
					"The mileage request is approved and sent to payroll for processing." 
						: `There is a mileage request for ${mileageClaimFirstName?.trim()} ${mileageClaimLastName?.trim()}, waiting for your review/approval.`,
			};
			
			const response = await fetch(host.email_uri, {
				method: "POST",
				headers:{
					"Content-Type": "application/json",
				},
				body: JSON.stringify(emailBody)
			});

			if(response && response.ok) {
				setWorkerValue([]);
			}

			return response;
		} catch (error) {
			throw new Error(`Error in Mileage handleSendEmail: ${error}`)
		}
	};

	const handleSelectOnChange = (params: Value) => {
		const [values] = params;
		setFieldErrors({});
		setWorkerValue([values]);
	};

	const handleReadyClaim = async () => {
		if(!workerValue?.length || !!workerValue.some(value => value === undefined)) {
			setFieldErrors(
				(prev:Record<string, any>) => ({...prev, ['reviewed_by']: {type: "required",message: `"To be reviewed_by" is required!`}})
			);
			return;
		};
		const response = await handleSendEmail(workerValue);

		if (response && response.ok) {
			const response = await readyClaim.mutate(id);
			if(response.status === 200 && response.error === false) {
				setValue("reviewed_by", "")
			}
			return;
		};
	}; 

	const handleDecision = async (outcome: ReviewStatus, declinedReason?: string) => {
		if(!!declinedReason) {
			await reviewClaim.mutate({ id, outcome: outcome.toUpperCase(), declinedReason })
			await getMileageClaimsReady()
			return;
		} else {
			if((!workerValue?.length || !!workerValue.some(value => value === undefined))) {
				setFieldErrors(
					(prev:Record<string, any>) => ({
						...prev, 
						['approved_by']: {
							type: "required",
							message: `"Submit to payroll" is required!`
						}
					})
				);
				return;
			};

			const response = await handleSendEmail(workerValue);
			if(response && response.ok) {
				await reviewClaim.mutate({ id, outcome: outcome.toUpperCase(), declinedReason })
				const response = await getMileageClaimsReady()
				if(response.status === 200 && response.error === false) {
					setValue("approved_by", "")
				}
				return;
			};
		};
	}

	const handleComplete = async () => {
		if(!workerValue?.length) {
			setApprovalErr(true);
			setFieldErrors(
				(prev:Record<string, any>) => ({
					...prev, 
					['completed_by']: {
						type:"required",
						message:`"To be completed by" is required.`,
						modalErrorMsg:`Please select relevant team member from the list of workers.`}})
			);
			return;
		};

		const response = await handleSendEmail(workerValue, mileageClaimUserEmail);
		if(response && response.ok) {
			await completeClaim.mutate({ id })
			setOpenCompleteConfirmation(false)
			const response = await getMileageClaimsReady()
			if(response.status === 200 && response.error === false) {
				setValue("completed_by", "")
			}
			return;
		};
	}

	const handleUncomplete = async () => {
		await uncompleteClaim.mutate({ id })
		setOpenUncompleteConfirmation(false)
		await getMileageClaimsReady()
	}

	const handleCheck = async () => {
		if(!workerValue?.length || !!workerValue.some(value => value === undefined)) {
			setApprovalErr(true);
			setFieldErrors(
				(prev:Record<string, any>) => ({
					...prev, 
					['checked_by']: { 
						type: "required",
						message:`"To be approved by" is required.`, 
						modalErrorMsg: `Please select relevant team member from the list of workers.`
					}
				})
			);
			return;
		};

		const response = await handleSendEmail(workerValue);
		if(response && response.ok) {
			await checkClaim.mutate(id)
			setOpenCheckConfirmation(false)
			const response = await getMileageClaimsReady()
			if(response.status === 200 && response.error === false) {
				setValue("checked_by", "")
			}
			return;
		};
	}

	const handleUnapprove = async () => {
		await unapproveClaim.mutate({ mileageClaimID: id })
		setOpenUnapproveModal(false)
		await getMileageClaimsReady()
	}

	// Fetch mileage claim and travels when opened
	React.useEffect(() => {
		if (isOpen && id) {
			mileageClaimCallback()
			travelClaimCallback()
		}
	}, [id, isOpen, mileageClaimCallback, travelClaimCallback])

	React.useEffect(() => {
		if (
			!(
				checkClaim.payload ||
				reviewClaim.payload ||
				completeClaim.payload ||
				deleteClaim.payload ||
				readyClaim.payload ||
				unapproveClaim.payload ||
				uncompleteClaim.payload
			)
		)
			return
		setShowDeleteModal(false)
		if (checkClaim.error || reviewClaim.error || completeClaim.error || deleteClaim.error || readyClaim.error || unapproveClaim.error || uncompleteClaim.error)
			return
		reQuery()
		onClose()
	}, [
		checkClaim.payload,
		checkClaim.error,
		reviewClaim.payload,
		completeClaim.payload,
		deleteClaim.payload,
		readyClaim.payload,
		unapproveClaim.payload,
		uncompleteClaim.payload,
		reviewClaim.error,
		completeClaim.error,
		deleteClaim.error,
		readyClaim.error,
		unapproveClaim.error,
		uncompleteClaim.error,
		reQuery,
		onClose,
	])

	const isLoading =
		travelsClaimQueryLoading ||
		mileageClaimQueryLoading ||
		reviewClaim.loading ||
		completeClaim.loading ||
		uncompleteClaim.loading ||
		checkClaim.loading ||
		readyClaim.loading ||
		deleteClaim.loading

	const canMarkReady = (): boolean => {
		if (!currentUser || !mileageClaimQueryPayload) {
			return false
		}
		if (
			(currentUser.id === mileageClaimQueryPayload.user.id || hasPermission(RolePermission.ReportUpdate)) &&
			(!mileageClaimQueryPayload.readyAt || mileageClaimQueryPayload.reviewStatus === ReviewStatus.Declined)
		) {
			return true
		}
		return false
	}

	// Distances in metres
	let totalDistance = 0
	let totalDistancePayable = 0
	let totalDistanceOther = 0
	
	if (travelsClaimQueryPayload && !travelsClaimQueryError) {
		for (const travel of travelsClaimQueryPayload) {
			totalDistance += travel.actualDistanceMetres
			totalDistancePayable += getDistancePayable(travel)
			totalDistanceOther += getDistanceOther(travel)
		}
	}

	const zenModalOnClose = () => {
		setWorkerValue((prev: Value) => ([]));
		const formName = status === "Draft" ? "reviewed_by" :
			status === "Pending" ? "checked_by" :
			status === "Checked" ? "approved_by" :
			status === "Approved" ? "completed_by" :
			"";
			setFieldErrors(
				(prev:Record<string, any>) => ({...prev, [`${formName}`]: {}})
			);
		setValue(formName, "")
		onClose();
	}

	return (
		<>
			<ZenModal isOpen={isOpen} onClose={zenModalOnClose} size="auto">
				<ModalHeader>Mileage Claim Review</ModalHeader>
				<ModalBody className={container}>
					<ReportReviewBody
						refetchMileageClaim={() => {
							mileageClaimCallback()
							travelClaimCallback()
						}}
						totalDistance={totalDistance}
						totalDistancePaid={totalDistancePayable}
						totalDistanceOther={totalDistanceOther}
						mileageClaim={mileageClaimQueryPayload}
						travels={travelsClaimQueryPayload}
						loading={mileageClaimQueryLoading || travelsClaimQueryLoading}
						error={travelsClaimQueryError}
					/>
					{openUnapproveModal && (
						<ZenConfirmModal
							title={"Are you sure you want to un-approve this mileage claim?"}
							action={"Confirm"}
							open={openUnapproveModal}
							setOpen={setOpenUnapproveModal}
							loading={unapproveClaim.loading}
							confirm={handleUnapprove}
						/>
					)}
					{openCompleteConfirmation && (
						<ZenConfirmModal
							title={approvalErr ? fieldErrors['completed_by']?.modalErrorMsg : "Are you sure you want to mark this mileage claim as complete?"}
							action={"Confirm"}
							open={openCompleteConfirmation}
							setOpen={setOpenCompleteConfirmation}
							loading={completeClaim.loading}
							confirm={handleComplete}
							kind={approvalErr ? "danger" : ButtonKind.primary}
							errKey="completed_by"
							setFieldErrors={setFieldErrors}
							setApprovalErr={setApprovalErr}
						/>
					)}
					{openUncompleteConfirmation && (
						<ZenConfirmModal
							title={"Are you sure you want to reverse this mileage claim back to approved state?"}
							action={"Confirm"}
							open={openUncompleteConfirmation}
							setOpen={setOpenUncompleteConfirmation}
							loading={uncompleteClaim.loading}
							confirm={handleUncomplete}
						/>
					)}
					{openCheckConfirmation && (
						<ZenConfirmModal
							title={approvalErr ? fieldErrors['checked_by']?.modalErrorMsg : "Are you sure you want to mark current mileage claim as checked for approval?"}
							action={"Mark Checked"}
							open={openCheckConfirmation}
							setOpen={setOpenCheckConfirmation}
							loading={checkClaim.loading}
							confirm={handleCheck}
							kind={approvalErr ? "danger" : ButtonKind.primary}
							setFieldErrors={setFieldErrors}
							setApprovalErr={setApprovalErr}
						/>
					)}
				</ModalBody>
				<ModalFooter>
					{travelsClaimQueryError && <ErrorNotification messageOrPayload={travelsClaimQueryPayload} />}
					{reviewClaim.error && <ErrorNotification messageOrPayload={reviewClaim.payload} />}
					{completeClaim.error && <ErrorNotification messageOrPayload={completeClaim.payload} />}
					{readyClaim.error && <ErrorNotification messageOrPayload={readyClaim.payload} />}
					{deleteClaim.error && <ErrorNotification messageOrPayload={deleteClaim.payload} />}
					{checkClaim.error && <ErrorNotification messageOrPayload={checkClaim.payload} />}
					{unapproveClaim.error && <ErrorNotification messageOrPayload={unapproveClaim.payload} />}
					<div className={totalsContainer}>
						<LabelMedium marginRight={"15px"} color={ZenTheme.colors.primaryGrey}>
							Total: {(totalDistance / 1000).toFixed(2)}kms
						</LabelMedium>
						<LabelMedium>Total Payable: {(totalDistancePayable / 1000).toFixed(2)}kms</LabelMedium>
					</div>
					<div className={footerContainer}>
						{canMarkReady() && (
							<>
								<ZenButton altKind="danger" onClick={() => setShowDeleteModal(true)}>
									Delete
								</ZenButton>
								<form className={footerSubmit} autoComplete="off" onSubmit={handleSubmit(handleReadyClaim)}>
									<ZenUserDropdownSelect 
										label={"Reviewed by: "} 
										formName={"reviewed_by"} 
										formRef={control}
										inputError={fieldErrors["reviewed_by"]}
										placeholder="To be reviewed by.."
										actionOnChange={handleSelectOnChange}
										width={"360px"} 
									/>
									{/* <ZenButton disabled={isLoading} isLoading={isLoading} onClick={() => readyClaim.mutate(id)}> */}
									<ZenButton type="submit" disabled={isLoading} isLoading={isLoading}>
										Submit
									</ZenButton>
								</form>
							</>
						)}
						{(hasPermission(RolePermission.ReportUpdate) || hasPermission(RolePermission.PayrollComplete)) && mileageClaimQueryPayload?.readyAt && (
							<>
								{mileageClaimQueryPayload &&
									mileageClaimQueryPayload.reviewStatus.toLowerCase() === ReviewStatus.Pending.toLowerCase() &&
									hasPermission(RolePermission.ReportUpdate) && (
										<>
											<div>
												<ZenButton altKind="danger" marginRight={"20px"} onClick={() => setShowDeleteModal(true)}>
													Delete
												</ZenButton>
												<ZenButton altKind={"warning"} disabled={isLoading} isLoading={isLoading} onClick={() => setOpenDeclineReasonModal(true)}>
													Decline
												</ZenButton>
											</div>
											{mileageClaimQueryPayload.checkedAt ? (
												<div>
													<div className={footerSubmit}>
														<ZenUserDropdownSelect 
															label={`Approved by: ${firstName} ${lastName ?? ''}`} 
															formName={"approved_by"} 
															formRef={control}
															inputError={fieldErrors["approved_by"]}
															placeholder="Submit to payroll"
															actionOnChange={handleSelectOnChange}
															width={"360px"} 
														/>
														<ZenButton disabled={isLoading} isLoading={isLoading} onClick={() => handleDecision(ReviewStatus.Approved)}>
															Approve
														</ZenButton>
													</div>
												</div>
											) : (
												<div>
													<div className={footerSubmit}>
														<ZenUserDropdownSelect 
															label={`Checked by: ${firstName} ${lastName ?? ''}`} 
															formName={"checked_by"} 
															formRef={control}
															error={!!fieldErrors["checked_by"]?.message}
															inputError={fieldErrors["checked_by"]}
															placeholder="To be approved by.."
															actionOnChange={handleSelectOnChange}
															width={"360px"} 
														/>
														<ZenButton disabled={isLoading} isLoading={isLoading} onClick={() => setOpenCheckConfirmation(true)}>
															Mark Checked
														</ZenButton>
													</div>
												</div>
											)}
										</>
									)}
								{mileageClaimQueryPayload?.reviewStatus === ReviewStatus.Approved && !mileageClaimQueryPayload.completedAt && (
									<>
										{hasPermission(RolePermission.ReportUpdate) && (
											<ZenButton disabled={isLoading} altKind={"danger"} marginRight={"auto"} isLoading={isLoading} onClick={() => setOpenUnapproveModal(true)}>
												Un-approve
											</ZenButton>
										)}
										{hasPermission(RolePermission.PayrollComplete) && (
											<div>
												<div className={footerSubmit}>
													<ZenUserDropdownSelect 
														label={`Completed by: ${firstName} ${lastName ?? ''}`} 
														formName={"completed_by"} 
														formRef={control}
														error={!!fieldErrors["completed_by"]?.message}
														inputError={fieldErrors["completed_by"]}
														placeholder="Submit to payroll"
														actionOnChange={handleSelectOnChange}
														width={"360px"} 
													/>
													<ZenButton disabled={isLoading} marginLeft={"auto"} isLoading={isLoading} onClick={() => setOpenCompleteConfirmation(true)}>
														Payroll Complete
													</ZenButton>
												</div>
											</div>
										)}
									</>
								)}
							</>
						)}
						{!!mileageClaimQueryPayload?.completedAt && (
							<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
								{/* <LabelMedium>
									Marked complete by {mileageClaimQueryPayload.completedBy?.firstName} {mileageClaimQueryPayload.completedBy?.lastName} on{" "}
									{moment(mileageClaimQueryPayload.completedAt).format(ZenDateFormat)}
								</LabelMedium> */}
								{hasPermission(RolePermission.PayrollComplete) && (
									<ZenButton disabled={isLoading} altKind={"danger"} marginTop="4px" isLoading={isLoading} onClick={() => setOpenUncompleteConfirmation(true)}>
										Payroll Uncomplete
									</ZenButton>
								)}
							</div>
						)}
					</div>
				</ModalFooter>
			</ZenModal>
			<ZenModal
				isOpen={openDeclineReasonModal}
				onClose={() => {
					setValue("declinedReason", "")
					setOpenDeclineReasonModal(false)
				}}
			>
				<ModalHeader>Decline Reason</ModalHeader>
				<ModalBody>
					<form autoComplete="off" onSubmit={handleSubmit(onSubmitDeclinedReason)}>
						<ZenTextArea
							placeholder={"Provide a brief reason why the mileage claim was declined."}
							formRef={control}
							nameRef="declinedReason"
							formRules={{ required: "Declined reason is required" }}
							inputError={errors.declinedReason}
						/>
						<ZenDivider borderColor="transparent" />
						<div className={declinedReasonButton}>
							<ZenButton type="submit">
								<LabelSmall color="white">Submit</LabelSmall>
							</ZenButton>
						</div>
					</form>
				</ModalBody>
			</ZenModal>
			<ZenConfirmModal
				title={"Are you sure you want to delete this mileage claim?"}
				action={"Delete"}
				open={showDeleteModal}
				setOpen={setShowDeleteModal}
				loading={isLoading}
				confirm={async () => {
					const resp = await deleteClaim.mutate(id)
					if (resp.error) return
					showToast("Mileage claim deleted successfully.", "positive")
				}}
			/>
		</>
	)
}

interface ReportReviewBodyProps {
	mileageClaim?: MileageClaim
	totalDistance: number
	totalDistancePaid: number
	totalDistanceOther: number

	travels?: Travel[]
	loading: boolean
	error: unknown

	refetchMileageClaim: () => void
}

const ReportReviewBody = (props: ReportReviewBodyProps) => {
	const { loading, mileageClaim } = props

	const [css] = useStyletron()
	const leftColumn = css({
		flex: 1,
		display: "flex",
		flexDirection: "column",
		maxHeight: "100%",
		marginRight: "20px",
		width: "60%",
	})
	const rightColumn = css({
		backgroundColor: ZenTheme.colors.lightGrey,
		display: "flex",
		minWidth: "40%",
		height: "100%",
		"@media only screen and (max-width: 1440px)": {
			width: "100%",
			height: "100%",
		},
	})
	const greyArea = css({
		backgroundColor: ZenTheme.colors.lightGrey,
		height: "100%",
		width: "100%",
		"@media only screen and (max-width: 1440px)": {
			display: "none",
			height: "0%",
			width: "0%",
		},
	})
	const infoContainer = css({
		display: "flex",
		marginBottom: "10px",
	})
	const buttons = css({
		width: "100%",
		display: "flex",
		marginTop: "10px",
	})
	const top = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
	})

	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const [selectedTravelID, setSelectedTravelID] = React.useState("")
	const [previewModalOpen, setPreviewModalOpen] = React.useState(false)
	const [manageModalOpen, setManageModalOpen] = React.useState(false)
	const [rows, setRows] = React.useState<Travel[]>([])

	const detailsRef = React.useRef<HTMLDivElement>(null)

	const canEdit = (): boolean => {
		if (!mileageClaim) return false

		if (mileageClaim.reviewStatus === ReviewStatus.Approved) {
			return false
		}

		// ready but no update perms
		if (mileageClaim && !!mileageClaim.readyAt && !hasPermission(RolePermission.ReportUpdate)) {
			return false
		}

		// no perms or not own  editing own
		if (!(hasPermission(RolePermission.ReportUpdate) || (currentUser && mileageClaim.user.id === currentUser.id))) {
			return false
		}
		return true
	}

	const isNote = () => {
		setSelectedTravelID('')
	}

	React.useEffect(() => {
		if (!props.travels) return
		if (props.travels && props.travels.length === 0) {
			setRows([])
		}
		setRows(props.travels)
	}, [props.travels])

	if (loading || !mileageClaim) return <Loading />

	return (
		<>
			<div className={leftColumn}>
				<div className={infoContainer}>
					<div className={top}>
						<FieldDisplay label={"Created"}>
							<LabelSmall>{moment(props.mileageClaim?.createdAt).format(ZenDateFormat)}</LabelSmall>
						</FieldDisplay>
						<FieldDisplay label={"User"}>
							<LabelSmall>{`${mileageClaim.user.firstName} ${mileageClaim.user.lastName} | ${snakeToTitle(mileageClaim.user.status || "")}`}</LabelSmall>
						</FieldDisplay>
						<FieldDisplay label={"Period"}>
							<LabelSmall>
								{moment(mileageClaim.periodStart).tz(TZString[0].id).format(ZenDateFormat)} -{" "}
								{moment(mileageClaim.periodEnd).tz(TZString[0].id).format(ZenDateFormat)}
							</LabelSmall>
						</FieldDisplay>
						<div className={buttons}>
							{canEdit() && (
								<ZenButton marginRight="10px" onClick={() => setManageModalOpen(true)}>
									Edit Time Period
								</ZenButton>
							)}
							<ZenButton onClick={() => setPreviewModalOpen(true)}>Preview & Export</ZenButton>
							<ZenButton marginLeft={"10px"} onClick={() => isNote()}>
								Notes
							</ZenButton>
						</div>
					</div>
					{mileageClaim.checkedBy && mileageClaim.checkedAt && (
						<ReviewStamp noticeType={"CHECKED"} byUser={mileageClaim.checkedBy} time={moment(mileageClaim.checkedAt)} />
					)}
					{mileageClaim.reviewedBy && mileageClaim.reviewedAt && (
						<>
							{mileageClaim.reviewStatus === ReviewStatus.Pending && (
								<ReviewStamp noticeType={"UNAPPROVED"} byUser={mileageClaim.reviewedBy} time={moment(mileageClaim.reviewedAt)} />
							)}
							{mileageClaim.reviewStatus !== ReviewStatus.Pending && (
								<ReviewStamp
									noticeType={mileageClaim.reviewStatus === ReviewStatus.Approved ? "APPROVED" : "DECLINED"}
									byUser={mileageClaim.reviewedBy}
									time={moment(mileageClaim.reviewedAt)}
									reason={mileageClaim.declinedReason}
								/>
							)}
						</>
					)}
					{mileageClaim.checkedBy && mileageClaim.completedBy && mileageClaim.reviewedBy && (
						<ReviewStamp noticeType={"PAYROLL COMPLETED"} byUser={mileageClaim.completedBy} time={moment(mileageClaim.completedAt)} />
					)}
				</div>
				{!props.error && (
					<ZenCard style={{ margin: "3px", minHeight: 0 }}>
						<Block display="flex" height="100%" overflow="auto">
							<TravelTable
								mileageClaim={props.mileageClaim}
								onRowClick={(row: Travel) => {
									setSelectedTravelID(row.id)
									detailsRef.current?.scrollIntoView({ behavior: "smooth" })
								}}
								rows={rows}
							/>
						</Block>
					</ZenCard>
				)}
			</div>
			<div ref={detailsRef} className={rightColumn}>
				{selectedTravelID && <TravelView id={selectedTravelID} refetchMileageClaim={props.refetchMileageClaim} />}
				{!selectedTravelID && (<div className={greyArea}><MileageNoteArea mileage={mileageClaim} loading={props.loading} refetchTimesheet={props.refetchMileageClaim}/></div>)}
				{/* {!selectedTravelID && <div className={greyArea} />} */}
			</div>

			{previewModalOpen && (
				<PreviewExportModal
					totalDistance={props.totalDistance}
					totalDistanceOther={props.totalDistanceOther}
					totalDistancePaid={props.totalDistancePaid}
					mileageClaim={mileageClaim}
					rows={rows}
					setIsOpen={setPreviewModalOpen}
				/>
			)}

			{manageModalOpen && (
				<Modal isOpen={manageModalOpen} closeable={false}>
					<MileageClaimManage
						milageClaim={mileageClaim}
						onSuccess={() => {
							setManageModalOpen(false)
							props.refetchMileageClaim()
						}}
						onCancel={() => setManageModalOpen(false)}
					/>
				</Modal>
			)}
		</>
	)
}

const getDistancePayable = (travel: Travel): number => {
	if (!travel.travelType.distancePayable || travel.car) {
		return 0
	}

	if (!!travel.maximumDistancePayable) {
		if (travel.actualDistanceMetres > travel.maximumDistancePayable) {
			return travel.actualDistanceMetres - travel.maximumDistancePayable
		}
		return 0
	}

	return travel.actualDistanceMetres
}

const getDistanceOther = (travel: Travel): number => {
	if (!travel.travelType.distancePayable || travel.car) {
		return travel.actualDistanceMetres
	}

	if (!!travel.maximumDistancePayable) {
		if (travel.actualDistanceMetres > travel.maximumDistancePayable) {
			return travel.actualDistanceMetres - (travel.actualDistanceMetres - travel.maximumDistancePayable)
		}
		return travel.actualDistanceMetres
	}

	return 0
}

const TravelTable = (props: { onRowClick: (row: Travel) => void; rows: Travel[]; mileageClaim?: MileageClaim }) => {
	const [css] = useStyletron()
	const { currentUser, hasPermission } = AuthContainer.useContainer()

	const canEdit = (travel: Travel): boolean => {
		const ready = !!(props.mileageClaim && !!props.mileageClaim.readyAt)
		// mileage locked (approved + completed)
		if (travel.isLocked) {
			return false
		}

		// ready but no update perms
		if (ready && !hasPermission(RolePermission.TravelUpdate)) {
			return false
		}

		// no perms or not own  editing own timesheet
		if (!(hasPermission(RolePermission.TravelUpdate) || (currentUser && travel.workerID === currentUser.id))) {
			return false
		}
		return true
	}
	const group = css({
		display: "flex",
		alignItems: "center",
	})

	return (
		<ListTable
			rows={props.rows}
			onRowClick={props.onRowClick}
			columns={[
				{
					id: "Date",
					header: "Date",
					sortable: true,
					resolver: (row: Travel) => {
						const tz = TZString.find((tzs) => tzs.id === row.timezoneID) || TZString[0]
						return (
							<StatefulTooltip
								content={() => (row.fromAddress.placeID === "" || row.toAddress.placeID === "" ? "contain invalid addresses" : null)}
								triggerType="hover"
								returnFocus
								autoFocus
								placement="right"
							>
								<div className={group}>
									{friendlyDate(row.startTime, tz)}
									{(row.fromAddress.placeID === "" || row.toAddress.placeID === "") && (
										<Block marginLeft="3px">
											<FontAwesomeIcon size={"lg"} color={ZenTheme.colors.warning300} icon={["fal", "exclamation-triangle"]} />
										</Block>
									)}
								</div>
							</StatefulTooltip>
						)
					},
				},
				{
					id: "StartTime",
					header: "Start Time",
					resolver: (row: Travel) => {
						const tz = TZString.find((tzs) => tzs.id === row.timezoneID) || TZString[0]
						return (
							<>
								{friendlyTime(tz, row.startTime)}
								<br />
								<span style={{ color: "grey" }}>{row.timezoneID}</span>
							</>
						)
					},
				},
				{
					id: "TravelType",
					header: "Travel Type",
					resolver: (row: Travel) => row.travelType.label,
				},
				{
					id: "From",
					header: "From",
					resolver: (row: Travel) => row.fromAddress.fullAddress,
				},
				{
					id: "To",
					header: "To",
					resolver: (row: Travel) => row.toAddress.fullAddress,
				},
				{
					id: "Payable",
					header: "Distance Payable",
					resolver: (row: Travel) => `${(getDistancePayable(row) / 1000).toFixed(2)}kms`,
				},
				{
					id: "Other",
					header: "Distance Other",
					resolver: (row: Travel) => `${(getDistanceOther(row) / 1000).toFixed(2)}kms`,
				},
				{
					id: "viewEdit",
					header: "View/Edit",
					resolver: (row: Travel) => (
						<div>
							<Button kind={"minimal"} size={"compact"}>
								<FontAwesomeIcon icon={["fas", "eye"]} color="grey" />
								{canEdit(row) && <FontAwesomeIcon icon={["fal", "pencil"]} color="grey" />}
							</Button>
						</div>
					),
					omitted: !props.mileageClaim,
				},
			]}
		/>
	)
}

interface PreviewExportModalProps {
	setIsOpen: (b: boolean) => void
	rows: Travel[]
	mileageClaim: MileageClaim
	totalDistance: number
	totalDistancePaid: number
	totalDistanceOther: number
}

const PreviewExportModal = (props: PreviewExportModalProps) => {
	const [css] = useStyletron()
	const tableContainer = css({
		marginTop: "50px",
		height: "90vh",
	})

	return (
		<ZenModal isOpen={true} onClose={() => props.setIsOpen(false)} size="full">
			<ModalBody className={tableContainer}>
				<MileageClaimPDFPreview claims={[{ ...props }]} />
			</ModalBody>
		</ZenModal>
	)
}

interface BulkPreviewExportModelProps {
	show: boolean
	mileages: MileageClaim[]
	onClose: () => void
}

const BulkPreviewExportModel = (props: BulkPreviewExportModelProps) => {
	const { show, mileages } = props
	const [travels, setTravels] = React.useState<{ id: string; rows: Travel[] }[]>([])
	const { query: travelsQuery, payload: travelsPayload, error: travelsError } = useParameterizedQuery(fetching.query.getMileageClaimTravels)

	React.useEffect(() => {
		setTravels([])
	}, [mileages])

	React.useEffect(() => {
		if (travels.length >= mileages.length) return
		travelsQuery(mileages[travels.length].id)
	}, [mileages, travels, travelsQuery])

	React.useEffect(() => {
		setTravels((prev) => {
			if (!travelsPayload) return prev
			if (!mileages[prev.length]) return prev
			return prev.concat({
				id: mileages[prev.length].id,
				rows: travelsPayload,
			})
		})
	}, [mileages, travelsPayload])

	const [css] = useStyletron()
	const tableContainer = css({
		marginTop: "50px",
		height: travels.length === mileages.length ? "90vh" : undefined,
	})

	return (
		<ZenModal isOpen={show && mileages.length > 0} onClose={() => props.onClose()} size={travels.length === mileages.length ? "full" : "auto"}>
			<ModalBody className={tableContainer}>
				{/* Show Progress Bar while loading */}
				{travels.length < mileages.length && (
					<>
						<HeadingMedium>Generating Mileage Claim PDF</HeadingMedium>
						{travelsError && <ErrorNotification messageOrPayload={"An error occurred while loading Mileage data, please try again"} />}
						<ProgressBar
							value={Math.round((travels.length / mileages.length) * 100)}
							overrides={{
								BarProgress: {
									style: ({ $theme, $value }) => {
										return {
											...$theme.typography.font350,
											backgroundColor: travelsError ? $theme.colors.negative : $theme.colors.positive,
											color: $theme.colors.mono200,
											position: "relative",
											":after": {
												position: "absolute",
												content: $value > 5 ? `"${$value}%"` : "",
												right: "10px",
											},
										}
									},
								},
								Bar: {
									style: ({ $theme }) => ({
										height: $theme.sizing.scale800,
									}),
								},
							}}
						/>
					</>
				)}

				{/* PDF Preview + Export */}
				{travels.length === mileages.length && (
					<MileageClaimPDFPreview
						fileName={mileages.length > 1 ? "Mileage_claims_bulk.pdf" : undefined}
						claims={mileages.map((m) => {
							const rows = travels.find((t) => t.id === m.id)?.rows || []

							let total = 0
							let totalPayable = 0
							let totalOther = 0

							for (const travel of rows) {
								total += travel.actualDistanceMetres
								totalPayable += getDistancePayable(travel)
								totalOther += getDistanceOther(travel)
							}

							return {
								mileageClaim: m,
								rows,
								totalDistance: total,
								totalDistancePaid: totalPayable,
								totalDistanceOther: totalOther,
							}
						})}
					/>
				)}
			</ModalBody>
		</ZenModal>
	)
}
