import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Option, Value } from "baseui/select"
import { Spinner, StyledSpinnerNext } from "baseui/spinner"
import { LabelLarge, LabelMedium, LabelSmall, ParagraphSmall, ParagraphXSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import { useStateMachine } from "little-state-machine"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { routes } from "routes"
import { ZenTheme } from "themeOverrides"
import { updateCallLogCreate } from "../../actions/callLogCreate"
import { AuthContainer } from "../../controllers/auth"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { getError } from "../../helpers/errors"
import { alignDate, snakeToTitle, timeDuration } from "../../helpers/utils"
import { callLogCreateInitialState } from "../../states/callLogCreate"
import { FilterBy, SortBy, SortDir } from "../../types/enums"
import { Alert, BasicLabel, BasicName, CallLog, CallPurpose, Client, ClientDetail, RolePermission, UserDetail } from "../../types/types"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { ClientCreateForm } from "../clients/clientCreateForm"
import { Divider, Spacer, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { Loading } from "../loading"
import { ClientSingleSessionSteps, SingleClientSessionInput } from "../sessions/newClientAppointment/singleSessionBaseForm"
import { AppointmentConfirmation } from "../sessions/newClientAppointment/singleSessionConfirmation"
import { NewClientSessionGeneral } from "../sessions/newClientAppointment/singleSessionGeneralForm"
import { ClientNotesForm } from "../sessions/newClientAppointment/singleSessionNotesForm"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton, ZenButtonGroup } from "../zenComponents/zenButtons"
import { ZenConfirmModal } from "../zenComponents/zenConfirmModal"
import { ZenInput, ZenTextArea } from "../zenComponents/zenInput"
import { ZenPagination } from "../zenComponents/zenPagination"
import { ZenProgressBar } from "../zenComponents/zenProgressBar"
import { ZenClientSelect, ZenSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../zenComponents/zenTime"
import { ErrorFieldTracker } from "../forms/errorFieldTracker"

export enum CallFromClientOptions {
	CallFromExistingClient = "CALL_FROM_EXISTING_CLIENT",
	CallAboutAClient = "CALL_ABOUT_A_CLIENT",
	CallFromNewClient = "CALL_FROM_NEW_CLIENT",
	AnonymousCaller = "ANONYMOUS_CALLER",
}

enum CallFromContainClientOptions {
	CallFromExistingClient = "CALL_FROM_EXISTING_CLIENT",
	CallAboutAClient = "CALL_ABOUT_A_CLIENT",
}

export enum CallTypeOptions {
	IncomingCall = "INCOMING_CALL",
	OutgoingCall = "OUTGOING_CALL",
}

export const CallLogCreateForm = () => {
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])

	const createThroughClientTab = !!searchArgs.get("client_id")

	const { showToast } = useZenToast()
	const { errors, handleSubmit, control, watch, setValue, getValues } = useForm()
	const { hasPermission, currentUser } = AuthContainer.useContainer()
	const { mutate, payload: response, error, loading } = useMutation<CallLog>(fetching.mutation.callLogCreate)

	const watchStartTime = watch("startTime")
	const watchEndTime = watch("endTime")

	// call log form persist data
	const isResumable = React.useRef(true)
	const { state, actions } = useStateMachine({
		updateCallLogCreate,
	})

	const [selectedCallPurpose, setSelectedCallPurpose] = React.useState<CallPurpose>()
	const [callPurposeOptions, setCallPurposeOptions] = React.useState<Option[]>([])
	const callPurposeData = useQuery<CallPurpose[]>(fetching.query.getCallPurposeAll())
	React.useEffect(() => {
		if (callPurposeData.loading || !callPurposeData.payload) return
		setCallPurposeOptions(callPurposeData.payload)
	}, [callPurposeData.payload, callPurposeData.error, callPurposeData.loading])

	// options for call purpose type
	const [callPurposeTypeOptions, setCallPurposeTypeOptions] = React.useState<Option[]>([])
	const {
		query: callPurposeTypesQuery,
		payload: callPurposeTypesPayload,
		error: callPurposeTypesError,
		loading: callPurposeTypesLoading,
	} = useParameterizedQuery<BasicLabel[]>(fetching.query.getCallPurposeTypesByCallPurposeID)

	React.useEffect(() => {
		if (!selectedCallPurpose?.id) return
		callPurposeTypesQuery(selectedCallPurpose.id)
	}, [selectedCallPurpose, callPurposeTypesQuery])
	const loadCallPurposeTypes = React.useCallback(() => {
		if (callPurposeTypesLoading || callPurposeTypesError || !callPurposeTypesPayload) return
		setCallPurposeTypeOptions(callPurposeTypesPayload)
	}, [callPurposeTypesPayload, callPurposeTypesError, callPurposeTypesLoading, setCallPurposeTypeOptions])
	React.useEffect(() => {
		loadCallPurposeTypes()
	}, [loadCallPurposeTypes])

	// options for departments
	const [departmentOptions, setDepartmentOptions] = React.useState<Option[]>([])
	const departmentData = useQuery<BasicName[]>(fetching.query.getDepartmentAll())
	React.useEffect(() => {
		if (departmentData.loading || !departmentData.payload) return
		setDepartmentOptions(departmentData.payload.map<Option>((d) => ({ ...d, label: d.name })))
	}, [departmentData.payload, departmentData.loading, departmentData.error])

	// handle client alerts model
	const selectedClient = watch("client")
	const [showAlertsModel, setShowAlertsModel] = React.useState(false)

	// handle transfer to staff worker
	const selectedTransferredTo = watch("transferredTo")

	// handle create new client
	const [newClient, setNewClient] = React.useState<Client>()
	const createNewClient = (c: Client) => {
		setNewClient(c)
		setValue("client", [{ ...c, label: `${c.firstName} ${c.lastName}` }])
		setExcludedClientID([c.id])
	}

	// handle excluded workers and clients
	const [workerID, setWorkerID] = React.useState<string>("")
	const [excludedUserID, setExcludedUserID] = React.useState<string[]>([])
	const [excludedClientID, setExcludedClientID] = React.useState<string[]>([])
	React.useEffect(() => {
		if (currentUser) setWorkerID(currentUser.id)
	}, [currentUser])

	React.useEffect(() => {
		const idList: string[] = []
		if (workerID !== "") idList.push(workerID)
		setExcludedUserID(idList)
	}, [workerID])

	const onCreate = async (formData: any) => {
		// submit call log
		const resp = await mutate({
			callType: CallTypeOptionKey,
			workerID: formData.worker[0].id,
			startTime: alignDate(formData.date, formData.startTime),
			endTime: alignDate(formData.date, formData.endTime),
			callFrom: CallFromOptionKey,
			callerName: formData.callerName,
			clientID: CallFromOptionKey !== CallFromClientOptions.AnonymousCaller ? formData.client[0].id : undefined,
			phoneNumber: formData.phoneNumber,
			callPurposeID: formData.callPurpose[0].id,
			callPurposeTypeIDList:
				callPurposeTypeOptions.length > 0 && formData.callPurposeType && formData.callPurposeType.length > 0
					? formData.callPurposeType.map((cpt: { id: string }) => cpt.id)
					: undefined,
			transferredToDepartmentID: formData.transferredTo && formData.transferredTo.length > 0 ? formData.transferredTo[0].id : undefined,
			transferredToWorkerID: formData.transferredToWorker && formData.transferredToWorker.length > 0 ? formData.transferredToWorker[0].id : undefined,
			note: formData.note && formData.note.length > 0 ? formData.note : undefined,
		})

		if (resp.error || !resp.payload) return

		showToast("Call Log created successfully.", "positive")
		actions.updateCallLogCreate(callLogCreateInitialState())

		isResumable.current = false

		// return back to client session tab, if client id exists
		const returnClientID = searchArgs.get("client_id")
		if (returnClientID) {
			history.push({
				pathname: routes.withID(returnClientID, routes.clients.client.root),
				hash: "callLogs",
			})
			return
		}

		history.push(`/portal/call-logs/${resp.payload}`)
	}

	// Action for the button group
	const [openModal, setOpenModal] = React.useState<boolean>(false)
	const [openDialog, setOpenDialog] = React.useState(false)
	const [CallTypeOptionKey, setCallTypeOptionKey] = React.useState<string>(state.callLogCreate.callTypeOptionKey || Object.values(CallTypeOptions)[0])
	const [CallFromOptionKey, setCallFromOptionKey] = React.useState<string>(state.callLogCreate.callFromOptionKey || Object.values(CallFromClientOptions)[0])
	const callTypeActionOnSelect = (id: string) => {
		setCallTypeOptionKey(id)
	}
	const actionOnSelect = (id: string) => {
		setCallFromOptionKey(id)
		if (id === CallFromClientOptions.CallFromNewClient) {
			setValue("client", newClient ? [{ ...newClient, label: `${newClient.firstName} ${newClient.lastName}` }] : [])
			setOpenModal(true)
		}
	}

	// Load and Persist Form Data (when user leaves the page)
	const loadDefaults = React.useCallback(() => {
		const values = state.callLogCreate
		setValue("client", values.client)
		setValue("callPurpose", values.callPurpose)
		setValue("callPurposeType", values.callPurposeType)
		setValue("callerName", values.callerName)
		setValue("phoneNumber", values.phoneNumber)
		setValue("date", new Date(values.date))
		setValue("startTime", values.startTime)
		setValue("endTime", values.endTime ? new Date() : undefined)
		setValue("note", values.note)
		setValue("transferredTo", values.transferredTo)
		setValue("transferredToWorker", values.transferredToWorker)

		if (values.worker && values.worker.length > 0) {
			setWorkerID(values.worker[0].id)
		}
		if (values.client && values.client.length > 0 && !!values.client[0].id) {
			setExcludedClientID([values.client[0].id.toString()])
		}
		if (values.callPurpose && values.callPurpose.length > 0) {
			setSelectedCallPurpose(values.callPurpose[0] as CallPurpose)
		}
	}, [setValue]) // eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		loadDefaults()
	}, [loadDefaults])

	React.useEffect(() => {
		return () => {
			if (!isResumable.current || response) return
			const formValues = getValues()
			actions.updateCallLogCreate({
				stale: true,
				callPurpose: formValues.callPurpose,
				callPurposeType: formValues.callPurposeType,
				client: CallFromOptionKey !== CallFromClientOptions.AnonymousCaller ? formValues.client : [],
				worker: formValues.worker,
				callTypeOptionKey: CallTypeOptionKey,
				callFromOptionKey: CallFromOptionKey,
				callerName: formValues.callerName,
				phoneNumber: formValues.phoneNumber,
				date: formValues.date,
				startTime: formValues.startTime,
				endTime: formValues.endTime,
				transferredTo: formValues.transferredTo,
				transferredToWorker: formValues.transferredToWorker,
				note: formValues.note,
			})
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// pre-fill client if create through client tab
	const { payload: clientData, query } = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	React.useEffect(() => {
		const clientID = searchArgs.get("client_id")
		if (!clientID || clientID === "") return
		query(clientID)
	}, [query, searchArgs])

	React.useEffect(() => {
		if (!clientData) return
		setValue("client", [{ ...clientData, label: `${clientData.firstName} ${clientData.lastName}` }])
	}, [clientData, setValue])

	// Auto-fill phone number if call from existing client
	const autoFillPhone = React.useCallback(() => {
		if (CallFromOptionKey === CallFromClientOptions.CallFromExistingClient && !!selectedClient && selectedClient.length > 0)
			setValue("phoneNumber", selectedClient[0]?.currentContact?.mobileNumber || selectedClient[0]?.currentContact?.telephoneNumber)
	}, [selectedClient, CallFromOptionKey, setValue])
	React.useEffect(() => {
		autoFillPhone()
	}, [autoFillPhone])

	const [css] = useStyletron()
	const form = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
	})
	const body = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		minHeight: 0,
		position: "relative",
	})
	const scrollingDiv = css({
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "8px",
	})
	const rangeTimeStyle = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const durationContainer = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
	})

	if (loading) return <Loading />
	if (error) return <ErrorNotification messageOrPayload={response} />

	let defaultWorker = currentUser
	if (state.callLogCreate.worker && state.callLogCreate.worker.length > 0) {
		defaultWorker = state.callLogCreate.worker[0]
	}

	let defaultClient: Client | null = null
	if (state.callLogCreate.client && state.callLogCreate.client.length > 0) {
		defaultClient = state.callLogCreate.client[0]
	}

	let defaultTransferToWorker: UserDetail | null = null
	if (state.callLogCreate.transferredToWorker && state.callLogCreate.transferredToWorker.length > 0) {
		defaultTransferToWorker = state.callLogCreate.transferredToWorker[0]
	}

	return (
		<>
			<form autoComplete="off" className={form} onSubmit={handleSubmit(onCreate)}>
				<div className={body}>
					<LabelLarge marginBottom={"15px"}>
						New Call Log{searchArgs.get("client_name") && ` - ${snakeToTitle(searchArgs.get("client_name") || "")}`}
					</LabelLarge>
					<div className={scrollingDiv}>
						<ZenButtonGroup
							label="Call Type"
							data={Object.values(CallTypeOptions).map((c) => ({
								id: c,
								label: snakeToTitle(c),
							}))}
							currentKey={CallTypeOptionKey}
							actionOnSelect={callTypeActionOnSelect}
						/>

						<ZenUserSelect
							label="Worker"
							formName="worker"
							formRef={control}
							excludedID={excludedUserID}
							defaultValue={defaultWorker ? [defaultWorker] : undefined}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
								},
							}}
							inputError={errors.worker}
							actionOnChange={(w: Value) => setWorkerID(w.length > 0 && w[0].id ? w[0].id.toString() : "")}
						/>

						<div className={rangeTimeStyle}>
							<ZenTimePicker
								label="Start time"
								formName="startTime"
								date={watch("date")}
								formRef={control}
								inputError={errors.startTime}
								minuteStep={1}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "You must select a start time"
											}
											if (watchEndTime && moment(value).isSameOrAfter(watchEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
								width="100%"
							/>
							<Spacer style={{ minWidth: "12px" }} />
							<div
								style={{
									width: "100%",
								}}
							>
								<ZenDatePicker label="Date" formName="date" formRef={control} clearable={false} />
							</div>
						</div>

						<Divider style={{ backgroundColor: "transparent" }} />

						<ZenButtonGroup
							label={CallTypeOptionKey === CallTypeOptions.IncomingCall ? "Call From" : "Call To"}
							data={Object.values(createThroughClientTab ? CallFromContainClientOptions : CallFromClientOptions)
								.filter((co) => hasPermission(RolePermission.ClientUpdate) || co !== CallFromClientOptions.CallFromNewClient)
								.map((c) => {
									let label = snakeToTitle(c)
									switch (c) {
										case CallFromClientOptions.CallFromExistingClient:
											if (CallTypeOptionKey === CallTypeOptions.OutgoingCall) {
												label = "Call To Existing Client"
											}
											break
										case CallFromClientOptions.CallFromNewClient:
											if (CallTypeOptionKey === CallTypeOptions.OutgoingCall) {
												label = "Call To New Client"
											}
											break
									}

									return { id: c, label }
								})}
							currentKey={CallFromOptionKey}
							actionOnSelect={actionOnSelect}
						/>
						{CallFromOptionKey === CallFromClientOptions.CallAboutAClient && (
							<ZenInput
								label="Name of the caller"
								nameRef="callerName"
								placeholder="Enter caller's name"
								inputError={errors.callerName}
								formRef={control}
								required
							/>
						)}
						{CallFromOptionKey !== CallFromClientOptions.AnonymousCaller && (
							<>
								<ZenClientSelect
									label="Client"
									clearable
									formName="client"
									formRef={control}
									inputError={errors.client}
									defaultValue={defaultClient ? [defaultClient] : undefined}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length > 0) || "Client is required",
										},
									}}
									disabled={CallFromOptionKey === CallFromClientOptions.CallFromNewClient || createThroughClientTab}
									options={
										createThroughClientTab ? (clientData ? [{ ...clientData, label: `${clientData.firstName} ${clientData.lastName}` }] : []) : undefined
									}
									excludedID={excludedClientID}
								/>
								{selectedClient && selectedClient.length > 0 && (
									<ZenButton type={"button"} onClick={() => setShowAlertsModel(true)} marginTop="5px">
										View Alerts
									</ZenButton>
								)}
							</>
						)}

						<ZenInput
							// Use generic field so to allow for private numbers
							placeholder={"Phone number"}
							inputError={errors.phoneNumber}
							label={"Phone Number"}
							nameRef={"phoneNumber"}
							marginBottom="0"
							marginTop="0"
							formRef={control}
							required // A call log needs a related number, 'Private Number', or note
						/>

						<ZenSelect
							label="Purpose of Call"
							formName="callPurpose"
							options={callPurposeOptions}
							inputError={errors.callPurpose}
							formRef={control}
							actionOnChange={(v) => {
								setValue("callPurposeType", [])
								setSelectedCallPurpose(undefined)
								if (!v.length || !v[0].id) {
									setCallPurposeTypeOptions([])
									return
								}
								setSelectedCallPurpose(v[0] as CallPurpose)
							}}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || `Purpose of call required`,
								},
							}}
						/>

						{callPurposeTypeOptions.length > 0 && (
							<ZenSelect
								label="Type"
								formName="callPurposeType"
								options={callPurposeTypeOptions}
								formRef={control}
								multi={selectedCallPurpose?.acceptMultiTypes}
							/>
						)}

						<Divider style={{ backgroundColor: "transparent" }} />

						<ZenSelect label="Transferred to" formName="transferredTo" options={departmentOptions} formRef={control} />

						{selectedTransferredTo && selectedTransferredTo.length > 0 && selectedTransferredTo[0].label === "Staff Member" && (
							<ZenUserSelect
								label={"Transferred to Worker"}
								formName={"transferredToWorker"}
								formRef={control}
								defaultValue={defaultTransferToWorker ? [defaultTransferToWorker] : []}
								excludedID={currentUser ? [currentUser.id] : []}
								inputError={errors.transferredToWorker}
								formRules={{
									validate: {
										required: (value: Value) => (!!value && value.length > 0) || `transferred worker is required`,
									},
								}}
							/>
						)}

						<ZenTextArea placeholder={"Notes"} inputError={errors.note} label={"Notes"} nameRef={"note"} formRef={control} />

						<Divider style={{ backgroundColor: "transparent" }} />

						<div className={rangeTimeStyle}>
							<ZenTimePicker
								label="End time"
								formName="endTime"
								date={watch("date")}
								formRef={control}
								inputError={errors.endTime}
								minuteStep={1}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "You must select an end time"
											}
											if (watchStartTime && moment(value).isSameOrBefore(watchStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
								width="100%"
							/>
							<Spacer style={{ minWidth: "12px" }} />
							<div className={durationContainer}>
								<FormControl
									overrides={{
										ControlContainer: {
											style: {
												marginBottom: 0,
											},
										},
									}}
									label={<LabelSmall>Duration</LabelSmall>}
								>
									<Input
										overrides={{
											Input: {
												style: {
													minHeight: "48px",
												},
											},
										}}
										disabled
										value={watchStartTime && watchEndTime ? timeDuration(watchStartTime, watchEndTime) : "0h 0m"}
									/>
								</FormControl>
							</div>
						</div>
					</div>
					<ErrorFieldTracker errorIDs={Object.keys(errors)} position="end" />
					<CancelAndSaveButtons cancelFn={() => setOpenDialog(true)} />
				</div>
			</form>
			<CreateNewClientModal isOpen={openModal} setIsOpen={setOpenModal} setCreatedClient={createNewClient} />
			<AlertsModel
				isOpen={showAlertsModel}
				clientID={selectedClient && selectedClient.length > 0 ? selectedClient[0].id : undefined}
				setIsOpen={setShowAlertsModel}
			/>

			{openDialog && (
				<ZenConfirmModal
					open={openDialog}
					setOpen={setOpenDialog}
					loading={false}
					title={"Are you sure you want to delete"}
					message={"Call log draft?"}
					action={"Delete"}
					confirm={() => {
						setOpenModal(false)
						isResumable.current = false

						actions.updateCallLogCreate(callLogCreateInitialState())

						// return back to client session tab, if client id exists
						const returnClientID = searchArgs.get("client_id")
						if (returnClientID) {
							history.push({
								pathname: routes.withID(returnClientID, routes.clients.client.root),
								hash: "callLogs",
							})
							return
						}

						// otherwise, return to call log list
						history.push(routes.callLogs.root)
					}}
				/>
			)}
		</>
	)
}

interface CreateNewClientModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setCreatedClient: (c: Client) => void
}
export const CreateNewClientModal = (props: CreateNewClientModalProps) => {
	const { isOpen, setIsOpen, setCreatedClient } = props
	const [client, setClient] = React.useState<Client>()
	const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false)
	const clearData = () => {
		setClient(undefined)
	}

	const onCreateClient = (c: Client) => {
		setCreatedClient(c)
		setClient(c)
	}

	const onComplete = () => {
		clearData()
		setIsOpen(false)
	}

	const onClose = () => {
		if (client) {
			// if client is created trigger confirmation
			setOpenConfirmation(true)
			return
		}
		setIsOpen(false)
	}
	const displayForm = () => {
		// use isOpen variable to clean up the variables from react use form
		if (!isOpen) return null
		if (!client) return <CallLogCreateClient onCreateClient={onCreateClient} />
		return <BookSession client={client} onComplete={onComplete} setOpenConfirmation={setOpenConfirmation} />
	}

	return (
		<ZenModal onClose={onClose} isOpen={isOpen} role={ROLE.dialog}>
			<ModalHeader>{client ? "Book a Session" : ""}</ModalHeader>
			<ModalBody>{displayForm()}</ModalBody>
			<ConfirmationModal isOpen={openConfirmation} setIsOpen={setOpenConfirmation} setOpenParentModal={setIsOpen} clearData={clearData} />
		</ZenModal>
	)
}

interface ConfirmationModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setOpenParentModal: React.Dispatch<React.SetStateAction<boolean>>
	clearData: () => void
}
const ConfirmationModal = (props: ConfirmationModalProps) => {
	const { isOpen, setIsOpen, setOpenParentModal, clearData } = props
	const [css] = useStyletron()
	const container = css({
		height: "80px",
		width: "465px",
	})
	return (
		<ZenModal isOpen={isOpen} role={ROLE.dialog} size={SIZE.auto}>
			<ModalHeader>Confirmation</ModalHeader>
			<ModalBody>
				<div className={container}>
					<LabelMedium marginTop="10px">Are you sure you want to exit the session booking process?</LabelMedium>
					<CancelAndSaveButtons
						cancelLabel="Yes, I do"
						buttonWidths="fit-content"
						cancelFn={() => {
							clearData()
							setIsOpen(false)
							setOpenParentModal(false)
						}}
						saveLabel="No, I want to compete the process"
						saveFn={() => setIsOpen(false)}
					/>
				</div>
			</ModalBody>
		</ZenModal>
	)
}

const CallLogCreateClient = (props: { onCreateClient: (c: Client) => void }) => {
	const { onCreateClient } = props
	const [css] = useStyletron()
	const [avatar, setAvatar] = React.useState<File>()
	const [sameAsResidentialAddress, setSameAsResidentialAddress] = React.useState<boolean>(false)
	const [manualPostalAddress, setManualPostalAddress] = React.useState(false)

	const fileUpload = useMutation<{ fileID: string }>(fetching.mutation.fileUpload)
	const clientCreate = useMutation(fetching.mutation.clientCreate)

	// marketing data
	const [explainedServiceIDs, setExplainedServiceIDs] = React.useState<string[]>([])
	const [interestedServiceIDs, setInterestedServiceIDs] = React.useState<string[]>([])

	const formMethods = useForm()
	const onSubmit = async () => {
		const data = formMethods.getValues()

		// avatar upload
		let avatarID = ""
		if (avatar) {
			const resp = await fileUpload.mutate({ file: avatar })
			if (!resp || resp.error) return
			avatarID = resp.payload?.fileID || ""
		}

		const postalAddress = () => {
			if (sameAsResidentialAddress) return undefined
			if (manualPostalAddress) {
				return {
					postalAddressLine1: data.manualPostalAddress.addressLine1,
					postalAddressLine2: data.manualPostalAddress.addressLine2,
					postalSuburb: data.manualPostalAddress.suburb,
					postalState: data.manualPostalAddress.state,
					postalPostalCode: data.manualPostalAddress.postalCode,
				}
			}
			if (data.postalAddress && data.postalAddress.length > 0) {
				return { postalAddressPlaceID: data.postalAddress[0].id }
			}
			return undefined
		}

		await clientCreate.mutate({
			avatarID: avatarID || null,
			firstName: data.firstName,
			lastName: data.lastName,
			preferredName: data.preferredName,
			dateOfBirth: data.dateOfBirth,
			hasApproximateDob: data.hasApproximateDob,
			leaveTextMessage: !!data.leaveTextMessage,
			agreedToReceiveEmails: !!data.agreedToReceiveEmails,
			leaveVoiceMessage: !!data.leaveVoiceMessage,

			// additional information
			countryOfBirthID: data.countryOfBirthID && data.countryOfBirthID.length > 0 ? data.countryOfBirthID[0].id : undefined,
			languageSpokenAtHomeID: data.languageSpokenAtHomeID && data.languageSpokenAtHomeID.length > 0 ? data.languageSpokenAtHomeID[0].id : undefined,
			requiresTranslator: data.requiresTranslator,
			residencyStatus: data.residencyStatus && data.residencyStatus.length > 0 ? data.residencyStatus[0].id : undefined,
			genderID: data.genderID && data.genderID.length > 0 ? data.genderID[0].id : undefined,
			culturallyAndLinguisticallyDiverse: data.culturallyAndLinguisticallyDiverse,
			lesbianGayBisexualAndTransgender: data.lesbianGayBisexualAndTransgender,
			aboriginalOrTorresStraitIslander:
				data.aboriginalOrTorresStraitIslander && data.aboriginalOrTorresStraitIslander.length > 0 ? data.aboriginalOrTorresStraitIslander[0].id : undefined,

			contact: {
				residentialAddressPlaceID: data.residentialAddress && data.residentialAddress.length > 0 ? data.residentialAddress[0].id : undefined,
				postalAddress: postalAddress(),
				email: data.email,
				telephoneNumber: data.telephoneNumber,
				mobileNumber: data.mobileNumber,
			},

			parentGuardian: data.hasParentGuardianContact
				? {
						firstName: data.parentGuardianFirstName,
						lastName: data.parentGuardianLastName,
						relationship: data.parentGuardianRelationship,
						telephoneNumber: data.parentGuardianTelephoneNumber,
						mobileNumber: data.parentGuardianMobileNumber,
						dateOfBirth: data.parentGuardianDateOfBirth,
						countryOfBirthID: data.parentGuardianCountryOfBirthID,
						languageSpokenAtHomeID: data.parentGuardianLanguageSpokenAtHomeID,
						requiresTranslator: data.parentGuardianRequiredTranslator,
						culturallyAndLinguisticallyDiverse: data.parentGuardianCulturallyAndLinguisticallyDiverse,
						aboriginalOrTorresStraitIslander: data.parentGuardianAboriginalOrTorresStraitIslander,
				  }
				: undefined,

			// marketing information
			receiveUpcomingEvent: data.receiveUpcomingEvent,
			whereClientHearAboutUs: data.whereClientHearAboutUs && data.whereClientHearAboutUs.length > 0 ? data.whereClientHearAboutUs[0].label : undefined,
			initialContactSourceID: data.initialContactSource && data.initialContactSource.length > 0 ? data.initialContactSource[0].id : undefined,
			helpLookingFor: data.helpLookingFor,
			serviceProviderID: data.serviceProvider && data.serviceProvider.length > 0 ? data.serviceProvider[0].id : undefined,
			interestedServiceIDs: interestedServiceIDs,
			explainedServiceIDs: explainedServiceIDs,
			isOrganisation: data.isOrganisation,
		})
	}

	const onCreateClientCallback = React.useCallback(() => {
		if (!clientCreate.payload) return
		onCreateClient(clientCreate.payload)
	}, [clientCreate.payload, onCreateClient])
	React.useEffect(() => {
		onCreateClientCallback()
	}, [onCreateClientCallback])

	const container = css({
		display: "flex",
		flexDirection: "column",
		position: clientCreate.loading || fileUpload.loading ? "relative" : "unset",
		opacity: clientCreate.loading || fileUpload.loading ? 0.5 : 1,
	})
	const inner = css({
		flex: "1",
		minHeight: 0,
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})

	return (
		<FormProvider {...formMethods}>
			<form autoComplete="off" className={container} onSubmit={formMethods.handleSubmit(onSubmit)}>
				{(clientCreate.loading || fileUpload.loading) && (
					<div className={loadingIcon}>
						<StyledSpinnerNext />
					</div>
				)}
				<div className={inner}>
					<ClientCreateForm
						errorObject={
							clientCreate.error
								? getError(clientCreate.payload)
								: fileUpload.error
								? {
										message: "Failed to upload avatar image; please try again or try a different file format",
										error_code: "00002",
								  }
								: undefined
						}
						submit={onSubmit}
						avatar={avatar}
						setAvatar={setAvatar}
						sameAsResidentialAddress={sameAsResidentialAddress}
						setSameAsResidentialAddress={setSameAsResidentialAddress}
						manualPostalAddress={manualPostalAddress}
						setManualPostalAddress={setManualPostalAddress}
						noCard
						explainedServiceIDs={explainedServiceIDs}
						setExplainedServiceIDs={setExplainedServiceIDs}
						interestedServiceIDs={interestedServiceIDs}
						setInterestedServiceIDs={setInterestedServiceIDs}
					/>
				</div>
			</form>
		</FormProvider>
	)
}

interface BookSessionProps {
	client: Client
	onComplete: () => void
	setOpenConfirmation: React.Dispatch<React.SetStateAction<boolean>>
}
export const BookSession = (props: BookSessionProps) => {
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "85vh",
		width: "715px",
		padding: "15px",
	})
	const form = css({
		display: "flex",
		padding: "15px",
		height: "100%",
		justifyContent: "center",
		minHeight: 0,
	})

	const { client, onComplete, setOpenConfirmation } = props
	const { currentUser } = AuthContainer.useContainer()
	const [currentStep, setCurrentStep] = React.useState<number>(0)
	const [data, setData] = React.useState<SingleClientSessionInput>({
		worker: currentUser
			? [
					{
						...currentUser,
						label: `${currentUser.firstName} ${currentUser.lastName}`,
					},
			  ]
			: [],
	})
	const getClient = useQuery<ClientDetail>(fetching.query.getClient(client.id))
	const loadClient = React.useCallback(() => {
		if (getClient.loading || getClient.error || !getClient.payload) return
		setData({
			...data,
			client: [
				{
					...getClient.payload,
					label: `${getClient.payload.firstName} ${getClient.payload.lastName}`,
				},
			],
			sessionLocation: getClient.payload.currentContact?.residentialAddress
				? [
						{
							id: getClient.payload.currentContact?.residentialAddress.placeID,
							label: getClient.payload.currentContact?.residentialAddress.fullAddress,
						},
				  ]
				: [],
			dateOfBirth: getClient.payload.dateOfBirth ? new Date(getClient.payload.dateOfBirth) : undefined,
		})
	}, [getClient.payload, getClient.loading, getClient.error]) // eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		loadClient()
	}, [loadClient])

	const onPageChange = (nextMove: 1 | -1) => {
		const nextStep = currentStep + nextMove
		if (nextStep < 0) {
			// trigger the confirmation
			setOpenConfirmation(true)
			return
		}
		if (nextStep > 2) {
			// exit the entire process
			onComplete()
			return
		}
		setCurrentStep(nextStep)
	}

	if (getClient.loading) {
		return (
			<ZenCard className={container}>
				<Spinner />
			</ZenCard>
		)
	}

	const displayForm = () => {
		switch (currentStep) {
			case 0:
				return <NewClientSessionGeneral data={data} setData={setData} pageChange={onPageChange} />
			case 1:
				return <ClientNotesForm data={data} setData={setData} pageChange={onPageChange} />
			case 2:
				return <AppointmentConfirmation data={data} setData={setData} pageChange={onPageChange} />
			default:
				return null
		}
	}
	return (
		<ZenCard className={container}>
			<ZenProgressBar labels={ClientSingleSessionSteps} currentStep={currentStep} />
			<div className={form}>{displayForm()}</div>
		</ZenCard>
	)
}

interface AlertsModelProps {
	isOpen: boolean
	clientID?: string
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const AlertsModel = (props: AlertsModelProps) => {
	const { isOpen, clientID } = props
	const [offset, setOffset] = React.useState(0)
	const { timezone } = PortalContainer.useContainer()
	const limit = 5

	const [css, theme] = useStyletron()
	const alertContainer: string = css({
		height: "400px",
		overflowY: "scroll",
	})
	const alertItemContainer: string = css({
		display: "flex",
		marginTop: "15px",
		padding: "8px",
	})
	const alertItemStripe: string = css({
		backgroundColor: theme.colors.backgroundSecondary,
	})
	const alertItemAvatar: string = css({
		marginRight: "15px",
		cursor: "pointer",
	})
	const alertItemBody: string = css({
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		paddingRight: "7px",
	})

	const {
		payload: data,
		loading,
		error,
	} = useQuery<{
		alertList: Alert[]
		total: number
	}>(
		fetching.query.getClientAlertMany({
			search: {
				search: "",
				filterBy: FilterBy.Active,
				sortBy: SortBy.DateCreated,
				sortDir: SortDir.Descending,
			},
			limit,
			offset,
			clientID,
		}),
	)

	return (
		<ZenModal isOpen={isOpen && !!clientID} role={ROLE.dialog} size={SIZE.auto} onClose={() => props.setIsOpen(false)}>
			<ModalHeader>Client Alerts</ModalHeader>
			{clientID && (
				<ModalBody>
					{loading && <StyledSpinnerNext size={"24px"} />}
					{error && <ErrorNotification messageOrPayload={data} />}

					{!loading && (!data?.alertList || data.alertList.length === 0) && <ParagraphSmall>No Alerts Found</ParagraphSmall>}
					{!loading && data?.alertList && (
						<div className={alertContainer}>
							{data.alertList.map((a, i) => {
								let itemClassName = alertItemContainer
								if (i % 2 === 0) {
									itemClassName += ` ${alertItemStripe}`
								}

								return (
									<div key={`alert-${a.id}`} className={itemClassName}>
										<div className={alertItemAvatar}>
											<Avatar
												name={a.poster ? `${a.poster.firstName} ${a.poster.lastName}` : ""}
												size="scale1000"
												overrides={{
													Root: {
														style: {
															minWidth: ZenTheme.sizing.scale1000,
														},
													},
												}}
												src={a.poster?.avatarURL || ""}
											/>
										</div>
										<div className={alertItemBody}>
											<LabelSmall>{a.poster ? `${a.poster.firstName} ${a.poster.lastName}` : "System"}</LabelSmall>
											<ParagraphXSmall margin={0}>{moment(a.createdAt).tz(timezone.id).format("MMMM Do YYYY, h:mm:ss a")}</ParagraphXSmall>
											<ParagraphSmall>{a.content}</ParagraphSmall>
										</div>
									</div>
								)
							})}
						</div>
					)}
					<ZenPagination total={data?.total} limit={limit} offset={offset} setOffset={setOffset} />
				</ModalBody>
			)}
		</ZenModal>
	)
}
