import * as React from "react"
import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import helping_minds_logo_light from "../assets/images/HM_TM_RGB_COLOUR_without_byline.svg"
import { useMediaQuery } from "react-responsive"
import { Link } from "react-router-dom"

import { AuthContainer } from "../controllers/auth"
import { ZenTheme } from "../themeOverrides"
import { RolePermission } from "../types/types"
import { routes } from "../routes"
import { LabelSmall } from "baseui/typography"
import { faUserSlash } from "@fortawesome/pro-light-svg-icons"

export const SideBar = () => {
	const [css] = useStyletron()
	const { currentUser, isImpersonatingUser, hasPermission } = AuthContainer.useContainer()
	const isTrainingMode = process.env.REACT_APP_TRAINING_HOST_URL === window.location.host
	const hasBorder = isTrainingMode || isImpersonatingUser

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

	const containerStyle = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
		minHeight: 0,
		width: isTabletOrMobile ? "100px" : ZenTheme.sizing.sideBarScale,
		flexShrink: 0,
		boxShadow: "0px 3px 6px #00000029",
		backgroundColor: ZenTheme.colors.dark,

		[`@media print`]: {
			display: "none",
		},
	})

	const logoContainerStyle = css({
		minHeight: "80px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: "2rem 0",
		textDecoration: "none",
		"@media only screen and (max-width: 1224px)": {
			marginLeft: hasBorder ? "15px" : "unset",
			marginTop: ".5rem",
			marginRight: ".5rem",
			marginBottom: ".5rem",
		},
	})

	const logoStyle = css({
		width: "100%",
		maxWidth: "200px",
	})

	const bottom = css({
		marginTop: "auto",
		width: "100%",
	})

	const content = css({
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})

	const scrollingDiv = css({
		height: "100%",
		maxHeight: "90%",
		overflowY: "auto",
		overflowX: "hidden",
		minHeight: 0,
	})

	if (!currentUser || !currentUser.role) return null

	return (
		<div className={containerStyle}>
			<Link className={logoContainerStyle} to={routes.portal}>
				<img className={logoStyle} src={helping_minds_logo_light} alt={"HelpingMinds logo"} />
			</Link>
			<div className={content}>
				<div className={scrollingDiv}>
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"border-all"} label={"My Dashboard"} url={routes.portal} exact />
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"calendar-alt"} label={"Schedule"} url={routes.schedule.root} exact />
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"user-circle"} label={"Clients"} url={routes.clients.root} />
					{hasPermission(RolePermission.TeamCreate) && (
						<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"users"} label={"Team"} url={routes.team.root} />
					)}
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"history"} label={"Sessions"} url={routes.sessions.root} />
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"map-marked-alt"} label={"Travel"} url={routes.travels.root} />
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"file-alt"} label={"Forms"} url={routes.forms.root} />
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"briefcase"} label={"Team Members"} url={routes.workers.root} />
					<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"phone-square"} label={"Contact Logs"} url={routes.callLogs.root} />
					{(hasPermission(RolePermission.ReportRead) || hasPermission(RolePermission.NDISAdmin) || hasPermission(RolePermission.LockDateManage)) && (
						<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"file-chart-line"} label={"Admin"} url={routes.admin.root} />
					)}
					{(hasPermission(RolePermission.Executive) || hasPermission(RolePermission.ExecutiveCorporate)) && (
						<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"lock-alt"} specficIcon={faUserSlash} label={"Restricted Clients"} url={routes.restrictedClients.root} />
					)}
					{hasPermission(RolePermission.AuditLogRead) && (
						<SideMenuButton isTabletOrMobile={isTabletOrMobile} icon={"id-card"} label={"Audit Logs"} url={routes.auditLogs.root} />
					)}
				</div>

				<div className={bottom}>
					<LabelSmall color="white" width="100%" display="flex" alignItems="center" justifyContent="center" padding="6px">
						v{process.env.REACT_APP_VERSION}
					</LabelSmall>
					<LogoutButton isTabletOrMobile={isTabletOrMobile} icon={"sign-out"} label={"Logout"} url={"#"} />
				</div>
			</div>
		</div>
	)
}

interface ButtonProps {
	label: string
	icon: IconName
	url: string
	exact?: boolean
	specficIcon?: IconDefinition
	isTabletOrMobile: boolean
}

const SideMenuButton = (props: ButtonProps) => {
	const { label, icon, url, exact, isTabletOrMobile, specficIcon } = props

	const selected = exact ? window.location.pathname === url : window.location.pathname.startsWith(url)

	const [css] = useStyletron()
	const buttonStyle = css({
		height: isTabletOrMobile ? "50px" : "70px",
		backgroundColor: selected ? "#2D3225" : "transparent",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: isTabletOrMobile ? "center" : undefined,
		color: ZenTheme.colors.primaryGreen,
		":hover": {
			backgroundColor: "rgba(0, 0, 0, 0.5)",
		},
	})

	const iconStyle = css({
		width: "38px",
		marginRight: isTabletOrMobile ? "5px" : "20px",
		marginLeft: isTabletOrMobile ? "5px" : "30px",
		textAlign: "center",
	})

	const linkStyle = css({
		textDecoration: "none",
	})

	const labelStyle = css({
		fontSize: "18px",
		fontWeight: selected ? 600 : "normal",
		color: "white",
		textDecoration: "none",

		[`@media only screen and (max-width: 1500px)`]: {
			fontSize: "16px",
		},
	})

	return (
		<Link title={label} to={url} className={linkStyle}>
			<div className={buttonStyle}>
				<div className={iconStyle}>
					{!specficIcon && <FontAwesomeIcon icon={["fal", icon]} size={"2x"} />}
					{specficIcon && <FontAwesomeIcon icon={specficIcon} size={"2x"} />}
				</div>
				{!isTabletOrMobile && <div className={labelStyle}>{label}</div>}
			</div>
		</Link>
	)
}

const LogoutButton = (props: ButtonProps) => {
	const { useLogout } = AuthContainer.useContainer()
	const { logout } = useLogout()
	const [css] = useStyletron()
	const { label, icon, isTabletOrMobile } = props

	const buttonStyle = css({
		cursor: "pointer",
		height: "70px",
		backgroundColor: "#2F3930",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		color: ZenTheme.colors.primaryGreen,
		[`@media only screen and (max-width: 1500px)`]: {
			height: "50px",
		},
		":hover": {
			backgroundColor: "rgba(0, 0, 0, 0.5)",
		},
	})
	const iconStyle = css({
		width: "38px",
		marginRight: "20px",
		marginLeft: "30px",
		textAlign: "center",
	})
	const labelStyle = css({
		fontSize: "18px",
		color: "white",
		textDecoration: "none",

		[`@media only screen and (max-width: 700px)`]: {
			display: "none",
		},
	})

	return (
		<div
			title={label}
			className={buttonStyle}
			onClick={() => {
				logout()
			}}
		>
			<div className={iconStyle}>
				<FontAwesomeIcon style={{ color: ZenTheme.colors.primaryGreen }} icon={["fal", icon]} size={"2x"} />
			</div>
			{!isTabletOrMobile && <div className={labelStyle}>{label}</div>}
		</div>
	)
}
