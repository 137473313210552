import { LabelSmall } from "baseui/typography"
import { FieldDisplay } from "./fieldDisplay"
import moment from "moment-timezone"
import { User, ZenDateFormat, ZenDateTimeFormat } from "../types/types"
import { Notification, KIND } from "baseui/notification"
import * as React from "react"

interface Props {
	noticeType: "DECLINED" | "APPROVED" | "CHECKED" | "UNAPPROVED" | "PAYROLL COMPLETED"
	byUser: User
	time: moment.Moment
	reason?: string
}

export const ReviewStamp = (props: Props) => {
	const { noticeType, byUser, time, reason } = props

	const getKind = () => {
		switch (noticeType) {
			case "APPROVED":
				return KIND.positive
			case "DECLINED":
				return KIND.negative
			case "PAYROLL COMPLETED":
				return KIND.positive
			default:
				return KIND.info
		}
	}

	return (
		<Notification
			overrides={{
				Body: {
					style: {
						marginLeft: "10px",
						marginRight: "auto",
						marginTop: 0,
						marginBottom: 0,
						width: "100%",
						maxWidth: "220px",
					},
				},
				InnerContainer: {
					style: {
						width: "100%",
					},
				},
			}}
			kind={getKind()}
		>
			<LabelSmall color={"inherit"}>{props.noticeType}</LabelSmall>
			<FieldDisplay label={"By"}>
				<LabelSmall>
					{byUser.firstName} {byUser.lastName}
				</LabelSmall>
			</FieldDisplay>
			<FieldDisplay label={"Date"}>
				<LabelSmall>{time.tz(byUser?.timezoneID).format(ZenDateTimeFormat)}</LabelSmall>
			</FieldDisplay>
			{reason && (
				<FieldDisplay label={"Reason"}>
					<LabelSmall
						overrides={{
							Block: {
								style: {
									whiteSpace: "pre-line",
									width: "100%",
									maxHeight: "150px",
									overflow: "auto",
								},
							},
						}}
					>
						{reason}
					</LabelSmall>
				</FieldDisplay>
			)}
		</Notification>
	)
}
