import * as React from "react"
import { useStyletron } from "baseui"
import { Checkbox } from "baseui/checkbox"
import { FormControl } from "baseui/form-control"
import { InputProps } from "baseui/input"
import { SelectProps } from "baseui/select"
import { LabelSmall } from "baseui/typography"
import { FieldError } from "react-hook-form"

import { ZenInput } from "./zenInput"
import { ZenPlaceSelect } from "./zenSelectBox"

interface ZenPostalAddressProps extends SelectProps {
	label: string
	formName: string
	formNameManual: string
	formRef: any
	formRules?: any
	inputError?: FieldError
	placeholder?: React.ReactNode

	manualAddress: boolean
	setManualAddress: React.Dispatch<React.SetStateAction<boolean>>
}

export const ZenPostalAddress = (props: ZenPostalAddressProps) => {
	const { label, formRef, formName, formNameManual, inputError, placeholder, manualAddress, setManualAddress } = props

	return (
		<FormControl
			label={<LabelSmall>{label}</LabelSmall>}
			error={inputError?.message || ""}
			overrides={{
				ControlContainer: {
					style: {
						marginBottom: "3px",
					},
				},
			}}
		>
			<div>
				{manualAddress ? (
					<ZenManualAddress formName={formNameManual} formRef={formRef} />
				) : (
					<ZenPlaceSelect label="" formName={formName} formRef={formRef} placeholder={placeholder} />
				)}
				<Checkbox
					overrides={{
						Root: {
							style: {
								width: "15%",
								whiteSpace: "nowrap",
							},
						},
					}}
					checked={manualAddress}
					onChange={() => setManualAddress((e) => !e)}
				>
					<LabelSmall>Enter Manually</LabelSmall>
				</Checkbox>
			</div>
		</FormControl>
	)
}

interface ZenManualAddressProps extends InputProps {
	formRef: any
	formName: string
}

const ZenManualAddress = (props: ZenManualAddressProps) => {
	const { formRef, formName } = props
	const [css] = useStyletron()

	const oneLine = css({ display: "flex" })

	return (
		<div>
			<ZenInput label={"Address Line 1"} nameRef={`${formName}.addressLine1`} formRef={formRef} />
			<ZenInput label={"Address line 2"} nameRef={`${formName}.addressLine2`} formRef={formRef} />
			<div className={oneLine}>
				<ZenInput label={"Suburb"} nameRef={`${formName}.suburb`} formRef={formRef} />
				<ZenInput label={"State"} nameRef={`${formName}.state`} formRef={formRef} marginLeft="20px" />
				<ZenInput label={"Postal Code"} nameRef={`${formName}.postalCode`} formRef={formRef} marginLeft="20px" />
			</div>
		</div>
	)
}
