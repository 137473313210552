import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { Block } from "baseui/block"
import { Breadcrumbs } from "baseui/breadcrumbs"
import { Button, KIND, SIZE } from "baseui/button"
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox"
import { StatefulMenu } from "baseui/menu"
import { ModalHeader } from "baseui/modal"
import { Notification } from "baseui/notification"
import { PLACEMENT, StatefulPopover } from "baseui/popover"
import { Select } from "baseui/select"
import { HeadingLarge, LabelSmall } from "baseui/typography"
import { useStateMachine } from "little-state-machine"
import { Claims } from "pages/workers/claims"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { Link, useHistory } from "react-router-dom"
import { routes } from "routes"
import { FilterBy } from "types/enums"
import { updateCallLogCreate } from "../actions/callLogCreate"
import { AuthContainer } from "../controllers/auth"
import { PortalContainer } from "../controllers/portal"
import { fetching } from "../fetching"
import { resetLayout } from "../pages/dashboard/layout"
import { SessionDisplay } from "../pages/sessions/sessionList"
import { ZenTheme } from "../themeOverrides"
import { RolePermission, Timezone, TZString, UserDetail, UserWithRole } from "../types/types"
import { ZenCard } from "./common"
import { FormList } from "./forms/formList"
import { WorkerList } from "./workers/workerList"
import { ZenButton } from "./zenComponents/zenButtons"
import { ZenModal } from "./zenComponents/zenModal"
import * as Sentry from "@sentry/react"

interface Crumb {
	name: string
	url?: string
}

export const TopBar = () => {
	const [css] = useStyletron()
	const history = useHistory()

	const containerStyle = css({
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		width: "100%",
		height: "66px",
		minHeight: "66px",
		fontSize: "1.5rem",
		backgroundColor: ZenTheme.colors.lightGrey,
		[`@media print`]: {
			display: "none",
		},
	})

	const topBarContentStyle = css({
		margin: "0 25px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
	})
	const accountBar = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
	})
	const breadcrumbSeparator = css({
		marginLeft: "10px",
		marginRight: "10px",
		color: "#AAAAAA",
		userSelect: "none",
	})
	const breadcrumbDimmed = css({
		color: "#AAAAAA !important",
		":hover": {
			color: "#DADADA !important",
		},
	})
	const prevBreadcrumb = css({
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: "10vw",
		"@media only screen and (max-width: 1000px)": {
			display: "none",
		},
	})
	const prevBreadcrumbTooLong = css({
		display: "none",
		"@media only screen and (max-width: 1000px)": {
			display: "inline",
		},
	})
	const endBreadcrumb = css({
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: "calc(min(20vw, 40ch))",
		height: "1.5rem",
	})

	const [pageTitle, setPageTile] = React.useState<string>(history.location.pathname.split("/").pop() || "")
	const updateUserTimezone = useMutation(fetching.mutation.userUpdateTimezone)

	React.useEffect(() => {
		let currentPageTitle: string = history.location.pathname.split("/").pop() || ""
		currentPageTitle = currentPageTitle ? currentPageTitle.replace(/_/g, " ").replace(/-/g, " ") : currentPageTitle
		currentPageTitle = currentPageTitle ? currentPageTitle.charAt(0).toUpperCase() + currentPageTitle.slice(1) : currentPageTitle
		if (currentPageTitle.length > 30) currentPageTitle = ""
		setPageTile(currentPageTitle)
	}, [history.location.pathname])

	// Calculate breadcrumbs from pathname
	const { client, worker, call, travel, setTimezone, timezone } = PortalContainer.useContainer()

	const [breadcrumbs, setBreadcrumbs] = React.useState<Crumb[]>([])
	React.useEffect(() => {
		const crumbs: Crumb[] = []
		const split = history.location.pathname.split("/")
		if (split[0] === "") split.shift()
		if (split[split.length - 1] === "") split.pop()
		if (split.length === 0) {
			setBreadcrumbs([])
			return
		}

		let path = "/"
		for (let i = 0; i < split.length; i++) {
			// replaces "_" or "-" with " " and "~" with " "
			// eg. "path-here" => "path here"
			let name = split[i].replace(/_/g, " ").replace("~", " / ").replace(/-/g, " ")

			// capitalizes first letter
			name = name.charAt(0).toUpperCase() + name.slice(1)

			// splits name on capital letters and joins to a string
			// eg. "PathHere" => "Path Here"
			const match = name.match(/[A-Z][a-z]+/g) || []
			if (match.length > 1) {
				name = match.join(" ")
			}

			// exception for create session
			if (i > 0 && split[i - 1] === "sessions" && name === "create") {
				crumbs.push({ name })
				path += split[i] + "/"
				continue
			}

			if (split[i] !== "portal") {
				let clientName = "Unknown"
				if (client) {
					if (client.firstName) clientName = client.firstName
					if (client.lastName) clientName += `${clientName.length > 0 ? " " : ""}${client.lastName}`
				}

				let workerName = "Unknown"
				if (worker?.firstName && worker.lastName) workerName = `${worker.firstName} ${worker.lastName}`

				let callIdentifier = "Unknown"
				if (call?.identifier) callIdentifier = call.identifier.toString()

				let travelIdentifier = "Unknown"
				if (travel?.identifier) travelIdentifier = travel.identifier.toString()

				if (i > 0 && name !== "Create") {
					switch (split[i - 1]) {
						case "clients":
							name = client ? clientName : "Loading..."
							break
						case "workers":
							name = worker ? workerName : "Loading..."
							break
						case "call-logs":
							name = call ? callIdentifier : "Loading..."
							break
						case "travels":
							name = travel ? travelIdentifier : "Loading..."
							break
					}
				}
				// change worker name to team members
				if ( name === "Workers") {
					name = "Team Members"
				}
				crumbs.push({
					name,
					url: i === split.length - 1 ? undefined : path + split[i],
				})
			} else if (i === split.length - 1) {
				crumbs.push({
					name: "My Dashboard",
				})
			}
			path += split[i] + "/"
		}

		setBreadcrumbs(crumbs)
	}, [history.location.pathname, client, worker, call, travel])

	const handleSetTimezone = (tz: Timezone) => {
		updateUserTimezone
			.mutate({
				timezoneID: tz.id,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				setTimezone(tz)
			})
	}

	return (
		<div className={containerStyle}>
			<div className={topBarContentStyle}>
				<Breadcrumbs
					overrides={{
						Root: {
							style: {},
						},
						ListItem: {
							style: {
								fontSize: "22px",
								fontWeight: 600,
								textTransform: "capitalize",
								display: "flex",
							},
						},
						List: {
							style: {
								display: "flex",
							},
						},
						Separator: {
							component: () => <div className={breadcrumbSeparator}>/</div>,
						},
					}}
				>
					{breadcrumbs.map((crumb, index) => {
						// Deal with acronym's
						// eg. Ndis => NDIS
						crumb.name.split(" ").forEach((s) => {
							switch (s.toLowerCase()) {
								case "ndis":
									crumb.name = crumb.name.replace(s, "NDIS")
									break
								case "sms":
									crumb.name = crumb.name.replace(s, "SMS")
									break
								case "dass21":
									crumb.name = crumb.name.replace(s, "DASS21")
									break
								default:
									break
							}
						})

						if (crumb.url) {
							return (
								<Link
									title={crumb.name}
									key={`breadcrumb-${index}`}
									to={crumb.url}
									className={`${index !== breadcrumbs.length - 1 ? breadcrumbDimmed : undefined}`}
								>
									<span className={prevBreadcrumb}>{crumb.name}</span>
									<span className={prevBreadcrumbTooLong}>...</span>
								</Link>
							)
						}
						return (
							<div title={crumb.name} className={endBreadcrumb} key={`breadcrumb-${index}`}>
								{crumb.name}
							</div>
						)
					})}
				</Breadcrumbs>

				<div className={accountBar}>
					<Select
						options={TZString}
						value={[timezone]}
						onChange={({ value }) => handleSetTimezone(value[0] as Timezone)}
						clearable={false}
						overrides={{
							Root: {
								style: {
									width: "fit-content",
								},
							},
						}}
					/>
					<ButtonBar pageTitle={pageTitle} />
					<Account />
				</div>
			</div>
		</div>
	)
}

const ButtonBar = (props: { pageTitle: string }) => {
	const { hasPermission, editingLayout, setEditingLayout, currentUser } = AuthContainer.useContainer()
	const history = useHistory()

	const { state: resumeState } = useStateMachine({
		updateCallLogCreate,
	})

	const [css] = useStyletron()
	const buttonWrap = css({
		"@media only screen and (max-width: 1147px)": {
			display: "none",
		},
	})

	if (window.location.pathname === "/portal/" || window.location.pathname === "/portal" || props.pageTitle === "Portal") {
		return (
			<>
				<span className={buttonWrap} style={{ marginRight: "10px" }}>
					<Button
						kind={KIND.secondary}
						size={SIZE.mini}
						onClick={() => {
							resetLayout()
							setEditingLayout(undefined)
						}}
					>
						Reset layout
					</Button>
				</span>
				<span className={buttonWrap} style={{ marginRight: "20px" }}>
					<Checkbox
						checked={editingLayout}
						checkmarkType={STYLE_TYPE.toggle_round}
						onChange={(e) => setEditingLayout(e.currentTarget.checked)}
						labelPlacement={LABEL_PLACEMENT.right}
					>
						Edit layout
					</Checkbox>
				</span>
			</>
		)
	}

	switch (props.pageTitle) {
		case "Call logs":
			return <HeaderButton label={resumeState.callLogCreate.stale ? "Resume Call Log" : "New Call Log"} onClick={() => history.push(routes.callLogs.create)} />
		case "Travels":
			return (
				<HeaderButton
					label="New Travel"
					onClick={() => {
						if (currentUser?.department.name === "Individualised Services") {
							history.push(routes.travels.createConfirm)
							return
						}
						history.push(routes.travels.create)
					}}
				/>
			)
		case "Sessions":
			return (
				<Block
					display="flex"
					flexWrap={true}
					overrides={{
						Block: {
							style: {
								"@media only screen and (max-width: 1100px)": {
									display: "none",
								},
							},
						},
					}}
				>
					<HeaderButton label={"Log Non-billable Session"} onClick={() => history.push(routes.sessions.logNonBillable)} />
					<HeaderButton label={"New Appointment"} onClick={() => history.push(routes.sessions.create.single.root)} />
					<HeaderButton label={"New Group Appointment"} onClick={() => history.push(routes.sessions.create.group)} />
				</Block>
			)
		case "Mileage":
			if (!hasPermission(RolePermission.ReportCreate)) return null
			return <HeaderButton label={"Create Mileage Reports"} onClick={() => history.push(routes.admin.mileage.create)} />
		case "Timesheets":
			if (!hasPermission(RolePermission.ReportCreate)) return null
			return <HeaderButton label={"Create Timesheet"} onClick={() => history.push(routes.admin.timesheets.create)} />
		case "Clients":
			return hasPermission(RolePermission.ClientUpdate) ? <HeaderButton label={"New Client"} onClick={() => history.push(routes.clients.create)} /> : <></>
		case "Team":
			return <HeaderButton label={"Add Worker"} onClick={() => history.push(routes.team.add)} />
		case "Workers":
			if (!hasPermission(RolePermission.UserCreate)) return null
			return <HeaderButton label={"New Team Member"} onClick={() => history.push(routes.workers.create.root)} />
		default:
			return null
	}
}

const HeaderButton = (props: { label: string; onClick: () => void }) => {
	return (
		<ZenButton marginRight={"15px"} onClick={props.onClick}>
			{props.label}
		</ZenButton>
	)
}

const Account = () => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false)
	const { useLogout, currentUser, loading, hasPermission, impersonateUser, isImpersonatingUser } = AuthContainer.useContainer()
	const { logout } = useLogout()
	const history = useHistory()
	const getUser = useParameterizedQuery<UserDetail>(fetching.query.getUser)
	const [firstLoad, setFirstLoad] = React.useState<boolean>(true)
	const [showImpersonateModal, setShowImpersonateModal] = React.useState(false)

	// incomplete session
	const [showIncompleteSessions, setShowIncompleteSessions] = React.useState(false)
	const [showDeclinedReports, setShowDeclinedReports] = React.useState(false)

	const {
		fetchIncompleteSessionAlert,
		has24HrsIncompleteSessions,
		has48HrsIncompleteSessions,
		fetchActionableForms,
		hasActionableForms,
		hasDeclinedMileageClaims,
		hasDeclinedTimesheets,
	} = PortalContainer.useContainer()
	// actionable forms
	const [showActionableForms, setShowActionableForms] = React.useState(false)

	// trigger query when first loaded
	React.useEffect(() => {
		if (!isImpersonatingUser) {
			fetchIncompleteSessionAlert()
			fetchActionableForms()
		}
	}, [fetchActionableForms, fetchIncompleteSessionAlert, isImpersonatingUser])

	const [css] = useStyletron()
	const userStyle = css({
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
	})
	const userNameStyle = css({
		marginLeft: "0.5rem",
		marginRight: "0.5rem",
	})
	const listContainerStyle = css({
		margin: "10px",
		height: "100%",
		overflow: "auto",
	})
	const group = css({
		display: "flex",
		width: "100%",
		alignItems: "center",
	})
	const avatarWrap = css({
		position: "relative",
		display: "flex",
		alignItems: "center",
	})
	const noticeBadge = css({
		position: "absolute",
		top: "-13px",
		right: "-7px",
	})
	const notification = css({
		position: "absolute",
		zIndex: 11,
		right: "40px",
		cursor: "pointer",
	})

	const caseNotesNotification = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const formList = css({
		height: "90%",
	})

	const handleImpersonateClick = async (user: UserWithRole) => {
		const response = await getUser.query(user.id)
		if (!response.payload) {
			return
		}
		impersonateUser(response.payload)
		setShowImpersonateModal(false)
	}

	React.useEffect(() => {
		// Prevent redirect on first load
		if (isImpersonatingUser) {
			if (firstLoad) {
				setFirstLoad(false)
				return
			}
			history.push(routes.portal)
		}
	}, [firstLoad, history, isImpersonatingUser])

	const menuContent = (close: () => void) => {
		const items: { label: React.ReactNode; onClick?: () => void }[] = [
			{
				label: "Profile",
				onClick: () => {
					history.push(`/portal/workers/${currentUser?.id}`)
					close()
				},
			},
		]
		if (hasPermission(RolePermission.ImpersonateUser) || isImpersonatingUser) {
			items.push({
				label: isImpersonatingUser ? "Stop Impersonating User" : "Impersonate User",
				onClick: () => {
					isImpersonatingUser ? impersonateUser() : setShowImpersonateModal(true)
					close()
				},
			})
		}
		if (has24HrsIncompleteSessions) {
			items.push({
				label: (
					<div className={group}>
						<LabelSmall width="100%">Incomplete Sessions</LabelSmall>
						<FontAwesomeIcon style={{ width: "20px" }} color="red" icon={["fas", "exclamation-circle"]} />
					</div>
				),
				onClick: () => {
					setShowIncompleteSessions(true)
					close()
				},
			})
		}

		if (hasDeclinedTimesheets || hasDeclinedMileageClaims) {
			items.push({
				label: (
					<div className={group}>
						<LabelSmall width="100%">Declined Timesheets/ Mileage Claims</LabelSmall>
						<FontAwesomeIcon style={{ width: "20px" }} color="red" icon={["fas", "exclamation-circle"]} />
					</div>
				),
				onClick: () => {
					setShowDeclinedReports(true)
					close()
				},
			})
		}

		if (hasActionableForms) {
			items.push({
				label: (
					<div className={group}>
						<LabelSmall width="100%">Incomplete Forms</LabelSmall>
						<FontAwesomeIcon style={{ width: "20px" }} color="red" icon={["fas", "exclamation-circle"]} />
					</div>
				),
				onClick: () => {
					setShowActionableForms(true)
					close()
				},
			})
		}
		items.push({
			label: "My Account",
			onClick: () => window.open("https://myaccount.microsoft.com/", "_blank"),
		})
		items.push({
			label: "Log Out",
			onClick: () => logout(),
		})

		return (
			<StatefulMenu
				overrides={{
					List: {
						style: () => {
							return {
								outline: "none",
								minWidth: "200px",
							}
						},
					},
				}}
				items={items}
				onItemSelect={(selection) => {
					selection.item.onClick && selection.item.onClick()
				}}
			/>
		)
	}

	if (!currentUser || loading) {
		return null
	}

	return (
		<div style={{ position: "relative" }}>
			<StatefulPopover
				content={({ close }) => menuContent(close)}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				placement={PLACEMENT.bottomRight}
				overrides={{
					Body: {
						style: {
							zIndex: 12,
							":focus": {
								borderColor: "black",
							},
						},
					},
				}}
			>
				<div className={userStyle}>
					<div className={avatarWrap}>
						<Avatar
							name={`${currentUser.firstName} ${currentUser.lastName}`}
							size="scale1000"
							src={currentUser.avatarURL || ""}
							overrides={{
								Root: {
									style: {
										minWidth: ZenTheme.sizing.scale1000,
									},
								},
								Avatar: {
									style: {
										filter: isImpersonatingUser ? "grayscale(1) opacity(0.5)" : "unset",
									},
								},
							}}
						/>
						{(has24HrsIncompleteSessions || hasActionableForms) && (
							<div className={noticeBadge}>
								<FontAwesomeIcon style={{ width: "14px" }} color="red" icon={["fas", "exclamation-circle"]} />
							</div>
						)}
					</div>
					<div className={userNameStyle}>
						<HeadingLarge
							overrides={{
								Block: {
									style: {
										marginTop: "0px",
										marginBottom: "0px",
										whiteSpace: "nowrap",
										"@media only screen and (max-width: 800px)": {
											display: "none",
										},
									},
								},
							}}
						>
							{`${currentUser.firstName} ${currentUser.lastName}`}
						</HeadingLarge>
					</div>
					<FontAwesomeIcon icon={["fal", isOpen ? "chevron-up" : "chevron-down"]} size={"xs"} />
				</div>
			</StatefulPopover>
			{has48HrsIncompleteSessions && (
				<div className={notification}>
					<Notification
						closeable
						kind="negative"
						overrides={{
							Body: {
								style: {
									width: "400px",
								},
							},
							InnerContainer: {
								style: { width: "100%" },
							},
						}}
					>
						{({ dismiss }) => (
							<div
								style={{ paddingRight: "10px" }}
								className={caseNotesNotification}
								onClick={() => {
									setShowIncompleteSessions(true)
									dismiss()
								}}
							>
								<div>You have incomplete post session detail</div>
								<ZenButton altKind="tertiary">View</ZenButton>
							</div>
						)}
					</Notification>
				</div>
			)}

			{(hasDeclinedTimesheets || hasDeclinedMileageClaims) && (
				<div className={notification}>
					<Notification
						closeable
						kind="negative"
						overrides={{
							Body: {
								style: {
									width: "440px",
								},
							},
							InnerContainer: {
								style: { width: "100%" },
							},
						}}
					>
						{({ dismiss }) => (
							<div
								style={{ paddingRight: "10px" }}
								className={caseNotesNotification}
								onClick={() => {
									setShowDeclinedReports(true)
									dismiss()
								}}
							>
								<div>
									{hasDeclinedTimesheets && !hasDeclinedMileageClaims && "You have declined Timesheet(s)"}
									{hasDeclinedMileageClaims && !hasDeclinedTimesheets && "You have declined Mileage Claim(s)"}
									{hasDeclinedMileageClaims && hasDeclinedTimesheets && "You have declined Timesheet/ Mileage Claim(s)"}
								</div>
								<ZenButton altKind="tertiary">View</ZenButton>
							</div>
						)}
					</Notification>
				</div>
			)}

			<ZenModal
				onClose={() => setShowImpersonateModal(false)}
				isOpen={showImpersonateModal}
				overrides={{
					Dialog: {
						style: {
							width: "100%",
							height: "80vh",
							overflow: "hidden",
						},
					},
				}}
			>
				<ModalHeader>Impersonate User</ModalHeader>
				<div className={listContainerStyle}>
					<WorkerList height={"70vh"} viewOnly onRowClick={handleImpersonateClick} hideViewToggle />
				</div>
			</ZenModal>

			<ZenModal
				onClose={() => setShowIncompleteSessions(false)}
				isOpen={showIncompleteSessions}
				overrides={{
					Root: {
						style: {
							zIndex: 30,
						},
					},
					Dialog: {
						style: {
							width: "100%",
							height: "80vh",
							overflow: "hidden",
						},
					},
				}}
			>
				<ModalHeader>Incomplete Sessions</ModalHeader>
				<div className={listContainerStyle}>
					<SessionDisplay
						workerID={currentUser.id}
						whoseSessions="worker"
						title=" "
						hideFilter
						showPastSessions
						getIncomplete
						modalOnClose={() => setShowIncompleteSessions(false)}
					/>
				</div>
			</ZenModal>

			<ZenModal
				size="auto"
				onClose={() => setShowDeclinedReports(false)}
				isOpen={showDeclinedReports}
				overrides={{
					Dialog: {
						style: {
							width: "70%",
							height: "80vh",
							overflow: "hidden",
						},
					},
				}}
			>
				<ModalHeader>Declined Timesheets/ Mileage Claims</ModalHeader>
				<div className={listContainerStyle}>
					<ZenCard style={{ height: "90%", overflow: "auto", display: "flex", justifyContent: "center" }}>
						<Claims workerID={currentUser.id} showDeclined />
					</ZenCard>
				</div>
			</ZenModal>

			<ZenModal
				onClose={() => {
					fetchActionableForms()
					setShowActionableForms(false)
				}}
				isOpen={showActionableForms}
				overrides={{
					Root: {
						style: {
							zIndex: 30,
						},
					},
					Dialog: {
						style: {
							width: "100%",
							height: "80vh",
							overflow: "hidden",
						},
					},
				}}
			>
				<ModalHeader>Incomplete forms</ModalHeader>
				<div className={listContainerStyle}>
					<FormList
						className={formList}
						requiresAction={1}
						filter={FilterBy.Active}
						title=" "
						onItemClick={() => setShowActionableForms(false)}
						style={{
							maxWidth: "100%",
						}}
						hideFilter
						omitAction
						isOwnActionableForms
					/>
				</div>
			</ZenModal>

			{<FeedbackBar />}
		</div>
	)
}

const FeedbackBar = () => {
	const [css] = useStyletron()
	const feedbackBar = css({
		position: "fixed",
		zIndex: 50000,
		right: "-45px",
		bottom: "20%",
		transform: "rotate(-90deg)",
	})
	const isTrainingMode = process.env.REACT_APP_TRAINING_HOST_URL === window.location.host

	const [disableButton, setDisableButton] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const openDialog = async () => {
		setLoading(true)
		setDisableButton(true)
		try {
			throw new Error("User Feedback")
		} catch (e) {
			const eventID = await Sentry.captureException(e)
			Sentry.showReportDialog({
				eventId: eventID,
				title: "Report a Bug or Send us some feedback",
				subtitle: "Our team will be notified.",
				subtitle2: "",
				labelSubmit: "Submit",
				onLoad: () => {
					// HACK: Sentry's user feedback modal does not have a onclose callback so
					// had to implement it my self using query selectors
					setLoading(false)
					if (window.document != null) {
						// click close
						if ((window.document as any)?.querySelector(".sentry-error-embed .close")) {
							;(window.document as any)?.querySelector(".sentry-error-embed .close").addEventListener("click", () => {
								setDisableButton(false)
								return
							})
						}

						// click inside modal
						if ((window.document as any)?.querySelector(".sentry-error-embed-wrapper .clearfix")) {
							;(window.document as any)?.querySelector(".sentry-error-embed-wrapper .clearfix").addEventListener("click", (e: Event) => {
								e.stopPropagation()
							})
						}

						// click outside modal
						if ((window.document as any)?.querySelector(".sentry-error-embed-wrapper")) {
							;(window.document as any)?.querySelector(".sentry-error-embed-wrapper").addEventListener("click", () => {
								setDisableButton(false)
							})
						}
					}
				},
			})
		}
	}

	return (
		<div className={feedbackBar}>
			<a href={`mailto:helpdesk@helpingminds.org.au?subject=${isTrainingMode ? "Zen Training Database Feedback" : " Zen Database Feedback"}`}>
				<ZenButton>User Feedback</ZenButton>
			</a>
		</div>
	)
}
