import React from "react"
import { Option, OnChangeParams, Value, Select, SelectProps } from "baseui/select"
import { ErrorMessage } from "./errorMessage"
import { Control, Controller } from "react-hook-form"
import { FieldError, FieldValues } from "react-hook-form/dist/types/form"
import { SortOrder, UserDetail } from "types/types"
import { fetching } from "fetching"
import { useDebounce } from "helpers/utils"
import { useQuery } from "react-fetching-library"
import { FilterBy } from "types/enums"
import { ErrorNotification } from "./errorBox"
import { FormControl } from "baseui/form-control"
import { LabelMedium, LabelLarge } from "baseui/typography"
import { useStyletron } from "styletron-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar } from "baseui/avatar"
import { Block } from "baseui/block"
import { ZenTheme } from "themeOverrides"

interface DropdownSelect {
    id: string
    options: Value
    value: Value
    error: boolean
    errorMessage: string
    onFocus?: () => void // if on focus remove the error message
    placeholder: string
    onChange: (params: OnChangeParams) => void
    overRides?: Record<string, any>
    clearable?: boolean
    errorMsgOverRides?: Record<string, any>
};
interface ZenDisplaySelectProps extends SelectProps {
	label: string
	formName: string
	formRef: any
	formRules?: any
	excludedID?: string[]
	inputError?: FieldError
	defaultValue?: Value
	actionOnChange?: (v: Value) => void
	width?: string
	options: Option[]
	loading: boolean
	delayedQuery?: (s: string) => void
	testText?: string
	marginTop?: string
	marginBottom?: string
};

interface ZenPersonSelectProps extends SelectProps {
	label: string
	formName: string
	formRef: Control<FieldValues>
	formRules?: any
	excludedID?: string[]
	inputError?: FieldError
	defaultValue?: UserDetail[] | Client[]
	actionOnChange?: (v: Value) => void
	width?: string
	marginTop?: string
	marginBottom?: string
};
interface CommonArrayType {
	id: string
	firstName: string
	lastName: string
};

export const DropdownSelect = (props: DropdownSelect) => {
    const { 
        id, 
        options, 
        value, 
        error, 
        errorMessage, 
        onFocus,
        placeholder, 
        onChange: handleSelectOnChange, 
        overRides, 
        errorMsgOverRides,
        clearable
    } = props ?? {}
    const { root, dropdown } = overRides ?? {}
    const { style: rootStyle } = root ?? {}
    const { style: dropdownStyle } = dropdown ?? {}
    const { width: rootStyleWidth } = rootStyle ?? {}
    const { maxHeight: dropdownMaxHeight } = dropdownStyle ?? {}

    return (
        <div>
            <Select
                id={id}
                options={options}
                value={value}
                clearable={clearable}
                autoFocus={true}
                onFocus={onFocus}
                error={error}
                placeholder={placeholder}
                onChange={handleSelectOnChange}
                overrides={{
                    Root: {
                        style: {
                            width: `${rootStyleWidth ? rootStyleWidth : "280px"}`,
                            height: "fit-content",
                            boxShadow: "1px 0px 8px 1px rgba(0,0,0,0.2)",
                            marginRight: "15px",
                            borderRadius: "5px"
                        },
                    },
                    InputContainer: {
                        style: {
                            width: "fit-content",
                            height: "fit-content",
                        },
                    },
                    ValueContainer: {
                        style: {
                            paddingTop: 0,
                            paddingBottom: 0,
                        },
                    },
                    Dropdown: {
                        style: {
                            maxHeight: dropdownMaxHeight ? dropdownMaxHeight : "150px",
                        },
                    },
                }}
            />
            <div>{error && <ErrorMessage message={errorMessage} overRides={errorMsgOverRides}/>}</div>
        </div>
    )
}

export const DisplaySelectCard = ({ option }: any) => {
	const department = option?.department && option?.department.name
	const firstName = option?.firstName
	const lastName = option?.lastName
	const label = option.label || `${firstName} ${lastName}`
	return (
		<Block display="flex" alignItems="center" justifyContent="space-between">
			<Block display="flex" alignItems="center">
				<Avatar
					name={label}
					overrides={{
						Root: {
							style: {
								minWidth: ZenTheme.sizing.scale1000,
							},
						},
					}}
					src={option?.avatarURL || ""}
				/>
				<LabelMedium marginLeft="8px">{label}</LabelMedium>
			</Block>
			<div>
				{department && <Block marginLeft="5px">{department}</Block>}
				{option.isOrganisation && (
					<Block marginLeft="5px">
						<FontAwesomeIcon title="Organisation" icon={["fal", "users"]} />
					</Block>
				)}
			</div>
		</Block>
	)
};

export const ZenDisplaySelect = (props: ZenDisplaySelectProps) => {
	const { label, formName, formRef, formRules, inputError, defaultValue, actionOnChange, width, options, loading, delayedQuery, error, ...rest } = props

	const [css] = useStyletron()
	const container = css({
		width: width || "unset",
	});

	return (
		<div className={container}>
			<FormControl
				label={<LabelLarge>{label}</LabelLarge>}
				error={inputError?.message || ""}
				overrides={{
					ControlContainer: {
						style: {
							opacity: props.disabled ? 0.5 : 1,
							fontSize: "18px",
						},
					},
					Label:{
						style: {
							textAlign: "left",
						}
					},
				}}
			>
				<Controller
					name={formName}
					control={formRef}
					rules={formRules}
					defaultValue={defaultValue || []}
					render={({ value, name, onChange }) => {
						return (
							<Select
								{...rest}
								id={name}
								error={error}
								searchable
								onChange={(s) => {
									if (actionOnChange) actionOnChange(s.value)
									onChange(s.value)
								}}
								value={value || []}
								placeholder={rest.placeholder || ""}
								labelKey="label"
								valueKey="id"
								getOptionLabel={DisplaySelectCard}
								getValueLabel={DisplaySelectCard}
								onInputChange={delayedQuery ? (e) => delayedQuery(e.currentTarget.value) : undefined}
								options={options}
								filterOptions={(v) => v}
								overrides={{
									Root: {
										style: {
											backgroundColor: "transparent",
										},
									},
									ControlContainer: {
										style: ({ $disabled }) => ({
											backgroundColor: "transparent",
											borderTopWidth: 0,
											borderLeftWidth: 0,
											borderRightWidth: 0,
											borderBottomWidth: $disabled ? 0 : "2px",
											borderBottomStyle: "solid",
											borderBottomColor: "black",
											minHeight: "48px",
										}),
									},
									Tag: {
										props: {
											overrides: {
												Root: {
													style: {
														height: "fit-content",
														backgroundColor: "white",
													},
												},
												Text: {
													style: {
														maxWidth: "fit-content",
													},
												},
												ActionIcon: {
													style: {
														color: "black",
														marginBottom: "10px",
													},
												},
											},
										},
									},
									ValueContainer: {
										style: {
											paddingBottom: 0,
											paddingTop: 0,
										},
									},
									Dropdown: {
										style: {
											display: !loading && options.length > 0 ? "block" : "none",
											maxHeight: "calc(min(250px, 60vh))",
										},
									},
									SelectArrow: {
										style: {
											display: "none",
										},
									},
								}}
							/>
						)
					}}
				/>
			</FormControl>
		</div>
	)
};

export const ZenUserDropdownSelect = (props: ZenPersonSelectProps) => {
	const { excludedID, label, formName, formRef, formRules, inputError, defaultValue, width, actionOnChange, placeholder, ...rest } = props;
	const [searchKey, setSearchKey] = React.useState<string>("");
	const [userOptions, setUserOptions] = React.useState<Option[]>([]);

	const { payload, loading, error } = useQuery<{ users: UserDetail[]; total: number }>(
		fetching.query.getManyUsers({
			search: {
				filterBy: FilterBy.Active,
				search: searchKey || "",
				sortBy: "Name",
				sortDir: SortOrder.Ascending,
			},
			limit: 10,
			offset: 0,
			excludedID,
		}),
	);

	const [displayKey, setDisplayKey] = React.useState<string>("");
	const debouncedSearchTerm = useDebounce(displayKey, 500);
	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm]);

	React.useEffect(() => {
		if (loading || !payload || error) return
		setUserOptions(payload.users.map<Option>((u) => ({ ...u, label: `${u.firstName} ${u.lastName}` })))
	}, [payload, loading, error]);

	if (error) return <ErrorNotification messageOrPayload={payload} />

	return (
		<ZenDisplaySelect
			{...rest}
			label={label || "Worker"}
			placeholder={placeholder === undefined ? "Select Worker" : placeholder}
			formName={formName || "Worker"}
			formRef={formRef}
			formRules={formRules}
			excludedID={excludedID}
			inputError={inputError}
			defaultValue={defaultValue ? (defaultValue as CommonArrayType[]).map((dv) => ({ ...dv, id: dv.id, label: `${dv.firstName} ${dv.lastName} ` })) : []}
			actionOnChange={actionOnChange}
			width={width}
			options={userOptions}
			loading={loading}
			delayedQuery={setDisplayKey}
		/>
	)
};