import * as React from "react"
import { LabelLarge } from "baseui/typography"
import moment from "moment-timezone"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { useStyletron } from "styletron-react"

import { Spacer, ZenCard } from "../../components/common"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenDatePicker } from "../../components/zenComponents/zenTime"

export const SMSReport = () => {
	const [css] = useStyletron()
	const [loading, setLoading] = React.useState(false)
	const { control, handleSubmit, setValue } = useForm()
	const history = useHistory()
	React.useEffect(() => {
		// set time to 00:00:00
		const today = new Date().setHours(0, 0, 0, 0)
		setValue("startDate", moment(today).add(1, "day").toDate())
		setValue("endDate", moment(today).add(1, "day").toDate())
	}, [history.location, setValue])

	const onSubmit = (formData: any) => {
		setLoading(true)
		fetch("/api/sessions/sms_reminder", {
			method: "post",
			body: JSON.stringify({
				startDate: formData.startDate,
				endDate: moment(formData.endDate).add(1, "day").add(-1, "second").toDate(),
			}),
		})
			.then((response) => {
				response.blob().then((blob) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement("a")
					a.href = url
					a.download = `Session_SMS_Reminder_${moment(formData.startDate).format("YYYYMMDD")}_${moment(formData.endDate).format("YYYYMMDD")}.xlsx`
					a.click()
					setLoading(false)
				})
			})
			.catch((e) => {
				console.error(e)
				setLoading(false)
			})
	}
	const card = css({
		height: "fit-content",
		width: "fit-content",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		flexDirection: "column",
	})
	const buttonRow = css({
		display: "flex",
		justifyContent: "flex-end",
		padding: "10px",
	})
	const group = css({
		display: "flex",
		justifyContent: "space-between",
	})
	return (
		<ZenCard className={card}>
			<form autoComplete="off" className={title} onSubmit={handleSubmit(onSubmit)}>
				<LabelLarge marginBottom="20px">Export Session SMS Reminder</LabelLarge>
				<div className={group}>
					<div>
						<ZenDatePicker formName="startDate" label="Start Date" formRef={control} />
					</div>
					<Spacer style={{ width: 15 }} />
					<div>
						<ZenDatePicker formName="endDate" label="End Date" formRef={control} />
					</div>
				</div>
				<div className={buttonRow}>
					<ZenButton isLoading={loading} type="submit">
						Export
					</ZenButton>
				</div>
			</form>
		</ZenCard>
	)
}
