import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Value } from "baseui/select"
import { LabelMedium } from "baseui/typography"
import * as React from "react"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { fetching } from "../../../fetching"
import { useDebounce } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { Language } from "../../../types/types"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}

export const LanguageOptionManagement = () => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [languages, setLanguages] = React.useState<Language[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(20)

	const languageMany = useQuery(
		fetching.query.getLanguageMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (languageMany.error || !languageMany.payload) return
		setTotal(languageMany.payload.total)
		setLanguages(languageMany.payload.languages)
	}, [languageMany.payload, languageMany.error])

	const languageArchive = useMutation(fetching.mutation.languageArchive)
	const languageUnarchive = useMutation(fetching.mutation.languageUnarchive)

	const [targetedLanguageID, setTargetedLanguageID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedLanguage, setSelectedLanguage] = React.useState<Language>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={setFilter}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Language</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{languageMany.error && <ErrorNotification messageOrPayload={languageMany.payload} />}
			{languageArchive.error && <ErrorNotification messageOrPayload={languageArchive.payload} />}
			{languageUnarchive.error && <ErrorNotification messageOrPayload={languageUnarchive.payload} />}

			<ListTable
				isLoading={languageMany.loading || languageArchive.loading || languageUnarchive.loading}
				rows={languages}
				onRowClick={(row: Language) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedLanguageID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedLanguage(row)
				}}
				columns={[
					{
						id: "label",
						header: "Language",
						resolver: (row: Language) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButton}>Action</div>,
						resolver: (row: Language) => (
							<div className={actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedLanguageID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedLanguageID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={languageArchive.loading || languageMany.loading}
												message={row.label}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													languageArchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														languageMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={languageUnarchive.loading || languageMany.loading}
												message={row.label}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													languageUnarchive.mutate(row.id).then((resp) => {
														if (resp.error || !resp.payload) return
														languageMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedLanguage && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<LanguageForm
						language={selectedLanguage}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedLanguage(undefined)
						}}
						queryLanguages={languageMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<LanguageForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryLanguages={languageMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface LanguageFormProps {
	language?: Language
	onClose: () => void
	queryLanguages: () => Promise<
		QueryResponse<{
			languages: Language[]
			total: number
		}>
	>
}
const LanguageForm = (props: LanguageFormProps) => {
	const { language, onClose, queryLanguages } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedLanguage, setDuplicatedLanguage] = React.useState<Language>()

	const languageCreate = useMutation(fetching.mutation.languageCreate)
	const languageUpdate = useMutation(fetching.mutation.languageUpdate)
	const languageUnarchive = useMutation(fetching.mutation.languageUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!language) return
		setValue("label", language.label)
	}, [language, setValue])

	const onSubmit = (formData: any) => {
		// update existing language
		if (language) {
			// close modal if the language name is unchanged
			if (language.label === formData.label) {
				onClose()
				return
			}

			languageUpdate
				.mutate({
					id: language.id,
					...formData,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated language
					if (typeof resp.payload === "object") {
						setDuplicatedLanguage(resp.payload)
						return
					}

					queryLanguages()
					onClose()
				})
			return
		}

		// create new language
		languageCreate.mutate(formData).then((resp) => {
			if (resp.error || !resp.payload) return
			// if contain duplicated language
			if (typeof resp.payload === "object") {
				setDuplicatedLanguage(resp.payload)
				return
			}

			queryLanguages()
			onClose()
		})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated language after submit
		if (duplicatedLanguage && !!duplicatedLanguage.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The language "{duplicatedLanguage.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedLanguage(undefined)}
						saveFn={() =>
							languageUnarchive.mutate(duplicatedLanguage.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryLanguages()
								onClose()
							})
						}
						isLoading={languageUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{languageUnarchive.error && <ErrorNotification messageOrPayload={languageUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!language ? "Rename" : "Create"} language</LabelMedium>
				<ZenInput label="Language Name" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons cancelFn={onClose} saveLabel={!!language ? "Save" : "Submit"} isLoading={languageCreate.loading || languageUpdate.loading} />
				{duplicatedLanguage && <ErrorNotification message={`The language "${duplicatedLanguage.label}" is already exists`} />}
				{languageCreate.error && <ErrorNotification messageOrPayload={languageCreate.payload} />}
				{languageUpdate.error && <ErrorNotification messageOrPayload={languageUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
