import * as React from "react"
import moment from "moment-timezone"
import { useHistory } from "react-router-dom"
import { useQuery, useMutation } from "react-fetching-library"
import { useStyletron } from "baseui"
import { LabelSmall, LabelLarge, ParagraphSmall, ParagraphXSmall } from "baseui/typography"
import { StyledSpinnerNext } from "baseui/spinner"
import { Avatar } from "baseui/avatar"
import { Button } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { fetching } from "../../../fetching"
import { Alert } from "../../../types/types"
import { FilterBy, SortBy, SortDir } from "../../../types/enums"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenTheme } from "themeOverrides"
import { PortalContainer } from "../../../controllers/portal"

// Unread Alerts
export const Alerts = () => {
	const history = useHistory()
	const { showToast } = useZenToast()
	const { error, loading, payload, query } = useQuery(
		fetching.query.getClientAlertMany({
			search: {
				search: "",
				filterBy: FilterBy.Active,
				sortBy: SortBy.DateCreated,
				sortDir: SortDir.Descending,
			},
			limit: 10,
			offset: 0,
			fromAssignedClient: true,
			excludeReadMessages: true,
		}),
	)
	const { mutate: markAsRead, loading: updating, error: errorMutate, payload: payloadMutate } = useMutation<Alert>(fetching.mutation.alertMarkRead)
	const { timezone } = PortalContainer.useContainer()
	const [css, theme] = useStyletron()
	const container: string = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const loadingContainer: string = css({
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignContent: "center",
	})
	const alertContainer: string = css({
		flexGrow: 1,
		position: "relative",
	})
	const alertContainerInner: string = css({
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		overflowY: "auto",
	})
	const alertItemContainer: string = css({
		display: "flex",
		marginTop: "15px",
		padding: "8px",
	})
	const alertItemStripe: string = css({
		backgroundColor: theme.colors.backgroundSecondary,
	})
	const alertItemAvatar: string = css({
		marginRight: "15px",
		cursor: "pointer",
	})
	const alertItemBody: string = css({
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		paddingRight: "7px",
	})
	const alertMarkAsReadIcon: string = css({
		marginRight: "1em",
	})

	return (
		<ZenCard className={container}>
			<LabelLarge>Latest Alerts</LabelLarge>
			{error && <ErrorNotification messageOrPayload={payload} />}
			{errorMutate && <ErrorNotification messageOrPayload={payloadMutate} />}

			{loading && (
				<div className={loadingContainer}>
					<StyledSpinnerNext />
				</div>
			)}
			{!loading && !payload?.alertList && <ParagraphSmall marginTop={"15px"}>No New Alerts Found</ParagraphSmall>}
			{!loading && payload?.alertList && (
				<div className={alertContainer}>
					<div className={alertContainerInner}>
						{payload.alertList.map((a, i) => {
							let itemClassName = alertItemContainer
							if (i % 2 === 0) {
								itemClassName += ` ${alertItemStripe}`
							}

							const userClickHandler = () => {
								history.push(`/portal/clients/${a.client.id}`)
							}

							const latestAlert = a.revisions[0]

							return (
								<div key={`alert-${a.id}`} className={itemClassName}>
									<div className={alertItemAvatar} onClick={userClickHandler}>
										<Avatar
											name={`${a.client.firstName} ${a.client.lastName}`}
											size="scale1000"
											overrides={{
												Root: {
													style: {
														minWidth: ZenTheme.sizing.scale1000,
													},
												},
											}}
											src={a.client.avatarURL || ""}
										/>
									</div>
									<div className={alertItemBody}>
										<LabelSmall
											overrides={{
												Block: {
													props: {
														onClick: userClickHandler,
													},
												},
											}}
										>{`${a.client.firstName} ${a.client.lastName}`}</LabelSmall>
										<ParagraphXSmall margin={0}>{moment(latestAlert.createdAt).tz(timezone.id).format("MMMM Do YYYY, h:mm:ss a")}</ParagraphXSmall>
										<ParagraphSmall
											overrides={{
												Block: {
													style: {
														whiteSpace: "pre-line",
														wordBreak: "break-word",
													},
												},
											}}
										>
											{latestAlert.content}
										</ParagraphSmall>
										<Button
											type={"button"}
											size={"compact"}
											overrides={{
												BaseButton: {
													style: {
														...theme.typography.ParagraphXSmall,
														alignSelf: "flex-end",
														backgroundColor: "transparent",
														color: "black",
														borderTopLeftRadius: "4px",
														borderTopRightRadius: "4px",
														borderBottomLeftRadius: "4px",
														borderBottomRightRadius: "4px",
														borderTopWidth: "1px",
														borderLeftWidth: "1px",
														borderBottomWidth: "1px",
														borderRightWidth: "1px",
														borderTopStyle: "solid",
														borderLeftStyle: "solid",
														borderBottomStyle: "solid",
														borderRightStyle: "solid",
														borderTopColor: "black",
														borderLeftColor: "black",
														borderBottomColor: "black",
														borderRightColor: "black",
														":hover": {
															backgroundColor: "transparent",
														},
														":focus": {
															backgroundColor: "transparent",
														},
														":active": {
															backgroundColor: "transparent",
														},
													},
												},
											}}
											onClick={async () => {
												if (updating) {
													return
												}
												const response = await markAsRead({
													id: a.id,
												})
												if (response.payload) {
													query()
													showToast("Marked Alert as read.", "positive")
												}
											}}
										>
											<FontAwesomeIcon className={alertMarkAsReadIcon} icon={["fal", "envelope-open"]} />
											Mark as read
										</Button>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			)}
		</ZenCard>
	)
}
