import * as React from "react"
import { useStyletron } from "baseui"
import { ZenCard } from "../../components/common"
import { PortalContainer } from "../../controllers/portal"
import { ClientEditForm } from "../../components/clients/clientEditForm"
import { Loading } from "../../components/loading"
import { ErrorNotification } from "../../components/errorBox"

export const ClientEdit = () => {
	const { client, clientLoading, clientError, clientErrorPayload } = PortalContainer.useContainer()
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		maxHeight: "100%",
		height: "100%",
		minHeight: 0,
		width: "100%",
	})
	const editFormStyle = css({
		width: "100%",
	})
	if (clientLoading) return <Loading />
	if (!client || clientError) return <ErrorNotification messageOrPayload={clientErrorPayload} />

	return (
		<div className={container}>
			<ZenCard className={editFormStyle}>
				<ClientEditForm client={client} />
			</ZenCard>
		</div>
	)
}
