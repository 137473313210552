import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover"
import { Option, Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelLarge, LabelSmall, LabelXSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { NestedValue, useForm } from "react-hook-form"
import { UnpackNestedValue } from "react-hook-form/dist/types/form"
import { DeepPartial } from "react-hook-form/dist/types/utils"
import { Link, Prompt, useHistory } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { TravelExpenseInput, TravelInput } from "../../fetching/inputType"
import { useCheckWorkerAvailability } from "../../helpers/useCheckWorkerAvailability"
import { kilometersRegex, setTimezoneToTime, snakeToTitle, timeDuration } from "../../helpers/utils"
import {
	Address,
	BasicLabel,
	Car,
	ClientDetail,
	ClientWithContact,
	RolePermission,
	Session,
	Timezone,
	Travel,
	TravelExpense,
	TZString,
} from "../../types/types"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { AvailableCheck } from "../common"
import { ErrorNotification } from "../errorBox"
import { Notification } from "baseui/notification"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenCheckbox } from "../zenComponents/zenCheckboxList"
import { ZenInput } from "../zenComponents/zenInput"
import { ZenClientSelect, ZenPlaceSelect, ZenSelect, ZenTimezoneSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../zenComponents/zenTime"
import { AddExpensesModal } from "./addExpenses"
import { TravelExpenseList } from "./expenseList"
import { Block } from "baseui/block"
import { routes } from "routes"
import { PortalContainer } from "../../controllers/portal"
import { ErrorFieldTracker } from "../forms/errorFieldTracker"
import { ZenTheme } from "../../themeOverrides"

export enum TravelTypesForShowingClient {
	homeToWork = "From home to work",
	workToHome = "From work to home",
	travelBetweenClients = "Travel between clients",
	travelWithClient = "Travel with client",
	ndisGeneralTransport = "NDIS General Transport",
	cBProviderTravel = "CB Provider Travel",
}

interface TravelFormProps {
	travel?: Travel
	session?: Session
	onSuccess?: (id: string) => void
	onCancel?: () => void

	refetchTimesheet?: () => void
	refetchMileageClaim?: () => void
}

interface FormData {
	worker: NestedValue<Option[]>
	travelType: NestedValue<Option[]>
	timezone: NestedValue<Timezone[]>
	date?: Date
	startTime?: Date
	endTime?: Date
	client: NestedValue<ClientWithContact[]>
	anotherClient: NestedValue<ClientWithContact[]>
	fromAddress: NestedValue<Option[]>
	toAddress: NestedValue<Option[]>
	distance: number
	withCompanyCar: boolean
	companyCar: NestedValue<Option[]>
	travelExpenses: NestedValue<TravelExpenseInput[]>
	odometerBeforeKM: number
	odometerAfterKM: number
	fundingSource: NestedValue<Option[]>
}

const defaultValues: UnpackNestedValue<DeepPartial<FormData>> = {
	worker: [],
	travelType: [],
	client: [],
	anotherClient: [],
	distance: 0,
	fromAddress: [],
	toAddress: [],
	withCompanyCar: false,
	companyCar: [],
	travelExpenses: [],
	odometerBeforeKM: 0,
	odometerAfterKM: 0,
}

export const TravelForm = (props: TravelFormProps) => {
	const { session, travel, onSuccess } = props
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])

	const { showToast } = useZenToast()
	const isNonNDIASession = session && session.sessionFundingSources.length > 0 && session.sessionFundingSources[0].fundingSource.label !== "NDIA"

	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { timezone: globalTimezone } = PortalContainer.useContainer()
	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === travel?.timezoneID)
		if (!target) return globalTimezone
		return target
	}, [travel?.timezoneID]) // eslint-disable-line react-hooks/exhaustive-deps

	const [distanceIsManuallyChanged, setDistanceIsManuallyChanged] = React.useState(!!travel?.distanceIsManuallySet)
	const [endTimeIsManuallyChanged, setEndTimeIsManuallyChanged] = React.useState(!!travel?.endTimeIsManuallySet)
	const [calculatedEndTime, setCalculatedEndTime] = React.useState<Date | undefined>(
		travel ? moment(travel.startTime).add(travel.calculatedDurationSeconds, "second").toDate() : undefined,
	)
	const [calculatedDistanceMetres, setCalculatedDistanceMetres] = React.useState(travel?.calculatedDistanceMetres)

	const clientWithLabel = (c: ClientWithContact) => {
		return { ...c, label: `${c.firstName} ${c.lastName}` }
	}

	const [selectedClient, setSelectedClient] = React.useState<ClientWithContact>()

	React.useEffect(() => {
		if (session?.clients && session?.clients.length === 1) {
			const client = clientWithLabel(session.clients[0])
			setSelectedClient(client)
		}
	}, [session])

	const loadDefaults = (session?: Session, travel?: Travel): UnpackNestedValue<DeepPartial<FormData>> => {
		// get offset minutes different
		const localTimezoneOffset = new Date().getTimezoneOffset()
		const setTimezoneOffset = timezone.offsetMinutes
		const offsetDiff = localTimezoneOffset - setTimezoneOffset

		let initialValues: UnpackNestedValue<DeepPartial<FormData>> = {
			...defaultValues,
			worker: currentUser ? [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }] : [],
		}

		if (travel) {
			initialValues = {
				...defaultValues,
				worker: travel.worker ? [{ ...travel.worker, label: `${travel.worker.firstName} ${travel.worker.lastName}` }] : [],
				travelType: [travel.travelType],
				client: travel.client ? [clientWithLabel(travel.client)] : [],
				anotherClient: travel.anotherClient ? [clientWithLabel(travel.anotherClient)] : [],
				date: moment(travel.startTime).add(offsetDiff, "minute").toDate(),
				startTime: moment(travel.startTime).add(offsetDiff, "minute").toDate(),
				endTime: moment(travel.endTime).add(offsetDiff, "minute").toDate(),
				fromAddress: [{ id: travel.fromAddress.placeID, label: travel.fromAddress.fullAddress }],
				toAddress: [{ id: travel.toAddress.placeID, label: travel.toAddress.fullAddress }],
				distance: travel.actualDistanceMetres ? travel.actualDistanceMetres / 1000.0 : travel.calculatedDistanceMetres / 1000,
				withCompanyCar: !!travel.car,
				companyCar: travel.car ? [{ id: travel.car.id, label: `[${travel.car.regoNumber}] ${travel.car.make} ${travel.car.model}` }] : [],
				odometerBeforeKM: travel.odometerBeforeKM || 0,
				odometerAfterKM: travel.odometerAfterKM || 0,
				fundingSource: travel.fundingSource ? [{ id: travel.fundingSource.id, label: travel.fundingSource.label }] : [],
			}
			if (travel.travelExpenses) {
				const t: TravelExpenseInput[] = []
				for (const e of travel.travelExpenses) {
					t.push({ expenseType: e.expenseType, amount: e.amount, fileName: e.fileName, id: e.id })
				}
				initialValues.travelExpenses = t
			}
		}

		// If creating a travel entry from a session, the session data will be sent through which can be used to help with
		// prefil of the form
		if (session && !travel) {
			initialValues = {
				...defaultValues,
				worker: [session.worker],
				date: moment(session.startTime).add(offsetDiff, "minute").toDate(),
				startTime: moment(session.startTime).add(offsetDiff, "minute").toDate(),
			}

			if (session.clients && session.clients.length === 1) {
				initialValues.client = [clientWithLabel(session.clients[0])]
			}
		}

		return initialValues
	}

	// react hook form
	const { handleSubmit, control, setValue, errors, getValues, watch, formState } = useForm<FormData>({
		defaultValues: loadDefaults(props.session, props.travel),
	})

	const { dirtyFields } = formState

	// pre-fill client when create travel from client tab
	const { payload: clientData, query } = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	React.useEffect(() => {
		const clientID = searchArgs.get("client_id")
		if (!clientID || clientID === "") return
		query(clientID).then((resp) => {
			if (resp.error || !resp.payload) return
			setValue("client", [clientWithLabel(resp.payload)])
		})
	}, [query, searchArgs, setValue])

	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const [excludedUserID, setExcludedUserID] = React.useState<string[]>([])
	const [excludedClientID, setExcludedClientID] = React.useState<string[]>([])

	const startDate = watch("date")
	const watchedTimezone = watch("timezone")
	const startTime = watch("startTime")
	const endTime = watch("endTime")
	const fromAddress = watch("fromAddress")
	const toAddress = watch("toAddress")
	const travelType = watch("travelType")
	const worker = watch("worker")
	const client = watch("client")
	const anotherClient = watch("anotherClient")
	const travelExpenses = watch("travelExpenses")
	const withCompanyCar = watch("withCompanyCar")

	const [travelTypeOptions, setTravelTypeOptions] = React.useState<Option[]>([])
	const getTravelTypes = useQuery<Option[]>(fetching.query.getTravelTypeAll())
	//const [firstClientOptions, setFirstClientOptions] = React.useState<Option[]>()

	React.useEffect(() => {
		if (!currentUser || getTravelTypes.loading || getTravelTypes.error || !getTravelTypes.payload) return
		// full travel option list
		let options = getTravelTypes.payload

		// if attaching travels to sessions
		if (session || clientData) {
			options = options.filter((tts) => {
				if (!tts.label) return false
				return Object.values(TravelTypesForShowingClient).includes(tts.label.toString() as TravelTypesForShowingClient)
			})
			if (isNonNDIASession) {
				options = options.filter((tts) => {
					if (!tts.label) return false
					return tts.label.toString() !== TravelTypesForShowingClient.ndisGeneralTransport
				})
			}

			// restrict client options to session clients
			//if (session) setFirstClientOptions(session.clients?.map((c) => ({ ...c, label: `${c.firstName} ${c.lastName}` })))

			// restrict client options to queried client
			//if (clientData) setFirstClientOptions([clientWithLabel(clientData)])
		}

		if (!session && currentUser.department.name === "Individualised Services") {
			options = options.filter((o) => o.label !== TravelTypesForShowingClient.travelBetweenClients)
		}

		if (!hasPermission(RolePermission.SupportCoordinator)) {
			options = options.filter((o) => o.label !== TravelTypesForShowingClient.cBProviderTravel)
		}
		setTravelTypeOptions(options)
	}, [getTravelTypes.payload, getTravelTypes.loading, getTravelTypes.error, session, isNonNDIASession, clientData, /* setFirstClientOptions, */ currentUser, hasPermission])

	const [cars, setCars] = React.useState<Option[]>([])
	const getCars = useQuery<Car[]>(fetching.query.getCarAll())
	React.useEffect(() => {
		if (!getCars.payload) return
		const carsOptions: Option[] = []
		for (const car of getCars.payload) {
			carsOptions.push({
				id: car.id,
				label: `[${car.regoNumber}] ${car.make} ${car.model}`,
			})
		}
		setCars(carsOptions)
	}, [getCars.payload])

	const [travelWithClientError, setTravelWithClientError] = React.useState("")
	React.useEffect(() => {
		if (!session || travelType.length === 0 || client.length === 0 || isNonNDIASession) return

		// reset error
		setTravelWithClientError("")

		if (travelType[0].label === TravelTypesForShowingClient.cBProviderTravel) return

		switch (travelType[0].label) {
			case TravelTypesForShowingClient.travelWithClient:
				if (!session.canTransportClients.some((tc) => tc.id === client[0].id)) {
					setTravelWithClientError(`${client[0].firstName} ${client[0].lastName} does not have available activity transport included in this session`)
					return
				}
				break
			default:
				if (travelType[0].label === TravelTypesForShowingClient.cBProviderTravel) return;

				if (!session.canTravelClients.some((tc) => tc.id === client[0].id)) {
					setTravelWithClientError(`${client[0].firstName} ${client[0].lastName} does not have available provider travel included in this session`)
					return
				}
				break
		}
	}, [travelType, client, session, isNonNDIASession])

	const [fundingSources, setFundingSources] = React.useState<BasicLabel[]>([])
	const getFundingSources = useQuery(fetching.query.getFundingSourceAll(true))
	React.useEffect(() => {
		if (getFundingSources.loading || getFundingSources.error || !getFundingSources.payload) return
		setFundingSources(getFundingSources.payload)
	}, [getFundingSources.payload, getFundingSources.loading, getFundingSources.error])

	const [expensesModalOpen, setExpensesModalOpen] = React.useState<boolean>(false)

	// check worker availability query
	const workerAvailability = useCheckWorkerAvailability({ timezone: watchedTimezone })

	// distance calc query
	const { loading: distanceLoading, query: distanceMatrixCalc } = useParameterizedQuery<{
		distanceMeter: number
		trafficMinute: number
		message?: string
	}>(fetching.query.getDistanceMatrix)

	const createTravel = useMutation<Travel>(fetching.mutation.travelCreate)
	const updateTravel = useMutation<Travel>(fetching.mutation.travelUpdate)

	// handle submit
	const onSubmit = async (formData: any) => {
		// if client does not have transport line time and the travel type is "TRAVEL WITH CLIENT"
		if (travelWithClientError) return
		// if the time frame of session travel is not available
		if (checkSessionTravelAvailability.payload && !checkSessionTravelAvailability.payload.available) return
		// if worker not available at this time
		if (workerAvailability.state && !workerAvailability.available) return

		// format date to make sure starTime's date matches travel date input
		const travelDate = {
			year: formData.date.getFullYear(),
			month: formData.date.getMonth(),
			date: formData.date.getDate(),
		}

		// parse funding source id
		let fundingSourceID: string | undefined = undefined
		if (withCompanyCar && travelType.length > 0 && travelType[0].label === TravelTypesForShowingClient.travelWithClient && formData.fundingSource.length > 0) {
			fundingSourceID = formData.fundingSource[0].id
		}

		let startTime = moment(formData.startTime).set(travelDate)
		let endTime = moment(formData.endTime)

		const isCBAndTravelBetweenClients: Boolean = [
			TravelTypesForShowingClient.travelBetweenClients,
			TravelTypesForShowingClient.cBProviderTravel
		].some((t) => t === travelType[0].label)

		const values: TravelInput = {
			timezoneID: watchedTimezone[0].id,
			startTime: setTimezoneToTime(startTime.toDate(), watchedTimezone[0].id),
			endTime: setTimezoneToTime(endTime.toDate(), watchedTimezone[0].id),
			fromAddress: {
				placeID: formData.fromAddress[0].id,
				fullAddress: formData.fromAddress[0].label,
				displayName: formData.fromAddress[0].displayName,
			},
			toAddress: {
				placeID: formData.toAddress[0].id,
				fullAddress: formData.toAddress[0].label,
				displayName: formData.toAddress[0].displayName,
			},
			distance: parseFloat(formData.distance),
			clientID: Object.values(TravelTypesForShowingClient).includes(formData.travelType[0].label) && client.length === 1 ? client[0].id : undefined,
			anotherClientID: isCBAndTravelBetweenClients && anotherClient.length === 1 ? anotherClient[0].id : undefined,
			workerID: formData.worker[0].id,
			travelTypeID: formData.travelType[0].id,
			companyCarID: withCompanyCar ? formData.companyCar[0].id : undefined,
			sessionID: session?.id,
			fundingSourceID,
			travelExpenses: travelExpenses,
			id: travel?.id,
			distanceIsManuallySet: distanceIsManuallyChanged,
			endTimeIsManuallySet: endTimeIsManuallyChanged,
			odometerBeforeKM: parseInt(formData.odometerBeforeKM) || 0,
			odometerAfterKM: parseInt(formData.odometerAfterKM) || 0,
		}

		if (values.id) {
			const resp = await updateTravel.mutate(values)
			if (resp.error || !resp.payload) return
			setIsSubmitted(true)
			showToast("Travel document updated successfully.", "positive")
			if (props.refetchTimesheet) props.refetchTimesheet()
			if (props.refetchMileageClaim) props.refetchMileageClaim()

			if (onSuccess) onSuccess(resp.payload.id)
			return
		}

		const resp = await createTravel.mutate(values)
		if (resp.error || !resp.payload) return
		setIsSubmitted(true)
		showToast("Travel document created successfully.", "positive")
		if (onSuccess) onSuccess(resp.payload.id)
	}

	// adjust start time on data change
	const handleDateOnChange = (date: Date) => {
		const newStartTime = moment(getValues().startTime)
			.set({
				year: date.getFullYear(),
				month: date.getMonth(),
				date: date.getDate(),
			})
			.toDate()

		// set start time
		setValue("startTime", newStartTime)

		if (getValues().endTime) {
			const newStartTime = moment(getValues().endTime)
				.set({
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
				})
				.toDate()

			// set start time
			setValue("endTime", newStartTime)
		}

		// calc distance
		calcDist({ startTimeInput: newStartTime })
	}

	const calcDist = (values: { fromAddressInput?: Option[]; toAddressInput?: Option[]; startTimeInput?: Date }) => {
		const { fromAddressInput, toAddressInput, startTimeInput } = values
		let innerFromAddress = fromAddress
		if (fromAddressInput) innerFromAddress = fromAddressInput
		let innerToAddress = toAddress
		if (toAddressInput) innerToAddress = toAddressInput
		let innerStartTime = startTime
		if (startTimeInput) innerStartTime = startTimeInput

		if (innerFromAddress.length !== 1 || innerToAddress.length !== 1 || !innerStartTime) return
		distanceMatrixCalc({ fromAddressPlaceID: innerFromAddress[0].id, toAddressPlaceID: innerToAddress[0].id, innerStartTime }).then((resp) => {
			if (resp.error || !resp.payload) {
				return
			}

			setValue("distance", resp.payload.distanceMeter / 1000.0)
			setDistanceIsManuallyChanged(false)
			setCalculatedDistanceMetres(resp.payload.distanceMeter)

			const newEndTime = moment(innerStartTime).add(resp.payload.trafficMinute, "minutes").toDate()
			setValue("endTime", newEndTime)
			setEndTimeIsManuallyChanged(false)
			setCalculatedEndTime(newEndTime)
		})
	}

	const checkSessionTravelAvailability = useParameterizedQuery(fetching.query.checkSessionTravelAvailability)
	const [sessionTravelAvailabilityMsg, setSessionTravelAvailabilityMsg] = React.useState("")

	// check availability
	React.useEffect(() => {
		if (
			!startTime ||
			!startDate ||
			!endTime ||
			worker.length === 0 ||
			!worker[0].id ||
			travelType.length === 0 ||
			!travelType[0].id ||
			!watchedTimezone ||
			!watchedTimezone.length
		) {
			return
		}

		const innerStartTime = setTimezoneToTime(startTime, watchedTimezone[0].id)
		const innerEndTime = setTimezoneToTime(endTime, watchedTimezone[0].id)

		const sessionID = session?.id || travel?.sessionID
		// while adding travel through session
		if (!!sessionID) {
			checkSessionTravelAvailability
				.query({
					workerID: worker[0].id.toString(),
					sessionID: sessionID,
					travelID: travel?.id,
					travelTypeID: travelType[0].id.toString(),
					startTime: innerStartTime,
					endTime: innerEndTime,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return
					let msg = resp.payload.message
					if (resp.payload.message.includes("[start]")) msg = `${msg} (${watchedTimezone[0].label})`
					if (resp.payload.startTime) {
						msg = msg.replace("[date]", moment(resp.payload.startTime).tz(watchedTimezone[0].id).format("DD/MM/YYYY"))
						msg = msg.replace("[start]", moment(resp.payload.startTime).tz(watchedTimezone[0].id).format("hh:mm A"))
					}
					if (resp.payload.endTime) msg = msg.replace("[end]", moment(resp.payload.endTime).tz(watchedTimezone[0].id).format("hh:mm A"))
					setSessionTravelAvailabilityMsg(msg)
				})
			return
		}

		// normal availability check
		workerAvailability.check({
			workerIDs: [worker[0].id as string],
			travelID: travel?.id,
			timezone: watchedTimezone[0],
			startTime: innerStartTime,
			endTime: innerEndTime,
		})
	}, [startTime, startDate, endTime, worker, travelType, watchedTimezone]) // eslint-disable-line react-hooks/exhaustive-deps

	// auto-fill addresses when travel type is changed
	const onTravelTypeChange = (travelType: Value) => {
		if (travelType.length === 0) return
		// parse worker address
		let workerAddress: Address | undefined = undefined
		if (worker && worker.length > 0 && !!worker[0].address) {
			workerAddress = worker[0].address
		}

		// parse client address
		let clientAddress: Address | undefined = undefined
		if (client && client.length > 0 && client[0].currentContact && client[0].currentContact.residentialAddress) {
			clientAddress = client[0].currentContact.residentialAddress
		}

		// parse another client address
		let anotherClientAddress: Address | undefined = undefined
		if (anotherClient && anotherClient.length > 0 && anotherClient[0].currentContact && anotherClient[0].currentContact.residentialAddress) {
			anotherClientAddress = anotherClient[0].currentContact.residentialAddress
		}

		let calcDistInput: { fromAddressInput?: Option[]; toAddressInput?: Option[] } = {}
		setValue("fromAddress", [])
		setValue("toAddress", [])
		const currentClient = getValues('client')
		if (!!currentClient) {
			if (selectedClient) setValue('client', [selectedClient])
		}
		switch (travelType[0].label) {
			case TravelTypesForShowingClient.homeToWork:
				if (!!workerAddress?.placeID) {
					setValue("fromAddress", [{ id: workerAddress.placeID, label: workerAddress.fullAddress }])
					calcDistInput.fromAddressInput = [{ id: workerAddress.placeID, label: workerAddress.fullAddress }]
				}
				if (!!clientAddress?.placeID) {
					setValue("toAddress", [{ id: clientAddress.placeID, label: clientAddress.fullAddress }])
					calcDistInput.toAddressInput = [{ id: clientAddress.placeID, label: clientAddress.fullAddress }]
				}
				break
			case TravelTypesForShowingClient.workToHome:
				if (!!clientAddress?.placeID) {
					setValue("fromAddress", [{ id: clientAddress.placeID, label: clientAddress.fullAddress }])
					calcDistInput.fromAddressInput = [{ id: clientAddress.placeID, label: clientAddress.fullAddress }]
				}
				if (!!workerAddress?.placeID) {
					setValue("toAddress", [{ id: workerAddress.placeID, label: workerAddress.fullAddress }])
					calcDistInput.toAddressInput = [{ id: workerAddress.placeID, label: workerAddress.fullAddress }]
				}
				break
			case TravelTypesForShowingClient.travelBetweenClients:
			case TravelTypesForShowingClient.cBProviderTravel:
				setValue("client", [])
				setValue("fromAddress", [])
				setValue("toAddress", [])
				if (!!clientAddress?.placeID) {
					setValue("toAddress", [{ id: clientAddress.placeID, label: clientAddress.fullAddress }])
					calcDistInput.toAddressInput = [{ id: clientAddress.placeID, label: clientAddress.fullAddress }]
				}
				if (!!anotherClientAddress?.placeID) {
					setValue("fromAddress", [
						{
							id: anotherClientAddress.placeID,
							label: anotherClientAddress.fullAddress,
						},
					])
					calcDistInput.fromAddressInput = [{ id: anotherClientAddress.placeID, label: anotherClientAddress.fullAddress }]
				}
				break
		}
	}

	// auto-fill worker address on change
	const onWorkerChange = (worker: Value) => {
		if (travelType.length === 0 || worker.length === 0 || !worker[0].address?.placeID) return
		const workerAddress = worker[0].address
		switch (travelType[0].label) {
			case TravelTypesForShowingClient.homeToWork:
				setValue("fromAddress", [{ id: workerAddress.placeID, label: workerAddress.fullAddress }])
				calcDist({ fromAddressInput: [{ id: workerAddress.placeID, label: workerAddress.fullAddress }] })
				break
			case TravelTypesForShowingClient.workToHome:
				setValue("toAddress", [{ id: workerAddress.placeID, label: workerAddress.fullAddress }])
				calcDist({ toAddressInput: [{ id: workerAddress.placeID, label: workerAddress.fullAddress }] })
				break
			case TravelTypesForShowingClient.travelBetweenClients:
			case TravelTypesForShowingClient.cBProviderTravel:
				setValue("toAddress", [{ id: workerAddress.placeID, label: workerAddress.fullAddress }])
				calcDist({ toAddressInput: [{ id: workerAddress.placeID, label: workerAddress.fullAddress }] })
				break
		}
	}
	const [searchKey, setSearchKey] = React.useState<any>({
		key: "",
		isFromAddress: true
	})
	const placePredictionData = useQuery<Address[]>(fetching.query.getPlacePredictions(searchKey.key), false)

	const travelsOnly = [
		TravelTypesForShowingClient.travelBetweenClients,
		TravelTypesForShowingClient.cBProviderTravel
	]

	// auto-fill address when client is changed
	const onClientChange = (client: Value) => {
		if (travelType.length === 0 || client.length === 0) {
			setValue("fromAddress", [])
			return
		}

		if (!client[0].currentContact?.residentialAddress?.placeID) {
			const fullAddress = client[0].currentContact?.residentialAddress?.fullAddress
			setSearchKey({
				key: fullAddress,
				isFromAddress: true
			})
			return
		}

		// parse client address
		let clientAddress: Address = client[0].currentContact.residentialAddress
		addAddresses(travelType[0], clientAddress)
	}

	const addAddresses = (travelType: Option, clientAddress: Address, isFromAddress: boolean = true): void => {

		const entryType: string = isFromAddress ? "fromAddress" : "toAddress";

		switch (travelType.label) {
			case TravelTypesForShowingClient.homeToWork:
				setValue("toAddress", [{ id: clientAddress.placeID, label: clientAddress.fullAddress }])
				calcDist({ toAddressInput: [{ id: clientAddress.placeID, label: clientAddress.fullAddress }] })
				break
			case TravelTypesForShowingClient.workToHome:
				setValue("fromAddress", [{ id: clientAddress.placeID, label: clientAddress.fullAddress }])
				calcDist({ fromAddressInput: [{ id: clientAddress.placeID, label: clientAddress.fullAddress }] })
				break
			case TravelTypesForShowingClient.travelBetweenClients:

				setValue(entryType, [{ id: clientAddress.placeID, label: clientAddress.fullAddress }])
				calcDist({ fromAddressInput: [{ id: clientAddress.placeID, label: clientAddress.fullAddress }] })
				break
			case TravelTypesForShowingClient.cBProviderTravel:
				setValue(entryType, [{ id: clientAddress.placeID, label: clientAddress.fullAddress }])
				calcDist({ fromAddressInput: [{ id: clientAddress.placeID, label: clientAddress.fullAddress }] })
				break
		}
	}

	// To Client or Client
	const onAnotherClientChange = (anotherClient: Value) => {
		const isNotTravelBetweenClients: boolean = travelType[0].label !== TravelTypesForShowingClient.travelBetweenClients;
		const isNotCBProviderTravel: boolean = travelType[0].label !== TravelTypesForShowingClient.cBProviderTravel;

		if (isNotTravelBetweenClients || isNotCBProviderTravel) {
			if (travelType.length === 0 ||
				anotherClient.length === 0 ||
				!anotherClient[0].currentContact?.residentialAddress?.placeID
			) {
				return
			}
		}

		if (!anotherClient[0].currentContact?.residentialAddress?.placeID && (!isNotTravelBetweenClients || !isNotCBProviderTravel)) {
			const fullAddress = anotherClient[0].currentContact.residentialAddress?.fullAddress
			setSearchKey({
				key: fullAddress,
				isFromAddress: travelType[0].label !== TravelTypesForShowingClient.workToHome
			})
			return
		}
		const anotherClientAddress = anotherClient[0].currentContact.residentialAddress
		switch (travelType[0].label) {
			case TravelTypesForShowingClient.workToHome:
				setValue("fromAddress", [{ id: anotherClientAddress.placeID, label: anotherClientAddress.fullAddress, },])
				calcDist({ fromAddressInput: [{ id: anotherClientAddress.placeID, label: anotherClientAddress.fullAddress }] })
				break
			case TravelTypesForShowingClient.homeToWork:
			case TravelTypesForShowingClient.travelBetweenClients:
			case TravelTypesForShowingClient.cBProviderTravel:
				setValue("toAddress", [{ id: anotherClientAddress.placeID, label: anotherClientAddress.fullAddress, },])
				calcDist({ toAddressInput: [{ id: anotherClientAddress.placeID, label: anotherClientAddress.fullAddress }] })
				break
		}
	}

	// sets excluded clientID
	React.useEffect(() => {
		const excludedIDs: string[] = []
		if (client.length === 1) excludedIDs.push(client[0].id)
		if (anotherClient.length === 1 && travelType.length > 0 && travelType[0].label === "Travel between clients") excludedIDs.push(anotherClient[0].id)
		setExcludedClientID(excludedIDs)
	}, [client, anotherClient, travelType])

	const removeExpenseItem = (index: number) => {
		const t = [...travelExpenses]
		t.splice(index, 1)

		setValue("travelExpenses", t)
	}

	const addExpense = (data: TravelExpenseInput) => {
		setValue("travelExpenses", [...travelExpenses, data])
		setExpensesModalOpen(false)
	}

	const [css] = useStyletron()
	const container: string = css({
		height: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const scrollingDiv = css({
		height: "100%",
		maxHeight: "100%",
		paddingRight: "8px",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const rangeTimeStyle = css({
		marginTop: "8px",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		"@media only screen and (max-width: 700px)": {
			flexWrap: "wrap",
		},
	})
	const timerStyle = css({
		width: "30%",
		"@media only screen and (max-width: 700px)": {
			width: "40%",
			margin: "5px",
		},
	})
	const durationStyle = css({
		width: "30%",
		"@media only screen and (max-width: 700px)": {
			width: "100%",
		},
	})
	const loadingIcon = css({
		position: "absolute",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)",
	})
	const odometerInputWrap = css({
		display: "flex",
		justifyContent: "space-between",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const hereLink = css({
		marginLeft: "3px",
		textDecoration: "underline",
	})

	const expenseItems: TravelExpense[] = React.useMemo(() => {
		return travelExpenses.map((value) => ({
			expenseType: value.expenseType,
			amount: value.amount,
			fileName: value.fileName,
			id: value.id || "",
			blobID: value.blobID,
		}))
	}, [travelExpenses])

	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
			{(createTravel.loading || distanceLoading || getTravelTypes.loading) && (
				<div className={loadingIcon}>
					<StyledSpinnerNext />
				</div>
			)}
			<LabelLarge marginBottom="8px">
				{travel ? "Edit" : "New"} Travel{searchArgs.get("client_name") && ` - ${snakeToTitle(searchArgs.get("client_name") || "")}`}
			</LabelLarge>
			<div className={scrollingDiv}>
				<ZenUserSelect
					label="Worker"
					formName="worker"
					formRef={control}
					inputError={errors.worker}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
						},
					}}
					actionOnChange={(v) => {
						setExcludedUserID(v.length > 0 && v[0].id?.toString() ? [v[0].id.toString()] : [])
						onWorkerChange(v)
					}}
					excludedID={excludedUserID}
					disabled={!hasPermission(RolePermission.TravelUpdate)}
				/>
				<ZenSelect
					label={"Travel Type"}
					formName={"travelType"}
					formRef={control}
					inputError={errors.travelType}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Travel type is required",
						},
					}}
					placeholder={"Select travel type"}
					options={travelTypeOptions}
					actionOnChange={onTravelTypeChange}
				/>
				{travelWithClientError && (
					<Notification
						kind="negative"
						overrides={{
							Body: {
								style: {
									width: "100%",
								},
							},
						}}
					>
						{travelWithClientError}
					</Notification>
				)}
				<ZenDatePicker label="Date" formName="date" formRef={control} clearable={false} actionOnChange={handleDateOnChange} />
				<ZenTimezoneSelect
					formRef={control}
					formName="timezone"
					label="Timezone"
					timezone={[timezone]}
					clearable={false}
					actionOnChange={(v) => {
						const offsetDifferent = watchedTimezone[0].offsetMinutes - v[0].offsetMinutes
						if (startTime) {
							const newStartTime = moment(startTime).add(offsetDifferent, "minute").toDate()
							setValue("startTime", newStartTime)

							if (endTime) {
								const newEndTime = moment(endTime).add(offsetDifferent, "minute").toDate()
								setValue("endTime", newEndTime)
							}

							calcDist({ startTimeInput: newStartTime })
						}
					}}
				/>
				<div className={rangeTimeStyle}>
					<div className={timerStyle}>
						<ZenTimePicker
							label="Start Time"
							formName="startTime"
							date={watch("date")}
							inputError={errors.startTime}
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "Start time is required"
										}
										return null
									},
								},
							}}
							creatable={false}
							nullable
							formRef={control}
							actionOnChange={(d) => calcDist({ startTimeInput: d })}
						/>
					</div>
					<div className={timerStyle}>
						<ZenTimePicker
							label=""
							customLabel={
								<StatefulPopover
									content={() => {
										if (!endTimeIsManuallyChanged) {
											return <LabelXSmall padding={"10px"}>End Time will be calculated based on travel from and destination</LabelXSmall>
										}
										return <LabelXSmall padding={"10px"}>End Time had been manually edited</LabelXSmall>
									}}
									triggerType={TRIGGER_TYPE.hover}
									returnFocus
									autoFocus
								>
									<LabelSmall>
										End Time{" "}
										<FontAwesomeIcon
											size={"lg"}
											color={endTimeIsManuallyChanged ? ZenTheme.colors.warning300 : undefined}
											icon={endTimeIsManuallyChanged ? ["fal", "exclamation-triangle"] : ["fal", "question-circle"]}
										/>
									</LabelSmall>
								</StatefulPopover>
							}
							formName="endTime"
							disabled={!(currentUser && hasPermission(RolePermission.TravelUpdate))}
							creatable
							formRef={control}
							date={watch("date")}
							nullable
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "End time is required"
										}
										return null
									},
								},
							}}
							actionOnChange={(date: Date) => {
								if (!!calculatedEndTime && moment(date).isSame(calculatedEndTime)) {
									setEndTimeIsManuallyChanged(false)
									return
								}
								setEndTimeIsManuallyChanged(true)
							}}
						/>
					</div>
					<div className={durationStyle}>
						<FormControl label={<LabelSmall>Duration</LabelSmall>}>
							<Input disabled value={startTime && endTime ? timeDuration(startTime, endTime) : undefined} />
						</FormControl>
					</div>
				</div>
				<div>
					{!!workerAvailability.state && workerAvailability.message !== "Start date must be before end date" && (
						<>
							<AvailableCheck isAvailable={workerAvailability.state} />
							{!!workerAvailability.state && !workerAvailability.state.available && workerAvailability.state.session && (
								<Notification
									overrides={{
										Body: {
											style: {
												width: "100%",
											},
										},
									}}
								>
									If this travel is in relation to this session, please create the travel on the session overview page
									<Link to={routes.sessions.view.replace(":identifier", `${workerAvailability.state?.session?.identifier}` || "")} className={hereLink}>
										here
									</Link>
								</Notification>
							)}
						</>
					)}

					{/* Message when trying to create a travel in session view page  */}
					{checkSessionTravelAvailability.payload && checkSessionTravelAvailability.payload.message !== "Start date must be before end date" && (
						<>
							<AvailableCheck isAvailable={{ ...checkSessionTravelAvailability.payload, message: sessionTravelAvailabilityMsg }} />

							{!checkSessionTravelAvailability.payload.available && (
								<AvailableCheck
									isAvailable={{
										...checkSessionTravelAvailability.payload,
										message: "The Travel Type must be 'Travel with client' if you want to overlap with this session",
									}}
								/>
							)}
						</>
					)}
				</div>
				{travelType.length > 0 && (travelsOnly.some((t) => travelType[0].label === t)) && (
					<ZenClientSelect
						label="From Client"
						formName="anotherClient"
						formRef={control}
						inputError={errors.anotherClient}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Client is required",
							},
						}}
						excludedID={excludedClientID}
						// actionOnChange={(v) => onAnotherClientChange(v)}
						actionOnChange={(v) => onClientChange(v)}
					/>
				)}

				{travelType.length > 0 && travelType[0].label && Object.values(TravelTypesForShowingClient).some((jt) => jt === travelType[0].label) && (
					<>
						<ZenClientSelect
							label={(travelType[0].label === TravelTypesForShowingClient.travelBetweenClients || travelType[0].label === TravelTypesForShowingClient.cBProviderTravel) ? "To Client" : "Client"}
							formName="client"
							formRef={control}
							inputError={errors.client}
							formRules={{
								validate: {
									required: (value: Value) => {
										if (travelType[0].label === TravelTypesForShowingClient.homeToWork || travelType[0].label === TravelTypesForShowingClient.workToHome) {
											return true
										}
										return (!!value && value.length > 0) || "Client is required"
									},
								},
							}}
							excludedID={excludedClientID}
							// actionOnChange={(v) => onClientChange(v)}
							actionOnChange={(v) => onAnotherClientChange(v)}
						/>
					</>
				)}
				<ZenPlaceSelect
					label="Travel From"
					formName="fromAddress"
					formRef={control}
					placeholder="Address here"
					inputError={errors.fromAddress}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Address is required",
							invalidAddress: (value: Value) => value[0].id !== "" || "Address is invalid, please regenerate it.",
						},
					}}
					actionOnChange={(v) => calcDist({ fromAddressInput: v as Option[] })}
				/>
				<ZenPlaceSelect
					label="Destination"
					formName="toAddress"
					formRef={control}
					placeholder="Address here"
					inputError={errors.toAddress}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Address is required",
							invalidAddress: (value: Value) => value[0].id !== "" || "Address is invalid, please regenerate it.",
						},
					}}
					actionOnChange={(v) => calcDist({ toAddressInput: v as Option[] })}
				/>
				<ZenInput
					formRef={control}
					nameRef="distance"
					placeholder="KM here"
					label="Distance (KM)"
					customLabel={
						<StatefulPopover
							content={() => {
								if (!distanceIsManuallyChanged) {
									return <LabelXSmall padding={"10px"}>Distance will be calculated based on travel from and destination</LabelXSmall>
								}
								return <LabelXSmall padding={"10px"}>Distance had been manually edited</LabelXSmall>
							}}
							triggerType={TRIGGER_TYPE.hover}
							returnFocus
							autoFocus
							placement="left"
						>
							<LabelSmall>
								Distance (KM){" "}
								<FontAwesomeIcon
									size={"lg"}
									color={distanceIsManuallyChanged ? ZenTheme.colors.warning300 : undefined}
									icon={distanceIsManuallyChanged ? ["fal", "exclamation-triangle"] : ["fal", "question-circle"]}
								/>
							</LabelSmall>
						</StatefulPopover>
					}
					disabled={!(currentUser && hasPermission(RolePermission.TravelUpdate))}
					inputError={errors.distance}
					formRules={{
						pattern: {
							value: kilometersRegex,
							message: "Please enter a valid distance",
						},
						validate: {
							required: (value: string) => {
								return (!!value && !isNaN(parseFloat(value)) && parseFloat(value) > 0) || "Distance is required"
							},
						},
					}}
					type="number"
					actionOnChange={(event) => {
						const distStr = event.currentTarget.value
						const dist = parseFloat(distStr)
						if (!isNaN(dist) && Math.round(dist * 1000) === calculatedDistanceMetres) {
							setDistanceIsManuallyChanged(false)
							return
						}
						setDistanceIsManuallyChanged(true)
					}}
				/>

				<div className={odometerInputWrap}>
					<ZenInput
						type="number"
						formRef={control}
						nameRef="odometerBeforeKM"
						placeholder="Insert odometer value before trip"
						label="Odometer Before Trip (Optional)"
						disabled={!currentUser}
						inputError={errors.odometerBeforeKM}
						formRules={{
							pattern: {
								value: kilometersRegex,
								message: "Please enter a valid distance",
							},
						}}
					/>
					<Block minWidth="10px" />
					<ZenInput
						type="number"
						formRef={control}
						nameRef="odometerAfterKM"
						placeholder="Insert odometer value after trip"
						label="Odometer After Trip (Optional)"
						disabled={!currentUser}
						inputError={errors.odometerAfterKM}
						formRules={{
							pattern: {
								value: kilometersRegex,
								message: "Please enter a valid distance",
							},
						}}
					/>
				</div>
				<ZenCheckbox labelPlacement="right" formRef={control} formName="withCompanyCar" label="With Company Car" />
				{withCompanyCar && (
					<ZenSelect
						label={"Car"}
						formName={"companyCar"}
						formRef={control}
						inputError={errors.companyCar}
						placeholder={"Select which car you used"}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Car is required",
							},
						}}
						options={cars}
					/>
				)}
				{withCompanyCar && travelType.length > 0 && travelType[0].label === TravelTypesForShowingClient.travelWithClient && (
					<ZenSelect label="Funding Source" formName="fundingSource" formRef={control} options={fundingSources} />
				)}
				<ZenButton type="button" onClick={() => setExpensesModalOpen(true)} marginTop="5px">
					Add Travel Expense
				</ZenButton>
				{expensesModalOpen && <AddExpensesModal isOpen={expensesModalOpen} onSubmit={addExpense} setIsOpen={setExpensesModalOpen} />}
				<TravelExpenseList travelExpenses={expenseItems} removeItem={removeExpenseItem} />
			</div>
			{getTravelTypes.error && <ErrorNotification messageOrPayload={getTravelTypes.payload} />}
			{getCars.error && <ErrorNotification messageOrPayload={getCars.payload} />}
			{createTravel.error && <ErrorNotification messageOrPayload={createTravel.payload} />}
			{updateTravel.error && <ErrorNotification messageOrPayload={updateTravel.payload} />}
			<ErrorFieldTracker errorIDs={Object.keys(errors)} />
			<CancelAndSaveButtons
				buttonWidths={"28%"}
				cancelFn={() => {
					if (props.onCancel) {
						props.onCancel()
						return
					}
					// return back to client session tab, if client id exists
					const returnClientID = searchArgs.get("client_id")
					if (returnClientID) {
						history.push({
							pathname: routes.withID(returnClientID, routes.clients.client.root),
							hash: "travels",
						})
						return
					}
					// otherwise return to travel list page
					history.push(`/portal/travels`)
				}}
				saveLabel="Submit"
				isLoading={createTravel.loading || updateTravel.loading}
			/>
			<Prompt when={!isSubmitted && Object.keys(dirtyFields).length > 0} message={"You have unsaved changes, are you sure you want to leave?"} />
		</form>
	)
}
