import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Value } from "baseui/select"
import { ZenSelect, ZenUserSelect } from "components/zenComponents/zenSelectBox"
import { ZenDatePicker } from "components/zenComponents/zenTime"
import * as React from "react"
import { useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { ZenCard } from "../../../components/common"
import { fetching } from "../../../fetching"
import { ContractArea } from "../../../types/types"
import { ZenCheckbox } from "components/zenComponents/zenCheckboxList"
import { getErrorFromBlob, snakeToTitle, useDebounce } from "helpers/utils"
import { AttendanceStatus } from "types/enums"

export const GenerateFundingSourceReport = () => {
	const { control } = useForm()
	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})

	const { query, loading, error } = useParameterizedQuery(fetching.query.getFundingSourceReport)

	// fields
	const [type, setType] = React.useState<"SINGLE" | "GROUP">()
	const [fromDate, setFromDate] = React.useState<Date>()
	const [endDate, setEndDate] = React.useState<Date>()
	const [selectedFundingSourceID, setSelectedFundingSourceID] = React.useState<string>()
	const [selectedContractAreaID, setSelectedContractAreaID] = React.useState<string>()
	const [workerID, setWorkerID] = React.useState<string>()
	const [attendanceStatus, setAttendanceStatus] = React.useState<string[]>([]);

	const isGroupSession = type === "GROUP"

	// error
	const [errMsg, setErrMsg] = React.useState<string | undefined>()

	// get entire funding source list
	const getFundingSourceAll = useQuery(fetching.query.getFundingSourceAll())
	const fundingSourceList = React.useMemo(() => {
		if (getFundingSourceAll.loading || getFundingSourceAll.error || !getFundingSourceAll.payload) return []
		let list = [...getFundingSourceAll.payload]

		// filter out NDIA if it is a group session
		if (isGroupSession) list = list.filter((fs) => fs.label !== "NDIA")

		return list
	}, [getFundingSourceAll.payload, getFundingSourceAll.loading, getFundingSourceAll.error, isGroupSession])

	// options
	const [contractAreaOptions, setContractAreaOptions] = React.useState<ContractArea[]>([])

	// fetching function for contract area options
	const getContractAreas = useParameterizedQuery(fetching.query.getContractAreasByFundingSourceID)
	const refetchContractAreas = (fundingSourceID: string) => {
		getContractAreas.query(fundingSourceID).then((resp) => {
			if (resp.error || !resp.payload) return
			setContractAreaOptions(resp.payload)
		})
	}

	React.useEffect(() => {
		if (!selectedFundingSourceID) return
		refetchContractAreas(selectedFundingSourceID || "")
	}, [selectedFundingSourceID])

	const onSubmit = async () => {
		// do some validations
		if (!type) {
			setErrMsg("Session Type is required.")
			return
		}
		// if (!attendanceStatus) {
		// 	setErrMsg("Attendance Status is required.")
		// 	return
		// }
		if (!fromDate) {
			setErrMsg("From Date is required.")
			return
		}
		if (!endDate) {
			setErrMsg("To Date is required.")
			return
		}
		if (fromDate > endDate) {
			setErrMsg("From Date must be earlier than end date.")
			return
		}
		if (!selectedFundingSourceID) {
			setErrMsg("Funding Source is required.")
			return
		}
		if (!selectedContractAreaID) {
			setErrMsg("Contract Area is required.")
			return
		}

		setErrMsg("")

		// send request to generate funding source report
		const body = {
			sessionType: type,
			fundingSourceID: selectedFundingSourceID,
			contractAreaID: selectedContractAreaID,
			workerID: workerID,
			attendanceStatus : attendanceStatus,
			startDate: fromDate,
			endDate: endDate,
		}

		const resp = await query(body)

		if (resp.error || !resp.payload) {
			setErrMsg("failed to generate report")
			return
		}

		let filename = `funding_source_summary.xlsx`
		const downloadLink = document.createElement("a")
		downloadLink.href = URL.createObjectURL(resp.payload)
		downloadLink.download = filename
		downloadLink.click()
	}
	return (
		<ZenCard className={container}>
			<form
				autoComplete="off"
				onSubmit={(e) => {
					e.preventDefault()
					onSubmit()
				}}
			>
				<ZenSelect
					label="Type"
					formName="type"
					formRef={control}
					clearable={false}
					actionOnChange={(e) => {
						if (!e || !e[0] || (e[0].id !== "SINGLE" && e[0].id !== "GROUP")) {
							return
						}
						setType(e[0].id)
					}}
					options={[
						{ label: "Single", id: "SINGLE" },
						{ label: "Group", id: "GROUP" },
					]}
				/>

				<ZenSelect
				multi={true}
				options={[
					{ id: "ATTENDED", label: snakeToTitle("ATTENDED") },
					{ id: "DNA", label: snakeToTitle("DNA") },
					{ id: "SHORT_NOTICE_CANCELLATION", label: snakeToTitle("SHORT_NOTICE_CANCELLATION") },
					{ id: "ENDED_EARLY", label: snakeToTitle("ENDED_EARLY") },
					{ id: "CANCELLED", label: snakeToTitle("CANCELLED") },
				]}
				label="Attendance Status (Optional)"
				formName="attendanceStatus"
				formRef={control}
				actionOnChange={(selectedOptions) => {
					// Map over selectedOptions to extract the 'id' as a string
					if (selectedOptions) {
					setAttendanceStatus(selectedOptions.map(option => option.id as string)); // Type assertion here
					} else {
					setAttendanceStatus([]);
					}
				}}
				/>

				<ZenDatePicker nullDefaultValue formRef={control} actionOnChange={(e) => setFromDate(e)} label={"From"} formName={"periodStart"} />
				<ZenDatePicker nullDefaultValue formRef={control} actionOnChange={(e) => setEndDate(e)} label={"To"} formName={"periodEnd"} />
				<ZenSelect
					label="Funding Source"
					formName="fundingSource-0"
					formRef={control}
					clearable={false}
					actionOnChange={(e) => {
						if (!e || !e[0]) {
							return
						}
						setSelectedFundingSourceID(`${e[0].id}`)
					}}
					options={fundingSourceList}
				/>
				<ZenSelect
					label="Contract area"
					formRef={control}
					actionOnChange={(e) => {
						if (!e || !e[0]) {
							return
						}

						setSelectedContractAreaID(`${e[0].id}` || "")
					}}
					formName="contractArea"
					options={contractAreaOptions}
				/>

				<ZenUserSelect
					label="Worker"
					formName="worker"
					formRef={control}
					placeholder="Select Worker (optional)"
					actionOnChange={(w: Value) => setWorkerID(w.length > 0 && w[0].id ? w[0].id.toString() : "")}
				/>
				{errMsg && <div style={{ color: "red" }}>{errMsg}</div>}
				<Button $style={{ marginTop: "1rem" }} type="submit">
					Export
				</Button>
			</form>
		</ZenCard>
	)
}
