import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { useStateMachine } from "little-state-machine"
import { useHistory } from "react-router-dom"

import { updateCallLogCreate } from "../../actions/callLogCreate"
import { callLogCreateInitialState } from "../../states/callLogCreate"
import { ZenTheme } from "../../themeOverrides"
import { ZenConfirmModal } from "../zenComponents/zenConfirmModal"
import { routes } from "routes"

/**
 * Drops a Floating Action Button that reminds workers to complete the form
 */
export const CallLogFloatingActionButton = () => {
	const [openModal, setOpenModal] = React.useState(false)
	const history = useHistory()
	const { state, actions } = useStateMachine({
		updateCallLogCreate,
	})

	const [css, theme] = useStyletron()

	const containerStyle = css({
		...theme.typography.LabelMedium,
		display: !state?.callLogCreate.stale || history.location.pathname === routes.callLogs.create ? "none" : "block",
		position: "absolute",
		bottom: "25px",
		right: "70px",
		cursor: "pointer",
	})

	const innerStyle = css({
		position: "relative",
		padding: "15px 25px 15px 15px",
		borderRadius: "3px",
		border: "1.5px solid white",
		boxShadow: "#00000029 0px 0px 6px",
		backgroundColor: ZenTheme.colors.primaryGreen,
		color: theme.colors.white,
	})

	const phoneIconStyle = css({
		display: "inline-block",
		marginRight: "15px",
	})

	const closeIconStyle = css({
		position: "absolute",
		top: "5px",
		right: "5px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: theme.colors.white,
		color: theme.colors.contentPrimary,
		fontSize: "0.6rem",
		borderRadius: "50%",
		width: "16px",
		height: "16px",
		cursor: "pointer",
	})

	return (
		<>
			<div className={containerStyle} onClick={() => history.push(routes.callLogs.create)}>
				<div className={innerStyle}>
					<FontAwesomeIcon className={phoneIconStyle} icon={["fal", "phone-square"]} /> Resume Call Log
					<div
						className={closeIconStyle}
						title={"Cancel Call Log"}
						onClick={(e) => {
							e.stopPropagation()
							setOpenModal(true)
						}}
					>
						<FontAwesomeIcon icon={["fal", "times"]} />
					</div>
				</div>
			</div>
			{openModal && (
				<ZenConfirmModal
					open={openModal}
					setOpen={setOpenModal}
					loading={false}
					title={"Are you sure you want to delete"}
					message={"Call log draft?"}
					action={"Delete"}
					confirm={() => {
						setOpenModal(false)
						actions.updateCallLogCreate(callLogCreateInitialState())
					}}
				/>
			)}
		</>
	)
}
