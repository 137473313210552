import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { CheckboxOverrides, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox"
import { ModalBody, ROLE, SIZE } from "baseui/modal"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import { FieldError, useForm, useFormContext } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"
import { useHistory } from "react-router-dom"
import { PortalContainer } from "../../../../../controllers/portal"
import { CancelAndSaveButtons } from "../../../../cancelSaveButtons"
import { Divider } from "../../../../common"
import { ZenButton } from "../../../../zenComponents/zenButtons"
import { ZenCheckbox } from "../../../../zenComponents/zenCheckboxList"
import { ZenInput, ZenTextArea } from "../../../../zenComponents/zenInput"
import { IntakeAdultProps, intakeURLParser } from "../intakeAdultBaseForm"
import { ErrorFieldTracker } from "../../../../forms/errorFieldTracker"

// field list for cleaning up the value
const fieldSetThree = ["TypeOfSupportOther", "ContactNameOther", "ContactNumberOther", "FrequencyOfSupportOther"]

interface NDISMentalHealthTreatingTeamProps extends IntakeAdultProps {
	otherMentalTreatingTeams: string[]
	setOtherMentalTreatingTeams: React.Dispatch<React.SetStateAction<string[]>>
}

export const IntakeNDISMentalHealthTeam = (props: NDISMentalHealthTreatingTeamProps) => {
	const { clientID, formID, isReadOnly, otherMentalTreatingTeams, setOtherMentalTreatingTeams, validationTriggered } = props
	const history = useHistory()
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	const scrollDiv = css({
		paddingRight: "10px",
		marginTop: "10px",
		maxHeight: "100%",
		width: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		paddingBottom: "10px",
	})
	const subForm = css({
		padding: "10px",
	})
	const checkboxOverrides: CheckboxOverrides = {
		Root: {
			style: {
				marginTop: "5px",
			},
		},
	}

	const { trigger, control, errors, setValue, watch, getValues } = useFormContext()
	const { clientLoading } = PortalContainer.useContainer()
	const [openModal, setOpenModal] = React.useState<boolean>(false)

	const clearData = (serviceName: string, fields: string[]) => {
		fields.forEach((fn) => {
			setValue(`${serviceName}${fn}`)
		})
	}

	const hasMentalHealthTeam = watch("hasMentalHealthTeam")
	const hadRecentHospitalisationForMentalHealth = watch("hadRecentHospitalisationForMentalHealth")
	const hasGP = watch("hasGP")
	const hasPsychiatrist = watch("hasPsychiatrist")
	const hasCaseManager = watch("hasCaseManager")
	const hasPsychologist = watch("hasPsychologist")

	const handleAddNewService = (newMentalTreatingTeamName: string) => {
		setOtherMentalTreatingTeams(otherMentalTreatingTeams.concat(newMentalTreatingTeamName))
	}

	const validation = React.useCallback(async () => {
		const checkList: string[] = []

		if (hadRecentHospitalisationForMentalHealth) checkList.push("recentHospitalisation")
		if (hasMentalHealthTeam) {
			if (hasGP) checkList.push("GPContactName", "GPOrganisation", "GPContactNumber", "GPFrequencyOfSupport")
			if (hasPsychiatrist) checkList.push("PsychiatristContactName", "PsychiatristOrganisation", "PsychiatristContactNumber", "PsychiatristFrequencyOfSupport")
			if (hasCaseManager) checkList.push("Case ManagerOrganisation", "Case ManagerContactName", "Case ManagerContactNumber", "Case ManagerFrequencyOfSupport")
			if (hasPsychologist) checkList.push("PsychologistPractice", "PsychologistContactName", "PsychologistContactNumber", "PsychologistFrequencyOfSupport")
			if (otherMentalTreatingTeams.length > 0) {
				otherMentalTreatingTeams.forEach((o) => {
					checkList.push(`${o}TypeOfSupportOther`, `${o}ContactNameOther`, `${o}ContactNumberOther`, `${o}FrequencyOfSupportOther`)
				})
			}
		} else {
			checkList.push("reasonForNoMentalHealthTreatingTeam")
		}

		// skip check if checking list is empty
		if (checkList.length === 0) return true

		const isValid = await trigger(checkList)
		return isValid
	}, [hadRecentHospitalisationForMentalHealth, hasCaseManager, hasGP, hasMentalHealthTeam, hasPsychiatrist, hasPsychologist, otherMentalTreatingTeams, trigger])

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validation, validationTriggered])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return
		nextPage()
	}
	const nextPage = () => history.push(intakeURLParser(`/portal/clients/${clientID}/intake/ndis/ndisSupports`, formID, true))

	return (
		<>
			<form autoComplete="off" className={container}>
				<LabelLarge>Mental health treatment team</LabelLarge>
				<div className={body}>
					<div className={scrollDiv}>
						<ZenCheckbox
							formRef={control}
							formName="hasMentalHealthTeam"
							label="Do you have a mental health treatment team"
							disabled={isReadOnly}
							checkmarkType={STYLE_TYPE.toggle_round}
							labelPlacement={LABEL_PLACEMENT.left}
						/>
						{!hasMentalHealthTeam && (
							<ZenTextArea
								disabled={isReadOnly}
								label=" "
								nameRef="reasonForNoMentalHealthTreatingTeam"
								formRef={control}
								formRules={{ required: "Details are required" }}
								inputError={errors.reasonForNoMentalHealthTreatingTeam}
								placeholder="If no, is there a reason for this? Is there anyone providing support with medication/mental health needs currently"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						<ZenCheckbox
							formRef={control}
							label="Has there been a recent hospitalisation for your mental health (3 months)"
							formName="hadRecentHospitalisationForMentalHealth"
							disabled={isReadOnly}
							checkmarkType={STYLE_TYPE.toggle_round}
							labelPlacement={LABEL_PLACEMENT.left}
						/>
						{hadRecentHospitalisationForMentalHealth && (
							<ZenTextArea
								disabled={isReadOnly}
								label=" "
								nameRef="recentHospitalisation"
								formRef={control}
								formRules={{ required: "Details are required" }}
								inputError={errors.recentHospitalisation}
								placeholder="If yes, when were you discharged and is there a safeguarding plan in place?"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						{hasMentalHealthTeam && (
							<>
								<Divider style={{ backgroundColor: "transparent" }} />
								<ZenCheckbox formRef={control} disabled={isReadOnly} label="Do you have a GP?" formName="hasGP" checkmarkType="toggle_round" />
								{hasGP && (
									<>
										<div className={subForm}>
											<ZenInput disabled={isReadOnly} label="Name" nameRef="GPContactName" inputError={errors.GPContactName} formRef={control} required />
											<ZenInput disabled={isReadOnly} label="Practice" nameRef="GPOrganisation" inputError={errors.GPOrganisation} formRef={control} required />
											<ZenInput
												disabled={isReadOnly}
												label="Contact number"
												nameRef="GPContactNumber"
												inputError={errors.GPContactNumber}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Frequency of support"
												nameRef="GPFrequencyOfSupport"
												inputError={errors.GPFrequencyOfSupport}
												formRef={control}
												required
											/>
										</div>
										<Divider />
									</>
								)}
								<ZenCheckbox
									formRef={control}
									disabled={isReadOnly}
									formName="hasPsychiatrist"
									label="Do you have a Psychiatrist?"
									overrides={checkboxOverrides}
									checkmarkType="toggle_round"
								/>

								{hasPsychiatrist && (
									<>
										<div className={subForm}>
											<ZenInput
												disabled={isReadOnly}
												label="Name"
												nameRef="PsychiatristContactName"
												inputError={errors.PsychiatristContactName}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Practice"
												nameRef="PsychiatristOrganisation"
												inputError={errors.PsychiatristOrganisation}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Contact number"
												nameRef="PsychiatristContactNumber"
												inputError={errors.PsychiatristContactNumber}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Frequency of support"
												nameRef="PsychiatristFrequencyOfSupport"
												inputError={errors.PsychiatristFrequencyOfSupport}
												formRef={control}
												required
											/>
										</div>
										<Divider />
									</>
								)}
								<ZenCheckbox
									formRef={control}
									disabled={isReadOnly}
									formName="hasPsychologist"
									label="Do you have a Psychologist?"
									overrides={checkboxOverrides}
									checkmarkType="toggle_round"
								/>

								{hasPsychologist && (
									<>
										<div className={subForm}>
											<ZenInput
												disabled={isReadOnly}
												label="Name"
												nameRef="PsychologistContactName"
												inputError={errors.PsychologistContactName}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Practice"
												nameRef="PsychologistPractice"
												inputError={errors.PsychologistPractice}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Contact number"
												nameRef="PsychologistContactNumber"
												inputError={errors.PsychologistContactNumber}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Frequency of support"
												nameRef="PsychologistFrequencyOfSupport"
												inputError={errors.PsychologistFrequencyOfSupport}
												formRef={control}
												required
											/>
										</div>
										<Divider />
									</>
								)}
								<ZenCheckbox
									formRef={control}
									formName="hasCaseManager"
									label="Do you have a Case Manager?"
									disabled={isReadOnly}
									checked={hasCaseManager}
									overrides={checkboxOverrides}
									checkmarkType="toggle_round"
								/>

								{hasCaseManager && (
									<>
										<div className={subForm}>
											<ZenInput
												disabled={isReadOnly}
												label="Name"
												nameRef="Case ManagerContactName"
												inputError={errors[`Case ManagerContactName`]}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Organisation"
												nameRef="Case ManagerOrganisation"
												inputError={errors[`Case ManagerOrganisation`]}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Contact number"
												nameRef="Case ManagerContactNumber"
												inputError={errors[`Case ManagerContactNumber`]}
												formRef={control}
												required
											/>
											<ZenInput
												disabled={isReadOnly}
												label="Frequency of support"
												nameRef="Case ManagerFrequencyOfSupport"
												inputError={errors[`Case ManagerFrequencyOfSupport`]}
												formRef={control}
												required
											/>
										</div>
									</>
								)}
								{/*Other support teams*/}
								<LabelMedium marginTop="6px">Other HelpingMinds services</LabelMedium>
								{otherMentalTreatingTeams.length > 0 &&
									otherMentalTreatingTeams.map((s, i) => (
										<ServiceForm
											disabled={isReadOnly}
											key={i}
											service={s}
											control={control}
											errors={errors}
											clear={() => {
												if (isReadOnly) return
												// modify service list and id list
												const newServiceList = [...otherMentalTreatingTeams]
												newServiceList.splice(i, 1)
												setOtherMentalTreatingTeams(newServiceList)
												clearData(s, fieldSetThree)
											}}
										/>
									))}
								{!isReadOnly && (
									<ZenButton type="button" marginTop="8px" width="100%" onClick={() => setOpenModal(true)}>
										Add new service
									</ZenButton>
								)}
							</>
						)}
					</div>
					{/* err message */}
					<ErrorFieldTracker errorIDs={Object.keys(errors)} />
					{!isReadOnly && <CancelAndSaveButtons cancelFn={history.goBack} cancelLabel="Back" saveLabel="Next" isLoading={clientLoading} saveFn={onSubmit} />}
				</div>
			</form>
			<AddServiceModal isOpen={openModal} addNewService={handleAddNewService} setIsOpen={setOpenModal} />
		</>
	)
}

// Component for display additional service fields
interface ServiceFormProps {
	service: string
	control: any
	errors: DeepMap<Record<string, any>, FieldError>
	clear: () => void
	disabled?: boolean
}
const ServiceForm = (props: ServiceFormProps) => {
	const { service, control, errors, clear, disabled } = props
	const [css] = useStyletron()
	const subForm = css({
		padding: "10px",
	})

	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: "10px",
	})
	return (
		<>
			<div className={title}>
				<LabelMedium>{service}</LabelMedium>
				<FontAwesomeIcon icon={["fal", "times-circle"]} onClick={clear} />
			</div>
			<div className={subForm}>
				<ZenInput
					disabled={disabled}
					label="Type of Support"
					nameRef={`${service}TypeOfSupportOther`}
					inputError={errors[`${service}TypeOfSupportOther`]}
					formRef={control}
					required
				/>
				<ZenInput
					disabled={disabled}
					label="Name"
					nameRef={`${service}ContactNameOther`}
					inputError={errors[`${service}ContactNameOther`]}
					formRef={control}
					required
				/>
				<ZenInput
					disabled={disabled}
					label="Contact number"
					nameRef={`${service}ContactNumberOther`}
					inputError={errors[`${service}ContactNumberOther`]}
					formRef={control}
					required
				/>
				<ZenInput
					disabled={disabled}
					label="Frequency of support"
					nameRef={`${service}FrequencyOfSupportOther`}
					inputError={errors[`${service}FrequencyOfSupportOther`]}
					formRef={control}
					required
				/>
			</div>
			<Divider />
		</>
	)
}

// Modal for creating additional services
interface AddServiceModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	addNewService: (serviceName: string) => void
}
const AddServiceModal = (props: AddServiceModalProps) => {
	const { isOpen, setIsOpen, addNewService } = props

	const { setValue, getValues, control, errors, handleSubmit } = useForm()

	const clearData = () => {
		setValue("serviceName", "")
	}
	const onSubmit = () => {
		addNewService(getValues().serviceName)
		onClose()
	}
	const onClose = () => {
		clearData()
		setIsOpen(false)
	}

	const [css] = useStyletron()
	const container = css({
		width: "400px",
	})
	return (
		<ZenModal
			onClose={onClose}
			isOpen={isOpen}
			role={ROLE.dialog}
			size={SIZE.auto}
			overrides={{
				Close: {
					style: {
						display: "none",
					},
				},
			}}
		>
			<ModalBody>
				<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
					<ZenInput
						label="Enter New Service Name"
						nameRef={"serviceName"}
						inputError={errors.serviceName}
						formRef={control}
						formRules={{ required: "Service Name is required" }}
					/>
					<ZenButton>Add</ZenButton>
				</form>
			</ModalBody>
		</ZenModal>
	)
}
