import * as React from "react"
import { useStyletron } from "baseui"
import { Textarea } from "baseui/textarea"
import { Controller, useFormContext } from "react-hook-form"

import { ZenInputOverrides } from "../../../themeOverrides"
import { WellbeingFormData } from "./wellbeingPlan"

export enum Domain {
	Mind = "Mind",
	Body = "Body",
	Spirit = "Spirit",
	Safety = "Safety",
	Work = "Work",
	Emotions = "Emotions",
	Relationships = "Relationships",
}

export interface DomainInfoRet {
	sub: string
	colour: string
	description: string
}

export interface DomainScore {
	score: number
	reason: string
	currentStrengths: string
	thingsToDevelop: string
}

export const DomainInfo = (domain: Domain): DomainInfoRet => {
	switch (domain) {
		case Domain.Mind:
			return {
				sub: " (Cognitive wellbeing)",
				colour: "#F56161",
				description: "Maintaining positive ways of thinking and problem solving",
			}
		case Domain.Body:
			return {
				sub: " (Physical wellbeing)",
				colour: "#EA954C",
				description: "Maintaining healthy diet, exercise and personal self-care",
			}
		case Domain.Spirit:
			return {
				sub: " (Spiritual wellbeing)",
				colour: "#F5EF61",
				description: "Experiencing meaning and purpose through connection to nature, music/art, religion, meditation practices",
			}
		case Domain.Safety:
			return {
				sub: "",
				colour: "#58C969",
				description: "Feeling free from harm or danger emotionally and/or physically",
			}
		case Domain.Work:
			return {
				sub: "/School/Volunteering",
				colour: "#4FB4C6",
				description: "Finding purposeful ways to apply yourself and finding balance between work/life commitments",
			}
		case Domain.Emotions:
			return {
				sub: " (Emotional wellbeing)",
				colour: "#B45AE3",
				description: "Enabling yourself to accept and safely manage your full range of emotions",
			}
		case Domain.Relationships:
			return {
				sub: " (Relational wellbeing)",
				colour: "#E56DAD",
				description: "Seeking / maintaining healthy, supportive relationships; feeling a sense of belonging / community",
			}
	}
}

interface WellbeingDomainProps {
	index: number
	domain: Domain
	disabled?: boolean
}

export const WellbeingDomain = (props: WellbeingDomainProps) => {
	const { index, domain, disabled } = props

	const info = DomainInfo(domain)
	const { control } = useFormContext<WellbeingFormData>()

	// Styling
	const [css, theme] = useStyletron()
	const headerStyle = css({
		width: "100%",
		borderRadius: "3px",
		backgroundColor: info.colour,
		color: domain === Domain.Spirit ? theme.colors.black : "white",
		fontSize: "14px",
		marginTop: "20px",
		marginBottom: "20px",
		padding: "10px",
	})
	const titleStyle = css({
		fontWeight: "bold",
	})
	const labelStyle = css({
		fontWeight: 600,
		fontSize: "14px",
		marginBottom: "8px",
	})
	const flexStyle = css({
		display: "flex",
	})
	const rowStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
			justifyContent: "initial",
		},
	})
	const scoreStyle = css({
		maxWidth: "50%",
		"@media only screen and (max-width: 700px)": {
			maxWidth: "100%",
		},
	})
	const textAreaInputStyle = css({
		flex: "1",
		marginLeft: "10px",
	})

	return (
		<div>
			<div className={headerStyle}>
				<div className={titleStyle}>{domain + info.sub}</div>
				<div>{info.description}</div>
			</div>
			<div className={rowStyle}>
				<div className={scoreStyle}>
					<div className={labelStyle}>{`${disabled ? "" : "Select "}${domain + (domain === Domain.Work ? info.sub : "")} Score`}</div>

					<Controller
						name={`scores[${index}].score`}
						control={control}
						defaultValue={0}
						render={({ onChange, value, name }) => (
							<div id={name} className={flexStyle}>
								{Array.from(Array(6)).map((_, i) => (
									<ScoreButton index={i} key={`domain-${domain}-score-${i}`} setScore={(value) => onChange(value)} currentScore={value} disabled={disabled} />
								))}
							</div>
						)}
					/>
				</div>
				<div className={textAreaInputStyle}>
					<div className={labelStyle}>Reason for this score</div>
					<Controller
						name={`scores[${index}].reason`}
						control={control}
						as={Textarea}
						placeholder={!disabled ? "Enter Description" : ""}
						disabled={disabled}
						overrides={ZenInputOverrides}
					/>
				</div>
			</div>

			<div className={rowStyle}>
				<div className={textAreaInputStyle}>
					<div className={labelStyle}>Their current strengths</div>
					<Controller
						name={`scores[${index}].currentStrengths`}
						control={control}
						as={Textarea}
						placeholder={!disabled ? "Enter Description" : ""}
						disabled={disabled}
						overrides={ZenInputOverrides}
					/>
				</div>
				<div className={textAreaInputStyle}>
					<div className={labelStyle}>Things they would like to develop</div>
					<Controller
						name={`scores[${index}].thingsToDevelop`}
						control={control}
						as={Textarea}
						placeholder={!disabled ? "Enter Description" : ""}
						disabled={disabled}
						overrides={ZenInputOverrides}
					/>
				</div>
			</div>
		</div>
	)
}

const ScoreButton = (props: { index: number; setScore: (val: number) => void; currentScore: number; disabled?: boolean }) => {
	const { index, setScore, disabled } = props
	const selected = props.currentScore === index

	const [css, theme] = useStyletron()
	const scoreButtonStyle = css({
		width: "90px",
		height: "90px",
		borderRadius: "3px",
		backgroundColor: selected ? "#edffd1" : "white",
		borderWidth: "2px",
		borderColor: selected ? theme.colors.primary : "#D6DBF0",
		color: selected ? theme.colors.primary : "#abb0c4",
		display: "flex",
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "34px",
		cursor: disabled ? "default" : "pointer",
		userSelect: "none",
		marginRight: index !== 5 ? "15px" : "unset",
		":hover": disabled
			? {}
			: {
					backgroundColor: "#e4ffba",
					borderColor: theme.colors.primary,
					color: theme.colors.primary,
			  },
	})
	return (
		<div className={scoreButtonStyle} onClick={disabled ? undefined : () => setScore(index)}>
			{index}
		</div>
	)
}
