import * as React from "react"
import { useQuery } from "react-fetching-library"
import { RouteComponentProps } from "react-router-dom"
import { fetching } from "../../fetching"
import { getTimesheetStatus, friendlyDate, snakeToTitle } from "../../helpers/utils"
import { ListTable, useListTable } from "../../components/listTable"
import { Timesheet, TZString } from "../../types/types"
import { ZenCard } from "../../components/common"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { TimesheetReview } from "../reports/timeSheets"
import { getErrorMessage } from "../../helpers/errors"

export const WorkerTimesheets = (props: RouteComponentProps<{ workerID: string }>) => {
	const workerID = props.match.params.workerID
	const [timeSheets, setTimeSheets] = React.useState<Timesheet[]>([])
	const [selectedTimeSheetID, setSelectedTimeSheetID] = React.useState("")
	const { limit, offset, setTotal, total, setOffset } = useListTable({})

	const timeSheetsQuery = useQuery(
		fetching.query.getManyTimesheets({
			search: {
				workerID,
				sortBy: "PeriodStart",
				sortDir: "Descending",
			},
			limit,
			offset,
		}),
	)
	React.useEffect(() => {
		if (!timeSheetsQuery.payload) return
		setTimeSheets(timeSheetsQuery.payload.timesheets)
		setTotal(timeSheetsQuery.payload.total)
	}, [timeSheetsQuery.payload, setTotal])

	return (
		<ZenCard style={{ height: "100%" }}>
			<ListTable
				error={timeSheetsQuery.error ? getErrorMessage(timeSheetsQuery.payload) : undefined}
				isLoading={timeSheetsQuery.loading}
				rows={timeSheets}
				onRowClick={(row: Timesheet) => setSelectedTimeSheetID(row.id)}
				columns={[
					{
						id: "User",
						header: "User",
						resolver: (row: Timesheet) => `${row.user.firstName} ${row.user.lastName}`,
					},
					{
						id: "From",
						header: "From",
						resolver: (row: Timesheet) => friendlyDate(row.periodStart, TZString[0]),
					},
					{
						id: "To",
						header: "To",
						resolver: (row: Timesheet) => friendlyDate(row.periodEnd, TZString[0]),
					},
					{
						id: "Status",
						header: "Status",
						resolver: (row: Timesheet) => snakeToTitle(getTimesheetStatus(!!row.completedAt, !!row.readyAt, !!row.checkedAt, row.reviewStatus)),
					},
					{
						id: "ReviewedBy",
						header: "Reviewed By",
						resolver: (row: Timesheet) => (row.reviewedBy ? `${row.reviewedBy.firstName} ${row.reviewedBy.lastName}` : ""),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{selectedTimeSheetID && (
				<TimesheetReview id={selectedTimeSheetID} isOpen={!!selectedTimeSheetID} onClose={() => setSelectedTimeSheetID("")} reQuery={timeSheetsQuery.query} />
			)}
		</ZenCard>
	)
}
