import * as React from "react"
import { useStyletron } from "baseui"
import { BlockOverrides } from "baseui/block"
import { LabelLarge, LabelMedium } from "baseui/typography"
import moment from "moment-timezone"
import { useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { SessionListItem } from "../../fetching/query"
import { ZenTheme } from "../../themeOverrides"
import { SortDir } from "../../types/enums"
import { ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { SelectedItemCard } from "../zenComponents/zenSelectBox"
import { Loading } from "../loading"
import { DisplayLabel } from "./newClientAppointment/singleSessionConfirmation"
import { SessionMeetingType } from "./newClientAppointment/singleSessionGeneralForm"
import { PortalContainer } from "../../controllers/portal"

export type LocationType = "Location" | "Online" | "Phone"

interface SessionUpcomingTableProps {
	width?: string
	padding?: string
}
export const SessionUpcomingTable = (props: SessionUpcomingTableProps) => {
	const { width, padding } = props
	const { currentUser } = AuthContainer.useContainer()
	const [css, theme] = useStyletron()
	const [sessionList, setSessionList] = React.useState<SessionListItem[]>([])
	const { payload, loading, error } = useQuery(
		fetching.query.getSessionMany({
			search: {
				search: "",
				sortBy: "StartTime",
				sortDir: SortDir.Ascending,
				workerID: currentUser ? currentUser?.id : undefined,
			},
			limit: 20,
			offset: 0,
			showPastSessions: false,
			isCancelled: false,
		}),
	)

	React.useEffect(() => {
		if (loading || !payload) return
		setSessionList(payload.sessions)
	}, [payload, loading])

	const container = css({
		height: "100%",
		backgroundColor: theme.colors.white,
		width: width || "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "15px",
	})

	const title = css({
		padding: padding || "0",
		marginBottom: "10px",
	})
	const titleOverride: BlockOverrides = {
		Block: {
			style: {
				fontWeight: "bold",
				fontSize: "18px",
			},
		},
	}
	const UpcomingSessionContainer = css({
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		overflowX: "hidden",
	})

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelLarge overrides={titleOverride}>Upcoming sessions</LabelLarge>
			</div>
			{error && <ErrorNotification messageOrPayload={payload} />}
			<div className={UpcomingSessionContainer}>
				{sessionList.length > 0 &&
					sessionList.map((s) => {
						let location = ""
						if (s.location !== "") {
							location = s.location
						} else if (s.officeLocation !== "") {
							location = s.officeLocation + " office"
						}
						let locationType = "Location"
						switch (s.meetingType) {
							case SessionMeetingType.Online:
								locationType = "Location (Website Link)"
								break
							case SessionMeetingType.Telephone:
								locationType = "Location (Phone Number)"
								break
						}

						return (
							<UpcomingSessionCard
								key={s.id}
								{...s}
								clientFirstName={s.clients.length > 0 ? s.clients[0].firstName : ""}
								clientLastName={s.clients.length > 0 ? s.clients[0].lastName : ""}
								clientAvatarURL={s.clients.length > 0 ? s.clients[0].avatarURL : ""}
								workerFirstName={s.workers[0].firstName}
								workerLastName={s.workers[0].lastName}
								workerAvatarURL={s.workers[0].avatarURL}
								location={location}
								locationType={locationType}
							/>
						)
					})}
			</div>
		</ZenCard>
	)
}

interface UpcomingSessionProps {
	identifier: number
	workerFirstName: string
	workerLastName: string
	workerAvatarURL: string
	clientFirstName: string
	clientLastName: string
	clientAvatarURL: string
	supportType?: string
	locationType: string
	location: string
	startTime: string
	endTime: string
}
const UpcomingSessionCard = (props: UpcomingSessionProps) => {
	const {
		identifier,
		workerFirstName,
		workerLastName,
		workerAvatarURL,
		clientAvatarURL,
		clientFirstName,
		clientLastName,
		supportType,
		location,
		startTime,
		endTime,
		locationType,
	} = props
	const { currentUser } = AuthContainer.useContainer()
	const { timezone } = PortalContainer.useContainer()
	const history = useHistory()

	const [css] = useStyletron()
	const outer = css({
		width: "100%",
		borderRadius: "5px",
		backgroundColor: ZenTheme.colors.lightGrey,
		marginBottom: "5px",
		cursor: "pointer",
	})
	const container = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px",
		"@media screen and (max-width: 1670px)": {
			flexDirection: "column",
			alignItems: "unset",
		},
	})
	const info = css({
		display: "flex",
		flexDirection: "column",
		marginRight: "10px",
		"@media screen and (max-width: 1670px)": {
			marginRight: "0",
			marginBottom: "10px",
		},
	})
	const time = css({
		display: "flex",
		alignSelf: "stretch",
		minWidth: "200px",
		padding: "10px",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "8px",
		backgroundColor: ZenTheme.colors.white,
	})
	const timeSub = css({
		display: "flex",
		whiteSpace: "nowrap",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "5px",
	})
	if (!currentUser) return <Loading />

	const clientExists = (clientFirstName !== "" || clientLastName !== "") && (clientFirstName || clientLastName)
	return (
		<div className={outer} onClick={() => history.push(`/portal/sessions/${identifier}`)}>
			<div className={container}>
				<div className={info}>
					<div className={group}>
						<SelectedItemCard firstName={workerFirstName} lastName={workerLastName} avatarUrl={workerAvatarURL} />
						{clientExists && <>{"is seeing"}</>}
					</div>
					{clientExists && (
						<SelectedItemCard firstName={clientFirstName || ""} lastName={clientLastName || ""} avatarUrl={clientAvatarURL} marginBottom="5px" />
					)}
					{supportType && <DisplayLabel label="Support Type">{supportType}</DisplayLabel>}
					{location && <DisplayLabel label={locationType}>{location}</DisplayLabel>}
				</div>
				<div className={time}>
					<LabelMedium>{moment(startTime).tz(timezone.id).format("DD MMMM")}</LabelMedium>
					<LabelMedium className={timeSub}>
						<span>{moment(startTime).tz(timezone.id).format("hh:mm a")}</span>
						<span>&#8212;</span>
						<span>{moment(endTime).tz(timezone.id).format("hh:mm a")}</span>
					</LabelMedium>
				</div>
			</div>
		</div>
	)
}
