import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Select, Value } from "baseui/select"
import { LabelLarge, LabelMedium, ParagraphSmall } from "baseui/typography"
import { useQuery } from "react-fetching-library"
import { Control, Controller, FieldError, useForm } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"

import { fetching } from "../../../fetching"
import { ZenFormControlOverrides, ZenInputBottomOverrides, ZenSelectOverrides } from "../../../themeOverrides"
import { Gender } from "../../../types/types"
import { Spacer } from "../../common"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenDatePicker, ZenTimePicker } from "../../zenComponents/zenTime"
import { FormValuePreview } from "../common"
import { IncidentAccidentReportPartData, ReportPerson } from "./incidentAccidentReport"

interface IncidentAccidentReportStep1Props {
	control: Control<IncidentAccidentReportPartData>
	errors: DeepMap<IncidentAccidentReportPartData, FieldError>
	disabled?: boolean
	infoOnly?: boolean
}

const required = { required: { value: true, message: "Required" } }

const IncidentAccidentReportStep1 = (props: IncidentAccidentReportStep1Props) => {
	const { control, errors, disabled, infoOnly } = props

	// Used to keep date conistent when using time picker
	const [date, setDate] = React.useState<Date>()

	const genderData = useQuery<Gender[]>(fetching.query.getGenderAll())

	const [css, theme] = useStyletron()
	const titleStyle = css({
		fontWeight: "bold",
		marginBottom: "20px",
	})
	const centerStyle = css({
		// textAlign: "center",
	})
	const valuePreview = css({
		color: "#545454",
		padding: "10px 14px",
	})
	const tableStyle = css({
		width: "100%",
		borderCollapse: "collapse",
		tableLayout: "fixed",
		fontSize: "14px",
		whiteSpace: "nowrap",
		marginBottom: "12px",
	})
	const tableHeaderRowStyle = css({
		height: "28px",
		verticalAlign: "middle",
		textAlign: "left",
		borderBottom: "#E6E6E6 solid 2px",
	})
	const thStyle = css({
		color: "#747675",
		fontWeight: 600,
		paddingLeft: "10px",
	})
	const tdStyle = css({
		paddingLeft: "10px !important",
		height: "32px",
		fontWeight: 600,
	})
	const tdOverflowStyle = css({
		overflow: "hidden",
		textOverflow: "ellipsis",
	})
	const rowStyle = css({
		backgroundColor: theme.colors.backgroundSecondary,
		borderRadius: "3px",
	})
	const removeStyle = css({
		cursor: "pointer",
	})
	const group = css({
		display: "flex",
		width: "100%",
	})
	const timeField = css({
		width: "100%",
	})

	return (
		<div>
			{!infoOnly && (
				<>
					<div className={centerStyle}>
						<LabelMedium>This form is for team members to report any incident or accident.</LabelMedium>
						<ParagraphSmall>Please complete Part A and submit to relevant Executive.</ParagraphSmall>
					</div>
					<LabelLarge className={titleStyle}>Part A: To be completed by team member</LabelLarge>
				</>
			)}
			<FormControl
				label="Name of Team Member Reporting"
				overrides={ZenFormControlOverrides}
				error={errors.partA?.reporterName && errors.partA.reporterName.message}
			>
				<Controller
					name={`partA.reporterName`}
					control={control}
					as={infoOnly ? undefined : <Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} placeholder="Enter name" />}
					render={FormValuePreview(infoOnly)}
					disabled
					error={errors.partA?.reporterName !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl label="Persons Involved" overrides={ZenFormControlOverrides}>
				<Controller
					name={`partA.personsInvolved`}
					control={control}
					render={({ value, onChange }) =>
						disabled && (!value || value.length === 0) ? (
							<div className={valuePreview}>N/A</div>
						) : (
							<>
								<table className={tableStyle}>
									<tbody>
										<tr className={tableHeaderRowStyle}>
											<th className={thStyle}>Name</th>
											<th className={thStyle} style={{ width: "160px" }}>
												Team / Client / Public
											</th>
											<th className={thStyle}>Gender</th>
											<th className={thStyle} style={{ width: "50px" }}>
												Age
											</th>
											{!disabled && <th style={{ width: "25px" }} />}
										</tr>
										{(value as ReportPerson[]).map((p, i) => (
											<tr key={`person-${i}-${p.name}`} className={i % 2 !== 0 ? rowStyle : ""}>
												<td className={tdStyle}>{p.name}</td>
												<td className={tdStyle}>{p.relation}</td>
												<td className={tdStyle + " " + tdOverflowStyle} title={p.gender}>
													{p.gender}
												</td>
												<td className={tdStyle}>{p.age}</td>
												{!disabled && (
													<td className={tdStyle}>
														<div className={removeStyle} onClick={() => onChange((value as ReportPerson[]).filter((_, index) => i !== index))}>
															<FontAwesomeIcon icon={["fal", "times"]} />
														</div>
													</td>
												)}
											</tr>
										))}
									</tbody>
								</table>
								{!disabled && <AddPerson value={value} onChange={onChange} genderData={genderData} />}
							</>
						)
					}
				/>
			</FormControl>

			<FormControl
				label="Where Incident / Accident Occurred (Including Address)"
				overrides={ZenFormControlOverrides}
				error={errors.partA?.location && errors.partA.location.message}
			>
				<Controller
					name={`partA.location`}
					control={control}
					as={infoOnly ? undefined : <Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} placeholder="Enter location" />}
					render={FormValuePreview(infoOnly)}
					disabled={disabled}
					error={errors.partA?.location !== undefined}
					rules={required}
				/>
			</FormControl>

			<div className={group}>
				<div className={timeField}>
					<ZenDatePicker
						label="Date of Incident / Accident"
						nullDefaultValue
						formName="partA.date"
						formRef={control}
						inputError={errors.partA ? errors.partA.date : undefined}
						minDate={new Date("1900-01-01")}
						maxDate={new Date()}
						actionOnChange={(d) => setDate(d)}
						disabled={disabled}
						formRules={required}
					/>
				</div>
				<Spacer style={{ minWidth: "15px" }} />
				<div className={timeField}>
					<ZenTimePicker
						label="Time of Incident / Accident"
						formName="partA.time"
						date={date}
						formRef={control}
						inputError={errors.partA ? errors.partA.time : undefined}
						showTodayButton={false}
						disabled={disabled}
						formRules={required}
					/>
				</div>
			</div>
		</div>
	)
}

interface AddPersonProps {
	value: ReportPerson[]
	onChange: (...event: any[]) => void
	genderData: any
}

const AddPerson = (props: AddPersonProps) => {
	const { genderData } = props

	const { control, handleSubmit, errors, reset, trigger } = useForm<ReportPerson>({
		defaultValues: {
			name: "",
			relation: "",
			age: "",
		},
	})

	const [visible, setVisible] = React.useState(false)

	const onAdd = handleSubmit((data) => {
		if (!trigger()) return
		props.onChange([...props.value, data])
		reset()
		setVisible(false)
	})

	const genderOptions: Value = genderData.payload

	const relationOptions = [
		{ id: "Team Member", label: "Team Member" },
		{ id: "Client", label: "Client" },
		{ id: "Public", label: "Public" },
	]

	const [css, theme] = useStyletron()
	const personInvolvedStyle = css({
		border: "1px solid #D6DBF0",
		borderRadius: "3px",
		backgroundColor: theme.colors.backgroundSecondary,
		paddingLeft: "20px",
		paddingRight: "20px",
	})
	const footerStyle = css({
		display: "flex",
		marginTop: "10px",
		marginBottom: "10px",
		justifyContent: "space-between",
	})
	const flexStyle = css({
		display: "flex",
	})

	if (!visible) {
		return (
			<div className={flexStyle}>
				<ZenButton marginRight="auto" type="button" onClick={() => setVisible(true)}>
					Add person
				</ZenButton>
			</div>
		)
	}

	return (
		<div className={personInvolvedStyle}>
			<FormControl label="Name" overrides={ZenFormControlOverrides} error={errors.name && errors.name.message}>
				<Controller
					name="name"
					control={control}
					as={<Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} placeholder="Enter name" />}
					error={errors.name !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl label="Team Member / Client / Public" error={errors.relation && errors.relation.message}>
				<Controller
					name="relation"
					control={control}
					rules={required}
					render={({ onChange, value }) => {
						const v = relationOptions.find((i) => i.id === value)
						return (
							<Select
								options={relationOptions}
								onChange={({ value }) => value[0] && onChange(value[0].id)}
								value={v ? [v] : []}
								overrides={ZenSelectOverrides}
								clearable={false}
								error={errors.relation !== undefined}
							/>
						)
					}}
				/>
			</FormControl>

			<FormControl label="Gender" error={errors.gender && errors.gender.message}>
				<Controller
					name="gender"
					control={control}
					rules={required}
					render={({ onChange, value }) => {
						const v = genderOptions.find((i) => i.label === value)
						return (
							<Select
								options={genderOptions}
								onChange={({ value }) => value[0] && onChange(value[0].label)}
								value={v ? [v] : []}
								overrides={ZenSelectOverrides}
								clearable={false}
								error={errors.gender !== undefined}
							/>
						)
					}}
					isLoading={genderData.loading}
				/>
			</FormControl>

			<FormControl label="Age" overrides={ZenFormControlOverrides} error={errors.age && errors.age.message}>
				<Controller
					name="age"
					control={control}
					error={errors.age !== undefined}
					rules={required}
					as={<Input data-lpignore="true" autoComplete="off" overrides={ZenInputBottomOverrides} placeholder="Enter age" type="number" min={0} />}
				/>
			</FormControl>

			<div className={footerStyle}>
				<ZenButton type="button" altKind="secondary" onClick={() => setVisible(false)}>
					Cancel
				</ZenButton>
				<ZenButton type="button" onClick={onAdd}>
					Save
				</ZenButton>
			</div>
		</div>
	)
}

export default IncidentAccidentReportStep1
