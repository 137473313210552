import * as React from "react"
import { Button } from "baseui/button"
import { StyledLink } from "baseui/link"
import { H1, Paragraph1 } from "baseui/typography"
import { ErrorInfo } from "react"
import { useLocation } from "react-router-dom"
import { useStyletron } from "styletron-react"

interface Props {
	children?: React.ReactNode
	setTrackPathChange: (track: boolean) => void
}

interface State {
	err: Error | null
	hasError: boolean
}

const ErrorMessage = (props: { err: Error }) => {
	const [css] = useStyletron()
	const [showDetails, setShowDetails] = React.useState(false)
	const container = css({
		padding: "100px",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	})
	const link = css({
		paddingTop: "5px",
		cursor: "pointer",
	})
	const pre = css({
		padding: "20px",
		borderRadius: "5px",
		borderStyle: "ridge",
	})
	return (
		<div className={container}>
			<H1>An error occurred</H1>
			<Paragraph1>The IT team has been notified. Please try again later.</Paragraph1>
			<Button
				onClick={() => {
					setShowDetails(!showDetails)
				}}
			>
				Show Details
			</Button>
			{showDetails && (
				<pre className={pre}>
					{props.err.name}
					{props.err.message}
					{props.err.stack}
				</pre>
			)}
			<StyledLink className={link} href={"/"}>
				Return to Home
			</StyledLink>
		</div>
	)
}

class ResettableErrorBoundary extends React.Component<Props, State> {
	public state: State = {
		err: null,
		hasError: false,
	}

	public static getDerivedStateFromError(err: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, err: err }
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.props.setTrackPathChange(true)

		console.error("Uncaught error:", error, errorInfo)
	}

	public render() {
		if (this.state.hasError && this.state.err) {
			return <ErrorMessage err={this.state.err} />
		}

		return this.props.children
	}
}

function usePrevious<T>(value: T) {
	const ref = React.useRef(value)

	React.useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}

interface ErrorBoundaryProps {
	children: React.ReactNode
}

// ErrorBoundary will reset the ResettableErrorBoundary
// whenever a pathname change happens after an error occurs
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }: ErrorBoundaryProps) => {
	const [key, setKey] = React.useState(0)
	const { pathname } = useLocation()
	const previousPathname = usePrevious(pathname)
	const [trackPathChange, setTrackPathChange] = React.useState(false)

	React.useEffect(() => {
		if (trackPathChange && previousPathname !== pathname) {
			setKey((key) => key + 1)
			setTrackPathChange(false)
		}
	}, [trackPathChange, previousPathname, pathname])

	return (
		<ResettableErrorBoundary key={key} setTrackPathChange={setTrackPathChange}>
			{children}
		</ResettableErrorBoundary>
	)
}

// From https://gist.github.com/donaldpipowitch/1248ca4658506c7c8b481edfbd740ca7
class FatalErrorBoundary extends React.Component<{ children?: React.ReactNode }, { error: unknown }> {
	state = { error: undefined }

	static getDerivedStateFromError(error: unknown) {
		return { error }
	}

	render() {
		if (this.state.error) {
			return (
				<p>
					An unrecoverable error occurred.
					<button onClick={() => window.location.reload()}>Reload</button>
				</p>
			)
		} else {
			return this.props.children
		}
	}
}

export { ErrorBoundary, FatalErrorBoundary }
