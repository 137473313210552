import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FILL, Tab, TabOverrides, Tabs } from "baseui/tabs-motion"
import { StatefulTooltip } from "baseui/tooltip"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { useQuery } from "react-fetching-library"
import { CogMenuItem } from "../../../components/cogMenu"
import { ErrorNotification } from "../../../components/errorBox"
import { FieldDisplay } from "../../../components/fieldDisplay"
import { ListTable } from "../../../components/listTable"
import { Loading } from "../../../components/loading"
import { Spaced } from "../../../components/spaced"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { fetching } from "../../../fetching"
import { ZenTheme } from "../../../themeOverrides"
import { NDISFileType, NDISLineItemType, NDISPlanStatus, NDISPriceType } from "../../../types/enums"
import { Blob, NDISLineItem, NDISPlan, NDISPlanLine, RolePermission, ZenDateFormat } from "../../../types/types"
import { AddNDISLine } from "./addNDISLine"
import { FileAttachments } from "./fileAttachments"
import { MigrateSessions } from "./migrateSessions"
import { TransferBudget } from "./transferBudget"
import { ValueCharges } from "./valueCharges"
import { AuthContainer } from "../../../controllers/auth"
import { TooltipButton } from "../../../components/tooltipButton"

const getHourlyRate = (lineItem: NDISLineItem, priceType: NDISPriceType): number => {
	switch (priceType) {
		case NDISPriceType.National:
			return lineItem.priceNational ? parseFloat(lineItem.priceNational) : -1
		case NDISPriceType.Remote:
			return lineItem.priceRemote ? parseFloat(lineItem.priceRemote) : -1
		case NDISPriceType.VeryRemote:
			return lineItem.priceVeryRemote ? parseFloat(lineItem.priceVeryRemote) : -1
		default:
			return -1
	}
}

export interface LineItemRow {
	id: string
	itemID: string
	itemNumber: string
	itemName: string
	serviceStartDate: string
	serviceEndDate: string
	budget: number
	remainingBudget: number
	weekRemainingBudget: number
	unitPrice: number
	isTravel: boolean
	isTransport: boolean
	isGeneralTransport: boolean
	planLine: NDISPlanLine
	inGapManagement: boolean
}

export const GetLineItemRows = (payload: NDISPlanLine[], priceType: NDISPriceType) => {
	const lineItems: LineItemRow[] = []
	for (const item of payload) {
		lineItems.push({
			budget: parseFloat(item.support.budget),
			id: item.id,
			itemID: item.support.id,
			isTravel: false,
			isTransport: false,
			isGeneralTransport: item.support.lineItem.itemType === NDISLineItemType.GeneralTransport,
			itemName: item.support.lineItem.itemName,
			itemNumber: item.support.lineItem.itemNumber,
			remainingBudget: parseFloat(item.support.remainingBudget),
			weekRemainingBudget: parseFloat(item.support.weekRemainingBudget),
			serviceEndDate: item.serviceEndDate,
			serviceStartDate: item.serviceStartDate,
			unitPrice: getHourlyRate(item.support.lineItem, priceType),
			planLine: item,
			inGapManagement: item.inGapManagement,
		})
		if (item.travel) {
			lineItems.push({
				budget: parseFloat(item.travel.budget || "0"),
				id: item.id,
				itemID: item.travel.id,
				isTravel: true,
				isTransport: false,
				isGeneralTransport: false,
				itemName: item.travel.lineItem.itemName,
				itemNumber: item.travel.lineItem.itemNumber,
				remainingBudget: parseFloat(item.travel.remainingBudget),
				weekRemainingBudget: parseFloat(item.travel.weekRemainingBudget),
				serviceEndDate: item.serviceEndDate,
				serviceStartDate: item.serviceStartDate,
				unitPrice: getHourlyRate(item.travel.lineItem, priceType),
				planLine: item,
				inGapManagement: item.inGapManagement,
			})
		}
		if (item.transport) {
			lineItems.push({
				budget: parseFloat(item.transport.budget || "0"),
				id: item.id,
				itemID: item.transport.id,
				isTravel: false,
				isTransport: true,
				isGeneralTransport: false,
				itemName: item.transport.lineItem.itemName,
				itemNumber: item.transport.lineItem.itemNumber,
				remainingBudget: parseFloat(item.transport.remainingBudget),
				weekRemainingBudget: parseFloat(item.transport.weekRemainingBudget),
				serviceEndDate: item.serviceEndDate,
				serviceStartDate: item.serviceStartDate,
				unitPrice: getHourlyRate(item.transport.lineItem, priceType),
				planLine: item,
				inGapManagement: item.inGapManagement,
			})
		}
	}
	return lineItems
}

interface PlanDetailsProps {
	plan: NDISPlan
	ndisNumber: string
	onSuccess: (plan: NDISPlan) => void
	newLineOpen: boolean
	setNewLineOpen: React.Dispatch<React.SetStateAction<boolean>>
	activePlan?: NDISPlan
}

export const PlanDetails = (props: PlanDetailsProps) => {
	const { newLineOpen, setNewLineOpen, plan, activePlan } = props

	const [css] = useStyletron()
	const heading = css({
		display: "flex",
		justifyContent: "space-between",
		flexWrap: "wrap",
		marginBottom: "10px",
	})
	const files = css({
		marginTop: "10px",
		display: "flex",
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "10px",
	})
	const fileNameContainer = css({
		display: "flex",
		flexDirection: "column",
	})
	const iconStyle = css({
		display: "inline-block",
		marginRight: "10px",
	})
	const warningIconStyle = css({
		marginLeft: "5px",
	})
	const tabOverrides: TabOverrides = {
		Tab: {
			style: {
				fontWeight: "bold",
				fontSize: "16px",
				whiteSpace: "nowrap",
				background: "unset",
			},
		},
		TabPanel: {
			style: {
				minHeight: "100px",
				"> *": {
					height: "auto",
				},
				paddingLeft: 0,
				paddingRight: 0,
				outline: "unset",
			},
		},
	}

	const [lineItems, setLineItems] = React.useState<LineItemRow[]>([])
	const [manageFilesOpen, setManageFilesOpen] = React.useState(false)
	const [migrateOpen, setMigrateOpen] = React.useState(false)
	const [transferBudgetOpen, setTransferBudgetOpen] = React.useState(false)
	const [editLine, setEditLine] = React.useState<NDISPlanLine>()
	const [activeKey, setActiveKey] = React.useState("1")
	const [menuItems, setMenuItems] = React.useState<CogMenuItem[]>([])
	const [manageChargesOpen, setManageChargesOpen] = React.useState(false)
	const [selectedNDISPlanLine, setSelectedNDISPlanLine] = React.useState<LineItemRow>()
	const { hasPermission } = AuthContainer.useContainer()

	const { payload, loading, error, query: refetch } = useQuery(fetching.query.getClientNDISPlanLines(props.plan.id))
	const planFiles = useQuery(fetching.query.getNDISPlanFiles(props.plan.id))
	React.useEffect(() => {
		if (!payload) return
		setLineItems(GetLineItemRows(payload, plan.priceType))
	}, [payload, plan.priceType])

	React.useEffect(() => {
		const lineItemsMenuItems: CogMenuItem[] = []

		if (hasPermission(RolePermission.NDISPlanRead)) {
			for (const item of lineItems) {
				if (!item.isGeneralTransport) {
					continue
				}
				lineItemsMenuItems.push({
					label: hasPermission(RolePermission.NDISPlanUpdate) ? "Manage General Charges" : "View General Charges",
					onClick: () => {
						setActiveKey("1")
						setManageChargesOpen(true)
						setSelectedNDISPlanLine(item)
					},
				})
			}
		}

		if (hasPermission(RolePermission.NDISPlanUpdate)) {
			lineItemsMenuItems.push({
				label: "Add Line Item",
				onClick: () => {
					setActiveKey("1")
					setNewLineOpen(true)
				},
			})

			lineItemsMenuItems.push({
				label: "Manage File Attachments",
				onClick: () => {
					setActiveKey("2")
					setManageFilesOpen(true)
				},
			})
		}

		if (hasPermission(RolePermission.NDISPlanMigrate) && props.plan.status === NDISPlanStatus.Inactive && lineItems.length > 0 && !!activePlan) {
			lineItemsMenuItems.push({
				label: "Migrate Sessions and Travel",
				onClick: () => {
					setActiveKey("1")
					setMigrateOpen(true)
				},
			})
		}

		if (plan.supportBudgetTransferBalance > 0.0 && hasPermission(RolePermission.NDISPlanTransferSupportBudget)) {
			lineItemsMenuItems.push({
				label: "Transfer Support Budget",
				onClick: () => {
					setActiveKey("1")
					setTransferBudgetOpen(true)
				},
			})
		}

		setMenuItems(lineItemsMenuItems)
	}, [hasPermission, activeKey, activePlan, lineItems, plan.status, plan.supportBudgetTransferBalance, setNewLineOpen, props.plan.status])

	const onDeleteLine = () => {
		refetch()
		setEditLine(undefined)
	}

	if (loading) {
		return <Loading />
	}

	const onClickEdit = (row: LineItemRow) => {
		if (!payload || !hasPermission(RolePermission.NDISPlanUpdate)) return

		for (const item of payload) {
			if (item.id === row.id) {
				setEditLine(item)
				return
			}
		}
	}

	const isOutOfDateRange = (row: LineItemRow) => {
		const start = moment(plan.planStartDate)
		const end = moment(plan.planEndDate)
		return !moment(row.serviceStartDate).isBetween(start, end, "day", "[]") || !moment(row.serviceEndDate).isBetween(start, end, "day", "[]")
	}

	const ndisPlanFiles = planFiles.payload ? planFiles.payload.filter((p) => p.fileType === NDISFileType.NDISPlan) : []
	const serviceAgreements = planFiles.payload ? planFiles.payload.filter((p) => p.fileType === NDISFileType.ServiceAgreement) : []

	return (
		<div>
			<div className={heading}>
				<LabelLarge>Plan Details</LabelLarge>
				<Spaced>
					{menuItems.map((a, i) => (
						<ZenButton key={`plan-details-action-${i}`} onClick={a.onClick}>
							{a.label}
						</ZenButton>
					))}
				</Spaced>
			</div>
			{error && <ErrorNotification messageOrPayload={payload} />}
			{planFiles.error && <ErrorNotification messageOrPayload={planFiles.payload} />}

			<Tabs
				uid="ndis-plan-details" // required for tab styles to work from styles.scss
				activeKey={activeKey}
				onChange={({ activeKey }) => {
					setActiveKey(activeKey.toString())
				}}
				fill={FILL.fixed}
				overrides={{
					TabList: {
						style: {
							backgroundColor: "transparent",
						},
					},
					TabHighlight: {
						style: {
							backgroundColor: ZenTheme.colors.primaryGreen,
						},
					},
				}}
			>
				<Tab title="Line Items" key="1" overrides={tabOverrides}>
					<ListTable
						isLoading={loading}
						rows={lineItems}
						disabledFunc={(row: LineItemRow) => row.isTravel || row.isTransport}
						onRowClick={(row: LineItemRow) => !row.isTravel && !row.isTransport && onClickEdit(row)}
						columns={[
							{
								id: "ItemNumber",
								header: "Item Number",
								resolver: (row: LineItemRow) => {
									return (
										<div style={{ display: "flex" }}>
											{(row.isTravel || row.isTransport) && <FontAwesomeIcon className={iconStyle} icon={["fal", row.isTransport ? "bus" : "car"]} />}
											{row.itemNumber}
											{row.inGapManagement && (
												<StatefulTooltip content="Service is in gap management">
													<span>
														<FontAwesomeIcon className={warningIconStyle} icon={["fas", "exclamation-triangle"]} color={ZenTheme.colors.warning400} />
													</span>
												</StatefulTooltip>
											)}
										</div>
									)
								},
							},
							{
								id: "Name",
								header: "Name",
								resolver: (row: LineItemRow) => row.itemName,
							},
							{
								id: "ServiceDates",
								header: "Service Date",
								resolver: (row: LineItemRow) => (
									<div>
										<span>{`${moment(row.serviceStartDate).format(ZenDateFormat)} - ${moment(row.serviceEndDate).format(ZenDateFormat)}`}</span>
										{isOutOfDateRange(row) && (
											<StatefulTooltip content="Service Dates are outside NDIS plan date range.">
												<span>
													<FontAwesomeIcon icon={["fas", "exclamation-circle"]} className={warningIconStyle} />
												</span>
											</StatefulTooltip>
										)}
									</div>
								),
							},
							{
								id: "Budget",
								header: "Budget",
								resolver: (row: LineItemRow) => `$${row.budget.toFixed(2)}`,
							},
							{
								id: "TotalBalance",
								header: "Remaining Balance",
								resolver: (row: LineItemRow) => {
									let output = ""
									if (row.isTravel || row.isTransport || row.isGeneralTransport) {
										const dateThreshold = new Date('2023-09-01'); // affected date
										const currentDate = new Date()

										const balance = row.remainingBudget / ( (currentDate >= dateThreshold) ? 1.1 : row.unitPrice);

										//const balance = row.remainingBudget / row.unitPrice
										if (balance <= 0) {
											output = "0 km"
										} else {
											output = balance.toFixed(2) + " km"
										}
									} else {
										const balance = moment(row.serviceEndDate).diff(moment(), "weeks", true)
										if (balance <= 0) {
											output = "0 weeks"
										} else {
											output = balance.toFixed(2) + " weeks"
										}
									}

									return `$${row.remainingBudget.toFixed(2)} /${output}`
								},
							},
							{
								id: "WeeklyBalance",
								header: "Remaining Weekly Balance",
								resolver: (row: LineItemRow) => {
									let amount = row.weekRemainingBudget / row.unitPrice
									// prevent weekly budget from going into negative values
									if (amount < 0) {
										amount = 0
									}
									if (amount == Infinity){
										amount = 0
									}

									return `${amount.toFixed(2)} ${row.isTravel || row.isTransport || row.isGeneralTransport ? "km" : "hours"}`
								},
							},
							{
								id: "oneOffCharge",
								header: "",
								resolver: (row: LineItemRow) => {
									return (
										<>
											{hasPermission(RolePermission.NDISBalanceAdjustment) && (
												<TooltipButton
													tooltip={hasPermission(RolePermission.NDISPlanUpdate) ? "Manage Balance Adjustment" : "View Balance Adjustment"}
													iconName="file-invoice-dollar"
													onClick={(e) => {
														e.stopPropagation()
														setManageChargesOpen(true)

														setSelectedNDISPlanLine({ ...row, isGeneralTransport: false })
													}}
												/>
											)}
										</>
									)
								},
							},
						]}
					/>
				</Tab>
				<Tab title="File Attachments" key="2" overrides={tabOverrides}>
					<div className={files}>
						<FieldDisplay label="NDIS Plan(s)">
							<div className={fileNameContainer}>
								{ndisPlanFiles.length === 0 ? (
									<LabelSmall marginRight={"15px"}>{"[No File]"}</LabelSmall>
								) : (
									ndisPlanFiles.map((value) => <FileListItem key={value.id} blob={value.blob} />)
								)}
							</div>
						</FieldDisplay>
						<FieldDisplay label="Service Agreement(s)">
							<div className={fileNameContainer}>
								{serviceAgreements.length === 0 ? (
									<LabelSmall marginRight={"15px"}>{"[No Files]"}</LabelSmall>
								) : (
									serviceAgreements.map((value) => <FileListItem key={value.id} blob={value.blob} />)
								)}
							</div>
						</FieldDisplay>
					</div>
				</Tab>
			</Tabs>
			<ZenModal isOpen={newLineOpen} onClose={() => setNewLineOpen(false)} size="auto" autoFocus={false}>
				<AddNDISLine
					ndisNumber={props.ndisNumber}
					onSuccess={() => {
						refetch()
						setNewLineOpen(false)
					}}
					onCancel={() => setNewLineOpen(false)}
					onDeleteNotify={onDeleteLine}
					plan={props.plan}
					planLines={payload}
				/>
			</ZenModal>
			<ZenModal isOpen={!!editLine} onClose={() => setEditLine(undefined)} size="auto" autoFocus={false}>
				<AddNDISLine
					ndisNumber={props.ndisNumber}
					onSuccess={() => {
						refetch()
						setEditLine(undefined)
					}}
					onCancel={() => setEditLine(undefined)}
					onDeleteNotify={onDeleteLine}
					plan={props.plan}
					planLine={editLine}
					planLines={payload}
				/>
			</ZenModal>
			<ZenModal
				isOpen={manageFilesOpen}
				onClose={() => {
					setManageFilesOpen(false)
				}}
				size="auto"
				autoFocus={false}
			>
				<FileAttachments onAdd={() => planFiles.query()} onRemove={() => planFiles.query()} plan={props.plan} />
			</ZenModal>
			<ZenModal isOpen={migrateOpen} onClose={() => setMigrateOpen(false)} size="auto" autoFocus={false}>
				<MigrateSessions
					plan={props.plan}
					activePlan={props.activePlan}
					ndisNumber={props.ndisNumber}
					clientID={props.plan.clientID}
					currentPlanLines={lineItems}
					onClose={() => setMigrateOpen(false)}
				/>
			</ZenModal>
			<ZenModal isOpen={transferBudgetOpen} onClose={() => setTransferBudgetOpen(false)} size="auto" autoFocus={false}>
				<TransferBudget
					plan={props.plan}
					onClose={(newPlan?: NDISPlan) => {
						setTransferBudgetOpen(false)
						if (newPlan) {
							refetch()
							props.onSuccess(newPlan)
						}
					}}
				/>
			</ZenModal>
			{manageChargesOpen && selectedNDISPlanLine && (
				<ZenModal
					isOpen={manageChargesOpen}
					onClose={() => {
						setManageChargesOpen(false)
						setSelectedNDISPlanLine(undefined)
					}}
					size="auto"
					autoFocus={false}
				>
					<ValueCharges
						onSuccess={() => {
							refetch()
						}}
						planLine={selectedNDISPlanLine}
					/>
				</ZenModal>
			)}
		</div>
	)
}

const FileListItem = (props: { blob: Blob }) => {
	const [css] = useStyletron()
	const fileNameStyle = css({
		margin: "3px 9px 3px 0px",
		cursor: "pointer",
		textDecoration: "underline",
		display: "flex",
		justifyContent: "flex-start",
		backgroundColor: "#FFFFFF",
		borderLeft: "3px solid #88BB3C",
	})
	const innerStyle = css({
		margin: "5px",
	})
	const handleClick = () => {
		window.open(`/api/files/${props.blob.id}`, "_blank")
	}

	return (
		<div className={fileNameStyle} onClick={handleClick}>
			<FontAwesomeIcon className={innerStyle} fontWeight="bold" color="#A4AFB7" icon={["fal", "download"]} size="1x" />
			<LabelSmall className={innerStyle} marginRight={"15px"}>
				{props.blob.fileName}
			</LabelSmall>
		</div>
	)
}
