import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelLarge, LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography"
import moment from "moment-timezone"
import { Link } from "react-router-dom"

import { ZenTheme } from "../../../themeOverrides"
import { AddedWorker, Client, UserDetail } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { Divider } from "../../common"
import { DisplayLabel } from "../newClientAppointment/singleSessionConfirmation"
import { DisplayOrgCard } from "../organisationDisplay"
import { GroupAppointmentFormProps } from "./groupSessionBaseForm"

export const GroupSessionConfirmation = (props: GroupAppointmentFormProps) => {
	const { data, changePage, loading } = props
	const [imageLoading, setImageLoading] = React.useState<boolean>(true)
	const checkLoading = () => {
		setImageLoading(false)
	}

	const [css] = useStyletron()
	const outer = css({
		height: "100%",
		overflowX: "hidden",
	})
	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		padding: "15px",
		backgroundColor: ZenTheme.colors.lightGrey,
	})
	const groupContainer = css({
		display: "flex",
	})
	const verticalGroup = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
	})
	const imgLoading = css({
		width: "100%",
		height: "150px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	})
	const scrollDiv = css({
		overflowY: "auto",
		overflowX: "hidden",
		padding: "15px",
	})

	return (
		<div className={outer}>
			<div className={container}>
				<LabelLarge paddingBottom={"15px"}>Group Appointment Confirmation</LabelLarge>
				<div className={scrollDiv}>
					<div className={groupContainer}>
						<div className={verticalGroup}>
							<DisplayLabel label="Group Client" icon="users">
								{data.organisation && data.organisation.length > 0 && data.organisation[0].label && (
									<DisplayOrgCard name={data.organisation[0].label.toString()} />
								)}
							</DisplayLabel>
							{data.clientType && data.clientType.length > 0 && (
								<DisplayLabel label="Client Type" icon="user">
									<LabelMedium>{data.clientType[0].label}</LabelMedium>
								</DisplayLabel>
							)}
							<DisplayLabel label="Date of First Contact" icon="calendar-alt">
								{data.organisation && data.organisation.length > 0 && (
									<LabelMedium>{moment(data.organisation[0].dateOfFirstContact).format("DD/MM/YYYY")}</LabelMedium>
								)}
							</DisplayLabel>
							<DisplayLabel label={` Additional Attendees (${data.attendees?.length || 0})`} icon="user">
								<DisplayAttendeeList attendees={data.attendees || []} />
							</DisplayLabel>
						</div>
						<DisplayLabel label="Worker" icon="user">
							<DisplayWorkerList
								primaryWorker={data.worker && data.worker.length > 0 ? ({ ...data.worker[0] } as UserDetail) : undefined}
								additionalWorkers={data.additionalWorkers}
							/>
						</DisplayLabel>
					</div>

					<div className={groupContainer}>
						<DisplayLabel label="Date" icon="calendar-alt">
							{data.appointmentDate && <LabelMedium>{moment(data.appointmentDate).format("DD MMMM YYYY")}</LabelMedium>}
						</DisplayLabel>
						<DisplayLabel label={`Time ${data.timezone && data.timezone.length > 0 ? `(${data.timezone[0].label})` : ""}`} icon="clock">
							{data.startTime && data.endTime && (
								<LabelMedium>{`${moment(data.startTime).format("hh:mm a")} - ${moment(data.endTime).format("hh:mm a")}`}</LabelMedium>
							)}
						</DisplayLabel>
					</div>

					<Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />

					<DisplayLabel label="Location" icon="map-marker-alt">
						{data.sessionAddress && <LabelMedium>{data.sessionAddress[0].label}</LabelMedium>}
					</DisplayLabel>
					{data.sessionAddress && imageLoading && (
						<div className={imgLoading}>
							<LabelSmall>Loading image...</LabelSmall>
							<StyledSpinnerNext />
						</div>
					)}
					{data.sessionAddress && data.sessionAddress.length > 0 && (
						<img alt="map" width="100%" src={`/api/maps/static_map?address=${data.sessionAddress[0].label}`} onLoad={checkLoading} />
					)}

					{data.sessionFundingSources?.map((sfs, i) => (
						<div key={i}>
							<Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />
							<DisplayLabel label="Funding source">
								<LabelMedium>{sfs.fundingSource[0].label || "N/A"}</LabelMedium>
							</DisplayLabel>
							<DisplayLabel label="Contract Area">
								<LabelMedium>{sfs.contractArea && sfs.contractArea.length > 0 ? sfs.contractArea[0].label : "N/A"}</LabelMedium>
							</DisplayLabel>
							<DisplayLabel label="Support Type">
								<LabelMedium>{sfs.supportType && sfs.supportType.length > 0 ? sfs.supportType[0].label : "N/A"}</LabelMedium>
							</DisplayLabel>
							<DisplayLabel label="Sub Support Type">
								<LabelMedium>{sfs.subSupportType && sfs.subSupportType.length > 0 ? sfs.subSupportType[0].label : "N/A"}</LabelMedium>
							</DisplayLabel>
						</div>
					))}

					<Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />
					<DisplayLabel label="Year Group">
						<LabelMedium>{data.yearGroup || "N/A"}</LabelMedium>
					</DisplayLabel>
					<DisplayLabel label="Program Delivered">
						<LabelMedium>{data.programDelivered && data.programDelivered.length > 0 ? data.programDelivered[0].label : "N/A"}</LabelMedium>
					</DisplayLabel>

					<Divider style={{ backgroundColor: "#A1ADDD", opacity: 0.4 }} />

					<DisplayLabel label="Pre-Session Note">
						<LabelMedium>{data.notes || "N/A"}</LabelMedium>
					</DisplayLabel>
				</div>

				<CancelAndSaveButtons
					buttonWidths={"wrap"}
					cancelLabel="Back"
					cancelFn={() => changePage(-1)}
					saveLabel="Create Appointment"
					saveFn={() => changePage(1)}
					isLoading={loading}
				/>
			</div>
		</div>
	)
}

interface DisplayWorkerListProps {
	primaryWorker?: UserDetail
	additionalWorkers?: AddedWorker[]
}
export const DisplayWorkerList = (props: DisplayWorkerListProps) => {
	const { primaryWorker, additionalWorkers } = props
	const [css] = useStyletron()
	const container = css({
		overflowX: "hidden",
	})
	return (
		<div className={container}>
			{primaryWorker && (
				<Link to={`/portal/workers/${primaryWorker.id}`}>
					<WorkerCard worker={primaryWorker} claimHour={true} isPrimary={true} isBuddyMentor={false} />
				</Link>
			)}
			{additionalWorkers &&
				additionalWorkers.length > 0 &&
				additionalWorkers.map((aw, i) => (
					<Link to={`/portal/workers/${aw.worker.id}`} key={`additional-worker-${aw.worker.id}-${i}`}>
						<WorkerCard key={aw.worker.id + i} worker={aw.worker} claimHour={aw.claimHour} isPrimary={false} isBuddyMentor={false} />
					</Link>
				))}
		</div>
	)
}

interface WorkerCardProps extends AddedWorker {
	isPrimary: boolean
}
const WorkerCard = (props: WorkerCardProps) => {
	const { isPrimary, worker, claimHour } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "5px",
	})
	const detail = css({
		display: "flex",
		flexDirection: "column",
	})
	const tagContainer = css({
		display: "flex",
		alignItems: "center",
		marginLeft: "20px",
	})
	return (
		<div className={container}>
			<Avatar
				name={`${worker.firstName} ${worker.lastName}`}
				size="scale1000"
				overrides={{
					Root: {
						style: {
							minWidth: ZenTheme.sizing.scale1000,
						},
					},
				}}
				src={worker.avatarURL || ""}
			/>
			<div className={detail}>
				<div className={tagContainer}>
					<CardTag label={isPrimary ? "Primary" : "Secondary"} />
					<CardTag marginLeft="5px" label="CH" backgroundColor={claimHour ? ZenTheme.colors.primaryGreen : ZenTheme.colors.primaryGrey} />
				</div>
				<LabelMedium marginLeft="20px" marginTop="5px">{`${worker.firstName} ${worker.lastName}`}</LabelMedium>
			</div>
		</div>
	)
}

interface DisplayAttendeeListProps {
	attendees: Client[]
}
export const DisplayAttendeeList = (props: DisplayAttendeeListProps) => {
	const { attendees } = props
	const [css] = useStyletron()
	const container = css({
		overflowX: "hidden",
	})
	return (
		<div className={container}>
			{attendees &&
				attendees.length > 0 &&
				attendees.map((a, i) => (
					<Link to={`/portal/clients/${a.id}`} key={`attendee-${a.id}-${i}`}>
						<AttendeeCard key={a.id + i} {...a} />
					</Link>
				))}
		</div>
	)
}

const AttendeeCard = (props: Client) => {
	const { firstName, lastName, avatarURL } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "5px",
	})
	const detail = css({
		display: "flex",
		flexDirection: "column",
	})

	return (
		<div className={container}>
			<Avatar
				name={`${firstName} ${lastName}`}
				size="scale1000"
				overrides={{
					Root: {
						style: {
							minWidth: ZenTheme.sizing.scale1000,
						},
					},
				}}
				src={avatarURL || ""}
			/>
			<div className={detail}>
				<LabelMedium marginLeft="20px" marginTop="5px">{`${firstName} ${lastName}`}</LabelMedium>
			</div>
		</div>
	)
}

const CardTag = (props: { label: string; backgroundColor?: string; marginLeft?: string }) => {
	const { label, backgroundColor, marginLeft } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		paddingTop: "2px",
		paddingBottom: "2px",
		paddingLeft: "8px",
		paddingRight: "8px",
		borderRadius: "25px",
		marginLeft: marginLeft || 0,
		backgroundColor: backgroundColor || ZenTheme.colors.primaryGreen,
	})
	return (
		<div className={container}>
			<LabelXSmall
				color="white"
				overrides={{
					Block: {
						style: {
							fontSize: "10px",
							lineHeight: "inherit",
							fontWeight: "bold",
						},
					},
				}}
			>
				{label}
			</LabelXSmall>
		</div>
	)
}
