import * as React from "react"
import { IconName } from "@fortawesome/pro-light-svg-icons"
import { ButtonGroup } from "baseui/button-group"
import { TooltipButton } from "./tooltipButton"

interface RowActionProps {
	menuItems: MenuItem[]
	disabled?: boolean
}

export interface MenuItem {
	label: string // button tooltip
	icon: IconName
	onClick: () => void
}

export const RowActions = (props: RowActionProps) => {
	return (
		<ButtonGroup>
			{props.menuItems.map((item, key) => (
				<TooltipButton
					key={key}
					onClick={(e) => {
						e.stopPropagation()
						item.onClick()
					}}
					tooltip={item.label}
					iconName={item.icon}
				/>
			))}
		</ButtonGroup>
	)
}
