import * as React from "react"
import { useStyletron } from "baseui"
import { Textarea } from "baseui/textarea"
import { LabelLarge, ParagraphSmall } from "baseui/typography"
import { Controller, useFormContext } from "react-hook-form"

import { WellbeingFormData } from "./wellbeingPlan"

interface WellbeingActivitiesPageProps {
	disabled?: boolean
}

export const WellbeingActivitiesPage = (props: WellbeingActivitiesPageProps) => {
	const { disabled } = props
	const { control } = useFormContext<WellbeingFormData>()

	const [css, theme] = useStyletron()
	const container = css({})
	const titleStyle = css({
		display: "flex",
		alignItems: "center",
	})
	const optionalTextStyle = css({
		fontSize: "18px",
		fontWeight: 600,
		color: theme.colors.contentTertiary,
		marginLeft: "10px",
	})
	const tableStyle = css({
		width: "100%",
		minWidth: "700px",
		borderCollapse: "collapse",
		tableLayout: "fixed",
		fontSize: "16px",
	})
	const cellStyle = css({
		border: "1px solid #1A1A1A",
		color: "#BEBEBE",
		padding: "0px !important",
		verticalAlign: "top",
		height: "200px",
	})
	const dayNameShort = css({
		display: "none",
		"@media only screen and (max-width: 1900px)": {
			display: "block",
		},
	})
	const dayNameFull = css({
		display: "block",
		"@media only screen and (max-width: 1900px)": {
			display: "none",
		},
	})
	const timeName = css({
		wordWrap: "break-word",
	})

	const ary = Array.from(Array(7))

	const cellTextArea = (index: number) => (
		<Controller
			name={`weeklyActivities[${index}].goal`}
			control={control}
			as={Textarea}
			placeholder="Activity"
			disabled={disabled}
			overrides={{
				InputContainer: {
					style: {
						borderTopStyle: "unset",
						borderBottomStyle: "unset",
						borderLeftStyle: "unset",
						borderRightStyle: "unset",
						height: "100%",
					},
				},
			}}
		/>
	)

	return (
		<div className={container}>
			<div className={titleStyle}>
				<LabelLarge $style={{ fontWeight: "bold" }}>Their Weekly Activities</LabelLarge>
				<div className={optionalTextStyle}>(Optional)</div>
			</div>
			<ParagraphSmall>
				This section may help them plan out their weekly schedule and organise their appointments and activities.
				<br />
				Try and include some self-care activities (from the worksheet on the previous page), as well as necessary tasks.
			</ParagraphSmall>

			<table className={tableStyle}>
				<tbody>
					<tr>
						<th></th>
						<th>
							<span className={dayNameShort}>Mon</span>
							<span className={dayNameFull}>Monday</span>
						</th>
						<th>
							<span className={dayNameShort}>Tue</span>
							<span className={dayNameFull}>Tuesday</span>
						</th>
						<th>
							<span className={dayNameShort}>Wed</span>
							<span className={dayNameFull}>Wednesday</span>
						</th>
						<th>
							<span className={dayNameShort}>Thu</span>
							<span className={dayNameFull}>Thursday</span>
						</th>
						<th>
							<span className={dayNameShort}>Fri</span>
							<span className={dayNameFull}>Friday</span>
						</th>
						<th>
							<span className={dayNameShort}>Sat</span>
							<span className={dayNameFull}>Saturday</span>
						</th>
						<th>
							<span className={dayNameShort}>Sun</span>
							<span className={dayNameFull}>Sunday</span>
						</th>
					</tr>
					<tr>
						<th className={timeName}>Morning</th>
						{ary.map((_, i) => (
							<td key={`row1-${i}`} className={cellStyle}>
								{cellTextArea(i)}
							</td>
						))}
					</tr>
					<tr>
						<th className={timeName}>Afternoon</th>
						{ary.map((_, i) => (
							<td key={`row2-${i}`} className={cellStyle}>
								{cellTextArea(7 + i)}
							</td>
						))}
					</tr>
					<tr>
						<th className={timeName}>Evening</th>
						{ary.map((_, i) => (
							<td key={`row3-${i}`} className={cellStyle}>
								{cellTextArea(14 + i)}
							</td>
						))}
					</tr>
				</tbody>
			</table>
		</div>
	)
}
