import * as React from "react"
import { useStyletron } from "baseui"
import { ZenCard } from "../../components/common"
import { TravelTable } from "../../components/travels/travelTable"

export const TravelList = () => {
	const [css] = useStyletron()
	const listContainer = css({
		maxHeight: "100%",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		padding: "20px",
	})

	return (
		<ZenCard key="travelTable" className={listContainer}>
			<TravelTable hasPagination />
		</ZenCard>
	)
}
