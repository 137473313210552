import * as React from "react"
import { useStyletron } from "baseui"
import { StyledSpinnerNext } from "baseui/spinner"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { FormProvider, useForm } from "react-hook-form"
import { Prompt, Route, RouteComponentProps, Switch, useHistory } from "react-router-dom"
import { routes } from "routes"
import { AuthContainer } from "../../../../controllers/auth"
import { PortalContainer } from "../../../../controllers/portal"
import { fetching } from "../../../../fetching"
import { IntakeType, StarChartLabel,YouthStarChartLabel} from "../../../../types/enums"
import {
	CarersStarActionPlanInput,
	CarersStarFormInput,
	YouthStarActionPlanInput,
	YouthStarFormInput,
	ClientEmergencyContactInput,
	MentalHealthTreatingTeam,
	TempIntakeAdultFormData,
} from "../../../../types/inputTypes"
import { Form, LabelLink } from "../../../../types/types"
import { ZenCard } from "../../../common"
import { ErrorNotification } from "../../../errorBox"
import { useZenToast } from "../../../zenComponents/useZenToast"
import { ZenVerticalProgress } from "../../../zenComponents/zenProgressBar"
import { ActionPlan } from "../commonForms/actionPlan"
import { ChallengesForClient } from "../commonForms/challengesForClient"
import { Detail } from "../commonForms/detail"
import { PresenceOfRiskConcerns } from "../commonForms/presenceOfRiskConcerns"
import { Supports } from "../commonForms/supports"
import { validateActionPlan, validateChallengeForClient, validateClientContext, validatePresenceOfRiskConcerns, validateReferrerDetail } from "../validation"
import { IntakeAdultCareContext } from "./intakeAdultCareContext"
import { IntakeAdultCarerStar } from "./intakeAdultCarerStar"
import { IntakeAdultEmergencyContactDetails } from "./intakeAdultEmergencyContact"
import { IntakeNDISMentalHealthTeam } from "./ndis/intakeNDISMentalHealthTeam"
import { IntakeNDISServiceDetail } from "./ndis/intakeNDISServiceDetail"
import { IntakeNDISSupport } from "./ndis/intakeNDISSupport"

// labels for adult intake progression bar
const adultLabelLinks: LabelLink[] = [
	{ label: "Referrer Details", link: "" },
	{ label: "Eligibility Screen / Presence of Risk Concerns", link: "presenceOfRiskConcerns" },
	{ label: "Emergency Contact Details", link: "emergencyContactDetails" },
	{ label: "Challenges for Client", link: "challengesForClient" },
	{ label: "Client Context", link: "clientContext" },
	{ label: "Carer Star", link: "carerStar" },
	{ label: "Youth Star", link: "youthStar" },
	{ label: "Supports", link: "supports" },
	{ label: "Action Plan", link: "actionPlan" },
]

// labels for ndis intake progression bar
const ndisLabelLinks: LabelLink[] = [
	{ label: "Referrer Details", link: "" },
	{ label: "Emergency Contact Details", link: "emergencyContactDetails" },
	{ label: "Challenges for Client", link: "challengesForClient" },
	{ label: "Service detail", link: "serviceDetail" },
	{ label: "Mental Health Treating Team", link: "mentalHealthTreatingTeam" },
	{ label: "NDIS Supports", link: "ndisSupports" },
]

export const intakeURLParser = (url: string, formID: string | null, withNDIS?: boolean): string => {
	if (!withNDIS && !formID) return url
	let result = `${url}?`
	if (formID) result += `formID=${formID}`
	if (formID && withNDIS) result += "&&"
	if (withNDIS) result += "ndis=true"

	return result
}

export interface IntakeAdultProps {
	clientID: string
	formID: string | null
	withNDIS: boolean
	isReadOnly: boolean
	validationTriggered: boolean
}
export const BaseForm = (props: RouteComponentProps<{ id: string }>) => {
	const clientID = props.match.params.id
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const history = useHistory()
	const searchArgs = new URLSearchParams(history.location.search)
	const formMethods = useForm()
	const { currentUser } = AuthContainer.useContainer()
	const { fetchClient, client, clientLoading } = PortalContainer.useContainer()

	const fetchClientCallback = React.useCallback(() => {
		if (clientID) fetchClient(clientID)
	}, [clientID]) // eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		fetchClientCallback()
	}, [fetchClientCallback])

	const loadCarerStarClient = React.useCallback(() => {
		if (!client) return
		formMethods.setValue("carerStarClient", [{ ...client, label: `${client.firstName} ${client.lastName}`, avatar: client.avatarURL || "" }])
	}, [client, formMethods])
	React.useEffect(() => {
		loadCarerStarClient()
	}, [loadCarerStarClient])

	// form dirty state
	const [formDirty, setFormDirty] = React.useState(false)

	React.useEffect(() => {
		if (formMethods.formState.isDirty && !formDirty) setFormDirty(true)
	}, [formMethods.formState.isDirty, formDirty])

	// track whether the intake is ndis intake
	const withNDIS = !!searchArgs.get("ndis")
	const formID = searchArgs.get("formID")

	const [firstLoad, setFirstLoad] = React.useState<boolean>(true)
	const [step, setStep] = React.useState<number>(0)
	const [formIsCompleted, setFormIsCompleted] = React.useState<boolean>(false)
	const clientIntakeCreate = useMutation<Client>(fetching.mutation.clientIntakeCreate)

	const { query, payload: formData, loading: formLoading } = useParameterizedQuery<Form<TempIntakeAdultFormData>>(fetching.query.getForm)
	const refetchForm = React.useCallback(() => {
		if (!formID) return
		query(formID)
	}, [formID, query])
	React.useEffect(() => {
		refetchForm()
	}, [refetchForm])

	// Prepare form data for emergency contact
	const [emergencyContactPlaceholder, setEmergencyContactPlaceholder] = React.useState<number[]>([0]) // use for tracking the index of each emergency form
	const [additionEmergencyContactCount, setAdditionEmergencyContactCount] = React.useState<number>(0) // track number of additional emergency contact
	const [emergencyContactInputErrors, setEmergencyContactInputErrors] = React.useState<boolean[]>([false]) // track whether there is at least one contact form

	// Prepare mental health treating team
	const [otherMentalTreatingTeams, setOtherMentalTreatingTeams] = React.useState<string[]>([])

	// Prepare action plan data
	const [starSpike, setStarSpike] = React.useState(Object.values(StarChartLabel).map((sct) => ({ label: sct, value: 0 })))
	const [youthStarSpike, setYouthStarSpike] = React.useState(Object.values(YouthStarChartLabel).map((sct) => ({ label: sct, value: 0 })))
	const [actionPlanPlaceholder, setActionPlanPlaceholder] = React.useState<number[]>([0])
	const [actionPlanNextIndex, setActionPlanNextIndex] = React.useState<number>(1)

	//pre-fill form data
	const preFillData = React.useCallback(() => {
		if (formLoading || !formData) return
		const currentData: TempIntakeAdultFormData = formData.content

		// parse form data
		setFormIsCompleted(!formData.requiresAction)

		// set fields - referrer data
		formMethods.setValue("referrerID", currentData.referrerID)
		formMethods.setValue("subReferrerID", currentData.subReferrerID)
		formMethods.setValue("referrerName", currentData.referrerName)
		formMethods.setValue("referrerAgencyRelationship", currentData.referrerAgencyRelationship)
		formMethods.setValue("referrerContactNumber", currentData.referrerContactNumber)
		formMethods.setValue("referrerEmail", currentData.referrerEmail)
		formMethods.setValue("confidentiallyDiscussed", !!currentData.confidentiallyDiscussed)
		formMethods.setValue("SummaryOfWhatToAcceptDuringTheIntakeProcessProvided", !!currentData.SummaryOfWhatToAcceptDuringTheIntakeProcessProvided)
		formMethods.setValue("VerbalConsentProvidedByClient", !!currentData.VerbalConsentProvidedByClient)
		// set default worker
		if (currentData.workerID) {
			formMethods.setValue("workerID", currentData.workerID)
		}

		// set fields - Presence of risk concerns
		formMethods.setValue("suicidalIdeationSelfHarm", currentData.suicidalIdeationSelfHarm)
		formMethods.setValue("financialHardship", currentData.financialHardship)
		formMethods.setValue("unstableHousing", currentData.unstableHousing)
		formMethods.setValue("violenceAbuse", currentData.violenceAbuse)
		formMethods.setValue("aodIssue", currentData.aodIssue)
		formMethods.setValue("otherResiding", currentData.otherResiding)
		formMethods.setValue("withinLastThreeMonth", currentData.withinLastThreeMonth)
		formMethods.setValue("hasSuicidalIdeationSelfHarm", currentData.hasSuicidalIdeationSelfHarm)
		formMethods.setValue("hasFinancialHardship", currentData.hasFinancialHardship)
		formMethods.setValue("hasUnstableHousing", currentData.hasUnstableHousing)
		formMethods.setValue("hasViolenceAbuse", currentData.hasViolenceAbuse)
		formMethods.setValue("hasAODIssue", currentData.hasAODIssue)
		formMethods.setValue("referHelpingMindsCaseReview", currentData.referHelpingMindsCaseReview)
		formMethods.setValue("referralRequired", currentData.referralRequired)
		formMethods.setValue("referredToLifeLine", currentData.referredToLifeLine)

		// set fields - emergency contacts
		if (currentData.emergencyContacts) {
			const contacts: ClientEmergencyContactInput[] = currentData.emergencyContacts

			setEmergencyContactPlaceholder(contacts.length > 0 ? contacts.map<number>((_, i: number) => i) : [0])
			setAdditionEmergencyContactCount(contacts.length > 1 ? contacts.length - 1 : 0)
			setEmergencyContactInputErrors(contacts.length > 0 ? contacts.map(() => false) : [false])

			contacts.forEach((e: ClientEmergencyContactInput, i: number) => {
				formMethods.setValue(`emergencyContactFirstName${i}`, e.firstName)
				formMethods.setValue(`emergencyContactLastName${i}`, e.lastName)
				formMethods.setValue(`emergencyContactMobileNumber${i}`, e.mobileNumber)
				formMethods.setValue(`emergencyContactTelephoneNumber${i}`, e.telephoneNumber)
				formMethods.setValue(
					`emergencyContactRelationshipToClient${i}`,
					e.relationshipToClient ? [{ id: e.relationshipToClient, label: e.relationshipToClient }] : [],
				)
				formMethods.setValue(
					`emergencyContactRelationshipToConsumer${i}`,
					e.relationshipToConsumer ? [{ id: e.relationshipToConsumer, label: e.relationshipToConsumer }] : [],
				)
			})
		}

		// set fields - challenge for client
		formMethods.setValue("learningCognitiveDifficulties", currentData.learningCognitiveDifficulties)
		formMethods.setValue("mentalHealthConcerns", currentData.mentalHealthConcerns)
		formMethods.setValue("physicalDifficulties", currentData.physicalDifficulties)
		formMethods.setValue("accessibilityRequirements", currentData.accessibilityRequirements)
		formMethods.setValue("riskOfFalls", currentData.riskOfFalls)
		formMethods.setValue("allergies", currentData.allergies)
		formMethods.setValue("medicalHistory", currentData.medicalHistory)
		formMethods.setValue("palliativeSupportCare", currentData.palliativeSupportCare)
		formMethods.setValue("hasLearningCognitiveDifficulties", currentData.hasLearningCognitiveDifficulties)
		formMethods.setValue("hasMentalHealthConcerns", currentData.hasMentalHealthConcerns)
		formMethods.setValue("hasPhysicalDifficulties", currentData.hasPhysicalDifficulties)
		formMethods.setValue("hasAccessibilityRequirements", currentData.hasAccessibilityRequirements)
		formMethods.setValue("hasRiskOfFalls", currentData.hasRiskOfFalls)
		formMethods.setValue("hasAllergies", currentData.hasAllergies)
		formMethods.setValue("hasMedicalHistory", currentData.hasMedicalHistory)
		formMethods.setValue("hasPalliativeSupportCare", currentData.hasPalliativeSupportCare)

		// set fields - client context
		formMethods.setValue("name", currentData.name)
		formMethods.setValue("relationship", currentData.relationship)
		formMethods.setValue("mentalHealthDiagnosisConcern", currentData.mentalHealthDiagnosisConcern)
		formMethods.setValue("receivingFormalSupports", currentData.receivingFormalSupports)
		formMethods.setValue("formalSupportDetail", currentData.formalSupportDetail)
		formMethods.setValue("currentCarerConcerns", currentData.currentCarerConcerns)

		// set fields - supports
		formMethods.setValue("informalSupport", currentData.informalSupport)
		formMethods.setValue("formalSupport", currentData.formalSupport)
		formMethods.setValue("youngPersonGoodAt", currentData.youngPersonGoodAt)
		formMethods.setValue("workAndOrEducation", currentData.workAndOrEducation)
		formMethods.setValue("protectiveFactors", currentData.protectiveFactors)
		formMethods.setValue("identifiedSupport", currentData.identifiedSupport)
		formMethods.setValue("appointmentDetail", currentData.appointmentDetail)
		formMethods.setValue("reasonForNotAppropriate", currentData.reasonForNotAppropriate)
		formMethods.setValue("additionalSupportComment", currentData.additionalSupportComment)
		formMethods.setValue("Counselling", currentData.Counselling)
		formMethods.setValue("PeerSupport", currentData.PeerSupport)
		formMethods.setValue("Advocacy", currentData.Advocacy)
		formMethods.setValue("SupportGroups", currentData.SupportGroups)

		// allocated support
		if (!withNDIS) {
			formMethods.setValue("Counselling", currentData.Counselling)
			formMethods.setValue("PeerSupport", currentData.PeerSupport)
			formMethods.setValue("Advocacy", currentData.Advocacy)
			formMethods.setValue("SupportGroups", currentData.SupportGroups)
		}

		// set fields - action plan
		formMethods.setValue("referralAccepted", currentData.referralAccepted)
		formMethods.setValue("bookSession", currentData.bookSession)
		formMethods.setValue("clientPackIssued", currentData.clientPackIssued)
		formMethods.setValue("referralAcceptedNote", currentData.referralAcceptedNote)
		formMethods.setValue("referralPending", currentData.referralPending)
		formMethods.setValue("pendingTeamMemberAvailability", currentData.pendingTeamMemberAvailability)
		formMethods.setValue("helpingMindsCaseReviewMeeting", currentData.helpingMindsCaseReviewMeeting)
		formMethods.setValue("intakeIncomplete", currentData.intakeIncomplete)
		formMethods.setValue("referralPendingNote", currentData.referralPendingNote)
		formMethods.setValue("referralIneligible", currentData.referralIneligible)
		formMethods.setValue("warmReferralRequired", currentData.warmReferralRequired)
		formMethods.setValue("referralIneligibleNote", currentData.referralIneligibleNote)
		formMethods.setValue("referredToLifeLinePhone", currentData.referredToLifeLinePhone)
		formMethods.setValue("preferredLocationOption", currentData.preferredLocationOption)
		formMethods.setValue("office", currentData.office)
		formMethods.setValue("preferredLocation", currentData.preferredLocation)
		formMethods.setValue("sentCounsellingForm", currentData.sentCounsellingForm)
		formMethods.setValue("additionalActionPlanComment", currentData.additionalActionPlanComment)

		// NDIS fields
		formMethods.setValue("serviceType", currentData.serviceType)
		formMethods.setValue("ndisPlanIsProvided", currentData.ndisPlanIsProvided)
		formMethods.setValue("ndisPlanNumber", currentData.ndisPlanNumber)
		formMethods.setValue("ndisPlanReviewDate", currentData.ndisPlanReviewDate ? moment(currentData.ndisPlanReviewDate).toDate() : null)

		formMethods.setValue("supportNeedsSummary", currentData.supportNeedsSummary)
		formMethods.setValue("currentHousingSituationProblem", currentData.currentHousingSituationProblem)
		formMethods.setValue("specificSupportRequests", currentData.specificSupportRequests)
		formMethods.setValue("additionalComment", currentData.additionalComment)

		if (currentData.mentalHealthTreatingTeams && currentData.mentalHealthTreatingTeams.length > 0)
			currentData.mentalHealthTreatingTeams.forEach((m) => {
				switch (m.name) {
					case "GP":
						if (!m.contactName) break // skip if value is empty
						formMethods.setValue("hasGP", true)
						formMethods.setValue(`${m.name}Organisation`, m.organisation)
						formMethods.setValue(`${m.name}ContactName`, m.contactName)
						formMethods.setValue(`${m.name}ContactNumber`, m.contactNumber)
						formMethods.setValue(`${m.name}FrequencyOfSupport`, m.frequencyOfSupport)
						break
					case "Psychiatrist":
						if (!m.contactName) break // skip if value is empty
						formMethods.setValue("hasPsychiatrist", true)
						formMethods.setValue(`${m.name}Organisation`, m.organisation)
						formMethods.setValue(`${m.name}ContactName`, m.contactName)
						formMethods.setValue(`${m.name}ContactNumber`, m.contactNumber)
						formMethods.setValue(`${m.name}FrequencyOfSupport`, m.frequencyOfSupport)
						break
					case "Case Manager":
						if (!m.contactName) break // skip if value is empty
						formMethods.setValue("hasCaseManager", true)
						formMethods.setValue(`${m.name}Organisation`, m.organisation)
						formMethods.setValue(`${m.name}ContactName`, m.contactName)
						formMethods.setValue(`${m.name}ContactNumber`, m.contactNumber)
						formMethods.setValue(`${m.name}FrequencyOfSupport`, m.frequencyOfSupport)
						break
					case "Psychologist":
						if (!m.contactName) break // skip if value is empty
						formMethods.setValue("hasPsychologist", true)
						formMethods.setValue(`${m.name}Practice`, m.practice)
						formMethods.setValue(`${m.name}ContactName`, m.contactName)
						formMethods.setValue(`${m.name}ContactNumber`, m.contactNumber)
						formMethods.setValue(`${m.name}FrequencyOfSupport`, m.frequencyOfSupport)
						break
					default:
						if (!m.contactName) break // skip if value is empty
						setOtherMentalTreatingTeams(otherMentalTreatingTeams.concat(m.name))
						formMethods.setValue(`${m.name}TypeOfSupportOther`, m.typeOfSupport)
						formMethods.setValue(`${m.name}ContactNameOther`, m.contactName)
						formMethods.setValue(`${m.name}ContactNumberOther`, m.contactNumber)
						formMethods.setValue(`${m.name}FrequencyOfSupportOther`, m.frequencyOfSupport)
						break
				}
			})

		if (currentData.carersStarInput) {
			formMethods.setValue("carerStarClient", currentData.carersStarInput.carerStarClient)
			formMethods.setValue("carerStarWorker", currentData.carersStarInput.carerStarWorker)
			formMethods.setValue("carersStarType", currentData.carersStarInput.carersStarType)
			formMethods.setValue(
				"dateOfCompletion",
				currentData.carersStarInput.dateOfCompletion ? moment(currentData.carersStarInput.dateOfCompletion).toDate() : null,
			)

			formMethods.setValue("completedBy", currentData.carersStarInput.completedBy)
			formMethods.setValue("healthNote", currentData.carersStarInput.healthNote)
			formMethods.setValue("caringRoleNote", currentData.carersStarInput.caringRoleNote)
			formMethods.setValue("managingAtHomeNote", currentData.carersStarInput.managingAtHomeNote)
			formMethods.setValue("timeForYourselfNote", currentData.carersStarInput.timeForYourselfNote)
			formMethods.setValue("howYouFeelNote", currentData.carersStarInput.howYouFeelNote)
			formMethods.setValue("financesNote", currentData.carersStarInput.financesNote)
			formMethods.setValue("workNote", currentData.carersStarInput.workNote)
			formMethods.setValue("otherNote", currentData.carersStarInput.otherNote)

			// set star spike
			setStarSpike(
				starSpike.map((sp) => {
					if (!currentData.carersStarInput) return sp
					// find form star spike
					const target = currentData.carersStarInput.starSpike.find((s) => s.label === sp.label)
					if (!target) return sp
					return target
				}),
			)


			// set action plan
			if (currentData.carersStarInput.actionPlans && currentData.carersStarInput.actionPlans.length > 0) {
				const placeHolder: number[] = []
				let nextIndex = 0
				currentData.carersStarInput.actionPlans.forEach((ap, i) => {
					placeHolder.push(i)
					nextIndex += 1
					formMethods.setValue(`priorityAreaAndStageStep${i}`, ap.priorityAreaAndStageStep)
					formMethods.setValue(`goal${i}`, ap.goal)
					formMethods.setValue(`action${i}`, ap.action)
					formMethods.setValue(`byWho${i}`, ap.byWho)
					formMethods.setValue(`byWhen${i}`, ap.byWhen ? moment(ap.byWhen).toDate() : null)
				})
				setActionPlanPlaceholder(placeHolder)
				setActionPlanNextIndex(nextIndex)
			}
		}

		if (currentData.youthStarInput) {
			formMethods.setValue("carerStarClient", currentData.youthStarInput.carerStarClient)
			formMethods.setValue("carerStarWorker", currentData.youthStarInput.carerStarWorker)
			formMethods.setValue("carersStarType", currentData.youthStarInput.carersStarType)
			formMethods.setValue(
				"dateOfCompletion",
				currentData.youthStarInput.dateOfCompletion ? moment(currentData.youthStarInput.dateOfCompletion).toDate() : null,
			)

			formMethods.setValue("completedBy", currentData.youthStarInput.completedBy)
			formMethods.setValue("interestAndActivitiesNote", currentData.youthStarInput.interestAndActivitiesNote)
			formMethods.setValue("hopesAndDreamsNote", currentData.youthStarInput.hopesAndDreamsNote)
			formMethods.setValue("healthAndWellBeingNote", currentData.youthStarInput.healthAndWellBeingNote)
			formMethods.setValue("educationAndWorkNote", currentData.youthStarInput.educationAndWorkNote)
			formMethods.setValue("communicatingNote", currentData.youthStarInput.communicatingNote)
			formMethods.setValue("choicesAndBehaviorNote", currentData.youthStarInput.choicesAndBehaviorNote)

			// set star spike
			setYouthStarSpike(
				youthStarSpike.map((sp) => {
					if (!currentData.youthStarInput) return sp
					// find form star spike
					const target = currentData.youthStarInput.youthStarSpike.find((s) => s.label === sp.label)
					if (!target) return sp
					return target
				}),
			)


			// set youth star spike
			setYouthStarSpike(
				youthStarSpike.map((sp) => {
					if (!currentData.youthStarInput) return sp
					// find form star spike
					const target = currentData.youthStarInput.youthStarSpike.find((s) => s.label === sp.label)
					if (!target) return sp
					return target
				}),
			)

			// set action plan
			if (currentData.youthStarInput.actionPlans && currentData.youthStarInput.actionPlans.length > 0) {
				const placeHolder: number[] = []
				let nextIndex = 0
				currentData.youthStarInput.actionPlans.forEach((ap, i) => {
					placeHolder.push(i)
					nextIndex += 1
					formMethods.setValue(`priorityAreaAndStageStep${i}`, ap.priorityAreaAndStageStep)
					formMethods.setValue(`goal${i}`, ap.goal)
					formMethods.setValue(`action${i}`, ap.action)
					formMethods.setValue(`byWho${i}`, ap.byWho)
					formMethods.setValue(`byWhen${i}`, ap.byWhen ? moment(ap.byWhen).toDate() : null)
				})
				setActionPlanPlaceholder(placeHolder)
				setActionPlanNextIndex(nextIndex)
			}
		}

	}, [formData]) // eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		preFillData()
	}, [preFillData])

	React.useEffect(() => {
		formMethods.setValue(
			"workerID",
			currentUser ? [{ id: currentUser.id, label: `${currentUser.firstName} ${currentUser.lastName}`, avatar: currentUser.avatarURL || "" }] : [],
		)
		formMethods.setValue(
			"carerStarWorker",
			currentUser ? [{ id: currentUser.id, label: `${currentUser.firstName} ${currentUser.lastName}`, avatar: currentUser.avatarURL || "" }] : [],
		)
		formMethods.setValue("hasMentalHealthTeam", true)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const [validationTriggered, setValidationTriggered] = React.useState<boolean>(false)
	const [incompleteSteps, setIncompleteSteps] = React.useState<number[]>([])
	// trigger validation of the entire form
	const triggerValidation = () => {
		const currentState = formMethods.getValues()
		setValidationTriggered(true)
		const incompleteStep: number[] = []

		let isValid = validateReferrerDetail(currentState)
		if (!isValid) incompleteStep.push(0)

		// adult intake validation
		if (!withNDIS) {
			isValid = validatePresenceOfRiskConcerns(currentState)
			if (!isValid) incompleteStep.push(1)

			isValid = validateEmergencyContact(currentState)
			if (!isValid) incompleteStep.push(2)

			isValid = validateChallengeForClient(currentState)
			if (!isValid) incompleteStep.push(3)

			isValid = validateClientContext(currentState)
			if (!isValid) incompleteStep.push(4)

			if (checkCarersStarIsFilled()) {
				isValid = validateCarersStar(currentState)
				if (!isValid) incompleteStep.push(5)
			}

			isValid = validateActionPlan(currentState)
			if (!isValid) incompleteStep.push(7)

			// store the incomplete steps to show on the progress bar
			setIncompleteSteps(incompleteStep)

			// return whether the entire form is valid
			return incompleteStep.length === 0
		}

		// ndis intake validation
		isValid = validateEmergencyContact(currentState)
		if (!isValid) incompleteStep.push(1)

		isValid = validateChallengeForClient(currentState)
		if (!isValid) incompleteStep.push(2)

		isValid = validateNDISServiceDetail(currentState)
		if (!isValid) incompleteStep.push(3)

		isValid = validateNDISMentalHealthTreatingTeam(currentState)

		if (!isValid) incompleteStep.push(4)

		// store the incomplete steps to show on the progress bar
		setIncompleteSteps(incompleteStep)

		return incompleteStep.length === 0
	}

	// validation functions which only used in Adult intake and NDIS intake
	// check emergency contacts form
	const validateEmergencyContact = (currentState: any) => {
		let isValid = true // track whether there is any contact error

		emergencyContactPlaceholder.forEach((p) => {
			if (!isValid) return // skip process if any field is already invalid
			// check current emergency contact has contact error
			if (!currentState[`emergencyContactMobileNumber${p}`] && !currentState[`emergencyContactTelephoneNumber${p}`]) {
				isValid = false
				return
			}

			if (!currentState[`emergencyContactRelationshipToClient${p}`] || currentState[`emergencyContactRelationshipToClient${p}`].length === 0) {
				isValid = false
				return
			}

			if (!currentState[`emergencyContactFirstName${p}`] || !currentState[`emergencyContactLastName${p}`]) {
				isValid = false
				return
			}
		})

		return isValid
	}

	const validateCarersStar = (currentState: any) => {
		if (!currentState.carerStarWorker || currentState.carerStarWorker.length === 0) return false
		if (!currentState.carerStarClient || currentState.carerStarClient.length === 0) return false
		if (!currentState.dateOfCompletion) return false
		return true
	}

	// check ndis mental health treating team
	const validateNDISMentalHealthTreatingTeam = (currentState: any) => {
		if (currentState.hadRecentHospitalisationForMentalHealth) {
			if (!currentState.recentHospitalisation) return false
		}
		if (currentState.hasMentalHealthTeam) {
			if (currentState.hasGP) {
				if (!currentState.GPContactName) return false
				if (!currentState.GPOrganisation) return false
				if (!currentState.GPContactNumber) return false
				if (!currentState.GPFrequencyOfSupport) return false
			}
			if (currentState.hasPsychiatrist) {
				if (!currentState.PsychiatristContactName) return false
				if (!currentState.PsychiatristOrganisation) return false
				if (!currentState.PsychiatristContactNumber) return false
				if (!currentState.PsychiatristFrequencyOfSupport) return false
			}
			if (currentState.hasCaseManager) {
				if (!currentState["Case ManagerOrganisation"]) return false
				if (!currentState["Case ManagerContactName"]) return false
				if (!currentState["Case ManagerContactNumber"]) return false
				if (!currentState["Case ManagerFrequencyOfSupport"]) return false
			}
			if (currentState.hasPsychologist) {
				if (!currentState.PsychologistPractice) return false
				if (!currentState.PsychologistContactName) return false
				if (!currentState.PsychologistContactNumber) return false
				if (!currentState.PsychologistFrequencyOfSupport) return false
			}
			if (otherMentalTreatingTeams.length > 0) {
				let isValid = true
				otherMentalTreatingTeams.forEach((o) => {
					// skip check if something is already not valid
					if (!isValid) return

					// check whether current fields are valid
					if (
						!currentState[`${o}TypeOfSupportOther`] ||
						!currentState[`${o}ContactNameOther`] ||
						!currentState[`${o}ContactNumberOther`] ||
						!currentState[`${o}FrequencyOfSupportOther`]
					) {
						isValid = false
						return
					}
				})
			}
		} else {
			if (!currentState.reasonForNoMentalHealthTreatingTeam) return false
		}

		return true
	}

	// check ndis service detail form
	const validateNDISServiceDetail = (currentState: any) => {
		if (!currentState.serviceType || currentState.serviceType.length === 0) return false
		if (!currentState.ndisPlanIsProvided || currentState.ndisPlanIsProvided.length === 0) return false

		//if ndis plan is provided, check ndis plan number and review date
		if (currentState.ndisPlanIsProvided && currentState.ndisPlanIsProvided.length > 0 && currentState.ndisPlanIsProvided[0].id !== "No") {
			// if any of them is empty return false
			if (!currentState.ndisPlanNumber || !currentState.ndisPlanReviewDate) return false
		}
		return true
	}

	// display the current progress on side progress bar
	React.useEffect(() => {
		const pathElements = history.location.pathname.split("/")
		const lastValue = pathElements[pathElements.length - 1]
		if (firstLoad) {
			if (lastValue !== IntakeType.Adult && lastValue !== IntakeType.NDIS) {
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/${withNDIS ? IntakeType.NDIS : IntakeType.Adult}`, formID, withNDIS))
			}
			setFirstLoad(false)
			return
		}
		switch (lastValue) {
			case "presenceOfRiskConcerns":
				setStep(1)
				break
			case "emergencyContactDetails":
				if (withNDIS) {
					setStep(1)
					break
				}
				setStep(2)
				break
			case "challengesForClient":
				if (withNDIS) {
					setStep(2)
					break
				}
				setStep(3)
				break
			case "serviceDetail": // for NDIS version
				setStep(3)
				break
			case "clientContext":
			case "mentalHealthTreatingTeam": // for NDIS version
				setStep(4)
				break
			case "carerStar":
			case "ndisSupports": // for NDIS version
				setStep(5)
				break
			case "supports":
				setStep(6)
				break
			case "actionPlan":
				setStep(7)
				break
			default:
				setStep(0)
				break
		}
	}, [history.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = async (isOnSave: boolean) => {
		if (formIsCompleted || clientIntakeCreate.loading || clientLoading || !client) return
		const currentState = formMethods.getValues()

		// if not on save and does not pass the validation, terminate the process
		if (!isOnSave && !triggerValidation()) return

		// parse emergency contact
		const emergencyContacts = parseEmergencyContact()

		// parse mental health treating teams
		const mentalHealthTreatingTeams = parseMentalHealthTreatingTeam()

		// parse carers star
		const carersStarInput = parseCarersStar()


		// parse carers star
		const youthStarInput = parseYouthStar()

		// submit client intake
		const resp = await clientIntakeCreate.mutate({
			clientID,
			formID,
			type: withNDIS ? IntakeType.NDIS : IntakeType.Adult,
			onSave: isOnSave,
			content: {
				...currentState,
				emergencyContacts,
				mentalHealthTreatingTeams,
				carersStarInput,
				youthStarInput
			},
		})

		if (resp.error || !resp.payload) return
		setFormDirty(false)
		await fetchClient(clientID)
		showToast("Adult Intake Document created successfully.", "positive")
		history.push(`/portal/clients/${clientID}#forms`)
	}

	const parseEmergencyContact = (): ClientEmergencyContactInput[] => {
		const currentState = formMethods.getValues()

		// convert emergency contact data
		const emergencyContacts: ClientEmergencyContactInput[] = []
		emergencyContactPlaceholder.forEach((i) => {
			// recorded formData
			const ec: ClientEmergencyContactInput = {
				firstName: currentState[`emergencyContactFirstName${i}`],
				lastName: currentState[`emergencyContactLastName${i}`],
				mobileNumber: currentState[`emergencyContactMobileNumber${i}`],
				telephoneNumber: currentState[`emergencyContactTelephoneNumber${i}`],
			}
			if (currentState[`emergencyContactRelationshipToClient${i}`] && currentState[`emergencyContactRelationshipToClient${i}`].length > 0) {
				ec.relationshipToClient = currentState[`emergencyContactRelationshipToClient${i}`][0].id
			}
			if (currentState[`emergencyContactRelationshipToConsumer${i}`] && currentState[`emergencyContactRelationshipToConsumer${i}`].length > 0) {
				ec.relationshipToConsumer = currentState[`emergencyContactRelationshipToConsumer${i}`][0].id
			}
			emergencyContacts.push(ec)
		})

		return emergencyContacts
	}

	const checkCarersStarIsFilled = (): boolean => {
		const currentState = formMethods.getValues()
		// check any of the fields is filled
		if (
			(!currentState.carersStarType || currentState.carersStarType.length === 0) &&
			!currentState.dateOfCompletion &&
			(!currentState.completedBy || currentState.completedBy.length === 0) &&
			!currentState.healthNote &&
			!currentState.caringRoleNote &&
			!currentState.managingAtHomeNote &&
			!currentState.timeForYourselfNote &&
			!currentState.howYouFeelNote &&
			!currentState.financesNote &&
			!currentState.workNote &&
			!currentState.otherNote
		) {
			let starDegreeIsSet = false
			// check star degree
			starSpike.forEach((ss) => {
				if (ss.value > 0) starDegreeIsSet = true
			})

			if (!starDegreeIsSet) {
				// check action plan value is set
				let actionPlanIsSet = false
				actionPlanPlaceholder.forEach((index) => {
					if (actionPlanIsSet) return

					if (
						!!currentState[`priorityAreaAndStageStep${index}`] ||
						!!currentState[`goal${index}`] ||
						!!currentState[`action${index}`] ||
						!!currentState[`byWho${index}`] ||
						!!currentState[`byWhen${index}`]
					) {
						actionPlanIsSet = true
					}
				})

				// if no value is set, exit function
				if (!actionPlanIsSet) return false
			}
		}

		return true
	}

	const parseCarersStar = (): CarersStarFormInput | undefined => {
		if (!checkCarersStarIsFilled()) return

		const currentState = formMethods.getValues()

		// if pass all the test, generate carer star
		const actionPlans: CarersStarActionPlanInput[] = []
		actionPlanPlaceholder.forEach((index) => {
			return actionPlans.push({
				priorityAreaAndStageStep: currentState[`priorityAreaAndStageStep${index}`],
				goal: currentState[`goal${index}`],
				action: currentState[`action${index}`],
				byWho: currentState[`byWho${index}`],
				byWhen: currentState[`byWhen${index}`],
			})
		})
		return {
			carerStarClient: currentState.carerStarClient,
			carerStarWorker: currentState.carerStarWorker,
			carersStarType: currentState.carersStarType,
			dateOfCompletion: currentState.dateOfCompletion,
			completedBy: currentState.completedBy,
			healthNote: currentState.healthNote,
			caringRoleNote: currentState.caringRoleNote,
			managingAtHomeNote: currentState.managingAtHomeNote,
			timeForYourselfNote: currentState.timeForYourselfNote,
			howYouFeelNote: currentState.howYouFeelNote,
			financesNote: currentState.financesNote,
			workNote: currentState.workNote,
			otherNote: currentState.otherNote,
			starSpike,
			actionPlans,
		}
	}


	const checkYouthStarIsFilled = (): boolean => {
		const currentState = formMethods.getValues()
		// check any of the fields is filled
		if (
			(!currentState.carersStarType || currentState.carersStarType.length === 0) &&
			!currentState.dateOfCompletion &&
			(!currentState.completedBy || currentState.completedBy.length === 0) &&
			!currentState.interestAndActivitiesNote &&
			!currentState.hopesAndDreamsNote &&
			!currentState.healthAndWellBeingNote &&
			!currentState.educationAndWorkNote &&
			!currentState.communicatingNote &&
			!currentState.choicesAndBehaviorNote 
		) {
			let starDegreeIsSet = false
			// check star degree
			youthStarSpike.forEach((ss) => {
				if (ss.value > 0) starDegreeIsSet = true
			})

			if (!starDegreeIsSet) {
				// check action plan value is set
				let actionPlanIsSet = false
				actionPlanPlaceholder.forEach((index) => {
					if (actionPlanIsSet) return

					if (
						!!currentState[`priorityAreaAndStageStep${index}`] ||
						!!currentState[`goal${index}`] ||
						!!currentState[`action${index}`] ||
						!!currentState[`byWho${index}`] ||
						!!currentState[`byWhen${index}`]
					) {
						actionPlanIsSet = true
					}
				})

				// if no value is set, exit function
				if (!actionPlanIsSet) return false
			}
		}

		return true
	}



	const parseYouthStar = (): YouthStarFormInput | undefined => {
		if (!checkYouthStarIsFilled()) return

		const currentState = formMethods.getValues()

		// if pass all the test, generate carer star
		const actionPlans: YouthStarActionPlanInput[] = []
		actionPlanPlaceholder.forEach((index) => {
			return actionPlans.push({
				priorityAreaAndStageStep: currentState[`priorityAreaAndStageStep${index}`],
				goal: currentState[`goal${index}`],
				action: currentState[`action${index}`],
				byWho: currentState[`byWho${index}`],
				byWhen: currentState[`byWhen${index}`],
			})
		})
		return {
			carerStarClient: currentState.carerStarClient,
			carerStarWorker: currentState.carerStarWorker,
			carersStarType: currentState.carersStarType,
			dateOfCompletion: currentState.dateOfCompletion,
			completedBy: currentState.completedBy,
			interestAndActivitiesNote: currentState.interestAndActivitiesNote,
			hopesAndDreamsNote: currentState.hopesAndDreamsNote,
			healthAndWellBeingNote: currentState.healthAndWellBeingNote,
			educationAndWorkNote: currentState.educationAndWorkNote,
			communicatingNote: currentState.communicatingNote,
			choicesAndBehaviorNote: currentState.choicesAndBehaviorNote,
			youthStarSpike,
			actionPlans,
		}
	}

	const parseMentalHealthTreatingTeam = (): MentalHealthTreatingTeam[] => {
		const currentState = formMethods.getValues()
		const mentalHealthTreatingTeams: MentalHealthTreatingTeam[] = []

		if (currentState.hasGP) {
			mentalHealthTreatingTeams.push({
				name: "GP",
				organisation: currentState.GPOrganisation,
				contactName: currentState.GPContactName,
				contactNumber: currentState.GPContactNumber,
				frequencyOfSupport: currentState.GPFrequencyOfSupport,
			})
		}
		if (currentState.hasPsychiatrist) {
			mentalHealthTreatingTeams.push({
				name: "Psychiatrist",
				organisation: currentState.PsychiatristOrganisation,
				contactName: currentState.PsychiatristContactName,
				contactNumber: currentState.PsychiatristContactNumber,
				frequencyOfSupport: currentState.PsychiatristFrequencyOfSupport,
			})
		}
		if (currentState.hasPsychologist) {
			mentalHealthTreatingTeams.push({
				name: "Psychologist",
				practice: currentState.PsychologistPractice,
				contactName: currentState.PsychologistContactName,
				contactNumber: currentState.PsychologistContactNumber,
				frequencyOfSupport: currentState.PsychologistFrequencyOfSupport,
			})
		}
		if (currentState.hasCaseManager) {
			mentalHealthTreatingTeams.push({
				name: "Case Manager",
				organisation: currentState[`Case ManagerOrganisation`],
				contactName: currentState[`Case ManagerContactName`],
				contactNumber: currentState[`Case ManagerContactNumber`],
				frequencyOfSupport: currentState[`Case ManagerFrequencyOfSupport`],
			})
		}
		if (otherMentalTreatingTeams.length > 0) {
			mentalHealthTreatingTeams.push(
				...otherMentalTreatingTeams.map<MentalHealthTreatingTeam>((os) => ({
					name: os,
					typeOfSupport: currentState[`${os}TypeOfSupportOther`],
					contactName: currentState[`${os}ContactNameOther`],
					contactNumber: currentState[`${os}ContactNumberOther`],
					frequencyOfSupport: currentState[`${os}FrequencyOfSupportOther`],
				})),
			)
		}

		return mentalHealthTreatingTeams
	}

	const container = css({
		display: "flex",
		height: "100%",
		width: "100%",
		opacity: clientIntakeCreate.loading || clientLoading ? 0.5 : 1,
		"@media only screen and (max-width: 1400px)": {
			flexDirection: "column",
			maxHeight: "100%",
			overflowY: "auto",
		},
	})

	const progressContainer = css({
		backgroundColor: "white",
		height: "fit-content",
		minWidth: "300px",
		maxWidth: "340px",
		padding: "20px",
		marginRight: "25px",
		"@media only screen and (max-width: 1400px)": {
			marginBottom: "10px",
			marginRight: "0",
			maxWidth: "100%",
		},
	})

	const formContainer = css({
		display: "flex",
		alignItems: "center",
		height: "100%",
		position: clientIntakeCreate.loading || clientLoading ? "relative" : "unset",
	})

	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})

	const formInner = css({
		width: "100%",
		height: "100%",
		[`@media only screen and (max-width: 1500px)`]: {
			width: "90%",
		},
	})

	return (
		<div className={container}>
			<ZenCard className={progressContainer}>
				<ZenVerticalProgress
					list={withNDIS ? ndisLabelLinks : adultLabelLinks}
					step={step}
					clientID={clientID}
					formID={formID}
					withNDIS={withNDIS}
					intakeType={withNDIS ? IntakeType.NDIS : IntakeType.Adult}
					saveForm={() => onSubmit(true)}
					incompleteSteps={incompleteSteps}
					isReadOnly={formIsCompleted}
				/>
			</ZenCard>
			<ZenCard className={formContainer}>
				{(clientIntakeCreate.loading || clientLoading) && (
					<div className={loadingIcon}>
						<StyledSpinnerNext />
					</div>
				)}
				{clientIntakeCreate.error && <ErrorNotification messageOrPayload={clientIntakeCreate.payload} />}
				<FormProvider {...formMethods}>
					<div className={formInner}>
						<Switch>
							<Route
								exact
								path={[routes.clients.client.intake.adult.root, routes.clients.client.intake.ndis.root]}
								render={() => (
									<Detail
										isReadOnly={formIsCompleted}
										clientID={clientID}
										intakeType={IntakeType.Adult}
										formID={formID}
										withNDIS={withNDIS}
										validationTriggered={validationTriggered}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.adult.presenceOfRiskConcerns}
								render={() => (
									<PresenceOfRiskConcerns
										isReadOnly={formIsCompleted}
										clientID={clientID}
										intakeType={IntakeType.Adult}
										formID={formID}
										withNDIS={withNDIS}
										validationTriggered={validationTriggered}
									/>
								)}
							/>
							<Route
								exact
								path={[routes.clients.client.intake.adult.emergencyContactDetails, routes.clients.client.intake.ndis.emergencyContactDetails]}
								render={() => (
									<IntakeAdultEmergencyContactDetails
										isReadOnly={formIsCompleted}
										clientID={clientID}
										formID={formID}
										withNDIS={withNDIS}
										placeholderList={emergencyContactPlaceholder}
										setPlaceholderList={setEmergencyContactPlaceholder}
										additionContactCount={additionEmergencyContactCount}
										setAdditionContactCount={setAdditionEmergencyContactCount}
										contactInputError={emergencyContactInputErrors}
										setContactInputError={setEmergencyContactInputErrors}
										validationTriggered={validationTriggered}
									/>
								)}
							/>
							<Route
								exact
								path={[routes.clients.client.intake.adult.challengesForClient, routes.clients.client.intake.ndis.challengesForClient]}
								render={() => (
									<ChallengesForClient
										isReadOnly={formIsCompleted}
										clientID={clientID}
										intakeType={IntakeType.Adult}
										formID={formID}
										withNDIS={withNDIS}
										validationTriggered={validationTriggered}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.adult.clientContext}
								render={() => (
									<IntakeAdultCareContext
										isReadOnly={formIsCompleted}
										clientID={clientID}
										formID={formID}
										withNDIS={withNDIS}
										validationTriggered={validationTriggered}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.adult.carerStar}
								render={() => (
									<IntakeAdultCarerStar
										isReadOnly={formIsCompleted}
										clientID={clientID}
										formID={formID}
										withNDIS={withNDIS}
										validationTriggered={validationTriggered}
										starSpike={starSpike}
										setStarSpike={setStarSpike}
										actionPlanPlaceholder={actionPlanPlaceholder}
										setActionPlanPlaceholder={setActionPlanPlaceholder}
										actionPlanNextIndex={actionPlanNextIndex}
										setActionPlanNextIndex={setActionPlanNextIndex}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.adult.supports}
								render={() => (
									<Supports
										isReadOnly={formIsCompleted}
										clientID={clientID}
										intakeType={IntakeType.Adult}
										formID={formID}
										withNDIS={withNDIS}
										validationTriggered={validationTriggered}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.adult.actionPlan}
								render={() => (
									<ActionPlan
										isReadOnly={formIsCompleted}
										clientID={clientID}
										intakeType={IntakeType.Adult}
										formID={formID}
										withNDIS={withNDIS}
										validationTriggered={validationTriggered}
										onSubmit={() => onSubmit(false)}
									/>
								)}
							/>

							{/* intake forms for NDIS version */}
							<Route
								exact
								path={routes.clients.client.intake.ndis.serviceDetail}
								render={() => (
									<IntakeNDISServiceDetail
										validationTriggered={validationTriggered}
										isReadOnly={formIsCompleted}
										clientID={clientID}
										formID={formID}
										withNDIS={withNDIS}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.ndis.mentalHealthTreatingTeam}
								render={() => (
									<IntakeNDISMentalHealthTeam
										validationTriggered={validationTriggered}
										otherMentalTreatingTeams={otherMentalTreatingTeams}
										setOtherMentalTreatingTeams={setOtherMentalTreatingTeams}
										isReadOnly={formIsCompleted}
										clientID={clientID}
										formID={formID}
										withNDIS={withNDIS}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.ndis.ndisSupports}
								render={() => (
									<IntakeNDISSupport
										validationTriggered={validationTriggered}
										isReadOnly={formIsCompleted}
										clientID={clientID}
										formID={formID}
										withNDIS={withNDIS}
										onSubmit={() => onSubmit(false)}
									/>
								)}
							/>
						</Switch>
					</div>
				</FormProvider>
			</ZenCard>
			<Prompt
				when={formDirty}
				message={() => {
					if (
						history.location.pathname.startsWith(routes.withID(clientID, routes.clients.client.intake.adult.root)) ||
						history.location.pathname.startsWith(routes.withID(clientID, routes.clients.client.intake.ndis.root))
					)
						return true
					return "You have unsaved changes, are you sure you want to leave?"
				}}
			/>
		</div>
	)
}
