import { useStyletron } from "baseui"
import { Alert } from "baseui/icon"
import { ModalBody, ModalFooter, ModalHeader, ROLE } from "baseui/modal"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { EmptyPrompt } from "components/emptyPrompt"
import { ZenModal } from "components/zenComponents/zenModal"
import * as React from "react"
import { PortalContainer } from "../../../../controllers/portal"
import { friendlyDate, friendlyTime } from "../../../../helpers/utils"
import { ZenTheme } from "../../../../themeOverrides"
import { ClientDetail, Contact } from "../../../../types/types"
import { Divider } from "../../../common"
import { FieldDisplay } from "../../../fieldDisplay"
import { ZenButton } from "../../../zenComponents/zenButtons"

export const PostalAddress = (props: ClientDetail) => {
	const { leaveTextMessage, agreedToReceiveEmails, leaveVoiceMessage, currentContact, previousContacts } = props
	const [showContactsModal, setShowContactsModal] = React.useState(false)
	const [css] = useStyletron()

	const container = css({
		display: "flex",
		flexDirection: "column",
		marginTop: "8px",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	const innerGroup = css({
		marginTop: "8px",
		width: "100%",
		display: "flex",
		alignItems: "center",
	})

	return (
		<div className={container}>
			<LabelSmall color={ZenTheme.colors.primaryGrey}>Postal Address</LabelSmall>
			<LabelSmall marginTop="8px">{currentContact?.postalAddress?.fullAddress || "Same as residential address"}</LabelSmall>
			<Divider style={{ backgroundColor: "white" }} />

			<div>
				<LabelSmall
					$style={{
						cursor: "pointer",
						textDecoration: "underline",
					}}
					onClick={() => setShowContactsModal(true)}
				>
					Previous contact details
				</LabelSmall>
				<PreviousContactsModal
					isOpen={showContactsModal}
					setIsOpen={setShowContactsModal}
					onClose={() => setShowContactsModal(false)}
					contacts={previousContacts || []}
				/>
			</div>
			<div className={group}>
				<FieldDisplay label="Can text" marginTop="10px">
					<LabelSmall>{leaveTextMessage ? "Yes" : "No"}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Can voice message" marginTop="10px">
					<LabelSmall>{leaveVoiceMessage ? "Yes" : "No"}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Can email" marginTop="10px">
					<LabelSmall>{agreedToReceiveEmails ? "Yes" : "No"}</LabelSmall>
				</FieldDisplay>
			</div>
			{!props.isOrganisation && (
				<>
					<FieldDisplay label="Country of birth" marginTop="8px">
						<LabelSmall>{props?.countryOfBirth?.countryName || "N/A"}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Primary language at home" marginTop="8px">
						<LabelSmall>{props?.languageSpokenAtHome?.label || "N/A"}</LabelSmall>
					</FieldDisplay>
					<div className={group}>
						<FieldDisplay label="ATSI status" marginTop="8px">
							<LabelSmall>{props?.aboriginalOrTorresStraitIslander || "N/A"}</LabelSmall>
						</FieldDisplay>
						<div className={innerGroup}>
							<FieldDisplay label="CaLD">
								<LabelSmall>{props?.culturallyAndLinguisticallyDiverse ? "Yes" : "No"}</LabelSmall>
							</FieldDisplay>
							<FieldDisplay label="LGBT">
								<LabelSmall>{props?.lesbianGayBisexualAndTransgender ? "Yes" : "No"}</LabelSmall>
							</FieldDisplay>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

interface PreviousContactsModalProps {
	onClose: () => void
	isOpen: boolean
	setIsOpen: (b: boolean) => void
	contacts: Contact[]
}
const PreviousContactsModal = (props: PreviousContactsModalProps) => {
	const { onClose, isOpen, contacts } = props
	const { timezone } = PortalContainer.useContainer()
	const [css] = useStyletron()
	const row = css({
		marginBottom: "10px",
	})
	const card = css({
		marginBottom: "10px",
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "15px",
	})
	const scroll = css({
		height: "400px",
		overflowY: "auto",
	})

	return (
		<ZenModal
			onClose={onClose}
			isOpen={isOpen}
			role={ROLE.dialog}
			overrides={{
				Dialog: {
					style: {
						height: "auto",
						overflowY: "hidden",
					},
				},
			}}
		>
			<ModalHeader>Previous Contacts</ModalHeader>
			<ModalBody>
				{(!contacts || contacts.length === 0) && (
					<div>
						<EmptyPrompt
							style={{
								color: "rgba(0,0,0,0.3)",
							}}
							icon={<Alert size={32} />}
							title={"No Results"}
							titleSize="s"
						/>
					</div>
				)}

				{contacts.length > 0 && (
					<div className={scroll}>
						{contacts.map((c, i) => {
							return (
								<div className={card} key={"contact-" + i}>
									<div className={row}>
										<LabelMedium>Address</LabelMedium>
										<div>{c.residentialAddress?.fullAddress || "N/A"}</div>
									</div>
									<div className={row}>
										<LabelMedium>Mobile Number</LabelMedium>
										<div>{c.mobileNumber || "N/A"}</div>
									</div>

									<div className={row}>
										<LabelMedium>Telephone Number</LabelMedium>
										<div>{c.telephoneNumber || "N/A"}</div>
									</div>

									<div className={row}>
										<LabelMedium>Email</LabelMedium>
										<div>{c.email || "N/A"}</div>
									</div>

									<div className={row}>
										<LabelMedium>Postal Address</LabelMedium>
										<div>{c.postalAddress?.fullAddress || "Same as residential address"}</div>
									</div>

									<div className={row}>
										<LabelMedium>Active Period</LabelMedium>
										<div>
											{friendlyDate(c.createdAt)} {friendlyTime(timezone, c.createdAt)} - {friendlyDate(c.deletedAt)} {friendlyTime(timezone, c.deletedAt)}
										</div>
									</div>
								</div>
							)
						})}
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<ZenButton onClick={onClose}>Close</ZenButton>
			</ModalFooter>
		</ZenModal>
	)
}
