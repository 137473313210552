import * as React from "react"
import { useEffect, useMemo } from "react"
import { useQuery } from "react-fetching-library"
import { Responsive, WidthProvider } from "react-grid-layout"

import { ItemMask, NodeFNProps } from ".."
import { Loading } from "../../../components/loading"
import { AssignedClients } from "../../../components/workers/assignedClient"
import { WorkerSession } from "../../../components/workers/workerSession"
import { fetching } from "../../../fetching"
import { AssignedClient, UserDetail } from "../../../types/types"
import { getLayouts } from "../layout"
import * as layout from "./layout"

const ResponsiveGridLayout = WidthProvider(Responsive)

export const SupervisorDashboard = ({
	editing,
	currentUser,
	onLayoutChange,
	onWidthChange,
	onBreakpointChange,
	itemCls,
	breakpoint,
	layouts,
	setLayouts,
}: NodeFNProps) => {
	const skey = layout.storageKey(breakpoint)

	useEffect(() => {
		setLayouts(getLayouts(skey, editing, layout.layouts, layout.panels))
	}, [editing, breakpoint, skey, setLayouts])

	const { error, payload, loading } = useQuery<{
		users: UserDetail[]
		total: number
	}>(fetching.query.getTeamUsersAll())

	const workers: UserDetail[] = useMemo(() => {
		if (!payload || loading || error) {
			return [currentUser]
		}
		return [currentUser, ...payload.users]
	}, [payload, loading, error, currentUser])

	const assignedClients = useMemo(() => {
		let ass: AssignedClient[] = []
		workers.forEach((w) => {
			if (!w.assignedClients) return
			const assClients = w.assignedClients.map((a) => {
				return { ...a, workerName: `${w.firstName} ${w.lastName}` }
			})
			ass = ass.concat(assClients || [])
		})
		return ass
	}, [workers])

	if (!layouts) return <Loading />

	return (
		<ResponsiveGridLayout
			onLayoutChange={onLayoutChange(skey, breakpoint)}
			onWidthChange={onWidthChange}
			onBreakpointChange={onBreakpointChange}
			layouts={layouts}
			rowHeight={(window.innerHeight - 66) / 25}
		>
			<div className={itemCls} key={"worker-session"}>
				{editing && <ItemMask />}
				<WorkerSession userId={workers} />
			</div>
			<div className={itemCls} key={"assigned"}>
				{editing && <ItemMask />}
				<AssignedClients assignedClients={assignedClients} />
			</div>
		</ResponsiveGridLayout>
	)
}
