import * as React from "react"
import { useQuery } from "react-fetching-library"
import { RouteComponentProps } from "react-router-dom"
import { fetching } from "../../fetching"
import { getMileageClaimStatus, friendlyDate, snakeToTitle } from "../../helpers/utils"
import { ListTable, useListTable } from "../../components/listTable"
import { MileageClaim } from "../../types/types"
import { getErrorMessage } from "../../helpers/errors"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { MileageClaimReview } from "../reports/mileageClaim"
import { ZenCard } from "../../components/common"

export const WorkerMileageClaims = (props: RouteComponentProps<{ workerID: string }>) => {
	const workerID = props.match.params.workerID
	const [mileageClaims, setMileageClaims] = React.useState<MileageClaim[]>([])
	const [selectedMileageClaimID, setSelectedMileageClaimID] = React.useState("")
	const { limit, offset, setTotal, total, setOffset } = useListTable({})

	const mileageClaimsQuery = useQuery(
		fetching.query.getMileageClaimMany({
			search: {
				workerID,
				sortBy: "PeriodStart",
				sortDir: "Descending",
			},
			limit,
			offset,
		}),
	)

	React.useEffect(() => {
		if (!mileageClaimsQuery.payload) return
		setMileageClaims(mileageClaimsQuery.payload.mileageClaims)
		setTotal(mileageClaimsQuery.payload.total)
	}, [mileageClaimsQuery.payload, setTotal])

	return (
		<ZenCard style={{ height: "100%" }}>
			<ListTable
				error={mileageClaimsQuery.error ? getErrorMessage(mileageClaimsQuery.payload) : ""}
				isLoading={mileageClaimsQuery.loading}
				rows={mileageClaims}
				onRowClick={(row: MileageClaim) => setSelectedMileageClaimID(row.id)}
				columns={[
					{
						id: "User",
						header: "User",
						resolver: (row: MileageClaim) => `${row.user.firstName} ${row.user.lastName}`,
					},
					{
						id: "From",
						header: "From",
						resolver: (row: MileageClaim) => friendlyDate(row.periodStart),
					},
					{
						id: "To",
						header: "To",
						resolver: (row: MileageClaim) => friendlyDate(row.periodEnd),
					},
					{
						id: "Status",
						header: "Status",
						resolver: (row: MileageClaim) =>
							snakeToTitle(getMileageClaimStatus(!!row.completedAt, !!row.readyAt, !!row.checkedAt, row.reviewStatus)),
					},
					{
						id: "ReviewedBy",
						header: "Reviewed By",
						resolver: (row: MileageClaim) => (row.reviewedBy ? `${row.reviewedBy.firstName} ${row.reviewedBy.lastName}` : ""),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{selectedMileageClaimID && (
				<MileageClaimReview
					id={selectedMileageClaimID}
					isOpen={!!selectedMileageClaimID}
					onClose={() => setSelectedMileageClaimID("")}
					reQuery={mileageClaimsQuery.query}
				/>
			)}
		</ZenCard>
	)
}
