import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { Alert } from "baseui/icon"
import { OptionProfile } from "baseui/menu"
import { LabelMedium } from "baseui/typography"
import { useHistory } from "react-router-dom"
import { ZenTheme } from "themeOverrides"

import { AuthContainer } from "../../controllers/auth"
import { AssignedClient, RolePermission } from "../../types/types"
import { ZenCard } from "../common"
import { EmptyPrompt } from "../emptyPrompt"

export const Supervisors = () => {
	const [css] = useStyletron()
	const history = useHistory()
	const container = css({
		height: "100%",
		width: "100%",
		padding: "15px",
	})
	const title = css({
		display: "flex",
		height: "fit-content",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const scrollingDiv = css({
		maxHeight: "100%",
		overflowY: "auto",
		marginTop: "8px",
	})

	const { currentUser, hasPermission } = AuthContainer.useContainer()
	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>Supervisors</LabelMedium>
			</div>
			<div className={scrollingDiv}>
				{currentUser && currentUser.supervisors && Array.isArray(currentUser.supervisors) ? (
					currentUser.supervisors.map((c, i) => (
						<div key={i} onClick={() => (hasPermission(RolePermission.UserRead) ? history.push(`/portal/workers/${c.id}`) : undefined)}>
							<OptionProfile
								item={c}
								getProfileItemImg={(t: AssignedClient) => () =>
									(
										<Avatar
											name={`${t.firstName} ${t.lastName}`}
											overrides={{
												Root: {
													style: {
														minWidth: ZenTheme.sizing.scale1000,
													},
												},
											}}
											src={t.avatarURL}
										/>
									)}
								getProfileItemImgText={(t: AssignedClient) => `${t.firstName} ${t.lastName}`}
								getProfileItemLabels={(t: AssignedClient) => ({ title: `${t.firstName} ${t.lastName}` })}
								overrides={{
									ListItemProfile: {
										style: {
											cursor: hasPermission(RolePermission.UserRead) ? "cursor" : "default",
											paddingTop: "3px",
											paddingBottom: "3px",
											paddingLeft: "1px",
											paddingRight: "2px",
										},
									},
									ProfileImgContainer: {
										style: {
											height: "40px",
											width: "40px",
										},
									},
									ProfileLabelsContainer: {
										style: {
											marginLeft: "8px",
										},
									},
									ProfileTitle: {
										style: {
											marginTop: "8px",
										},
									},
								}}
							/>
						</div>
					))
				) : (
					<EmptyPrompt
						style={{
							color: "rgba(0,0,0,0.3)",
						}}
						icon={<Alert size={32} />}
						title={"No results"}
						titleSize="s"
					/>
				)}
			</div>
		</ZenCard>
	)
}
