import { useEffect, useState } from "react"
import { useQuery } from "react-fetching-library"
import { createContainer } from "unstated-next"

import { fetching } from "../fetching"

export const ReportsContainer = createContainer(() => {
	const [timesheetsReady, setTimesheetsReady] = useState(0)
	const { payload: payrollReadyTimesheets, query: getTimesheetsReady } = useQuery(
		fetching.query.getManyTimesheets({
			search: {
				filterBy: "PayrollReady",
			},
		}),
		false,
	)

	useEffect(() => {
		if (!payrollReadyTimesheets) return
		setTimesheetsReady(payrollReadyTimesheets.total)
	}, [payrollReadyTimesheets])

	const [mileageClaimsReady, setMileageClaimsReady] = useState(0)
	const { payload: payrollReadyMileageClaims, query: getMileageClaimsReady } = useQuery(
		fetching.query.getMileageClaimMany({
			search: {
				filterBy: "PayrollReady",
			},
		}),
		false,
	)
	useEffect(() => {
		if (!payrollReadyMileageClaims) return
		setMileageClaimsReady(payrollReadyMileageClaims.total)
	}, [payrollReadyMileageClaims])

	return { timesheetsReady, getTimesheetsReady, mileageClaimsReady, getMileageClaimsReady }
})
