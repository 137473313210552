import * as React from "react"
import { faSignIn } from "@fortawesome/pro-solid-svg-icons"
import { faUserPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { ZenTheme } from "themeOverrides"
import { fetching } from "../../fetching"
import { friendlyDate, friendlyTime, timeDuration } from "../../helpers/utils"
import { FilterBy } from "../../types/enums"
import { CallLog, ClientDetail, SearchTextMinLength, SortOrder } from "../../types/types"
import { SearchAndFilter, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { ListTable } from "../listTable"
import { ZenPagination } from "../zenComponents/zenPagination"
import { PortalContainer } from "../../controllers/portal"
import { callLogCreateInitialState } from "../../states/callLogCreate"
import { updateCallLogCreate } from "../../actions/callLogCreate"
import { useStateMachine } from "little-state-machine"
import { ZenButton } from "../zenComponents/zenButtons"
import { routes } from "../../routes"
import { ZenConfirmModal } from "../zenComponents/zenConfirmModal"

enum SortBy {
	Identifier = "Identifier",
	CallType = "CallType",
	Worker = "Worker",
	StartTime = "StartTime",
	EndTime = "EndTime",
	Duration = "Duration",
	CallFrom = "CallFrom",
	Client = "Client",
	PhoneNumber = "PhoneNumber",
	CallPurpose = "CallPurpose",
	TransferredToDepartment = "TransferredToDepartment",
}

export const CallLogList = (props: { client?: ClientDetail }) => {
	const { client } = props
	const history = useHistory()
	const limit = 20
	const [offset, setOffset] = React.useState(0)
	const [rows, setRows] = React.useState<CallLog[]>([])
	const [total, setTotal] = React.useState<number>(0)
	const [sortColumn, setSortColumn] = React.useState<string>(SortBy.StartTime)
	const [sortAsc, setSortAsc] = React.useState(false)

	const [filter, setFilter] = React.useState<Value>([{ label: FilterBy.All, id: FilterBy.All }])

	const [search, setSearch] = React.useState("")

	const { timezone } = PortalContainer.useContainer()

	const [openRemoveStaleCallLog, setOpenRemoveStaleCallLog] = React.useState(false)
	const { state, actions } = useStateMachine({
		updateCallLogCreate,
	})

	const isRelatedToCurrentClient =
		!!client?.id && !!state.callLogCreate.client && state.callLogCreate.client.length > 0 && client.id === state.callLogCreate.client[0].id

	const { error, payload, loading } = useQuery<{
		callLogs: CallLog[]
		total: number
	}>(
		fetching.query.getCallLogMany({
			search: {
				filterBy: filter[0]?.id?.toString(),
				search: search.length >= SearchTextMinLength ? search : undefined,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
			},
			limit,
			clientID: client?.id,
			offset: offset,
		}),
	)
	const callLogsMemo = React.useMemo(() => {
		return () => {
			if (loading || !payload || !payload.callLogs) return
			setRows(payload.callLogs)
			setTotal(payload.total)
		}
	}, [payload, loading])
	React.useEffect(() => {
		callLogsMemo()
	}, [callLogsMemo])

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	const viewCallLog = (id: string) => {
		history.push({
			pathname: `/portal/call-logs/${id}`,
			search: client ? `?client_id=${client.id}` : "",
		})
	}

	const [css] = useStyletron()
	const containerStyle = css({
		height: "100%",
	})
	const top = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})

	if (error) return <ErrorNotification messageOrPayload={payload} />

	const filterOptions = [
		{ label: FilterBy.All, id: FilterBy.All },
		{ label: FilterBy.Team, id: FilterBy.Team },
	]

	return (
		<ZenCard className={containerStyle}>
			<div className={top}>
				{/* Search/ filter bar */}
				<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} filterOptions={filterOptions} hideFilter={true} />
				{client && (
					<ZenButton
						onClick={() => {
							if (!state.callLogCreate.stale || isRelatedToCurrentClient) {
								history.push({
									pathname: routes.callLogs.create,
									search: `?client_id=${client.id}&client_name=${client.firstName || ""}_${client.lastName || ""}`,
								})
								return
							}
							setOpenRemoveStaleCallLog(true)
						}}
					>
						{isRelatedToCurrentClient ? "Resume Call Log" : "New Call Log"}
					</ZenButton>
				)}
			</div>

			<ListTable
				isLoading={loading}
				handleSort={handleSort}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				rows={rows}
				onRowClick={(row: CallLog) => viewCallLog(row.id)}
				columns={[
					{
						id: SortBy.CallType,
						header: "",
						resolver: (row: CallLog) => {
							if (row.type === "INCOMING_CALL") {
								return <FontAwesomeIcon icon={faSignIn} rotation={180} color={ZenTheme.colors.primaryGreen} title="Incoming Call" />
							}
							if (row.type === "OUTGOING_CALL") {
								return <FontAwesomeIcon icon={faSignIn} color={ZenTheme.colors.negative} title="Outgoing Call" />
							}
							return row.type
						},
						sortable: false,
					},
					{
						id: SortBy.StartTime,
						header: "Date",
						resolver: (row: CallLog) => `${friendlyDate(row.startTime, timezone)} ${friendlyTime(timezone, row.startTime)}`,
						sortable: true,
					},
					{
						id: SortBy.Client,
						header: "Client",
						resolver: (row: CallLog) => {
							return row.client ? (
								<>
									{row.callFrom === "CALL_FROM_NEW_CLIENT" && (
										<FontAwesomeIcon
											icon={faUserPlus}
											style={{
												marginRight: "5px",
											}}
											title="New Client"
										/>
									)}
									{row.client.firstName} {row.client.lastName}
								</>
							) : (
								"N/A"
							)
						},
						sortable: false,
					},
					{
						id: SortBy.Worker,
						header: "Worker",
						resolver: (row: CallLog) => `${row.worker.firstName} ${row.worker.lastName}`,
						sortable: false,
					},
					{
						id: SortBy.Duration,
						header: "Duration",
						resolver: (row: CallLog) => timeDuration(row.startTime, row.endTime),
						sortable: false,
					},
					{
						id: SortBy.CallPurpose,
						header: "Purpose of Call",
						resolver: (row: CallLog) => row.callPurpose.label,
						sortable: false,
					},
				]}
			/>

			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openRemoveStaleCallLog && client && (
				<ZenConfirmModal
					open={openRemoveStaleCallLog}
					setOpen={setOpenRemoveStaleCallLog}
					loading={false}
					title={"Are you sure you want to override"}
					message={"Existing call log draft?"}
					action={"Confirm"}
					confirm={() => {
						setOpenRemoveStaleCallLog(false)
						actions.updateCallLogCreate(callLogCreateInitialState())
						history.push({
							pathname: routes.callLogs.create,
							search: `?client_id=${client.id}&client_name=${client.firstName || ""}_${client.lastName || ""}`,
						})
					}}
				/>
			)}
		</ZenCard>
	)
}
