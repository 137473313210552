import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { Button, KIND } from "baseui/button"
import { Alert } from "baseui/icon"
import { ModalHeader, SIZE } from "baseui/modal"
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip"
import { LabelLarge, LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography"
import { useMutation, useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { AuthContainer } from "../../controllers/auth"

import { fetching } from "../../fetching"
import { snakeToTitle, truncate } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { FilterBy } from "../../types/enums"
import { RolePermission, SearchTextMinLength, SortOrder, User, UserWithRole } from "../../types/types"
import { Divider, SearchAndFilter, Spacer, ZenCard } from "../common"
import { EmptyPrompt } from "../emptyPrompt"
import { ErrorNotification } from "../errorBox"
import EventCalendar from "../eventCalendar"
import { ListTable, useListTable } from "../listTable"
import { ZenArchiveModal } from "../zenComponents/zenArchiveDialog"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenModal } from "../zenComponents/zenModal"
import { ZenPagination } from "../zenComponents/zenPagination"

export enum SortBy {
	Name = "Name",
	Role = "Role",
	Number = "Number",
	Email = "Email",
}

interface Props {
	viewOnly?: boolean
	onRowClick?: (user: UserWithRole) => void
	height?: string
	hideViewToggle?: boolean
}
export const WorkerList = (props: Props) => {
	const [rows, setRows] = React.useState<UserWithRole[]>([])
	const { sortColumn, sortAsc, limit, offset, setTotal, total, setOffset, handleSort, filter, setFilter, search, setSearch, listView, setListView } =
		useListTable({
			sortColumn: SortBy.Name,
		})

	const { error, payload, loading, query } = useQuery<{
		users: UserWithRole[]
		total: number
	}>(
		fetching.query.getManyUsers({
			search: {
				filterBy: filter[0]?.id?.toString() || FilterBy.Active,
				search: search.length >= SearchTextMinLength ? search : undefined,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
			},
			limit,
			offset,
		}),
	)

	const { mutate, payload: archivePayload, loading: archiveLoading, error: archiveError } = useMutation<User>(fetching.mutation.userToggleArchive)

	const { hasPermission } = AuthContainer.useContainer()

	// modal states
	const [showViewModal, setShowViewModal] = React.useState<"SCHEDULE" | "OFFICES" | undefined>()
	const [archiveModalOpen, setArchiveModalOpen] = React.useState(false)

	// selected row (user)
	const [selectedUser, setSelectedUser] = React.useState<UserWithRole>()

	const history = useHistory()

	const onRowClick = (row: UserWithRole) => {
		if (props.onRowClick) {
			props.onRowClick(row)
			return
		}
		history.push(`/portal/workers/${row.id}`)
	}

	React.useEffect(() => {
		if (loading || !payload || !payload.users) return
		setRows(payload.users)
		setTotal(payload.total)
	}, [payload, loading, error, setTotal])

	const toggleArchive = (id: string) => {
		mutate({
			id,
		})
	}

	React.useEffect(() => {
		if (!archivePayload) return
		query()
		setArchiveModalOpen(false)
	}, [archivePayload, query])

	const [css] = useStyletron()
	const cardStyle = css({
		minWidth: "320px",
		display: "flex",
		flexDirection: "row",
		paddingLeft: 0,
		paddingBottom: "0px",
		cursor: "pointer",
		height: "auto",

		":hover": {
			backgroundColor: "#eef0f9",
		},
	})
	const col1 = css({
		width: "20%",
		paddingTop: "10px",
		marginLeft: "10px",
		paddingLeft: 0,
		marginRight: "10px",
	})
	const col2 = css({
		width: "100%",
		paddingTop: "10px",
	})
	const col3 = css({
		paddingTop: "10px",
	})
	const containerStyle = css({
		height: props.height || "100%",
	})
	const top = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})
	const eventBody = css({
		height: "85%",
		fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
		padding: "20px",
	})

	// renders card component for card view
	const CardResolver = (row: UserWithRole) => {
		const { firstName, lastName, avatarURL, mobileNumber, deletedAt, email } = row
		return (
			<>
				<ZenCard
					className={cardStyle}
					onClick={() => {
						if (!hasPermission(RolePermission.UserRead) || deletedAt) {
							return
						}

						onRowClick(row)
					}}
					style={{
						backgroundColor: deletedAt ? "#eef0f9" : "white",
						borderLeft: deletedAt ? "#eef0f9" : "4px solid " + ZenTheme.colors.primaryGreen,
						cursor: !hasPermission(RolePermission.UserRead) || deletedAt ? "default" : "pointer",
					}}
				>
					<div className={col1}>
						<Avatar
							overrides={{
								Root: {
									style: {
										minWidth: ZenTheme.sizing.scale1000,
									},
								},
								Initials: { style: { fontSize: "12px" } },
							}}
							size={"37px"}
							name={`${firstName} ${lastName}`}
							src={avatarURL || ""}
						/>
					</div>
					<div className={col2}>
						{deletedAt && (
							<>
								<LabelMedium>[ARCHIVED]</LabelMedium>
								<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />
							</>
						)}

						<LabelLarge>{`${firstName} ${lastName}`}</LabelLarge>
						<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />

						{hasPermission(RolePermission.UserRead) && (
							<>
								<LabelSmall>{mobileNumber}</LabelSmall>
								<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />
							</>
						)}

						<StatefulTooltip content={() => <LabelSmall color="white">{email}</LabelSmall>} placement={PLACEMENT.top} returnFocus autoFocus>
							<LabelSmall>{truncate(email, 20)}</LabelSmall>
						</StatefulTooltip>

						<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />
						<ZenButton
							paddingLeft="0"
							onClick={() => {
								setSelectedUser(row)
								setShowViewModal("OFFICES")
							}}
							altKind="tertiary"
						>
							View Offices
						</ZenButton>
						{hasPermission(RolePermission.SessionRead) && (
							<ZenButton
								paddingLeft="0"
								onClick={() => {
									setSelectedUser(row)
									setShowViewModal("SCHEDULE")
								}}
								altKind="tertiary"
							>
								View Schedule
							</ZenButton>
						)}
					</div>
					{hasPermission(RolePermission.UserRead) && (
						<div className={col3}>
							<Button
								kind={KIND.minimal}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setArchiveModalOpen(true)
									setSelectedUser(row)
								}}
							>
								<FontAwesomeIcon
									color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
									icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
								/>
							</Button>
						</div>
					)}
				</ZenCard>
			</>
		)
	}

	if (error) return <ErrorNotification messageOrPayload={payload} />
	return (
		<ZenCard className={containerStyle}>
			<div className={top}>
				{/* Search/ filter bar */}
				<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} />

				{/* Toggle buttons for list / cards view */}
				{hasPermission(RolePermission.UserRead) && !props.hideViewToggle && (
					<>
						<Button kind={KIND.tertiary} onClick={() => setListView(false)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
							<FontAwesomeIcon size={"lg"} color={!listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "th"]} />
						</Button>

						<Spacer />

						<Button kind={KIND.tertiary} onClick={() => setListView(true)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
							<FontAwesomeIcon size={"lg"} color={listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "bars"]} />
						</Button>
					</>
				)}
			</div>
			<ListTable
				isLoading={loading}
				handleSort={handleSort}
				cardView={!hasPermission(RolePermission.UserRead) || !listView}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				rows={rows}
				cardViewResolver={(row: UserWithRole) => {
					return CardResolver(row)
				}}
				onRowClick={(row: UserWithRole) => {
					onRowClick(row)
				}}
				columns={[
					{
						id: SortBy.Name,
						header: "Name",
						resolver: (row: UserWithRole) => (
							<div>
								<div>
									{`${row.firstName} ${row.lastName} `}
									{row.deletedAt && <LabelXSmall marginLeft="10px">[ARCHIVED]</LabelXSmall>}
								</div>
							</div>
						),
						sortable: true,
					},
					{
						id: SortBy.Role,
						header: "Role",
						resolver: (row: UserWithRole) => snakeToTitle(row.role.name),
						sortable: false,
					},
					{
						id: SortBy.Number,
						header: "Number",
						resolver: (row: UserWithRole) => row.mobileNumber,
					},

					{
						id: SortBy.Email,
						sortable: false,
						header: "Email",
						resolver: (row: UserWithRole) => row.email,
					},
					{
						id: "",
						header: "",
						omitted: props.viewOnly,
						resolver: (row: UserWithRole) => (
							<Button
								kind={KIND.minimal}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setArchiveModalOpen(true)
									setSelectedUser(row)
								}}
							>
								<FontAwesomeIcon
									color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
									icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
								/>
							</Button>
						),
					},
					{
						id: "",
						header: "",
						resolver: () => <div style={{ width: "30px" }}></div>,
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{archiveError && <ErrorNotification messageOrPayload={archivePayload} />}
			{selectedUser && (
				<ZenArchiveModal
					message={`${selectedUser.firstName} ${selectedUser.lastName}`}
					open={archiveModalOpen}
					confirmArchive={() => {
						toggleArchive(selectedUser.id)
						setArchiveModalOpen(false)
					}}
					onClose={() => {
						setArchiveModalOpen(false)
					}}
					restoreMode={!!selectedUser.deletedAt}
					loading={archiveLoading}
				/>
			)}

			{/* Schedule/ Offices modal */}
			<ZenModal
				overrides={{
					Dialog: {
						style: {
							maxWidth: showViewModal === "OFFICES" ? "600px" : "80%",
							height: showViewModal === "OFFICES" ? "400px" : "600px",
							overflowY: "auto",
							width: "100%",
						},
					},
				}}
				isOpen={!!showViewModal}
				onClose={() => setShowViewModal(undefined)}
				size={SIZE.auto}
				autoFocus={false}
			>
				<ModalHeader>
					<LabelLarge>
						{selectedUser && `${selectedUser.firstName} ${selectedUser.lastName}'s ${showViewModal === "OFFICES" ? "Offices" : "Schedule"}`}
					</LabelLarge>
				</ModalHeader>
				<div className={eventBody}>
					{selectedUser && showViewModal === "SCHEDULE" && <EventCalendar hideBorder workerID={selectedUser.id} defaultView="agenda" />}
					{selectedUser && showViewModal === "OFFICES" && (
						<>
							{(!selectedUser.offices || selectedUser.offices.length === 0) && (
								<EmptyPrompt
									style={{
										color: "rgba(0,0,0,0.3)",
									}}
									icon={<Alert size={32} />}
									title={"No results"}
									titleSize="s"
								/>
							)}
							{selectedUser.offices &&
								selectedUser.offices.map((o, i) => {
									return (
										<div key={i} style={{ marginTop: "5px", padding: "5px", backgroundColor: i % 2 === 0 ? "#00000011" : "unset" }}>
											<LabelSmall>{o.day}</LabelSmall>
											<LabelSmall>{o.office.name}</LabelSmall>
										</div>
									)
								})}
						</>
					)}
				</div>
			</ZenModal>
		</ZenCard>
	)
}
