import { Layout, Layouts } from "react-grid-layout"

export const cols: { [key: string]: number } = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
export const breakpoints = ["lg", "md", "sm", "xs", "xxs"]
export const breakpointWidths = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }

export const storeKey = "dashboard"

export const getBreakpoint = (width: number): string => {
	if (width >= breakpointWidths.lg) {
		return "lg"
	} else if (width >= breakpointWidths.md) {
		return "md"
	} else if (width >= breakpointWidths.sm) {
		return "sm"
	} else if (width >= breakpointWidths.xs) {
		return "xs"
	} else if (width >= breakpointWidths.xxs) {
		return "xxs"
	}
	return "lg"
}

export const resetLayout = () => {
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i)
		if (!key) continue
		if (key.startsWith(storeKey)) {
			localStorage.removeItem(key)
		}
	}
}

export function getLayouts(storageKey: string, editing: boolean | undefined, layouts: { [key: string]: Layout[] }, panels: string[]): Layouts {
	const newLayouts: { [key: string]: Layout[] } = { ...layouts }
	breakpoints.forEach((breakpoint) => {
		const layout = localStorage.getItem(storageKey)
		if (layout) {
			const l = JSON.parse(layout) as Layout[]
			const finLayout: Layout[] = panels.map((panel) => {
				let panellayout = l.find((layout) => layout.i === panel)
				if (!panellayout) {
					panellayout = layouts[breakpoint].find((layout) => layout.i === panel)
				}

				if (!panellayout) {
					throw new Error("no panel")
				}

				return panellayout
			})
			if (!finLayout) return
			newLayouts[breakpoint] = finLayout
		}
	})

	Object.keys(newLayouts).forEach(function (item) {
		newLayouts[item].forEach(() => {
			newLayouts[item] = newLayouts[item].map((l) => {
				return { ...l, static: !editing }
			})
		})
	})

	return newLayouts
}
