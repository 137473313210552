import * as React from "react"
import { toaster, ToastProps } from "baseui/toast"
import { ZenTheme } from "../../themeOverrides"

/**
 * React hook that exposes methods for displaying/removing toasts
 * Based on:
 *  - https://codesandbox.io/s/xbwpt?file=/src/ToastNotification/useToastNotification.tsx
 */

type ToastOptions = ToastProps
type ToastKind = "positive" | "negative" | "info" | "warning"
type ShowToast = (children: React.ReactNode, kind?: ToastKind, options?: ToastOptions) => void
type CloseToast = () => void

interface ZenToastReturn {
	isShowing: boolean
	showToast: ShowToast
	closeToast: CloseToast
}

export const useZenToast = (): ZenToastReturn => {
	const [toastKey, setToastKey] = React.useState<React.Key | null>(null)

	const showToast: ShowToast = (children, kind = "info", options) => {
		const defaultOptions: ToastOptions = {
			autoHideDuration: 2000,
		}

		switch (kind) {
			case "info":
				options = {
					overrides: {
						Body: {
							style: {
								backgroundColor: ZenTheme.colors.primaryGrey,
							},
						},
					},
					...defaultOptions,
					...options,
				}
				setToastKey(toaster.info(children, options))
				break
			case "positive":
				options = {
					overrides: {
						Body: {
							style: {
								backgroundColor: ZenTheme.colors.primary,
							},
						},
					},
					...defaultOptions,
					...options,
				}
				setToastKey(toaster.positive(children, options))
				break
			case "negative":
				options = {
					overrides: {
						Body: {
							style: {
								backgroundColor: ZenTheme.colors.negative,
							},
						},
					},
					...defaultOptions,
					...options,
				}
				setToastKey(toaster.negative(children, options))
				break
			case "warning":
				options = {
					overrides: {
						Body: {
							style: {
								backgroundColor: ZenTheme.colors.warning,
							},
						},
					},
					...defaultOptions,
					...options,
				}
				setToastKey(toaster.warning(children, options))
				break
		}
	}

	const closeToast: CloseToast = () => {
		if (toastKey) {
			const oldKey = toastKey
			setToastKey(null)
			toaster.clear(oldKey)
		}
	}

	return {
		isShowing: !!toastKey,
		showToast,
		closeToast,
	}
}
