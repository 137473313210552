import * as React from "react"
import { useStyletron } from "baseui"
import { LabelMedium } from "baseui/typography"
import { useHistory } from "react-router-dom"

import { User } from "../../types/types"
import { ZenCard } from "../common"
import { SessionPanel } from "../sessions/sessionPanel"
import { ZenButton } from "../zenComponents/zenButtons"

interface WorkerSessionProps {
	userId: string | User[]
}

export const WorkerSession = ({ userId }: WorkerSessionProps) => {
	const [css] = useStyletron()
	const history = useHistory()
	const container = css({
		height: "100%",
		width: "100%",
		padding: "15px",
	})
	const title = css({
		display: "flex",
		height: "fit-content",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const scrollableDiv = css({
		maxHeight: "96%",
		height: "96%",
		overflowY: "auto",
		marginTop: "8px",
	})

	if (Array.isArray(userId)) {
		return (
			<ZenCard className={container}>
				<div className={title}>
					<LabelMedium>Upcoming Sessions</LabelMedium>
				</div>
				<div className={scrollableDiv}>
					{userId.map((user) => (
						<SessionPanel
							workerID={user.id}
							showPastSessions={false}
							nullOnEmpty={true}
							key={`sessions-${user.id}-future`}
							renderName={`${user.firstName} ${user.lastName}`}
						/>
					))}
				</div>
			</ZenCard>
		)
	}
	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>Upcoming Sessions</LabelMedium>
				<ZenButton onClick={() => history.push(`/portal/workers/${userId}/calendar`)}>Calendar</ZenButton>
			</div>
			<div className={scrollableDiv}>
				<SessionPanel workerID={userId} showPastSessions={false} isCancelled={false} />
				<LabelMedium marginTop="10px" marginBottom="15px">
					Past Sessions
				</LabelMedium>
				<SessionPanel workerID={userId} showPastSessions={true} />
			</div>
		</ZenCard>
	)
}
