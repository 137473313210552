import * as React from "react"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { useParameterizedQuery } from "react-fetching-library"
import { Prompt, Route, Switch, useHistory } from "react-router-dom"
import { AuthContainer } from "../../../controllers/auth"
import { fetching } from "../../../fetching"
import { ClientDetail, ContractArea, FundingSource, SubSupportType, SupportType, Timezone, User } from "../../../types/types"
import { ZenCard } from "../../common"
import { ZenProgressBar } from "../../zenComponents/zenProgressBar"
import { AppointmentConfirmation } from "./singleSessionConfirmation"
import { NewClientSessionGeneral } from "./singleSessionGeneralForm"
import { ClientNotesForm } from "./singleSessionNotesForm"
import { routes } from "routes"

export const ClientSingleSessionSteps = ["General", "Client Notes", "Confirmation"]

export interface SingleClientSessionInput {
	worker?: Value
	client?: Value
	dateOfBirth?: Date
	appointmentDate?: Date
	timezone?: Timezone[]
	startTime?: Date
	endTime?: Date
	meetingTypeKey?: string
	sessionLocation?: Value
	office?: Value
	otherMeetingMethod?: string
	sessionFundingSources?: SessionFundingSourceInput[]
	notes?: string
	buddySessionWithMentor?: boolean
	buddySessionMentor?: [User]
	clientType?: Value
	// data that cached locally
	fontSize?: number

	// force session time check pass
	forceCreate?: boolean
}

export interface SessionFundingSourceInput {
	fundingSource: FundingSource[]
	contractArea?: ContractArea[]
	supportType?: SupportType[]
	subSupportType?: SubSupportType[]
}

export interface SessionFormData {
	data: SingleClientSessionInput
	setData: React.Dispatch<React.SetStateAction<SingleClientSessionInput>>
	pageChange?: (step: 1 | -1) => void
	setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SingleSessionBaseForm = () => {
	const [css] = useStyletron()
	const history = useHistory()
	const searchArgs = React.useMemo(() => {
		return new URLSearchParams(history.location.search)
	}, [history.location.search])
	const { currentUser } = AuthContainer.useContainer()
	const [firstLoad, setFirstLoad] = React.useState<boolean>(true)
	const [step, setStep] = React.useState<number>(0)
	const [data, setData] = React.useState<SingleClientSessionInput>({})
	const { payload: clientData, query } = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	const [isDirty, setIsDirty] = React.useState(false)

	React.useEffect(() => {
		const clientID = searchArgs.get("client_id")
		if (!clientID || clientID === "") return
		query(clientID)
	}, [query, searchArgs])

	React.useEffect(() => {
		if (!clientData) return
		setData((data: SingleClientSessionInput) => {
			return {
				...data,
				client: [{ ...clientData, label: `${clientData.firstName} ${clientData.lastName}` }],
				sessionLocation: clientData.currentContact?.residentialAddress
					? [{ id: clientData.currentContact?.residentialAddress.placeID, label: clientData.currentContact?.residentialAddress.fullAddress }]
					: [],
				dateOfBirth: clientData.dateOfBirth ? new Date(clientData.dateOfBirth) : undefined,
			}
		})
	}, [clientData])

	React.useEffect(() => {
		if (!currentUser) return
		setData((data: SingleClientSessionInput) => {
			return {
				...data,
				worker: [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }],
			}
		})
	}, [currentUser])

	// navigate page and progress bar
	React.useEffect(() => {
		const pathElements = history.location.pathname.split("/")
		const lastValue = pathElements[pathElements.length - 1]
		if (firstLoad) {
			if (lastValue !== "create_single_session") {
				history.replace({
					pathname: routes.sessions.create.single.root,
					search: searchArgs.toString(),
				})
			}
			setFirstLoad(false)
			return
		}
		switch (lastValue) {
			case "note":
				setStep(1)
				break
			case "confirmation":
				setStep(2)
				break
			default:
				setStep(0)
				break
		}
	}, [history.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

	const container = css({
		display: "flex",
		flexDirection: "column",
		padding: "15px",
		width: "100%",
		height: "100%",
	})

	const form = css({
		display: "flex",
		padding: "15px",
		justifyContent: "center",
		height: "100%",
		overflowY: "auto",
	})

	return (
		<ZenCard className={container}>
			<ZenProgressBar labels={ClientSingleSessionSteps} currentStep={step} />
			<div className={form}>
				<Switch>
					<Route
						exact
						path={routes.sessions.create.single.root}
						render={() => <NewClientSessionGeneral data={data} setData={setData} setIsDirty={setIsDirty} />}
					/>
					<Route exact path={routes.sessions.create.single.note} render={() => <ClientNotesForm data={data} setData={setData} setIsDirty={setIsDirty} />} />
					<Route
						exact
						path={routes.sessions.create.single.confirmation}
						render={() => <AppointmentConfirmation data={data} setData={setData} setIsDirty={setIsDirty} />}
					/>
				</Switch>
			</div>
			<Prompt
				when={isDirty}
				message={(location) => {
					if (location.pathname.startsWith(routes.sessions.create.single.root)) return true
					return "You have unsaved changes, are you sure you want to leave?"
				}}
			/>
		</ZenCard>
	)
}
