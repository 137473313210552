import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Block } from "baseui/block"
import { Checkbox } from "baseui/checkbox"
import { Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { Textarea } from "baseui/textarea"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"
import { useStyletron } from "styletron-react"

import { AuthContainer } from "../../controllers/auth"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { friendlyDate } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { FormType, FormTypeName } from "../../types/enums"
import { ClientDetail, Form, ReviewRecord, User } from "../../types/types"
import { Divider, Spacer, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { DisplayLabel } from "../sessions/newClientAppointment/singleSessionConfirmation"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenInput } from "../zenComponents/zenInput"
import { DisplaySelectCard, ZenClientSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../zenComponents/zenTime"
import { ReviewHistory } from "./reviewHistory"
import { ErrorFieldTracker } from "./errorFieldTracker"
import SignatureCanvas from "react-signature-canvas"
import { TooltipButton } from "../tooltipButton"
import { ZenModal } from "../zenComponents/zenModal"
import { ModalBody, ModalHeader } from "baseui/modal"
import { CancelAndSaveButtons } from "../cancelSaveButtons"

interface answerObject {
	question: string
	answers: string[]
}

interface relationshipObject {
	name: string
	relationship: Value
	phoneNumber: string
}

interface textareaQuestion {
	label: string
	caption?: string
	placeholder: number[]
	nextIndex: number
}

const initialTextareaQuestion = (): textareaQuestion[] => [
	{
		label: "People/things that are very important to me and make my life worthwhile:",
		placeholder: [0],
		nextIndex: 1,
	},
	{
		label: "What are my warning signs that indicate I am not okay?",
		placeholder: [0],
		nextIndex: 1,
	},
	{
		label: "Internal coping strategies/things I can do when I am not okay:",
		caption: "(Examples: ways to relax, physical exercise, helpful distractions, positive coping statements/self-talk, taking medication) ",
		placeholder: [0],
		nextIndex: 1,
	},
	{
		label: "Ways I can make my environment less stressful:",
		caption: "(This can involve a family member or support person to help me to do so)",
		placeholder: [0],
		nextIndex: 1,
	},
]

export const SafetyPlanForm = () => {
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const { timezone } = PortalContainer.useContainer()
	const { currentUser } = AuthContainer.useContainer()
	const history = useHistory()
	const searchArgs = new URLSearchParams(history.location.search)
	const formID = searchArgs.get("formID")
	const clientID = searchArgs.get("id")
	const signatureCanvasRef = React.useRef<SignatureCanvas | null>(null)
	const formMethods = useForm()
	const [questionList, setQuestionList] = React.useState<textareaQuestion[]>(initialTextareaQuestion())
	const [peopleCanContactIndexHolder, setPeopleCanContactIndexHolder] = React.useState([0])
	const [peopleToContactNextIndex, setPeopleCanContactNextIndex] = React.useState(1)

	const [professionalIndexHolder, setProfessionalIndexHolder] = React.useState([0])
	const [professionalNextIndex, setProfessionalNextIndex] = React.useState(1)

	const [isEditMode, setIsEditMode] = React.useState(!formID)
	const [reviewRecords, setReviewRecords] = React.useState<ReviewRecord[]>([])

	const [openSignatureModal, setOpenSignatureModal] = React.useState(false)

	const formSignatureUpdate = useMutation(fetching.mutation.formSignatureUpdate)

	// pre-fill worker
	React.useEffect(() => {
		if (!currentUser) return
		if (formID) {
			// pre-fill complete with worker field
			formMethods.setValue("completedWith", [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }])
			return
		}
		formMethods.setValue("worker", [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }])
	}, [currentUser, formID, formMethods])

	// pre-fill client
	const getClient = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	React.useEffect(() => {
		if (!clientID) return
		getClient
			.query(clientID)
			.then((resp) => {
				if (resp.error || !resp.payload) return
				formMethods.setValue("client", [{ ...resp.payload, label: `${resp.payload.firstName} ${resp.payload.lastName}` }])
			})
			.catch()
	}, [clientID]) // eslint-disable-line react-hooks/exhaustive-deps

	// pre-fill form data
	const formGet = useParameterizedQuery<Form<any>>(fetching.query.getForm)
	React.useEffect(() => {
		if (!formID) return
		formGet.query(formID)
	}, [formID]) // eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		if (formGet.loading || formGet.error || !formGet.payload) return
		setReviewRequired(!!formGet.payload.nextReviewDate)
		formMethods.setValue("worker", [
			{ ...formGet.payload.content.worker, label: `${formGet.payload.content.worker.firstName} ${formGet.payload.content.worker.lastName}` },
		])
		formMethods.setValue("client", [
			{ ...formGet.payload.content.client, label: `${formGet.payload.content.client.firstName} ${formGet.payload.content.client.lastName}` },
		])
		formMethods.setValue("date", formGet.payload.content.date ? moment(formGet.payload.content.date).toDate() : new Date())
		setQuestionList(
			initialTextareaQuestion().map((itq) => {
				if (!formGet.payload) return itq
				const targetAnswer: answerObject = formGet.payload.content.answerList.find((a: answerObject) => a.question === itq.label)
				if (!targetAnswer) return itq
				// update placeholder and nextIndex
				const newQuestion = { ...itq }
				newQuestion.placeholder = targetAnswer.answers.map((a, i) => i)
				newQuestion.nextIndex = targetAnswer.answers.length

				// set value in use form
				targetAnswer.answers.forEach((a, i) => {
					formMethods.setValue(`${itq.label}-${i}`, a)
				})

				return newQuestion
			}),
		)

		setPeopleCanContactIndexHolder(formGet.payload.content.peopleCanContactList.map((p: relationshipObject, i: number) => i))
		setPeopleCanContactNextIndex(formGet.payload.content.peopleCanContactList.length)

		formGet.payload.content.peopleCanContactList.forEach((pcc: relationshipObject, i: number) => {
			formMethods.setValue(`peopleCanContact-name-${i}`, pcc.name)
			formMethods.setValue(`peopleCanContact-relationship-${i}`, pcc.relationship)
			formMethods.setValue(`peopleCanContact-phoneNumber-${i}`, pcc.phoneNumber)
		})

		setProfessionalIndexHolder(formGet.payload.content.professionalList.map((p: relationshipObject, i: number) => i))
		setProfessionalNextIndex(formGet.payload.content.professionalList.length)
		formGet.payload.content.professionalList.forEach((p: relationshipObject, i: number) => {
			formMethods.setValue(`professionalContact-name-${i}`, p.name)
			formMethods.setValue(`professionalContact-relationship-${i}`, p.relationship)
			formMethods.setValue(`professionalContact-phoneNumber-${i}`, p.phoneNumber)
		})
		setFormData(formGet.payload.content)
	}, [formGet.payload, formGet.loading, formGet.error]) // eslint-disable-line react-hooks/exhaustive-deps

	const formEnableReview = useMutation(fetching.mutation.formEnableReview)
	const formDisableReview = useMutation(fetching.mutation.formDisableReview)
	const [reviewRequired, setReviewRequired] = React.useState<boolean>(false)
	const toggleFormReview = async (value: boolean) => {
		// directly change value if form does not exist yet
		if (!formID) {
			setReviewRequired(value)
			return
		}

		// enable/disable review feature if form is exist
		if (value) {
			const resp = await formEnableReview.mutate(formID)
			if (resp.error || !resp.payload) return
			setReviewRequired(value)
			return
		}
		const resp = await formDisableReview.mutate(formID)
		if (resp.error || !resp.payload) return
		setReviewRequired(value)
	}

	const formCreateWithSignature = useMutation(fetching.mutation.formCreateWithSignature)
	const formCreate = useMutation(fetching.mutation.formCreate)
	const formUpdate = useMutation(fetching.mutation.formUpdate)
	const formReview = useMutation(fetching.mutation.formReview)
	const isLoading =
		formGet.loading ||
		formEnableReview.loading ||
		formDisableReview.loading ||
		formCreate.loading ||
		formReview.loading ||
		formUpdate.loading ||
		formCreateWithSignature.loading

	const [cachedFormData, setCachedFormData] = React.useState<any>()
	// convert form data into form content to store in db
	const parseFormData = (formData: any) => {
		const answerList: answerObject[] = []
		questionList.forEach((ql) => {
			const answer: answerObject = {
				question: ql.label,
				answers: [],
			}
			ql.placeholder.forEach((qp) => {
				answer.answers.push(formData[`${ql.label}-${qp}`])
			})
			answerList.push(answer)
		})

		const peopleCanContactList: relationshipObject[] = []
		peopleCanContactIndexHolder.forEach((pci) => {
			// parse data
			peopleCanContactList.push({
				name: formData[`peopleCanContactName-${pci}`],
				relationship: formData[`peopleCanContactRelationship-${pci}`],
				phoneNumber: formData[`peopleCanContactPhoneNumber-${pci}`],
			})
		})

		const professionalList: relationshipObject[] = []
		professionalIndexHolder.forEach((pci) => {
			// parse data
			professionalList.push({
				name: formData[`professionalContactName-${pci}`],
				relationship: formData[`professionalContactRelationship-${pci}`],
				phoneNumber: formData[`professionalContactPhoneNumber-${pci}`],
			})
		})

		if (!formData || !formData.worker || !formData.worker[0] || !formData.client || !formData.client[0]) return null

		// parse data into array list
		return {
			worker: {
				id: formData.worker[0].id,
				avatarURL: formData.worker[0].avatarURL,
				firstName: formData.worker[0].firstName,
				lastName: formData.worker[0].lastName,
			},

			client: {
				id: formData.client[0].id,
				avatarURL: formData.client[0].avatarURL,
				firstName: formData.client[0].firstName,
				lastName: formData.client[0].lastName,
			},

			date: formData.date,
			answerList,
			peopleCanContactList,
			professionalList,
		}
	}

	// set up form from db form content
	const setFormData = (formData: any) => {
		formMethods.setValue("worker", [{ ...formData.worker, label: `${formData.worker.firstName} ${formData.worker.lastName}` }])
		formMethods.setValue("client", [{ ...formData.client, label: `${formData.client.firstName} ${formData.client.lastName}` }])
		formMethods.setValue("date", formData.date ? moment(formData.date).toDate() : new Date())
		setQuestionList(
			initialTextareaQuestion().map((itq) => {
				if (!formGet.payload) return itq
				const targetAnswer: answerObject = formData.answerList.find((a: answerObject) => a.question === itq.label)
				if (!targetAnswer) return itq
				// update placeholder and nextIndex
				const newQuestion = { ...itq }
				newQuestion.placeholder = targetAnswer.answers.map((a, i) => i)
				newQuestion.nextIndex = targetAnswer.answers.length

				// set value in use form
				targetAnswer.answers.forEach((a, i) => {
					formMethods.setValue(`${itq.label}-${i}`, a)
				})

				return newQuestion
			}),
		)

		setPeopleCanContactIndexHolder(formData.peopleCanContactList.map((p: relationshipObject, i: number) => i))
		setPeopleCanContactNextIndex(formData.peopleCanContactList.length)

		formData.peopleCanContactList.forEach((pcc: relationshipObject, i: number) => {
			formMethods.setValue(`peopleCanContactName-${i}`, pcc.name)
			formMethods.setValue(`peopleCanContactRelationship-${i}`, pcc.relationship)
			formMethods.setValue(`peopleCanContactPhoneNumber-${i}`, pcc.phoneNumber)
		})

		setProfessionalIndexHolder(formData.professionalList.map((p: relationshipObject, i: number) => i))
		setProfessionalNextIndex(formData.professionalList.length)
		formData.professionalList.forEach((p: relationshipObject, i: number) => {
			formMethods.setValue(`professionalContactName-${i}`, p.name)
			formMethods.setValue(`professionalContactRelationship-${i}`, p.relationship)
			formMethods.setValue(`professionalContactPhoneNumber-${i}`, p.phoneNumber)
		})
	}

	const onSubmit = async (formData: any) => {
		if (isLoading) return

		const file = buildSignatureFile()

		const content = parseFormData(formData)

		if (formID) {
			const resp = await formUpdate.mutate({
				id: formID,
				content: content,
				reviewRequired,
			})
			if (resp.error || !resp.payload) return
			showToast("Safety Plan reviewed successfully.", "positive")
			setIsEditMode(false)
			setIsFormDirty(false)
			return
		}

		if (!file) {
			const resp = await formCreate.mutate({
				input: {
					clientID: formData.client[0].id,
					content,
					date: formData.date,
					type: FormType.SafetyPlan,
					name: `${FormTypeName(FormType.SafetyPlan)} Form | ${formData.client[0].firstName} ${
						formData.client[0].lastName
					} | ${formData.date.toLocaleDateString(undefined, {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					})}`,
					reviewRequired,
				},
			})
			if (resp.error || !resp.payload) return
			setIsFormDirty(false)
			showToast("Safety Plan created successfully.", "positive")
			history.push(`/portal/clients/${formData.client[0].id}#forms`)
			return
		}

		const resp = await formCreateWithSignature.mutate({
			signature: file || undefined,
			input: {
				clientID: formData.client[0].id,
				content,
				date: formData.date,
				type: FormType.SafetyPlan,
				name: `${FormTypeName(FormType.SafetyPlan)} Form | ${formData.client[0].firstName} ${formData.client[0].lastName} | ${formData.date.toLocaleDateString(
					undefined,
					{
						day: "numeric",
						month: "numeric",
						year: "numeric",
					},
				)}`,
				reviewRequired,
			},
		})

		if (resp.error || !resp.payload) return
		setIsFormDirty(false)
		showToast("Safety Plan created successfully.", "positive")
		history.push(`/portal/clients/${formData.client[0].id}#forms`)
	}

	const buildSignatureFile = (): File | null => {
		if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) return null

		const arr = signatureCanvasRef.current.toDataURL().split(",")
		const type = arr[0].match(/:(.*?);/)
		if (!type) return null

		const mime = type[1]
		const bstr = atob(arr[1])
		let n = bstr.length
		const u8arr = new Uint8Array(n)

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n)
		}

		const file = new File([u8arr], "signature.png", { type: mime })

		return file
	}

	const parsedForm = parseFormData(formMethods.getValues())
	const worker = parsedForm && (parsedForm.worker as User | null)
	const client = parsedForm && (parsedForm.client as ClientDetail | null)
	const date = parsedForm && (parsedForm.date as Date | null)

	const review = async () => {
		if (!formID) return
		const resp = await formReview.mutate(formID)
		if (resp.error || !resp.payload) return
		if (currentUser) setReviewRecords((rr) => rr.concat({ date: moment().toString(), worker: currentUser }))
	}
	const continueOrSaveButton = () => {
		if (!formID)
			return (
				<ZenButton width="100px" type="submit" isLoading={isLoading}>
					Submit
				</ZenButton>
			)

		if (!isEditMode) return null

		return (
			<ZenButton width="100px" type="submit" isLoading={isLoading}>
				Save
			</ZenButton>
		)
	}
	// handle form dirty state
	const [isFormDirty, setIsFormDirty] = React.useState(false)
	React.useEffect(() => {
		if (formMethods.formState.isDirty || formMethods.formState.isSubmitting) {
			setIsFormDirty(true)
		}
	}, [formMethods.formState.isDirty, formMethods.formState.isSubmitting])

	React.useEffect(() => {
		if (formMethods.formState.isSubmitted) {
			setIsFormDirty(false)
		}
	}, [formMethods.formState.isSubmitted])

	const clientSignature = () => {
		return (
			<div className={signaturePad}>
				<div className={padInner}>
					<div className={signaturePadTitle}>
						<LabelMedium>Client Signature</LabelMedium>
						{!formID && (
							<div className={editButton}>
								{/* clear button */}
								<TooltipButton
									onClick={(e) => {
										e.stopPropagation()
										if (!signatureCanvasRef.current) return
										signatureCanvasRef.current.clear()
									}}
									type="button"
									tooltip={"clear"}
									iconName={"redo"}
								/>
							</div>
						)}
						{formID && (
							<div className={editButton}>
								{/* resign button */}
								<TooltipButton
									onClick={(e) => {
										e.stopPropagation()
										// trigger signature modal
										setOpenSignatureModal(true)
									}}
									type="button"
									tooltip={formGet.payload?.signatureURL ? "re-sign" : "sign"}
									iconName={"edit"}
								/>
							</div>
						)}
					</div>
					{!formID && (
						<SignatureCanvas
							backgroundColor="transparent"
							canvasProps={{ height: 100, width: 400 }}
							ref={(ref) => {
								signatureCanvasRef.current = ref
							}}
						/>
					)}
					{formID &&
						(formGet.payload?.signatureURL ? (
							<img draggable="false" alt="client_signature" height="100px" width="400px" src={formGet.payload?.signatureURL} />
						) : (
							<div className={noSignaturePlaceholder}>
								<LabelSmall color="white">No Signature</LabelSmall>
							</div>
						))}
				</div>
			</div>
		)
	}

	const outer = css({
		display: "flex",
		maxHeight: "100%",
		width: "100%",
		overflowY: "auto",
		"@media only screen and (max-width: 1400px)": {
			flexDirection: "column-reverse",
		},
		[`@media print`]: {
			maxHeight: "unset",
		},
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		position: isLoading ? "relative" : "unset",
		opacity: isLoading ? 0.5 : 1,
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})
	const group = css({
		display: "flex",
		width: "100%",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
			marginBottom: "10px",
		},
		[`@media print`]: {
			flexDirection: "column",
			marginBottom: 0,
			display: "none",
		},
	})
	const groupPrint = css({
		display: "none",

		[`@media print`]: {
			display: "flex",
			marginTop: "20px",
			marginBottom: "20px",
			flexDirection: "column",
			paddingTop: "30px",
			paddingBottom: "30px",
			paddingTRight: "10px",
			paddingLeft: "10px",
		},
	})

	const title = css({
		display: "flex",
		width: "100%",
		justifyContent: "space-between",

		[`@media print`]: {
			display: "none",
		},
	})

	const titlePrint = css({
		display: "none",
		[`@media print`]: {
			display: "block",
			backgroundColor: ZenTheme.colors.primaryGreen,
			color: "white !important",
			paddingTop: "30px",
			paddingBottom: "30px",
			paddingTRight: "10px",
			paddingLeft: "10px",
		},
	})
	const scrollingDiv = css({
		flex: 1,
		minHeight: 0,
		width: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		paddingRight: "8px",
		height: "100%",
		"@media only screen and (max-width: 1400px)": {
			overflowY: "hidden",
		},
	})
	const addMoreButton = css({
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
	})
	const reviewPanel = css({
		maxWidth: "450px",
		marginLeft: "15px",
		height: "100%",
		"@media only screen and (max-width: 1400px)": {
			maxWidth: "100%",
			marginLeft: 0,
			marginBottom: "10px",
		},
		[`@media print`]: {
			display: "none",
		},
	})
	const reviewPanelCard = css({
		maxHeight: "100%",
		overflowY: "auto",
		overflowX: "auto",
	})
	const buttonGroup = css({
		display: "flex",
		justifyContent: "space-between",
		marginTop: "10px",
		[`@media print`]: {
			display: "none",
		},
	})
	const editButton = css({
		[`@media print`]: {
			display: "none",
		},
	})

	const contactsBox = css({
		[`@media print`]: {
			border: "1px solid black",
			paddingTop: "30px",
			paddingBottom: "30px",
			paddingTRight: "10px",
			paddingLeft: "10px",
		},
	})
	const professionalsBox = css({
		[`@media print`]: {
			border: "1px solid black",
			paddingTop: "30px",
			paddingBottom: "30px",
			paddingTRight: "10px",
			paddingLeft: "10px",
			marginTop: "20px",
			marginBottom: "20px",
		},
	})

	const linesBox = css({
		[`@media print`]: {
			border: "3px dashed " + ZenTheme.colors.primaryGreen,
			paddingTop: "30px",
			paddingBottom: "30px",
			paddingTRight: "10px",
			paddingLeft: "10px",
			marginTop: "20px",
			marginBottom: "20px",
		},
	})

	const signaturePad = css({
		display: "flex",
		width: "100%",
		height: "140px",
		minHeight: "0",
		marginTop: "30px",
		justifyContent: "flex-end",
	})

	const signaturePadTitle = css({
		display: "flex",
		width: "100%",
		alignItems: "center",
	})

	const padInner = css({
		display: "flex",
		flexDirection: "column",
		width: "400px",
		minWidth: "400px",
		height: "100%",
		marginRight: "20px",
		borderBottom: "2px solid black",
	})

	const noSignaturePlaceholder = css({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
		backgroundColor: ZenTheme.colors.primaryGrey,
		opacity: 0.5,
	})

	return (
		<FormProvider {...formMethods}>
			<div className={outer} id="safety-plan-print">
				<ZenCard>
					<form autoComplete="off" className={formStyle} onSubmit={formMethods.handleSubmit(onSubmit)}>
						<div>
							<h1 className={titlePrint}>INT 025 - Safety Plan</h1>

							<div className={title}>
								<LabelLarge
									overrides={{
										Block: {
											style: {
												[`@media print`]: {
													color: "white",
													fontsize: "600px !important",
												},
											},
										},
									}}
								></LabelLarge>
								{!!formID && !isEditMode && (
									<div className={editButton}>
										<ZenButton
											onClick={() => {
												// save current status
												setCachedFormData(parseFormData(formMethods.getValues()))
												setIsEditMode(true)
												setIsFormDirty(true)
											}}
											height="38px"
											width="100px"
										>
											Edit
										</ZenButton>
										<ZenButton
											type="button"
											onClick={() => {
												window.print()
											}}
											height="38px"
											width="100px"
										>
											Print
										</ZenButton>
									</div>
								)}
							</div>
							{isLoading && (
								<div className={loadingIcon}>
									<StyledSpinnerNext />
								</div>
							)}
							<div className={group}>
								<ZenUserSelect
									label="Worker"
									formRef={formMethods.control}
									formName="worker"
									clearable={false}
									disabled={!!formID && !isEditMode}
									width="100%"
									inputError={formMethods.errors.worker}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
										},
									}}
								/>
								<Spacer style={{ width: "40px" }} />
								<ZenClientSelect
									label="Client"
									formRef={formMethods.control}
									formName="client"
									disabled={!!formID && !isEditMode}
									clearable={false}
									width="100%"
									inputError={formMethods.errors.client}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length > 0) || "Client is required",
										},
									}}
								/>
								<Spacer style={{ width: "40px" }} />
								<Block width="100%">
									<ZenDatePicker
										label="Date"
										formRef={formMethods.control}
										disabled={!!formID && !isEditMode}
										formName="date"
										paddingTop="11px"
										formRules={{
											required: "Date is required",
										}}
									/>
								</Block>
							</div>

							<div className={groupPrint}>
								<LabelLarge>{worker && `${worker.firstName} ${worker.lastName}`}</LabelLarge>
								<LabelLarge>{client && `${client.firstName} ${client.lastName}`}</LabelLarge>
								<LabelLarge>{date && friendlyDate(date, timezone)}</LabelLarge>
							</div>
						</div>
						<div className={scrollingDiv}>
							{questionList.map((q, i) => (
								<React.Fragment key={i}>
									<LabelMedium
										overrides={{
											Block: {
												style: {
													[`@media print`]: {
														backgroundColor: "#78ad2a",
														padding: "10px",
														color: "white",
														clipPath: "polygon(0 0, 100% 0, 97% 100%, 0% 100%);",
													},
												},
											},
										}}
									>
										{q.label}
									</LabelMedium>
									<LabelSmall>{q.caption}</LabelSmall>
									<Divider style={{ backgroundColor: "transparent" }} />
									{q.placeholder.map((p, index) => (
										<SafetyPlanTextArea
											getValues={formMethods.getValues}
											key={p}
											disabled={!!formID && !isEditMode}
											label={`${index + 1}. `}
											name={`${q.label}-${p}`}
											clear={
												index > 0
													? () =>
															setQuestionList(
																questionList.map((ques) => {
																	if (q.label !== ques.label) return ques
																	// remove index
																	const tempQ = { ...ques }
																	tempQ.placeholder = tempQ.placeholder.filter((ph) => ph !== p)
																	return tempQ
																}),
															)
													: undefined
											}
										/>
									))}
									{isEditMode && (
										<div className={addMoreButton}>
											<ZenButton
												type="button"
												onClick={() =>
													setQuestionList(
														questionList.map((ques) => {
															if (q.label !== ques.label) return ques
															// push index
															const tempQ = { ...ques }
															tempQ.placeholder.push(tempQ.nextIndex)
															tempQ.nextIndex += 1
															return tempQ
														}),
													)
												}
											>
												Add More Detail
											</ZenButton>
										</div>
									)}
									<Divider style={{ backgroundColor: "transparent" }} />
								</React.Fragment>
							))}
							<LabelMedium
								overrides={{
									Block: {
										style: {
											display: "none",
											[`@media print`]: {
												display: "block",
												backgroundColor: "#78ad2a",
												padding: "10px",
												color: "white",
												clipPath: "polygon(0 0, 100% 0, 97% 100%, 0% 100%);",
												marginBottom: "10px",
											},
										},
									},
								}}
							>
								Contacts
							</LabelMedium>
							<div className={contactsBox}>
								<LabelMedium marginBottom="8px">Friend(s), family, carers, neighbours who I can contact:</LabelMedium>
								{peopleCanContactIndexHolder.map((pci, i) => (
									<SafetyPlanPeopleContact
										key={i}
										fieldIndex={pci}
										disabled={!!formID && !isEditMode}
										fieldPrefix="peopleCanContact"
										clear={i > 0 ? () => setPeopleCanContactIndexHolder((pcc) => pcc.filter((p) => p !== pci)) : undefined}
									/>
								))}
							</div>

							{isEditMode && (
								<div className={addMoreButton}>
									<ZenButton
										type="button"
										onClick={() => {
											setPeopleCanContactIndexHolder(peopleCanContactIndexHolder.concat(peopleToContactNextIndex))
											setPeopleCanContactNextIndex((i) => i + 1)
										}}
										marginTop="5px"
										marginBottom="5px"
									>
										Add More People
									</ZenButton>
								</div>
							)}

							<div className={professionalsBox}>
								<LabelMedium>Professionals and agencies I can contact for help in an emergency:</LabelMedium>
								<LabelSmall marginBottom="8px">(Examples: mental health professionals, community services)</LabelSmall>
								{professionalIndexHolder.map((pci, i) => (
									<SafetyPlanPeopleContact
										key={i}
										fieldIndex={pci}
										disabled={!!formID && !isEditMode}
										fieldPrefix="professionalContact"
										nameLabel="Professional"
										clear={i > 0 ? () => setProfessionalIndexHolder((pi) => pi.filter((p) => p !== pci)) : undefined}
									/>
								))}
							</div>

							{isEditMode && (
								<div className={addMoreButton}>
									<ZenButton
										type="button"
										onClick={() => {
											setProfessionalIndexHolder(professionalIndexHolder.concat(professionalNextIndex))
											setProfessionalNextIndex((i) => i + 1)
										}}
										marginTop="5px"
										marginBottom="5px"
									>
										Add More
									</ZenButton>
								</div>
							)}

							<LabelMedium
								overrides={{
									Block: {
										style: {
											display: "none",
											[`@media print`]: {
												display: "block",
												backgroundColor: "#78ad2a",
												padding: "10px",
												color: "white",
												clipPath: "polygon(0 0, 100% 0, 97% 100%, 0% 100%);",
												marginBottom: "10px",
											},
										},
									},
								}}
							>
								Helplines
							</LabelMedium>

							<div className={linesBox}>
								<LabelMedium>- Mental Health Emergency Response Line (MHERL) 1300 555 788</LabelMedium>
								<LabelMedium>- Lifeline 13 11 14</LabelMedium>
								<LabelMedium>- Kids Helpline 1800 551 800</LabelMedium>
								<LabelMedium>- Suicide Call Back Line 1300 659 467</LabelMedium>
								<LabelMedium>- Emergency 000</LabelMedium>
								<LabelMedium>When in distress or crisis, I agree to use this safety plan to help myself keep safe and well.</LabelMedium>
							</div>

							{formGet.payload?.reviewRecords && formGet.payload.reviewRecords.length > 0 && <ReviewHistory records={formGet.payload.reviewRecords} />}
							{clientSignature()}
						</div>
						{formEnableReview.error && <ErrorNotification messageOrPayload={formEnableReview.payload} />}
						{formDisableReview.error && <ErrorNotification messageOrPayload={formDisableReview.payload} />}
						{formCreate.error && <ErrorNotification messageOrPayload={formCreate.payload} />}
						{formReview.error && <ErrorNotification messageOrPayload={formReview.payload} />}
						{formCreateWithSignature.error && <ErrorNotification messageOrPayload={formCreateWithSignature.payload} />}
						<ErrorFieldTracker errorIDs={Object.keys(formMethods.errors)} />
						<div className={buttonGroup}>
							<ZenButton
								type="button"
								onClick={() => {
									if (isEditMode && formID) {
										// set back to cached data
										if (cachedFormData) {
											// clear up data
											setFormData(cachedFormData)
										}
										setIsEditMode(false)
										setIsFormDirty(false)
										return
									}
									history.goBack()
								}}
								disabled={isLoading}
								altKind="secondary"
								width="100px"
							>
								{!!formID && !isEditMode ? "Exit" : "Cancel"}
							</ZenButton>
							{continueOrSaveButton()}
						</div>
						<Prompt when={isFormDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
					</form>
					{formID && (
						<ZenModal
							isOpen={openSignatureModal}
							size="auto"
							role="dialog"
							onClose={() => {
								signatureCanvasRef.current?.clear()
								setOpenSignatureModal(false)
							}}
						>
							<ModalHeader>
								<div className={signaturePadTitle}>
									<LabelMedium>Client Signature</LabelMedium>
									<TooltipButton
										onClick={(e) => {
											e.stopPropagation()
											if (!signatureCanvasRef.current) return
											signatureCanvasRef.current.clear()
										}}
										type="button"
										tooltip={"clear"}
										iconName={"redo"}
									/>
								</div>
							</ModalHeader>
							<ModalBody>
								<div className={padInner}>
									<SignatureCanvas
										backgroundColor="transparent"
										canvasProps={{ height: 100, width: 400 }}
										ref={(ref) => {
											signatureCanvasRef.current = ref
										}}
									/>
								</div>
								<CancelAndSaveButtons
									cancelFn={() => {
										signatureCanvasRef.current?.clear()
										setOpenSignatureModal(false)
									}}
									saveFn={() => {
										const file = buildSignatureFile()
										if (!file) return

										formSignatureUpdate.mutate({ id: formID, signature: file }).then((resp) => {
											if (resp.error || !resp.payload) return
											signatureCanvasRef.current?.clear()
											formGet.query(formID)
											setOpenSignatureModal(false)
										})
									}}
									isLoading={formSignatureUpdate.loading}
								/>
								{formSignatureUpdate.error && <ErrorNotification messageOrPayload={formSignatureUpdate.payload} />}
							</ModalBody>
						</ZenModal>
					)}
				</ZenCard>
				<div className={reviewPanel}>
					<ZenCard className={reviewPanelCard}>
						<Block width="100%" display="flex" justifyContent="space-between" alignItems="center">
							<LabelMedium>Review Record:</LabelMedium>
							<Checkbox
								checkmarkType="toggle_round"
								checked={reviewRequired}
								onChange={(e) => toggleFormReview(e.currentTarget.checked)}
								overrides={{
									Toggle: {
										style: ({ $checked }) => ({
											backgroundColor: $checked ? ZenTheme.colors.primaryGreen : "white",
										}),
									},
								}}
							>
								Turn on reminder
							</Checkbox>
						</Block>
						{reviewRequired && (
							<LabelSmall color={ZenTheme.colors.primaryGrey}>A notification will be sent to assigned workers to review the form in three months</LabelSmall>
						)}
						<Divider style={{ backgroundColor: "transparent" }} />
						{reviewRecords.map((rr, i) => (
							<div className={group} key={i}>
								<DisplayLabel marginTop={0} label={i > 0 ? "" : "Date"}>
									{moment(rr.date).format("DD/MM/YYYY")}
								</DisplayLabel>
								<Spacer style={{ width: "40px" }} />
								<DisplayLabel marginTop={0} label={i > 0 ? "" : "Completed with"}>
									{<DisplaySelectCard option={{ ...rr.worker, label: `${rr.worker.firstName} ${rr.worker.lastName}` }} />}
								</DisplayLabel>
							</div>
						))}
						<ZenButton type="button" onClick={review} disabled={isLoading} width="150px">
							Confirm Review
						</ZenButton>
					</ZenCard>
				</div>
			</div>
		</FormProvider>
	)
}

interface SafetyPlanTextAreaProps {
	label: string
	name: string
	disabled?: boolean
	clear?: () => void
	getValues: () => any
}
const SafetyPlanTextArea = (props: SafetyPlanTextAreaProps) => {
	const { label, name, disabled, clear, getValues } = props
	const { control, errors } = useFormContext()
	const [css] = useStyletron()
	const container = css({
		border: disabled ? "none" : "2px solid black",
		borderRadius: "5px",
		display: "flex",
		padding: disabled ? 0 : "6px 0 6px 6px",
		marginBottom: disabled ? 0 : "8px",
		position: clear ? "relative" : "unset",
	})
	const deleteIcon = css({
		position: "absolute",
		right: "8px",
		top: "4px",
		cursor: "pointer",
	})
	const group = css({
		display: "flex",
		width: "100%",
		[`@media print`]: {
			border: "1px solid black",
			marginBottom: "10px",
			padding: "20px",
		},
	})

	return (
		<>
			{errors[name] && <LabelSmall color="red">{errors[name].message}</LabelSmall>}
			<div className={container}>
				{!!clear && !disabled && (
					<div className={deleteIcon} onClick={clear}>
						<FontAwesomeIcon icon={["fal", "times-circle"]} />
					</div>
				)}
				{disabled ? (
					<div className={group}>
						<LabelMedium
							paddingTop="3px"
							marginRight="5px"
							overrides={{
								Block: {
									style: {
										[`@media print`]: {
											color: "black",
										},
									},
								},
							}}
							color={ZenTheme.colors.primaryGrey}
						>
							{label}
						</LabelMedium>
						<LabelMedium
							color={ZenTheme.colors.primaryGrey}
							paddingTop="3px"
							marginRight="5px"
							overrides={{
								Block: {
									style: {
										whiteSpace: "pre-wrap",
										[`@media print`]: {
											color: "black",
										},
									},
								},
							}}
						>
							{getValues()[name]}
						</LabelMedium>
					</div>
				) : (
					<>
						<LabelMedium paddingTop="3px" marginRight="5px">
							{label}
						</LabelMedium>
						<Controller
							id={name}
							name={name}
							disabled={disabled}
							control={control}
							as={Textarea}
							defaultValue=""
							placeholder=" "
							rules={{
								required: "Detail is required",
							}}
							overrides={{
								InputContainer: {
									style: {
										backgroundColor: "white",
									},
								},
								Input: {
									style: {
										paddingTop: 0,
										paddingBottom: 0,
										paddingRight: 0,
										paddingLeft: 0,
									},
								},
							}}
						/>
					</>
				)}
			</div>
		</>
	)
}

interface SafetyPlanPeopleContactProps {
	fieldIndex: number // used in field name
	fieldPrefix: string
	nameLabel?: string
	disabled?: boolean
	clear?: () => void
}
const SafetyPlanPeopleContact = (props: SafetyPlanPeopleContactProps) => {
	const { fieldIndex, fieldPrefix, nameLabel, clear, disabled } = props
	const { control, errors, getValues } = useFormContext()

	const name = getValues(`${fieldPrefix}Name-${fieldIndex}`)
	const relationship = getValues(`${fieldPrefix}Relationship-${fieldIndex}`)
	const phone = getValues(`${fieldPrefix}PhoneNumber-${fieldIndex}`)

	const [css] = useStyletron()
	const container = css({
		width: "100%",
		display: "flex",
		position: clear ? "relative" : "unset",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const deleteIcon = css({
		position: "absolute",
		right: "8px",
		top: "4px",
		cursor: "pointer",
	})
	const hideOnPrint = css({
		display: "flex",
		[`@media print`]: {
			display: "none",
		},
	})
	const printView = css({
		display: "none",
		[`@media print`]: {
			display: "block",
		},
	})
	return (
		<div className={container}>
			{!!clear && !disabled && (
				<div className={deleteIcon} onClick={clear}>
					<FontAwesomeIcon icon={["fal", "times-circle"]} />
				</div>
			)}
			<div className={printView}>
				<LabelMedium>
					{nameLabel || "Name"}: {name}
				</LabelMedium>
				<LabelMedium>Relationship: {relationship}</LabelMedium>
				<LabelMedium>Phone Number: {phone}</LabelMedium>
			</div>
			<div className={hideOnPrint}>
				<ZenInput
					width="100%"
					label={nameLabel || "Name"}
					required
					formRef={control}
					disabled={disabled}
					nameRef={`${fieldPrefix}Name-${fieldIndex}`}
					inputError={errors[`${fieldPrefix}Name-${fieldIndex}`]}
				/>
				<Spacer style={{ width: "40px" }} />
				<div style={{ width: "100%" }}>
					<ZenInput
						width="100%"
						label="Relationship"
						required
						formRef={control}
						disabled={disabled}
						nameRef={`${fieldPrefix}Relationship-${fieldIndex}`}
						inputError={errors[`${fieldPrefix}Relationship-${fieldIndex}`]}
					/>
				</div>
				<Spacer style={{ width: "40px" }} />
				<ZenInput
					width="100%"
					label="Phone Number"
					formRef={control}
					disabled={disabled}
					formRules={{
						required: "Phone Number is required",
					}}
					nameRef={`${fieldPrefix}PhoneNumber-${fieldIndex}`}
					inputError={errors[`${fieldPrefix}PhoneNumber-${fieldIndex}`]}
				/>
			</div>
		</div>
	)
}
