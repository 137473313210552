import * as React from "react"
import { useStyletron } from "baseui"
import { useQuery } from "react-fetching-library"
import { RouteComponentProps } from "react-router-dom"

import { ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { Loading } from "../../components/loading"
import { TravelForm } from "../../components/travels/travelForm"
import { TravelDetail } from "../../components/travels/travelView"
import { fetching } from "../../fetching"
import { Travel } from "../../types/types"

export const TravelViewWithRoute = (props: RouteComponentProps<{ id: string }>) => {
	const id = props.match.params.id
	return <TravelView id={id} />
}

export const TravelView = (props: {
	id: string
	refetchTimesheet?: () => void
	refetchMileageClaim?: () => void
	setSelectedTravel?: React.Dispatch<React.SetStateAction<Travel | null>>
}) => {
	const id = props.id
	const [editMode, setEditMode] = React.useState<boolean>(false)
	const { payload: travelData, loading: travelLoading, query, error } = useQuery<Travel>(fetching.query.getTravel(id))

	const [css] = useStyletron()
	const viewContainer = css({
		overflowY: "auto",
		height: "100%",
	})
	const editContainer = css({
		overflowY: "auto",
		width: "fit-content",
		height: "100%",
	})

	const onSuccess = async () => {
		await query()
		setEditMode(false)
	}

	if (error) return <ErrorNotification messageOrPayload={travelData} />
	if (travelLoading || !travelData) return <Loading />
	if (editMode)
		return (
			<ZenCard className={editContainer}>
				<TravelForm
					refetchTimesheet={props.refetchTimesheet}
					refetchMileageClaim={props.refetchMileageClaim}
					travel={travelData}
					onSuccess={onSuccess}
					onCancel={() => setEditMode(false)}
				/>
			</ZenCard>
		)

	return (
		<ZenCard className={viewContainer}>
			<TravelDetail
				setEditMode={setEditMode}
				travelData={travelData}
				refetch={query}
				setSelectedTravel={props.setSelectedTravel}
				refetchMileageClaim={props.refetchMileageClaim}
				refetchTimesheet={props.refetchTimesheet}
			/>
		</ZenCard>
	)
}
