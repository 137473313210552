import * as React from "react"
import { useStyletron } from "styletron-react"
import { ZenToggleButtonOverrides } from "../../themeOverrides"
import { Controller, Control } from "react-hook-form"
import { Button } from "baseui/button"
import { Spaced } from "../spaced"

interface YesNoSelectionProps {
	name: string
	control: Control<any>
	disabled?: boolean
	/** Show this element when the value === true */
	showOnYes?: React.ReactNode
	/** Show this element when the value === false */
	showOnNo?: React.ReactNode
	/** Include N/A Option */
	incNA?: boolean
}

export const YesNoSelection = (props: YesNoSelectionProps) => {
	const [css] = useStyletron()
	const valuePreview = css({
		color: "#545454",
		padding: "10px 14px",
	})

	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ value, onChange }) => (
				<>
					{props.disabled ? (
						<div className={valuePreview}>{value === true ? "Yes" : "No"}</div>
					) : (
						<Spaced>
							<Button overrides={ZenToggleButtonOverrides()} type="button" isSelected={value === true} onClick={() => onChange(true)}>
								Yes
							</Button>
							<Button overrides={ZenToggleButtonOverrides()} type="button" isSelected={value === false} onClick={() => onChange(false)}>
								No
							</Button>
							{props.incNA && (
								<Button overrides={ZenToggleButtonOverrides()} type="button" isSelected={value === undefined} onClick={() => onChange(undefined)}>
									N/A
								</Button>
							)}
						</Spaced>
					)}
					{value === true && props.showOnYes}
					{value === false && props.showOnNo}
				</>
			)}
		/>
	)
}

interface ButtonSelectionOptions {
	name: string
	color?: string
	textColor?: string
}

export const ExtremeToLowOptions: ButtonSelectionOptions[] = [
	{ name: "Extreme", color: "#F70000" },
	{ name: "High", color: "#FF6C00" },
	{ name: "Medium", color: "#FF9300" },
	{ name: "Low", color: "#FFEB00", textColor: "#1A1A1A" },
]

interface ButtonSelectionProps {
	name: string
	control: Control<any>
	disabled?: boolean
	required?: boolean
	options: ButtonSelectionOptions[]
}

export const ButtonSelection = (props: ButtonSelectionProps) => {
	const [css] = useStyletron()
	const valuePreview = css({
		color: "#545454",
		padding: "10px 14px",
	})

	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.required ? { required: { value: true, message: "Required" } } : undefined}
			render={({ value, onChange }) =>
				props.disabled ? (
					<div className={valuePreview}>{value}</div>
				) : (
					<Spaced>
						{props.options.map((option) => (
							<Button
								key={`select-option-${option.name}`}
								overrides={ZenToggleButtonOverrides(option.color, {
									...(option.textColor && { color: option.textColor }),
									":hover": {
										backgroundColor: option.color,
										color: option.textColor || "white",
									},
								})}
								type="button"
								isSelected={value === option.name}
								onClick={() => onChange(option.name)}
							>
								{option.name}
							</Button>
						))}
					</Spaced>
				)
			}
		/>
	)
}

/** For passing into a react-hook-forms Controller.
 *
 * usage:
 * ```typescript
 * 	as={disabled ? undefined : Textarea}
 * 	render={FormValuePreview(disabled)}
 * 	```
 */
export const FormValuePreview = (show?: boolean) => {
	const [css] = useStyletron()

	const valuePreview = css({
		color: "#545454",
		padding: "10px 14px",
	})

	return !show ? undefined : ({ value }: any) => <div className={valuePreview}>{value instanceof Date ? value.toLocaleDateString("en-AU") : value}</div>
}
