import * as React from "react"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { OptionProfile } from "baseui/menu"
import { ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal"
import { Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { StyleObject } from "styletron-react"
import { AuthContainer } from "../../../controllers/auth"
import { PortalContainer } from "../../../controllers/portal"
import { fetching } from "../../../fetching"
import { ZenTheme } from "../../../themeOverrides"
import { FilterBy } from "../../../types/enums"
import { ClientDetail, RemovedAssignedWorker, RolePermission, SortOrder, User } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { SearchAndFilter } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { DisplayLabel } from "../../sessions/newClientAppointment/singleSessionConfirmation"
import { TooltipButton } from "../../tooltipButton"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenTextArea } from "../../zenComponents/zenInput"
import { ClientOverviewBasicProps } from "./clientOverview"
import { routes } from "../../../routes"

export const WorkerInfo = (props: ClientOverviewBasicProps) => {
	const [css] = useStyletron()
	const container = css({
		height: "100%",
		width: "100%",
		paddingBottom: "15px",
		overflow: "hidden",
	})

	const scrollingDiv = css({
		maxHeight: "95%",
		overflowY: "auto",
		marginTop: "8px",
	})
	const title = css({
		display: "flex",
		height: "fit-content",
		justifyContent: "space-between",
		alignItems: "center",
	})

	const { client, refetch } = props
	const { assignedWorkers } = client
	const history = useHistory()
	const { hasPermission } = AuthContainer.useContainer()
	const [openAssignModal, setOpenAssignModal] = React.useState<boolean>(false)
	const [openHistoryModal, setOpenHistoryModal] = React.useState<boolean>(false)
	const [onRemove, setOnRemove] = React.useState<boolean>(false)
	const canUpdateClient = hasPermission(RolePermission.ClientUpdate)

	return (
		<div className={container}>
			<div className={title}>
				<LabelMedium>Assigned Workers</LabelMedium>
				<div>
					{canUpdateClient && (
						<TooltipButton
							onClick={() => {
								setOnRemove(false)
								setOpenAssignModal(true)
							}}
							tooltip={"Assign workers"}
							iconName={"plus"}
						/>
					)}
					<TooltipButton
						onClick={() => {
							setOnRemove(true)
							setOpenAssignModal(true)
						}}
						tooltip={!canUpdateClient ? "Unassign yourself from client" : "Remove workers"}
						iconName={"trash-alt"}
					/>
					{canUpdateClient && <TooltipButton onClick={() => setOpenHistoryModal(true)} tooltip={"View assignment history"} iconName={"history"} />}
				</div>
			</div>
			<div className={scrollingDiv}>
				{assignedWorkers &&
					assignedWorkers.length > 0 &&
					assignedWorkers.map((w, i) => (
						<div key={i} onClick={() => (hasPermission(RolePermission.UserRead) ? history.push(`/portal/workers/${w.id}`) : null)}>
							<OptionProfile
								item={w}
								getProfileItemImg={(t: User) => () =>
									(
										<Avatar
											name={`${t.firstName} ${t.lastName}`}
											src={t.avatarURL || ""}
											overrides={{
												Root: {
													style: {
														minWidth: ZenTheme.sizing.scale1000,
													},
												},
											}}
										/>
									)}
								getProfileItemImgText={(t: User) => `${t.firstName} ${t.lastName}`}
								getProfileItemLabels={(t: User) => ({
									title: `${t.firstName} ${t.lastName}`,
									subtitle: t.department.name,
									body: t.position.name,
								})}
								overrides={{
									ListItemProfile: {
										style: {
											paddingTop: "3px",
											paddingBottom: "3px",
											paddingLeft: "1px",
											paddingRight: "2px",
											cursor: hasPermission(RolePermission.UserRead) ? "cursor" : "default",
										},
									},
									ProfileImgContainer: {
										style: {
											height: "40px",
											width: "40px",
										},
									},
									ProfileLabelsContainer: {
										style: {
											marginLeft: "8px",
										},
									},
								}}
							/>
						</div>
					))}
			</div>
			<AssignRemoveWorkerModal
				isOpen={openAssignModal}
				setIsOpen={setOpenAssignModal}
				client={client}
				onRemove={onRemove}
				removeSelfOnly={!canUpdateClient} // general worker can only unassign themselves from the client
				refetch={refetch}
			/>
			<AssignWorkerHistory isOpen={openHistoryModal} setIsOpen={setOpenHistoryModal} clientID={client.id} />
		</div>
	)
}

interface AssignWorkerHistoryProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	clientID: string
}
const AssignWorkerHistory = (props: AssignWorkerHistoryProps) => {
	const { isOpen, setIsOpen, clientID } = props
	const [css] = useStyletron()
	const history = useHistory()
	const { payload, loading, error, query } = useQuery<RemovedAssignedWorker[]>(fetching.query.getClientWorkerHistoryByClientID(clientID), false)

	const onOpen = React.useCallback(() => {
		if (!isOpen) return
		query()
	}, [isOpen, query])
	React.useEffect(() => {
		onOpen()
	}, [onOpen])

	const displayResult = () => {
		if (loading || !payload) return <StyledSpinnerNext />
		if (error) return <ErrorNotification messageOrPayload={payload} />
		if (payload.length === 0) return <LabelMedium>No remove history</LabelMedium>
		return payload.map((h, i) => (
			<RemovedWorkerCard
				{...h}
				key={i}
				index={i}
				onWorkerClick={(id: string) => {
					setIsOpen(false)
					history.push(`/portal/workers/${id}`)
				}}
			/>
		))
	}
	const container = css({
		height: "500px",
		width: "400px",
		maxHeight: "500px",
		overflowY: "auto",
		paddingRight: "5px",
	})
	return (
		<ZenModal onClose={() => setIsOpen(false)} isOpen={isOpen} role={ROLE.dialog} size={SIZE.auto}>
			<ModalHeader>Removed workers history</ModalHeader>
			<ModalBody>
				<div className={container}>{displayResult()}</div>
			</ModalBody>
		</ZenModal>
	)
}

interface RemovedWorkerCardProps extends RemovedAssignedWorker {
	onWorkerClick: (id: string) => void
	index: number
}
const RemovedWorkerCard = (props: RemovedWorkerCardProps) => {
	const { worker, reason, deletedAt, archivedByUser, onWorkerClick, index } = props
	const { timezone } = PortalContainer.useContainer()
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		borderRadius: "3px",
		backgroundColor: index % 2 === 0 ? ZenTheme.colors.divider : "white",
		paddingLeft: "8px",
		paddingRight: "8px",
	})
	const group = css({
		display: "flex",
	})
	return (
		<div className={container}>
			{/* worker info */}
			<DisplayLabel label="Worker" marginTop={0} marginBottom={0}>
				<div onClick={() => onWorkerClick(worker.id)}>
					<OptionProfile
						item={worker}
						getProfileItemImg={(t: User) => () =>
							(
								<Avatar
									name={`${t.firstName} ${t.lastName}`}
									overrides={{
										Root: {
											style: {
												minWidth: ZenTheme.sizing.scale1000,
											},
										},
									}}
									src={t.avatarURL || ""}
								/>
							)}
						getProfileItemImgText={(t: User) => `${t.firstName} ${t.lastName}`}
						getProfileItemLabels={(t: User) => ({
							title: `${t.firstName} ${t.lastName}`,
							subtitle: t.department.name,
							body: t.position.name,
						})}
						overrides={{
							ListItemProfile: {
								style: {
									paddingTop: "3px",
									paddingBottom: "3px",
									paddingLeft: "1px",
									paddingRight: "2px",
								},
							},
							ProfileImgContainer: {
								style: {
									height: "40px",
									width: "40px",
								},
							},
							ProfileLabelsContainer: {
								style: {
									marginLeft: "8px",
								},
							},
							ProfileSubtitle: {
								style: {
									lineHeight: "8px",
								},
							},
						}}
					/>
				</div>
			</DisplayLabel>
			{/* Archived time */}
			<div className={group}>
				<DisplayLabel label="Removed By" marginTop={0}>
					<div onClick={() => onWorkerClick(archivedByUser.id)} style={{ cursor: "pointer" }}>
						<LabelMedium>{`${archivedByUser.firstName} ${archivedByUser.lastName}`}</LabelMedium>
					</div>
				</DisplayLabel>
				<DisplayLabel label="Removed At" marginTop={0}>
					<LabelMedium>{moment(deletedAt).tz(timezone.id).format("hh:mm a, DD/MM/YYYY")}</LabelMedium>
				</DisplayLabel>
			</div>
			{/* Reason */}
			<DisplayLabel label="Reason">
				<LabelMedium>{reason}</LabelMedium>
			</DisplayLabel>
		</div>
	)
}

interface AssignRemoveWorkerModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	client: ClientDetail
	onRemove: boolean
	removeSelfOnly: boolean
	refetch: () => Promise<QueryResponse<ClientDetail>>
}
// AssignRemoveWorkerModal show the model for user to select clients or workers to assign
const AssignRemoveWorkerModal = (props: AssignRemoveWorkerModalProps) => {
	const { isOpen, setIsOpen, client, onRemove, refetch, removeSelfOnly } = props
	const history = useHistory()
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const { currentUser } = AuthContainer.useContainer()
	const { handleSubmit, control, errors, reset } = useForm()
	const [idList, setIDList] = React.useState<string[]>(removeSelfOnly && currentUser ? [currentUser.id] : [])
	const [idListError, setIDListError] = React.useState<boolean>(false)
	const [searchKey, setSearchKey] = React.useState<string>("")
	const [filter, setFilter] = React.useState<Value>([{ label: FilterBy.Active, id: FilterBy.Active }])
	const [dataList, setDataList] = React.useState<User[]>([])
	const {
		payload: workerData,
		loading: workerLoading,
		query,
		error,
	} = useQuery<{ users: User[]; total: number }>(
		fetching.query.getManyUsers({
			search: {
				search: searchKey,
				filterBy: filter[0]?.id ? filter[0].id.toString() : FilterBy.Active,
				sortBy: "Name",
				sortDir: SortOrder.Ascending,
			},
			limit: 20,
			offset: 0,
			excludedID: client.assignedWorkers?.map<string>((aw) => aw.id),
		}),
		!onRemove,
	)
	const assignWorkerToClient = useMutation<User[]>(fetching.mutation.assignWorkerToClient)
	const removeAssignedWorker = useMutation<User>(fetching.mutation.removeAssignedWorker)

	const clearData = React.useCallback(() => {
		setSearchKey("")
		reset({
			reason: "",
		})
		setIDListError(false)
		setFilter([])
		setIDList([])
	}, [reset])

	const onClose = React.useCallback(() => {
		if (assignWorkerToClient.loading || removeAssignedWorker.loading) return
		clearData()
		setIsOpen(false)
	}, [setIsOpen, removeAssignedWorker.loading, assignWorkerToClient.loading, clearData])

	const displayTitle = () => {
		if (onRemove) {
			if (removeSelfOnly) {
				return "Unassign yourself from the client"
			}

			return "Remove Workers"
		}

		return "Assign Workers"
	}

	const onSave = async (formData: any) => {
		if (idList.length === 0) {
			setIDListError(true)
			return
		}

		// remove worker
		if (onRemove) {
			await removeAssignedWorker.mutate({
				clientID: client.id,
				removeWorkerID: idList[0],
				reason: formData.reason,
			})

			// redirect back to clients page, if it is a self removal
			if (removeSelfOnly) {
				history.replace(routes.clients.root)
				return
			}
			// refetch client data
			await refetch()
			showToast("Worker removed successfully.", "positive")
			return
		}

		// assign workers
		await assignWorkerToClient.mutate({
			clientID: client.id,
			workerIDList: idList,
		})
		// refetch client data
		await refetch()
		showToast("Worker(s) assigned to client successfully.", "positive")
	}

	// handle toggle selection card
	const onSelect = (uid: string) => {
		if (onRemove) {
			setIDList([uid])
			return
		}
		if (idList.includes(uid)) {
			setIDList((list) => list.filter((id) => id !== uid))
			return
		}
		setIDList(idList.concat(uid))
	}
	React.useEffect(() => {
		if (onRemove) {
			const list = client.assignedWorkers || []
			if (searchKey) {
				setDataList([...list].filter((l) => `${l.firstName} ${l.lastName}`.toLowerCase().includes(searchKey.toLowerCase())))
				return
			}
			setDataList(list || [])
			return
		}
		if (workerLoading || !workerData) return
		setDataList(workerData.users)
	}, [workerData, isOpen, onRemove, client.assignedWorkers, workerLoading, searchKey])

	React.useEffect(() => {
		if (!(assignWorkerToClient.payload || removeAssignedWorker.payload)) return
		query()
		onClose()
	}, [assignWorkerToClient.payload, removeAssignedWorker.payload, query, onClose])

	const isLoading = removeAssignedWorker.loading || assignWorkerToClient.loading

	const container = css({
		width: "500px",
		height: removeSelfOnly ? "fit-content" : "700px",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
		opacity: isLoading ? 0.5 : 1,
		position: isLoading ? "relative" : "unset",
	})
	const functionBar = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})
	const scrollingDiv = css({
		height: "100%",
		maxHeight: "100%",
		paddingRight: "16px",
		overflowX: "hidden",
		overflowY: "auto",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "10px",
	})

	const highlightedBorder: StyleObject = {
		borderTopWidth: "1px",
		borderTopStyle: "solid",
		borderTopColor: ZenTheme.colors.primaryGreen,

		borderBottomWidth: "1px",
		borderBottomStyle: "solid",
		borderBottomColor: ZenTheme.colors.primaryGreen,

		borderRightWidth: "1px",
		borderRightStyle: "solid",
		borderRightColor: ZenTheme.colors.primaryGreen,

		borderLeftWidth: "1px",
		borderLeftStyle: "solid",
		borderLeftColor: ZenTheme.colors.primaryGreen,

		borderTopRightRadius: "5px",
		borderTopLeftRadius: "5px",
		borderBottomRightRadius: "5px",
		borderBottomLeftRadius: "5px",
	}

	return (
		<ZenModal onClose={onClose} isOpen={isOpen} role={ROLE.dialog} size={SIZE.auto}>
			<ModalHeader>
				<div className={group}>
					<LabelLarge>{displayTitle()}</LabelLarge>
					{idListError && (
						<LabelLarge color={ZenTheme.colors.warning600} marginLeft="5px">
							{"- Please pick at least one worker"}
						</LabelLarge>
					)}
				</div>
				{!removeSelfOnly && ( // don't display if it is a self removal
					<div className={functionBar}>
						<SearchAndFilter search={searchKey} setSearch={setSearchKey} filter={filter} setFilter={setFilter} hideFilter />
					</div>
				)}
			</ModalHeader>
			<ModalBody>
				<form autoComplete="off" className={container} onSubmit={handleSubmit(onSave)}>
					{!removeSelfOnly && ( // don't display if it is a self removal
						<div className={scrollingDiv}>
							{dataList.length > 0 &&
								dataList.map((w: User, i) => (
									<div key={i} onClick={() => onSelect(w.id)}>
										<OptionProfile
											item={w}
											getProfileItemImg={(t: User) => () =>
												(
													<Avatar
														name={`${t.firstName} ${t.lastName}`}
														overrides={{
															Root: {
																style: {
																	minWidth: ZenTheme.sizing.scale1000,
																},
															},
														}}
														src={t.avatarURL || ""}
													/>
												)}
											getProfileItemImgText={(t: User) => `${t.firstName} ${t.lastName}`}
											getProfileItemLabels={(t: User) => ({
												title: `${t.firstName} ${t.lastName}`,
												subtitle: t.department.name,
												body: t.position.name,
											})}
											overrides={{
												ListItemProfile: {
													style: () => {
														if (idList.includes(w.id))
															return {
																...highlightedBorder,
																paddingTop: "3px",
																paddingBottom: "3px",
																paddingLeft: "1px",
																paddingRight: "2px",
																marginBottom: "5px",
															}
														return {
															paddingTop: "3px",
															paddingBottom: "3px",
															paddingLeft: "1px",
															paddingRight: "2px",
														}
													},
												},
												ProfileImgContainer: {
													style: {
														height: "60px",
														width: "60px",
													},
												},
											}}
										/>
									</div>
								))}
						</div>
					)}
					{onRemove && (
						<ZenTextArea label="Reason" formRef={control} nameRef="reason" inputError={errors.reason} formRules={{ required: "Reason is required" }} />
					)}
					<CancelAndSaveButtons disabled={isLoading} isLoading={isLoading} cancelFn={onClose} saveLabel={onRemove ? "Remove" : "Assign"} />
				</form>
				{assignWorkerToClient.error && <ErrorNotification messageOrPayload={assignWorkerToClient.payload} />}
				{removeAssignedWorker.error && <ErrorNotification messageOrPayload={removeAssignedWorker.error} />}
				{error && <ErrorNotification messageOrPayload={workerData} />}
			</ModalBody>
		</ZenModal>
	)
}
