import * as React from "react"
import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import { LabelLarge } from "baseui/typography"
import { useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { fetching } from "../../../fetching"
import { WorkerFormData } from "../../../pages/workers/workerCreate"
import { BasicName, Role } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { SubSection } from "../../common"
import { snakeToTitle } from "../../../helpers/utils"
import { ZenSelect } from "../../zenComponents/zenSelectBox"
import { ErrorNotification } from "../../errorBox"
import { routes } from "routes"
import { ErrorFieldTracker } from "../../forms/errorFieldTracker"

// set workers' role, permissions, position
export const WorkerRole = (props: WorkerFormData) => {
	const { data, setData } = props

	const history = useHistory()
	const [roleOptions, setRoleOptions] = React.useState<Option[]>([])
	const { handleSubmit, getValues, control, errors, setValue } = useForm()
	const roleData = useQuery<Role[]>(fetching.query.getRoleAll())

	React.useEffect(() => {
		if (roleData.loading || !roleData.payload) return
		setRoleOptions(
			roleData.payload.map<Option>((r) => ({
				label: snakeToTitle(r.name),
				id: r.id,
			})),
		)
	}, [roleData.payload, roleData.loading])

	// load department and position options
	const [department, setDepartment] = React.useState(data.department || [])
	const [departmentOptions, setDepartmentOptions] = React.useState<Option[]>([])
	const departmentData = useQuery<BasicName[]>(fetching.query.getDepartmentAll())
	React.useEffect(() => {
		if (departmentData.loading || !departmentData.payload) return
		setDepartmentOptions(departmentData.payload.map<Option>((d) => ({ ...d, label: d.name })))
	}, [departmentData.payload, departmentData.loading, departmentData.error])

	const [positionOptions, setPositionOptions] = React.useState<Option[]>([])
	const positionData = useQuery<BasicName[]>(fetching.query.getPositionAll(department.length > 0 && department[0].id ? department[0].id.toString() : ""), false)
	React.useEffect(() => {
		if (department.length === 0) return
		positionData.query()
	}, [department]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		// clear position field if department is empty
		if (department.length === 0) {
			setPositionOptions([])
			return
		}

		// otherwise fill in the position options
		if (positionData.loading || !positionData.payload) return
		setPositionOptions(positionData.payload.map<Option>((p) => ({ ...p, label: p.name })))
	}, [positionData.payload, department, positionData.loading])

	// other functions
	const onBack = () => {
		storeData(getValues())
		history.goBack()
	}
	const onSubmit = (formData: any) => {
		storeData(formData)
		history.push(routes.workers.create.emergencyContact)
	}
	const storeData = (formData: any) => {
		setData({
			...data,
			...formData,
		})
	}
	const statusOptions: Option[] = [
		{ id: "FULL_TIME", label: "Full Time" },
		{ id: "PART_TIME", label: "Part Time" },
		{ id: "CASUAL", label: "Casual" },
	]

	const [css] = useStyletron()
	const container = css({
		width: "50%",
		height: "100%",
		padding: "20px",
		"@media only screen and (max-width: 900px)": {
			width: "100%",
		},
	})
	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
			<LabelLarge>Role Detail</LabelLarge>
			<SubSection>
				<>
					<ZenSelect
						label="Role"
						formName="role"
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Role is required",
							},
						}}
						formRef={control}
						value={data.role}
						inputError={errors.role}
						options={roleOptions}
					/>
					<ZenSelect
						label="Department"
						formName="department"
						clearable={false}
						formRef={control}
						inputError={errors.department}
						actionOnChange={(v) => {
							setDepartment(v)
							setValue("position", [])
						}}
						value={data.department}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Department is required",
							},
						}}
						options={departmentOptions}
					/>
					<ZenSelect
						label="Position"
						formName="position"
						clearable={false}
						formRef={control}
						inputError={errors.position}
						value={data.position}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Position is required",
							},
						}}
						options={positionOptions}
					/>

					<ZenSelect
						label="Status"
						formName="status"
						clearable={false}
						formRef={control}
						inputError={errors.status}
						value={data.status}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Status is required",
							},
						}}
						options={statusOptions}
					/>
				</>
			</SubSection>
			{roleData.error && <ErrorNotification messageOrPayload={roleData.payload} />}
			{departmentData.error && <ErrorNotification messageOrPayload={departmentData.payload} />}
			{positionData.error && <ErrorNotification messageOrPayload={positionData.payload} />}
			<ErrorFieldTracker errorIDs={Object.keys(errors)} />
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={onBack} saveLabel="Next" />
		</form>
	)
}
