import * as React from "react"
import { useStyletron } from "baseui"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { ZenCard } from "../common"
import { ZenTheme } from "../../themeOverrides"
import { ZenButton } from "../zenComponents/zenButtons"
import { TravelExpense } from "../../types/types"

export const TravelExpenseList = (props: { travelExpenses: TravelExpense[]; removeItem?: (index: number) => void }) => {
	const [css] = useStyletron()
	const expenseCardRow = css({
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: "10px",
	})
	const clickable = css({
		cursor: "pointer",
	})

	const { travelExpenses, removeItem } = props

	const handleClick = (item: TravelExpense) => {
		if (!item.fileURL) return
		window.open(item.fileURL, "_blank")
	}

	if (travelExpenses.length < 1) return null

	return (
		<>
			<LabelMedium marginBottom="8px" marginTop="10px">
				Travel Expenses
			</LabelMedium>
			<div>
				{travelExpenses.map((e, index) => (
					<ZenCard className={expenseCardRow} key={index}>
						<div className={e.fileURL && clickable} onClick={() => handleClick(e)}>
							<LabelSmall>{`${e.expenseType} | $${e.amount}`}</LabelSmall>
							<LabelSmall color={ZenTheme.colors.contentTertiary}>{e.fileName ? e.fileName : ""}</LabelSmall>
						</div>
						<div>
							{!!removeItem && (
								<ZenButton altKind="danger" width={"30px"} type="button" onClick={() => removeItem(index)}>
									x
								</ZenButton>
							)}
						</div>
					</ZenCard>
				))}
			</div>
		</>
	)
}
