import * as React from "react"
import { useStyletron } from "baseui"
import { useFormContext } from "react-hook-form"
import { LabelLarge } from "baseui/typography"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { useHistory } from "react-router-dom"
import { ZenTextArea } from "../../../zenComponents/zenInput"
import { IntakeYouthProps } from "./intakeYouthBaseForm"
import { IntakeScrollDiv } from "../intakeComponent"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"

export const IntakeYouthWorkAndSchool = (props: IntakeYouthProps) => {
	const { clientID, formID, isReadOnly } = props
	const [css] = useStyletron()
	const history = useHistory()
	const { control, getValues } = useFormContext()
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		width: "100%",
		height: "95%",
	})
	const subForm = css({
		padding: "20px",
	})
	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<LabelLarge>Social and School</LabelLarge>
					<IntakeScrollDiv>
						<div className={subForm}>
							<ZenTextArea
								disabled={isReadOnly}
								label="How well does young person get along with others? Do they have many friends?"
								nameRef="getAlongWithOthers"
								formRef={control}
								placeholder="Enter details"
								initialHeight={180}
								getValues={getValues}
							/>
							<ZenTextArea
								disabled={isReadOnly}
								label="Involved in any after school activities/group:"
								nameRef="activitiesGroupAfterSchool"
								formRef={control}
								placeholder="Enter detail"
								initialHeight={180}
								getValues={getValues}
							/>
							<ZenTextArea
								disabled={isReadOnly}
								label="Attending school/study/work"
								nameRef="attendingSchoolStudyWork"
								formRef={control}
								placeholder="Explore performance, satisfaction, attendance"
								initialHeight={180}
								getValues={getValues}
							/>
						</div>
					</IntakeScrollDiv>
				</div>
				{!isReadOnly && (
					<CancelAndSaveButtons
						cancelLabel="Back"
						cancelFn={history.goBack}
						saveLabel="Next"
						saveFn={() => history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth/presenceOfRiskConcerns`, formID))}
					/>
				)}
			</form>
		</div>
	)
}
