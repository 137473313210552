import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge } from "baseui/typography"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { useForm } from "react-hook-form"
import { Divider } from "../../common"
import { GroupAppointmentFormProps, SessionFundingSourceInput } from "./groupSessionBaseForm"
import { SessionFundingSourceForm } from "../SessionFundingSource"
import { ZenButton } from "../../zenComponents/zenButtons"

export const GroupSessionAppointmentForm = (props: GroupAppointmentFormProps) => {
	const { data, setData, changePage } = props
	const [css] = useStyletron()
	const { control, setValue, getValues, errors, watch, handleSubmit } = useForm()
	React.useEffect(() => {
		if (data.sessionFundingSources && data.sessionFundingSources.length > 0) {
			setPlaceholder(data.sessionFundingSources.map((_, i) => i))
			data.sessionFundingSources.forEach((sfs, i) => {
				setAvailableIndexes((ais) => ais.filter((ai) => ai !== i))
				setValue(`fundingSource-${i}`, sfs.fundingSource)
				setValue(`contractArea-${i}`, sfs.contractArea || [])
				setValue(`supportType-${i}`, sfs.supportType || [])
				setValue(`subSupportType-${i}`, sfs.subSupportType || [])
			})
		}
	}, [data]) // eslint-disable-line react-hooks/exhaustive-deps

	// Prepare form data for multiple funding sources
	const [placeholder, setPlaceholder] = React.useState<number[]>([0]) // use for tracking the index of session funding source
	const [availableIndexes, setAvailableIndexes] = React.useState<number[]>([2, 1]) // additional index available for new funding sources
	const addMoreFundingSource = () => {
		let list = [...availableIndexes]
		const index = list.pop()

		// if the list is empty, then skip
		if (!index) return

		// store remain indexes
		setAvailableIndexes(list)

		// append index to placeholder list
		setPlaceholder((p) => p.concat(index))
	}

	const back = () => {
		saveData()
		changePage(-1)
	}

	const submit = async () => {
		saveData()
		changePage(1)
	}

	const saveData = () => {
		let sessionFundingSources: SessionFundingSourceInput[] = []
		placeholder
			.sort((a: number, b: number) => a - b)
			.forEach((p) => {
				if (!getValues(`fundingSource-${p}`) || getValues(`fundingSource-${p}`).length === 0) return
				sessionFundingSources.push({
					fundingSource: getValues(`fundingSource-${p}`) || [],
					supportType: getValues(`supportType-${p}`) || [],
					contractArea: getValues(`contractArea-${p}`) || [],
					subSupportType: getValues(`subSupportType-${p}`) || [],
				})
			})

		setData((data) => ({
			...data,
			sessionFundingSources,
		}))
	}

	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "50%",

		[`@media only screen and (max-width: 1500px)`]: {
			width: "90%",
		},
	})

	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(submit)}>
			<LabelLarge>Appointment</LabelLarge>
			<Divider style={{ backgroundColor: "white" }} />
			<SessionFundingSourceForm
				control={control}
				errors={errors}
				placeholder={placeholder}
				watch={watch}
				setValue={setValue}
				setAvailableIndexes={setAvailableIndexes}
				setPlaceholder={setPlaceholder}
				isGroupSession
				sessionFundingSources={
					data.sessionFundingSources && data.sessionFundingSources.length > 0
						? data.sessionFundingSources.map((sfs) => ({
								fundingSource: sfs.fundingSource[0],
								contractArea: sfs.contractArea && sfs.contractArea.length > 0 ? sfs.contractArea[0] : undefined,
								supportType: sfs.supportType && sfs.supportType.length > 0 ? sfs.supportType[0] : undefined,
								subSupportType: sfs.subSupportType && sfs.subSupportType.length > 0 ? sfs.subSupportType[0] : undefined,
						  }))
						: undefined
				}
			/>
			{availableIndexes.length > 0 && (
				<>
					<Divider style={{ backgroundColor: "white" }} />
					<div>
						<ZenButton type="button" onClick={addMoreFundingSource}>
							Add New Funding Source
						</ZenButton>
					</div>
				</>
			)}
			<Divider style={{ backgroundColor: "white" }} />
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={back} saveLabel="Next" />
		</form>
	)
}
