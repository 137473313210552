import React from "react";
import YouthCareerProvider from "./youthCareerProvider";
import YouthCareerView from "./youthCareerView";

export const YouthCareer = () => {
    return (
        <YouthCareerProvider>
            <YouthCareerView/>
        </YouthCareerProvider>
    )
};