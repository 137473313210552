import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Value } from "baseui/select"
import { LabelLarge } from "baseui/typography"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { FieldError, FormProvider, useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import { AuthContainer } from "../../../controllers/auth"
import { fetching } from "../../../fetching"
import { FormType, FormTypeName } from "../../../types/enums"
import { Client, Form, UserDetail } from "../../../types/types"
import { ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenProgressBar } from "../../zenComponents/zenProgressBar"
import { ZenClientSelect } from "../../zenComponents/zenSelectBox"
import { ActionPlan, WellbeingActionPlan } from "./wellbeingActionPlan"
import { WellbeingActivitiesPage } from "./wellbeingActivities"
import { WellbeingConfirmation } from "./wellbeingConfirmation"
import { Domain, DomainScore, WellbeingDomain } from "./wellbeingDomain"
import { WellbeingInfoCard } from "./wellbeingInfoCard"
import { WellbeingNDISPlan } from "./wellbeingNDISPlan"
import { routes } from "routes"
import { ErrorFieldTracker } from "../errorFieldTracker"

export interface WellbeingFormData {
	client: Client[]
	worker: UserDetail[]
	scores: DomainScore[]
	actionPlans: ActionPlan[]
	weeklyActivities: string[]
	ndisPlan: {
		goals: {
			goal: string
			hoursAllocated: number
			myActions: string
			progress: string
		}[]
		clientSignature: string
		clientSignatureDate: Date
		workerSignature: string
		workerSignatureDate: Date
	}
	confirmation: {
		dateCreated: Date
		dateToBeReviewed: Date

		clientParticipated: boolean
		clientReviewed: boolean
		clientProvidedWithCopy: boolean

		completedRiskAssessment: boolean
		completedSafetyPlan: boolean
		completedCommunicationAgreement: boolean
	}
}

const WellbeingForm = () => {
	const history = useHistory()
	const { showToast } = useZenToast()
	const searchArgs = new URLSearchParams(history.location.search)
	const formID = searchArgs.get("formID")
	const initialStep = searchArgs.get("step")

	const [cachedFormData, setCachedFormData] = React.useState<WellbeingFormData>()

	const { currentUser } = AuthContainer.useContainer()

	const [excludedClientID, setExcludedClientID] = React.useState<string[]>([])

	const { error: getFormErr, loading: formLoading, query, payload: formPayload } = useParameterizedQuery<Form<WellbeingFormData>>(fetching.query.getForm)

	const setFormData = (content: WellbeingFormData) => {
		formMethods.setValue("client", content.client)
		formMethods.setValue("worker", content.worker)
		formMethods.setValue("scores", content.scores)
		formMethods.setValue("actionPlans", content.actionPlans)
		formMethods.setValue("weeklyActivities", content.weeklyActivities)
		formMethods.setValue("ndisPlan", content.ndisPlan)
		formMethods.setValue("confirmation", content.confirmation)
	}

	React.useEffect(() => {
		if (!formID) {
			if (currentUser) formMethods.setValue("worker", [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` } as UserDetail])
			return
		}
		query(formID).then((resp) => {
			if (resp.error || !resp.payload) return
			const content = resp.payload.content
			// load existing form data
			setFormData(content)
		})
	}, [formID, currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

	// pre-fill client
	const clientID = searchArgs.get("id")

	const getClient = useParameterizedQuery<Client>(fetching.query.getClient)
	React.useEffect(() => {
		if (!clientID) return
		getClient
			.query(clientID)
			.then((r) => {
				if (r.error || !r.payload) return
				formMethods.setValue("client", [{ ...r.payload, label: `${r.payload.firstName} ${r.payload.lastName}` } as Client])
				if (r.payload.dateOfBirth) formMethods.setValue("dob", new Date(r.payload.dateOfBirth))
			})
			.catch()
	}, [clientID]) // eslint-disable-line react-hooks/exhaustive-deps

	// Editing
	const [step, setStep] = React.useState<number>(formID && initialStep ? parseInt(initialStep) : 0)

	const [isEditMode, setIsEditMode] = React.useState(!formID)

	const formMethods = useForm<WellbeingFormData>({
		defaultValues: {
			scores: Object.keys(Domain).map(() => {
				return {
					score: 0,
					reason: "",
					currentStrengths: "",
					thingsToDevelop: "",
				} as DomainScore
			}),
			actionPlans: [{ when: new Date() }],
			ndisPlan: {
				clientSignature: "",
				workerSignature: "",
			},
			confirmation: {
				dateCreated: new Date(),
				dateToBeReviewed: new Date(),
				clientParticipated: false,
				clientReviewed: false,
				clientProvidedWithCopy: false,

				completedRiskAssessment: false,
				completedSafetyPlan: false,
				completedCommunicationAgreement: false,
			},
		},
	})

	const [isDirty, setIsDirty] = React.useState(false) // Can't rely on formMethods.formState.isDirty as it get's reset on step change
	React.useEffect(() => {
		if (!isDirty && formMethods.formState.isDirty) setIsDirty(true)
	}, [formMethods.formState.isDirty, isDirty])

	const {
		mutate: createForm,
		loading: creatingForm,
		payload: createFormData,
		error: createError,
	} = useMutation<Form<WellbeingFormData>>(fetching.mutation.formCreate)
	const {
		mutate: updateForm,
		loading: updatingForm,
		payload: updateFormData,
		error: updateError,
	} = useMutation<Form<WellbeingFormData>>(fetching.mutation.formUpdate)

	const formDisabled = creatingForm || updatingForm || formLoading || !isEditMode

	// no client selected
	const noClient = !formMethods.getValues().client || !formMethods.getValues().client[0]

	const onSaveForm = formMethods.handleSubmit(async (data) => {
		if (creatingForm || updatingForm || noClient || (!formID && step < 4)) return
		const input = {
			clientID: formMethods.getValues().client[0].id,
			content: data,
		}

		if (formID) {
			const resp = await updateForm({
				id: formID,
				name: `${FormTypeName(FormType.WellbeingPlan)} Form | ${formMethods.getValues().client[0].firstName} ${
					formMethods.getValues().client[0].lastName
				} | ${new Date().toLocaleDateString(undefined, {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				})}`,
				...input,
			})
			if (resp.status === 200) {
				setIsEditMode(false)
				showToast("Wellbeing Plan updated successfully.", "positive")
				setIsDirty(false)
				formMethods.control.updateFormState({ isDirty: false })
			}
		} else {
			const { status } = await createForm({
				input: {
					name: `${FormTypeName(FormType.WellbeingPlan)} Form | ${formMethods.getValues().client[0].firstName} ${
						formMethods.getValues().client[0].lastName
					} | ${new Date().toLocaleDateString(undefined, {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					})}`,
					type: FormType.WellbeingPlan,
					...input,
				},
			})
			if (status === 200) {
				setIsEditMode(false)
				setIsDirty(false)
				formMethods.control.updateFormState({ isDirty: false })
				showToast("Wellbeing Plan created successfully.", "positive")
				history.push(routes.forms.root)
			}
		}
	})
	const nextStep = async () => {
		let isValid = true
		switch (step) {
			case 4:
				return
			case 3:
				isValid = await formMethods.trigger(["client", "worker"])
				if (!isValid) return
				break
			case 0:
				isValid = await formMethods.trigger(["client"])
				if (!isValid) return
				break
		}
		searchArgs.set("step", (step + 1).toString())
		history.push({ search: searchArgs.toString() })
		setStep(step + 1)
	}

	const prevStep = () => {
		if (step <= 0) {
			history.push(routes.forms.root)
			return
		}
		searchArgs.set("step", (step - 1).toString())
		history.push({ search: searchArgs.toString() })
		setStep(step - 1)
	}

	const ContinueOrSaveButton = (): JSX.Element => {
		if (!formID) {
			// show continue button if step is less than 4
			if (step < 4)
				return (
					<ZenButton width="100px" disabled={creatingForm || updatingForm} type="button" onClick={nextStep}>
						Continue
					</ZenButton>
				)

			// show submit button if it is the last step
			return (
				<ZenButton width="100px" disabled={creatingForm || updatingForm} type="button" onClick={onSaveForm} isLoading={creatingForm || updatingForm}>
					Submit
				</ZenButton>
			)
		}

		// if form id exist

		// show save button if it is in edit mode
		if (isEditMode) {
			return (
				<ZenButton width="100px" disabled={creatingForm || updatingForm} type="button" onClick={onSaveForm} isLoading={creatingForm || updatingForm}>
					Save
				</ZenButton>
			)
		}

		// show exit if it is the last step
		if (step >= 4)
			return (
				<ZenButton width="100px" disabled={creatingForm || updatingForm} type="button" onClick={() => history.push(routes.forms.root)}>
					Exit
				</ZenButton>
			)

		// otherwise show continue
		return (
			<ZenButton width="100px" disabled={creatingForm || updatingForm} type="button" onClick={nextStep}>
				Continue
			</ZenButton>
		)
	}

	// Styling
	const [css] = useStyletron()
	const containerStyle = css({
		height: "100%",
		display: "flex",
		overflowY: "auto",
		"@media only screen and (max-width: 1400px)": {
			flexDirection: "column-reverse",
		},
	})
	const cardStyle = css({
		padding: "20px 10px 20px 30px !important",
		flex: "unset",
	})
	const formStyle = css({
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	const infoCard = css({
		marginLeft: "20px",
		"@media only screen and (max-width: 1400px)": {
			margin: 0,
			marginBottom: "20px",
		},
	})
	const progressBarStyle = css({
		paddingRight: "20px",
		width: "100%",
	})
	const sectionStyle = css({
		overflowY: "auto",
		paddingRight: "10px",
		height: "calc(100vh - 240px)",
		"@media only screen and (max-width: 1400px)": {
			height: "auto",
		},
	})
	const topStyle = css({
		display: "flex",
		marginTop: "10px",
		marginBottom: "20px",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const titleContainer = css({
		display: "flex",
		width: "100%",
		"@media only screen and (max-width: 900px)": {
			flexDirection: "column-reverse",
		},
	})
	const footerStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginTop: "10px",
	})

	return (
		<FormProvider {...formMethods}>
			<div className={containerStyle}>
				<ZenCard className={cardStyle}>
					<form autoComplete="off" className={formStyle} onSubmit={onSaveForm}>
						<div className={titleContainer}>
							<div className={progressBarStyle}>
								<ZenProgressBar labels={["Wellbeing Plan", "Action Plan", "Weekly Activities", "NDIS Plan", "Confirmation"]} currentStep={step} />
							</div>
							{!!formID && !isEditMode && (
								<ZenButton
									onClick={() => {
										// save current status
										setCachedFormData(formMethods.getValues())
										setIsEditMode(true)
									}}
									marginTop="5px"
									marginBottom="5px"
									marginLeft="auto"
									height="38px"
									width="100px"
								>
									Edit
								</ZenButton>
							)}
						</div>

						<div className={sectionStyle}>
							{step === 0 && (
								<>
									<LabelLarge $style={{ fontWeight: "bold" }}>Their Wellbeing Plan</LabelLarge>

									{/* Client & DoB */}
									<div className={topStyle}>
										<ZenClientSelect
											defaultValue={!noClient ? formMethods.getValues().client : undefined}
											label="Client"
											formName="client"
											width="100%"
											formRef={formMethods.control}
											disabled={formDisabled}
											inputError={formMethods.errors.client as FieldError | undefined}
											formRules={{
												validate: {
													required: (value: Value) => (!!value && value.length > 0) || "Client is required",
												},
											}}
											excludedID={excludedClientID}
											actionOnChange={(v) => {
												if (v.length > 0) {
													setExcludedClientID(v[0].id ? [v[0].id.toString()] : [])
													formMethods.setValue("dob", new Date(v[0].dateOfBirth))
													return
												}
												setExcludedClientID([])
											}}
										/>
									</div>

									<div>
										{Object.keys(Domain).map((d, i) => (
											<WellbeingDomain key={`WellbeingDomain-${d}`} index={i} domain={d as Domain} disabled={formDisabled} />
										))}
									</div>
								</>
							)}
							{step === 1 && <WellbeingActionPlan disabled={formDisabled} fieldCount={formMethods.getValues("actionPlans")?.length} />}
							{step === 2 && <WellbeingActivitiesPage disabled={formDisabled} />}
							{step === 3 && <WellbeingNDISPlan disabled={formDisabled} fieldCount={formMethods.getValues("ndisPlan")?.goals.length} />}
							{step === 4 && <WellbeingConfirmation disabled={formDisabled} />}
						</div>

						{/* Footer */}
						{createError && <ErrorNotification messageOrPayload={createFormData} closeable />}
						{getFormErr && <ErrorNotification messageOrPayload={formPayload} closeable />}
						{updateError && <ErrorNotification messageOrPayload={updateFormData} closeable />}
						<ErrorFieldTracker errorIDs={Object.keys(formMethods.errors)} />
						<div className={footerStyle}>
							<ZenButton
								type="button"
								onClick={() => {
									if (isEditMode && formID) {
										// set back to cached data
										if (cachedFormData) {
											setFormData(cachedFormData)
										}
										setIsEditMode(false)
										return
									}
									prevStep()
								}}
								disabled={creatingForm || updatingForm || formLoading}
								altKind="secondary"
								width="100px"
							>
								{step === 0 ? "Cancel" : "Back"}
							</ZenButton>
							<ContinueOrSaveButton />
						</div>
					</form>
				</ZenCard>
				<Block className={infoCard}>
					<WellbeingInfoCard />
				</Block>
				<Prompt
					when={isDirty}
					message={(location) => {
						if (location.pathname === "/portal/forms/wellbeing_plan") {
							return true
						}
						return "You have unsaved changes, are you sure you want to leave?"
					}}
				/>
			</div>
		</FormProvider>
	)
}

export default WellbeingForm
