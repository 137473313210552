import * as React from "react"
import { CallPurpose } from "../../../types/types"
import { useStyletron } from "baseui"
import { Divider, SearchAndFilter, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { ListTable } from "../../../components/listTable"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "baseui/button"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { useDebounce } from "../../../helpers/utils"
import { Value } from "baseui/select"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { ZenTheme } from "../../../themeOverrides"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { useForm } from "react-hook-form"
import { PlaceholderPanel } from "./placeholderPanel"

enum FilterOption {
	Active = "Active",
	Archive = "Archive",
}
export const CallPurposeOptionManagement = () => {
	const [css] = useStyletron()
	const outer = css({
		display: "flex",
		flexDirection: "column",
		maxWidth: "calc(min(1280px, 100%))",
		width: "100%",
		height: "100%",
	})
	const [selectedCallPurpose, setSelectedCallPurpose] = React.useState<CallPurpose>()

	const displayCallPurposeTypes = () => {
		if (!selectedCallPurpose) return <PlaceholderPanel height="50%" text="Please select a call purpose to view call purpose types" />
		return <CallPurposeTypes selectedCallPurpose={selectedCallPurpose} />
	}

	return (
		<div className={outer}>
			<CallPurposes selectedCallPurpose={selectedCallPurpose} setSelectedCallPurpose={setSelectedCallPurpose} />
			<Divider style={{ backgroundColor: "transparent" }} />
			{displayCallPurposeTypes()}
		</div>
	)
}
interface CallPurposeProps {
	selectedCallPurpose: CallPurpose | undefined
	setSelectedCallPurpose: React.Dispatch<React.SetStateAction<CallPurpose | undefined>>
}

const CallPurposes = (props: CallPurposeProps) => {
	const { selectedCallPurpose, setSelectedCallPurpose } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [callPurposes, setCallPurposes] = React.useState<CallPurpose[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const callPurposeMany = useQuery(
		fetching.query.getCallPurposeMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
		}),
	)
	React.useEffect(() => {
		if (callPurposeMany.error || !callPurposeMany.payload) return
		setTotal(callPurposeMany.payload.total)
		setCallPurposes(callPurposeMany.payload.callPurposes)
	}, [callPurposeMany.payload, callPurposeMany.error])

	const callPurposeArchive = useMutation(fetching.mutation.callPurposeArchive)
	const callPurposeUnarchive = useMutation(fetching.mutation.callPurposeUnarchive)

	const [targetedCallPurpose, setTargetedCallPurpose] = React.useState<CallPurpose>() // call purpose use for update
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)

	return (
		<ZenCard className={container}>
			<div className={title}>
				<SearchAndFilter
					search={displayKey}
					setSearch={setDisplayKey}
					filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
					filter={filter}
					setFilter={(v) => {
						setSelectedCallPurpose(undefined)
						setFilter(v)
					}}
				/>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Call Purpose</ZenButton>}
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{callPurposeMany.error && <ErrorNotification messageOrPayload={callPurposeMany.payload} />}
			{callPurposeArchive.error && <ErrorNotification messageOrPayload={callPurposeArchive.payload} />}
			{callPurposeUnarchive.error && <ErrorNotification messageOrPayload={callPurposeUnarchive.payload} />}
			<ListTable
				isLoading={callPurposeMany.loading || callPurposeArchive.loading || callPurposeUnarchive.loading}
				rows={callPurposes}
				selectedID={selectedCallPurpose?.id}
				onRowClick={(row: CallPurpose) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedCallPurpose(row)
						setOpenUnarchiveModal(true)
						return
					}
					setSelectedCallPurpose(row)
				}}
				columns={[
					{
						id: "label",
						header: "Call Purpose",
						resolver: (row: CallPurpose) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: CallPurpose) => {
							const isHighlighted = selectedCallPurpose?.id === row.id
							return (
								<div className={row.deletedAt ? actionButtonHeader : actionButton}>
									{!row.deletedAt && (
										<Button
											kind="minimal"
											onClick={(e) => {
												e.stopPropagation()
												setOpenUpdateModal(true)
												setTargetedCallPurpose(row)
											}}
										>
											<FontAwesomeIcon color={isHighlighted ? "white" : ZenTheme.colors.primaryGreen} size={"1x"} icon={["fal", "edit"]} />
										</Button>
									)}
									<Button
										kind="minimal"
										onClick={(e) => {
											e.stopPropagation()
											setTargetedCallPurpose(row)
											if (!row.deletedAt) {
												setOpenArchiveModal(true)
												return
											}
											setOpenUnarchiveModal(true)
										}}
									>
										<FontAwesomeIcon
											color={isHighlighted ? "white" : row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
											size={"1x"}
											icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
										/>
									</Button>
									{targetedCallPurpose?.id === row.id && (
										<div onClick={(e) => e.stopPropagation()}>
											{openArchiveModal && (
												<ZenArchiveModal
													open={openArchiveModal}
													loading={callPurposeArchive.loading || callPurposeMany.loading}
													message={row.label}
													onClose={() => setOpenArchiveModal(false)}
													confirmArchive={() => {
														callPurposeArchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															if (selectedCallPurpose?.id === row.id) {
																setSelectedCallPurpose(undefined)
															}
															callPurposeMany.query()
															setOpenArchiveModal(false)
														})
													}}
												/>
											)}
											{openUnarchiveModal && (
												<ZenArchiveModal
													open={openUnarchiveModal}
													loading={callPurposeUnarchive.loading || callPurposeMany.loading}
													message={row.label}
													onClose={() => setOpenUnarchiveModal(false)}
													restoreMode
													confirmArchive={() => {
														callPurposeUnarchive.mutate(row.id).then((resp) => {
															if (resp.error || !resp.payload) return
															callPurposeMany.query()
															setOpenUnarchiveModal(false)
														})
													}}
												/>
											)}
										</div>
									)}
								</div>
							)
						},
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && targetedCallPurpose && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<CallPurposeForm
						callPurpose={targetedCallPurpose}
						onClose={() => {
							setOpenUpdateModal(false)
							setTargetedCallPurpose(undefined)
						}}
						queryCallPurposes={callPurposeMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<CallPurposeForm
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryCallPurposes={callPurposeMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface CallPurposeFormProps {
	callPurpose?: CallPurpose
	onClose: () => void
	queryCallPurposes: () => Promise<
		QueryResponse<{
			callPurposes: CallPurpose[]
			total: number
		}>
	>
}
const CallPurposeForm = (props: CallPurposeFormProps) => {
	const { callPurpose, onClose, queryCallPurposes } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedCallPurpose, setDuplicatedCallPurpose] = React.useState<CallPurpose>()

	const callPurposeCreate = useMutation(fetching.mutation.callPurposeCreate)
	const callPurposeUpdate = useMutation(fetching.mutation.callPurposeUpdate)
	const callPurposeUnarchive = useMutation(fetching.mutation.callPurposeUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!callPurpose) return
		setValue("label", callPurpose.label)
	}, [callPurpose, setValue])

	const onSubmit = (formData: any) => {
		if (callPurpose?.label === formData.label) {
			onClose()
			return
		}

		// update existing callPurpose
		if (callPurpose) {
			callPurposeUpdate
				.mutate({
					id: callPurpose.id,
					label: formData.label,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated callPurpose
					if (typeof resp.payload === "object") {
						setDuplicatedCallPurpose(resp.payload)
						return
					}

					queryCallPurposes()
					onClose()
				})
			return
		}

		// create new callPurpose
		callPurposeCreate
			.mutate({
				label: formData.label,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated callPurpose
				if (typeof resp.payload === "object") {
					setDuplicatedCallPurpose(resp.payload)
					return
				}

				queryCallPurposes()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated callPurpose after submit
		if (duplicatedCallPurpose && !!duplicatedCallPurpose.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The call purpose "{duplicatedCallPurpose.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedCallPurpose(undefined)}
						saveFn={() => {
							// handle call purposeType
							callPurposeUnarchive.mutate(duplicatedCallPurpose.id).then((resp) => {
								if (resp.error || !resp.payload) return
								queryCallPurposes()
								onClose()
							})
						}}
						isLoading={callPurposeUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{callPurposeUnarchive.error && <ErrorNotification messageOrPayload={callPurposeUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!callPurpose ? "Update" : "Create"} Call Purpose</LabelMedium>
				<ZenInput label="Name" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!callPurpose ? "Save" : "Submit"}
					isLoading={callPurposeCreate.loading || callPurposeUpdate.loading}
				/>
				{duplicatedCallPurpose && <ErrorNotification message={`The call purpose "${duplicatedCallPurpose.label}" is already exists`} />}
				{callPurposeCreate.error && <ErrorNotification messageOrPayload={callPurposeCreate.payload} />}
				{callPurposeUpdate.error && <ErrorNotification messageOrPayload={callPurposeUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}

interface CallPurposeTypeProps {
	selectedCallPurpose: CallPurpose
}
const CallPurposeTypes = (props: CallPurposeTypeProps) => {
	const { selectedCallPurpose } = props
	const [css] = useStyletron()
	const container = css({
		height: "50%",
		flex: "unset !important",
	})
	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const actionButtonHeader = css({
		textAlign: "right",
		marginRight: "8%",
	})

	const actionButton = css({
		textAlign: "right",
		marginRight: "5%",
	})

	const [displayKey, setDisplayKey] = React.useState("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	const [search, setSearch] = React.useState("")
	React.useEffect(() => setSearch(debouncedSearchTerm), [debouncedSearchTerm])

	const [callPurposeTypes, setCallPurposeTypes] = React.useState<CallPurpose[]>([])
	const [total, setTotal] = React.useState(0)
	const [filter, setFilter] = React.useState<Value>([{ id: FilterOption.Active, label: FilterOption.Active }])
	const [offset, setOffset] = React.useState(0)
	const [limit] = React.useState(10)

	const callPurposeTypeMany = useQuery(
		fetching.query.getCallPurposeTypeMany({
			search,
			limit,
			offset,
			isArchived: filter[0].id === FilterOption.Archive,
			callPurposeID: selectedCallPurpose.id,
		}),
	)
	React.useEffect(() => {
		if (callPurposeTypeMany.error || !callPurposeTypeMany.payload) return
		setTotal(callPurposeTypeMany.payload.total)
		setCallPurposeTypes(callPurposeTypeMany.payload.callPurposeTypes)
	}, [callPurposeTypeMany.payload, callPurposeTypeMany.error])

	const callPurposeTypeArchive = useMutation(fetching.mutation.callPurposeTypeArchive)
	const callPurposeTypeUnarchive = useMutation(fetching.mutation.callPurposeTypeUnarchive)

	const [targetedCallPurposeTypeID, setTargetedCallPurposeTypeID] = React.useState("")
	const [openArchiveModal, setOpenArchiveModal] = React.useState(false)
	const [openUnarchiveModal, setOpenUnarchiveModal] = React.useState(false)

	const [openCreateModal, setOpenCreateModal] = React.useState(false)
	const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
	const [selectedCallPurposeType, setSelectedCallPurposeType] = React.useState<CallPurpose>()

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelLarge>{selectedCallPurpose.label} - Call Purpose Types</LabelLarge>
				{filter[0].id === FilterOption.Active && <ZenButton onClick={() => setOpenCreateModal(true)}>New Call Purpose Type</ZenButton>}
			</div>
			<SearchAndFilter
				search={displayKey}
				setSearch={setDisplayKey}
				filterOptions={Object.values(FilterOption).map((f) => ({ id: f, label: f }))}
				filter={filter}
				setFilter={setFilter}
			/>
			<Divider style={{ backgroundColor: "transparent" }} />
			{callPurposeTypeMany.error && <ErrorNotification messageOrPayload={callPurposeTypeMany.payload} />}
			{callPurposeTypeArchive.error && <ErrorNotification messageOrPayload={callPurposeTypeArchive.payload} />}
			{callPurposeTypeUnarchive.error && <ErrorNotification messageOrPayload={callPurposeTypeUnarchive.payload} />}
			<ListTable
				isLoading={callPurposeTypeMany.loading || callPurposeTypeArchive.loading || callPurposeTypeUnarchive.loading}
				rows={callPurposeTypes}
				onRowClick={(row: CallPurpose) => {
					if (row.deletedAt) {
						// trigger unarchive
						setTargetedCallPurposeTypeID(row.id)
						setOpenUnarchiveModal(true)
						return
					}
					setOpenUpdateModal(true)
					setSelectedCallPurposeType(row)
				}}
				columns={[
					{
						id: "label",
						header: "Call Purpose Type",
						resolver: (row: CallPurpose) => row.label,
					},
					{
						id: "Action",
						header: <div className={actionButtonHeader}>Action</div>,
						resolver: (row: CallPurpose) => (
							<div className={row.deletedAt ? actionButtonHeader : actionButton}>
								<Button
									kind="minimal"
									onClick={(e) => {
										e.stopPropagation()
										setTargetedCallPurposeTypeID(row.id)
										if (!row.deletedAt) {
											setOpenArchiveModal(true)
											return
										}
										setOpenUnarchiveModal(true)
									}}
								>
									<FontAwesomeIcon
										color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
										size={"1x"}
										icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
									/>
								</Button>
								{targetedCallPurposeTypeID === row.id && (
									<div onClick={(e) => e.stopPropagation()}>
										{openArchiveModal && (
											<ZenArchiveModal
												open={openArchiveModal}
												loading={callPurposeTypeArchive.loading || callPurposeTypeMany.loading}
												message={row.label}
												onClose={() => setOpenArchiveModal(false)}
												confirmArchive={() => {
													callPurposeTypeArchive.mutate({ id: row.id, callPurposeID: selectedCallPurpose.id }).then((resp) => {
														if (resp.error || !resp.payload) return
														callPurposeTypeMany.query()
														setOpenArchiveModal(false)
													})
												}}
											/>
										)}
										{openUnarchiveModal && (
											<ZenArchiveModal
												open={openUnarchiveModal}
												loading={callPurposeTypeUnarchive.loading || callPurposeTypeMany.loading}
												message={row.label}
												onClose={() => setOpenUnarchiveModal(false)}
												restoreMode
												confirmArchive={() => {
													callPurposeTypeUnarchive.mutate({ id: row.id, callPurposeID: selectedCallPurpose.id }).then((resp) => {
														if (resp.error || !resp.payload) return
														callPurposeTypeMany.query()
														setOpenUnarchiveModal(false)
													})
												}}
											/>
										)}
									</div>
								)}
							</div>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{openUpdateModal && selectedCallPurposeType && (
				<ZenModal isOpen={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
					<CallPurposeTypeForm
						callPurposeType={selectedCallPurposeType}
						callPurposeID={selectedCallPurpose.id}
						onClose={() => {
							setOpenUpdateModal(false)
							setSelectedCallPurposeType(undefined)
						}}
						queryCallPurposeTypes={callPurposeTypeMany.query}
					/>
				</ZenModal>
			)}
			{openCreateModal && (
				<ZenModal isOpen={openCreateModal} onClose={() => setOpenCreateModal(false)}>
					<CallPurposeTypeForm
						callPurposeID={selectedCallPurpose.id}
						onClose={() => {
							setOpenCreateModal(false)
						}}
						queryCallPurposeTypes={callPurposeTypeMany.query}
					/>
				</ZenModal>
			)}
		</ZenCard>
	)
}

interface CallPurposeTypeFormProps {
	callPurposeType?: CallPurpose
	callPurposeID: string
	onClose: () => void
	queryCallPurposeTypes: () => Promise<
		QueryResponse<{
			callPurposeTypes: CallPurpose[]
			total: number
		}>
	>
}
const CallPurposeTypeForm = (props: CallPurposeTypeFormProps) => {
	const { callPurposeType, callPurposeID, onClose, queryCallPurposeTypes } = props
	const [css] = useStyletron()

	const container = css({
		minWidth: "350px",
		maxWidth: "550px",
	})

	const restoreMsg = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	})

	const [duplicatedCallPurposeType, setDuplicatedCallPurposeType] = React.useState<CallPurpose>()

	const callPurposeTypeCreate = useMutation(fetching.mutation.callPurposeTypeCreate)
	const callPurposeTypeUpdate = useMutation(fetching.mutation.callPurposeTypeUpdate)
	const callPurposeTypeUnarchive = useMutation(fetching.mutation.callPurposeTypeUnarchive)

	const { control, errors, setValue, handleSubmit } = useForm()

	React.useEffect(() => {
		if (!callPurposeType) return
		setValue("label", callPurposeType.label)
	}, [callPurposeType, setValue])

	const onSubmit = (formData: any) => {
		if (callPurposeType?.label === formData.label) {
			onClose()
			return
		}
		// update existing callPurposeType
		if (callPurposeType) {
			callPurposeTypeUpdate
				.mutate({
					id: callPurposeType.id,
					label: formData.label,
					callPurposeID,
				})
				.then((resp) => {
					if (resp.error || !resp.payload) return

					// if contain duplicated callPurposeType
					if (typeof resp.payload === "object") {
						setDuplicatedCallPurposeType(resp.payload)
						return
					}

					queryCallPurposeTypes()
					onClose()
				})
			return
		}

		// create new callPurposeType
		callPurposeTypeCreate
			.mutate({
				label: formData.label,
				callPurposeID,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				// if contain duplicated callPurposeType
				if (typeof resp.payload === "object") {
					setDuplicatedCallPurposeType(resp.payload)
					return
				}

				queryCallPurposeTypes()
				onClose()
			})
	}

	const displayFormContent = () => {
		// display message if received a archived duplicated callPurposeType after submit
		if (duplicatedCallPurposeType && !!duplicatedCallPurposeType.deletedAt) {
			return (
				<div className={restoreMsg}>
					<FontAwesomeIcon style={{ margin: "0" }} color={ZenTheme.colors.primaryGreen} size={"4x"} icon={["fal", "exclamation-circle"]} />
					<LabelMedium
						overrides={{
							Block: {
								style: {
									marginTop: "12px",
									textAlign: "center",
								},
							},
						}}
					>
						The call purpose type "{duplicatedCallPurposeType.label}" is already exists, but it had been archived. Do you want to restore it?
					</LabelMedium>
					<CancelAndSaveButtons
						width="100%"
						cancelFn={() => setDuplicatedCallPurposeType(undefined)}
						saveFn={() => {
							// handle callPurposeType
							callPurposeTypeUnarchive.mutate({ id: duplicatedCallPurposeType.id, callPurposeID }).then((resp) => {
								if (resp.error || !resp.payload) return
								queryCallPurposeTypes()
								onClose()
							})
						}}
						isLoading={callPurposeTypeUnarchive.loading}
						saveLabel={"Confirm"}
					/>
					{callPurposeTypeUnarchive.error && <ErrorNotification messageOrPayload={callPurposeTypeUnarchive.payload} />}
				</div>
			)
		}
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<LabelMedium>{!!callPurposeType ? "Update" : "Create"} Call Purpose Type</LabelMedium>
				<ZenInput label="Name" formRef={control} nameRef="label" inputError={errors.label} required />
				<CancelAndSaveButtons
					cancelFn={onClose}
					saveLabel={!!callPurposeType ? "Save" : "Submit"}
					isLoading={callPurposeTypeCreate.loading || callPurposeTypeUpdate.loading}
				/>
				{duplicatedCallPurposeType && <ErrorNotification message={`The call purpose type "${duplicatedCallPurposeType.label}" is already exists`} />}
				{callPurposeTypeCreate.error && <ErrorNotification messageOrPayload={callPurposeTypeCreate.payload} />}
				{callPurposeTypeUpdate.error && <ErrorNotification messageOrPayload={callPurposeTypeUpdate.payload} />}
			</form>
		)
	}

	return <ZenCard className={container}>{displayFormContent()}</ZenCard>
}
