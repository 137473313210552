import { createContext } from "react";
import { Option } from "baseui/select"
import { Values, Actions } from "./types";

type Props = {
    errorMsg: Record<string, any>;
    values: Values,
    actions: Actions,
    useFormHooks?: any,
};

const initialContext = {} as Props;

const YouthCareerContext = createContext<Props>(initialContext);

export default YouthCareerContext;