import * as React from "react"
import { useStyletron } from "baseui"
import { Notification } from "baseui/notification"
import { StyledSpinnerNext } from "baseui/spinner"
import { QueryResponse } from "react-fetching-library"
import { Responsive, WidthProvider } from "react-grid-layout"

import { ClientDetail } from "../../../types/types"
import { ZenCard } from "../../common"
import { NoteList } from "../notes"
import { AlertsList } from "./alerts"
import { ClientDetails } from "./clientDetails/clientDetail"
import { ClientSession } from "./clientSession/clientSessionRoot"
import { WorkerInfo } from "./workerInfo"
import moment from "moment/moment"
import { ZenDateFormat, ZenDateTimeFormat } from "../../../types/types"

const ResponsiveGridLayout = WidthProvider(Responsive)

export interface ClientOverviewBasicProps {
	client: ClientDetail
	loading: boolean
	refetch: () => Promise<QueryResponse<ClientDetail>>
}

export interface ClientOverViewExtendedProps extends ClientOverviewBasicProps {
	setActiveKey: React.Dispatch<React.SetStateAction<string>>
}

export const ClientOverviewPage = (props: ClientOverViewExtendedProps) => {
	const { client, refetch, loading } = props

	const [css, theme] = useStyletron()
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		overflowY: "auto",
	})

	const zenCardCls = css({
		maxWidth: "100% !important",
	})
	const dateStyle = css({
		...theme.typography.LabelSmall,
		color: theme.colors.contentTertiary,
		marginLeft: "30px",
	})

	const archivedDate = () => {
		if (!client?.deletedAt) return null
		return <span className={dateStyle}>{moment(client?.deletedAt).format(ZenDateTimeFormat)}</span>
	}

	if (!client || loading) {
		return <StyledSpinnerNext />
	}

	return (
		<div className={container}>
			<ResponsiveGridLayout layouts={layouts} measureBeforeMount={true} rowHeight={(window.innerHeight - 66) / 25}>
				<ZenCard className={zenCardCls} key={"client-details"}>
					{client.deletedAt && (
						<Notification
							kind="warning"
							overrides={{
								Body: { style: { width: "auto" } },
							}}
						>
							Archived {archivedDate()}
						</Notification>
					)}
					<ClientDetails client={client} loading={loading} showTitle refetch={refetch} />
				</ZenCard>

				<ZenCard className={zenCardCls} key={"note-list"}>
					<NoteList clientID={client.id} />
				</ZenCard>
				<ZenCard className={zenCardCls} key={"client-session"}>
					<ClientSession {...client} setActiveKey={props.setActiveKey} />
				</ZenCard>

				<ZenCard className={zenCardCls} key={"worker-info"}>
					<WorkerInfo client={client} loading={loading} refetch={refetch} />
				</ZenCard>
				<ZenCard className={zenCardCls} key={"alerts-list"}>
					<AlertsList clientID={client.id} />
				</ZenCard>
			</ResponsiveGridLayout>
		</div>
	)
}
const layouts = {
	lg: [
		{
			w: 3,
			h: 12,
			x: 0,
			y: 5,
			i: "client-details",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 17,
			x: 3,
			y: 0,
			i: "note-list",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 5,
			x: 0,
			y: 0,
			i: "client-session",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 7,
			y: 0,
			i: "worker-info",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 12,
			x: 7,
			y: 5,
			i: "alerts-list",
			moved: false,
			static: true,
		},
	],
	sm: [
		{
			w: 3,
			h: 12,
			x: 0,
			y: 0,
			i: "client-details",
			moved: false,
			static: true,
		},
		{
			w: 6,
			h: 9,
			x: 0,
			y: 12,
			i: "note-list",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 4,
			x: 3,
			y: 0,
			i: "client-session",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 4,
			x: 3,
			y: 4,
			i: "worker-info",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 4,
			x: 3,
			y: 8,
			i: "alerts-list",
			moved: false,
			static: true,
		},
	],
	xs: [
		{
			w: 3,
			h: 12,
			x: 0,
			y: 27,
			i: "client-details",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 17,
			x: 0,
			y: 5,
			i: "note-list",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 5,
			x: 0,
			y: 0,
			i: "client-session",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 5,
			x: 0,
			y: 22,
			i: "worker-info",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 12,
			x: 0,
			y: 39,
			i: "alerts-list",
			moved: false,
			static: true,
		},
	],
	xxs: [
		{
			w: 2,
			h: 12,
			x: 0,
			y: 27,
			i: "client-details",
			moved: false,
			static: true,
		},
		{
			w: 2,
			h: 17,
			x: 0,
			y: 5,
			i: "note-list",
			moved: false,
			static: true,
		},
		{
			w: 2,
			h: 5,
			x: 0,
			y: 0,
			i: "client-session",
			moved: false,
			static: true,
		},
		{
			w: 2,
			h: 5,
			x: 0,
			y: 22,
			i: "worker-info",
			moved: false,
			static: true,
		},
		{
			w: 2,
			h: 12,
			x: 0,
			y: 39,
			i: "alerts-list",
			moved: false,
			static: true,
		},
	],
	md: [
		{
			w: 3,
			h: 12,
			x: 0,
			y: 5,
			i: "client-details",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 17,
			x: 3,
			y: 0,
			i: "note-list",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 5,
			x: 0,
			y: 0,
			i: "client-session",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 5,
			x: 7,
			y: 0,
			i: "worker-info",
			moved: false,
			static: true,
		},
		{
			w: 3,
			h: 12,
			x: 7,
			y: 5,
			i: "alerts-list",
			moved: false,
			static: true,
		},
	],
}
