import * as React from "react"
import { Input } from "baseui/input"
import { ArrowLeft, ArrowRight } from "baseui/icon"
import { useStyletron } from "baseui"
import { ZenTheme } from "../../themeOverrides"
import { Button, ButtonProps } from "baseui/button"

interface ZenPaginationProps {
	total?: number
	limit: number
	offset: number
	setOffset: (value: number) => void
}
export const ZenPagination = (props: ZenPaginationProps) => {
	const { limit, offset, setOffset } = props
	const total = props.total || 1
	const [numPages, setNumPages] = React.useState<number>(1)
	const [pageField, setPageField] = React.useState((offset / limit + 1).toString())

	const [css] = useStyletron()
	const containerStyle = css({
		display: numPages === 1 ? "none" : "flex",
		marginTop: "15px",
		maxWidth: "320px",
		justifyContent: "space-evenly",
	})

	const totalPageStyle = css({
		alignSelf: "center",
		textAlign: "left",
		width: "80px",
	})

	React.useEffect(() => {
		if (!total || total <= limit) {
			setNumPages(1)
			return
		}
		setNumPages(Math.ceil(total / limit))
	}, [total, limit])

	React.useEffect(() => {
		setPageField(Math.trunc(offset / limit + 1).toString())
	}, [offset, limit])

	return (
		<div className={containerStyle}>
			<ZenPageButton onClick={() => setOffset(Math.min(Math.max(offset - limit, 0), total - 1))} disabled={offset <= 0} startEnhancer={ArrowLeft}>
				Prev
			</ZenPageButton>
			<Input
				data-lpignore="true"
				autoComplete="off"
				value={pageField}
				onChange={(e) => setPageField(e.currentTarget.value)}
				onKeyDown={(e) => e.keyCode === 13 && setOffset(Math.min(Math.max((+pageField - 1) * limit, 0), total - 1))}
				overrides={{
					Root: {
						style: {
							maxWidth: "80px",
						},
					},
					Input: {
						style: ({ $isFocused }) => ({
							backgroundColor: !$isFocused ? "white" : "rgb(246, 246, 246)",
							borderTopColor: !$isFocused ? "unset" : "black",
							borderBottomColor: !$isFocused ? "unset" : "black",
							borderLeftColor: !$isFocused ? "unset" : "black",
							borderRightColor: !$isFocused ? "unset" : "black",
							textAlign: "right",
						}),
					},
					InputContainer: {
						style: ({ $isFocused }) => ({
							borderTopColor: !$isFocused ? "white" : "black",
							borderBottomColor: !$isFocused ? "black" : "black",
							borderLeftColor: !$isFocused ? "white" : "black",
							borderRightColor: !$isFocused ? "white" : "black",
							borderBottomWidth: !$isFocused ? "2px" : "0px",
							borderBottomStyle: !$isFocused ? "solid" : "unset",
						}),
					},
				}}
			/>
			<div className={totalPageStyle}>{`of ${numPages}`}</div>
			<ZenPageButton
				onClick={() => props.setOffset(Math.min(Math.max(offset + limit, 0), total - 1))}
				disabled={offset >= total - limit}
				endEnhancer={ArrowRight}
			>
				Next
			</ZenPageButton>
		</div>
	)
}

export const ZenPageButton = (props: ButtonProps) => {
	const { children, ...rest } = props
	return (
		<Button
			{...rest}
			overrides={{
				BaseButton: {
					style: {
						borderTopLeftRadius: "3px",
						borderTopRightRadius: "3px",
						borderBottomLeftRadius: "3px",
						borderBottomRightRadius: "3px",

						borderTopStyle: "solid",
						borderBottomStyle: "solid",
						borderLeftStyle: "solid",
						borderRightStyle: "solid",

						borderTopColor: "white",
						borderBottomColor: "white",
						borderLeftColor: "white",
						borderRightColor: "white",

						borderTopWidth: "1.5px",
						borderBottomWidth: "1.5px",
						borderLeftWidth: "1.5px",
						borderRightWidth: "1.5px",

						boxShadow: "#00000029 0px 0px 6px",
						paddingTop: "8px",
						paddingBottom: "8px",
						paddingLeft: "12px",
						paddingRight: "12px",
						fontSize: "14px",
						fontWeight: 600,
						whiteSpace: "nowrap",
						backgroundColor: ZenTheme.colors.primaryGreen,
						color: ZenTheme.colors.white,
						":hover": {
							backgroundColor: props.disabled ? undefined : ZenTheme.colors.buttonPrimaryHover,
						},
					},
				},
				StartEnhancer: {
					style: { marginRight: "4px" },
				},
				EndEnhancer: {
					style: { marginLeft: "4px" },
				},
			}}
		>
			<div>{children}</div>
		</Button>
	)
}
