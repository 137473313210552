import * as React from "react"
import { useStyletron } from "baseui"
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover"
import { Select, Value } from "baseui/select"
import { LabelLarge, LabelMedium, LabelSmall, Paragraph2 } from "baseui/typography"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { PortalContainer } from "../../../controllers/portal"
import { fetching } from "../../../fetching"
import { getErrMsg, splitOnCaps, truncate, yesNo } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { ClientHealthTreatmentTeam } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { Divider, NoDataText, Spacer, ZenCard } from "../../common"
import { ListTable } from "../../listTable"
import { Loading } from "../../loading"
import { RowActions } from "../../rowActions"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenInput, ZenTextArea } from "../../zenComponents/zenInput"
import { ZenPagination } from "../../zenComponents/zenPagination"
import { ZenSelect } from "../../zenComponents/zenSelectBox"
import { ZenCheckbox } from "../../zenComponents/zenCheckboxList"
import { ZenArchiveModal } from "../../zenComponents/zenArchiveDialog"
import { ErrorNotification } from "../../errorBox"
import { FilterBy } from "../../../types/enums"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const HealthTreatmentTeams = () => {
	const { client } = PortalContainer.useContainer()
	const [filter, setFilter] = React.useState<Value>([{ label: FilterBy.Active, id: FilterBy.Active }])
	const [manageMode, setManageMode] = React.useState<boolean>(false)
	const [selectedHealthTeam, setSelectedHealthTeam] = React.useState<ClientHealthTreatmentTeam | null>(null)

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "10px",
	})
	const inner = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})

	if (!client) return <Loading />

	return (
		<ZenCard className={container}>
			<div className={inner}>
				<div className={title}>
					<LabelLarge>Health Treatment Team</LabelLarge>
					{!manageMode && (
						<>
							<div className={group}>
								<Select
									searchable={false}
									options={[
										{ label: FilterBy.Active, id: FilterBy.Active },
										{ label: FilterBy.Archived, id: FilterBy.Archived },
									]}
									value={filter}
									placeholder="Filter"
									onChange={(params) => setFilter(params.value)}
									clearable={false}
									backspaceRemoves={false}
									overrides={{
										Root: {
											style: {
												maxWidth: "165px",
												marginLeft: "14px",
												borderTopColor: "#D7DAE2",
												borderBottomColor: "#D7DAE2",
												borderLeftColor: "#D7DAE2",
												borderRightColor: "#D7DAE2",
												borderWidth: "1px",
												borderRadius: "3px",
												boxShadow: "#00000029 0px 2px 3px",
											},
										},
										InputContainer: {
											style: {
												width: "fit-content",
											},
										},
										ValueContainer: {
											style: {
												height: "24px",
												fontSize: "14px",
												fontWeight: 600,
												lineHeight: "16px",
												paddingTop: "0",
												paddingBottom: "0",
												paddingLeft: "6px",
											},
										},
										SelectArrow: {
											component: () => <FontAwesomeIcon fontWeight="bold" color="#A4AFB7" icon={["fal", "chevron-down"]} size="xs" />,
										},
										OptionContent: {
											style: {
												textTransform: "capitalize",
												whiteSpace: "nowrap",
											},
										},
										DropdownContainer: {
											style: {
												width: "unset",
												minWidth: "122px",
											},
										},
									}}
								/>
								<Spacer style={{ width: "40px" }} />
								<ZenButton onClick={() => setManageMode(true)}>Add new service</ZenButton>
							</div>
						</>
					)}
				</div>
				{!manageMode && (
					<ClientServicesList
						setSelectedHealthTeam={setSelectedHealthTeam}
						selectedHealthTeam={selectedHealthTeam}
						setManageMode={setManageMode}
						clientID={client.id}
						filter={filter}
						setFilter={setFilter}
					/>
				)}
				{manageMode && (
					<HealthTreatmentTeamEdit
						setSelectedHealthTeam={setSelectedHealthTeam}
						selectedHealthTeam={selectedHealthTeam}
						clientID={client.id}
						setManageMode={setManageMode}
						setFilter={setFilter}
					/>
				)}
			</div>
		</ZenCard>
	)
}

interface ManageRelatedServicesProps {
	setManageMode: React.Dispatch<React.SetStateAction<boolean>>
	clientID: string
	selectedHealthTeam: ClientHealthTreatmentTeam | null
	setSelectedHealthTeam: (h: ClientHealthTreatmentTeam | null) => void
	setFilter: React.Dispatch<React.SetStateAction<Value>>
}

interface ClientServicesListProps extends ManageRelatedServicesProps {
	filter: Value
}

// list table here
const ClientServicesList = (props: ClientServicesListProps) => {
	const { setManageMode, selectedHealthTeam, setSelectedHealthTeam, clientID, filter } = props
	const limit = 20
	const [offset, setOffset] = React.useState(0)
	const [sortColumn, setSortColumn] = React.useState<string>("Name")
	const [sortAsc, setSortAsc] = React.useState<boolean>(true)

	// Archive modal items
	const [archiveModalOpen, setArchiveModalOpen] = React.useState<boolean>(false)
	const { mutate: archive, payload: archivePayload, loading: archiving, error } = useMutation<Client>(fetching.mutation.clientHealthTeamArchive)
	const clientHealthTreatmentTeams = useQuery(
		fetching.query.clientHealthTreatmentTeamGet({
			clientID: clientID,
			isArchived: filter[0].label === FilterBy.Archived,
		}),
	)

	const manageView = (row: ClientHealthTreatmentTeam) => {
		setManageMode(true)
		setSelectedHealthTeam(row)
	}

	const archiveView = (row: ClientHealthTreatmentTeam) => {
		setArchiveModalOpen(true)
		setSelectedHealthTeam(row)
	}

	const [unarchiveModalOpen, setUnarchiveModalOpen] = React.useState<boolean>(false)
	const clientHealthTeamUnarchive = useMutation(fetching.mutation.clientHealthTeamUnarchive)
	const unarchiveView = (row: ClientHealthTreatmentTeam) => {
		setUnarchiveModalOpen(true)
		setSelectedHealthTeam(row)
	}

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	const [css] = useStyletron()
	const notePopover = css({
		padding: "20px",
		background: "white",
		width: "450px",
	})

	const NoteCard = (note: string) => {
		return (
			<ZenCard className={notePopover}>
				<Paragraph2>Notes: {note}</Paragraph2>
			</ZenCard>
		)
	}

	const cardStyle = css({
		borderLeft: "4px solid " + ZenTheme.colors.primaryGreen,
		width: "310px",
		height: "175px",
		display: "flex",
		cursor: "pointer",
		":hover": {
			backgroundColor: "#eef0f9",
		},
	})
	const header = css({
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	})
	const content = css({
		width: "100%",
	})
	const bodyText = css({
		display: "-webkit-box",
		"-webkit-line-clamp": 2,
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
		wordWrap: "break-word",
		hyphens: "auto",
	})
	const phone = css({ display: "-webkit-box", "-webkit-line-clamp": 1, "-webkit-box-orient": "vertical", overflow: "hidden" })

	const CardResolver = (row: ClientHealthTreatmentTeam) => {
		const { contactName, consentNotes, contactNumber, consentToContact, typeOfSupport, organisation, deletedAt } = row
		return (
			<ZenCard className={cardStyle} onClick={() => manageView(row)}>
				{error && <ErrorNotification messageOrPayload={archivePayload} />}

				<div className={header}>
					<LabelMedium>{typeOfSupport}</LabelMedium>
					<RowActions
						menuItems={
							!deletedAt
								? [
										{
											icon: "trash-alt",
											label: "Archive",
											onClick: () => {
												archiveView(row)
											},
										},
										{
											icon: "edit",
											label: "Edit",
											onClick: () => {
												manageView(row)
											},
										},
								  ]
								: [
										{
											icon: "trash-restore-alt",
											label: "Restore",
											onClick: () => {
												unarchiveView(row)
											},
										},
								  ]
						}
					/>
				</div>
				<Divider style={{ margin: "2px" }} />
				<div className={content}>
					<LabelSmall className={bodyText}>{contactName}</LabelSmall>
					{organisation && <LabelSmall className={bodyText}>{organisation}</LabelSmall>}
					<LabelSmall className={phone}>{contactNumber}</LabelSmall>

					<LabelSmall className={bodyText}>Consent: {yesNo(consentToContact)}</LabelSmall>
					{consentToContact && consentNotes && (
						<StatefulPopover
							overrides={{
								Body: {
									style: {
										wordWrap: "break-word",
									},
								},
							}}
							content={consentNotes && NoteCard(consentNotes)}
							triggerType={TRIGGER_TYPE.hover}
						>
							<LabelSmall className={bodyText}>{consentNotes && <>Notes: {truncate(consentNotes, 25)}</>}</LabelSmall>
						</StatefulPopover>
					)}
				</div>
			</ZenCard>
		)
	}

	if (!clientHealthTreatmentTeams.payload) return <NoDataText />
	const containerStyle = css({
		flex: "1",
		minHeight: "0",
	})

	return (
		<div className={containerStyle}>
			<ListTable
				handleSort={handleSort}
				cardView={true}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				rows={clientHealthTreatmentTeams.payload}
				cardViewResolver={(row: ClientHealthTreatmentTeam) => CardResolver(row)}
				onRowClick={(row: ClientHealthTreatmentTeam) => manageView(row)}
				columns={[]}
			/>
			<ZenPagination total={clientHealthTreatmentTeams.payload.length} limit={limit} offset={offset} setOffset={setOffset} />

			<ZenArchiveModal
				open={archiveModalOpen}
				onClose={() => setArchiveModalOpen(false)}
				loading={archiving}
				message={"service provider"}
				confirmArchive={() => {
					if (!selectedHealthTeam) return
					archive({ id: selectedHealthTeam.id }).then((resp) => {
						if (resp.error || !resp.payload) return
						setArchiveModalOpen(false)
						setSelectedHealthTeam(null)
						clientHealthTreatmentTeams.query()
					})
				}}
			/>

			<ZenArchiveModal
				open={unarchiveModalOpen}
				onClose={() => setUnarchiveModalOpen(false)}
				loading={clientHealthTeamUnarchive.loading}
				message={"service provider"}
				restoreMode
				confirmArchive={() => {
					if (!selectedHealthTeam) return
					clientHealthTeamUnarchive.mutate({ id: selectedHealthTeam.id }).then((resp) => {
						if (resp.error || !resp.payload) return
						setUnarchiveModalOpen(false)
						setSelectedHealthTeam(null)
						clientHealthTreatmentTeams.query()
					})
				}}
			/>
		</div>
	)
}

const HealthTreatmentTeamEdit = (props: ManageRelatedServicesProps) => {
	const { setManageMode, clientID, selectedHealthTeam, setSelectedHealthTeam } = props

	const { errors, handleSubmit, control, getValues, watch, setValue } = useForm()

	const { mutate, loading, error, payload } = useMutation<Client>(fetching.mutation.clientHealthTeamCreate)
	const {
		mutate: mutUpdate,
		loading: updateLoading,
		error: updateError,
		payload: updatePayload,
	} = useMutation<Client>(fetching.mutation.clientHealthTeamUpdate)

	const [fields, setFields] = React.useState<string[]>([])

	const defaultServices = [
		"GP",
		"Psychiatrist",
		"Psychologist",
		"CaseManager",
		"PublicTrustee",
		"Guardian",
		"Plan Nominee",
		"Support Coordinator",
		"Recovery Coach",
	]
	const servicesOptions = defaultServices.map((s) => {
		return {
			id: s,
			label: s === "GP" ? "GP" : splitOnCaps(s),
		}
	})

	const getFields = (supportType: string) => {
		const fields = ["ContactName", "Organisation", "ContactNumber", "FrequencyOfSupport"]
		if (supportType === "Psychiatrist") {
			return [...fields, "Practice"]
		}

		if (!defaultServices.includes(supportType)) {
			return fields.filter((c) => c !== "Organisation")
		}

		return fields
	}

	const [css] = useStyletron()
	const container = css({
		flex: "1",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		minHeight: "0",
	})
	const body = css({
		flex: "1",
		display: "flex",
		flexDirection: "column",
		minHeight: "0",
	})
	const scrollDiv = css({
		paddingRight: "10px",
		height: "100%",
		marginTop: "10px",
		maxHeight: "100%",
		width: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		paddingBottom: "10px",
	})

	const onSubmit = (formData: any) => {
		if (selectedHealthTeam) {
			mutUpdate({
				clientID: clientID,
				healthTeam: {
					...formData,
					consentNotes: formData.consentToContact ? formData.consentNotes : "",
					typeOfSupport: getValues().typeOfSupport[0].label,
					id: selectedHealthTeam.id,
				},
			}).then((resp) => {
				if (!resp.payload || resp.error) return
				setManageMode(false)
				setSelectedHealthTeam(null)
			})
			return
		}
		mutate({
			clientID: clientID,
			healthTeam: { ...formData, typeOfSupport: getValues().typeOfSupport[0].label },
		}).then((resp) => {
			if (!resp.payload || resp.error) return
			setManageMode(false)
			setSelectedHealthTeam(null)
		})
	}

	const typeOfSupport = watch("typeOfSupport")
	const consentToContact = watch("consentToContact")

	React.useEffect(() => {
		if (!typeOfSupport || typeOfSupport.length < 1 || !typeOfSupport[0].id) return
		const supportID = typeOfSupport[0].id
		setFields(getFields(supportID))
	}, [typeOfSupport]) // eslint-disable-line react-hooks/exhaustive-deps

	const loadFields = React.useCallback(() => {
		if (!selectedHealthTeam) return
		if (selectedHealthTeam.typeOfSupport) {
			setValue("typeOfSupport", [
				{
					id: selectedHealthTeam.typeOfSupport?.replace(/ /g, ""),
					label: selectedHealthTeam.typeOfSupport,
				},
			])
		}
		if (selectedHealthTeam.contactName) {
			setValue("ContactName", selectedHealthTeam.contactName)
		}
		if (selectedHealthTeam.organisation) {
			setValue("Organisation", selectedHealthTeam.organisation)
		}
		if (selectedHealthTeam.contactNumber) {
			setValue("ContactNumber", selectedHealthTeam.contactNumber)
		}
		if (selectedHealthTeam.frequencyOfSupport) {
			setValue("FrequencyOfSupport", selectedHealthTeam.frequencyOfSupport)
		}
		if (selectedHealthTeam.practice) {
			setValue("Practice", selectedHealthTeam.practice)
		}
		if (selectedHealthTeam.consentNotes) {
			setValue("consentNotes", selectedHealthTeam.consentNotes)
		}
	}, [selectedHealthTeam, setValue])
	React.useEffect(() => {
		loadFields()
	}, [loadFields])

	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
			<div className={body}>
				<div className={scrollDiv}>
					<ZenSelect
						label="Type of Support - Please select or enter a health team provider"
						formName="typeOfSupport"
						formRef={control}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Type of Support",
							},
						}}
						inputError={errors.typeOfSupport}
						clearable={false}
						creatable
						options={servicesOptions}
					/>

					{fields.map((f) => {
						if (f === "ContactNumber") return <ZenInput key={f} label={"Phone"} nameRef={f} inputError={errors[f]} formRef={control} type="tel" required />

						return <ZenInput key={f} label={splitOnCaps(f)} nameRef={f} inputError={errors[f]} formRef={control} required />
					})}

					{typeOfSupport && (
						<ZenCheckbox label="Consent to Contact" formName="consentToContact" formRef={control} checked={selectedHealthTeam?.consentToContact} />
					)}

					{consentToContact && (
						<ZenTextArea placeholder={"Enter notes"} inputError={errors.note} label={"Consent Notes"} nameRef={"consentNotes"} formRef={control} />
					)}
				</div>
			</div>
			{/* err message */}
			{error && <ErrorNotification messageOrPayload={payload} />}
			{updateError && <ErrorNotification messageOrPayload={updatePayload} />}
			{Object.keys(errors).length !== 0 && errors && <ErrorNotification messageOrPayload={getErrMsg(errors)} />}
			<CancelAndSaveButtons
				cancelFn={() => {
					setManageMode(false)
					props.setSelectedHealthTeam(null)
				}}
				isLoading={loading || updateLoading}
			/>
		</form>
	)
}
