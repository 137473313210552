import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { LabelMedium } from "baseui/typography"
import { useHistory } from "react-router-dom"
import { Client } from "../../../../types/types"
import { SessionPanel } from "../../../sessions/sessionPanel"
import { ZenButton } from "../../../zenComponents/zenButtons"

interface ClientSessionProps extends Client {
	triggerQuery?: boolean
	setActiveKey: React.Dispatch<React.SetStateAction<string>>
}

export const ClientSession = (props: ClientSessionProps) => {
	const { id, triggerQuery } = props
	const [css] = useStyletron()
	const history = useHistory()
	const container = css({
		display: "flex",
		flexDirection: "column",
		maxHeight: "100%",
		height: "100%",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "15px",
	})
	const scrollable = css({
		height: "100%",
		overflowX: "hidden",
		overflowY: "auto",
	})
	return (
		<div className={container}>
			<div className={title}>
				<LabelMedium>Upcoming sessions</LabelMedium>
			</div>
			<div className={scrollable}>
				<SessionPanel clientID={id} showPastSessions={false} triggerQuery={triggerQuery} />
			</div>
			<Block display="flex" marginTop="15px" justifyContent="space-between">
				<ZenButton
					altKind="tertiary"
					onClick={() => {
						history.push({ hash: "sessions" })
						props.setActiveKey("sessions")
					}}
				>
					View all
				</ZenButton>

				<ZenButton onClick={() => history.push(`/portal/clients/${id}/calendar`)}>Calendar</ZenButton>
			</Block>
		</div>
	)
}
