import * as React from "react"
import { useStyletron } from "baseui"
import { ZenCard } from "../../common"
import { Form, RolePermission } from "../../../types/types"
import { Prompt, useHistory } from "react-router-dom"
import { useQuery, useMutation } from "react-fetching-library"
import { FormType, FormTypeName, RiskCriteria } from "../../../types/enums"
import { ErrorNotification } from "../../errorBox"
import { ZenProgressBar } from "../../zenComponents/zenProgressBar"
import { AuthContainer } from "../../../controllers/auth"
import HazardRiskStep1 from "./hazardRiskStep1"
import HazardRiskStep2 from "./hazardRiskStep2"
import HazardRiskStep3 from "./hazardRiskStep3"
import HazardRiskInfoCard from "./hazardRiskInfoCard"
import { ZenButton } from "../../zenComponents/zenButtons"
import { fetching } from "../../../fetching"
import { useForm } from "react-hook-form"
import { Loading } from "../../loading"
import { useZenToast } from "../../zenComponents/useZenToast"

import { routes } from "routes"
import { LabelSmall } from "baseui/typography"

export interface HazardRiskIdentificationFormData {
	reporterID: string
	reporterName: string
	reporterPosition: string
	location: string
	dateRiskWasIdentified: Date
	riskTypes: boolean[]

	description: string
	cause: string
	result: string

	assessment: {
		risks: {
			criteria: RiskCriteria
			consequence: string
			likelihood: string
			levelOfRiskL: string
			riskRank: string
		}[]
		proposedAction: string
		resourceRequirements: string
		implementationCompletionDate: Date
		recommendationDueDate: Date
		sqCommitteeNotified: boolean
		sqCommitteeNotifiedDate: Date
		riskRegisterToBeUpdated: boolean
		riskRegisterUpdatedDate: Date
		adviseRiskNumber: string
	}
}

const HazardRiskIdentificationForm = () => {
	const history = useHistory()
	const { push } = history
	const searchArgs = new URLSearchParams(history.location.search)
	const { showToast } = useZenToast()
	const defaultStep = searchArgs.get("step")

	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const executiveCorporate = hasPermission(RolePermission.ExecutiveCorporate)

	// Get existing form
	const formID = searchArgs.get("formID")
	const [requiresAction, setRequiresAction] = React.useState(false)
	const [viewOnly, setViewOnly] = React.useState(false) // members can see full form (ie: step 3) but can not longer edit this form

	const { control, formState, handleSubmit, setValue, trigger, errors } = useForm<HazardRiskIdentificationFormData>({
		defaultValues: {
			reporterID: "",
			reporterName: "",
			reporterPosition: "",
			location: "",
			riskTypes: Array(15).fill(false),
			dateRiskWasIdentified: new Date(),
			assessment: {
				risks: Object.values(RiskCriteria).map((c) => {
					return {
						criteria: c as RiskCriteria,
						consequence: "",
						likelihood: "",
						levelOfRiskL: "",
						riskRank: "",
					}
				}),
			},
		},
	})

	const {
		payload: data,
		loading: formLoading,
		error: getFormError,
		query: getForm,
	} = useQuery<Form<HazardRiskIdentificationFormData>>(fetching.query.getForm(formID || ""), false)

	const [isEditMode, setIsEditMode] = React.useState(!formID)

	React.useEffect(() => {
		if (!formID) return
		getForm().then((resp) => {
			if (resp.error || !resp.payload) return
			if (isEditMode) return

			const data = resp.payload

			// load existing form data
			const content: HazardRiskIdentificationFormData = data.content
			setValue("reporterID", content.reporterID)
			setValue("reporterName", content.reporterName)
			setValue("reporterPosition", content.reporterPosition)
			setValue("location", content.location)
			setValue("dateRiskWasIdentified", new Date(content.dateRiskWasIdentified))
			setValue("riskTypes", content.riskTypes)
			setValue("description", content.description)
			setValue("cause", content.cause)
			setValue("result", content.result)
			setValue("assessment", content.assessment)

			setRequiresAction(data.requiresAction)

			// Jump to the last step
			setStep(2)

			// set form to view only if
			// it is completed OR
			// current worker is not executive corporate
			if (!data.requiresAction || !executiveCorporate) {
				setViewOnly(true)
				setLastStep(2)
				return
			}

			// if current worker is executive corporate and the form is incomplete
			// Automatically start with edit mode
			setIsEditMode(true)
		})
	}, [formID, getForm, executiveCorporate, isEditMode, setValue])

	// Editing
	const [lastStep, setLastStep] = React.useState<number>(executiveCorporate && !!formID ? 2 : 1)
	const [step, setStep] = React.useState<number>(defaultStep ? +defaultStep : 0)
	const canGoBackStep = step > 0 && !formID
	React.useEffect(() => {
		const s = searchArgs.get("step")
		if (s === null || +s === step) return
		const _step = s ? +s : 0
		setStep(_step)
	}, [searchArgs.get("step")]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (formLoading) return
		// set default date
		setValue("dateRiskWasIdentified", new Date())
	}, [formLoading, setValue])

	React.useEffect(() => {
		if (formLoading) return
		// Set default
		if (!!currentUser && step === 0) {
			setValue("reporterID", currentUser.id)
			setValue("reporterName", `${currentUser.firstName} ${currentUser.lastName}`)
			setValue("reporterPosition", currentUser.position.name)
		}
	}, [currentUser, setValue, step, formLoading])

	const {
		mutate: createForm,
		loading: creatingForm,
		payload: createFormData,
		errorObject: createFormError,
	} = useMutation<Form<HazardRiskIdentificationFormData>>(fetching.mutation.formCreate)

	const {
		mutate: updateForm,
		loading: updatingForm,
		payload: updateFormData,
		errorObject: updateFormError,
	} = useMutation<Form<HazardRiskIdentificationFormData>>(fetching.mutation.formUpdate)

	const loading = creatingForm || updatingForm || formLoading

	// On Created/Updated
	React.useEffect(() => {
		if (!createFormData) return
		push(routes.forms.root)
	}, [createFormData, push])

	const onSaveForm = handleSubmit(async (data) => {
		if (creatingForm || updatingForm || (!formID && step < 1)) return

		const input = {
			content: data,
			requiresAction: true,
		}

		if (formID) {
			if (executiveCorporate) input.requiresAction = false // form was reviewed by executiveCorporate

			const { status } = await updateForm({
				id: formID,
				...input,
			})
			if (status === 200) {
				control.updateFormState({ isDirty: false })
				// required action completed
				showToast("Risk Identification document updated successfully.", "positive")
				history.push(routes.forms.root)
			}
			return
		}
		input.requiresAction = true // this form needs to be reviewd by an executiveCorporate

		const { status } = await createForm({
			input: {
				name: `OSHF 024 ${FormTypeName(FormType.HazardRiskIdentification)} Form | ${data.location} | ${data.dateRiskWasIdentified.toLocaleDateString(
					undefined,
					{
						day: "numeric",
						month: "numeric",
						year: "numeric",
					},
				)}`,
				type: FormType.HazardRiskIdentification,
				...input,
			},
		})

		showToast("Risk Identification document created successfully.", "positive")
		if (status === 200 && createFormData) {
			control.updateFormState({ isDirty: false })
			history.push(routes.forms.root)
		}
	})
	const nextStep = async () => {
		if (step === lastStep) return

		// validate
		if (isEditMode) {
			let valid = false
			switch (step) {
				case 0:
					valid = await trigger(["reporterName", "reporterPosition", "location", "dateRiskWasIdentified"])
					break
				case 1:
					valid = await trigger(["description", "result"])
					break
				case 2:
					valid = await trigger(["assessment.proposedAction", "assessment.resourceRequirements", "assessment.risks"])
					break
			}
			if (!valid) return
		}

		// next step
		searchArgs.set("step", (step + 1).toString())
		history.push({ search: searchArgs.toString() })
		if (requiresAction && step + 1 === lastStep && !isEditMode) {
			// automatically start edit mode on reach step 3
			setIsEditMode(true)
		}
		setStep(step + 1)
	}
	const prevStep = () => {
		if (step <= 0) return
		searchArgs.set("step", (step - 1).toString())
		history.push({ search: searchArgs.toString() })
		setStep(step - 1)
	}

	// Styling
	const [css] = useStyletron()
	const containerStyle = css({
		display: "flex",
		maxHeight: "100%",
		width: "100%",
		overflowY: "auto",
	})
	const formStyle = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
	})
	const sectionStyle = css({
		overflowY: "auto",
		marginTop: "20px",
		flex: 1,
	})
	const sectionInnerStyle = css({
		width: "50%",
		minWidth: "400px",
		marginLeft: "auto",
		marginRight: "auto",
	})
	const footerStyle = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "end",
		height: createFormError || updateFormError || getFormError ? "98px" : "unset",
		marginTop: "10px",
	})
	const footerButtons = css({
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	})
	const sideBarStyle = css({
		display: "flex",
		flexDirection: "column",
	})
	const infoCardStyle = css({
		marginTop: "10px",
		height: "100%",
		visibility: step >= 2 ? "unset" : "hidden",
		maxWidth: "unset",
		overflowY: "auto",
	})
	const sectionTitleStyle = css({
		display: "flex",
		fontWeight: "bold",
		alignItems: "center",
	})

	if (loading) return <Loading />

	return (
		<div className={containerStyle}>
			<ZenCard style={{ margin: 3 }}>
				<form autoComplete="off" className={formStyle} onSubmit={onSaveForm}>
					<div>
						{!formID ? (
							<ZenProgressBar labels={["Identify risk", "Describe risk"]} currentStep={step} />
						) : (
							<div className={sectionTitleStyle}>
								<div style={{ marginLeft: "4px" }}>Executive Corporate Services Assessment</div>
								{requiresAction && <LabelSmall marginLeft="4px"> - Required Executive Corporate to finish this part</LabelSmall>}
							</div>
						)}
					</div>
					<div className={sectionStyle}>
						<div className={sectionInnerStyle}>
							{step === 0 && <HazardRiskStep1 control={control} errors={errors} disabled={loading || !isEditMode} />}
							{step === 1 && <HazardRiskStep2 control={control} errors={errors} disabled={loading || !isEditMode} />}
						</div>
						{step === 2 && <HazardRiskStep3 control={control} errors={errors} disabled={loading || !isEditMode} />}
					</div>
					{/* Footer */}
					<div className={footerStyle}>
						{updateFormError && <ErrorNotification messageOrPayload={updateFormData} />}
						{createFormError && <ErrorNotification messageOrPayload={createFormData} />}
						{getFormError && <ErrorNotification messageOrPayload={data} />}
						<div className={footerButtons}>
							{!viewOnly && (
								<ZenButton
									width="100px"
									type="button"
									onClick={() => {
										if (canGoBackStep) prevStep()
										else history.push(routes.forms.root)
									}}
									disabled={loading}
									altKind="secondary"
								>
									{!canGoBackStep ? "Cancel" : "Back"}
								</ZenButton>
							)}
							<div>
								{isEditMode && (step >= lastStep || !!formID) && (
									<ZenButton width="100px" marginLeft="10px" type="button" onClick={onSaveForm} isLoading={loading}>
										Submit
									</ZenButton>
								)}

								{step < lastStep && (
									<ZenButton width="100px" marginLeft={"10px"} disabled={loading} type="button" onClick={nextStep}>
										Continue
									</ZenButton>
								)}
							</div>
						</div>
					</div>
				</form>
			</ZenCard>
			<div key="sidebar" className={sideBarStyle}>
				{(executiveCorporate || viewOnly) && (
					<>
						{step === 2 && (
							<>
								<HazardRiskInfoCard visible={true} />
								<ZenCard className={infoCardStyle}>
									<HazardRiskStep1 control={control} errors={errors} disabled infoOnly />
									<HazardRiskStep2 control={control} errors={errors} disabled infoOnly />
								</ZenCard>
							</>
						)}
					</>
				)}
			</div>
			<Prompt
				when={formState.isDirty}
				message={(location) => {
					if (location.pathname === "/portal/forms/hazard~risk_identification") {
						return true
					}
					return "You have unsaved changes, are you sure you want to leave?"
				}}
			/>
		</div>
	)
}

export default HazardRiskIdentificationForm
