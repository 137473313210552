import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge, ParagraphSmall } from "baseui/typography"
import { Control, Controller, useFormContext } from "react-hook-form"
import { WellbeingFormData } from "./wellbeingPlan"
import { ZenInputOverrides, ZenDatepickerOverrides, ZenSelectOverrides } from "../../../themeOverrides"
import { FormControl } from "baseui/form-control"
import { Select } from "baseui/select"
import { Textarea } from "baseui/textarea"
import { DatePicker } from "baseui/datepicker"
import { Domain, DomainInfo } from "./wellbeingDomain"
import { ZenButton } from "../../zenComponents/zenButtons"

interface ActionPlanProps {
	fieldCount?: number
	disabled?: boolean
}

export interface ActionPlan {
	domain: Domain
	goal: string
	myActions: string
	whoCanHelp: string
	when: Date
}

export const WellbeingActionPlan = (props: ActionPlanProps) => {
	const { fieldCount, disabled } = props

	const [count, setCount] = React.useState(1)
	React.useEffect(() => {
		if (fieldCount) setCount(fieldCount)
	}, [fieldCount])
	const { control } = useFormContext<WellbeingFormData>()

	return (
		<div>
			<LabelLarge $style={{ fontWeight: "bold" }}>Their Wellbeing Action Plan</LabelLarge>
			<ParagraphSmall>
				Of the 7 areas of wellbeing scored in the first section, select the area/s of the most importance for your client to focus on in their Wellbeing Action
				Plan.
				<br />
				Remember when setting goals that they are <strong>SMART</strong> goals: <strong>S</strong> - Specific, <strong>M</strong> - Measurable,{" "}
				<strong>A</strong> - Achievable, <strong>R</strong> - Relevant, <strong>T</strong> - Time-based
			</ParagraphSmall>

			{Array.from(Array(count)).map((_, index) => (
				<DomainActionPlan key={`actionPlan-${index}`} index={index} control={control} disabled={disabled} />
			))}

			{!disabled && (
				<ZenButton type="button" onClick={() => setCount(count + 1)}>
					Add Wellbeing Domain
				</ZenButton>
			)}
		</div>
	)
}

const DomainActionPlan = (props: { index: number; control: Control<WellbeingFormData>; disabled?: boolean }) => {
	const { index, control, disabled } = props

	const selectValues = Object.keys(Domain).map((item) => ({ id: item, label: item + DomainInfo(item as Domain).sub }))

	const [css, theme] = useStyletron()
	const containerStyle = css({
		padding: "12px",
		borderRadius: "3px",
		backgroundColor: theme.colors.backgroundSecondary,
		marginBottom: "12px",
		boxShadow: theme.lighting.shadow400,
	})
	const helpStyle = css({
		color: "#BEBEBE",
		marginLeft: "7px",
	})
	const rowStyle = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
			justifyContent: "initial",
		},
	})
	const textAreaInputStyle = css({
		flex: "1",
		marginLeft: "10px",
	})

	return (
		<div className={containerStyle}>
			<FormControl
				label={
					!disabled ? (
						<span>
							Select Wellbeing Domain<span className={helpStyle}>eg. Mind</span>
						</span>
					) : (
						"Wellbeing Domain"
					)
				}
			>
				<Controller
					name={`actionPlans[${index}].domain`}
					control={control}
					render={({ onChange, value }: any) => {
						const v = selectValues.find((i) => i.id === value)
						return (
							<Select
								options={selectValues}
								onChange={({ value }) => value[0] && onChange(value[0].id as Domain)}
								value={v ? [v] : []}
								overrides={ZenSelectOverrides}
								disabled={disabled}
								clearable={false}
							/>
						)
					}}
				/>
			</FormControl>
			<div className={rowStyle}>
				<div className={textAreaInputStyle}>
					<FormControl label={<span>Their Goal</span>}>
						<Controller
							name={`actionPlans[${index}].goal`}
							control={control}
							as={Textarea}
							placeholder="What they would like to happen (try to be specific)"
							disabled={disabled}
							overrides={ZenInputOverrides}
						/>
					</FormControl>
				</div>
				<div className={textAreaInputStyle}>
					<FormControl label={<span>Their Actions</span>}>
						<Controller
							name={`actionPlans[${index}].myActions`}
							control={control}
							as={Textarea}
							placeholder="How they are going to achieve their goal (try to be specific)"
							disabled={disabled}
							overrides={ZenInputOverrides}
						/>
					</FormControl>
				</div>
			</div>
			<div className={rowStyle}>
				<div className={textAreaInputStyle}>
					<FormControl label="Who can help them?">
						<Controller
							name={`actionPlans[${index}].whoCanHelp`}
							control={control}
							as={Textarea}
							placeholder="Enter helpers"
							disabled={disabled}
							overrides={ZenInputOverrides}
						/>
					</FormControl>
				</div>
				<div className={textAreaInputStyle}>
					<FormControl label="By when?">
						<Controller
							name={`actionPlans[${index}].when`}
							control={control}
							render={({ value, onChange }: any) => (
								<DatePicker
									onChange={({ date }) => onChange(date)}
									formatString={"dd/MM/yyyy"}
									value={value ? new Date(value) : new Date()}
									minDate={new Date()}
									disabled={disabled}
									overrides={ZenDatepickerOverrides}
								/>
							)}
						/>
					</FormControl>
				</div>
			</div>
		</div>
	)
}
