import * as React from "react"
import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import { LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { AuthContainer } from "../../../../controllers/auth"
import { fetching } from "../../../../fetching"
import { useCheckWorkerAvailability } from "../../../../helpers/useCheckWorkerAvailability"
import { alignDate, setTimezoneToTime, snakeToTitle } from "../../../../helpers/utils"
import { BasicLabel, Client, ClientWithContact, RolePermission, TZString, ZenDateTimeFormat } from "../../../../types/types"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { AvailableCheck, Divider, Spacer, ZenCard } from "../../../common"
import { ErrorNotification } from "../../../errorBox"
import { useZenToast } from "../../../zenComponents/useZenToast"
import { ZenButton, ZenButtonGroup } from "../../../zenComponents/zenButtons"
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList"
import { ZenInput } from "../../../zenComponents/zenInput"
import { ZenClientSelect, ZenPlaceSelect, ZenSelect, ZenTimezoneSelect, ZenUserSelect } from "../../../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../../../zenComponents/zenTime"
import { SessionMeetingType } from "../../newClientAppointment/singleSessionGeneralForm"
import { SessionViewProps } from "../sessionInfo"
import { ZenTheme } from "../../../../themeOverrides"
import { SessionFundingSourceForm } from "../../SessionFundingSource"
import { PortalContainer } from "../../../../controllers/portal"
import { SessionSingleInput } from "../../../../fetching/inputType"
import { ZenModal } from "../../../zenComponents/zenModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SessionClientType } from "../../../../types/enums"

export const EditSingleSession = (props: SessionViewProps) => {
	const {
		id,
		worker,
		clients,
		startTime,
		endTime,
		sessionLocation,
		office,
		meetingType,
		sessionFundingSources,
		setDisplayMode,
		refetch,
		triggerQuerySessionList,
		refetchTimesheet,
		clientType,
		timesheets,
		buddySessionMentor,
	} = props
	const [css] = useStyletron()

	const mainWorkerClaimTimesheet = React.useMemo(() => {
		if (!worker || !timesheets || timesheets.length === 0) return false
		const targetTimesheet = timesheets.find((t) => t.userID === worker.id)
		if (!targetTimesheet) return false

		if (!targetTimesheet.readyAt) return false

		return true
	}, [worker, timesheets])

	const mentorHasClaimTimesheet = React.useMemo(() => {
		if (!buddySessionMentor || !timesheets || timesheets.length < 2) return false
		const targetTimesheet = timesheets.find((t) => t.userID === buddySessionMentor.id)
		if (!targetTimesheet) return false

		if (!targetTimesheet.readyAt) return false

		return true
	}, [buddySessionMentor, timesheets])

	// auth
	const { hasPermission } = AuthContainer.useContainer()
	const { timezone: globalTimezone, fetchIncompleteSessionAlert } = PortalContainer.useContainer()
	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === props.timezoneID)
		if (!target) return globalTimezone
		return target
	}, [props.timezoneID]) // eslint-disable-line react-hooks/exhaustive-deps

	const { mutate, payload, error: mutateError, loading } = useMutation(fetching.mutation.sessionSingleUpdate)
	const { control, setValue, getValues, handleSubmit, errors, watch, trigger } = useForm()
	const { showToast } = useZenToast()

	// handle post session reset message modal
	const [sessionInput, setSessionInput] = React.useState<SessionSingleInput>()
	const [resetPostDetailReason, setResetPostDetailReason] = React.useState("")
	const [remindMentorTimesheet, setRemindMentorTimesheet] = React.useState("")

	// manage exclude user/client id, restrict array length to 0 or 1
	const [selectedWorkerID, setSelectedWorkerID] = React.useState<string[]>([worker.id])
	const [selectedClientID, setSelectedClientID] = React.useState<string[]>(clients ? [clients[0].id] : [])

	const nonBillable = sessionFundingSources.length > 0 && sessionFundingSources[0].fundingSource.label === "Non-billable"
	const subSupportTypeGet = useParameterizedQuery(fetching.query.getNonBillableSubSupportTypes)
	const subSubSupportTypeGet = useParameterizedQuery(fetching.query.getNonBillableSubSubSupportTypes)

	// query non-billable support type options
	const [nonBillableSupportTypeOptions, setNonBillableSupportTypeOptions] = React.useState<BasicLabel[]>([])
	const getSupportTypes = useQuery<BasicLabel[]>(fetching.query.getSupportTypesByFundingSourceID(sessionFundingSources[0].fundingSource.id), nonBillable)
	React.useEffect(() => {
		// only trigger when the session is non-billable
		if (getSupportTypes.loading || getSupportTypes.error || !getSupportTypes.payload) return
		setNonBillableSupportTypeOptions(getSupportTypes.payload)
	}, [getSupportTypes.payload, getSupportTypes.loading, getSupportTypes.error])

	React.useEffect(() => {
		if (clientType) {
			const target = Object.entries(SessionClientType)
				.map((sct) => ({ id: sct[1], label: snakeToTitle(sct[0]) }))
				.find((sct) => sct.id === clientType)
			if (!target) return
			setValue("clientType", [{ ...target }])
		}
	}, [clientType, setValue])

	const isBuddySession = watch("buddySessionWithMentor")

	// Prepare form data for multiple funding sources
	const [placeholder, setPlaceholder] = React.useState<number[]>([0]) // use for tracking the index of session funding source
	const [availableIndexes, setAvailableIndexes] = React.useState<number[]>([2, 1]) // additional index available for new funding sources
	const addMoreFundingSource = () => {
		let list = [...availableIndexes]
		const index = list.pop()

		// if the list is empty, then skip
		if (!index) return

		// store remain indexes
		setAvailableIndexes(list)

		// append index to placeholder list
		setPlaceholder((p) => p.concat(index))
	}

	// the first funding source
	const baseFundingSource = watch("fundingSource-0")

	React.useEffect(() => {
		// if the base funding source is "NDIA", clean up all the additional funding sources
		if (baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA") {
			// clean up selected data
			placeholder.forEach((p) => {
				// skip base funding source
				if (p === 0) return

				// clean up the rest
				setValue(`fundingSource-${p}`, [])
				setValue(`contractArea-${p}`, [])
				setValue(`supportType-${p}`, [])
				setValue(`subSupportType-${p}`, [])
				setValue(`subSubSupportType-${p}`, [])
			})

			setPlaceholder([0])
			setAvailableIndexes([2, 1])
		}
	}, [baseFundingSource, setValue, setPlaceholder, setAvailableIndexes]) // eslint-disable-line react-hooks/exhaustive-deps

	// load existing data
	React.useEffect(() => {
		// get offset minutes different
		const localTimezoneOffset = new Date().getTimezoneOffset()
		const setTimezoneOffset = timezone.offsetMinutes
		const offsetDiff = localTimezoneOffset - setTimezoneOffset

		// modify time to current timezone
		setValue("startTime", moment(startTime).add(offsetDiff, "minute").toDate())
		setValue("endTime", moment(endTime).add(offsetDiff, "minute").toDate())
	}, [startTime, endTime]) // eslint-disable-line react-hooks/exhaustive-deps

	// load session funding sources
	React.useEffect(() => {
		if (sessionFundingSources.length > 0) {
			setPlaceholder(sessionFundingSources.map((_, i) => i))
			sessionFundingSources.forEach((sfs, i) => {
				setAvailableIndexes((ais) => ais.filter((ai) => ai !== i))
				setValue(`fundingSource-${i}`, [sfs.fundingSource])
				setValue(`contractArea-${i}`, sfs.contractArea ? [sfs.contractArea] : [])
				setValue(`supportType-${i}`, sfs.supportType ? [sfs.supportType] : [])
				setValue(`subSupportType-${i}`, sfs.subSupportType ? [sfs.subSupportType] : [])
				setValue(`subSubSupportType-${i}`, sfs.subSubSupportType ? [sfs.subSubSupportType] : [])
			})
			if (sessionFundingSources[0].supportType) {
				updateSubSupportTypeOptions(sessionFundingSources[0].supportType.id)
			}
			if (sessionFundingSources[0].subSupportType) {
				updateSubSubSupportTypeOptions(sessionFundingSources[0].subSupportType.id)
			}
		}
	}, [sessionFundingSources]) // eslint-disable-line react-hooks/exhaustive-deps

	const [nonBillableSubSupportTypeOptions, setNonBillableSubSupportTypeOptions] = React.useState<Option[]>([])
	const [nonBillableSubSubSupportTypeOptions, setNonBillableSubSubSupportTypeOptions] = React.useState<Option[]>([])
	const updateSubSupportTypeOptions = (supportTypeID: string) =>
		subSupportTypeGet.query({ supportTypeID }).then((resp) => {
			if (resp.error || !resp.payload) return
			setNonBillableSubSupportTypeOptions(resp.payload)
		})
	const updateSubSubSupportTypeOptions = (subSupportTypeID: string) =>
		subSubSupportTypeGet.query({ subSupportTypeID }).then((resp) => {
			if (resp.error || !resp.payload) return
			setNonBillableSubSubSupportTypeOptions(resp.payload)
		})

	// get HelpingMinds offices
	const [officeOptions, setOfficeOptions] = React.useState<Option[]>([])
	const officeAll = useQuery(fetching.query.getOfficeAll())
	React.useEffect(() => {
		if (officeAll.loading || officeAll.error || !officeAll.payload) return
		setOfficeOptions(officeAll.payload.map((o) => ({ id: o.id, label: o.name })).concat({ id: "other", label: "Other" }))
	}, [officeAll.payload, officeAll.loading, officeAll.error])
	const selectedOffice: Value = watch("selectedOffice")
	// handle meeting type
	React.useEffect(() => {
		if (meetingType !== SessionMeetingType.InPerson) {
			setValue("otherMeetingMethod", sessionLocation)
			return
		}
		if (baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label !== "NDIA") {
			setValue("selectedOffice", office ? [{ ...office, label: office.name }] : [{ id: "other", label: "Other" }])
		}
		setValue("location", sessionLocation ? [{ id: sessionLocation, label: sessionLocation }] : [])
	}, [meetingType, sessionLocation, office, baseFundingSource]) // eslint-disable-line react-hooks/exhaustive-deps

	const [currentMeetingTypeKey, setCurrentMeetingTypeKey] = React.useState<string>(meetingType)
	const handleOnMeetingTypeChange = (key: string) => {
		// erase data
		setValue("location", [])
		if (key === SessionMeetingType.Telephone && !!watchClients && watchClients.length === 1 && !!watchClients[0].currentContact) {
			// default to client phone number
			setValue("otherMeetingType", watchClients[0].currentContact.mobileNumber || watchClients[0].currentContact.telephoneNumber || "")
		} else setValue("otherMeetingType", "")

		setCurrentMeetingTypeKey(key)
	}

	// watches start/end time and date inputs
	const watchDate = watch("appointmentDate")
	const watchTimezone = watch("timezone")
	const watchStartTime = watch("startTime")
	const watchEndTime = watch("endTime")
	const watchWorker = watch("worker")
	const watchMentor = watch("buddySessionMentor")

	const watchClients: ClientWithContact[] = watch("client")
	const checkClientGroupLineItem = useParameterizedQuery(fetching.query.checkClientGroupLineItem)
	const [NDISErrorMessage, setNDISErrorMessage] = React.useState<string[]>([])
	const [NDISGroupSessionError, setNDISGroupSessionError] = React.useState<string[]>([])

	React.useEffect(() => {
		setNDISErrorMessage([])
		setNDISGroupSessionError([])
		if (!watchClients || watchClients.length === 0 || !baseFundingSource || baseFundingSource.length === 0 || baseFundingSource[0].label !== "NDIA") return

		if (!watchDate || !watchStartTime || !watchEndTime) {
			setNDISErrorMessage((msg) => msg.concat("Session date and time is not set"))
			return
		}

		checkClientGroupLineItem.query({ clientIDList: watchClients.map((c) => c.id), bookingDate: watchDate }).then((resp) => {
			if (resp.error || !resp.payload) return
			setNDISGroupSessionError(resp.payload)
		})
	}, [watchClients, baseFundingSource, watchDate]) // eslint-disable-line react-hooks/exhaustive-deps

	// check worker availability query
	const workerAvailability = useCheckWorkerAvailability({ timezone: watchTimezone })

	React.useEffect(() => {
		handleCheckAvailability()
	}, [watchDate, watchStartTime, watchEndTime, watchWorker, watchMentor, watchTimezone]) // eslint-disable-line react-hooks/exhaustive-deps

	if (officeAll.error) return <ErrorNotification messageOrPayload={officeAll.payload} />

	const displayMeetingTypeInput = () => {
		switch (currentMeetingTypeKey) {
			case SessionMeetingType.InPerson:
				return (
					<>
						{baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label !== "NDIA" && (
							<ZenSelect
								label="Office"
								formName="selectedOffice"
								formRef={control}
								clearable={false}
								options={officeOptions}
								inputError={errors.selectedOffice}
								formRules={{
									validate: {
										required: (v: Value) => (!!v && v.length > 0) || "Office is required",
									},
								}}
							/>
						)}
						{((baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA") ||
							(selectedOffice && selectedOffice.length > 0 && selectedOffice[0].id === "other")) && (
								<ZenPlaceSelect
									label="Location"
									formName="location"
									formRef={control}
									inputError={errors.location}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length > 0) || "Location is required",
										},
									}}
								/>
							)}
					</>
				)
			case SessionMeetingType.Online:
				return (
					<ZenInput
						label={"Additional Info"}
						formRef={control}
						nameRef="otherMeetingMethod"
						inputError={errors.otherMeetingMethod}
						placeholder="Enter session URL or location details for online session"
					/>
				)
			case SessionMeetingType.Telephone:
				return (
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						required // A phone meeting requires a number, or note
						label={"Phone Number"}
						formRef={control}
						nameRef="otherMeetingMethod"
						inputError={errors.otherMeetingMethod}
					/>
				)
			case SessionMeetingType.ServiceProviderOther:
				return (
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						required // A phone meeting requires a number, or note
						label={"Other"}
						formRef={control}
						nameRef="otherMeetingMethod"
						inputError={errors.otherMeetingMethod}
					/>
				)
		}
		return null
	}

	// submit function
	const onSubmit = async (data: any) => {
		// checks worker appointment availability
		if (!workerAvailability.available || NDISErrorMessage.length > 0 || NDISGroupSessionError.length > 0) return

		const isValid = trigger(["startTime", "endTime"])
		if (!isValid) return

		let location: string | undefined = undefined
		switch (currentMeetingTypeKey) {
			case SessionMeetingType.InPerson:
				if (
					(baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA") ||
					(selectedOffice && selectedOffice.length > 0 && selectedOffice[0].id === "other")
				)
					location = data.location[0].label
				break
			case SessionMeetingType.Telephone:
			case SessionMeetingType.Online:
			case SessionMeetingType.ServiceProviderOther:
				location = data.otherMeetingMethod
				break
		}
		let clientID: string[] = []
		if (data.client) clientID = data.client.map((c: Client) => c.id)

		const includeClientType = !nonBillable && baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label !== "NDIA"

		// build input data
		const input: SessionSingleInput = {
			id,
			workerID: data.worker[0].id,
			clientID,
			timezoneID: data.timezone[0].id,
			startTime: setTimezoneToTime(alignDate(data.appointmentDate, data.startTime), data.timezone[0].id),
			endTime: setTimezoneToTime(alignDate(data.appointmentDate, data.endTime), data.timezone[0].id),
			meetingType: currentMeetingTypeKey,
			sessionLocation: location,
			sessionFundingSourcesInput: placeholder
				.sort((a: number, b: number) => a - b)
				.map((p) => {
					// read data from react hook form
					const fundingSource: BasicLabel[] = getValues(`fundingSource-${p}`) || []
					const supportType: BasicLabel[] = getValues(`supportType-${p}`) || []
					const contractArea: BasicLabel[] = getValues(`contractArea-${p}`) || []
					const subSupportType: BasicLabel[] = getValues(`subSupportType-${p}`) || []
					const subSubSupportType: BasicLabel[] = getValues(`subSubSupportType-${p}`) || []

					// parse and return valid id
					return {
						fundingSourceID: fundingSource[0].id,
						supportTypeID: supportType.length > 0 ? supportType[0].id : undefined,
						contractAreaID: contractArea.length > 0 ? contractArea[0].id : undefined,
						subSupportTypeID: subSupportType.length > 0 ? subSupportType[0].id : undefined,
						subSubSupportTypeID: subSubSupportType.length > 0 ? subSubSupportType[0].id : undefined,
					}
				}),
			officeID:
				baseFundingSource &&
					baseFundingSource.length > 0 &&
					baseFundingSource[0].label !== "NDIA" &&
					selectedOffice &&
					selectedOffice.length > 0 &&
					selectedOffice[0].id !== "other"
					? selectedOffice[0].id?.toString()
					: undefined,
			buddySessionWithMentor: data.buddySessionWithMentor,
			buddySessionMentorID: data.buddySessionMentor && data.buddySessionMentor.length > 0 ? data.buddySessionMentor[0].id : undefined,
			clientType: includeClientType && data.clientType && data.clientType.length > 0 ? data.clientType[0].id?.toString() : undefined,
		}

		// show post session wipe out reminder if
		if (!nonBillable && props.attendanceStatus) {
			// 1. the date has changed
			if (
				moment(props.startTime).tz(props.timezoneID).format(ZenDateTimeFormat) !== moment(input.startTime).tz(props.timezoneID).format(ZenDateTimeFormat) ||
				moment(props.endTime).tz(props.timezoneID).format(ZenDateTimeFormat) !== moment(input.endTime).tz(props.timezoneID).format(ZenDateTimeFormat)
			) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the booking date of the session is changed,")
				return
			}

			// 2. the funding source has changed
			if (input.sessionFundingSourcesInput.length !== props.sessionFundingSources.length) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the funding source of the session is changed,")
				return
			}

			let fundingSourceChanged = false
			input.sessionFundingSourcesInput.forEach((sfs1) => {
				// skip process if detect funding source is changed
				if (fundingSourceChanged) return

				// check input funding source exists in original session funding source list
				const exists = props.sessionFundingSources.some((sfs2) => sfs2.fundingSource.id === sfs1.fundingSourceID)

				// funding source has changed
				if (!exists) fundingSourceChanged = true
			})

			if (fundingSourceChanged) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the funding source of the session is changed,")
				return
			}

			// 3. the client has changed
			if (input.clientID.length !== props.clients?.length) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the client of the session is changed,")
				return
			}

			// 4. mentor session id changed
			if (!input.buddySessionWithMentor && !!buddySessionMentor && timesheets && timesheets.length > 1) {
				// check timsheet exists
				const targetTimesheet = timesheets.find((t) => t.userID === buddySessionMentor.id)
				if (!!targetTimesheet && targetTimesheet.readyAt) {
					setSessionInput(input)
					// if timesheet is complete
					if (targetTimesheet.completedAt) {
						setRemindMentorTimesheet("Due to the mentor had claimed and complete the timesheet process,")
						return
					}

					// if timesheet is under review
					setRemindMentorTimesheet("The mentor had claimed this session")
					return
				}
			}

			let clientChanged = false
			input.clientID.forEach((cid) => {
				// skip process if detect funding source is changed
				if (clientChanged) return

				// check input funding source exists in original session funding source list
				const exists = props.clients?.some((client) => client.id === cid)

				// funding source has changed
				if (!exists) clientChanged = true
			})

			if (clientChanged) {
				// show modal
				setSessionInput(input)
				setResetPostDetailReason("Due to the client of the session is changed,")
				return
			}
		}

		const resp = await mutate(input)
		if (!resp.payload || resp.error) return
		showToast("Single Session updated successfully.", "positive")
		await refetch()
		setDisplayMode(true)
		triggerQuerySessionList((t) => !t)
		fetchIncompleteSessionAlert()

		if (refetchTimesheet) refetchTimesheet()
	}

	const container = css({
		height: "100%",
		marginTop: "10px",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
		width: "100%",
	})
	const footer = css({
		minHeight: "80px",
	})
	const rangeTimeStyle = css({
		marginTop: "8px",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	})
	const scrollingDiv = css({
		maxHeight: "100%",
		height: "100%",
		paddingRight: "8px",
		overflowY: "auto",
		overflowX: "hidden",
	})

	const overlay = css({
		opacity: mainWorkerClaimTimesheet ? 0.2 : "unset",
		pointerEvents: mainWorkerClaimTimesheet ? "none" : "unset",
	})

	const mentorOverlay = css({
		opacity: mentorHasClaimTimesheet ? 0.2 : "unset",
		pointerEvents: mentorHasClaimTimesheet ? "none" : "unset",
	})

	// handles worker availability check
	const handleCheckAvailability = () => {
		// form inputs
		const input = getValues()

		// null checks
		if (!input.worker || !input.worker.length || !input.startTime || !input.endTime || !watchTimezone || !watchTimezone.length) {
			if (workerAvailability.state) workerAvailability.resetState()
			return
		}

		const wIDs = [input.worker[0].id as string]
		if (input.buddySessionMentor && input.buddySessionMentor[0]) {
			wIDs.push(input.buddySessionMentor[0].id as string)
		}
		// check available
		workerAvailability.check({
			workerIDs: wIDs,
			timezone: watchTimezone[0],
			startTime: input.startTime,
			endTime: input.endTime,
			sessionID: props.id,
			billableSession: true,
		})
	}

	// adjust start/end time on data change
	const handleDateOnChange = (date: Date) => {
		setValue("startTime", alignDate(date, getValues().startTime))
		setValue("endTime", alignDate(date, getValues().endTime))
	}

	// display funding sources select boxes base on whether the session is billable
	const displayFundingSourceField = () => {
		if (nonBillable) {
			return (
				<>
					<ZenSelect
						label="Funding Source"
						formName="fundingSource-0"
						formRef={control}
						clearable={false}
						value={baseFundingSource}
						options={[]}
						disabled={true}
					/>
					<ZenSelect
						label="Activity Type"
						formName="supportType-0"
						formRef={control}
						options={nonBillableSupportTypeOptions} // query non-billable support type options
						inputError={errors.supportType}
						actionOnChange={(v) => {
							setNonBillableSubSupportTypeOptions([])
							setValue("subSupportType-0", [])
							if (v.length === 0) {
								return
							}
							const supportTypeID = v[0].id?.toString() || ""
							updateSubSupportTypeOptions(supportTypeID)
						}}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Activity type is required",
							},
						}}
					/>

					{nonBillableSubSupportTypeOptions.length > 0 && (
						<ZenSelect
							label="Sub Activity Type"
							formName="subSupportType-0"
							formRef={control}
							options={nonBillableSubSupportTypeOptions}
							actionOnChange={(v) => {
								setNonBillableSubSubSupportTypeOptions([])
								setValue("subSubSupportType-0", [])
								if (v.length === 0) return
								const subSupportTypeID = v[0].id?.toString() || ""
								updateSubSubSupportTypeOptions(subSupportTypeID)
							}} />
					)}

					{nonBillableSubSubSupportTypeOptions.length > 0 && (
						<ZenSelect
							label="Support Type"
							formName="subSubSupportType-0"
							formRef={control}
							options={nonBillableSubSubSupportTypeOptions} />
					)}
				</>
			)
		}

		return (
			<SessionFundingSourceForm
				control={control}
				involvedClient={watchClients}
				errors={errors}
				placeholder={placeholder}
				watch={watch}
				setValue={setValue}
				setAvailableIndexes={setAvailableIndexes}
				setPlaceholder={setPlaceholder}
				sessionFundingSources={sessionFundingSources}
			/>
		)
	}

	return (
		<>
			<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
				{mutateError && <ErrorNotification messageOrPayload={payload} closeable />}
				<div className={scrollingDiv}>
					<ZenUserSelect
						label="Worker"
						formName="worker"
						formRef={control}
						inputError={errors.worker}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
							},
						}}
						defaultValue={[worker]}
						disabled={!hasPermission(RolePermission.SessionUpdate)}
						actionOnChange={(v) => {
							// check null value
							if (!v || v.length === 0 || !v[0].id) {
								setSelectedWorkerID([])
								return
							}
							setSelectedWorkerID([v[0].id.toString()])
							setValue("worker", v)
						}}
						excludedID={
							watch("buddySessionMentor") && watch("buddySessionMentor").length > 0 && watch("buddySessionMentor")[0]?.id
								? [watch("buddySessionMentor")[0]?.id, ...selectedWorkerID]
								: []
						}
					/>
					{!nonBillable && (
						<ZenClientSelect
							label="Client(s)"
							formName="client"
							formRef={control}
							inputError={errors.client}
							multi
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Client is required",
								},
							}}
							defaultValue={clients ? clients : undefined}
							actionOnChange={(v) => {
								setSelectedClientID(v.length > 0 && v[0].id ? [v[0].id.toString()] : [])
							}}
							excludedID={selectedClientID}
						/>
					)}
					{!nonBillable && baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label !== "NDIA" && (
						<ZenSelect
							label="Client Type"
							formName="clientType"
							options={Object.entries(SessionClientType).map((sct) => ({ id: sct[1], label: snakeToTitle(sct[0]) }))}
							formRef={control}
						/>
					)}
					{NDISErrorMessage.length > 0 && NDISErrorMessage.map((errMsg, i) => <ErrorNotification key={i} messageOrPayload={errMsg} />)}
					{NDISGroupSessionError.length > 0 && NDISGroupSessionError.map((errMsg, i) => <ErrorNotification key={i} messageOrPayload={errMsg} />)}

					<ZenDatePicker
						label="Appointment date"
						clearable={false}
						actionOnChange={handleDateOnChange}
						formName="appointmentDate"
						formRef={control}
						value={moment(startTime).toDate()}
					/>
					<ZenTimezoneSelect
						formRef={control}
						formName="timezone"
						label="Timezone"
						timezone={[timezone]}
						clearable={false}
						actionOnChange={(v) => {
							const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
							if (watchStartTime) setValue("startTime", moment(watchStartTime).add(offsetDifferent, "minute"))
							if (watchEndTime) setValue("endTime", moment(watchEndTime).add(offsetDifferent, "minute"))
						}}
					/>
					<div className={rangeTimeStyle}>
						<ZenTimePicker
							label="Booked Time Start"
							formName="startTime"
							date={watch("appointmentDate")}
							formRef={control}
							value={moment(startTime).toDate()}
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "Start time is required"
										}
										if (watchEndTime && moment(value).isSameOrAfter(watchEndTime)) {
											return "Start time must be before end time"
										}
										return null
									},
								},
							}}
							inputError={errors.startTime}
						/>
						<Spacer />
						<ZenTimePicker
							label="Booked Time End"
							formName="endTime"
							date={watch("appointmentDate")}
							formRef={control}
							value={moment(endTime).toDate()}
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "End time is required"
										}
										if (watchStartTime && moment(value).isSameOrBefore(watchStartTime)) {
											return "End time must be after start time"
										}
										return null
									},
								},
							}}
							inputError={errors.endTime}
						/>
					</div>
					{!!workerAvailability.state && <AvailableCheck isAvailable={workerAvailability.state} />}

					{!nonBillable && (
						<>
							<LabelSmall marginBottom="5px">Meeting Type</LabelSmall>
							<ZenButtonGroup
								data={Object.values(SessionMeetingType).map((s) => ({ id: s, label: snakeToTitle(s) }))}
								currentKey={currentMeetingTypeKey}
								actionOnSelect={handleOnMeetingTypeChange}
							/>
							{displayMeetingTypeInput()}
						</>
					)}

					{mainWorkerClaimTimesheet && (
						<LabelSmall marginTop="4px" color={ZenTheme.colors.primaryGrey}>
							Funding Source information is not editable once session is claimed
						</LabelSmall>
					)}

					<div className={overlay}>
						{displayFundingSourceField()}

						{!nonBillable && baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label !== "NDIA" && availableIndexes.length > 0 && (
							<>
								<Divider style={{ backgroundColor: "white" }} />
								<ZenButton type="button" onClick={addMoreFundingSource}>
									Add New Funding Source
								</ZenButton>
							</>
						)}
					</div>
					{baseFundingSource && baseFundingSource.length > 0 && baseFundingSource[0].label === "NDIA" && (
						<>
							<ZenCheckbox
								label="Buddy Session?"
								marginTop="5px"
								labelPlacement="left"
								checked={props?.buddySessionWithMentor}
								formName="buddySessionWithMentor"
								formRef={control}
							/>
							{isBuddySession && (
								<div className={mentorOverlay}>
									<ZenUserSelect
										label={`Mentor ${mentorHasClaimTimesheet ? "(has claim this session)" : ""}`}
										formName="buddySessionMentor"
										inputError={errors.buddySessionMentor}
										defaultValue={buddySessionMentor ? [buddySessionMentor] : undefined}
										excludedID={watch("worker") && watch("worker").length > 0 && watch("worker")[0]?.id ? [watch("worker")[0]?.id] : []}
										formRef={control}
										formRules={{
											validate: {
												required: (value: Value) => (!!value && value.length > 0) || "Buddy Session Mentor is required",
											},
										}}
									/>
								</div>
							)}
						</>
					)}
				</div>
				<div className={footer}>
					<CancelAndSaveButtons cancelFn={() => setDisplayMode(true)} isLoading={loading} />
				</div>
			</form>
			{sessionInput && resetPostDetailReason && (
				<ZenModal
					isOpen={!!sessionInput}
					onClose={() => {
						setSessionInput(undefined)
						setResetPostDetailReason("")
					}}
					role="dialog"
				>
					<ZenCard style={{ alignItems: "center" }}>
						<FontAwesomeIcon size="3x" color={ZenTheme.colors.warning400} icon={["fal", "exclamation-circle"]} />
						<LabelMedium marginTop="8px">{resetPostDetailReason}</LabelMedium>
						<LabelMedium marginTop="4px">the post session detail will be reset.</LabelMedium>
						<LabelMedium marginTop="4px">Are you sure you want to update the session?</LabelMedium>
						<div style={{ width: "100%" }}>
							<CancelAndSaveButtons
								saveLabel="Confirm"
								isLoading={loading}
								saveFn={async () => {
									const resp = await mutate(sessionInput)
									if (!resp.payload || resp.error) return
									showToast("Single Session updated successfully.", "positive")
									await refetch()
									setDisplayMode(true)
									triggerQuerySessionList((t) => !t)
									fetchIncompleteSessionAlert()

									if (props.refetchTimesheet) props.refetchTimesheet()
									setSessionInput(undefined)
									setResetPostDetailReason("")
								}}
								cancelFn={() => {
									setSessionInput(undefined)
									setResetPostDetailReason("")
								}}
							/>
						</div>
					</ZenCard>
				</ZenModal>
			)}
			{sessionInput && remindMentorTimesheet && (
				<ZenModal
					isOpen={!!sessionInput}
					onClose={() => {
						setSessionInput(undefined)
						setRemindMentorTimesheet("")
					}}
					role="dialog"
				>
					<ZenCard style={{ alignItems: "center" }}>
						<FontAwesomeIcon size="3x" color={ZenTheme.colors.warning400} icon={["fal", "exclamation-circle"]} />
						<LabelMedium marginTop="8px">{remindMentorTimesheet}</LabelMedium>
						<LabelMedium marginTop="4px">Current update will change the mentor's timesheet</LabelMedium>
						<LabelMedium marginTop="4px">Are you sure you want to update the session?</LabelMedium>
						<div style={{ width: "100%" }}>
							<CancelAndSaveButtons
								saveLabel="Confirm"
								isLoading={loading}
								saveFn={async () => {
									const resp = await mutate(sessionInput)
									if (!resp.payload || resp.error) return
									showToast("Single Session updated successfully.", "positive")
									await refetch()
									setDisplayMode(true)
									triggerQuerySessionList((t) => !t)
									fetchIncompleteSessionAlert()

									if (props.refetchTimesheet) props.refetchTimesheet()
									setSessionInput(undefined)
									setResetPostDetailReason("")
								}}
								cancelFn={() => {
									setSessionInput(undefined)
									setResetPostDetailReason("")
								}}
							/>
						</div>
					</ZenCard>
				</ZenModal>
			)}
		</>
	)
}
