import * as React from "react"
import { useStyletron } from "baseui"
import { Checkbox } from "baseui/checkbox"
import { Option } from "baseui/select"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useQuery } from "react-fetching-library"
import { useFormContext } from "react-hook-form"

import { fetching } from "../../fetching"
import { emailRegex } from "../../helpers/utils"
import { ImageUpload } from "../../pages/admin/imageUpload"
import { minimumAgeMonths } from "../../types/enums"
import { atsiOption, BasicName, Country, ErrorObject, Gender, HowDoYouHearAboutUs, Language, residencyStatus, ResponseOptions } from "../../types/types"
import { SubSection, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { ZenPostalAddress } from "../zenComponents/zenAddress"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenCheckbox } from "../zenComponents/zenCheckboxList"
import { ZenInput, ZenTextArea } from "../zenComponents/zenInput"
import { ZenPlaceSelect, ZenSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../zenComponents/zenTime"
import { ServiceCheckTable } from "./clientServiceCheckTable"
import { ParentGuardianDetailForm } from "./Intake/youth/intakeYouthParentGuardianDetail"
import { ErrorFieldTracker } from "../forms/errorFieldTracker"


export const responseOptions: ResponseOptions[] = [
	{ id: 'YES', label: 'Yes' } as ResponseOptions,
	{ id: 'NO', label: 'No' } as ResponseOptions,
	{ id: 'PREFER_NOT_TO_SAY', label: 'Prefer not to say' } as ResponseOptions,
]

interface ClientCreateFormProps {
	submit: () => void
	avatar: File | undefined
	setAvatar: React.Dispatch<React.SetStateAction<File | undefined>>
	sameAsResidentialAddress: boolean
	setSameAsResidentialAddress: React.Dispatch<React.SetStateAction<boolean>>
	manualPostalAddress: boolean
	setManualPostalAddress: React.Dispatch<React.SetStateAction<boolean>>
	noCard?: boolean
	explainedServiceIDs: string[]
	setExplainedServiceIDs: React.Dispatch<React.SetStateAction<string[]>>
	interestedServiceIDs: string[]
	setInterestedServiceIDs: React.Dispatch<React.SetStateAction<string[]>>
	errorObject?: ErrorObject
	pronounOptionMemo?: {id: string;label: string;}[]
	setPronounSection?: React.Dispatch<React.SetStateAction<string>>
}

export const ClientCreateForm = (props: ClientCreateFormProps) => {
	const {
		submit,
		avatar,
		setAvatar,
		sameAsResidentialAddress,
		setSameAsResidentialAddress,
		manualPostalAddress,
		setManualPostalAddress,
		explainedServiceIDs,
		setExplainedServiceIDs,
		interestedServiceIDs,
		setInterestedServiceIDs,
		errorObject,
		pronounOptionMemo,
		setPronounSection
	} = props

	const { control, errors, watch, trigger, getValues } = useFormContext()

	const canLeaveVoice = !!watch("telephoneNumber")
	const canLeaveText = !!watch("mobileNumber")
	const canSendEmail = !!watch("email")
	const hasParentGuardianContact = watch("hasParentGuardianContact")
	const serviceProvider = watch("serviceProvider")
	const isOrganisation = watch("isOrganisation")

	// add parent guardian contact
	const [parentGuardianContactErrors, setParentGuardianContactErrors] = React.useState<boolean>(false)
	const [countryOptions, setCountryOptions] = React.useState<Option[]>([])
	const [languageOptions, setLanguageOptions] = React.useState<Option[]>([])
	const countryData = useQuery<Country[]>(fetching.query.getCountryAll())
	const languageData = useQuery<Language[]>(fetching.query.getLanguageAll())
	const genderData = useQuery<Gender[]>(fetching.query.getGenderAll())
	React.useEffect(() => {
		if (countryData.loading || !countryData.payload) return
		setCountryOptions(
			countryData.payload.map<Option>((c) => ({
				label: c.countryName,
				id: c.id,
			})),
		)
	}, [countryData.loading, countryData.payload])
	React.useEffect(() => {
		if (languageData.loading || !languageData.payload) return
		const newLanguageData = languageData.payload.map<Option>((l) => ({
			label: l.label,
			id: l.id,
		}))
		newLanguageData.sort((a, b) => {
			return a.label === "English" ? -1 : b.label === "English" ? 1 : 0
		})

		setLanguageOptions(newLanguageData)
	}, [languageData.loading, languageData.payload])

	// marketing options
	const [contactList, setContactList] = React.useState<BasicName[]>([])
	const [serviceList, setServiceList] = React.useState<BasicName[]>([])

	const initialContactSourceData = useQuery<BasicName[]>(fetching.query.getInitialContactSourceAll())
	const serviceProviderData = useQuery<BasicName[]>(fetching.query.getServiceProviderAll())
	React.useEffect(() => {
		if (initialContactSourceData.loading || !initialContactSourceData.payload) return
		setContactList(initialContactSourceData.payload)
	}, [initialContactSourceData.loading, initialContactSourceData.payload])
	React.useEffect(() => {
		if (serviceProviderData.loading || !serviceProviderData.payload) return
		setServiceList(serviceProviderData.payload)
	}, [serviceProviderData.loading, serviceProviderData.payload])

	const [contactError, setContactError] = React.useState<boolean>(false)
	// Check contact methods exist
	React.useEffect(() => {
		// client contact method
		const hasContact = canLeaveVoice || canLeaveText || canSendEmail
		if (!hasContact) {
			setContactError(true)
			return
		}
		setContactError(false)
	}, [canLeaveText, canLeaveVoice, canSendEmail])

	const [submitAttempted, setSubmitAttempted] = React.useState<boolean>(false)
	const onSubmit = async () => {
		setSubmitAttempted(true)
		// generate check list
		const checkList = ["firstName"]
		if (!isOrganisation) checkList.push("lastName", "dateOfBirth")
		let hasParentGuardianContactError = false
		// check current parent and guardian
		if (hasParentGuardianContact) {
			hasParentGuardianContactError = getValues().parentGuardianMobileNumber === "" && getValues().parentGuardianTelephoneNumber === ""
			setParentGuardianContactErrors(hasParentGuardianContactError)
			checkList.push(
				"parentGuardianFirstName",
				"parentGuardianLastName",
				"parentGuardianRelationship",
				"parentGuardianMobileNumber",
				"parentGuardianCountryOfBirthID",
				"parentGuardianLanguageSpokenAtHomeID",
				"parentGuardianAboriginalOrTorresStraitIslander",
				"parentGuardianDateOfBirth",
			)
		}

		// client contact method
		const hasContact = canLeaveVoice || canLeaveText || canSendEmail
		setContactError(!hasContact)

		// check form
		const isValid = await trigger(checkList)
		if (!isValid || !hasContact || hasParentGuardianContactError) return

		submit()
	}

	const [css] = useStyletron()
	const formStyle = css({
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		margin: "auto",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		marginTop: "10px",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
			alignItems: "center",
		},
	})
	const scrollDiv = css({
		paddingRight: "20px",
		marginTop: "10px",
		maxHeight: "97%",
		width: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		position: "relative",
	})
	const buttonContainer = css({
		width: "100%",
		display: "flex",
		marginTop: "10px",
		justifyContent: "flex-end",
		alignItems: "flex-end",
	})

	const card = css({
		padding: "20px",
		[`@media only screen and (max-width: 1500px)`]: {
			width: "100%",
		},
	})
	const avatarContainer = css({
		width: "225px",
		display: "flex",
	})
	const avatarContainerInner = css({
		width: "100%",
		height: "173px",
	})
	const nameContainer = css({
		width: "100%",
		marginLeft: "15px",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const renderedForm = (
		<div className={formStyle}>
			<div className={scrollDiv}>
				<div className={title}>
					<LabelLarge marginBottom="5px">General Details</LabelLarge>
					<ZenCheckbox label="Organisation Client" formName="isOrganisation" formRef={control} />
				</div>
				<div className={group}>
					<div className={avatarContainer}>
						<div className={avatarContainerInner}>
							<ImageUpload
								label="Avatar"
								name="avatar"
								imageURL={""}
								imageRemoved={false}
								file={avatar}
								setFile={(file?: File) => setAvatar(file)}
								previewHeight="200px"
								maxFileSize={1e7}
							/>
						</div>
					</div>
					<div className={nameContainer}>
						<ZenInput
							placeholder="Enter client's first name"
							label={isOrganisation ? "Name" : "First name"}
							nameRef="firstName"
							inputError={errors.firstName}
							formRef={control}
							required
						/>
						{!isOrganisation && (
							<ZenInput placeholder="Enter client's last name" label="Last name" nameRef="lastName" inputError={errors.lastName} formRef={control} required />
						)}
						<ZenInput placeholder="Enter client's preferred name" label="Preferred name" nameRef="preferredName" formRef={control} />
					</div>
				</div>

				{isOrganisation ? (
					<ZenDatePicker
						label="First date of contact"
						formName="firstDateOfContact"
						formRef={control}
						inputError={errors.firstDateOfContact}
						minDate={new Date("1900-01-01")}
						required
						formRules={{
							validate: {
								required: (value: string) => {
									if (!value) {
										return "First date of contact is required."
									}

									if (moment(value).isAfter(moment())) {
										return "First date of contact cannot be in the future."
									}
									return null
								},
							},
						}}
					/>
				) : (
					<>
						<ZenDatePicker
							label="Date of birth"
							formName="dateOfBirth"
							formRef={control}
							inputError={errors.dateOfBirth}
							value={moment().subtract(minimumAgeMonths, "month").toDate()}
							minDate={new Date("1900-01-01")}
							maxDate={moment().subtract(minimumAgeMonths, "month").toDate()}
							required
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) {
											return "Date of birth is required."
										}

										if (moment(value).isAfter(moment())) {
											return "Date of birth cannot be in the future."
										}
										return null
									},
								},
							}}
						/>
						<ZenCheckbox label="Date of birth is approximate" formName="hasApproximateDob" formRef={control} marginBottom="10px" marginTop="5px" />

						<ZenSelect
							label={"Gender"}
							formName={"genderID"}
							formRef={control}
							inputError={errors.genderID}
							options={genderData.payload || []}
							isLoading={genderData.loading}
						/>

						<ZenSelect
							label={"Pronoun"}
							formName={"pronounSection"}
							formRef={control}
							inputError={errors.pronounSection}
							onInputChange={(e: any) => setPronounSection?.(e.target.value)}
							options={pronounOptionMemo}
						/>

						<div className={group}>
							<ZenCheckbox label="Identify as CaLD" formName="culturallyAndLinguisticallyDiverse" formRef={control} width="100%" />
							<ZenCheckbox label="Identify as LGBTIQ+" formName="lesbianGayBisexualAndTransgender" formRef={control} width="100%" />
						</div>
						<ZenSelect
							label="Identify as ATSI"
							formName="aboriginalOrTorresStraitIslander"
							formRef={control}
							clearable={false}
							options={Object.values(atsiOption).map((a) => ({ label: a, id: a }))}
						/>
						<ZenSelect
							label="Country of Birth"
							formName="countryOfBirthID"
							formRef={control}
							clearable={false}
							options={countryOptions}
							isLoading={countryData.loading}
						/>
						<ZenSelect
							label="Language Spoken at Home"
							formName="languageSpokenAtHomeID"
							formRef={control}
							clearable={false}
							options={languageOptions}
							isLoading={languageData.loading}
						/>
						<ZenCheckbox label="Translator Required" formName="requiresTranslator" formRef={control} marginTop="5px" />
						<ZenSelect
							label="Residency Status"
							formName="residencyStatus"
							formRef={control}
							clearable={false}
							options={Object.values(residencyStatus).map((r) => ({ label: r, id: r }))}
						/>
					</>
				)}

				<LabelLarge marginBottom="5px" marginTop="25px">
					Address details
				</LabelLarge>
				<SubSection>
					<ZenPlaceSelect label="Residential Address" formName="residentialAddress" placeholder="Enter residential address" formRef={control} />
					<Checkbox
						overrides={{
							Root: {
								style: {
									marginTop: "5px",
								},
							},
						}}
						checked={sameAsResidentialAddress}
						onChange={() => setSameAsResidentialAddress((e) => !e)}
					>
						<LabelSmall> Postal address is the same as residential address</LabelSmall>
					</Checkbox>
					{!sameAsResidentialAddress ? (
						<ZenPostalAddress
							label="Postal Address"
							formName="postalAddress"
							formNameManual="manualPostalAddress"
							formRef={control}
							placeholder="Address here"
							manualAddress={manualPostalAddress}
							setManualAddress={setManualPostalAddress}
						/>
					) : (
						<></>
					)}
				</SubSection>

				<div className={group} id={"clientContact"}>
					<LabelLarge marginBottom="5px" marginTop="25px">
						Contact Details
					</LabelLarge>
				</div>
				{contactError && <ErrorNotification message="One contact method is required" kind="warning" />}

				<SubSection>
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						// Not required to help smooth onBoarding of clients
						marginTop="15px"
						label="Telephone"
						placeholder="Enter telephone"
						nameRef="telephoneNumber"
						inputError={errors.telephoneNumber}
						formRef={control}
					/>
					{canLeaveVoice ? (
						<ZenCheckbox
							label="Can we leave a voice message?"
							labelPlacement="left"
							formName="leaveVoiceMessage"
							formRef={control}
							marginBottom="10px"
							marginTop="5px"
						/>
					) : (
						<></>
					)}
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						// Not required to help smooth onBoarding of clients
						marginTop="15px"
						label="Mobile"
						placeholder="Enter mobile"
						nameRef="mobileNumber"
						inputError={errors.mobileNumber}
						formRef={control}
					/>
					{canLeaveText ? (
						<ZenCheckbox
							label="Can we send a text message?"
							labelPlacement="left"
							formName="leaveTextMessage"
							formRef={control}
							marginBottom="10px"
							marginTop="5px"
						/>
					) : (
						<></>
					)}
					<ZenInput
						marginTop="15px"
						label="Email"
						nameRef="email"
						placeholder="Enter email. eg. name@example.com"
						formRef={control}
						formRules={{
							required: "email is required",
							pattern: {
								value: emailRegex,
								message: "Invalid email address",
							},
						}}
					/>
					{canSendEmail ? (
						<ZenCheckbox
							label="Can we send emails?"
							labelPlacement="left"
							formName="agreedToReceiveEmails"
							formRef={control}
							marginBottom="10px"
							marginTop="5px"
						/>
					) : (
						<></>
					)}
					<ZenCheckbox label={"Add contact details for parents/guardians"} formName="hasParentGuardianContact" formRef={control} marginTop="5px" />
				</SubSection>

				{!isOrganisation && hasParentGuardianContact && (
					<ParentGuardianDetailForm
						control={control}
						inputError={errors}
						contactError={parentGuardianContactErrors}
						languageOptions={languageOptions}
						countryOptions={countryOptions}
					/>
				)}
				<LabelLarge marginBottom="5px" marginTop="25px">
					Marketing
				</LabelLarge>
				<SubSection>
					<ZenCheckbox
						label="Would you like to stay updated on upcoming HelpingMinds information and events via email?"
						formName="receiveUpcomingEvent"
						formRef={control}
					/>
					<ZenSelect
						label="How did you hear about HelpingMinds? (Select an option or enter another provider)"
						formName="whereClientHearAboutUs"
						formRef={control}
						options={HowDoYouHearAboutUs.map<Option>((h) => ({ label: h, id: h }))}
						creatable
					/>
					<ZenSelect
						label="Source of initial contact"
						formName="initialContactSource"
						formRef={control}
						options={contactList.map<Option>((c) => ({ label: c.name, id: c.id }))}
						isLoading={initialContactSourceData.loading}
					/>
					<ZenSelect
						label="Is the client linked with other service providers?"
						formName="serviceProvider"
						formRef={control}
						options={serviceList.map<Option>((s) => ({ id: s.id, label: s.name }))}
						isLoading={serviceProviderData.loading}
					/>
					{serviceProvider && serviceProvider[0] && serviceProvider[0].label === "Other" && (
						<ZenInput
							placeholder="Enter Service Provider"
							label={"Other"}
							nameRef="customServiceProvider"
							inputError={errors.customServiceProvider}
							formRef={control}
							required
						/>
					)}
					<ZenTextArea label="What help is the client looking for?" nameRef="helpLookingFor" formRef={control} />
					{!isOrganisation &&
						<>
							<ZenSelect
								label="Are you currently caring for someone with a NDIS plan?"
								formName="currentlyCaringForNdisPlan"
								formRef={control}
								clearable={true}
								options={responseOptions}
							/>
							<ZenSelect
								label="Has the person you are caring for applied for a NDIS plan and been declined?"
								formName="hasAppliedAndDeclinedNdisPlan"
								formRef={control}
								clearable={true}
								options={responseOptions}
							/>
						</>
					}
					<div className={group}>
						<LabelMedium marginBottom="8px">Discuss the following services with the client</LabelMedium>
					</div>
					<ServiceCheckTable
						explainedServiceIDs={explainedServiceIDs}
						interestedServiceIDs={interestedServiceIDs}
						setExplainedServiceIDs={setExplainedServiceIDs}
						setInterestedServiceIDs={setInterestedServiceIDs}
					/>
				</SubSection>
			</div>

			{/* err messages */}
			{countryData.error && <ErrorNotification messageOrPayload={countryData.payload} />}
			{languageData.error && <ErrorNotification messageOrPayload={languageData.payload} />}
			{genderData.error && <ErrorNotification messageOrPayload={genderData.payload} />}
			{serviceProviderData.error && <ErrorNotification messageOrPayload={serviceProviderData.payload} />}
			{errorObject && <ErrorNotification messageOrPayload={errorObject} />}

			<ErrorFieldTracker errorIDs={submitAttempted && contactError ? Object.keys(errors).concat("clientContact") : Object.keys(errors)} />
			<div className={buttonContainer}>
				<ZenButton width="25%" type="button" onClick={onSubmit}>
					Create
				</ZenButton>
			</div>
		</div>
	)

	if (props.noCard) return renderedForm
	return <ZenCard className={card}>{renderedForm}</ZenCard>
}
