import * as React from "react"
import { Block } from "baseui/block"
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip"
import { Button, KIND, SIZE } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/pro-light-svg-icons"

interface TooltipButtonProps {
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
	tooltip: string
	iconName: IconName
	type?: "button" | "submit" | "reset"
}

const TooltipButton = (props: TooltipButtonProps) => {
	return (
		<StatefulTooltip
			content={() => <Block padding={"5px"}>{props.tooltip}</Block>}
			ignoreBoundary={true}
			returnFocus
			autoFocus
			showArrow
			placement={PLACEMENT.top}
		>
			<Button
				onClick={props.onClick}
				kind={KIND.minimal}
				size={SIZE.compact}
				type={props.type}
				$style={{
					color: "unset",
					":hover": {
						color: "black",
					},
				}}
			>
				<FontAwesomeIcon icon={["fal", props.iconName]} />
			</Button>
		</StatefulTooltip>
	)
}

export { TooltipButton }
