import * as React from "react"
import { Document, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import { useStyletron } from "baseui"
import moment from "moment-timezone"
import { Loading } from "../../../components/loading"

import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { TimesheetDayData } from "../../../fetching/query"
import { friendlyDate, friendlyTime, minsStr, splitOnCaps } from "../../../helpers/utils"
import { Timesheet, TZString, ZenDateFormat } from "../../../types/types"
import { ReviewStatus } from "../../../types/enums"
import { TravelTypesForShowingClient } from "components/travels/travelForm"

interface TimesheetProps {
	rows: TimesheetDayData[]
	timesheetData: Timesheet
}

interface TimesheetPDFPreviewProps {
	fileName?: string
	timesheets: TimesheetProps[]
}

export const TimesheetPDFPreview = (props: TimesheetPDFPreviewProps) => {
	const { timesheets } = props

	const [css] = useStyletron()
	const exportContainer = css({
		height: "90%",
		overflowY: "auto",
	})

	// eg fileName: will_smith_2020_10_10_to_2020_10_17.pdf
	if (timesheets.length === 0) return null

	const documentFileName =
		props.fileName ||
		`Timesheet_${timesheets[0].timesheetData.user.firstName}_${timesheets[0].timesheetData.user.lastName}_${friendlyDate(
			timesheets[0].timesheetData.periodStart,
			TZString[0],
		)}_to_${friendlyDate(timesheets[0].timesheetData.periodEnd, TZString[0])}.pdf`

	const document = (
		<Document title={documentFileName}>
			{timesheets.map((t) => (
				<TimesheetPDFPage key={`timesheet-page-${t.timesheetData.id}`} {...t} />
			))}
		</Document>
	)

	return (
		<>
			<div className={exportContainer}>
				<PDFViewer width={"100%"} height={"100%"}>
					{document}
				</PDFViewer>
			</div>
			<br />
			<ZenButton>
				<PDFDownloadLink style={{ color: "white" }} document={document} fileName={documentFileName}>
					{({ loading, blob }) => (loading || !blob ? <Loading hideLabel /> : "Export")}
				</PDFDownloadLink>
			</ZenButton>
		</>
	)
}

const BORDER_COLOR = "#bfbfbf"
const BORDER_STYLE = "solid"
const COL1_WIDTH = 30
const COLN_WIDTH = (80 - COL1_WIDTH) / 2

const styles = StyleSheet.create({
	body: {
		padding: 10,
	},
	table: {
		width: "auto",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderRightWidth: 0,
		borderLeftWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		flexDirection: "row",
	},
	tableCol1Header: {
		width: COL1_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 0,
	},
	tableColHeader: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderBottomColor: "#000",
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 0,
	},
	tableCol1: {
		width: COL1_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 0,
	},

	tableColAlt: {
		width: COL1_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 0,
		borderBottomWidth: 0,
	},

	tableCol: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},

	tableColSummary: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderRightWidth: 0,
	},

	tableColSm: {
		width: COLN_WIDTH + "%",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 1,
		borderLeftWidth: 1,
		borderTopWidth: 0,
	},

	tableCellHeader: {
		margin: 5,
		fontSize: 12,
	},
	tableCell: {
		margin: 5,
		fontSize: 10,
	},

	tableCellAlt: {
		margin: 5,
		fontSize: 10,
	},

	divider: {
		padding: "4px",
	},

	infoText: {
		paddingBottom: "4px",
		fontSize: 13,
	},

	titleText: {
		paddingBottom: "4px",
		fontSize: 25,
		color: "#88BB3C",
	},

	block: {
		width: "auto",
	},
	hmLogoContainer: {
		height: "60px",
		width: "100%",
		textAlign: "center",
	},
	hmLogo: {
		height: "70px",
		width: "90px",
		textAlign: "center",
		margin: "auto",
	},
})

const TimesheetPDFHeaderRow = () => {
	return (
		<View style={styles.tableRow}>
			<View style={styles.tableCol1Header}>
				<Text style={styles.tableCellHeader}>Date</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Start/End</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Payable time</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Description</Text>
			</View>
		</View>
	)
}

const TimesheetPDFPage = (props: TimesheetProps) => {
	let breakpoint = 18 // used for page breaks, ie. every 18 rows insert new page
	const totalMinFormat = `${Math.floor(props.timesheetData.totalMinutes / 60)}h ${props.timesheetData.totalMinutes % 60}m`
	const summaryRecords: SummaryRecord[] = []
	if (!props.rows) return <></>
	props.rows.forEach((row) => {
		summaryRecords.push({
			date: row.date,
			timePayedDayMins: row.timePayedDayMins,
			multiplier: row.multiplier,
			originalPayableTime: row.originalPayableTime,
			equation: row.equation,
		})
	})

	return (
		<>
			<Page style={styles.body} orientation={"landscape"}>
				<View style={styles.block}>
					<Text style={styles.titleText}>Timesheet</Text>
					<Text style={styles.infoText}>
						{props.timesheetData.user.firstName} {props.timesheetData.user.lastName} | {moment(props.timesheetData.user.dateOfBirth).format(ZenDateFormat)} |{" "}
						{splitOnCaps(props.timesheetData.user.status)}
					</Text>
					<Text style={styles.infoText}>
						Period: {friendlyDate(props.timesheetData.periodStart, TZString[0])} - {friendlyDate(props.timesheetData.periodEnd, TZString[0])}
					</Text>
				</View>

				<View style={styles.table}>
					<TimesheetPDFHeaderRow />

					{props.rows.map((d, i) => {
						return (
							<React.Fragment key={`timesheet-row-${props.timesheetData.id}-${i}`}>
								<View style={styles.table}>
									{d.rows.map((r, idx) => {
										if (idx > breakpoint) {
											breakpoint += 18
										}
										const tz = TZString.find((tzs) => tzs.id === r.timezoneID) || TZString[0]
										
										if(r.description === TravelTypesForShowingClient.cBProviderTravel){
											console.log(r.description)
										}
										return (
											<View key={`timesheet-row-columns-${props.timesheetData.id}-${idx}`} break={breakpoint === idx}>
												{breakpoint === idx && <TimesheetPDFHeaderRow />}
												<View style={styles.tableRow} key={idx}>
													<View style={styles.tableCol1}>
														<Text style={idx === 0 ? styles.tableCell : styles.tableCellAlt}>{idx === 0 ? friendlyDate(d.date) : ""}</Text>
														<Text style={idx === 0 ? styles.tableCell : styles.tableCellAlt}>
															{idx === 0 ? `Total: ${minsStr(d.timePayedDayMins)} (${d.equation})` : ""}
														</Text>
														<Text style={idx === 0 ? styles.tableCell : styles.tableCellAlt}>{idx === 0 ? `Multiplier: ${d.multiplier}` : ""}</Text>
													</View>
													<View style={styles.tableCol}>
														<Text style={styles.tableCell}>
															{friendlyTime(tz, r.startTime)} - {friendlyTime(tz, r.endTime)}
														</Text>
														<Text style={styles.tableCell}>{r.timezoneID}</Text>
													</View>
													<View style={styles.tableCol}>
														<Text style={styles.tableCell}>{minsStr(r.timePayedMins, (r.description === TravelTypesForShowingClient.cBProviderTravel))}</Text>
													</View>
													<View style={styles.tableCol}>
														<Text style={styles.tableCell}>{r.description}</Text>
													</View>
												</View>
											</View>
										)
									})}
								</View>
								<View style={styles.divider}></View>
							</React.Fragment>
						)
					})}
				</View>

				<View>
					<Text style={styles.infoText}>
						Duration For Period: {friendlyDate(props.timesheetData.periodStart, TZString[0])} To {friendlyDate(props.timesheetData.periodEnd, TZString[0])} ={" "}
						{totalMinFormat}
					</Text>
				</View>
			</Page>

			{/* Summary Page */}
			<Page orientation="landscape" style={styles.body}>
				<TimesheetSummaryPDF
					timesheet={props.timesheetData}
					firstName={props.timesheetData.user.firstName}
					lastName={props.timesheetData.user.lastName}
					dob={moment(props.timesheetData.user.dateOfBirth)}
					status={splitOnCaps(props.timesheetData.user.status)}
					from={moment(props.timesheetData.periodStart)}
					to={moment(props.timesheetData.periodEnd)}
					records={summaryRecords}
					totalMinFormat={totalMinFormat}
				/>
			</Page>
		</>
	)
}

const TimesheetSummaryPDFHeader = () => {
	return (
		<View style={styles.tableRow}>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Date</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Payable time</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Multiplier</Text>
			</View>
			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>calculation</Text>
			</View>

			<View style={styles.tableColHeader}>
				<Text style={styles.tableCellHeader}>Total (Hrs / Mins)</Text>
			</View>
		</View>
	)
}

interface SummaryRecord {
	date: string
	timePayedDayMins: number
	multiplier: string
	originalPayableTime: number
	equation: string
}

interface SummaryProps {
	timesheet: Timesheet
	firstName: string
	lastName: string
	dob: moment.Moment
	status: string
	from: moment.Moment
	to: moment.Moment
	records: SummaryRecord[]
	totalMinFormat: string
}
const TimesheetSummaryPDF = (props: SummaryProps) => {
	return (
		<View>
			<View style={styles.block}>
				<Text style={styles.titleText}>Timesheet - Summary</Text>
				<Text style={styles.infoText}>
					{props.firstName} {props.lastName} | {props.dob.format(ZenDateFormat)} | {props.status}
				</Text>
				<Text style={styles.infoText}>
					Period: {friendlyDate(props.from, TZString[0])} - {friendlyDate(props.to, TZString[0])}
				</Text>

				<Text style={styles.infoText}>Daily Sum Totals (Hours) Accrued by Staff Member</Text>
			</View>

			<View style={styles.table}>
				<TimesheetSummaryPDFHeader />

				{props.records.map((record, idx) => {
					return (
						<View key={record.date} style={styles.table}>
							<View>
								<View style={styles.tableRow} key={idx}>
									<View style={styles.tableColSummary}>
										<Text style={styles.tableCell}>{friendlyDate(record.date)}</Text>
									</View>
									<View style={styles.tableColSummary}>
										<Text style={styles.tableCell}>{minsStr(record.originalPayableTime)}</Text>
									</View>
									<View style={styles.tableColSummary}>
										<Text style={styles.tableCell}>{record.multiplier}</Text>
									</View>
									<View style={styles.tableColSummary}>
										<Text style={styles.tableCell}>{record.equation}</Text>
									</View>
									<View style={styles.tableColSummary}>
										<Text style={styles.tableCell}>{minsStr(record.timePayedDayMins)}</Text>
									</View>
								</View>
							</View>
						</View>
					)
				})}
			</View>
			<View style={styles.divider}></View>
			<View break>
				<Text style={styles.infoText}>
					Total Hours For Period: {friendlyDate(props.from)} To {friendlyDate(props.to)} = {props.totalMinFormat}
				</Text>
				{props.timesheet.checkedBy && props.timesheet.checkedAt && (
					<Text style={styles.infoText}>
						Checked by {props.timesheet.checkedBy.firstName} {props.timesheet.checkedBy.lastName} at {friendlyDate(props.timesheet.checkedAt)}
					</Text>
				)}
				{props.timesheet.reviewedBy && props.timesheet.reviewedAt && (
					<>
						{props.timesheet.reviewStatus === ReviewStatus.Pending && (
							<Text style={styles.infoText}>
								Unapproved by {props.timesheet.reviewedBy.firstName} {props.timesheet.reviewedBy.lastName} at {friendlyDate(props.timesheet.reviewedAt)}
							</Text>
						)}
						{props.timesheet.reviewStatus !== ReviewStatus.Pending && (
							<Text style={styles.infoText}>
								{props.timesheet.reviewStatus === ReviewStatus.Approved ? "Approved" : "Declined"} by {props.timesheet.reviewedBy.firstName}{" "}
								{props.timesheet.reviewedBy.lastName} at {friendlyDate(props.timesheet.reviewedAt)}
							</Text>
						)}
					</>
				)}
			</View>
		</View>
	)
}
