import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { ZenTextArea } from "../../zenComponents/zenInput"
import { ZenSelect } from "../../zenComponents/zenSelectBox"
import { GroupAppointmentFormProps } from "./groupSessionBaseForm"
import { useQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"

export const EDUCATION_YEAR_GROUP = ["Primary School ", "7", "8", "9", "10", "11", "12", "18+"]

export const GroupSessionNotesForm = (props: GroupAppointmentFormProps) => {
	const { data, setData, changePage } = props
	const { control, getValues, handleSubmit, setValue } = useForm()

	React.useEffect(() => {
		setValue("yearGroup", data.yearGroup ? [{ label: data.yearGroup, id: data.yearGroup }] : undefined)
		setValue("programDelivered", data.programDelivered)
		setValue("notes", data.notes)
	}, [data, setValue])

	const back = () => {
		saveData()
		changePage(-1)
	}
	const submit = () => {
		saveData()
		changePage(1)
	}

	const saveData = () => {
		setData((data) => ({
			...data,
			yearGroup: getValues().yearGroup ? getValues().yearGroup[0]?.id : undefined,
			programDelivered: getValues().programDelivered ? getValues().programDelivered : undefined,
			notes: getValues().notes,
		}))
	}

	const deliveredPrograms = useQuery(fetching.query.getDeliveredProgramAll())

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "50%",
		[`@media only screen and (max-width: 1500px)`]: {
			width: "90%",
		},
	})
	const noteLabel = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "10px",
	})
	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(submit)}>
			<LabelLarge>Client Notes</LabelLarge>
			<ZenSelect label="Year Group" formName="yearGroup" formRef={control} options={EDUCATION_YEAR_GROUP.map((y) => ({ label: y, id: y }))} />
			<ZenSelect label="Program Delivered" formName="programDelivered" formRef={control} options={deliveredPrograms.payload} />
			<div className={noteLabel}>
				<LabelMedium>Pre-Session Note</LabelMedium>
			</div>
			<ZenTextArea formRef={control} nameRef="notes" resizable />
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={back} saveLabel="Next" />
		</form>
	)
}
