import { Action } from "react-fetching-library"

import { NDISFileType, NoteTypeCategory } from "../types/enums"
import {
	Alert,
	AlertType,
	BasicName,
	CallPurpose,
	Client,
	ClientFile,
	ContractArea,
	DeliveredProgram,
	Form,
	FundingSource,
	Gender,
	Language,
	MileageClaim,
	MileageClaimResult,
	NDISItemGroupBase,
	NDISLineItem,
	NDISPlan,
	NDISPlanFile,
	NDISPlanLine,
	NDISPlanMigrate,
	NDISTransferSupportBudget,
	Note,
	NoteTypeItem,
	OfficeWithAddress,
	PriceGuideImportResponse,
	Referrer,
	RelatedService,
	ServiceProvider,
	Session,
	SessionsNote,
	SubSupportType,
	SupportType,
	Timesheet,
	Travel,
	User,
	UserDetail,
	ValueCharge,
} from "../types/types"
import {
	BlacklistInput,
	CallLogInput,
	CarInput,
	ClientEmergencyContactInput,
	ClientParentGuardianContactInput,
	ClientUpdateInput,
	FormInput,
	HealthTreatmentTeamInput,
	ItemGroupInput,
	LockDateUpdateInput,
	NDISLineItemEditInput,
	NDISMigrateSessionInput,
	NDISPlanInput,
	NDISPlanLineInput,
	NDISTransferSupportBudgetInput,
	NonBillableInput,
	SessionGroupInput,
	SessionPostInput,
	SessionSingleInput,
	TimesheetAddNoteInput,
	TimesheetCreateInput,
	TimesheetUpdateInput,
	TravelInput,
	ValueChargeInput,
} from "./inputType"

const archiveUser = (values: { id: string }): Action<UserDetail> => {
	return {
		method: "POST",
		endpoint: `/users/archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const unarchiveUser = (values: { id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/users/unarchive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const fileUpload = (values: { file: File }): Action<{ fileID: string }> => {
	const formData = new FormData()
	formData.set("file", values.file)

	return {
		method: "POST",
		endpoint: `/files/upload`,
		credentials: "include",
		body: formData,
		responseType: "json",
	}
}

const clientArchive = (values: { id: string }): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientUnarchive = (values: { id: string }): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/unarchive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientUpdate = (values: ClientUpdateInput): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientNDISDetailUpdate = (values: { clientID: string; ndisNumber: string; contractAreaID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/clients/ndis_detail/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const userSupervisorAdd = (values: { supervisorID: string; workerID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/users/supervisor/add`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const userSupervisorRemove = (values: { supervisorID: string; workerID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/users/supervisor/remove`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const userUpdateTimezone = (values: { timezoneID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/users/update_timezone`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientNoteAdd = (values: { clientID: string; noteTypeID: string; content?: string; audioBlobID?: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/clients/notes/add`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientNoteEdit = (values: { id: string; content?: string; audioBlobID?: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/clients/notes/edit`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const noteArchive = (values: { id: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/notes/archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const noteUnarchive = (values: { id: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/notes/unarchive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const noteTypeCreate = (values: { category: NoteTypeCategory; name: string }): Action<boolean | NoteTypeItem> => {
	return {
		method: "POST",
		endpoint: `/notes/types/create`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const noteTypeUpdate = (values: { id: string; category: NoteTypeCategory; name: string }): Action<boolean | NoteTypeItem> => {
	return {
		method: "POST",
		endpoint: `/notes/types/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const noteTypeArchive = (values: { id: string; category: NoteTypeCategory }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/notes/types/archive`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const noteTypeUnarchive = (values: { id: string; category: NoteTypeCategory }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/notes/types/unarchive`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const callLogCreate = (values: CallLogInput): Action<string> => {
	return {
		method: "POST",
		endpoint: `/call_logs/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const callLogUpdate = (values: CallLogInput & { id: string }): Action<string> => {
	return {
		method: "POST",
		endpoint: `/call_logs/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const travelCreate = (values: TravelInput): Action<Travel> => {
	return {
		method: "POST",
		endpoint: `/travels/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const travelUpdate = (values: TravelInput): Action<Travel> => {
	return {
		method: "POST",
		endpoint: `/travels/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const alertToggleArchive = (values: { id: string }): Action<Alert> => {
	return {
		method: "POST",
		endpoint: `/alerts/archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const alertMarkRead = (values: { id: string }): Action<Alert> => {
	return {
		method: "POST",
		endpoint: `/alerts/mark_read`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const alertTypeCreate = (values: { name: string }): Action<boolean | AlertType> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/create`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const alertTypeUpdate = (values: { id: string; name: string }): Action<boolean | AlertType> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const alertTypeArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const alertTypeUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const alertSubtypeCreate = (values: { name: string; parentAlertTypeID: string }): Action<boolean | AlertType> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/${values.parentAlertTypeID}/subtypes/create`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const alertSubtypeUpdate = (values: { id: string; name: string; parentAlertTypeID: string }): Action<boolean | AlertType> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/${values.parentAlertTypeID}/subtypes/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const alertSubtypeArchive = (values: { id: string; parentAlertTypeID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/${values.parentAlertTypeID}/subtypes/${values.id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const alertSubtypeUnarchive = (values: { id: string; parentAlertTypeID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/${values.parentAlertTypeID}/subtypes/${values.id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const travelToggleArchive = (values: { id: string }): Action<Travel> => {
	return {
		method: "POST",
		endpoint: `/travels/toggle_archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const userToggleArchive = (values: { id: string }): Action<Travel> => {
	return {
		method: "POST",
		endpoint: `/users/toggle_archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const callLogAddNote = (values: { callLogID: string; content: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/call_logs/add_note`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionSingleCreate = (values: SessionSingleInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/single/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionNonBillableCreate = (values: NonBillableInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/non_billable/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionGroupCreate = (values: SessionGroupInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/group/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionPostUpdate = (values: SessionPostInput): Action<Session> => {
	return {
		method: "POST",
		endpoint: `/sessions/post/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionUncancel = (id: string): Action<Session> => {
	return {
		method: "POST",
		endpoint: `/sessions/uncancel`,
		credentials: "include",
		body: {
			id,
		},
		responseType: "json",
	}
}

const sessionNoteAdd = (values: { sessionID: string; clientID: string; type: string; content: string }): Action<SessionsNote> => {
	return {
		method: "POST",
		endpoint: `/sessions/note/add`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionSingleUpdate = (values: SessionSingleInput): Action<Session> => {
	return {
		method: "POST",
		endpoint: `/sessions/single/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionGroupUpdate = (values: SessionGroupInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/group/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionToggleArchive = (values: { id: string }): Action<Travel> => {
	return {
		method: "POST",
		endpoint: `/sessions/toggle_archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const sessionPreSessionNoteUpdate = (values: { sessionID: string; content: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/sessions/pre_session_note/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const ndisChargesRegenerate = (values: { startDate: Date; endDate: Date }): Action<{ sessionCount: number }> => {
	return {
		method: "POST",
		endpoint: `/admin/regenerate_charges`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const recalculateTravelLimits = (values: { startDate: Date; endDate: Date }): Action<{ travelCount: number }> => {
	return {
		method: "POST",
		endpoint: `/admin/recalculate_travel_limits`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const formCreate = <T>(values: FormInput): Action<Form<T>> => {
	return {
		method: "POST",
		endpoint: `/forms/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const formCreateWithSignature = <T>(values: { input: FormInput; signature: File }): Action<Form<T>> => {
	const formData = new FormData()
	formData.set("file", values.signature)
	formData.set("json", JSON.stringify({ input: values.input }))

	return {
		method: "POST",
		endpoint: `/forms/create_with_signature`,
		credentials: "include",
		body: formData,
		responseType: "json",
	}
}
const formSignatureUpdate = (values: { id: string; signature: File }): Action<boolean> => {
	const formData = new FormData()
	formData.set("file", values.signature)

	return {
		method: "POST",
		endpoint: `/forms/${values.id}/signature_update`,
		credentials: "include",
		body: formData,
		responseType: "json",
	}
}

const formUpdate = <T>(values: FormInput): Action<Form<T>> => {
	return {
		method: "POST",
		endpoint: `/forms/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const formReview = (formID: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/forms/review`,
		credentials: "include",
		body: {
			formID,
		},
		responseType: "json",
	}
}

const formEnableReview = (formID: string): Action<boolean> => {
	return {
		method: "GET",
		endpoint: `/forms/${formID}/enable_review`,
		credentials: "include",
		responseType: "json",
	}
}

const formDisableReview = (formID: string): Action<boolean> => {
	return {
		method: "GET",
		endpoint: `/forms/${formID}/disable_review`,
		credentials: "include",
		responseType: "json",
	}
}

const assignWorkerToClient = (values: { clientID: string; workerIDList: string[] }): Action<User[]> => {
	return {
		method: "POST",
		endpoint: `/clients/assign_workers`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const removeAssignedWorker = (values: { clientID: string; removeWorkerID: string; reason: string }): Action<User> => {
	return {
		method: "POST",
		endpoint: `/clients/remove_assigned_worker`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const assignClientToWorker = (values: { workerID: string; clientIDList: string[] }): Action<Client[]> => {
	return {
		method: "POST",
		endpoint: `/users/assign_clients`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const removeAssignedClient = (values: { workerID: string; removeClientID: string; reason: string }): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/users/remove_assigned_client`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const userCreate = (values: Record<string, unknown>): Action<UserDetail> => {
	return {
		method: "POST",
		endpoint: `/users/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const userUpdate = (values: Record<string, unknown>): Action<UserDetail> => {
	return {
		method: "POST",
		endpoint: `/users/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const invalidateTokens = (values: { userID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/auth/invalidate_tokens`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientAlertAdd = (values: {
	clientID: string
	content: string
	alertSubtypeID?: string
	customSubAlertType?: string
	customAlertType?: string
}): Action<Alert> => {
	return {
		method: "POST",
		endpoint: `/alerts/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientAlertUpdate = (values: { id: string; content: string }): Action<Alert> => {
	return {
		method: "POST",
		endpoint: `/alerts/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const clientCreate = (values: Record<string, unknown>): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientIntakeCreate = (values: Record<string, unknown>): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/intake/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const mileageClaimCreate = (values: { periodStart: Date; periodEnd: Date; userIDs: string[] }): Action<MileageClaimResult> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const mileageClaimUpdate = (values: { mileageClaimID?: string; periodStart: Date; periodEnd: Date; userIDs: string[] }): Action<MileageClaimResult> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const mileageClaimReady = (id: string): Action<MileageClaim> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/${id}/ready`,
		credentials: "include",
		responseType: "json",
	}
}

const mileageClaimDelete = (id: string): Action<MileageClaim> => {
	return {
		method: "DELETE",
		endpoint: `/mileage_claims/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const mileageClaimCheck = (id: string): Action<MileageClaim> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/${id}/check`,
		credentials: "include",
		responseType: "json",
	}
}

const reviewMileageClaim = (values: { id: string; outcome: string; declinedReason?: string }): Action<MileageClaim> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/review`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const unapproveMileageClaim = (values: { mileageClaimID: string }): Action<MileageClaim> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/unapprove`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const completeMileageClaim = (values: { id: string }): Action<MileageClaim> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/complete`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const uncompleteMileageClaim = (values: { id: string }): Action<MileageClaim> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/uncomplete`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const mileageAddNote = (values: { userIDs: string[], mileageID: string, mileageNotes: string }): Action<MileageClaimResult> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/add_note`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const mileageEditNote = (values: { userIDs: string[], mileageID: string, mileageNotes: string }): Action<MileageClaimResult> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/edit_note`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientHealthTeamCreate = (values: HealthTreatmentTeamInput): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/health_team/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientHealthTeamUpdate = (values: HealthTreatmentTeamInput): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/health_team/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientHealthTeamArchive = (values: { id: string }): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/health_team/archive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientHealthTeamUnarchive = (values: { id: string }): Action<Client> => {
	return {
		method: "POST",
		endpoint: `/clients/health_team/unarchive`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientFilesUpload = (values: { clientID: string; files: File[] }): Action<Form<any>> => {
	return {
		method: "POST",
		endpoint: `/files/upload`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}
const clientFilesAdd = (values: { clientID: string; fileID: string }): Action<Form<any>> => {
	return {
		method: "POST",
		endpoint: `/clients/files/add`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientFileArchive = (id: string): Action<ClientFile> => {
	return {
		method: "POST",
		endpoint: `/files/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const clientFileUnarchive = (id: string): Action<ClientFile> => {
	return {
		method: "POST",
		endpoint: `/files/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const clientFormArchive = (id: string): Action<Form<any>> => {
	return {
		method: "POST",
		endpoint: `/forms/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const clientFormUnarchive = (id: string): Action<Form<any>> => {
	return {
		method: "POST",
		endpoint: `/forms/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const sessionNoteUpdate = (values: { id: string; content: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/sessions/note/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const updateLockDate = (values: LockDateUpdateInput): Action<{ date: string; updatedAt: string }> => {
	return {
		method: "PATCH",
		endpoint: `/admin/lock_date`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const timesheetCreate = (values: TimesheetCreateInput): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const timesheetUpdate = (values: TimesheetUpdateInput): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const timesheetReady = (id: string): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/${id}/ready`,
		credentials: "include",
		responseType: "json",
	}
}

const timesheetUnapprove = (values: { timesheetID: string }): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/unapprove`,
		body: values,
		credentials: "include",
		responseType: "json",
	}
}

const timesheetDelete = (id: string): Action<Timesheet> => {
	return {
		method: "DELETE",
		endpoint: `/timesheets/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const timesheetAddNote = (values: TimesheetAddNoteInput): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/add_note`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const timesheetEditNote = (values: TimesheetAddNoteInput): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/edit_note`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientNDISCreate = (values: NDISPlanInput): Action<NDISPlan> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientNDISUpdate = (values: NDISPlanInput): Action<NDISPlan> => {
	return {
		method: "PATCH",
		endpoint: `/ndis_plans/${values.planID}`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const clientNDISInactivate = (values: { clientID: string }): Action<boolean> => {
	return {
		method: "PATCH",
		endpoint: "/ndis_plans/inactivate-plans",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const NDISPlanNoteUpdate = (values: { planID: string; note: string }): Action<boolean> => {
	return {
		method: "PATCH",
		endpoint: `/ndis_plans/note`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const ndisPlanAddLine = (values: NDISPlanLineInput): Action<NDISPlanLine> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/${values.planID}/lines`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const ndisPlanDeleteLine = ({ id }: { id: string }): Action<NDISPlanLine> => {
	return {
		method: "DELETE",
		endpoint: `/ndis_plans/lines/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const ndisPlanEditLine = (values: NDISPlanLineInput): Action<NDISPlanLine> => {
	return {
		method: "PATCH",
		endpoint: `/ndis_plans/lines/${values.planLineID}`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const ndisMigrateSessions = (values: NDISMigrateSessionInput): Action<NDISPlanMigrate> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/${values.planID}/migrate_sessions`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const ndisTransferSupportBudget = (values: NDISTransferSupportBudgetInput): Action<NDISTransferSupportBudget> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/${values.planID}/transfer_support_budget`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const ndisPlanManagerCreate = (values: { id: string; label: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/plan_managers/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const ndisPlanManagerUpdate = (values: { id: string; label: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/plan_managers/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const ndisPlanManagerArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/plan_managers/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const ndisPlanManagerUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/plan_managers/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const ndisLineItemToggleArchive = (values: { id: string }): Action<NDISLineItem> => {
	return {
		method: "DELETE",
		endpoint: `/ndis_management/line_items/${values.id}`,
		credentials: "include",
		responseType: "json",
	}
}

const ndisLineItemUpdate = (values: NDISLineItemEditInput): Action<NDISLineItem> => {
	const { id, ...rest } = values

	return {
		method: "PATCH",
		endpoint: `/ndis_management/line_items/${values.id}`,
		credentials: "include",
		body: {
			...rest,
		},
		responseType: "json",
	}
}

const itemGroupToggleArchive = (values: { id: string }): Action<NDISItemGroupBase> => {
	return {
		method: "DELETE",
		endpoint: `/ndis_management/item_groups/${values.id}`,
		credentials: "include",
		responseType: "json",
	}
}

const itemGroupUpdate = (values: { id: string; input: ItemGroupInput }): Action<NDISItemGroupBase> => {
	return {
		method: "PATCH",
		endpoint: `/ndis_management/item_groups/${values.id}`,
		credentials: "include",
		body: {
			...values.input,
		},
		responseType: "json",
	}
}

const itemGroupCreate = (values: { id: string; input: ItemGroupInput }): Action<NDISItemGroupBase> => {
	return {
		method: "POST",
		endpoint: `/ndis_management/price_guides/${values.id}/item_groups`,
		credentials: "include",
		body: {
			...values.input,
		},
		responseType: "json",
	}
}

const priceGuideImport = (values: {
	file: File
	json: { yearTo: number; yearFrom: number; versionNumber: string; commit: boolean }
}): Action<PriceGuideImportResponse> => {
	const formData = new FormData()
	formData.set("file", values.file)
	formData.set("json", JSON.stringify(values.json))

	return {
		method: "POST",
		endpoint: `/ndis_management/import`,
		credentials: "include",
		body: formData,
		responseType: "json",
	}
}

const ndisPlanAddFile = (values: { file: File; json: { fileType: NDISFileType }; planID: string }): Action<NDISPlanFile> => {
	const formData = new FormData()
	formData.set("file", values.file)
	formData.set("json", JSON.stringify(values.json))

	return {
		method: "POST",
		endpoint: `/ndis_plans/${values.planID}/files`,
		credentials: "include",
		body: formData,
		responseType: "json",
	}
}

const ndisAddGeneralCharge = (values: { file?: File; json: ValueChargeInput; planLineID: string }): Action<ValueCharge> => {
	const formData = new FormData()
	if (values.file) formData.set("file", values.file)
	formData.set("json", JSON.stringify(values.json))

	return {
		method: "POST",
		endpoint: `/ndis_plans/${values.planLineID}/general_charges`,
		credentials: "include",
		body: formData,
		responseType: "json",
	}
}

const ndisPlanFileDelete = (planFileID: string): Action<NDISPlanFile> => {
	return {
		method: "DELETE",
		endpoint: `/ndis_plans/files/${planFileID}`,
		credentials: "include",
		responseType: "json",
	}
}

const ndisGeneralChargeDelete = (valueChargeID: string): Action<ValueCharge> => {
	return {
		method: "DELETE",
		endpoint: `/ndis_plans/general_charges/${valueChargeID}`,
		credentials: "include",
		responseType: "json",
	}
}

const timesheetComplete = (values: { id: string }): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/complete`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const timesheetUncomplete = (values: { id: string }): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/uncomplete`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const timesheetReview = (values: { id: string }): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/review`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const timesheetCheck = (id: string): Action<Timesheet> => {
	return {
		method: "POST",
		endpoint: `/timesheets/${id}/check`,
		credentials: "include",
		responseType: "json",
	}
}

const sessionNDISNoteUpdate = (values: any): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/notes/ndis_session_detail/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const callLogNoteUpdate = (values: { noteID: string; content: string }): Action<Note> => {
	return {
		method: "POST",
		endpoint: `/call_logs/note/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const clientEmergencyContactCreate = (values: ClientEmergencyContactInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/clients/emergency_contact/create`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}
const clientEmergencyContactUpdate = (values: ClientEmergencyContactInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/clients/emergency_contact/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const clientEmergencyContactArchive = (values: { clientID: string; emergencyContactID: string }): Action<boolean> => {
	return {
		method: "DELETE",
		endpoint: `/clients/emergency_contact/archive`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const clientEmergencyContactUnarchive = (values: { clientID: string; emergencyContactID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/clients/emergency_contact/unarchive`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const clientParentGuardianContactCreate = (values: ClientParentGuardianContactInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/clients/parent_guardian_contact/create`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const clientParentGuardianContactUpdate = (values: ClientParentGuardianContactInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/clients/parent_guardian_contact/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const clientParentGuardianContactArchive = (id: string): Action<boolean> => {
	return {
		method: "DELETE",
		endpoint: `/clients/parent_guardian_contact/archive`,
		credentials: "include",
		body: {
			id,
		},
		responseType: "json",
	}
}

const clientParentGuardianContactUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/clients/parent_guardian_contact/unarchive`,
		credentials: "include",
		body: {
			id,
		},
		responseType: "json",
	}
}

const carCreate = (values: CarInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/cars/create`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const carUpdate = (values: CarInput): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/cars/update`,
		credentials: "include",
		body: values,
		responseType: "json",
	}
}

const carArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/cars/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const carUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/cars/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const languageCreate = (values: { label: string }): Action<boolean | Language> => {
	return {
		method: "POST",
		endpoint: `/languages/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const languageUpdate = (values: { id: string; label: string }): Action<boolean | Language> => {
	return {
		method: "POST",
		endpoint: `/languages/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const languageArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/languages/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const languageUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/languages/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const genderCreate = (values: { label: string }): Action<boolean | Gender> => {
	return {
		method: "POST",
		endpoint: `/genders/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const genderUpdate = (values: { id: string; label: string }): Action<boolean | Gender> => {
	return {
		method: "POST",
		endpoint: `/genders/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const genderArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/genders/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const genderUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/genders/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const relatedServiceCreate = (values: { name: string }): Action<boolean | RelatedService> => {
	return {
		method: "POST",
		endpoint: `/related_services/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const relatedServiceUpdate = (values: { id: string; name: string }): Action<boolean | RelatedService> => {
	return {
		method: "POST",
		endpoint: `/related_services/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const relatedServiceArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/related_services/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const relatedServiceUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/related_services/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const serviceProviderCreate = (values: { name: string }): Action<boolean | ServiceProvider> => {
	return {
		method: "POST",
		endpoint: `/service_providers/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const serviceProviderUpdate = (values: { id: string; name: string }): Action<boolean | ServiceProvider> => {
	return {
		method: "POST",
		endpoint: `/service_providers/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const serviceProviderArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/service_providers/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const serviceProviderUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/service_providers/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const officeCreate = (values: { name: string; placeID: string }): Action<boolean | OfficeWithAddress> => {
	return {
		method: "POST",
		endpoint: `/offices/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const officeUpdate = (values: { id: string; name: string; placeID: string }): Action<boolean | OfficeWithAddress> => {
	return {
		method: "POST",
		endpoint: `/offices/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const officeArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/offices/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const officeUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/offices/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const referrerCreate = (values: { referrerName: string; parentID?: string }): Action<boolean | Referrer> => {
	return {
		method: "POST",
		endpoint: `/referrers/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const referrerUpdate = (values: { id: string; referrerName: string; parentID?: string }): Action<boolean | Referrer> => {
	return {
		method: "POST",
		endpoint: `/referrers/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const referrerArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/referrers/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const referrerUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/referrers/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const departmentCreate = (values: { name: string }): Action<boolean | BasicName> => {
	return {
		method: "POST",
		endpoint: `/departments/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const departmentUpdate = (values: { id: string; name: string }): Action<boolean | BasicName> => {
	return {
		method: "POST",
		endpoint: `/departments/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const departmentArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/departments/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const departmentUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/departments/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const positionCreate = (values: { name: string; departmentID: string }): Action<boolean | BasicName> => {
	return {
		method: "POST",
		endpoint: `/positions/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const positionUpdate = (values: { id: string; name: string; departmentID: string }): Action<boolean | BasicName> => {
	return {
		method: "POST",
		endpoint: `/positions/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const positionArchive = (values: { id: string; departmentID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/positions/${values.id}/archive?departmentID=${values.departmentID}`,
		credentials: "include",
		responseType: "json",
	}
}

const positionUnarchive = (values: { id: string; departmentID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/positions/${values.id}/unarchive?departmentID=${values.departmentID}`,
		credentials: "include",
		responseType: "json",
	}
}

const callPurposeCreate = (values: { label: string }): Action<boolean | CallPurpose> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const callPurposeUpdate = (values: { id: string; label: string }): Action<boolean | CallPurpose> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const callPurposeArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const callPurposeUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const callPurposeTypeCreate = (values: { label: string; callPurposeID: string }): Action<boolean | CallPurpose> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/${values.callPurposeID}/call_purpose_types/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const callPurposeTypeUpdate = (values: { id: string; label: string; callPurposeID: string }): Action<boolean | CallPurpose> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/${values.callPurposeID}/call_purpose_types/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const callPurposeTypeArchive = (values: { id: string; callPurposeID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/${values.callPurposeID}/call_purpose_types/${values.id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const callPurposeTypeUnarchive = (values: { id: string; callPurposeID: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/${values.callPurposeID}/call_purpose_types/${values.id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const fundingSourceCreate = (values: { label: string }): Action<boolean | FundingSource> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const fundingSourceUpdate = (values: { id: string; label: string }): Action<boolean | FundingSource> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const fundingSourceArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const fundingSourceUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const contractAreaCreate = (values: { fundingSourceID: string; label: string }): Action<boolean | ContractArea> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/contract_areas/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const contractAreaUpdate = (values: { fundingSourceID: string; id: string; label: string }): Action<boolean | ContractArea> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/contract_areas/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const contractAreaArchive = (values: { fundingSourceID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/contract_areas/${values.id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const contractAreaUnarchive = (values: { fundingSourceID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/contract_areas/${values.id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const supportTypeCreate = (values: { fundingSourceID: string; label: string }): Action<boolean | SupportType> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const supportTypeUpdate = (values: { fundingSourceID: string; id: string; label: string }): Action<boolean | SupportType> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const supportTypeArchive = (values: { fundingSourceID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const supportTypeUnarchive = (values: { fundingSourceID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const subSupportTypeCreate = (values: { fundingSourceID: string; supportTypeID: string; label: string }): Action<boolean | SubSupportType> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const subSubSupportTypeCreate = (values: { fundingSourceID: string; supportTypeID: string; subSupportTypeID: string; label: string }): Action<boolean | SubSupportType> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.subSupportTypeID}/sub_sub_support_types/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const subSupportTypeUpdate = (values: { fundingSourceID: string; supportTypeID: string; id: string; label: string }): Action<boolean | SubSupportType> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const subSubSupportTypeUpdate = (values: { fundingSourceID: string; supportTypeID: string; subSupportTypeID: string; id: string; label: string }): Action<boolean | SubSupportType> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.subSupportTypeID}/sub_sub_support_types/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const subSubSupportTypeArchive = (values: { fundingSourceID: string; supportTypeID: string; subSupportTypeID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.subSupportTypeID}/sub_sub_support_types/${values.id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const subSupportTypeArchive = (values: { fundingSourceID: string; supportTypeID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const subSubSupportTypeUnarchive = (values: { fundingSourceID: string; supportTypeID: string; subSupportTypeID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.subSupportTypeID}/sub_sub_support_types/${values.id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const subSupportTypeUnarchive = (values: { fundingSourceID: string; supportTypeID: string; id: string }): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const deliveredProgramCreate = (values: { label: string }): Action<boolean | DeliveredProgram> => {
	return {
		method: "POST",
		endpoint: `/sessions/delivered_programs/create`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const deliveredProgramUpdate = (values: { id: string; label: string }): Action<boolean | DeliveredProgram> => {
	return {
		method: "POST",
		endpoint: `/sessions/delivered_programs/update`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const deliveredProgramArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/delivered_programs/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}

const deliveredProgramUnarchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/sessions/delivered_programs/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const blacklistCreate = (values: BlacklistInput): Action<Travel> => {
	return {
		method: "POST",
		endpoint: `/users/blacklist/create`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const blacklistUpdate = (values: BlacklistInput): Action<Travel> => {
	return {
		method: "POST",
		endpoint: `/users/blacklist/update`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const blacklistUnArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/users/blacklist/${id}/unarchive`,
		credentials: "include",
		responseType: "json",
	}
}

const blacklistArchive = (id: string): Action<boolean> => {
	return {
		method: "POST",
		endpoint: `/users/blacklist/${id}/archive`,
		credentials: "include",
		responseType: "json",
	}
}


export const mutation = {
	archiveUser,
	unarchiveUser,
	fileUpload,
	clientArchive,
	clientUnarchive,
	clientUpdate,
	clientNoteAdd,
	clientNoteEdit,
	noteArchive,
	noteUnarchive,
	callLogCreate,
	callLogUpdate,
	userSupervisorAdd,
	userSupervisorRemove,
	travelCreate,
	travelUpdate,
	travelToggleArchive,
	userToggleArchive,
	alertToggleArchive,
	alertMarkRead,
	callLogAddNote,
	sessionSingleCreate,
	sessionToggleArchive,
	sessionGroupCreate,
	sessionPostUpdate,
	sessionUncancel,
	sessionNoteAdd,
	sessionSingleUpdate,
	sessionGroupUpdate,
	formCreate,
	formUpdate,
	assignWorkerToClient,
	removeAssignedWorker,
	userCreate,
	userUpdate,
	clientAlertAdd,
	clientAlertUpdate,
	clientCreate,
	clientIntakeCreate,
	mileageClaimCreate,
	mileageClaimUpdate,
	mileageClaimReady,
	mileageClaimDelete,
	reviewMileageClaim,
	unapproveMileageClaim,
	completeMileageClaim,
	uncompleteMileageClaim,
	mileageAddNote,
	mileageEditNote,
	clientHealthTeamUpdate,
	clientFilesAdd,
	clientFileArchive,
	clientFileUnarchive,
	sessionNoteUpdate,
	timesheetCreate,
	timesheetUpdate,
	timesheetReady,
	timesheetDelete,
	timesheetComplete,
	timesheetUncomplete,
	timesheetReview,
	timesheetUnapprove,
	timesheetAddNote,
	timesheetEditNote,
	clientNDISCreate,
	clientNDISUpdate,
	clientNDISInactivate,
	NDISPlanNoteUpdate,
	ndisPlanAddLine,
	ndisPlanDeleteLine,
	ndisPlanAddFile,
	ndisPlanFileDelete,
	ndisMigrateSessions,
	ndisTransferSupportBudget,
	ndisPlanManagerCreate,
	ndisPlanManagerUpdate,
	ndisPlanManagerArchive,
	ndisPlanManagerUnarchive,
	clientFormArchive,
	clientFormUnarchive,
	clientFilesUpload,
	sessionNDISNoteUpdate,
	ndisPlanEditLine,
	clientHealthTeamArchive,
	clientHealthTeamUnarchive,
	clientHealthTeamCreate,
	sessionNonBillableCreate,
	formReview,
	formEnableReview,
	formDisableReview,
	invalidateTokens,
	callLogNoteUpdate,
	clientEmergencyContactCreate,
	clientEmergencyContactUpdate,
	clientEmergencyContactArchive,
	clientEmergencyContactUnarchive,
	clientParentGuardianContactCreate,
	clientParentGuardianContactUpdate,
	clientParentGuardianContactArchive,
	clientParentGuardianContactUnarchive,
	ndisLineItemToggleArchive,
	priceGuideImport,
	updateLockDate,
	ndisLineItemUpdate,
	itemGroupToggleArchive,
	itemGroupUpdate,
	itemGroupCreate,
	ndisGeneralChargeDelete,
	ndisAddGeneralCharge,
	userUpdateTimezone,
	sessionPreSessionNoteUpdate,
	ndisChargesRegenerate,
	recalculateTravelLimits,
	mileageClaimCheck,
	clientNDISDetailUpdate,
	timesheetCheck,
	carCreate,
	carUpdate,
	carArchive,
	carUnarchive,
	languageCreate,
	languageUpdate,
	languageArchive,
	languageUnarchive,
	genderCreate,
	genderUpdate,
	genderArchive,
	genderUnarchive,
	relatedServiceCreate,
	relatedServiceUpdate,
	relatedServiceArchive,
	relatedServiceUnarchive,
	serviceProviderCreate,
	serviceProviderUpdate,
	serviceProviderArchive,
	serviceProviderUnarchive,
	officeCreate,
	officeUpdate,
	officeArchive,
	officeUnarchive,
	noteTypeCreate,
	noteTypeUpdate,
	noteTypeArchive,
	noteTypeUnarchive,
	referrerCreate,
	referrerUpdate,
	referrerArchive,
	referrerUnarchive,
	alertTypeCreate,
	alertTypeUpdate,
	alertTypeArchive,
	alertTypeUnarchive,
	alertSubtypeCreate,
	alertSubtypeUpdate,
	alertSubtypeArchive,
	alertSubtypeUnarchive,
	departmentCreate,
	departmentUpdate,
	departmentArchive,
	departmentUnarchive,
	positionCreate,
	positionUpdate,
	positionArchive,
	positionUnarchive,
	callPurposeCreate,
	callPurposeUpdate,
	callPurposeArchive,
	callPurposeUnarchive,
	callPurposeTypeCreate,
	callPurposeTypeUpdate,
	callPurposeTypeArchive,
	callPurposeTypeUnarchive,
	fundingSourceCreate,
	fundingSourceUpdate,
	fundingSourceArchive,
	fundingSourceUnarchive,
	contractAreaCreate,
	contractAreaUpdate,
	contractAreaArchive,
	contractAreaUnarchive,
	supportTypeCreate,
	supportTypeUpdate,
	supportTypeArchive,
	supportTypeUnarchive,
	subSupportTypeCreate,
	subSubSupportTypeCreate, // sub sub
	subSubSupportTypeUpdate, // sub sub
	subSupportTypeUpdate,
	subSupportTypeArchive,
	subSubSupportTypeArchive, // sub sub
	subSupportTypeUnarchive,
	subSubSupportTypeUnarchive, // sub sub
	deliveredProgramCreate,
	deliveredProgramUpdate,
	deliveredProgramArchive,
	deliveredProgramUnarchive,
	formCreateWithSignature,
	formSignatureUpdate,
	assignClientToWorker,
	removeAssignedClient,
	blacklistCreate,
	blacklistUpdate,
	blacklistArchive,
	blacklistUnArchive,
}
