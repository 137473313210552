import * as React from "react"
import { FileUploader } from "baseui/file-uploader"
import { Button, ButtonProps } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Paragraph1 } from "baseui/typography"

interface ZenFileUploaderProps {
	onError?: (error: string) => void
	onFileAccepted?: (file: File) => void
	disabled?: boolean
	secondaryLabel?: string
	acceptedFileTypes?: string[]
}

export const ZenFileUploader = (props: ZenFileUploaderProps) => {
	return (
		<FileUploader
			accept={props.acceptedFileTypes || [".doc", ".docx", ".xls", ".xlsx", ".csv", ".ppt", ".pptx", ".pdf", ".jpg", ".jpeg", ".png", ".gif", ".bmp"]}
			disabled={props.disabled}
			multiple={false}
			onDrop={(acceptedFiles, rejectedFiles) => {
				let errorMsg = ""
				if (acceptedFiles.length === 1) {
					if (acceptedFiles[0].size > 50 << 20) {
						errorMsg = "Files must be under 50MB in size."
						props.onError && props.onError(errorMsg)
						return
					} else {
						props.onFileAccepted && props.onFileAccepted(acceptedFiles[0])
						return
					}
				}
				if (acceptedFiles.length > 1) {
					errorMsg = "You may only upload one file at a time."
				}
				if (rejectedFiles.length > 0) {
					errorMsg = "This file type is not allowed."
				}
				props.onError && props.onError(errorMsg)
			}}
			overrides={{
				Root: {
					style: {
						width: "unset",
						borderLeft: "1px solid #D6DBF0",
						borderRight: "1px solid #D6DBF0",
						borderTop: "1px solid #D6DBF0",
						borderBottom: "1px solid #D6DBF0",
					},
				},

				ContentMessage: {
					style: { display: "none" },
				},
				ContentSeparator: {
					style: { display: "none" },
				},
				FileDragAndDrop: {
					style: {
						paddingLeft: 0,
						paddingRight: 0,
						paddingTop: 0,
						paddingBottom: 0,
						borderTopStyle: "unset",
						borderBottomStyle: "unset",
						borderLeftStyle: "unset",
						borderRightStyle: "unset",
						outline: "unset",
					},
				},
				ButtonComponent: { component: (rest: BrowseFileButtonProps) => <BrowseFileButton {...rest} label={props.secondaryLabel} /> },
			}}
		/>
	)
}

interface BrowseFileButtonProps extends ButtonProps {
	label?: string
}

const BrowseFileButton = ({ ...rest }: BrowseFileButtonProps) => {
	return (
		<Button
			{...rest}
			type="button"
			overrides={{
				BaseButton: {
					style: {
						minWidth: "170px",
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#F8F9FD",
						color: "#D6DBF0",
						":hover": {
							backgroundColor: "#F8F9FD",
						},
					},
				},
			}}
		>
			<FontAwesomeIcon color={"#8189A6"} icon={["fal", "upload"]} size={"3x"} />
			<Paragraph1
				overrides={{
					Block: {
						style: {
							fontSize: "15px",
							marginBottom: 0,
						},
					},
				}}
				color={"#8189A6"}
			>
				Drag and drop files here or click to browse
			</Paragraph1>
			<Paragraph1
				overrides={{
					Block: {
						style: {
							margin: 0,
							fontSize: "15px",
						},
					},
				}}
				color={"#8189A6"}
			>
				{rest.label || "(doc, docx, xls, xlsx, csv, ppt, pptx, pdf, jpg, jpeg, png, gif, bmp)"}
			</Paragraph1>
		</Button>
	)
}
