import * as React from "react"
import { useStyletron } from "baseui"
import { StyledSpinnerNext } from "baseui/spinner"
import { useMutation } from "react-fetching-library"
import { FormProvider, useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"
import { ClientCreateForm } from "../../components/clients/clientCreateForm"
import { useZenToast } from "../../components/zenComponents/useZenToast"
import { fetching } from "../../fetching"
import { getError } from "../../helpers/errors"
import { Client } from "../../types/types"
import { PronounSection } from "types/enums"

export const ClientCreate = () => {
	const [css] = useStyletron()
	const history = useHistory()
	const { showToast } = useZenToast()
	const [avatar, setAvatar] = React.useState<File>()
	const [sameAsResidentialAddress, setSameAsResidentialAddress] = React.useState<boolean>(false)
	const [manualPostalAddress, setManualPostalAddress] = React.useState(false)
	const [pronounSection, setPronounSection] = React.useState<string>("")

	const pronounOptionArr = [
		{ id: PronounSection.HeHimHis, label: "He/Him/His"},
		{ id: PronounSection.SheHer, label: "She/Her"},
		{ id: PronounSection.TheyThem, label: "They/Them"},
	]
	const pronounOptionMemo = React.useMemo(() => {
		return !!pronounSection ? 
		[
			{id: pronounSection, label: pronounSection},
			...pronounOptionArr
		]
		: pronounOptionArr
	}, [pronounSection]);
	const { mutate: clientCreate, error: createError, loading: createLoading, payload: createPayload } = useMutation<Client>(fetching.mutation.clientCreate)
	const fileUpload = useMutation<{ fileID: string }>(fetching.mutation.fileUpload)

	// marketing data
	const [explainedServiceIDs, setExplainedServiceIDs] = React.useState<string[]>([])
	const [interestedServiceIDs, setInterestedServiceIDs] = React.useState<string[]>([])

	const formMethods = useForm()
	const onSubmit = async () => {
		const data = formMethods.getValues()

		// avatar upload
		let avatarID = ""
		if (avatar) {
			const resp = await fileUpload.mutate({ file: avatar })
			if (!resp || resp.error) return
			avatarID = resp.payload?.fileID || ""
		}

		const postalAddress = () => {
			if (sameAsResidentialAddress) return undefined
			if (manualPostalAddress) {
				return {
					postalAddressLine1: data.manualPostalAddress.addressLine1,
					postalAddressLine2: data.manualPostalAddress.addressLine2,
					postalSuburb: data.manualPostalAddress.suburb,
					postalState: data.manualPostalAddress.state,
					postalPostalCode: data.manualPostalAddress.postalCode,
				}
			}
			if (data.postalAddress && data.postalAddress.length > 0) {
				return { postalAddressPlaceID: data.postalAddress[0].id }
			}
			return undefined
		}

		const resp = await clientCreate({
			avatarID: avatarID || null,
			firstName: data.firstName,
			lastName: data.lastName,
			preferredName: data.preferredName,
			dateOfBirth: data.dateOfBirth,
			firstDateOfContact: data.firstDateOfContact,
			hasApproximateDob: data.hasApproximateDob,
			leaveTextMessage: !!data.leaveTextMessage,
			agreedToReceiveEmails: !!data.agreedToReceiveEmails,
			leaveVoiceMessage: !!data.leaveVoiceMessage,

			// additional information
			countryOfBirthID: data.countryOfBirthID && data.countryOfBirthID.length > 0 ? data.countryOfBirthID[0].id : undefined,
			languageSpokenAtHomeID: data.languageSpokenAtHomeID && data.languageSpokenAtHomeID.length > 0 ? data.languageSpokenAtHomeID[0].id : undefined,
			requiresTranslator: data.requiresTranslator,
			residencyStatus: data.residencyStatus && data.residencyStatus.length > 0 ? data.residencyStatus[0].id : undefined,
			genderID: data.genderID && data.genderID.length > 0 ? data.genderID[0].id : undefined,
			culturallyAndLinguisticallyDiverse: data.culturallyAndLinguisticallyDiverse,
			lesbianGayBisexualAndTransgender: data.lesbianGayBisexualAndTransgender,
			aboriginalOrTorresStraitIslander:
				data.aboriginalOrTorresStraitIslander && data.aboriginalOrTorresStraitIslander.length > 0 ? data.aboriginalOrTorresStraitIslander[0].id : undefined,

			contact: {
				residentialAddressPlaceID: data.residentialAddress && data.residentialAddress.length > 0 ? data.residentialAddress[0].id : undefined,
				postalAddress: postalAddress(),
				email: data.email,
				telephoneNumber: data.telephoneNumber,
				mobileNumber: data.mobileNumber,
			},

			parentGuardian: data.hasParentGuardianContact
				? {
					firstName: data.parentGuardianFirstName,
					lastName: data.parentGuardianLastName,
					relationship: data.parentGuardianRelationship,
					telephoneNumber: data.parentGuardianTelephoneNumber,
					mobileNumber: data.parentGuardianMobileNumber,
					dateOfBirth: data.parentGuardianDateOfBirth,
					countryOfBirthID: data.parentGuardianCountryOfBirthID,
					languageSpokenAtHomeID: data.parentGuardianLanguageSpokenAtHomeID,
					requiresTranslator: data.parentGuardianRequiredTranslator,
					culturallyAndLinguisticallyDiverse: data.parentGuardianCulturallyAndLinguisticallyDiverse,
					aboriginalOrTorresStraitIslander: data.parentGuardianAboriginalOrTorresStraitIslander,
				}
				: undefined,

			// marketing information
			receiveUpcomingEvent: data.receiveUpcomingEvent,
			whereClientHearAboutUs: data.whereClientHearAboutUs && data.whereClientHearAboutUs.length > 0 ? data.whereClientHearAboutUs[0].label : undefined,
			initialContactSourceID: data.initialContactSource && data.initialContactSource.length > 0 ? data.initialContactSource[0].id : undefined,
			helpLookingFor: data.helpLookingFor,
			serviceProviderID: data.serviceProvider && data.serviceProvider.length > 0 ? data.serviceProvider[0].id : undefined,
			customServiceProvider: data.customServiceProvider,
			interestedServiceIDs: interestedServiceIDs,
			explainedServiceIDs: explainedServiceIDs,
			isOrganisation: data.isOrganisation,
			currentlyCaringForNdisPlan: !data.isOrganisation && data.currentlyCaringForNdisPlan && data.currentlyCaringForNdisPlan.length > 0 ? data.currentlyCaringForNdisPlan[0].id : undefined,
			hasAppliedAndDeclinedNdisPlan: !data.isOrganisation && data.hasAppliedAndDeclinedNdisPlan && data.hasAppliedAndDeclinedNdisPlan.length > 0 ? data.hasAppliedAndDeclinedNdisPlan[0].id : undefined,
			pronounSection: data.pronounSection?.length > 0 ? data.pronounSection[0]?.id : !!pronounSection ? pronounSection : undefined,
		})
		if (resp.status === 200 && resp.payload) {
			formMethods.control.updateFormState({ isDirty: false })
			showToast("Client created successfully.", "positive")

			if (resp.payload.isOrganisation) {
				history.push(`/portal/sessions/create_group_session?client_id=${resp.payload.id}`)
				return
			}
			history.push(`/portal/sessions/create_single_session?client_id=${resp.payload.id}`)
		}
	}

	const container = css({
		height: "100%",
		width: "100%",
		display: "flex",
		position: createLoading || fileUpload.loading ? "relative" : "unset",
		opacity: createLoading || fileUpload.loading ? 0.5 : 1,
	})

	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})

	return (
		<>
			<FormProvider {...formMethods}>
				<form autoComplete="off" className={container} onSubmit={formMethods.handleSubmit(onSubmit)}>
					{(createLoading || fileUpload.loading) && (
						<div className={loadingIcon}>
							<StyledSpinnerNext />
						</div>
					)}
					<ClientCreateForm
						errorObject={createError ? getError(createPayload) : fileUpload.error ? getError(fileUpload.payload) : undefined}
						submit={onSubmit}
						avatar={avatar}
						setAvatar={setAvatar}
						sameAsResidentialAddress={sameAsResidentialAddress}
						setSameAsResidentialAddress={setSameAsResidentialAddress}
						manualPostalAddress={manualPostalAddress}
						setManualPostalAddress={setManualPostalAddress}
						explainedServiceIDs={explainedServiceIDs}
						setExplainedServiceIDs={setExplainedServiceIDs}
						interestedServiceIDs={interestedServiceIDs}
						setInterestedServiceIDs={setInterestedServiceIDs}
						pronounOptionMemo={pronounOptionMemo}
						setPronounSection={setPronounSection}
					/>
				</form>
			</FormProvider>
			<Prompt when={formMethods.formState.isDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
		</>
	)
}
