import * as React from "react"
import { StyledSpinnerNext } from "baseui/spinner"
import { useStyletron } from "baseui"
import { H6 } from "baseui/typography"

export const Loading = (props: { hideLabel?: boolean }) => {
	const [css] = useStyletron()
	const containerStyle = css({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
	})
	const contentStyle = css({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	})

	return (
		<div className={containerStyle}>
			<div className={contentStyle}>
				<StyledSpinnerNext />
				{!props.hideLabel && <H6>Loading...</H6>}
			</div>
		</div>
	)
}
