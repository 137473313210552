import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Block, BlockOverrides } from "baseui/block"
import { Checkbox } from "baseui/checkbox"
import { FormControl } from "baseui/form-control"
import { Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form"
import { useHistory } from "react-router"
import { Prompt } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { ZenTheme } from "../../themeOverrides"
import { FormType, FormTypeName } from "../../types/enums"
import { ClientDetail, Form, ReviewRecord } from "../../types/types"
import { Divider, Spacer, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { DisplayLabel } from "../sessions/newClientAppointment/singleSessionConfirmation"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton } from "../zenComponents/zenButtons"
import { DisplaySelectCard, ZenClientSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../zenComponents/zenTime"
import { ErrorFieldTracker } from "./errorFieldTracker"

enum checkOption {
	Yes = "Y",
	No = "N",
	Unknown = "UK",
}

interface CheckBoxLabels {
	label: string
	name: string
	caption?: string
	backgroundFactors: string[]
	currentFactors: string[]
}

const boldText: BlockOverrides = {
	Block: {
		style: {
			fontWeight: "bolder",
		},
	},
}

// this is the field which should display a textarea instead of a checkbox list
const OtherSpecify = "Other (specify)"

const checkboxLabels: CheckBoxLabels[] = [
	{
		label: "General Risk FACTORS",
		name: "generalRiskFactors",
		backgroundFactors: [
			"Major psychiatric illness",
			"Diagnosed personality disorder",
			"Significant alcohol/drug abuse history",
			"Serious medical condition",
			"Intellectual disability/cognitive deficits",
			"Significant behavioural disorder (<18 years)",
			"Childhood abuse/maladjustment",
			OtherSpecify,
		],
		currentFactors: [
			"Disorientation or disorganisation",
			"Disinhibition/intrusive/impulsive behaviour",
			"Current intoxication/withdrawal",
			"Significant physical pain",
			"Emotional distress/agitation",
			OtherSpecify,
		],
	},
	{
		label: "SUICIDE",
		name: "suicide",
		backgroundFactors: [
			"Previous suicide attempts",
			"History of self harm",
			"Family history of suicide",
			"Separated/widowed/divorced",
			"Isolation/lack of support/supervision",
			OtherSpecify,
		],
		currentFactors: [
			"Recent significant life events",
			"Hopelessness/despair",
			"Expressing high levels of distress",
			"Expressing suicidal ideas",
			"Self-harming behaviour",
			"Current plan/intent",
			"Access to means",
			OtherSpecify,
		],
	},
	{
		label: "VIOLENCE/AGGRESSION",
		name: "violenceAggression",
		backgroundFactors: [
			"Previous incidents of violence",
			"Previous use of weapons",
			"Forensic history",
			"Previous dangerous/violent ideation",
			"History of predatory behaviour",
			OtherSpecify,
		],
		currentFactors: [
			"Recent/current violence",
			"Command hallucinations",
			"Violence restraining order",
			"Paranoid ideation about others",
			"Expressing intent to harm others",
			"Anger, frustration or agitation",
			"Reduced ability to control behaviour",
			"Access to available means",
			"Contact with vulnerable person/s",
			OtherSpecify,
		],
	},
	{
		label: "OTHER RISKS",
		name: "otherRisks",
		caption: "eg. absconding, physical sexual victimisation, financial, falls, accidents, etc",
		backgroundFactors: [
			"History of absconding",
			"History of physical/sexual victimisation",
			"History of financial vulnerability eg. gambling",
			"History of falls or other accidents",
			"History of harm to children or dependents",
			"History of exploitation",
			"History of neglect of a serious medical condition",
			"History of non-adherence",
			OtherSpecify,
		],
		currentFactors: [
			"Desire/intent to leave hospital",
			"Vulnerability to sexual exploitation/abuse",
			"Current delusional beliefs",
			"Physical illness",
			"Parental/carer status or access to children",
			"Self neglect, poor self care, etc",
			"Non-adherence to medications/treatment",
			"Impaired cognition/judgement/self-control",
			"Driving",
			OtherSpecify,
		],
	},
]

const overallAssessment = [
	{
		label: "SUICIDE",
		options: [
			"0 = Nil suicidal ideation",
			"1 = Fleeting suicidal ideation",
			"2 = Ongoing suicidal ideation",
			"3 = Ongoing suicidal ideation, plan and intent",
			"4 = Ongoing ideation, plan, intent and a recent history of attempts",
		],
	},
	{
		label: "DELIBERATE SELF HARM",
		options: [
			"0 = Nil ideas of self harm",
			"1 = Fleeting self harm ideation",
			"2 = Ongoing self harm ideation",
			"3 = Ongoing self harm ideation, plan and intent",
			"4 = Ongoing ideation, plan, intent and a recent history of self harm",
		],
	},
	{
		label: "SELF NEGLECT / ACCIDENTAL SELF HARM",
		options: [
			"0 = Nil evidence of above risk",
			"1 = Fleeting self harm ideation",
			"2 = Ongoing self harm ideation",
			"3 = Ongoing self harm ideation, plan and intent",
			"4 = Ongoing ideation, plan, intent and a recent history of self harm",
		],
	},
	{
		label: "VIOLENCE / HARM TO OTHERS",
		options: [
			"0 = Nil ideas of harm to others",
			"1 = Fleeting ideation to harm others",
			"2 = Ongoing ideation",
			"3 = Ongoing ideation, plan and intent",
			"4 = Ongoing ideation, plan, intent and a recent history of violence",
		],
	},
	{
		label: "VULNERABILITY / HARM FROM OTHERS",
		options: [
			"0 = Nil evidence of above risk",
			"1 = Nil evidence or risk, may be at risk if untreated",
			"2 = Recent evidence of vulnerability / harm from others",
			"3 = Ongoing evidence of vulnerability / harm from others",
			"4 = As per 3 with major impact on client’s physical and mental ",
		],
	},
]

export const RiskAssessment = () => {
	const [css, theme] = useStyletron()
	const { currentUser } = AuthContainer.useContainer()
	const { showToast } = useZenToast()

	const formMethods = useForm()
	const history = useHistory()

	const searchArgs = new URLSearchParams(history.location.search)
	const formID = searchArgs.get("formID")
	const clientID = searchArgs.get("id")

	const [isEditMode, setIsEditMode] = React.useState(!formID)

	const [reviewRecords, setReviewRecords] = React.useState<ReviewRecord[]>([])

	// pre-fill carerStarWorker
	React.useEffect(() => {
		if (formID || !currentUser) return
		formMethods.setValue("worker", [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }])
	}, [currentUser, formMethods, formID])

	// pre-fill client
	const getClient = useParameterizedQuery<ClientDetail>(fetching.query.getClient)
	React.useEffect(() => {
		if (!!formID || !clientID) return
		getClient
			.query(clientID)
			.then((resp) => {
				if (resp.error || !resp.payload) return
				formMethods.setValue("client", [{ ...resp.payload, label: `${resp.payload.firstName} ${resp.payload.lastName}` }])
			})
			.catch()
	}, [clientID]) // eslint-disable-line react-hooks/exhaustive-deps

	// pre-fill form data
	const [fillingForm, setFillingForm] = React.useState(false)
	const formGet = useParameterizedQuery<Form<any>>(fetching.query.getForm)
	React.useEffect(() => {
		if (!formID) return
		setFillingForm(true)
		formGet.query(formID).then((resp) => {
			if (resp.error || !resp.payload) return
			const currentState = resp.payload.content
			setReviewRequired(!!resp.payload.nextReviewDate)
			setFormData(currentState)
			if (resp.payload.reviewRecords) setReviewRecords(resp.payload.reviewRecords)
		})
	}, [formID]) // eslint-disable-line react-hooks/exhaustive-deps

	const setFormData = (formData: any, clearUp?: boolean) => {
		if (clearUp) {
			Object.entries(formData).forEach((data) => {
				if (data[0] === "date") {
					return
				}
				formMethods.setValue(data[0], "")
			})
			return
		}
		Object.entries(formData).forEach((data) => {
			if (data[0] === "date") {
				formMethods.setValue("date", moment(data[1] as string).toDate())
				return
			}
			formMethods.setValue(data[0], data[1])
		})

		setFillingForm(false)
	}

	const [cachedFormData, setCachedFormData] = React.useState<any>()

	const formEnableReview = useMutation(fetching.mutation.formEnableReview)
	const formDisableReview = useMutation(fetching.mutation.formDisableReview)
	const [reviewRequired, setReviewRequired] = React.useState<boolean>(false)
	const toggleFormReview = (value: boolean) => {
		// directly change value if form does not exist yet
		if (!formID) {
			setReviewRequired(value)
			return
		}

		// enable/disable review feature if form is exist
		if (value) {
			formEnableReview.mutate(formID).then((resp) => {
				if (resp.error || !resp.payload) return
				setReviewRequired(value)
			})
			return
		}
		formDisableReview.mutate(formID).then((resp) => {
			if (resp.error || !resp.payload) return
			setReviewRequired(value)
		})
	}

	const formCreate = useMutation(fetching.mutation.formCreate)
	const formUpdate = useMutation(fetching.mutation.formUpdate)
	const formReview = useMutation(fetching.mutation.formReview)
	const isLoading =
		fillingForm || formGet.loading || formEnableReview.loading || formDisableReview.loading || formCreate.loading || formReview.loading || formUpdate.loading
	const onSubmit = async (formData: any) => {
		if (isLoading) return
		if (formID) {
			const resp = await formUpdate.mutate({
				id: formID,
				content: formData,
				reviewRequired,
			})

			if (resp.error || !resp.payload) return
			setIsEditMode(false)
			showToast("Risk Assessment document updated successfully.", "positive")
			return
		}

		const resp = await formCreate.mutate({
			input: {
				clientID: formData.client[0].id,
				content: formData,
				date: new Date(),
				type: FormType.RiskAssessment,
				name: `${FormTypeName(FormType.RiskAssessment)} Form | ${formData.client[0].firstName} ${formData.client[0].lastName} | ${new Date().toLocaleDateString(
					undefined,
					{
						day: "numeric",
						month: "numeric",
						year: "numeric",
					},
				)}`,
				reviewRequired,
			},
		})

		if (resp.error || !resp.payload) return
		history.push(`/portal/clients/${formData.client[0].id}#forms`)
		showToast("Risk Assessment document created successfully.", "positive")
	}
	const review = async () => {
		if (!formID) return
		const resp = await formReview.mutate(formID)
		if (resp.error || !resp.payload) return
		if (currentUser) setReviewRecords((rr) => rr.concat({ date: moment().toString(), worker: currentUser }))
	}

	const continueOrSaveButton = () => {
		if (!formID)
			return (
				<ZenButton width="100px" type="submit" isLoading={isLoading}>
					Submit
				</ZenButton>
			)

		if (!isEditMode) return null

		return (
			<ZenButton width="100px" type="submit" isLoading={isLoading}>
				Save
			</ZenButton>
		)
	}
	const outer = css({
		display: "flex",
		maxHeight: "100%",
		overflowY: "auto",
		paddingRight: "5px",
		"@media only screen and (max-width: 1400px)": {
			flexDirection: "column-reverse",
		},
	})
	const container = css({
		height: "100%",
	})
	const group = css({
		display: "flex",
		width: "100%",
		"@media only screen and (max-width: 900px)": {
			flexDirection: "column",
			marginBottom: "10px",
		},
	})
	const title = css({
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	})
	const formContainer = css({
		display: "flex",
		width: "100%",
		height: "100%",
		flexDirection: "column",
		opacity: isLoading ? 0.5 : 1,
		position: isLoading ? "relative" : "unset",
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})
	const scrollingDiv = css({
		width: "100%",
		height: "100%",
		maxHeight: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		paddingRight: "8px",
		[`@media print`]: {
			overflow: "unset",
			paddingRight: 0,
		},
	})
	const subContainer = css({
		display: "flex",
		width: "100%",
		flexDirection: "column",
		border: theme.colors.borderOpaque + " solid 2px",
		borderRadius: "3px",
		height: "fit-content",
	})
	const hiddenWhenPrint = css({
		[`@media print`]: {
			display: "none",
		},
	})

	const reviewPanel = css({
		maxWidth: "450px",
		marginLeft: "15px",
		height: "100%",
		"@media only screen and (max-width: 1400px)": {
			marginLeft: 0,
			marginBottom: "10px",
			maxWidth: "100%",
		},
		[`@media print`]: {
			display: "none",
		},
	})

	const reviewPanelCard = css({
		maxHeight: "100%",
		overflowY: "auto",
		overflowX: "auto",
	})

	const buttonGroup = css({
		display: "flex",
		justifyContent: "space-between",
		marginTop: "10px",
		[`@media print`]: {
			display: "none",
		},
	})
	const printBreak = css({
		[`@media print`]: {
			pageBreakAfter: "always",
		},
	})

	return (
		<FormProvider {...formMethods}>
			<div className={outer} id="outerContainer">
				<ZenCard className={container}>
					<form autoComplete="off" className={formContainer} onSubmit={formMethods.handleSubmit(onSubmit)}>
						{isLoading && (
							<div className={loadingIcon}>
								<StyledSpinnerNext />
							</div>
						)}
						{formEnableReview.error && <ErrorNotification messageOrPayload={formEnableReview.payload} />}
						{formDisableReview.error && <ErrorNotification messageOrPayload={formDisableReview.payload} />}
						{formCreate.error && <ErrorNotification messageOrPayload={formCreate.payload} />}
						{formReview.error && <ErrorNotification messageOrPayload={formReview.payload} />}

						<div className={title}>
							<LabelLarge>Risk Assessment Form</LabelLarge>
							{!!formID && !isEditMode && (
								<div className={hiddenWhenPrint}>
									<ZenButton
										onClick={() => {
											// save current status
											setCachedFormData(formMethods.getValues())
											setIsEditMode(true)
										}}
										height="38px"
										width="100px"
									>
										Edit
									</ZenButton>
								</div>
							)}
						</div>
						<div className={group}>
							<ZenUserSelect
								label="Worker"
								formRef={formMethods.control}
								formName="worker"
								clearable={false}
								disabled={!!formID && !isEditMode}
								width="100%"
								inputError={formMethods.errors.worker}
								formRules={{
									validate: {
										required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
									},
								}}
							/>
							<Spacer style={{ width: "40px" }} />
							<ZenClientSelect
								label="Client"
								formRef={formMethods.control}
								formName="client"
								disabled={!!formID && !isEditMode}
								clearable={false}
								width="100%"
								inputError={formMethods.errors.client}
								formRules={{
									validate: {
										required: (value: Value) => (!!value && value.length > 0) || "Client is required",
									},
								}}
							/>
							<Spacer style={{ width: "40px" }} />
							<Block width="100%">
								<ZenDatePicker
									label="Date"
									formRef={formMethods.control}
									disabled={!!formID && !isEditMode}
									formName="date"
									paddingTop="11px"
									formRules={{ required: "Date is required" }}
								/>
							</Block>
						</div>
						<LabelMedium color={ZenTheme.colors.black}>
							To be completed by staff to assess client risk. Staff must complete a Safety Plan to address any identified risks from this assessment.
						</LabelMedium>
						<LabelMedium marginBottom="8px">Checkbox options: (Y = Yes, N = No, UK = Unknown)</LabelMedium>
						<div className={scrollingDiv}>
							{checkboxLabels.map((cl, i) => (
								<CheckboxQuestion key={i} {...cl} disabled={!!formID && !isEditMode} />
							))}
							<div className={subContainer}>
								<HeaderRow label="OVERVIEW / IMPRESSION:" checkOptions={[checkOption.Yes, checkOption.No]} gridTemplateColumns="80% 10% 10%" />
								<Row
									label={"Is this person’s level of risk highly changeable?"}
									category={`overviewImpression`}
									checkOptions={[checkOption.Yes, checkOption.No]}
									gridTemplateColumns="80% 10% 10%"
									disabled={!!formID && !isEditMode}
								/>
								<Row
									label={"Are there factors that contribute to uncertainty regarding the level of risk?"}
									category={`overviewImpression`}
									checkOptions={[checkOption.Yes, checkOption.No]}
									gridTemplateColumns="80% 10% 10%"
									disabled={!!formID && !isEditMode}
								/>
							</div>
							<Divider style={{ backgroundColor: "transparent" }} />

							<div className={subContainer}>
								<LabelMedium padding="8px" backgroundColor={theme.colors.borderOpaque} overrides={boldText}>
									CLIENT PROTECTIVE FACTORS:
								</LabelMedium>
								<Comment name="clientProtectiveFactors" disabled={!!formID && !isEditMode} />
							</div>

							<Divider style={{ backgroundColor: "transparent" }} />
							<div className={printBreak} />
							<OverallAssessmentOfRisk disabled={!!formID && !isEditMode} />
						</div>
						<div className={hiddenWhenPrint}>
							<LabelLarge
								backgroundColor={theme.colors.borderOpaque}
								color="red"
								marginTop="5px"
								padding="8px"
								overrides={{
									Block: {
										style: {
											fontWeight: "bolder",
											borderTopWidth: "1px",
											borderBottomWidth: "1px",
											borderLeftWidth: "1px",
											borderRightWidth: "1px",
											borderTopStyle: "solid",
											borderBottomStyle: "solid",
											borderLeftStyle: "solid",
											borderRightStyle: "solid",
											borderTopColor: theme.colors.borderOpaque,
											borderBottomColor: theme.colors.borderOpaque,
											borderLeftColor: theme.colors.borderOpaque,
											borderRightColor: theme.colors.borderOpaque,
										},
									},
								}}
							>
								A SAFETY PLAN MUST BE COMPLETED WHEN A SCORE OF 1 OR HIGHER IS SCORED IN ANY DOMAIN.
							</LabelLarge>
						</div>
						<ErrorFieldTracker errorIDs={Object.keys(formMethods.errors)} />
						<div className={buttonGroup}>
							<ZenButton
								type="button"
								onClick={() => {
									if (isEditMode && formID) {
										// set back to cached data
										if (cachedFormData) {
											// clear up data
											setFormData(formMethods.getValues(), true)
											setFormData(cachedFormData)
										}
										setIsEditMode(false)
										return
									}
									history.goBack()
								}}
								disabled={isLoading}
								altKind="secondary"
								width="100px"
							>
								{!!formID && !isEditMode ? "Exit" : "Cancel"}
							</ZenButton>
							{continueOrSaveButton()}
						</div>
					</form>
					<Prompt
						when={formMethods.formState.isDirty && !formMethods.formState.isSubmitting}
						message={"You have unsaved changes, are you sure you want to leave?"}
					/>
				</ZenCard>
				<div className={reviewPanel}>
					<ZenCard className={reviewPanelCard}>
						<Block width="100%" display="flex" justifyContent="space-between" alignItems="center">
							<LabelMedium>Review Record:</LabelMedium>
							<Checkbox
								checkmarkType="toggle_round"
								checked={reviewRequired}
								onChange={(e) => toggleFormReview(e.currentTarget.checked)}
								overrides={{
									Toggle: {
										style: ({ $checked }) => ({
											backgroundColor: $checked ? ZenTheme.colors.primaryGreen : "white",
										}),
									},
								}}
							>
								Turn on reminder
							</Checkbox>
						</Block>
						{reviewRequired && (
							<LabelSmall color={ZenTheme.colors.primaryGrey}>A notification will be sent to assigned workers to review the form in three months</LabelSmall>
						)}
						<Divider style={{ backgroundColor: "transparent" }} />
						{reviewRecords.map((rr, i) => (
							<div className={group} key={i}>
								<DisplayLabel marginTop={0} label={i > 0 ? "" : "Date"}>
									{moment(rr.date).format("DD/MM/YYYY")}
								</DisplayLabel>
								<Spacer style={{ width: "40px" }} />
								<DisplayLabel marginTop={0} label={i > 0 ? "" : "Completed with"}>
									{<DisplaySelectCard option={{ ...rr.worker, label: `${rr.worker.firstName} ${rr.worker.lastName}` }} />}
								</DisplayLabel>
							</div>
						))}
						<ZenButton type="button" onClick={review} disabled={isLoading} width="150px">
							Confirm Review
						</ZenButton>
					</ZenCard>
				</div>
			</div>
		</FormProvider>
	)
}

interface CheckboxQuestionProps extends CheckBoxLabels {
	disabled?: boolean
}
const CheckboxQuestion = (props: CheckboxQuestionProps) => {
	const [css, theme] = useStyletron()
	const checkOptions = Object.values(checkOption)
	const formContainer = css({
		display: "flex",
		width: "100%",
		height: "fit-content",
	})
	const group = css({
		display: "flex",
		width: "100%",
		height: "100%",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
		},
	})
	const subContainer = css({
		display: "flex",
		width: "100%",
		flexDirection: "column",
		border: theme.colors.borderOpaque + " solid 2px",
		height: "fit-content",
	})
	const blockOverrides: BlockOverrides = {
		Block: {
			style: {
				fontWeight: "bolder",
				borderTopWidth: "2px",
				borderTopStyle: "solid",
				borderTopColor: theme.colors.borderOpaque,
				borderRightWidth: "2px",
				borderRightStyle: "solid",
				borderRightColor: theme.colors.borderOpaque,
				borderLeftWidth: "2px",
				borderLeftStyle: "solid",
				borderLeftColor: theme.colors.borderOpaque,
			},
		},
	}
	const printBreak = css({
		[`@media print`]: {
			pageBreakAfter: "always",
		},
	})
	return (
		<>
			<LabelLarge backgroundColor={theme.colors.borderOpaque} padding="8px" overrides={blockOverrides}>
				{props.label}
			</LabelLarge>
			<div className={formContainer}>
				<div className={group}>
					<div className={subContainer}>
						<HeaderRow label="Background Factors" checkOptions={checkOptions} />
						{props.backgroundFactors.map((bf, i) => (
							<Row key={i} label={bf} disabled={props.disabled} category={`${props.name}BackgroundFactor`} checkOptions={checkOptions} />
						))}
					</div>
					<div className={subContainer}>
						<HeaderRow label="Current Factors" checkOptions={checkOptions} />
						{props.currentFactors.map((cf, i) => (
							<Row key={i} label={cf} disabled={props.disabled} category={`${props.name}CurrentFactor`} checkOptions={checkOptions} />
						))}
					</div>
				</div>
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			<Comment name={`${props.name}Comment`} label="Comments:" border={theme.colors.borderOpaque + " solid 2px"} disabled={props.disabled} />
			<div className={printBreak} />
		</>
	)
}

const HeaderRow = (props: { label: string; checkOptions: string[]; gridTemplateColumns?: string }) => {
	const { label, checkOptions, gridTemplateColumns } = props
	const [css, theme] = useStyletron()
	const row = css({
		display: "grid",
		gridTemplateColumns: gridTemplateColumns || "70% 10% 10% 10%",
		padding: "8px",
		backgroundColor: theme.colors.borderOpaque,
		color: "white",
	})
	const cell = css({
		maxWidth: "80px",
		textAlign: "center",
		justifySelf: "center",
	})
	return (
		<div className={row}>
			<LabelMedium overrides={boldText}>{label}</LabelMedium>
			{checkOptions.map((co, i) => (
				<div className={cell} key={i}>
					<LabelMedium overrides={boldText}>{co}</LabelMedium>
				</div>
			))}
		</div>
	)
}

interface RowProps {
	category: string
	label: string
	checkOptions: string[]
	gridTemplateColumns?: string
	disabled?: boolean
}
const Row = (props: RowProps) => {
	const { label, category, checkOptions, gridTemplateColumns, disabled } = props
	const { watch, setValue } = useFormContext()

	// Only watch value, when the field is not a textarea
	const value = label !== OtherSpecify ? watch(`${category}${label}`) : ""

	const [css] = useStyletron()
	const row = css({
		display: "grid",
		gridTemplateColumns: gridTemplateColumns || "70% 10% 10% 10%",
		padding: "8px",
	})
	const cell = css({
		maxWidth: "80px",
		textAlign: "center",
		justifySelf: "center",
	})
	const checkStyle = css({
		textAlign: "center",
		color: ZenTheme.colors.primaryGreen,
	})

	const displayQuestion = () => {
		if (label === OtherSpecify)
			return (
				<Block paddingLeft="8px">
					<ResizableTextArea label={label} name={`${category}${label}`} disabled={disabled} />
				</Block>
			)
		return (
			<div className={row}>
				<LabelSmall>{label}</LabelSmall>
				{checkOptions.map((co, i) => {
					const checked = value === co
					if (disabled) {
						if (checked) {
							return (
								<div className={checkStyle} key={`question-${label}-checkbox-${i}`}>
									<FontAwesomeIcon icon={["fas", "check"]} />
								</div>
							)
						}
						return <span key={`question-${label}-checkbox-${i}`} />
					}
					return (
						<div className={cell} key={`question-${label}-checkbox-${i}`}>
							<span className="customCheck">
								<input
									type="checkbox"
									disabled={disabled}
									checked={checked}
									onChange={(e) => {
										if (e.currentTarget.checked) {
											setValue(`${category}${label}`, co)
											return
										}
										setValue(`${category}${label}`, "")
									}}
								/>
							</span>
						</div>
					)
				})}
			</div>
		)
	}
	return (
		<>
			<Divider style={{ marginTop: 0 }} />
			{displayQuestion()}
		</>
	)
}

interface CommentTextareaProps {
	name: string
	label?: string
	disabled?: boolean
	border?: string
	initialHeight?: number
}
const Comment = (props: CommentTextareaProps) => {
	const { name, label, disabled, border, initialHeight } = props
	const [css] = useStyletron()
	const container = css({
		border: border || "unset",
		borderRadius: "3px",
		padding: "5px",
		marginBottom: "8px",
	})
	return (
		<div className={container}>
			<ResizableTextArea label={label} name={name} disabled={disabled} initialHeight={initialHeight} />
		</div>
	)
}

interface ResizableTextAreaProps {
	label?: string
	name: string
	disabled?: boolean
	initialHeight?: number
}
const ResizableTextArea = (props: ResizableTextAreaProps) => {
	const { label, name, disabled, initialHeight } = props
	const [css] = useStyletron()
	const { control, watch } = useFormContext()
	const [, setValue] = React.useState<string>("")
	const textInputField = document.getElementById(name)
	const [scrollHeight, setScrollHeight] = React.useState<number>(initialHeight || 34)

	const actionOnChange = React.useCallback(
		(v: string) => {
			setValue(v)
			if (textInputField) {
				textInputField.style.height = "auto"
				const textInputScrollHeight = textInputField.scrollHeight
				if (textInputScrollHeight !== scrollHeight) setScrollHeight(textInputScrollHeight)
			}
		},
		[scrollHeight, textInputField],
	)

	// only watch value when the form is read only
	const textMessage = disabled ? watch(name) : undefined
	React.useEffect(() => {
		if (disabled && textInputField && textMessage) {
			textInputField.style.height = "auto"
			textInputField.style.height = scrollHeight + "px"
			actionOnChange(textMessage)
		}
	}, [disabled, textInputField, textMessage, actionOnChange, scrollHeight])

	const autoResizeTextArea: string = css({
		overflowY: "auto",
		width: "100%",
		height: scrollHeight + "px",
		maxHeight: "200px",
		outline: "none",
		border: "none",
		borderRadius: "10px",
		fontSize: "15px",
		resize: "none",
		fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
		backgroundColor: "white",
		color: "black",
	})

	if (textInputField) {
		textInputField.style.height = "auto"
		textInputField.style.height = scrollHeight + "px"
	}

	return (
		<FormControl
			label={label ? <LabelMedium>{label}</LabelMedium> : null}
			overrides={{
				Label: {
					style: {
						marginTop: 0,
						marginBottom: 0,
					},
				},
				ControlContainer: {
					style: {
						marginBottom: 0,
					},
				},
			}}
		>
			<Controller
				name={name}
				disabled={disabled}
				control={control}
				defaultValue=""
				placeholder=" "
				render={({ value, name, onChange }) => (
					<textarea
						name={name}
						id={name}
						className={autoResizeTextArea}
						autoComplete="on"
						value={value}
						disabled={disabled}
						onChange={(e) => {
							onChange(e)
							actionOnChange(e.currentTarget.value)
						}}
					/>
				)}
			/>
		</FormControl>
	)
}

const OverallAssessmentOfRisk = (props: { disabled?: boolean }) => {
	const [css, theme] = useStyletron()
	const container = css({
		display: "flex",
		width: "100%",
		flexDirection: "column",
		border: theme.colors.borderOpaque + " solid 2px",
		borderRadius: "3px",
		height: "fit-content",
	})
	const row = css({
		display: "grid",
		gridTemplateColumns: "45% 10% 45%",
		padding: "8px",
		backgroundColor: theme.colors.borderOpaque,
		color: "white",
	})
	const cell = css({
		maxWidth: "80px",
		textAlign: "center",
		justifySelf: "center",
	})

	return (
		<div className={container}>
			<div className={row}>
				<LabelMedium>OVERALL ASSESSMENT OF RISK:</LabelMedium>
				<div className={cell}>
					<LabelMedium>Score</LabelMedium>
				</div>
				<div className={cell}>
					<LabelMedium>Comments</LabelMedium>
				</div>
			</div>
			{overallAssessment.map((oa, i) => (
				<OverallAssessmentOfRiskContent overallAssessment={oa} key={i} disabled={props.disabled} />
			))}
		</div>
	)
}

interface OverallAssessmentOfRiskContentProps {
	disabled?: boolean
	overallAssessment: {
		label: string
		options: string[]
	}
}
const OverallAssessmentOfRiskContent = (props: OverallAssessmentOfRiskContentProps) => {
	const { overallAssessment, disabled } = props
	const [css, theme] = useStyletron()
	const { watch, setValue } = useFormContext()
	const value = watch(`overallAssessment${overallAssessment.label}Score`)
	const row = css({
		height: "fit-content",
		display: "grid",
		gridTemplateColumns: "45% 10% 45%",
	})
	const centerCell = css({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderLeft: theme.colors.borderOpaque + " solid 2px",
		borderRight: theme.colors.borderOpaque + " solid 2px",
	})

	const clickableDiv = css({
		width: "100%",
		cursor: "pointer",
		padding: "2px",
		":hover": {
			backgroundColor: theme.colors.borderOpaque + "80",
		},
	})

	const descriptionBox = css({
		padding: "8px",
	})
	return (
		<>
			<Divider style={{ marginTop: 0, backgroundColor: theme.colors.borderOpaque }} />
			<div className={row}>
				<div className={descriptionBox}>
					<LabelSmall overrides={boldText}>{overallAssessment.label}</LabelSmall>
					{overallAssessment.options.map((o, i) => (
						<div
							key={i}
							className={clickableDiv}
							style={{ backgroundColor: value === i ? theme.colors.borderOpaque : undefined }}
							onClick={() => {
								if (disabled) return
								setValue(`overallAssessment${overallAssessment.label}Score`, i)
							}}
						>
							<LabelSmall>{o}</LabelSmall>
						</div>
					))}
				</div>
				<div className={centerCell}>
					<LabelLarge overrides={boldText}>{value}</LabelLarge>
				</div>
				<Comment name={`overallAssessment${overallAssessment.label}`} initialHeight={120} disabled={disabled} />
			</div>
		</>
	)
}
