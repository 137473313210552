import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Button, KIND, SIZE } from "baseui/button"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { EmergencyContactInput, WorkerFormData } from "../../../pages/workers/workerCreate"
import { ZenTheme } from "../../../themeOverrides"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { ZenCard } from "../../common"
import { DisplayLabel } from "../../sessions/newClientAppointment/singleSessionConfirmation"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenConfirmModal } from "../../zenComponents/zenConfirmModal"
import { ZenInput } from "../../zenComponents/zenInput"
import { routes } from "routes"

export const WorkerEmergencyContact = (props: WorkerFormData) => {
	const { data, setData } = props
	const [css] = useStyletron()
	const history = useHistory()
	const [emergencyContacts, setEmergencyContacts] = React.useState<EmergencyContactInput[]>(data.emergencyContact || [])
	const RemoveCard = (index: number) => {
		const target = [...emergencyContacts]
		target.splice(index, 1)
		setEmergencyContacts(target)
	}
	const container = css({
		width: "60%",
		height: "100%",
		paddong: "20px",
		display: "flex",
		flexDirection: "column",
		"@media only screen and (max-width: 900px)": {
			width: "100%",
		},
	})
	const card = css({
		height: "unset",
		marginTop: "15px",
		marginBottom: "15px",
		backgroundColor: ZenTheme.colors.divider,
	})
	const scrollDiv = css({
		flex: 1,
		maxHeight: "50%",
		padding: "10px",
		overflowY: "auto",
		overflowX: "hidden",
		borderRadius: "3px",
		marginTop: "10px",
	})
	const onBack = () => {
		storeData()
		history.goBack()
	}
	const onSubmit = () => {
		storeData()
		history.push(routes.workers.create.confirmation)
	}
	const storeData = () => {
		setData({
			...data,
			emergencyContact: emergencyContacts,
		})
	}
	return (
		<div className={container}>
			<LabelMedium>Emergency Contact</LabelMedium>
			<ZenCard className={card}>
				<EmergencyContactForm setEmergencyContacts={setEmergencyContacts} />
			</ZenCard>
			<LabelMedium marginTop="10px">Emergency Contacts</LabelMedium>
			<div className={scrollDiv}>
				{emergencyContacts.length > 0 ? (
					emergencyContacts.map((ec, i) => <EmergencyCard key={i} index={i} {...ec} clear={RemoveCard} setEmergencyContacts={setEmergencyContacts} />)
				) : (
					<LabelSmall>N/A</LabelSmall>
				)}
			</div>
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={onBack} saveLabel="Next" saveFn={onSubmit} />
		</div>
	)
}

type setEmergencyContacts = React.Dispatch<React.SetStateAction<EmergencyContactInput[]>>

interface EmergencyContactFormProps {
	setEmergencyContacts: setEmergencyContacts
	isEditMode?: boolean
	cancelFn?: () => void
	prefill?: EmergencyContactInput & { index: number }
}

export const EmergencyContactForm = (props: EmergencyContactFormProps) => {
	const { setEmergencyContacts, isEditMode, cancelFn, prefill } = props
	const [css, theme] = useStyletron()
	const { control, handleSubmit, errors, setValue } = useForm()
	const [error, setError] = React.useState<boolean>(false)

	React.useEffect(() => {
		if (prefill) {
			setValue("firstName", prefill.firstName)
			setValue("lastName", prefill.lastName)
			setValue("relationship", prefill.relationship)
			setValue("mobileNumber", prefill.mobileNumber)
			setValue("telephoneNumber", prefill.telephoneNumber)
		}
	}, [prefill, setValue])

	const onSubmit = (data: any) => {
		if (!data.mobileNumber && !data.telephoneNumber) {
			setError(true)
			return
		}

		if (isEditMode) {
			if (prefill) {
				setEmergencyContacts((contacts) => {
					const copyContacts = [...contacts]
					copyContacts[prefill.index] = {
						firstName: data.firstName,
						lastName: data.lastName,
						relationship: data.relationship,
						telephoneNumber: data.telephoneNumber,
						mobileNumber: data.mobileNumber,
					}
					return copyContacts
				})
				if (cancelFn) {
					cancelFn()
				}
			}
			return
		}

		setEmergencyContacts((ec) =>
			ec.concat({
				firstName: data.firstName,
				lastName: data.lastName,
				relationship: data.relationship,
				telephoneNumber: data.telephoneNumber,
				mobileNumber: data.mobileNumber,
			}),
		)

		setValue("firstName", "")
		setValue("lastName", "")
		setValue("relationship", "")
		setValue("mobileNumber", "")
		setValue("telephoneNumber", "")
	}

	const container = css({
		height: "fit-content",
		width: "100%",
		borderRadius: "5px",
		padding: "15px",
		marginTop: "15px",
	})
	const group = css({
		display: "flex",
	})
	const buttonContainer = css({
		width: "100%",
		height: "fit-content",
		marginTop: "10px",
		display: "flex",
		justifyContent: "flex-end",
	})
	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
			<div className={group}>
				<ZenInput label="First name *" placeholder="Enter first name" nameRef="firstName" inputError={errors.firstName} formRef={control} required />
				<ZenInput
					label="Last name *"
					nameRef="lastName"
					placeholder="Enter last name"
					inputError={errors.lastName}
					formRef={control}
					required
					marginLeft="10px"
				/>
			</div>
			<ZenInput label="Relationship *" placeholder="Enter relationship" nameRef="relationship" inputError={errors.relationship} formRef={control} required />

			<div className={group}>
				<ZenInput
					// Use generic input to allow for more flexible usage, such as a note about the number.
					// Not required because details could be unknown at time of sign up
					placeholder="Enter telephone"
					label="Telephone Number"
					nameRef="telephoneNumber"
					formRef={control}
					inputError={errors.telephoneNumber}
					marginLeft="10px"
				/>
				<ZenInput
					// Use generic input to allow for more flexible usage, such as a note about the number.
					// Not required because details could be unknown at time of sign up
					label="Mobile"
					placeholder="Mobile Number"
					nameRef="mobileNumber"
					inputError={errors.mobileNumber}
					formRef={control}
				/>
			</div>
			<LabelSmall marginBottom="15px" color={error ? theme.colors.warning500 : theme.colors.black}>
				Contact (at least enter one contact number)
			</LabelSmall>
			{isEditMode ? (
				<CancelAndSaveButtons cancelFn={cancelFn} />
			) : (
				<div className={buttonContainer}>
					<ZenButton width="25%" type="submit">
						Add
					</ZenButton>
				</div>
			)}
		</form>
	)
}

interface EmergencyCardProps extends EmergencyContactInput {
	clear: (index: number) => void
	index: number
	setEmergencyContacts: React.Dispatch<React.SetStateAction<EmergencyContactInput[]>>
}
export const EmergencyCard = (props: EmergencyCardProps) => {
	const { clear, index, setEmergencyContacts, ...emergencyContact } = props
	const { firstName, lastName, relationship, telephoneNumber, mobileNumber } = emergencyContact
	const [isOpen, setIsOpen] = React.useState(false)
	const [isEditMode, setIsEditMode] = React.useState(false)

	const [css] = useStyletron()
	const container = css({
		position: "relative",
		width: "100%",
		padding: "10px",
		marginBottom: "8px",
		borderRadius: "3px",
		backgroundColor: ZenTheme.colors.divider,
		border: "1px solid " + ZenTheme.colors.primaryGrey,
	})
	const buttonContainer = css({
		position: "absolute",
		top: "8px",
		right: "8px",
		display: "flex",
		alignItems: "center",
	})
	const group = css({
		display: "flex",
	})

	if (isEditMode) {
		return (
			<EmergencyContactForm
				setEmergencyContacts={setEmergencyContacts}
				isEditMode={isEditMode}
				cancelFn={() => setIsEditMode(false)}
				prefill={{
					index,
					...emergencyContact,
				}}
			/>
		)
	}

	return (
		<>
			<div className={container}>
				<div className={buttonContainer}>
					<Button onClick={() => setIsEditMode(true)} kind={KIND.minimal} size={SIZE.compact}>
						<FontAwesomeIcon icon={["fal", "pencil"]} />
					</Button>
					<Button onClick={() => setIsOpen(true)} kind={KIND.minimal} size={SIZE.compact}>
						<FontAwesomeIcon icon={["fas", "times-circle"]} />
					</Button>
				</div>
				<div className={group}>
					<DisplayLabel label="Name">{`${firstName} ${lastName}`}</DisplayLabel>
					<DisplayLabel label="Relationship">{relationship}</DisplayLabel>
				</div>
				<div className={group}>
					<DisplayLabel label="Mobile">{mobileNumber}</DisplayLabel>
					<DisplayLabel label="Telephone">{telephoneNumber}</DisplayLabel>
				</div>
			</div>
			<ZenConfirmModal
				title="Confirm emergency contact removal"
				message={`Are you sure you want to remove ${firstName} ${lastName} as an emergency contact?`}
				action="Remove contact"
				open={isOpen}
				setOpen={setIsOpen}
				loading={false}
				confirm={() => {
					clear(index)
					setIsOpen(false)
				}}
			/>
		</>
	)
}
