import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { FieldError, useFormContext } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"
import { useHistory } from "react-router-dom"

import { ZenTheme } from "../../../../themeOverrides"
import { ClientRelationshipType } from "../../../../types/enums"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ZenButton } from "../../../zenComponents/zenButtons"
import { ZenInput } from "../../../zenComponents/zenInput"
import { ZenSelect } from "../../../zenComponents/zenSelectBox"
import { IntakeScrollDiv } from "../intakeComponent"
import { IntakeAdultProps, intakeURLParser } from "./intakeAdultBaseForm"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"

interface EmergencyContactIntake extends IntakeAdultProps {
	placeholderList: number[]
	setPlaceholderList: React.Dispatch<React.SetStateAction<number[]>>
	additionContactCount: number
	setAdditionContactCount: React.Dispatch<React.SetStateAction<number>>
	contactInputError: boolean[]
	setContactInputError: React.Dispatch<React.SetStateAction<boolean[]>>
}

export const IntakeAdultEmergencyContactDetails = (props: EmergencyContactIntake) => {
	const {
		clientID,
		formID,
		withNDIS,
		placeholderList,
		setPlaceholderList,
		additionContactCount,
		setAdditionContactCount,
		contactInputError,
		setContactInputError,
		isReadOnly,
		validationTriggered,
	} = props
	const [css] = useStyletron()
	const history = useHistory()

	const { trigger, control, errors, getValues } = useFormContext()

	const addNewForm = () => {
		const nextIndex = additionContactCount + 1
		setPlaceholderList(placeholderList.concat(nextIndex))
		setContactInputError(contactInputError.concat(false))
		setAdditionContactCount(additionContactCount + 1)
	}
	const clearEmergencyContact = (index: number) => {
		setPlaceholderList(placeholderList.filter((p) => p !== index))
	}

	const validation = React.useCallback(async () => {
		// generate check list
		const checkList: string[] = []
		const contactErrors: boolean[] = []
		let hasError = false // track whether there is any contact error

		placeholderList.forEach((p) => {
			let isError = false // track whether current contact has error

			// check current emergency contact has contact error
			if (getValues()[`emergencyContactMobileNumber${p}`] === "" && getValues()[`emergencyContactTelephoneNumber${p}`] === "") {
				isError = true
				hasError = true
			}

			checkList.push(
				`emergencyContactFirstName${p}`,
				`emergencyContactLastName${p}`,
				`emergencyContactMobileNumber${p}`,
				`emergencyContactRelationshipToClient${p}`,
			)

			contactErrors.push(isError)
		})

		setContactInputError(contactErrors)

		// trigger validation
		const isValid = await trigger(checkList)

		return isValid && !hasError
	}, [getValues, trigger, placeholderList, setContactInputError])

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validationTriggered, validation])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return

		// route to next page
		history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/challengesForClient`, formID, withNDIS))
	}

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
	})
	const body = css({
		marginTop: "10px",
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})

	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<LabelLarge>Emergency contact detail</LabelLarge>
					<IntakeScrollDiv>
						{placeholderList.length > 0 &&
							placeholderList.map((c) => (
								<EmergencyContactForm
									key={c}
									index={c}
									formRef={control}
									inputError={errors}
									clear={c > 0 ? () => clearEmergencyContact(c) : undefined}
									contactError={contactInputError[c]}
									disabled={isReadOnly}
								/>
							))}
						{!isReadOnly && (
							<ZenButton type="button" onClick={addNewForm}>
								Add emergency contact
							</ZenButton>
						)}
					</IntakeScrollDiv>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={history.goBack} saveLabel="Next" saveFn={onSubmit} />}
			</form>
		</div>
	)
}

interface EmergencyContactFormProps {
	index: number
	formRef: any
	inputError: DeepMap<Record<string, any>, FieldError>
	contactError: boolean
	clear?: () => void
	disabled?: boolean
	omitBorder?: boolean
}
export const EmergencyContactForm = (props: EmergencyContactFormProps) => {
	const { index, formRef, inputError, clear, contactError, disabled, omitBorder } = props
	const [css] = useStyletron()
	const container = css({
		border: omitBorder ? 0 : `1px solid ${ZenTheme.colors.primaryGrey}`,
		borderRadius: "8px",
		padding: "10px",
		marginBottom: "15px",
		position: clear ? "relative" : "unset",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	const removeIcon = css({
		position: "absolute",
		top: "8px",
		right: "10px",
	})
	return (
		<div className={container}>
			<LabelMedium>{`Emergency Contact`}</LabelMedium>
			{clear && !disabled && (
				<div className={removeIcon} onClick={() => clear()}>
					<FontAwesomeIcon icon={["fal", "times-circle"]} />
				</div>
			)}
			<div className={group}>
				<ZenInput
					disabled={disabled}
					label="First name"
					nameRef={`emergencyContactFirstName${index}`}
					formRef={formRef}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "First name is required",
						},
					}}
					inputError={inputError[`emergencyContactFirstName${index}`]}
				/>
				<ZenInput
					disabled={disabled}
					marginLeft="10px"
					label="Last name"
					nameRef={`emergencyContactLastName${index}`}
					formRef={formRef}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length > 0) || "Last name is required",
						},
					}}
					inputError={inputError[`emergencyContactLastName${index}`]}
				/>
			</div>
			<div className={group}>
				<LabelMedium color="black" marginTop="10px">
					Contact Number
				</LabelMedium>
				{contactError && (
					<LabelMedium color={ZenTheme.colors.warning600} marginTop="10px" marginLeft="8px">
						- One phone number is required
					</LabelMedium>
				)}
			</div>
			<ZenInput
				// Use generic input to allow for more flexible usage, such as a note about the number.
				// Not required to speed up onboarding
				disabled={disabled}
				label="Telephone Number"
				nameRef={`emergencyContactTelephoneNumber${index}`}
				formRef={formRef}
				inputError={inputError[`emergencyContactTelephoneNumber${index}`]}
			/>
			<ZenInput
				// Use generic input to allow for more flexible usage, such as a note about the number.
				// Not required to speed up onboarding
				disabled={disabled}
				label="Mobile"
				nameRef={`emergencyContactMobileNumber${index}`}
				formRef={formRef}
				inputError={inputError[`emergencyContactMobileNumber${index}`]}
			/>

			<LabelMedium color="black" marginTop="10px">
				Relationship
			</LabelMedium>
			<ZenSelect
				disabled={disabled}
				placeholder="Select or enter a relationship"
				label="Relationship to client"
				formName={`emergencyContactRelationshipToClient${index}`}
				formRef={formRef}
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "Relationship to client is required",
					},
				}}
				inputError={inputError[`emergencyContactRelationshipToClient${index}`]}
				clearable={false}
				options={Object.values(ClientRelationshipType).map((r) => ({ id: r, label: r }))}
				creatable
			/>
			<ZenSelect
				disabled={disabled}
				placeholder="Select or enter a relationship"
				label="Relationship to consumer"
				formName={`emergencyContactRelationshipToConsumer${index}`}
				formRef={formRef}
				options={Object.values(ClientRelationshipType).map((r) => ({ id: r, label: r }))}
				creatable
			/>
		</div>
	)
}
