import * as React from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import { AlertsPage } from "../../components/clients/clientOverview/alerts"
import { BaseForm as AdultBaseForm } from "../../components/clients/Intake/adult/intakeAdultBaseForm"
import { IntakeSelectPage } from "../../components/clients/Intake/intakeSelect"
import { BaseForm as YouthBaseForm } from "../../components/clients/Intake/youth/intakeYouthBaseForm"
import { NotesPage } from "../../components/clients/notes"
import EventCalendar from "../../components/eventCalendar"
import { AuthContainer } from "../../controllers/auth"
import { PortalContainer } from "../../controllers/portal"
import { ClientCreate } from "./clientCreate"
import { ClientEdit } from "./clientEdit"
import { ClientsList } from "./clientsList"
import { ClientView } from "./clientView"
import { routes } from "routes"

export default function ClientRoot() {
	const { currentUser } = AuthContainer.useContainer()

	if (!currentUser) return null

	return (
		<Switch>
			<Route exact path={routes.clients.create} component={ClientCreate} />
			<Route path={routes.clients.client.root} component={ClientSubRoot} />
			<Route path={routes.clients.root} component={ClientsList} />
		</Switch>
	)
}

export const ClientSubRoot = (props: RouteComponentProps<{ id: string }>) => {
	const { fetchClient } = PortalContainer.useContainer()

	React.useEffect(() => {
		fetchClient(props.match.params.id)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Switch>
			<Route path={routes.clients.client.intake.root} component={ClientIntakeRoot} />
			<Route path={routes.clients.client.calendar} render={() => <EventCalendar clientID={props.match.params.id} />} />
			<Route path={routes.clients.client.notes} component={NotesPage} />
			<Route path={routes.clients.client.alerts} component={AlertsPage} />
			<Route path={routes.clients.client.update} component={ClientEdit} />
			<Route path={routes.clients.client.root} component={ClientView} />
		</Switch>
	)
}

export const ClientIntakeRoot = () => {
	return (
		<Switch>
			<Route path={[routes.clients.client.intake.adult.root, routes.clients.client.intake.ndis.root]} component={AdultBaseForm} />
			<Route path={routes.clients.client.intake.youth.root} component={YouthBaseForm} />
			<Route exact path={routes.clients.client.intake.root} component={IntakeSelectPage} />
		</Switch>
	)
}
