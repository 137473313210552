import * as React from "react"
import { Modal, ModalFooter, ModalHeader } from "baseui/modal"
import { ButtonKind, ZenButton } from "../zenComponents/zenButtons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenTheme } from "../../themeOverrides"

interface ZenConfirmModalProps {
	title: string
	action: string
	open: boolean
	onClose?: () => void
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	errKey?: string
	setApprovalErr?: React.Dispatch<React.SetStateAction<boolean>>
	setFieldErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>
	loading: boolean
	message?: React.ReactNode
	confirm: () => void
	kind?: ButtonKind
}

export const ZenConfirmModal = (props: ZenConfirmModalProps) => {
	const { open, setOpen, onClose, loading, message, confirm, action, title, kind, setApprovalErr, setFieldErrors } = props

	return (
		<Modal
			onClose={onClose}
			isOpen={open}
			overrides={{
				Root: {
					style: {
						zIndex: 30,
					},
				},
				Dialog: {
					style: {
						minHeight: "250px",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
					},
				},
				Close: {
					style: {
						display: "none",
					},
				},
			}}
		>
			<FontAwesomeIcon
				style={{ margin: "0" }}
				color={kind === "primary" ? ZenTheme.colors.primary : ZenTheme.colors.red}
				size={"4x"}
				icon={["fal", "exclamation-circle"]}
			/>
			<ModalHeader
				style={{
					margin: "0",
					fontWeight: "bold",
				}}
			>
				{title}
			</ModalHeader>
			{message ? (
				<ModalHeader
					style={{
						margin: "0",
					}}
				>
					{message}
				</ModalHeader>
			) : null}
			<ModalFooter
				style={{
					display: "flex",
					justifyContent: "space-between",
					padding: "0",
					width: "100%",
				}}
			>
				<ZenButton
					altKind={"secondary"}
					onClick={(e) => {
						e.preventDefault()
						setOpen(false)
						setApprovalErr && setApprovalErr(false);
						setFieldErrors && setFieldErrors((prev:any) => ({...prev, ['checked_by']: ''}));
					}}
				>
					Cancel
				</ZenButton>

				<ZenButton
					isLoading={loading}
					altKind={kind || "danger"}
					onClick={(e) => {
						e.preventDefault()
						confirm()
					}}
				>
					{action}
				</ZenButton>
			</ModalFooter>
		</Modal>
	)
}
