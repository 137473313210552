import * as React from "react"
import { useStyletron } from "baseui"

import { CallLogCreateForm } from "../../components/callLogs/callLogCreateForm"
import { ZenCard } from "../../components/common"

export const CallLogCreate = () => {
	const [css] = useStyletron()

	const outer = css({
		display: "flex",
		flexDirection: "row",
		width: "100%",
		height: "100%",
	})
	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: "800px !important",
		height: "100%",
		padding: "11px 11px 14px 14px",
		backgroundColor: "white",
		marginBottom: "3%",
	})
	return (
		<div className={outer}>
			<ZenCard className={container}>
				<CallLogCreateForm />
			</ZenCard>
		</div>
	)
}
