import { useStyletron } from "baseui"
import { Option, Value } from "baseui/select"
import { LabelLarge } from "baseui/typography"
import * as React from "react"
import { useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { routes } from "routes"
import { fetching } from "../../../fetching"
import { capitalize } from "../../../helpers/utils"
import { UserOfficesInput, WorkerFormData } from "../../../pages/workers/workerCreate"
import { daysOfWeek } from "../../../types/enums"
import { BasicName } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { SubSection } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { ErrorFieldTracker } from "../../forms/errorFieldTracker"
import { ZenInput } from "../../zenComponents/zenInput"
import { ZenPlaceSelect, ZenSelect } from "../../zenComponents/zenSelectBox"

export const WorkerContact = (props: WorkerFormData) => {
	const { data, setData } = props
	const [css] = useStyletron()
	const history = useHistory()
	const { setValue, errors, control, handleSubmit, getValues } = useForm()
	const [officeOptions, setOfficeOptions] = React.useState<Option[]>([])
	const officeData = useQuery<BasicName[]>(fetching.query.getOfficeAll())
	React.useEffect(() => {
		if (officeData.loading || !officeData.payload) return
		setOfficeOptions(officeData.payload.map<Option>((o) => ({ label: o.name, id: o.id })))
	}, [officeData.payload, officeData.loading])

	// pre-fill data
	const prefillData = React.useCallback(() => {
		setValue("mobileNumber", data.mobileNumber)
		setValue("vehicleRegistrationNumber", data.vehicleRegistrationNumber)
	}, [data, setValue])
	React.useEffect(() => {
		prefillData()
	}, [prefillData])

	const onBack = () => {
		storeData(getValues())
		history.goBack()
	}
	const onSubmit = (formData: any) => {
		storeData(formData)
		history.push(routes.workers.create.role)
	}
	const storeData = (formData: any) => {
		const offices: UserOfficesInput[] = daysOfWeek.map<UserOfficesInput>((d) => ({
			day: d,
			office:
				formData[`offices${d}`] && formData[`offices${d}`].length > 0
					? { id: formData[`offices${d}`][0].id, name: formData[`offices${d}`][0].label }
					: undefined,
		}))
		setData({
			...data,
			mobileNumber: formData.mobileNumber,
			address: formData.address,
			offices,
			vehicleRegistrationNumber: formData.vehicleRegistrationNumber,
		})
	}
	const container = css({
		width: "60%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		"@media only screen and (max-width: 900px)": {
			width: "100%",
		},
	})
	const formStyle = css({
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	const scrollingDiv = css({
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
		paddingRight: "35px",
	})

	return (
		<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
			<div className={formStyle}>
				<LabelLarge>Contact Details</LabelLarge>
				<div className={scrollingDiv}>
					<SubSection>
						<>
							<ZenInput
								// Use generic input to allow for more flexible usage, such as a note about the number.
								label="Contact Number"
								placeholder="Enter Contact"
								nameRef="mobileNumber"
								inputError={errors.mobileNumber}
								formRef={control}
								required // A worker needs a contact number, or note
							/>

							<ZenPlaceSelect
								label="Address"
								formName="address"
								placeholder="Select address"
								formRef={control}
								inputError={errors.address}
								formRules={{
									validate: {
										required: (value: Value) => (!!value && value.length > 0) || "Address is required",
									},
								}}
								value={data.address && data.address.length > 0 ? [{ id: data.address[0].id, label: data.address[0].label }] : []}
							/>
							<ZenInput
								label="Vehicle Registration Number"
								placeholder="Enter vehicle registration number"
								nameRef="vehicleRegistrationNumber"
								inputError={errors.vehicleRegistrationNumber}
								formRef={control}
							/>
						</>
					</SubSection>

					<LabelLarge marginTop="8px" marginBottom="6px">
						Office Details
					</LabelLarge>
					<SubSection>
						{daysOfWeek.map((day, index) => {
							const usersOffice = data.offices?.find((value) => value.day === day)
							return (
								<ZenSelect
									key={index}
									label={capitalize(day)}
									formName={`offices${day}`}
									inputError={errors[`offices${day}`]}
									formRef={control}
									clearable={true}
									value={usersOffice && usersOffice.office ? [{ label: usersOffice.office.name, id: usersOffice.office.id }] : []}
									options={officeOptions}
								/>
							)
						})}
					</SubSection>
				</div>
			</div>
			{officeData.error && <ErrorNotification messageOrPayload={officeData.payload} />}
			<ErrorFieldTracker errorIDs={Object.keys(errors)} />
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={onBack} saveLabel="Next" />
		</form>
	)
}
