import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { Button, KIND } from "baseui/button"
import { Value } from "baseui/select"
import { StatefulTooltip } from "baseui/tooltip"
import { LabelLarge, LabelSmall, LabelXSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useQuery } from "react-fetching-library"
import { useHistory, useLocation } from "react-router-dom"
import { Divider, SearchAndFilter, Spacer, ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { ListTable, ListTableColumn } from "../../components/listTable"
import { ZenArchiveModal } from "../../components/zenComponents/zenArchiveDialog"
import { ZenPagination } from "../../components/zenComponents/zenPagination"
import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { friendlyDate, truncate } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { FilterBy } from "../../types/enums"
import { Client, ClientWithContact, RolePermission, SearchTextMinLength, SortOrder } from "../../types/types"

enum SortBy {
	Name = "Name",
	Mobile = "Mobile",
	DateOfBirth = "DateOfBirth",
	Address = "Address",
	DateArchived = "DateArchived",
	NDISPlanExpiryDays = "NDISPlanExpiryDays",
}

export const ClientsList = () => {
	const history = useHistory()
	const location = useLocation()
	const { hasPermission } = AuthContainer.useContainer()
	const limit = 20
	const [rows, setRows] = React.useState<ClientWithContact[]>([])
	const [total, setTotal] = React.useState<number>(0)
	const [listView, setListView] = React.useState(true)

	const searchParams = new URLSearchParams(location.search)

	let defFilter = searchParams.get("filter") || FilterBy.Active
	const index: number = Object.keys(FilterBy).indexOf(defFilter)
	if (index === -1) {
		defFilter = FilterBy.Active
	}

	// Page/ Search/ Filter state
	const [filter, setFilter] = React.useState<Value>([{ label: defFilter, id: defFilter }])

	let defaultOffset = parseInt(searchParams.get("offset") || "0")
	if (isNaN(defaultOffset)) defaultOffset = 0

	const [offset, setOffset] = React.useState(defaultOffset)
	const [sortColumn, setSortColumn] = React.useState<string>(searchParams.get("sortColumn") || SortBy.Name)
	const [sortAsc, setSortAsc] = React.useState<boolean>(searchParams.get("sortAsc") === "true")
	const [search, setSearch] = React.useState<string | null>(searchParams.get("search"))

	React.useEffect(() => {
		let changed = false
		if (`${sortAsc}` !== searchParams.get("asc")) {
			searchParams.set("asc", `${sortAsc}`)
			changed = true
		}
		if (sortColumn !== searchParams.get("sortColumn")) {
			searchParams.set("sortColumn", `${sortColumn}`)
			changed = true
		}

		if (`${offset}` !== searchParams.get("offset")) {
			searchParams.set("offset", `${offset}`)
			changed = true
		}
		if (`${search}` !== searchParams.get("search")) {
			if (`${search}` === "null" || search === "") {
				searchParams.delete("search")
			} else {
				searchParams.set("search", `${search}`)
				searchParams.delete("offset")
				setOffset(0)
			}
			changed = true
		}
		if (filter.length > 0 && searchParams.get("filter") !== filter[0].id) {
			searchParams.set("filter", `${filter[0].id}`)
			searchParams.delete("offset")
			setOffset(0)
		}
		if (changed) {
			history.push({ pathname: location.pathname, search: searchParams.toString() })
		}
	}, [filter, sortAsc, offset, sortColumn, search]) // eslint-disable-line react-hooks/exhaustive-deps

	// modal states
	const [archiveModalOpen, setArchiveModalOpen] = React.useState(false)

	// selected row (client)
	const [selectedClient, setSelectedClient] = React.useState<Client>()

	// clients filter query
	const { payload, loading, query, error } = useQuery<{ clients: ClientWithContact[]; total: number }>(
		fetching.query.getClientMany({
			search: {
				search: search ? (search.length >= SearchTextMinLength ? search : undefined) : undefined,
				sortBy: sortColumn,
				sortDir: sortAsc ? SortOrder.Ascending : SortOrder.Descending,
				filterBy: filter[0].id?.toString() || FilterBy.Active,
			},
			limit,
			offset,
		}),
	)

	// archive/unarchive
	const archiveClient = useMutation<Client>(fetching.mutation.clientArchive)
	const unarchiveClient = useMutation<Client>(fetching.mutation.clientUnarchive)

	React.useEffect(() => {
		if (!(archiveClient.payload || unarchiveClient.payload)) return
		query()
		setArchiveModalOpen(false)
	}, [archiveClient.payload, unarchiveClient.payload, query])

	// set rows / total
	React.useEffect(() => {
		if (!payload) return
		setRows(payload.clients)
		setTotal(payload.total)
	}, [payload])

	const viewClient = (id: string) => {
		history.push(`/portal/clients/${id}`)
	}

	const handleSort = (id: string) => {
		if (id === sortColumn) {
			setSortAsc(!sortAsc)
			return
		}
		setSortColumn(id)
		setSortAsc(true)
	}

	const [css] = useStyletron()
	const cardStyle = css({
		borderLeft: "4px solid " + ZenTheme.colors.primaryGreen,
		width: "330px",
		height: "auto",
		display: "flex",
		flexDirection: "row",
		paddingLeft: 0,
		paddingBottom: "20px",
		cursor: "pointer",
		":hover": {
			backgroundColor: "#eef0f9",
		},
	})
	const clientNameCell = css({
		display: "flex",
		alignItems: "center",
	})

	const col1 = css({
		width: "20%",
		paddingTop: "10px",
		marginLeft: "10px",
		marginRight: "10px",
		paddingLeft: 0,
	})
	const col2 = css({
		width: "100%",
		paddingTop: "10px",
	})
	const col3 = css({
		paddingTop: "10px",
	})

	const containerStyle = css({
		height: "100%",
		overflowX: "hidden",
	})
	const top = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "14px",
	})

	// renders card component for card view
	const CardResolver = (row: ClientWithContact) => {
		const { firstName, lastName, currentContact, dateOfBirth, deletedAt, avatarURL } = row

		return (
			<ZenCard
				className={cardStyle}
				onClick={() => viewClient(row.id)}
				style={{
					backgroundColor: deletedAt ? "#eef0f9" : "white",
					borderLeft: deletedAt ? "#eef0f9" : "4px solid " + ZenTheme.colors.primaryGreen,
					cursor: deletedAt ? "" : "pointer",
				}}
			>
				<div className={col1}>
					<Avatar
						overrides={{
							Root: {
								style: {
									minWidth: ZenTheme.sizing.scale1000,
								},
							},
							Initials: { style: { fontSize: "12px" } },
						}}
						size={"37px"}
						name={`${firstName} ${lastName}`}
						src={avatarURL || ""}
					/>
				</div>
				<div className={col2}>
					<LabelLarge>{truncate(`${firstName} ${lastName}`, 15)}</LabelLarge>
					<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />

					<LabelSmall>{currentContact?.mobileNumber || ""}</LabelSmall>
					<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />

					<LabelSmall>{dateOfBirth ? friendlyDate(dateOfBirth) : ""}</LabelSmall>
					<Divider style={{ backgroundColor: "transparent", margin: "2px" }} />

					<StatefulTooltip content={() => <div>{currentContact?.residentialAddress?.fullAddress || ""}</div>} returnFocus autoFocus>
						<LabelSmall>{truncate(currentContact?.residentialAddress?.fullAddress || "", 20)}</LabelSmall>
					</StatefulTooltip>

					<Divider style={{ backgroundColor: "transparent" }} />
				</div>
				<div className={col3}>
					{hasPermission(RolePermission.ClientUpdate) && (
						<Button
							kind="minimal"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								setSelectedClient(row)
								setArchiveModalOpen(true)
							}}
						>
							<FontAwesomeIcon
								color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
								icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
							/>
						</Button>
					)}
				</div>
			</ZenCard>
		)
	}

	const clientListColumns: ListTableColumn[] = [
		{
			id: SortBy.Name,
			header: "Client Name",
			resolver: (row: ClientWithContact) => (
				<div className={clientNameCell}>
					<FontAwesomeIcon
						icon={["fal", row.isOrganisation ? "users" : "user"]}
						style={{
							marginRight: "5px",
						}}
					/>
					<div>{`${row.firstName} ${row.lastName}`}</div>
					{row.deletedAt && <LabelXSmall marginLeft="10px">[ARCHIVED]</LabelXSmall>}
				</div>
			),
			sortable: true,
		},
		{
			id: SortBy.Mobile,
			header: "Contact Number",
			resolver: (row: ClientWithContact) =>
				(row.currentContact && (row.currentContact.mobileNumber ? row.currentContact.mobileNumber : row.currentContact.telephoneNumber)) || "N/A",
			sortable: false,
		},
		{
			id: SortBy.DateOfBirth,
			sortable: false,
			header: "Date of birth",
			resolver: (row: ClientWithContact) => (row.dateOfBirth ? friendlyDate(row.dateOfBirth) : "N/A"),
		},
		{
			id: SortBy.Address,
			header: "Address",
			resolver: (row: ClientWithContact) =>
				row.currentContact?.residentialAddress?.fullAddress ? truncate(row.currentContact.residentialAddress.fullAddress, 20) : "N/A",
		},
		{
			id: SortBy.DateArchived,
			sortable: false,
			header: "Date Archived",
			resolver: (row: ClientWithContact) => (row.deletedAt ? friendlyDate(row.deletedAt) : ""),
		},
		{
			id: "",
			header: "",
			resolver: (row: ClientWithContact) =>
				// only the creator of this client or superadmin are allowed to archive
				hasPermission(RolePermission.ClientUpdate) && (
					<div
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}}
					>
						<Button
							kind={KIND.minimal}
							onClick={() => {
								setSelectedClient(row)
								setArchiveModalOpen(true)
							}}
						>
							<FontAwesomeIcon
								color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
								icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
							/>
						</Button>
					</div>
				),
		},
	]
	if (filter && filter[0].id === FilterBy.NDISPlanReviewPeriod) {
		clientListColumns.splice(4, 0, {
			id: SortBy.NDISPlanExpiryDays,
			sortable: true,
			header: "# Days until NDIS Plan Expires",
			resolver: (row: ClientWithContact) => {
				if (!row.ndisPlan) {
					return ""
				}
				return moment(row.ndisPlan.planEndDate).diff(moment.now(), "days")
			},
		})
	}

	const filterOptions = [
		{ label: FilterBy.All, id: FilterBy.All },
		{ label: FilterBy.Active, id: FilterBy.Active },
		{ label: "NDIS", id: FilterBy.NDIS },
		{ label: "NDIS Plan Review Period", id: FilterBy.NDISPlanReviewPeriod },
		{ label: FilterBy.Archived, id: FilterBy.Archived },
		{ label: FilterBy.Organisation, id: FilterBy.Organisation },
		{ label: "Archived Organisations", id: FilterBy.OrganisationArchived },
		{ label: "Client Without Intake", id: FilterBy.ClientWithoutIntake },
	]

	if (hasPermission(RolePermission.TeamCreate)) {
		filterOptions.push({ label: FilterBy.Team, id: FilterBy.Team })
	}

	return (
		<ZenCard className={containerStyle}>
			{error && <ErrorNotification messageOrPayload={payload} />}

			<div className={top}>
				{/* Search/ filter bar */}
				<SearchAndFilter search={search || ""} setSearch={setSearch} filter={filter} setFilter={setFilter} filterOptions={filterOptions} />

				{/* Toggle buttons for list / cards view */}
				<Button kind={KIND.tertiary} onClick={() => setListView(false)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
					<FontAwesomeIcon size={"lg"} color={!listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "th"]} />
				</Button>

				<Spacer />

				<Button kind={KIND.tertiary} onClick={() => setListView(true)} overrides={{ BaseButton: { style: { backgroundColor: "transparent" } } }}>
					<FontAwesomeIcon size={"lg"} color={listView ? ZenTheme.colors.primaryGreen : "grey"} icon={["fas", "bars"]} />
				</Button>
			</div>
			<ListTable
				handleSort={handleSort}
				cardView={!listView}
				sortColumn={sortColumn}
				sortAsc={sortAsc}
				rows={rows}
				cardViewResolver={(row: ClientWithContact) => CardResolver(row)}
				onRowClick={(row: ClientWithContact) => viewClient(row.id)}
				columns={clientListColumns}
				isLoading={loading}
			/>

			<ZenArchiveModal
				error={archiveClient.error || unarchiveClient.error}
				payload={archiveClient.payload || unarchiveClient.payload}
				open={archiveModalOpen}
				onClose={() => setArchiveModalOpen(false)}
				loading={archiveClient.loading || unarchiveClient.loading}
				message="Client"
				restoreMode={!!selectedClient?.deletedAt}
				confirmArchive={() => {
					if (!selectedClient) return
					if (selectedClient.deletedAt) {
						unarchiveClient.mutate({ id: selectedClient.id })
					} else {
						archiveClient.mutate({ id: selectedClient.id })
					}
				}}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
		</ZenCard>
	)
}
