import * as React from "react"
import { Route, RouteComponentProps, Switch, useHistory } from "react-router-dom"

import { PortalContainer } from "../../controllers/portal"
import { TravelCreate } from "./travelCreate"
import { TravelList } from "./travelList"
import { TravelViewWithRoute } from "./travelView"
import { routes } from "routes"
import { ZenCard } from "../../components/common"
import { useStyletron } from "styletron-react"
import { LabelLarge } from "baseui/typography"
import { CancelAndSaveButtons } from "../../components/cancelSaveButtons"
import { ZenButton } from "../../components/zenComponents/zenButtons"

export default function TravelRoot() {
	return (
		<Switch>
			<Route exact path={routes.travels.root} component={TravelList} />
			<Route exact path={routes.travels.create} component={TravelCreate} />
			<Route exact path={routes.travels.createConfirm} component={TravelConfirmReminder} />
			<Route path={routes.travels.view} component={TravelSubRoot} />
		</Switch>
	)
}

export const TravelSubRoot = (props: RouteComponentProps<{ id: string }>) => {
	const firstLoad = React.useRef(true)
	const { fetchTravel } = PortalContainer.useContainer()

	React.useEffect(() => {
		if (firstLoad.current) {
			// On the first render fetch the travel details
			fetchTravel(props.match.params.id)
			firstLoad.current = false
		}
	}, [fetchTravel, props.match.params.id])
	return (
		<Switch>
			<Route exact path={routes.travels.view} component={TravelViewWithRoute} />
		</Switch>
	)
}

const TravelConfirmReminder = () => {
	const [css] = useStyletron()
	const history = useHistory()
	const [clickYes, setClickYes] = React.useState(false)
	const container = css({
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
	})
	const inner = css({
		height: "fit-content",
		display: "flex",
		flexDirection: "column",
		width: "fit-content",
	})
	const buttonContainer = css({
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		marginTop: "15px",
	})

	return (
		<ZenCard className={container}>
			<div className={inner}>
				<LabelLarge>{clickYes ? "Travels related to a session must be created via sessions." : "Do you want to log travel related to a session?"}</LabelLarge>
				{!clickYes && (
					<CancelAndSaveButtons
						buttonWidths="100px"
						width="100%"
						cancelLabel="No"
						saveLabel="Yes"
						cancelFn={() => history.push(routes.travels.create)}
						saveFn={() => setClickYes(true)}
					/>
				)}
				{clickYes && (
					<div className={buttonContainer}>
						<ZenButton width="fit-content" onClick={() => history.push(routes.sessions.root)}>
							Go to my sessions
						</ZenButton>
					</div>
				)}
			</div>
		</ZenCard>
	)
}
