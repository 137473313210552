import { styled } from "baseui"

export const IntakeScrollDiv = styled("div", () => ({
	paddingRight: "10px",
	marginTop: "10px",
	maxHeight: "95%",
	width: "100%",
	overflowX: "hidden",
	overflowY: "auto",
}))
