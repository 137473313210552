import * as React from "react"
import { useStyletron } from "baseui"
import { Alert } from "baseui/icon"
import { SIZE } from "baseui/modal"
import { Value } from "baseui/select"
import { LabelMedium } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { useQuery } from "react-fetching-library"
import { useHistory } from "react-router-dom"
import { ZenTheme } from "themeOverrides"
import { ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { ListTable } from "../../components/listTable"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenDivider } from "../../components/zenComponents/zenInput"
import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { getTimesheetStatus, getMileageClaimStatus, snakeToTitle } from "../../helpers/utils"
import { TZString, ZenDateFormat } from "../../types/types"
import { MileageClaimReview, MileageClaimManage } from "../reports/mileageClaim"
import { TimesheetManage, TimesheetReview } from "../reports/timeSheets"

export const Claims = (props: { workerID: string; showDeclined?: boolean }) => {
	const [mileageClaims, setMileageClaims] = React.useState<ListData[]>()
	const [timeSheets, setTimeSheets] = React.useState<ListData[]>()
	const [selectedMileageClaim, setSelectedMileageClaim] = React.useState("")
	const [selectedTimeSheet, setSelectedTimeSheet] = React.useState("")
	const [newMileageClaimOpen, setNewMileageClaimOpen] = React.useState(false)
	const [newTimeSheetOpen, setNewTimeSheetOpen] = React.useState(false)
	const { currentUser } = AuthContainer.useContainer()
	const [claimUser, setClaimUser] = React.useState<Value>()
	const history = useHistory()

	React.useEffect(() => {
		if (!currentUser) return
		setClaimUser([{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}` }])
	}, [currentUser])

	const mileageClaimsQuery = useQuery(
		fetching.query.getMileageClaimMany({
			limit: 5,
			search: {
				workerID: props.workerID,
				filterBy: props.showDeclined ? "Declined" : undefined,
				sortBy: "PeriodStart",
				sortDir: "Descending",
			},
		}),
	)
	React.useEffect(() => {
		if (!mileageClaimsQuery.payload) {
			return
		}
		const rows: ListData[] = []
		for (const mileageClaim of mileageClaimsQuery.payload.mileageClaims) {
			rows.push({
				id: mileageClaim.id,
				period_to: moment(mileageClaim.periodEnd),
				period_from: moment(mileageClaim.periodStart),
				status: snakeToTitle(getMileageClaimStatus(!!mileageClaim.completedAt, !!mileageClaim.readyAt, !!mileageClaim.checkedAt, mileageClaim.reviewStatus)),
			})
		}
		setMileageClaims(rows)
	}, [mileageClaimsQuery.payload])

	const timeSheetsQuery = useQuery(
		fetching.query.getManyTimesheets({
			limit: 5,
			search: {
				workerID: props.workerID,
				filterBy: props.showDeclined ? "Declined" : undefined,
				sortBy: "PeriodStart",
				sortDir: "Descending",
			},
		}),
	)
	React.useEffect(() => {
		if (!timeSheetsQuery.payload) {
			return
		}
		const rows: ListData[] = []
		for (const timeSheet of timeSheetsQuery.payload.timesheets) {
			rows.push({
				id: timeSheet.id,
				period_to: moment(timeSheet.periodEnd),
				period_from: moment(timeSheet.periodStart),
				status: snakeToTitle(getTimesheetStatus(!!timeSheet.completedAt, !!timeSheet.readyAt, !!timeSheet.checkedAt, timeSheet.reviewStatus)),
			})
		}
		setTimeSheets(rows)
	}, [timeSheetsQuery.payload])

	const onNewMileageClaimSuccess = React.useCallback(() => {
		mileageClaimsQuery.query()
		setNewMileageClaimOpen(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const mileageClaimOnClose = React.useCallback(() => {
		setSelectedMileageClaim("")
		mileageClaimsQuery.query()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const mileageClaimReQuery = React.useCallback(() => mileageClaimsQuery.query(), []) // eslint-disable-line react-hooks/exhaustive-deps

	const onNewTimeSheetSuccess = React.useCallback(() => {
		timeSheetsQuery.query()
		setNewTimeSheetOpen(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const timesheetOnClose = React.useCallback(() => {
		setSelectedTimeSheet("")
		timeSheetsQuery.query()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const timesheetsReQuery = React.useCallback(() => timeSheetsQuery.query(), []) // eslint-disable-line react-hooks/exhaustive-deps

	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})

	return (
		<ZenCard className={container}>
			<List
				hideNewButton={props.showDeclined}
				heading={"Time Sheets"}
				data={timeSheets}
				loading={timeSheetsQuery.loading}
				onClickRow={setSelectedTimeSheet}
				onClickNew={() => setNewTimeSheetOpen(true)}
				viewAll={() => history.push(`/portal/workers/${props.workerID}/timesheets`)}
			/>
			<ZenDivider marginBottom={"10px"} />
			<List
				hideNewButton={props.showDeclined}
				heading={"Mileage Claims"}
				data={mileageClaims}
				loading={mileageClaimsQuery.loading}
				onClickRow={setSelectedMileageClaim}
				onClickNew={() => setNewMileageClaimOpen(true)}
				viewAll={() => history.push(`/portal/workers/${props.workerID}/mileage_claims`)}
			/>
			<MileageClaimReview id={selectedMileageClaim} isOpen={!!selectedMileageClaim} onClose={mileageClaimOnClose} reQuery={mileageClaimReQuery} />
			{!!selectedTimeSheet && <TimesheetReview id={selectedTimeSheet} isOpen={!!selectedTimeSheet} onClose={timesheetOnClose} reQuery={timesheetsReQuery} />}
			<ZenModal isOpen={newMileageClaimOpen} onClose={() => setNewMileageClaimOpen(false)} size={SIZE.auto} autoFocus={false}>
				{mileageClaimsQuery.error && <ErrorNotification messageOrPayload={mileageClaimsQuery.payload} />}
				<MileageClaimManage onSuccess={onNewMileageClaimSuccess} onCancel={() => setNewMileageClaimOpen(false)} worker={claimUser} />
			</ZenModal>
			<ZenModal isOpen={newTimeSheetOpen} onClose={() => setNewTimeSheetOpen(false)} size={SIZE.auto} autoFocus={false}>
				{timeSheetsQuery.error && <ErrorNotification messageOrPayload={timeSheetsQuery.payload} />}
				<TimesheetManage onSuccess={onNewTimeSheetSuccess} onCancel={() => setNewTimeSheetOpen(false)} worker={claimUser} />
			</ZenModal>
		</ZenCard>
	)
}

interface ListData {
	id: string
	period_from: moment.Moment
	period_to: moment.Moment
	status: string
}

interface ListProps {
	heading: string
	data?: ListData[]
	onClickRow?: (id: string) => void
	onClickNew?: () => void
	loading?: boolean
	hideNewButton?: boolean
	viewAll?: () => void
}

const List = (props: ListProps) => {
	const [css] = useStyletron()
	const heading = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	})
	const alertRow = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
	})
	const titleButtons = css({
		display: "flex",
		alignItems: "center",
	})

	return (
		<>
			<div className={heading}>
				<LabelMedium>{props.heading}</LabelMedium>
				<div className={titleButtons}>
					{!!props.viewAll && (
						<ZenButton altKind="tertiary" onClick={props.viewAll}>
							View all
						</ZenButton>
					)}
					{!props.hideNewButton && <ZenButton onClick={props.onClickNew}>New</ZenButton>}
				</div>
			</div>
			<ListTable
				isLoading={props.loading}
				rows={props.data || []}
				onRowClick={(row: ListData) => (props.onClickRow ? props.onClickRow(row.id) : null)}
				columns={[
					{
						id: "Period",
						header: "Period",
						resolver: (row: ListData) => (
							<div>{`${row.period_from.tz(TZString[0].id).format(ZenDateFormat)} - ${row.period_to.tz(TZString[0].id).format(ZenDateFormat)}`}</div>
						),
					},
					{
						id: "Declined",
						header: "",
						resolver: (row: ListData) => (
							<div className={alertRow}>
								{row.status === "Declined" && (
									<Alert
										overrides={{
											Svg: {
												style: {
													height: "20px",
													width: "20px",
													alignSelf: "center",
												},
											},
										}}
										color={ZenTheme.colors.negative}
										title="This claim was declined"
									/>
								)}
							</div>
						),
					},
					{
						id: "Status",
						header: "Status",
						resolver: (row: ListData) => row.status,
					},
				]}
			/>
		</>
	)
}
