import * as React from "react"
import { useStyletron } from "baseui"
import { LabelMedium } from "baseui/typography"
import { ReviewRecord } from "../../types/types"
import { Spacer } from "../common"
import { DisplayLabel } from "../sessions/newClientAppointment/singleSessionConfirmation"
import { DisplaySelectCard } from "../zenComponents/zenSelectBox"
import moment from "moment-timezone"

export const ReviewHistory = (props: { records: ReviewRecord[] }) => {
	const [css] = useStyletron()
	const container = css({
		height: "fit-content",
		width: "100%",
		padding: "8px",
		border: "1px solid black",
		borderRadius: "3px",
		display: "flex",
		flexDirection: "column",
		marginTop: "8px",
	})
	const group = css({
		display: "flex",
		width: "100%",
	})
	return (
		<div className={container}>
			<LabelMedium>Review History:</LabelMedium>
			{props.records.map((rr, i) => (
				<div className={group} key={i}>
					<DisplayLabel marginTop={0} label="Date">
						{moment(rr.date).format("DD/MM/YYYY")}
					</DisplayLabel>
					<Spacer style={{ width: "40px" }} />
					<DisplayLabel marginTop={0} label="Completed with">
						{<DisplaySelectCard option={{ ...rr.worker, label: `${rr.worker.firstName} ${rr.worker.lastName}` }} />}
					</DisplayLabel>
				</div>
			))}
		</div>
	)
}
