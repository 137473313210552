import * as React from "react"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelLarge, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"

import { AuthContainer } from "../../controllers/auth"
import { fetching } from "../../fetching"
import { FormType, FormTypeName, minimumAgeMonths } from "../../types/enums"
import { ClientDetail, Form, UserDetail } from "../../types/types"
import { Spacer, ZenCard } from "../common"
import { ErrorNotification } from "../errorBox"
import { DisplayLabel } from "../sessions/newClientAppointment/singleSessionConfirmation"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenButton } from "../zenComponents/zenButtons"
import { ZenCheckbox } from "../zenComponents/zenCheckboxList"
import { ZenTextArea } from "../zenComponents/zenInput"
import { ZenClientSelect, ZenPlaceSelect, ZenUserSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../zenComponents/zenTime"

import { routes } from "routes"
import { ErrorFieldTracker } from "./errorFieldTracker"

interface RiskAssessmentForm {
	date: string
	client: ClientDetail
	worker: UserDetail
	dateOfBirth: string
	address: Value
	reasonForHomeVisit?: string
	otherPeopleInHome?: string
	clearEntryPoint?: string
	placeForMeeting?: string
	smokeInHouse?: string
	petInHouse?: string
	mentalHealthConcern?: string
	substanceInHome?: string
	restrainingOrderPerson?: string
	involvedDCP?: string
	firearmOrWeapon?: string
	anythingElse?: string
	hasOtherPeopleInHome: boolean
	hasClearEntryPoint: boolean
	hasPlaceForMeeting: boolean
	hasSmokeInHouse: boolean
	hasPetInHouse: boolean
	hasMentalHealthConcern: boolean
	hasSubstanceInHome: boolean
	hasRestrainingOrderPerson: boolean
	hasInvolvedDCP: boolean
	hasFirearmOrWeapon: boolean
	hasAnythingElse: boolean
}

export const RiskAssessmentVisitation = () => {
	const history = useHistory()
	const { showToast } = useZenToast()
	const searchArgs = new URLSearchParams(history.location.search)
	const { currentUser } = AuthContainer.useContainer()
	const { handleSubmit, formState, control, errors, setValue, getValues, watch } = useForm()

	const { query, loading, payload, error } = useParameterizedQuery(fetching.query.getForm)

	// pre-query form
	const formID = searchArgs.get("formID")
	React.useEffect(() => {
		if (!formID) return
		query(formID)
	}, [formID, query])

	const [cachedFormData, setCachedFormData] = React.useState<RiskAssessmentForm>()

	const hasOtherPeopleInHome = watch("hasOtherPeopleInHome")
	const hasClearEntryPoint = watch("hasClearEntryPoint")
	const hasPlaceForMeeting = watch("hasPlaceForMeeting")
	const hasSmokeInHouse = watch("hasSmokeInHouse")
	const hasPetInHouse = watch("hasPetInHouse")
	const hasMentalHealthConcern = watch("hasMentalHealthConcern")
	const hasSubstanceInHome = watch("hasSubstanceInHome")
	const hasRestrainingOrderPerson = watch("hasRestrainingOrderPerson")
	const hasInvolvedDCP = watch("hasInvolvedDCP")
	const hasFirearmOrWeapon = watch("hasFirearmOrWeapon")
	const hasAnythingElse = watch("hasAnythingElse")

	const setFormData = (formData: RiskAssessmentForm) => {
		setValue("date", moment(formData.date).toDate())
		setValue("client", formData.client)
		setValue("address", formData.address)
		setValue("dateOfBirth", formData.dateOfBirth ? moment(formData.dateOfBirth).toDate() : undefined)
		setValue("reasonForHomeVisit", formData.reasonForHomeVisit)
		setValue("worker", formData.worker)
		setValue("reasonForHomeVisit", formData.reasonForHomeVisit)
		setValue("otherPeopleInHome", formData.otherPeopleInHome)
		setValue("clearEntryPoint", formData.clearEntryPoint)
		setValue("placeForMeeting", formData.placeForMeeting)
		setValue("smokeInHouse", formData.smokeInHouse)
		setValue("petInHouse", formData.petInHouse)
		setValue("mentalHealthConcern", formData.mentalHealthConcern)
		setValue("substanceInHome", formData.substanceInHome)
		setValue("restrainingOrderPerson", formData.restrainingOrderPerson)
		setValue("involvedDCP", formData.involvedDCP)
		setValue("firearmOrWeapon", formData.firearmOrWeapon)
		setValue("anythingElse", formData.anythingElse)
		setValue("hasOtherPeopleInHome", formData.hasOtherPeopleInHome)
		setValue("hasClearEntryPoint", formData.hasClearEntryPoint)
		setValue("hasPlaceForMeeting", formData.hasPlaceForMeeting)
		setValue("hasSmokeInHouse", formData.hasSmokeInHouse)
		setValue("hasPetInHouse", formData.hasPetInHouse)
		setValue("hasMentalHealthConcern", formData.hasMentalHealthConcern)
		setValue("hasSubstanceInHome", formData.hasSubstanceInHome)
		setValue("hasRestrainingOrderPerson", formData.hasRestrainingOrderPerson)
		setValue("hasInvolvedDCP", formData.hasInvolvedDCP)
		setValue("hasFirearmOrWeapon", formData.hasFirearmOrWeapon)
		setValue("hasAnythingElse", formData.hasAnythingElse)
	}

	React.useEffect(() => {
		if (loading || error || !payload) return
		const content = payload.content as RiskAssessmentForm
		setFormData(content)
	}, [payload, loading, error]) // eslint-disable-line react-hooks/exhaustive-deps

	// pre-query client
	const clientID = searchArgs.get("id")
	const { query: clientQuery, loading: clientLoading, error: clientError, payload: clientPayload } = useParameterizedQuery(fetching.query.getClient)
	const [isEditMode, setIsEditMode] = React.useState(!formID)
	const canEdit = !formID || isEditMode
	const [formDirty, setFormDirty] = React.useState(false)

	React.useEffect(() => {
		if (formState.isDirty || formState.isSubmitting) {
			setFormDirty(true)
		}
	}, [formState.isDirty, formState.isSubmitting])

	React.useEffect(() => {
		if (formState.isSubmitted) {
			setFormDirty(false)
		}
	}, [formState.isSubmitted])

	React.useEffect(() => {
		if (!clientID) return
		clientQuery(clientID)
	}, [clientID, clientQuery])

	React.useEffect(() => {
		if (clientLoading || clientError || !clientPayload) return
		setValue("client", [{ ...clientPayload, label: `${clientPayload.firstName} ${clientPayload.lastName}` }])
		setValue("dateOfBirth", clientPayload.dateOfBirth ? moment(clientPayload.dateOfBirth).toDate() : undefined)
		setValue(
			"address",
			clientPayload.currentContact && clientPayload.currentContact.residentialAddress
				? [{ label: clientPayload.currentContact.residentialAddress.fullAddress, id: clientPayload.currentContact.residentialAddress.placeID }]
				: [],
		)
	}, [clientPayload, clientLoading, clientError, setValue])

	const handleClientOnChange = (v: Value) => {
		setValue("dateOfBirth", null)
		setValue("address", [])
		if (v.length > 0) {
			if (v[0].dateOfBirth) {
				setValue("dateOfBirth", moment(v[0].dateOfBirth).toDate())
			}
			if (v[0].currentContact && v[0].currentContact.residentialAddress) {
				setValue("address", [{ label: v[0].currentContact.residentialAddress.fullAddress, id: v[0].currentContact.residentialAddress.placeID }])
			}
		}
	}

	const {
		mutate: createForm,
		loading: creatingForm,
		error: createError,
		payload: createPayload,
	} = useMutation<Form<RiskAssessmentForm>>(fetching.mutation.formCreate)
	const {
		mutate: updateForm,
		loading: updatingForm,
		error: updateError,
		payload: updatePayload,
	} = useMutation<Form<RiskAssessmentForm>>(fetching.mutation.formUpdate)

	const onSubmit = async (formData: any) => {
		const content: RiskAssessmentForm = {
			date: formData.date,
			client: formData.client,
			worker: formData.worker,
			dateOfBirth: formData.dateOfBirth,
			address: formData.address,
			reasonForHomeVisit: formData.reasonForHomeVisit,
			otherPeopleInHome: hasOtherPeopleInHome ? formData.otherPeopleInHome : undefined,
			clearEntryPoint: hasClearEntryPoint ? formData.clearEntryPoint : undefined,
			placeForMeeting: hasPlaceForMeeting ? formData.placeForMeeting : undefined,
			smokeInHouse: hasSmokeInHouse ? formData.smokeInHouse : undefined,
			petInHouse: hasPetInHouse ? formData.petInHouse : undefined,
			mentalHealthConcern: hasMentalHealthConcern ? formData.mentalHealthConcern : undefined,
			substanceInHome: hasSubstanceInHome ? formData.substanceInHome : undefined,
			restrainingOrderPerson: hasRestrainingOrderPerson ? formData.restrainingOrderPerson : undefined,
			involvedDCP: hasInvolvedDCP ? formData.involvedDCP : undefined,
			firearmOrWeapon: hasFirearmOrWeapon ? formData.firearmOrWeapon : undefined,
			anythingElse: hasAnythingElse ? formData.anythingElse : undefined,
			hasOtherPeopleInHome,
			hasClearEntryPoint,
			hasPlaceForMeeting,
			hasSmokeInHouse,
			hasPetInHouse,
			hasMentalHealthConcern,
			hasSubstanceInHome,
			hasRestrainingOrderPerson,
			hasInvolvedDCP,
			hasFirearmOrWeapon,
			hasAnythingElse,
		}
		const input = {
			clientID: formData.client[0].id,
			content,
		}

		if (formID) {
			const resp = await updateForm({
				id: formID,
				...input,
			})

			if (resp.error || !resp.payload) return
			setFormDirty(false)
			setIsEditMode(false)
			showToast("Outreach Risk Assessment Visitation document updated successfully.", "positive")
			return
		}
		const resp = await createForm({
			input: {
				name: `${FormTypeName(FormType.RiskAssessmentVisitation)} Form | ${formData.client[0].firstName} ${
					formData.client[0].lastName
				} | ${formData.date.toLocaleDateString(undefined, {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				})}`,
				type: FormType.RiskAssessmentVisitation,
				...input,
			},
		})

		if (resp.payload) {
			setFormDirty(false)
			showToast("Outreach Risk Assessment Visitation document created successfully.", "positive")
			history.push(resp.payload.clientID ? `/portal/clients/${resp.payload.clientID}#forms` : "/portal/forms")
		}
	}

	const isLoading = creatingForm || updatingForm

	const cancelFn = () => {
		if (!!formID && isEditMode) {
			// reset data
			if (cachedFormData) setFormData(cachedFormData)
			setFormDirty(false)
			setIsEditMode(false)
			return
		}
		if (!formID && clientID) {
			history.push(`/portal/clients/${clientID}#forms`)
			return
		}
		const client = getValues().client
		if (client && client.length > 0) {
			history.push(`/portal/clients/${client[0].id}#forms`)
			return
		}

		history.push(routes.forms.root)
	}

	const continueOrSaveButton = () => {
		if (!formID)
			return (
				<ZenButton width="100px" type="submit" isLoading={isLoading}>
					Submit
				</ZenButton>
			)

		if (!isEditMode) return null

		return (
			<ZenButton width="100px" type="submit" isLoading={isLoading}>
				Save
			</ZenButton>
		)
	}

	const [css] = useStyletron()
	const container = css({
		height: "100%",
	})
	const formStyles = css({
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		padding: "15px",
		position: isLoading ? "relative" : "unset",
		opacity: isLoading ? 0.5 : 1,
	})
	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})

	const title = css({
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "8px",
	})

	const scrollingDiv = css({
		flex: 1,
		minHeight: 0,
		width: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		paddingRight: "8px",
		marginBottom: "8px",
	})

	const buttonGroup = css({
		display: "flex",
		justifyContent: "space-between",
		marginTop: "10px",
		marginLeft: "-10px",
		paddingRight: "10px",
		[`@media print`]: {
			display: "none",
		},
	})

	return (
		<ZenCard className={container}>
			<form autoComplete="off" className={formStyles} onSubmit={handleSubmit(onSubmit)}>
				<div className={title}>
					<LabelLarge>Outreach Visitation Risk Assessment</LabelLarge>
					{!!formID && !isEditMode && (
						<ZenButton
							onClick={() => {
								// save current status
								setCachedFormData(getValues() as RiskAssessmentForm)
								setIsEditMode(true)
								setFormDirty(true)
							}}
							height="38px"
							width="100px"
						>
							Edit
						</ZenButton>
					)}
				</div>
				{isLoading && (
					<div className={loadingIcon}>
						<StyledSpinnerNext />
					</div>
				)}
				<div className={scrollingDiv}>
					<ZenDatePicker paddingTop="0px" label="Date" formName="date" formRef={control} clearable={false} disabled={!canEdit} />
					<ZenUserSelect
						width="100%"
						label="Worker"
						formName="worker"
						formRef={control}
						inputError={errors.worker}
						defaultValue={currentUser ? [currentUser] : []}
						disabled={!canEdit}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Worker is required",
							},
						}}
					/>
					<ZenClientSelect
						width="100%"
						label="Client"
						formName="client"
						formRef={control}
						inputError={errors.client}
						actionOnChange={handleClientOnChange}
						disabled={!canEdit}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Client is required",
							},
						}}
					/>
					<ZenDatePicker
						label="Date of Birth"
						paddingTop="0px"
						formName="dateOfBirth"
						disabled
						nullDefaultValue
						formRef={control}
						clearable={false}
						minDate={new Date("1900-01-01")}
						maxDate={moment().subtract(minimumAgeMonths, "month").toDate()}
					/>
					<ZenPlaceSelect
						disabled={!canEdit}
						label="Address"
						formName="address"
						formRef={control}
						inputError={errors.address}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Address is required",
							},
						}}
					/>
					<ZenTextArea
						getValues={getValues}
						disabled={!canEdit}
						label="Reason for home visit"
						nameRef="reasonForHomeVisit"
						inputError={errors.reasonForHomeVisit}
						formRef={control}
						formRules={{ required: "Reason for home visit is required" }}
					/>
					<LabelLarge>Risk Assessment Check List</LabelLarge>
					<DisplayLabel label="Read To Client">
						<LabelSmall>
							{`For the safety of the individuals and families we work with, HelpingMind's staff are subject to a Federal Police Clearance and a Working with
							Children Check. We are equally committed to the wellbeing of our staff which means keeping them informed of any concerns they may encounter whilst
							working.`}
						</LabelSmall>
					</DisplayLabel>
					<DisplayLabel label="Please assist us by answering the following questions">
						<ZenCheckbox
							disabled={!canEdit}
							formName="hasOtherPeopleInHome"
							formRef={control}
							label="Are there other people living in this home besides yourself? If yes, list those who may be present during home visit."
						/>
						<Spacer />
						{hasOtherPeopleInHome && (
							<ZenTextArea
								getValues={getValues}
								nameRef="otherPeopleInHome"
								disabled={!canEdit}
								inputError={errors.otherPeopleInHome}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox
							disabled={!canEdit}
							formName={"hasClearEntryPoint"}
							formRef={control}
							label="Is there a clear entry point for the house – front or back?"
						/>
						<Spacer />
						{hasClearEntryPoint && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="clearEntryPoint"
								inputError={errors.clearEntryPoint}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox
							disabled={!canEdit}
							formName={"hasPlaceForMeeting"}
							formRef={control}
							label="Is there a clear and private place where the meeting can take place? Where?"
						/>
						<Spacer />
						{hasPlaceForMeeting && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="placeForMeeting"
								inputError={errors.placeForMeeting}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox disabled={!canEdit} formName={"hasSmokeInHouse"} formRef={control} label="Do you smoke inside the house?" />
						<Spacer />
						{hasSmokeInHouse && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="smokeInHouse"
								inputError={errors.smokeInHouse}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox
							disabled={!canEdit}
							formName={"hasPetInHouse"}
							formRef={control}
							label="Do you have any pets? Are you prepared to move them outside or into another area when staff are present?"
						/>
						<Spacer />
						{hasPetInHouse && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="petInHouse"
								inputError={errors.petInHouse}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox
							disabled={!canEdit}
							formName={"hasMentalHealthConcern"}
							formRef={control}
							label="Are there any mental health concerns in the home? Are there any symptoms of unwellness that we may need to be aware of? Are there any known
							triggers we may need to be aware of? (ie. unknown male/female in house)"
						/>
						<Spacer />
						{hasMentalHealthConcern && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="mentalHealthConcern"
								inputError={errors.mentalHealthConcern}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox
							disabled={!canEdit}
							formName={"hasSubstanceInHome"}
							formRef={control}
							label="Is there any substance use in the home? (drugs and/or alcohol?)"
						/>
						<Spacer />
						{hasSubstanceInHome && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="substanceInHome"
								inputError={errors.substanceInHome}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox
							disabled={!canEdit}
							formName={"hasRestrainingOrderPerson"}
							formRef={control}
							label="Does anyone living in this home have a Restraining Order against another person?"
						/>
						<Spacer />
						{hasRestrainingOrderPerson && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="restrainingOrderPerson"
								inputError={errors.restrainingOrderPerson}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<ZenCheckbox disabled={!canEdit} formName={"hasInvolvedDCP"} formRef={control} label="(If applicable) Are you currently involved with DCP?" />
						<Spacer />
						{hasInvolvedDCP && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="involvedDCP"
								inputError={errors.involvedDCP}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<Spacer />
						<ZenCheckbox
							disabled={!canEdit}
							formName={"hasFirearmOrWeapon"}
							formRef={control}
							label="Are there any firearms or weapons in the house? If yes, where are they stored?"
						/>
						{hasFirearmOrWeapon && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="firearmOrWeapon"
								inputError={errors.firearmOrWeapon}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
						<Spacer />
						<ZenCheckbox disabled={!canEdit} formName={"hasAnythingElse"} formRef={control} label="Is there anything else we should be aware of?" />
						{hasAnythingElse && (
							<ZenTextArea
								getValues={getValues}
								disabled={!canEdit}
								nameRef="anythingElse"
								inputError={errors.anythingElse}
								formRef={control}
								formRules={{ required: "Detail is required" }}
							/>
						)}
					</DisplayLabel>
				</div>
				{createError && <ErrorNotification messageOrPayload={createPayload} />}
				{updateError && <ErrorNotification messageOrPayload={updatePayload} />}
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				<div className={buttonGroup}>
					<ZenButton type="button" onClick={cancelFn} disabled={isLoading} altKind="secondary" width="100px">
						{!!formID && !isEditMode ? "Exit" : "Cancel"}
					</ZenButton>
					{continueOrSaveButton()}
				</div>
			</form>
			<Prompt when={formDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
		</ZenCard>
	)
}
