import * as React from "react"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Textarea } from "baseui/textarea"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { Control, Controller, FieldError } from "react-hook-form"
import { DeepMap } from "react-hook-form/dist/types/utils"

import { ZenInputResizableOverrides } from "../../../themeOverrides"
import { FormValuePreview } from "../common"
import { HazardRiskIdentificationFormData } from "./hazardRiskIdentification"

interface HazardRiskStep2Props {
	control: Control<HazardRiskIdentificationFormData>
	errors: DeepMap<HazardRiskIdentificationFormData, FieldError>
	disabled?: boolean
	infoOnly?: boolean
}

const required = { required: { value: true, message: "Required" } }

const HazardRiskStep2 = (props: HazardRiskStep2Props) => {
	const { control, errors, disabled, infoOnly } = props

	const [css] = useStyletron()
	const labelStyle = css({
		fontWeight: 600,
	})
	const helpStyle = css({
		color: "#BEBEBE",
		marginLeft: "7px",
	})
	const titleStyle = css({
		fontWeight: "bold",
		marginBottom: "20px",
	})

	return (
		<div>
			<LabelLarge className={titleStyle}>Describe risk / hazard</LabelLarge>

			<FormControl
				label={
					<span>
						<span className={labelStyle}>Briefly describe the risk / hazard</span>
						<span className={helpStyle}>What can go wrong?</span>
					</span>
				}
				error={errors.description && errors.description.message}
			>
				<Controller
					name={`description`}
					control={control}
					as={infoOnly ? undefined : Textarea}
					render={FormValuePreview(infoOnly)}
					placeholder={!disabled ? "Enter description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					defaultValue=""
					error={errors.description !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl
				label={
					<span>
						<span className={labelStyle}>What is the cause of the risk / hazard?</span>
						<span className={helpStyle}>ie due to / caused by</span>
					</span>
				}
				error={errors.cause && errors.cause.message}
			>
				<Controller
					name={`cause`}
					control={control}
					as={infoOnly ? undefined : Textarea}
					render={FormValuePreview(infoOnly)}
					placeholder={!disabled ? "Enter description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					defaultValue=""
					error={errors.cause !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl
				label={
					<span>
						<span className={labelStyle}>What was the result</span>
						<span className={helpStyle}>What was the outcome?</span>
					</span>
				}
				error={errors.result && errors.result.message}
			>
				<Controller
					name={`result`}
					control={control}
					as={infoOnly ? undefined : Textarea}
					render={FormValuePreview(infoOnly)}
					placeholder={!disabled ? "Enter description" : ""}
					disabled={disabled}
					overrides={ZenInputResizableOverrides}
					error={errors.result !== undefined}
					defaultValue=""
					rules={required}
				/>
			</FormControl>

			{!disabled && <LabelSmall>The form will be sent to an Executive corporate to determine the level of risk.</LabelSmall>}
		</div>
	)
}

export default HazardRiskStep2
