import * as React from "react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Value } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import { LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography"
import moment from "moment-timezone"
import { QueryResponse, useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { NestedValue, useForm } from "react-hook-form"
import { RouteComponentProps, useHistory } from "react-router-dom"

import { fetching } from "../../../fetching"
import { ZenTheme } from "../../../themeOverrides"
import { FilterBy, SortBy, SortDir } from "../../../types/enums"
import { Alert, AlertType, BasicName, SearchTextMinLength, ZenDateFormat } from "../../../types/types"
import { Divider, SearchAndFilter, ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenArchiveModal } from "../../zenComponents/zenArchiveDialog"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenInput, ZenTextArea } from "../../zenComponents/zenInput"
import { ZenSelect } from "../../zenComponents/zenSelectBox"

import { Responsive, WidthProvider } from "react-grid-layout"
import { AuthContainer } from "../../../controllers/auth"
import { TooltipButton } from "../../tooltipButton"
import { ZenModal } from "../../zenComponents/zenModal"
import { ModalBody, ModalHeader } from "baseui/modal"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { PortalContainer } from "../../../controllers/portal"
const ResponsiveGridLayout = WidthProvider(Responsive)

interface AlertListProps {
	clientID: string
	search?: string
	limit?: number
	offset?: number
	filter?: Value
	setOffset?: (value: number) => void
	headerOptions?: React.ReactNode
	onArchive?: (alert: Alert) => void
	triggerQuery?: boolean
	resetTriggerQuery?: () => void
	showActionButton?: boolean
}

export const AlertsList = (props: AlertListProps) => {
	const { clientID, headerOptions, triggerQuery, resetTriggerQuery, showActionButton } = props
	const history = useHistory()
	const limit = props.limit || 20
	const offset = props.offset || 0

	const {
		payload: data,
		loading,
		error,
		query,
	} = useQuery<{ alertList: Alert[]; total: number }>(
		fetching.query.getClientAlertMany({
			search: {
				search: props.search && props.search.length >= SearchTextMinLength ? props.search : undefined,
				filterBy: props.filter && props.filter[0]?.id ? props.filter[0].id.toString() : FilterBy.Active,
				sortBy: SortBy.DateCreated,
				sortDir: SortDir.Descending,
			},
			limit,
			offset,
			clientID,
		}),
	)

	React.useEffect(() => {
		onResetTrigger(!!triggerQuery, resetTriggerQuery)
	}, [triggerQuery, resetTriggerQuery]) // eslint-disable-line react-hooks/exhaustive-deps

	const onResetTrigger = async (triggerQuery: boolean, resetTriggerQuery: (() => void) | undefined) => {
		if (triggerQuery && resetTriggerQuery) {
			const q = await query()
			if (q.error) return
			resetTriggerQuery()
		}
	}

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		height: "100%",
	})
	const title = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "15px",
	})
	const scrollable = css({
		height: "100%",
		overflowX: "hidden",
		overflowY: "auto",
	})

	const showMenu = !history.location.pathname.endsWith("/alerts")

	return (
		<div className={container}>
			<div className={title}>
				<LabelMedium marginRight="12px">Alerts</LabelMedium>
				{error || (!data && <ErrorNotification messageOrPayload={data} closeable />)}
				{loading && <StyledSpinnerNext size="24px" />}
				{headerOptions}
			</div>
			<div className={scrollable}>
				{data &&
					data.alertList &&
					data.alertList.map((alert, idx) => (
						<ClientAlert
							showActionButton={showActionButton}
							key={`client-alert-${alert.id}`}
							index={idx}
							alert={alert}
							onArchive={props.onArchive}
							clickable={showMenu}
							refetchAlerts={query}
						/>
					))}
			</div>
			{showMenu && (
				<Block display="flex" marginTop="15px" justifyContent="space-between">
					<ZenButton altKind="tertiary" onClick={() => history.push(history.location.pathname + "/alerts")}>
						View all
					</ZenButton>
					<ZenButton onClick={() => history.push(history.location.pathname + "/alerts")}>Create Alert</ZenButton>
				</Block>
			)}
		</div>
	)
}
interface ClientAlertProps {
	alert: Alert
	onArchive?: (alert: Alert) => void
	index: number
	clickable?: boolean
	showActionButton?: boolean
	refetchAlerts: () => Promise<
		QueryResponse<{
			alertList: Alert[]
			total: number
		}>
	>
}
const ClientAlert = (props: ClientAlertProps) => {
	const { alert, onArchive, index, clickable, refetchAlerts, showActionButton } = props
	const history = useHistory()
	const [css, theme] = useStyletron()
	const [editMode, setEditMode] = React.useState(false)
	const { setValue, control, errors, trigger, getValues } = useForm()
	const { currentUser } = AuthContainer.useContainer()
	const { timezone } = PortalContainer.useContainer()
	const containerStyle = css({
		marginBottom: "5px",
		padding: "10px",
		fontSize: "14px",
		display: "flex",
		flexDirection: "column",
		borderRadius: "3px",
		backgroundColor: index % 2 === 0 ? theme.colors.backgroundSecondary : theme.colors.white,
		cursor: clickable ? "pointer" : "auto",
	})
	const contentContainer = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	})
	const contentStyle = css({
		wordWrap: "break-word",
		whiteSpace: "pre-line",
	})
	const actionIcons = css({
		display: "flex",
		alignItems: "center",
	})

	const [showHistoryAlerts, setShowHistoryAlerts] = React.useState(false)
	const displayAlert = React.useMemo(() => {
		if (!alert.revisions) return
		if (alert.revisions.length === 0) return
		setValue("content", alert.revisions[0].content)
		return alert.revisions[0]
	}, [alert.revisions, setValue])

	const actionButton = () => {
		if (!showActionButton || editMode) return null
		if (alert.deletedAt) {
			if (onArchive)
				return (
					<TooltipButton
						onClick={(e) => {
							e.stopPropagation()
							onArchive(alert)
						}}
						tooltip={"Restore"}
						iconName={"trash-restore-alt"}
					/>
				)
			return null
		}
		return (
			<>
				<TooltipButton
					onClick={(e) => {
						e.stopPropagation()
						setShowHistoryAlerts(true)
					}}
					tooltip="history"
					iconName="history"
				/>
				{alert.poster && alert.poster.id === currentUser?.id && displayAlert && (
					<>
						<TooltipButton
							onClick={(e) => {
								e.stopPropagation()
								setEditMode(true)
							}}
							tooltip="Edit"
							iconName="edit"
						/>
					</>
				)}

				{onArchive && (
					<TooltipButton
						onClick={(e) => {
							e.stopPropagation()
							onArchive(alert)
						}}
						tooltip={"Archive"}
						iconName={"trash-alt"}
					/>
				)}
			</>
		)
	}

	const clientAlertUpdate = useMutation(fetching.mutation.clientAlertUpdate)
	const onSubmit = async () => {
		const isValid = await trigger()
		if (!isValid) return
		clientAlertUpdate
			.mutate({
				id: alert.id,
				content: getValues().content,
			})
			.then((resp) => {
				if (resp.error || !resp.payload) return
				refetchAlerts()
				setEditMode(false)
			})
	}

	const displayTextContent = () => {
		if (!displayAlert) return null
		if (editMode) {
			return (
				<>
					<ZenTextArea nameRef="content" formRef={control} formRules={{ required: "content is required" }} inputError={errors.content} />
					<CancelAndSaveButtons cancelFn={() => setEditMode(false)} saveFn={onSubmit} />
				</>
			)
		}
		return <div className={contentStyle}>{displayAlert.content}</div>
	}

	return (
		<div className={containerStyle} onClick={clickable ? () => history.push(history.location.pathname + "/alerts") : undefined}>
			<div>
				<div className={contentContainer}>
					<LabelSmall color={theme.colors.contentTertiary}>{`${moment(alert.createdAt).tz(timezone.id).format(ZenDateFormat)} - ${alert.alertType} - ${
						alert.alertSubtype
					}${alert.poster ? ` (by ${alert.poster.firstName} ${alert.poster.lastName})` : ""}`}</LabelSmall>
					<div className={actionIcons}>{actionButton()}</div>
				</div>
				{displayTextContent()}
			</div>
			<RevisionAlertsModal isOpen={showHistoryAlerts} setIsOpen={setShowHistoryAlerts} revisions={alert.revisions} />
		</div>
	)
}

interface RevisionAlertsModalProps {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	revisions: Alert[]
}
const RevisionAlertsModal = (props: RevisionAlertsModalProps) => {
	const { isOpen, setIsOpen, revisions } = props
	const [css, theme] = useStyletron()
	const { timezone } = PortalContainer.useContainer()
	const container = css({
		maxHeight: "700px",
		width: "500px",
		paddingRight: "8px",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const cardStyle = css({
		padding: "10px",
		backgroundColor: ZenTheme.colors.divider,
		marginBottom: "8px",
		width: "100%",
		height: "fit-content",
	})
	const timestamp = css({
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
	})

	const displayInfo = () => {
		if (!revisions || revisions.length === 0) return null
		return revisions.map((ra, i) => (
			<div key={i} className={cardStyle}>
				<LabelSmall color={theme.colors.contentTertiary}>{`${moment(ra.createdAt).tz(timezone.id).format(ZenDateFormat)} - ${ra.alertType} - ${
					ra.alertSubtype
				}${ra.poster ? ` (by ${ra.poster.firstName} ${ra.poster.lastName})` : ""}`}</LabelSmall>
				<LabelSmall
					padding="8px"
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
							},
						},
					}}
				>
					{ra.content}
				</LabelSmall>
				<div className={timestamp}>
					<LabelXSmall>{moment(ra.createdAt).tz(timezone.id).format("hh:mm a, DD/MM/YYYY")}</LabelXSmall>
				</div>
			</div>
		))
	}

	return (
		<ZenModal onClose={() => setIsOpen(false)} isOpen={isOpen} size="auto" role="dialog">
			<ModalHeader>Revision List</ModalHeader>
			<ModalBody>
				<div className={container}>{displayInfo()}</div>
			</ModalBody>
		</ZenModal>
	)
}

interface FormData {
	alertType: NestedValue<BasicName[]>
	alertSubtype: NestedValue<BasicName[]>
	customAlertType: string
	customSubAlertType: string
	content: string
}

export const AlertsPage = (props: RouteComponentProps<{ id: string }>) => {
	const { showToast } = useZenToast()
	const [css] = useStyletron()
	const containerStyle = css({
		display: "flex",
		maxHeight: "100%",
		overflowY: "auto",
	})
	const listStyle = css({
		width: "100%",
		minWidth: "400px",
		height: "100%",
	})
	const addAlertStyle = css({
		height: "100%",
		width: "100%",
	})
	const footerStyle = css({
		marginTop: "14px",
		display: "flex",
		justifyContent: "flex-end",
	})

	const clientID = props.match.params.id

	const [triggerQuery, setTriggerQuery] = React.useState<boolean>(false)

	const [search, setSearch] = React.useState("")
	const [filter, setFilter] = React.useState<Value>([])
	const [offset, setOffset] = React.useState(0)

	const { mutate: addAlert, loading: savingAlert, error: saveAlertError, payload: addAlertPayload } = useMutation(fetching.mutation.clientAlertAdd)
	const {
		mutate: tglAlert,
		loading: togglingAlert,
		error: toggleAlertError,
		payload: toggleAlertPayload,
	} = useMutation<Alert>(fetching.mutation.alertToggleArchive)

	const { control, trigger, watch, setValue, errors } = useForm<FormData>()
	const selectedAlertType = watch("alertType")
	const selectedAlertSubtype = watch("alertSubtype")
	const customAlertType = watch("customAlertType")
	const customSubAlertType = watch("customSubAlertType")

	const content = watch("content")

	const [alertSubtypeList, setAlertSubtypeList] = React.useState<AlertType[]>([])
	const [alertTypeList, setAlertTypeList] = React.useState<AlertType[]>([])
	const alertTypeMany = useQuery(fetching.query.getAlertTypes())
	const alertSubtypeMany = useParameterizedQuery(fetching.query.getAlertSubtypes)
	const [alertData, setAlertData] = React.useState<Alert>()

	const onSaveAlert = async () => {
		if (savingAlert) return

		const isValid = await trigger(["content"])
		if (!isValid) return

		const alertSubtypeID = selectedAlertSubtype[0]?.id || undefined
		const _customAlertType = alertSubtypeID === "Other" ? selectedAlertType[0].name : customAlertType

		const resp = await addAlert({
			clientID,
			content,
			customAlertType: _customAlertType,
			customSubAlertType,
			alertSubtypeID: alertSubtypeID === "Other" ? undefined : alertSubtypeID,
		})
		if (!resp.error && resp.payload) {
			showToast("Alert created successfully.", "positive")
			setValue("content", "")
			setValue("customSubAlertType", "")
			setValue("customAlertType", "")
			setValue("alertType", [])
			setValue("alertSubtype", [])
		}
	}

	const onArchive = async (alert: Alert) => {
		if (togglingAlert) return
		const msg = !alert.deletedAt ? "Alert archived successfully." : "Alert unarchived successfully."
		const resp = await tglAlert({ id: alert.id })
		if (resp.payload) {
			showToast(msg, "positive")
		}
	}

	// clear custom sub alert type field on alert sub type change
	const clearSubAlertType = React.useCallback(() => {
		if (selectedAlertSubtype) {
			setValue("customSubAlertType", "")
			return
		}
	}, [selectedAlertSubtype, setValue])
	React.useEffect(() => {
		clearSubAlertType()
	}, [clearSubAlertType])

	// clear all other fields on alert type change
	const clearOtherFields = React.useCallback(() => {
		if (selectedAlertType) {
			setValue("customSubAlertType", "")
			setValue("alertSubtype", [])
			return
		}
	}, [selectedAlertType, setValue])
	React.useEffect(() => {
		clearOtherFields()
	}, [clearOtherFields])

	// on archive toggle
	React.useEffect(() => {
		if (toggleAlertError || !toggleAlertPayload || togglingAlert) return
		setTriggerQuery(true)
	}, [toggleAlertPayload, togglingAlert, toggleAlertError])

	// on alert save/add
	React.useEffect(() => {
		if (saveAlertError || !addAlertPayload || savingAlert) return
		setTriggerQuery(true)
	}, [addAlertPayload, savingAlert, saveAlertError])

	// set default alert
	React.useEffect(() => {
		if (alertTypeMany.error || !alertTypeMany.payload) return
		setAlertTypeList(alertTypeMany.payload)
	}, [alertTypeMany.payload, alertTypeMany.error])

	const otherAlertTypeSelected = selectedAlertType && selectedAlertType[0] && selectedAlertType[0].name === "Other"
	const otherAlertSubTypeSelected = selectedAlertSubtype && selectedAlertSubtype[0] && selectedAlertSubtype[0].name === "Other"
	const loading = savingAlert || togglingAlert || alertTypeMany.loading

	return (
		<div className={containerStyle}>
			{alertData && (
				<ZenArchiveModal
					open={true}
					onClose={() => setAlertData(undefined)}
					loading={togglingAlert}
					message={"Alert"}
					restoreMode={alertData.deletedAt ? true : false}
					confirmArchive={async () => {
						if (alertData) {
							await onArchive(alertData)
							setAlertData(undefined)
						}
					}}
				/>
			)}
			<ResponsiveGridLayout style={{ width: "100%" }} layouts={layouts}>
				<div key="alert-list">
					<ZenCard className={listStyle}>
						<AlertsList
							clientID={clientID}
							search={search}
							offset={offset}
							filter={filter}
							setOffset={setOffset}
							headerOptions={<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} alignRight />}
							onArchive={setAlertData}
							triggerQuery={triggerQuery}
							resetTriggerQuery={() => setTriggerQuery(false)}
							showActionButton
						/>
					</ZenCard>
				</div>
				<div key="add-alert">
					<ZenCard className={addAlertStyle}>
						<LabelMedium>Add Alert</LabelMedium>
						<Divider />
						<ZenSelect
							label="Alert Type"
							formName="alertType"
							formRef={control}
							options={alertTypeList}
							isLoading={alertTypeMany.loading}
							labelKey="name"
							actionOnChange={(v) => {
								if (v.length === 0) {
									setAlertSubtypeList([])
									setValue("alertSubtype", [])
									return
								}
								const alertTypeID = v[0].id?.toString()
								if (!alertTypeID) return
								alertSubtypeMany.query(alertTypeID).then((resp) => {
									setAlertSubtypeList([{ id: "Other", name: "Other" }])
									if (resp.error || !resp.payload) return
									setAlertSubtypeList([...resp.payload, { id: "Other", name: "Other" }])
								})
							}}
							inputError={errors.alertType}
							formRules={{
								validate: {
									required: (v: Value) => (!!v && v.length > 0) || "alert type is required",
								},
							}}
						/>
						{otherAlertTypeSelected && (
							<ZenInput placeholder="Enter Custom Alert Type" nameRef="customAlertType" inputError={errors.customSubAlertType} formRef={control} required />
						)}

						{!otherAlertTypeSelected && (
							<ZenSelect
								label="Alert Subtype"
								formName="alertSubtype"
								formRef={control}
								options={alertSubtypeList}
								isLoading={alertSubtypeMany.loading}
								labelKey="name"
								inputError={errors.alertSubtype}
								formRules={{
									validate: {
										required: (v: Value) => (!!v && v.length > 0) || "alert subtype is required",
									},
								}}
							/>
						)}

						{(otherAlertSubTypeSelected || otherAlertTypeSelected) && (
							<ZenInput
								placeholder="Enter Custom Sub Alert Type"
								nameRef="customSubAlertType"
								inputError={errors.customSubAlertType}
								formRef={control}
								required
							/>
						)}
						<ZenTextArea
							label="Provide details and content of the alert"
							nameRef="content"
							initialHeight={300}
							formRef={control}
							inputError={errors.content}
							formRules={{ required: "Content is required" }}
						/>
						<div className={footerStyle}>
							<ZenButton onClick={onSaveAlert} isLoading={savingAlert} disabled={loading}>
								Save Alert
							</ZenButton>
						</div>
						{toggleAlertError && <ErrorNotification messageOrPayload={toggleAlertPayload} />}
						{alertTypeMany.error && <ErrorNotification messageOrPayload={alertTypeMany.payload} closeable />}
						{saveAlertError && addAlertPayload && <ErrorNotification messageOrPayload={addAlertPayload} closeable />}
					</ZenCard>
				</div>
			</ResponsiveGridLayout>
		</div>
	)
}

const layouts = {
	lg: [
		{
			w: 7,
			h: 6,
			x: 0,
			y: 0,
			i: "alert-list",
			moved: false,
			static: true,
		},
		{
			w: 5,
			h: 6,
			x: 7,
			y: 0,
			i: "add-alert",
			moved: false,
			static: true,
		},
	],
	md: [
		{
			w: 6,
			h: 5,
			x: 0,
			y: 0,
			i: "alert-list",
			moved: false,
			static: true,
		},
		{
			w: 4,
			h: 5,
			x: 6,
			y: 0,
			i: "add-alert",
			moved: false,
			static: true,
		},
	],
	sm: [
		{
			w: 6,
			h: 5,
			x: 0,
			y: 3,
			i: "alert-list",
			moved: false,
			static: true,
		},
		{
			w: 6,
			h: 5,
			x: 0,
			y: 0,
			i: "add-alert",
			moved: false,
			static: true,
		},
	],
}
