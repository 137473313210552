import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge } from "baseui/typography"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { IntakeType, SupportAllocationAdult, SupportAllocationYouth } from "../../../../types/enums"
import { BasicName } from "../../../../types/types"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ZenCheckList } from "../../../zenComponents/zenCheckboxList"
import { ZenInput, ZenTextArea } from "../../../zenComponents/zenInput"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"
import { IntakeScrollDiv } from "../intakeComponent"
import { CommonIntakeFormData } from "./detail"

export const Supports = (props: CommonIntakeFormData) => {
	const { clientID, intakeType, formID, isReadOnly } = props
	const history = useHistory()

	const { control, getValues } = useFormContext()

	const [allocationOptions] = React.useState<BasicName[]>(
		intakeType === IntakeType.Adult
			? Object.entries(SupportAllocationAdult).map<BasicName>((sa) => ({ id: sa[0], name: sa[1] }))
			: Object.entries(SupportAllocationYouth).map<BasicName>((sa) => ({ id: sa[0], name: sa[1] })),
	)

	const [css] = useStyletron()
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		width: "100%",
		height: "95%",
	})
	const subForm = css({
		padding: "20px",
	})
	const noteLabel = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "10px",
	})

	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<LabelLarge>Support</LabelLarge>
					<IntakeScrollDiv>
						<div className={subForm}>
							<ZenInput disabled={isReadOnly} label="Informal Support" nameRef="informalSupport" formRef={control} placeholder="Friends or family" />
							<ZenInput
								disabled={isReadOnly}
								label="Formal Support"
								nameRef="formalSupport"
								formRef={control}
								placeholder="Mainstream services i.e. counsellor/psychologist"
							/>
							{intakeType === IntakeType.Adult && (
								<ZenTextArea
									disabled={isReadOnly}
									label="Work and/or Education"
									nameRef="workAndOrEducation"
									formRef={control}
									placeholder="Satisfaction/Performance/Attendance"
									initialHeight={180}
									getValues={getValues}
								/>
							)}
						</div>
						<LabelLarge>Strengths</LabelLarge>
						<div className={subForm}>
							{intakeType === IntakeType.Youth && (
								<>
									<ZenTextArea
										disabled={isReadOnly}
										label="Things the young person is good at/enjoys doing:"
										nameRef="youngPersonGoodAt"
										formRef={control}
										placeholder="Enter detail"
										initialHeight={180}
										getValues={getValues}
									/>
								</>
							)}
							<ZenTextArea
								disabled={isReadOnly}
								label="Protective Factors"
								nameRef="protectiveFactors"
								formRef={control}
								placeholder="Current way of coping ie. leisure or relaxation activities, help seeking behaviours, insight, strategies"
								initialHeight={180}
								getValues={getValues}
							/>
						</div>
						<LabelLarge>Support Needs</LabelLarge>
						<div className={subForm}>
							<ZenTextArea
								disabled={isReadOnly}
								label="Identified support needs/client's desired outcomes"
								nameRef="identifiedSupport"
								formRef={control}
								placeholder="Enter details"
								initialHeight={180}
								getValues={getValues}
							/>
							<ZenCheckList disabled={isReadOnly} formRef={control} label="Support Allocation" list={allocationOptions} maxHeight="180px" overflow="auto" />
							{intakeType === IntakeType.Adult && (
								<ZenTextArea
									disabled={isReadOnly}
									label="Appointment details"
									nameRef="appointmentDetail"
									formRef={control}
									placeholder="Enter details"
									initialHeight={180}
									getValues={getValues}
								/>
							)}
						</div>
						<div className={noteLabel}>
							<LabelLarge>Additional Comments</LabelLarge>
						</div>
						<ZenTextArea getValues={getValues} disabled={isReadOnly} formRef={control} nameRef="additionalSupportComment" fontSize={13} />
					</IntakeScrollDiv>
				</div>
				{!isReadOnly && (
					<CancelAndSaveButtons
						cancelLabel="Back"
						cancelFn={() => history.goBack()}
						saveLabel="Next"
						saveFn={() => history.push(intakeURLParser(`/portal/clients/${clientID}/intake/${intakeType.toLowerCase()}/actionPlan`, formID))}
					/>
				)}
			</form>
		</div>
	)
}
