import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Alert } from "baseui/icon"
import { Modal, ModalBody, ModalHeader } from "baseui/modal"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import { EmptyPrompt } from "components/emptyPrompt"
import { QueryResponse, useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { RouteComponentProps, useHistory } from "react-router-dom"

import { Divider, ZenCard } from "../../components/common"
import { ErrorNotification } from "../../components/errorBox"
import { ListTable } from "../../components/listTable"
import { Loading } from "../../components/loading"
import { SessionInfo } from "../../components/sessions/manage/sessionInfo"
import { PostSessionDetail } from "../../components/sessions/manage/sessionPostDetail"
import { RevisionListModal, SessionAddNoteModal, SessionNoteCard } from "../../components/sessions/sessionNoteComponent"
import { TravelForm } from "../../components/travels/travelForm"
import { TravelTable } from "../../components/travels/travelTable"
import { ZenButton } from "../../components/zenComponents/zenButtons"
import { ZenModal } from "../../components/zenComponents/zenModal"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { friendlyTime } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { Note, Session, SessionsNote, Travel } from "../../types/types"
import { StatefulTooltip } from "baseui/tooltip"
import { ZenTextArea } from "../../components/zenComponents/zenInput"
import { useForm } from "react-hook-form"
import { CancelAndSaveButtons } from "../../components/cancelSaveButtons"
import { useZenToast } from "../../components/zenComponents/useZenToast"

export const SessionView = (props: RouteComponentProps<{ identifier: string }>) => {
	const identifier = props.match.params.identifier
	const { loading, error, query, payload: session } = useQuery<Session>(fetching.query.getSessionByIdentifer(parseInt(identifier)), !!parseInt(identifier))
	const { query: noteQuery, loading: noteQueryLoading } = useParameterizedQuery<SessionsNote[]>(fetching.query.getSessionNotes)
	const memoNoteQuery = React.useMemo(
		() => (id: string) => {
			// fetch session notes
			noteQuery(id).then((resp) => {
				if (resp.error || !resp.payload) return
				setNotes(resp.payload)
			})
		},
		[noteQuery],
	)
	React.useEffect(() => {
		if (error || !session) return
		memoNoteQuery(session.id)
	}, [session, error, memoNoteQuery])

	const scrollDiv = React.useRef<HTMLDivElement>(null)
	const [notes, setNotes] = React.useState<SessionsNote[]>([])
	const [openAddNoteModal, setOpenAddNoteModal] = React.useState<boolean>(false)
	React.useEffect(() => {
		if (!scrollDiv.current) return
		scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight
	}, [notes?.length])

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexWrap: "wrap",
		height: "100%",
		width: "100%",
		overflow: "auto",
	})
	const firstColumn = css({
		width: "100%",
		maxWidth: "500px",
		height: "95%",
		display: "flex",
		flexDirection: "column",
		marginTop: "2px",
		marginBottom: "2px",
		marginLeft: "2px",
		marginRight: "25px",

		[`@media only screen and (max-width: 1367px)`]: {
			marginBottom: "10px",
			maxWidth: "unset",
		},
	})
	const column = css({
		width: "100%",
		maxWidth: "500px",
		height: "95%",
		display: "flex",
		flexDirection: "column",
		marginTop: "2px",
		marginBottom: "2px",
		marginLeft: "2px",
		marginRight: "25px",
		opacity: session?.deletedAt ? ".5" : 1,

		[`@media only screen and (max-width: 1367px)`]: {
			marginBottom: "10px",
			maxWidth: "unset",
		},
	})
	const noteList = css({
		height: "100%",
		maxHeight: "100%",
		marginTop: "15px",
		overflowY: "auto",
		overflowX: "hidden",
	})
	const noteTitle = css({
		display: "flex",
		alignItems: "center",
	})
	const addNoteButton = css({
		marginLeft: "10px",
		cursor: session?.deletedAt ? "" : "pointer",
	})
	const noteCard = css({
		pointerEvents: session?.deletedAt ? "none" : "unset",
	})

	if (loading || !session) return <Loading />
	if (error) return <ErrorNotification messageOrPayload={session} />

	return (
		<div className={container}>
			<div key="session-info" className={firstColumn}>
				<SessionInfo {...session} refetch={query} triggerQuerySessionList={() => query()} onViewPage />
				<Divider />
				<PreSessionNote sessionID={session.id} note={session.preSessionNote?.note} />
			</div>

			{!session.isNonBillable && (
				<div key="post-session-detail" className={column}>
					<PostSessionDetail {...session} refetchCurrentSession={query} triggerQuerySessionList={() => query()} />
					<Divider style={{ backgroundColor: "transparent" }} />
					<SessionTravelList emptyPrompt={<div />} session={session} />
				</div>
			)}

			<div key="notes-list" className={column}>
				{!session.isNonBillable && (
					<ZenCard style={{ minHeight: 0 }}>
						<div className={noteTitle}>
							<LabelMedium>Session Notes</LabelMedium>
							<div
								className={addNoteButton}
								onClick={() => {
									if (session.deletedAt) {
										return
									}
									setOpenAddNoteModal(true)
								}}
							>
								<FontAwesomeIcon icon={["fal", "plus-circle"]} />
							</div>
						</div>
						<div className={noteList} ref={scrollDiv}>
							{noteQueryLoading && <Loading />}
							{notes &&
								notes.length > 0 &&
								notes.map((n, i) => (
									<div key={i} className={noteCard}>
										<SessionNoteCard key={n.note.id} {...n} />
									</div>
								))}
						</div>
						{openAddNoteModal && <SessionAddNoteModal session={session} isOpen={openAddNoteModal} setIsOpen={setOpenAddNoteModal} setNotes={setNotes} />}
					</ZenCard>
				)}
			</div>
		</div>
	)
}

export const SessionViewBasic = (props: {
	identifier: string
	inTimesheet?: boolean
	refetchTimesheet: () => void
	setSelectedSession: (value: React.SetStateAction<Session | null>) => void
}) => {
	// Limited use view used in reports/timesheets
	// select a time sheet and then select a line item
	const identifier = props.identifier
	const { payload, loading, error, errorObject, query } = useQuery<Session>(fetching.query.getSessionByIdentifer(parseInt(identifier)), false)
	const [, setSessionListQueryTrigger] = React.useState(false) // use for triggering refetch session list

	React.useEffect(() => {
		if (isNaN(parseInt(identifier))) return
		query()
	}, [identifier, query])

	const [css] = useStyletron()
	const container = css({
		display: "flex",
		width: "100%",
		maxHeight: "100%",
		justifyContent: "space-between",
		overflow: "auto",
	})

	if (loading) return <Loading />
	if (!payload || error || errorObject) return <ErrorNotification messageOrPayload={payload} />
	return (
		<div className={container}>
			<SessionInfo
				refetchTimesheet={props.refetchTimesheet}
				{...payload}
				inTimesheet
				refetch={query}
				triggerQuerySessionList={setSessionListQueryTrigger}
				setSelectedSession={props.setSelectedSession}
			/>
		</div>
	)
}

interface SessionTravelListProps {
	session: Session
	emptyPrompt?: React.ReactNode
}
const SessionTravelList = (props: SessionTravelListProps) => {
	const { session } = props
	const history = useHistory()
	const isNonNDIASession = session.sessionFundingSources.length > 0 && session.sessionFundingSources[0].fundingSource.label !== "NDIA"
	const canTravel = session.canTransportClients.length > 0 || session.canTravelClients.length > 0 || isNonNDIASession

	const [css] = useStyletron()
	const container = css({
		flex: "4 !important",
		maxHeight: "100%",
		overflow: "auto",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	const addNoteButton = css({
		marginLeft: "10px",
		cursor: "pointer",
	})
	const centered = css({
		height: "100%",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	})

	const [travels, setTravels] = React.useState<Travel[]>([])
	const sessionTravelsGet = useQuery(fetching.query.sessionTravelsGet({ sessionID: session.id, activeOnly: true }), canTravel)

	const [openTravelModal, setOpenTravelModal] = React.useState(false)
	const [openTravelAllModal, setOpenTravelAllModal] = React.useState(false)
	const { timezone } = PortalContainer.useContainer()

	React.useEffect(() => {
		if (sessionTravelsGet.error || !sessionTravelsGet.payload) return
		setTravels(sessionTravelsGet.payload)
	}, [sessionTravelsGet.payload, sessionTravelsGet.error])

	return (
		<ZenCard className={container}>
			<div className={group} style={{ justifyContent: "space-between" }}>
				<div className={group}>
					<LabelLarge>Travels</LabelLarge>
					{canTravel && (
						<div className={addNoteButton} onClick={() => setOpenTravelModal(true)}>
							<FontAwesomeIcon icon={["fal", "plus-circle"]} />
						</div>
					)}
				</div>
				<div>
					{canTravel && travels && travels.length > 0 && (
						<ZenButton disabled={!canTravel} onClick={() => setOpenTravelModal(true)}>
							Add Travel
						</ZenButton>
					)}
					<ZenButton disabled={!canTravel} onClick={() => setOpenTravelAllModal(true)}>
						View All
					</ZenButton>
				</div>
			</div>
			{!canTravel && (
				<div className={centered}>
					<LabelSmall color={ZenTheme.colors.primaryGrey}>
						This NDIS session does not have a appropriate line items or allocations to assign a travel
					</LabelSmall>
				</div>
			)}
			{canTravel && (
				<>
					<Divider style={{ backgroundColor: "transparent" }} />
					<ListTable
						emptyPrompt={
							<div className={centered}>
								<EmptyPrompt
									style={{
										color: "rgba(0,0,0,0.3)",
										fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
									}}
									icon={<Alert size={32} />}
									title={"No Results"}
									actions={
										<ZenButton marginTop="5%" disabled={!canTravel} onClick={() => setOpenTravelModal(true)}>
											Add Travel
										</ZenButton>
									}
									titleSize="s"
								/>
							</div>
						}
						isLoading={sessionTravelsGet.loading}
						rows={travels}
						onRowClick={(row: Travel) => history.push(`/portal/travels/${row.id}`)}
						columns={[
							{
								id: "",
								header: "Type",
								resolver: (row: Travel) => row.travelType.label,
								sortable: false,
							},
							{
								id: "",
								header: "Start",
								resolver: (row: Travel) => friendlyTime(timezone, row.startTime),
								sortable: false,
							},
							{
								id: "",
								header: "End",
								resolver: (row: Travel) => friendlyTime(timezone, row.endTime),
								sortable: false,
							},
						]}
					/>
				</>
			)}
			{openTravelModal && <AddTravelModal session={session} refetchTravels={sessionTravelsGet.query} open={openTravelModal} setOpen={setOpenTravelModal} />}
			<TravelAllModal open={openTravelAllModal} setOpen={setOpenTravelAllModal} sessionID={session.id} />
		</ZenCard>
	)
}

interface TravelAllModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	sessionID: string
}
const TravelAllModal = (props: TravelAllModalProps) => {
	const { open, setOpen, sessionID } = props
	const [css] = useStyletron()
	const container = css({
		height: "100%",
		minWidth: "500px",
		maxHeight: "80vh",
		overflowX: "hidden",
		overflowY: "auto",
	})
	return (
		<Modal isOpen={open} onClose={() => setOpen(false)} role="dialog" size="auto" unstable_ModalBackdropScroll={true}>
			<ModalHeader>
				<LabelLarge>Travels</LabelLarge>
			</ModalHeader>
			<ModalBody>
				<div className={container}>
					<TravelTable hasPagination sessionID={sessionID} />
				</div>
			</ModalBody>
		</Modal>
	)
}

interface AddTravelModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	session: Session
	refetchTravels: () => Promise<QueryResponse<Travel[]>>
}
const AddTravelModal = (props: AddTravelModalProps) => {
	const { open, setOpen, session, refetchTravels } = props
	const [css] = useStyletron()
	const onTravelCreateComplete = async () => {
		const resp = await refetchTravels()
		if (resp.error || !resp.payload) return
		setOpen(false)
	}

	const container = css({
		height: "80vh",
		minWidth: "500px",
		maxHeight: "80vh",
		overflowX: "hidden",
		overflowY: "auto",
	})
	return (
		<ZenModal isOpen={open} onClose={() => setOpen(false)} role="dialog" size="auto">
			<ModalHeader>
				<LabelLarge>Add Travel Log</LabelLarge>
			</ModalHeader>
			<ModalBody>
				<div className={container}>
					<TravelForm session={session} onCancel={() => setOpen(false)} onSuccess={onTravelCreateComplete} />
				</div>
			</ModalBody>
		</ZenModal>
	)
}

interface PreSessionNoteProps {
	note?: Note
	sessionID: string
}
const PreSessionNote = (props: PreSessionNoteProps) => {
	const [css] = useStyletron()
	const { note, sessionID } = props
	const { control, errors, handleSubmit, reset } = useForm({
		defaultValues: {
			newNote: note?.revisions[0].content || "",
		},
	})
	const { showToast } = useZenToast()
	const [poster, setPoster] = React.useState((note && note.revisions.length > 0 && note.revisions[0].poster) || undefined)
	const [content, setContent] = React.useState<string>((note && note.revisions && note.revisions.length > 0 && note.revisions[0].content) || "")
	const [revisionNotes, setRevisionNotes] = React.useState<Note[]>(note?.revisions || [])
	const [editMode, setEditMode] = React.useState<boolean>(false)
	const [showRevisionList, setShowRevisionList] = React.useState<boolean>(false)
	const container = css({
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		padding: "15px",
		flex: "unset !important",
		maxHeight: "225px",
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	const iconStyle = css({
		marginLeft: "8px",
		cursor: "pointer",
	})
	const contentContainer = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		maxHeight: "100%",
		overflow: "auto",
	})

	const { mutate, loading } = useMutation(fetching.mutation.sessionPreSessionNoteUpdate)
	const onSubmit = async (formData: any) => {
		// do not trigger fetching, if the content is the same
		if (content === formData.newNote) {
			setEditMode(false)
			return
		}
		const resp = await mutate({
			sessionID,
			content: formData.newNote,
		})

		if (resp.error || !resp.payload) return
		showToast("Session Note updated successfully.", "positive")

		const newList = [...revisionNotes]
		newList.unshift(resp.payload)
		setPoster(resp.payload.poster)
		setRevisionNotes(newList)
		setContent(resp.payload.content)
		reset({
			newNote: formData.newNote,
		})
		setEditMode(false)
	}

	const exitEdit = () => {
		reset({
			newNote: note?.revisions[0].content || "",
		})
		setEditMode(false)
	}

	const displayContent = () => {
		if (editMode) {
			return (
				<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<ZenTextArea formRef={control} nameRef="newNote" inputError={errors.newNote} height="100%" formRules={{ required: "can not be empty" }} />
					<CancelAndSaveButtons cancelFn={exitEdit} isLoading={loading} />
				</form>
			)
		}
		return (
			<div className={contentContainer}>
				<LabelSmall
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
							},
						},
					}}
				>
					{content || "N/A"}
				</LabelSmall>
			</div>
		)
	}
	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>{`Pre Session Note ${poster ? `(From ${poster.firstName} ${poster.lastName})` : ""}`}</LabelMedium>
				<div className={group}>
					<StatefulTooltip content={() => <div>History</div>} placement="top" returnFocus autoFocus>
						<div className={iconStyle} onClick={() => setShowRevisionList(true)}>
							<FontAwesomeIcon icon={["fal", "history"]} />
						</div>
					</StatefulTooltip>
					<div className={iconStyle} onClick={() => setEditMode(true)}>
						<FontAwesomeIcon icon={["fal", "edit"]} />
					</div>
				</div>
			</div>
			<Divider style={{ backgroundColor: "transparent" }} />
			{displayContent()}
			<RevisionListModal revisions={revisionNotes} isOpen={showRevisionList} setIsOpen={setShowRevisionList} />
		</ZenCard>
	)
}
