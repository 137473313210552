import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { ZenButton } from "components/zenComponents/zenButtons"
import { SelectedItemCard, ZenClientSelect } from "components/zenComponents/zenSelectBox"
import { useForm } from "react-hook-form"
import { ZenTheme } from "themeOverrides"
import { Client } from "types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { ZenInput } from "../../zenComponents/zenInput"
import { GroupAppointmentFormProps } from "./groupSessionBaseForm"

export const GroupSessionAttendeeInfoForm = (props: GroupAppointmentFormProps) => {
	const { data, setData, changePage } = props
	const { setValue, control, getValues, errors, trigger } = useForm()
	const [attendees, setAttendees] = React.useState<Client[]>(data.attendees || [])

	const orgID = (data.organisation && data.organisation[0].id?.toString()) || ""
	const [css] = useStyletron()

	// set initial values
	const loadInitialValues = React.useCallback(() => {
		setValue("approxNumberOfATSI", data.attendeeNumber || "0")
		setValue("approxNumberOfATSI", data.ATslNumber || "0")
		setValue("approxNumberOfCALD", data.CaLDNumber || "0")
	}, [data.ATslNumber, data.CaLDNumber, data.attendeeNumber, setValue])
	React.useEffect(() => {
		loadInitialValues()
	}, [loadInitialValues])

	const back = () => {
		saveData()
		changePage(-1)
	}
	const submit = async () => {
		const isValid = await trigger()
		if (!isValid) return
		saveData()
		changePage(1)
	}
	const saveData = () => {
		setData((data) => ({
			...data,
			attendees,
			attendeeNumber: getValues().numberOfAttendees || 0,
			ATslNumber: getValues().approxNumberOfATSI || 0,
			CaLDNumber: getValues().approxNumberOfCALD || 0,
		}))
	}
	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "50%",
		[`@media only screen and (max-width: 1500px)`]: {
			width: "90%",
		},
	})
	const unidentifiedMask = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
	})

	return (
		<div className={container}>
			<LabelLarge>Attendee Information</LabelLarge>
			<div className={unidentifiedMask}>
				<div>
					<AttendeeBlock attendees={attendees} setAttendees={setAttendees} excludedID={attendees.map((a) => a.id).concat(orgID)} />
				</div>

				<ZenInput
					formRef={control}
					nameRef="numberOfAttendees"
					label="Total Number of Attendees"
					type={"number"}
					required
					inputError={errors.numberOfAttendees}
				/>

				<ZenInput
					formRef={control}
					nameRef="approxNumberOfATSI"
					label="Approx Number of ATSI"
					type={"number"}
					required
					inputError={errors.approxNumberOfATSI}
					formRules={{
						validate: {
							greaterThanZero: (value: string) => (!isNaN(parseInt(value)) && parseInt(value) >= 0) || "Approx number of ATSI should not be negative",
							lessThanTotal: (value: string) => {
								const total = getValues().numberOfAttendees ? parseInt(getValues().numberOfAttendees) : 0
								const numberOfCaLD = getValues().approxNumberOfCALD ? parseInt(getValues().approxNumberOfCALD) : 0
								const numberOfATSI = parseInt(value)
								if (total < numberOfCaLD + numberOfATSI) {
									return "The sum of number of ATSI and number of CaLD should be less than the total of attendees"
								}
								return true
							},
						},
					}}
				/>
				<ZenInput
					formRef={control}
					nameRef="approxNumberOfCALD"
					label="Approx Number of CaLD"
					type={"number"}
					required
					inputError={errors.approxNumberOfCALD}
					formRules={{
						validate: {
							greaterThanZero: (value: string) => (!isNaN(parseInt(value)) && parseInt(value) >= 0) || "Approx number of CaLD should not be negative",
							lessThanTotal: (value: string) => {
								const total = getValues().numberOfAttendees ? parseInt(getValues().numberOfAttendees) : 0
								const numberOfCaLD = parseInt(value)
								const numberOfATSI = getValues().approxNumberOfATSI ? parseInt(getValues().approxNumberOfATSI) : 0
								if (total < numberOfCaLD + numberOfATSI) {
									return "The sum of number of ATSI and number of CaLD should be less than the total of attendees"
								}
								return true
							},
						},
					}}
				/>
			</div>
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={back} saveLabel="Next" saveFn={submit} />
		</div>
	)
}

interface AttendeeBlockProps {
	attendees: Client[]
	setAttendees: React.Dispatch<React.SetStateAction<Client[]>>
	excludedID: string[]
}
export const AttendeeBlock = (props: AttendeeBlockProps) => {
	const { attendees, setAttendees, excludedID } = props
	const [css] = useStyletron()
	const { control, handleSubmit, errors, getValues, trigger, setValue } = useForm()

	const addAttendee = async () => {
		const data = getValues()
		const isValid = await trigger(["attendee"])
		if (!isValid || !data || !data.attendee || !data.attendee[0]) return

		// append to the list
		setAttendees(attendees.concat(data.attendee[0]))
		setValue("attendee", [])
	}

	const removeAttendee = (index: number) => {
		const clients = [...attendees]
		clients.splice(index, 1)
		setAttendees(clients)
	}

	const container = css({
		display: "flex",
		flexDirection: "column",
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "10px",
		border: "1px solid " + ZenTheme.colors.primaryGrey,
		marginTop: "8px",
		borderRadius: "3px",
	})

	return (
		<div className={container} onSubmit={handleSubmit(addAttendee)}>
			<ZenClientSelect
				label="Add Attendee"
				placeholder={"Select Attendee (Client)"}
				formName="attendee"
				formRef={control}
				inputError={errors.attendee}
				showAllClients
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "attendee is required",
					},
				}}
				excludedID={excludedID}
				isOrganisation={false}
			/>

			<ZenButton onClick={addAttendee} type={"button"}>
				Add
			</ZenButton>
			<AttendeesTable data={attendees} onDelete={removeAttendee} />
		</div>
	)
}

const AttendeesTable = (props: { data: Client[]; onDelete: (index: number) => void }) => {
	const { data, onDelete } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		marginTop: "15px",
	})
	const cardStyle = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "5px",
	})
	const titleStyle = css({
		width: "65%",
	})
	const deleteIcon = css({
		width: "5%",
		cursor: "pointer",
	})
	return (
		<div className={container}>
			<div className={cardStyle}>
				<div className={titleStyle}>
					<LabelMedium>Attendees ({data.length})</LabelMedium>
				</div>
			</div>

			{data.map((d, i) => (
				<div key={d.id + i} className={cardStyle}>
					<div className={titleStyle}>
						<SelectedItemCard firstName={d.firstName} lastName={d.lastName} avatarUrl={d.avatarURL} />
					</div>
					<div className={deleteIcon} onClick={() => onDelete(i)}>
						<FontAwesomeIcon icon={["fal", "times-circle"]} />
					</div>
				</div>
			))}
		</div>
	)
}
