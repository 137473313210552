import { useStyletron } from "baseui"
import { LabelMedium } from "baseui/typography"
import * as React from "react"
import { ZenTheme } from "../../../themeOverrides"

interface PlaceHolderPanelProps {
	width?: string
	height?: string
	text: string
}

export const PlaceholderPanel = (props: PlaceHolderPanelProps) => {
	const { width, height, text } = props
	const [css] = useStyletron()
	const container = css({
		width: width || "100%",
		height: height || "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: ZenTheme.colors.primaryGrey,
		opacity: 0.5,
	})
	return (
		<div className={container}>
			<LabelMedium color={ZenTheme.colors.lightGrey}>{text}</LabelMedium>
		</div>
	)
}
