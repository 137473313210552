import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Value } from "baseui/select"
import { LabelLarge, LabelMedium } from "baseui/typography"
import { useForm } from "react-hook-form"

import { ZenTheme } from "../../../themeOverrides"
import { AddedWorker } from "../../../types/types"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenCheckbox } from "../../zenComponents/zenCheckboxList"
import { SelectedItemCard, ZenUserSelect } from "../../zenComponents/zenSelectBox"
import { GroupAppointmentFormProps } from "./groupSessionBaseForm"

export const GroupSessionWorkerDetailForm = (props: GroupAppointmentFormProps) => {
	const { data, setData, changePage } = props
	const [css] = useStyletron()
	const [addedWorkers, setAddedWorkers] = React.useState<AddedWorker[]>(data.additionalWorkers || [])
	const [excludedID, setExcludedID] = React.useState<string[]>(data.worker && data.worker.length > 0 && data.worker[0].id ? [data.worker[0].id.toString()] : [])

	React.useEffect(() => {
		let excludeIDList: string[] = []
		const workerID = data.worker && data.worker.length > 0 ? data.worker[0].id?.toString() : undefined
		const additionalWorkerID = addedWorkers.map((aw) => aw.worker.id)
		if (workerID) {
			excludeIDList.push(workerID)
		}
		if (additionalWorkerID.length > 0) {
			excludeIDList = excludeIDList.concat(...additionalWorkerID)
		}
		setExcludedID(excludeIDList)
	}, [addedWorkers, data.worker])

	const back = () => {
		saveData()
		changePage(-1)
	}

	const submit = () => {
		saveData()
		changePage(1)
	}

	const saveData = () => {
		setData((data) => ({
			...data,
			additionalWorkers: addedWorkers,
		}))
	}

	const container = css({
		display: "flex",
		flexDirection: "column",
		width: "50%",
		[`@media only screen and (max-width: 1500px)`]: {
			width: "90%",
		},
	})
	return (
		<div className={container}>
			<LabelLarge>Worker Details</LabelLarge>
			<AddUserBlock worker={data.worker} addedWorkers={addedWorkers} setAddedWorkers={setAddedWorkers} excludedID={excludedID} />
			<CancelAndSaveButtons cancelLabel="Back" cancelFn={() => back()} saveLabel="Next" saveFn={submit} />
		</div>
	)
}

interface AddUserBlockProps {
	worker?: Value
	addedWorkers: AddedWorker[]
	setAddedWorkers: React.Dispatch<React.SetStateAction<AddedWorker[]>>
	excludedID: string[]
}
export const AddUserBlock = (props: AddUserBlockProps) => {
	const { addedWorkers, setAddedWorkers, excludedID, worker } = props
	const [css] = useStyletron()
	const { control, handleSubmit, errors, getValues, trigger, setValue } = useForm()

	const addNewWorker = async () => {
		const isValid = await trigger(["worker"])
		if (!isValid) return

		const data = getValues()
		// append to the list
		setAddedWorkers(addedWorkers.concat({ worker: { ...data.worker[0] }, claimHour: data.claimHours, isBuddyMentor: false }))

		// clear data
		setValue("worker", [])
		setValue("claimHours", false)
	}

	const removeWorker = (index: number) => {
		const workers = [...addedWorkers]
		workers.splice(index, 1)
		setAddedWorkers(workers)
	}

	const container = css({
		display: "flex",
		flexDirection: "column",
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "10px",
		border: "1px solid " + ZenTheme.colors.primaryGrey,
		marginTop: "8px",
		borderRadius: "3px",
	})

	return (
		<div className={container} onSubmit={handleSubmit(addNewWorker)}>
			<ZenUserSelect
				label="Worker"
				formName="worker"
				formRef={control}
				inputError={errors.worker}
				formRules={{
					validate: {
						required: (value: Value) => (!!value && value.length > 0) || "worker is required",
					},
				}}
				excludedID={excludedID}
			/>

			<ZenCheckbox label="Claim hours" formName="claimHours" formRef={control} labelPlacement="left" marginBottom="10px" />
			<ZenButton onClick={addNewWorker} type="button">
				Add Worker
			</ZenButton>
			<AddedWorkerTable worker={worker} data={addedWorkers} onDelete={removeWorker} />
		</div>
	)
}

const AddedWorkerTable = (props: { data: AddedWorker[]; onDelete: (index: number) => void; worker?: Value }) => {
	const { data, onDelete, worker } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		flexDirection: "column",
		marginTop: "15px",
	})
	const cardStyle = css({
		display: "flex",
		alignItems: "center",
		marginBottom: "5px",
	})
	const titleStyle = css({
		width: "65%",
	})
	const claimHourStyle = css({
		width: "30%",
	})
	const deleteIcon = css({
		width: "5%",
		cursor: "pointer",
	})
	return (
		<div className={container}>
			<div className={cardStyle}>
				<div className={titleStyle}>
					<LabelMedium>Workers</LabelMedium>
				</div>
				<div className={claimHourStyle}>
					<LabelMedium>Claim Hours?</LabelMedium>
				</div>
			</div>
			{!!worker && worker.length > 0 && (
				<div className={cardStyle}>
					<div className={titleStyle}>
						<SelectedItemCard firstName={worker[0].firstName} lastName={worker[0].lastName} avatarUrl={worker[0].avatarURL} />
					</div>
					<div className={claimHourStyle}>
						<LabelMedium>Yes</LabelMedium>
					</div>
				</div>
			)}
			{data.map((d, i) => (
				<div key={d.worker.id + i} className={cardStyle}>
					<div className={titleStyle}>
						<SelectedItemCard firstName={d.worker.firstName} lastName={d.worker.lastName} avatarUrl={d.worker.avatarURL} />
					</div>
					<div className={claimHourStyle}>
						<LabelMedium>{d.claimHour ? "Yes" : "No"}</LabelMedium>
					</div>
					<div className={deleteIcon} onClick={() => onDelete(i)}>
						<FontAwesomeIcon icon={["fal", "times-circle"]} />
					</div>
				</div>
			))}
		</div>
	)
}
