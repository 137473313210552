import { ErrorObject } from "../types/types"

export const genericError = "Error: An issue occurred."

export function getError(errorObject: any): ErrorObject {
	try {
		return errorObject as ErrorObject
	} catch (error) {
		// if parsing failed, check if string, if string pass that as error message
		if (typeof errorObject === "string" || errorObject instanceof String) {
			const newError: ErrorObject = {
				message: errorObject as string,
				error_code: "00002", // generic error code if parsing error fails but object was a string
			}
			return newError
		} else {
			const newError: ErrorObject = {
				message: "Error: An issue occurred.", // this "shouldn't" be hit
				error_code: "00003", // generic error code if parsing error fails and object was not a string
			}
			return newError
		}
	}
}

export function getErrorMessage(errorObject: any): string {
	//check if string, if string pass that as error message
	if (typeof errorObject === "string" || errorObject instanceof String) {
		return ("00004: " + errorObject) as string
	}
	// if not string try parse as error object, if not return default message
	try {
		const errObj = errorObject as ErrorObject
		return `${errObj.error_code}: ${errObj.message}`
	} catch (error) {
		return "00005: Error: An issue occurred."
	}
}
