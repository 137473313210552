import { Layout } from "react-grid-layout"
import { storeKey } from "../layout"

export const storageKey = (breakpoint: string) => `${storeKey}-layout-${breakpoint}-v3`

export const panels = ["worker-session", "claims", "assigned", "worker-travel", "alerts", "supervisors"]

const xxs: Layout[] = [
	{ w: 2, h: 8, x: 0, y: 0, i: "worker-session", minW: 4, minH: 4, static: true },
	{ w: 2, h: 9, x: 0, y: 41, i: "claims", minW: 4, minH: 4, static: true },
	{ w: 2, h: 4, x: 0, y: 65, i: "supervisors", static: true },
	{ w: 2, h: 9, x: 0, y: 32, i: "assigned", minW: 4, minH: 4, static: true },
	{ w: 2, h: 15, x: 0, y: 50, i: "worker-travel", minW: 4, minH: 4, static: true },
	{ w: 2, h: 20, x: 0, y: 8, i: "alerts", minW: 4, minH: 4, static: true },
]

const xs: Layout[] = [
	{ w: 4, h: 9, x: 0, y: 0, i: "worker-session", minW: 4, minH: 4, static: true },
	{ w: 4, h: 9, x: 0, y: 46, i: "claims", minW: 4, minH: 4, static: true },
	{ w: 4, h: 3, x: 0, y: 71, i: "supervisors", static: true },
	{ w: 4, h: 9, x: 0, y: 33, i: "assigned", minW: 4, minH: 4, static: true },
	{ w: 4, h: 15, x: 0, y: 56, i: "worker-travel", minW: 4, minH: 4, static: true },
	{ w: 4, h: 20, x: 0, y: 9, i: "alerts", minW: 4, minH: 4, static: true },
]

const sm: Layout[] = [
	{ w: 6, h: 8, x: 0, y: 0, i: "worker-session", minW: 4, minH: 4, static: true },
	{ w: 6, h: 9, x: 0, y: 41, i: "claims", minW: 4, minH: 4, static: true },
	{ w: 6, h: 4, x: 0, y: 65, i: "supervisors", static: true },
	{ w: 6, h: 9, x: 0, y: 8, i: "assigned", minW: 4, minH: 4, static: true },
	{ w: 6, h: 15, x: 0, y: 50, i: "worker-travel", minW: 4, minH: 4, static: true },
	{ w: 6, h: 20, x: 0, y: 17, i: "alerts", minW: 4, minH: 4, static: true },
]

const md: Layout[] = [
	{ w: 7, h: 9, x: 3, y: 3, i: "worker-session", minW: 2, minH: 2, static: true },
	{ w: 7, h: 6, x: 3, y: 20, i: "claims", minW: 2, minH: 2, static: true },
	{ w: 7, h: 3, x: 3, y: 0, i: "supervisors", static: true },
	{ w: 7, h: 8, x: 3, y: 12, i: "assigned", minW: 2, minH: 2, static: true },
	{ w: 10, h: 7, x: 0, y: 26, i: "worker-travel", minW: 2, minH: 2, static: true },
	{ w: 3, h: 23, x: 0, y: 0, i: "alerts", minW: 2, minH: 2, static: true },
]

const lg: Layout[] = [
	{ w: 4, h: 13, x: 3, y: 0, i: "worker-session", minW: 2, minH: 2, static: true },
	{ w: 5, h: 10, x: 7, y: 3, i: "claims", minW: 2, minH: 2, static: true },
	{ w: 5, h: 3, x: 7, y: 0, i: "supervisors", static: true },
	{ w: 3, h: 9, x: 0, y: 0, i: "assigned", minW: 2, minH: 2, static: true },
	{ w: 9, h: 10, x: 3, y: 13, i: "worker-travel", minW: 2, minH: 2, static: true },
	{ w: 3, h: 14, x: 0, y: 9, i: "alerts", minW: 2, minH: 2, static: true },
]

export const layouts = { xxs, xs, sm, md, lg }
