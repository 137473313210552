import * as React from "react"
import { useStyletron } from "baseui"
import { OnChangeParams, Select, Value } from "baseui/select"
import { LabelMedium } from "baseui/typography"
import moment from "moment-timezone"
import { useHistory } from "react-router"

import { RangeOption, UserDetail } from "../../types/types"
import { ZenCard } from "../common"
import { TravelTable } from "../travels/travelTable"
import { ZenButton } from "../zenComponents/zenButtons"
import { routes } from "routes"
import { AuthContainer } from "../../controllers/auth"

export const WorkerTravel = (props: UserDetail) => {
	const { id } = props
	const { currentUser } = AuthContainer.useContainer()
	const history = useHistory()
	const [css] = useStyletron()
	const [dateOption, setDateOption] = React.useState<Value>([{ label: RangeOption.thisWeek, id: RangeOption.thisWeek }])
	const [startTime, setStartTime] = React.useState<Date>(moment().startOf("week").toDate())
	const [endTime, setEndTime] = React.useState<Date>(moment().endOf("week").toDate())
	const selectDateOption = (params: OnChangeParams) => {
		const value = params.value
		switch (value[0].id) {
			case RangeOption.thisWeek:
				setStartTime(moment().startOf("week").toDate())
				setEndTime(moment().endOf("week").toDate())
				break
			case RangeOption.thisMonth:
				setStartTime(moment().startOf("month").toDate())
				setEndTime(moment().endOf("month").toDate())
				break
		}
		setDateOption(params.value)
	}
	const container = css({
		height: "100%",
		width: "100%",
		padding: "15px",
		maxWidth: "unset !important",
	})

	const title = css({
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "15px",
		alignItems: "center",
	})
	const functionBar = css({
		display: "flex",
		alignItems: "center",
	})
	const scrollable = css({
		height: "100%",
		minHeight: 0,
	})
	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>Travels</LabelMedium>

				<div className={functionBar}>
					<Select
						options={Object.values(RangeOption).map((v) => ({
							label: v,
							id: v,
						}))}
						value={dateOption}
						clearable={false}
						onChange={selectDateOption}
						overrides={{
							Root: {
								style: {
									width: "150px",
									height: "fit-content",
									boxShadow: "1px 0px 8px 1px rgba(0,0,0,0.2)",
									marginRight: "15px",
								},
							},
							InputContainer: {
								style: {
									width: "fit-content",
									height: "fit-content",
								},
							},
							ValueContainer: {
								style: {
									paddingTop: 0,
									paddingBottom: 0,
								},
							},
						}}
					/>
					<ZenButton
						onClick={() => {
							if (currentUser?.department.name === "Individualised Services") {
								history.push(routes.travels.createConfirm)
								return
							}
							history.push(routes.travels.create)
						}}
					>
						New
					</ZenButton>
				</div>
			</div>
			<div className={scrollable}>
				<TravelTable hideTopbar workerID={id} canToggleView={false} startTime={startTime} endTime={endTime} height="100%" />
			</div>
		</ZenCard>
	)
}
