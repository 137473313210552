import * as React from "react"
import { NDISItemGroup, NDISItemGroupBase, NDISLineItem, NDISPriceGuide } from "../../../types/types"
import { ZenTheme } from "../../../themeOverrides"
import { useMutation, useParameterizedQuery } from "react-fetching-library"
import { fetching } from "../../../fetching"
import { FilterBy, RateVariant } from "../../../types/enums"
import { SearchAndFilter, ZenCard } from "../../../components/common"
import { snakeToTitle, useDebounce } from "../../../helpers/utils"
import { ListTable, useListTable } from "../../../components/listTable"
import { getErrorMessage } from "../../../helpers/errors"
import { Button, KIND } from "baseui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ZenPagination } from "../../../components/zenComponents/zenPagination"
import { ZenArchiveModal } from "../../../components/zenComponents/zenArchiveDialog"
import { ZenModal } from "../../../components/zenComponents/zenModal"
import { useZenToast } from "../../../components/zenComponents/useZenToast"
import { useStyletron } from "baseui"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenSelect } from "../../../components/zenComponents/zenSelectBox"
import { Option, Value } from "baseui/select"
import { useForm } from "react-hook-form"
import { ErrorNotification } from "../../../components/errorBox"
import { Loading } from "../../../components/loading"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { FieldDisplay } from "../../../components/fieldDisplay"
import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"

export const ItemGroups = (props: { priceGuide?: NDISPriceGuide }) => {
	const { priceGuide } = props

	const [css] = useStyletron()
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		marginBottom: "10px",
		alignItems: "center",
	})

	const { limit, offset, setTotal, total, setOffset, filter, setFilter, search, setSearch } = useListTable({})
	const { showToast } = useZenToast()

	const [archiveItemGroup, setArchiveItemGroup] = React.useState<NDISItemGroup>()
	const [selectedItemGroup, setSelectedItemGroup] = React.useState<NDISItemGroupBase>()
	const [createOpen, setCreateOpen] = React.useState(false)

	const {
		query: itemGroupsQuery,
		loading: itemGroupsLoading,
		payload: itemGroupsPayload,
		error: itemGroupsError,
	} = useParameterizedQuery(fetching.query.getItemGroupsByPriceGuide)
	const {
		payload: toggleArchivePayload,
		loading: toggleArchiveLoading,
		error: toggleArchiveError,
		mutate: toggleArchive,
		reset: toggleArchiveReset,
	} = useMutation(fetching.mutation.itemGroupToggleArchive)

	// re-fetch data with current parameters so page, search etc. is no lost
	const reFetch = React.useCallback(() => {
		if (!priceGuide) return

		let f: string = FilterBy.Active
		if (filter[0] && filter[0].id) {
			f = filter[0].id.toString()
		}
		itemGroupsQuery({ priceGuideID: priceGuide.id, limit: limit, offset: offset, search: search, filterBy: f })
	}, [priceGuide, search, filter, offset, limit, itemGroupsQuery])

	const handleArchiveItemGroup = async () => {
		if (!archiveItemGroup || !priceGuide) return

		const result = await toggleArchive({ id: archiveItemGroup.id })

		if (!result.payload || result.error) return

		showToast(`Item group ${result.payload.deletedAt ? "archived" : "unarchived"} successfully.`, "positive")
		setArchiveItemGroup(undefined)

		reFetch()
	}

	// Run query when selected row, pagination or search changes
	React.useEffect(() => {
		reFetch()
	}, [reFetch])

	// Update total on pagination when line items are fetched
	React.useEffect(() => {
		if (!itemGroupsPayload || itemGroupsError) return
		setTotal(itemGroupsPayload.total)
	}, [itemGroupsPayload, itemGroupsError, setTotal])

	// Reset offset when search value changes
	React.useEffect(() => {
		setOffset(0)
	}, [search, setOffset])

	let itemGroupRows: NDISItemGroup[] = []
	if (itemGroupsPayload && !itemGroupsError) {
		itemGroupRows = itemGroupsPayload.itemGroups
	}

	return (
		<>
			<div className={title}>
				<SearchAndFilter search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} />
				<ZenButton onClick={() => setCreateOpen(true)}>Create Group</ZenButton>
			</div>
			<ListTable
				error={itemGroupsError ? getErrorMessage(itemGroupsPayload) : undefined}
				isLoading={itemGroupsLoading}
				rows={itemGroupRows}
				onRowClick={(row: NDISItemGroup) => setSelectedItemGroup(row)}
				columns={[
					{
						id: "ItemNumber",
						header: "Item Number",
						resolver: (row: NDISItemGroup) => (
							<div>
								{row.standardItemNumber}
								{row.deletedAt && ` [ARCHIVED]`}
							</div>
						),
					},
					{
						id: "ItemName",
						header: "Item Name",
						resolver: (row: NDISItemGroup) => row.standardItemName,
					},
					{
						id: "Type",
						header: "Type",
						resolver: (row: NDISItemGroup) => snakeToTitle(row.standardItemType),
					},
					{
						id: "Actions",
						header: "Actions",
						resolver: (row: NDISItemGroup) => (
							<Button
								kind={KIND.minimal}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setArchiveItemGroup(row)
								}}
							>
								<FontAwesomeIcon
									color={row.deletedAt ? ZenTheme.colors.primaryGreen : ZenTheme.colors.red}
									icon={["fal", row.deletedAt ? "trash-restore-alt" : "trash-alt"]}
								/>
							</Button>
						),
					},
				]}
			/>
			<ZenPagination total={total} limit={limit} offset={offset} setOffset={setOffset} />
			{archiveItemGroup && (
				<ZenArchiveModal
					message={`${archiveItemGroup.standardItemNumber}`}
					open={!!archiveItemGroup}
					confirmArchive={() => {
						handleArchiveItemGroup()
					}}
					onClose={() => {
						setArchiveItemGroup(undefined)
						toggleArchiveReset()
					}}
					restoreMode={!!archiveItemGroup.deletedAt}
					loading={toggleArchiveLoading}
					error={toggleArchiveError}
					payload={toggleArchivePayload}
				/>
			)}

			{selectedItemGroup && priceGuide && (
				<ZenModal isOpen={!!selectedItemGroup} onClose={() => setSelectedItemGroup(undefined)} autoFocus={false}>
					<ItemGroupViewEdit
						itemGroup={selectedItemGroup}
						onSave={(item: NDISItemGroupBase) => {
							reFetch()
							setSelectedItemGroup(item)
						}}
						priceGuide={priceGuide}
					/>
				</ZenModal>
			)}
			{priceGuide && (
				<ZenModal isOpen={createOpen} onClose={() => setCreateOpen(false)} autoFocus={false}>
					<ItemGroupCreate
						onSave={() => {
							reFetch()
							setCreateOpen(false)
							showToast(`Line item group created successfully.`, "positive")
						}}
						onCancel={() => setCreateOpen(false)}
						priceGuide={priceGuide}
					/>
				</ZenModal>
			)}
		</>
	)
}

interface FormData {
	standardItem: Option[]
	saturdayItem: Option[]
	sundayItem: Option[]
	holidayItem: Option[]
	eveningItem: Option[]
}

const ItemGroupViewEdit = (props: { itemGroup: NDISItemGroupBase; onSave: (itemGroup: NDISItemGroupBase) => void; priceGuide: NDISPriceGuide }) => {
	const { itemGroup, onSave, priceGuide } = props
	const [css] = useStyletron()
	const standardItem = css({
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "5px",
		marginLeft: "-5px",
		marginRight: "-5px",
	})

	const { control, handleSubmit, reset } = useForm<FormData>()
	const [editMode, setEditMode] = React.useState(false)

	const { payload: itemGroupPayload, loading: itemGroupLoading, error: itemGroupError, query } = useParameterizedQuery(fetching.query.getItemGroup)
	const { loading, mutate } = useMutation(fetching.mutation.itemGroupUpdate)

	const onSubmit = async (data: FormData) => {
		if (!editMode) {
			setEditMode(true)
			return
		}

		const resp = await mutate({
			id: itemGroup.id,
			input: {
				standardItemID: itemGroup.standardLineID,
				saturdayItemID: data.saturdayItem && data.saturdayItem.length > 0 ? (data.saturdayItem[0].id as string) : undefined,
				sundayItemID: data.sundayItem && data.sundayItem.length > 0 ? (data.sundayItem[0].id as string) : undefined,
				holidayItemID: data.holidayItem && data.holidayItem.length > 0 ? (data.holidayItem[0].id as string) : undefined,
				eveningItemID: data.eveningItem && data.eveningItem.length > 0 ? (data.eveningItem[0].id as string) : undefined,
			},
		})

		if (resp.error || !resp.payload) {
			return
		}

		setEditMode(false)

		onSave(resp.payload)
	}

	const reFetch = React.useCallback(() => {
		query(itemGroup.id)
	}, [itemGroup, query])

	React.useEffect(() => {
		reFetch()
	}, [reFetch])

	React.useEffect(() => {
		if (!itemGroupPayload || itemGroupError) return

		reset({
			standardItem: [itemGroupPayload.standardItem],
			saturdayItem: itemGroupPayload.saturdayItem ? [itemGroupPayload.saturdayItem] : [],
			sundayItem: itemGroupPayload.sundayItem ? [itemGroupPayload.sundayItem] : [],
			holidayItem: itemGroupPayload.holidayItem ? [itemGroupPayload.holidayItem] : [],
			eveningItem: itemGroupPayload.eveningItem ? [itemGroupPayload.eveningItem] : [],
		})
	}, [itemGroupPayload, itemGroupError, reset])

	if (itemGroupLoading) {
		return (
			<ZenCard>
				<LabelLarge marginTop={0} marginBottom={"15px"} marginRight={"50px"}>
					NDIS Line Item Group
				</LabelLarge>
				<Loading />
			</ZenCard>
		)
	}

	return (
		<ZenCard>
			<LabelLarge marginTop={0} marginBottom={"15px"}>
				NDIS Line Item Group
			</LabelLarge>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<div className={standardItem}>
					<LineItem
						label="Standard Item"
						rateVariant={RateVariant.Standard}
						lineItem={itemGroupPayload && itemGroupPayload.standardItem}
						marginTop={"0px"}
						priceGuide={priceGuide}
					/>
				</div>
				<LineItem
					label="Saturday Item"
					rateVariant={RateVariant.Saturday}
					lineItem={itemGroupPayload && itemGroupPayload.saturdayItem}
					isEditMode={editMode}
					formProps={{ formControl: control, formName: "saturdayItem" }}
					priceGuide={priceGuide}
				/>
				<LineItem
					label="Sunday Item"
					rateVariant={RateVariant.Sunday}
					lineItem={itemGroupPayload && itemGroupPayload.sundayItem}
					isEditMode={editMode}
					formProps={{ formControl: control, formName: "sundayItem" }}
					priceGuide={priceGuide}
				/>
				<LineItem
					label="Public Holiday Item"
					rateVariant={RateVariant.Holiday}
					lineItem={itemGroupPayload && itemGroupPayload.holidayItem}
					isEditMode={editMode}
					formProps={{ formControl: control, formName: "holidayItem" }}
					priceGuide={priceGuide}
				/>
				<LineItem
					label="Evening Item"
					rateVariant={RateVariant.Evening}
					lineItem={itemGroupPayload && itemGroupPayload.eveningItem}
					isEditMode={editMode}
					formProps={{ formControl: control, formName: "eveningItem" }}
					priceGuide={priceGuide}
				/>
				{itemGroupError && <ErrorNotification messageOrPayload={itemGroupPayload} />}
				<CancelAndSaveButtons cancelFn={() => setEditMode(false)} saveLabel={editMode ? "Save" : "Edit"} isLoading={loading} omitCancel={!editMode} />
			</form>
		</ZenCard>
	)
}

interface FormItemProps {
	formControl: any
	errors?: any
	formName: string
	required?: boolean
}

interface LineItemProps {
	label: string
	lineItem?: NDISLineItem
	isEditMode?: boolean
	formProps?: FormItemProps
	marginTop?: string
	priceGuide: NDISPriceGuide
	rateVariant: RateVariant
}

const LineItem = (props: LineItemProps) => {
	const { lineItem, label, isEditMode, formProps, marginTop, priceGuide, rateVariant } = props

	const [searchKey, setSearchKey] = React.useState<string>("")
	const [lineItemDisplayKey, setLineItemDisplayKey] = React.useState<string>("")
	const debouncedSearchTerm = useDebounce(lineItemDisplayKey, 500)

	const { query, payload, loading, error } = useParameterizedQuery(fetching.query.getNDISLineItemsByPriceGuide)

	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm])
	React.useEffect(() => {
		if (searchKey === "") {
			return
		}
		query({ priceGuideID: priceGuide.id, search: searchKey, limit: 10, filterBy: FilterBy.Active, hourUnitOnly: true, rateVariant: rateVariant })
	}, [searchKey, query, priceGuide.id, rateVariant])

	const getLabel = ({ option }: any) => (
		<>
			<LabelSmall>{option?.itemNumber}</LabelSmall>
			{`${option?.itemName}`}
		</>
	)

	if (!(isEditMode && formProps)) {
		return (
			<FieldDisplay label={label} marginTop={marginTop || "10px"}>
				<LabelSmall>{lineItem && lineItem.itemNumber}</LabelSmall>
				<LabelSmall>{lineItem && lineItem.itemName}</LabelSmall>
			</FieldDisplay>
		)
	}

	if (error) {
		return (
			<FieldDisplay label={label} marginTop={marginTop || "10px"}>
				<ErrorNotification messageOrPayload={payload} />
			</FieldDisplay>
		)
	}

	return (
		<ZenSelect
			options={payload ? payload.lineItems : undefined}
			filterOptions={(options) => options}
			isLoading={loading}
			label={label}
			formRef={formProps.formControl}
			formName={formProps.formName}
			placeholder={"Search..."}
			labelKey={formProps.formName}
			valueKey="id"
			getOptionLabel={getLabel}
			getValueLabel={getLabel}
			inputError={formProps && formProps.errors ? formProps.errors[formProps.formName] : undefined}
			onInputChange={(e) => setLineItemDisplayKey(e.currentTarget.value)}
			formRules={{
				validate: {
					required: (value: Value) => {
						if (!formProps || !formProps.required) {
							return
						}
						return (!!value && value.length === 1) || "You must select a line item"
					},
				},
			}}
		/>
	)
}

const ItemGroupCreate = (props: { onSave: (itemGroup: NDISItemGroupBase) => void; onCancel: () => void; priceGuide: NDISPriceGuide }) => {
	const { onSave, onCancel, priceGuide } = props

	const { control, handleSubmit, errors } = useForm<FormData>()

	const { loading, error, payload, mutate } = useMutation(fetching.mutation.itemGroupCreate)

	const onSubmit = async (data: FormData) => {
		const resp = await mutate({
			id: priceGuide.id,
			input: {
				standardItemID: data.standardItem[0].id as string,
				saturdayItemID: data.saturdayItem && data.saturdayItem.length > 0 ? (data.saturdayItem[0].id as string) : undefined,
				sundayItemID: data.sundayItem && data.sundayItem.length > 0 ? (data.sundayItem[0].id as string) : undefined,
				holidayItemID: data.holidayItem && data.holidayItem.length > 0 ? (data.holidayItem[0].id as string) : undefined,
				eveningItemID: data.eveningItem && data.eveningItem.length > 0 ? (data.eveningItem[0].id as string) : undefined,
			},
		})

		if (resp.error || !resp.payload) {
			return
		}

		onSave(resp.payload)
	}

	return (
		<ZenCard>
			<LabelLarge marginTop={0} marginBottom={"15px"}>
				NDIS Line Item Group
			</LabelLarge>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<LineItem
					label="Standard Item"
					rateVariant={RateVariant.Standard}
					isEditMode={true}
					formProps={{ formControl: control, formName: "standardItem", required: true, errors: errors }}
					priceGuide={priceGuide}
				/>
				<LineItem
					label="Saturday Item"
					rateVariant={RateVariant.Saturday}
					isEditMode={true}
					formProps={{ formControl: control, formName: "saturdayItem" }}
					priceGuide={priceGuide}
				/>
				<LineItem
					label="Sunday Item"
					rateVariant={RateVariant.Sunday}
					isEditMode={true}
					formProps={{ formControl: control, formName: "sundayItem" }}
					priceGuide={priceGuide}
				/>
				<LineItem
					label="Public Holiday Item"
					rateVariant={RateVariant.Holiday}
					isEditMode={true}
					formProps={{ formControl: control, formName: "holidayItem" }}
					priceGuide={priceGuide}
				/>
				<LineItem
					label="Evening Item"
					rateVariant={RateVariant.Evening}
					isEditMode={true}
					formProps={{ formControl: control, formName: "eveningItem" }}
					priceGuide={priceGuide}
				/>
				{error && <ErrorNotification messageOrPayload={payload} />}
				<CancelAndSaveButtons cancelFn={onCancel} isLoading={loading} />
			</form>
		</ZenCard>
	)
}
