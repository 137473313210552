import * as React from "react"
import { useStyletron } from "baseui"
import { Checkbox } from "baseui/checkbox"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { ModalBody, ModalHeader } from "baseui/modal"
import { Option, Value } from "baseui/select"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import { ZenModal } from "components/zenComponents/zenModal"
import moment from "moment-timezone"
import { QueryResponse, useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { AuthContainer } from "../../../controllers/auth"
import { PortalContainer } from "../../../controllers/portal"
import { fetching } from "../../../fetching"
import { alignDate, setTimezoneToTime, snakeToTitle } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { AttendanceStatus } from "../../../types/enums"
import {
	BasicLabel,
	Client,
	NDISPlan,
	RolePermission,
	Session,
	SessionAllocatedLineItem,
	SessionNDISPlanLine,
	TZString,
	ZenDateFormat,
	ZenTimeFormat,
} from "../../../types/types"
import { UserLabel } from "../../callLogs/subComponents"
import { CancelAndSaveButtons } from "../../cancelSaveButtons"
import { AvailableCheck, Divider, Spacer, ZenCard } from "../../common"
import { ErrorNotification } from "../../errorBox"
import { TravelForm } from "../../travels/travelForm"
import { useZenToast } from "../../zenComponents/useZenToast"
import { ZenButton } from "../../zenComponents/zenButtons"
import { ZenInput } from "../../zenComponents/zenInput"
import { SelectedItemCard, ZenSelect, ZenTimezoneSelect, ZenUserSelect } from "../../zenComponents/zenSelectBox"
import { ZenDatePicker, ZenTimePicker } from "../../zenComponents/zenTime"
import { DisplayLabel } from "../newClientAppointment/singleSessionConfirmation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Notification } from "baseui/notification"
import { ZenArchiveModal } from "components/zenComponents/zenArchiveDialog"
import { SessionFundingSourceForm } from "../SessionFundingSource"
import { ErrorFieldTracker } from "../../forms/errorFieldTracker"
import { useCheckWorkerAvailability } from "../../../helpers/useCheckWorkerAvailability"
import { SessionNoteQuestionForm, SessionNoteQuestionLabels } from "../sessionNoteComponent"
import { CheckAvailabilityInput } from "../../../fetching/inputType"

interface PostSessionDetailProps extends Session {
	refetchCurrentSession: () => Promise<QueryResponse<Session>>
	triggerQuerySessionList: () => void

	inTimesheet?: boolean
	refetchTimesheet?: () => void
	setDisplayMode?: React.Dispatch<React.SetStateAction<boolean>>
}

export const PostSessionDetail = (props: PostSessionDetailProps) => {
	const { isLocked, attendanceStatus, timesheets, worker, refetchCurrentSession } = props
	const { currentUser, hasPermission } = AuthContainer.useContainer()
	const { fetchIncompleteSessionAlert } = PortalContainer.useContainer()
	const [css] = useStyletron()
	const [editMode, setEditMode] = React.useState<boolean>(false)
	React.useEffect(() => {
		setEditMode(!attendanceStatus)
	}, [attendanceStatus])

	const { mutate: uncancelSession, error, loading, payload } = useMutation<boolean>(fetching.mutation.sessionUncancel)

	const canEdit = (): boolean => {
		const ready = !!(timesheets && timesheets.length > 0 && !!timesheets[0].readyAt)
		// timesheet locked (approved + completed)
		if (isLocked && !hasPermission(RolePermission.CompletedReportManage)) {
			return false
		}

		// ready but no update perms
		if (ready && !hasPermission(RolePermission.TravelUpdate)) {
			return false
		}

		// no perms or not own  editing own timesheet
		if (!(hasPermission(RolePermission.TravelUpdate) || (currentUser && worker.id === currentUser.id))) {
			return false
		}

		return true
	}
	const cancelled = props.attendanceStatus === AttendanceStatus.Cancelled

	const container = css({
		backgroundColor: "white",
		minWidth: "360px",
		display: "flex",
		flexDirection: "column",
		flex: "7 !important",
		maxHeight: "100%",
		overflow: "auto",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})
	const title = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "8px",
	})

	return (
		<ZenCard className={container}>
			<div className={title}>
				<LabelMedium>Post Session Details</LabelMedium>

				{/* show edit button if:
					- have permission 
					- not cancelled 
					or 
					- cancelled but has time claimed
					and not in edit mode
				*/}
				{((canEdit() && !cancelled && !props.inTimesheet) || (cancelled && props.timeClaimed)) && !editMode && (
					<ZenButton onClick={() => setEditMode(true)}>Edit</ZenButton>
				)}
			</div>
			<div className={body}>
				{(editMode || props.inTimesheet) && !props.deletedAt ? (
					<PostSessionAttendanceEdit {...props} setEditMode={setEditMode} />
				) : (
					<PostSessionAttendanceView {...props} />
				)}
			</div>

			{/* NOTE: cant "un-cancel" a session if it's time is claimed  */}
			{canEdit() && !editMode && cancelled && !props.timeClaimed && (
				<ZenButton
					onClick={() =>
						uncancelSession(props.id).then((resp) => {
							if (resp.error || !resp.payload) return
							refetchCurrentSession()
							fetchIncompleteSessionAlert()
						})
					}
					isLoading={loading}
				>
					Un-cancel Session
				</ZenButton>
			)}
			{error && <ErrorNotification messageOrPayload={payload} />}
		</ZenCard>
	)
}

const PostSessionAttendanceView = (props: Session) => {
	const [css] = useStyletron()
	const group = css({
		display: "flex",
		width: "100%",
	})
	const scrollDiv = css({
		width: "100%",
		height: "100%",
		maxHeight: "100%",
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "8px",
	})
	const { timezone: globalTimezone } = PortalContainer.useContainer()

	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === props.timezoneID)
		if (!target) return globalTimezone
		return target
	}, [props.timezoneID]) // eslint-disable-line react-hooks/exhaustive-deps

	const baseFundingSource = React.useMemo(() => {
		return props.sessionFundingSources[0].fundingSource
	}, [props.sessionFundingSources])

	return (
		<div className={scrollDiv}>
			{props.attendanceStatus ? (
				<DisplayLabel label="Attendance Status">
					<LabelMedium marginLeft="5px">{snakeToTitle(props.attendanceStatus)}</LabelMedium>
				</DisplayLabel>
			) : (
				<DisplayLabel label="Attendance Status">
					<LabelMedium marginLeft="5px">N/A</LabelMedium>
				</DisplayLabel>
			)}
			<DisplayLabel label="Timezone">
				<LabelMedium marginLeft="5px">{timezone.label}</LabelMedium>
			</DisplayLabel>
			{props.actualStartTime && props.actualEndTime && (
				<div className={group}>
					<DisplayLabel label="Actual Start Time">
						<LabelMedium marginLeft="5px">{moment(props.actualStartTime).tz(timezone.id).format("hh:mm a")}</LabelMedium>
					</DisplayLabel>
					<DisplayLabel label="Actual End Time">
						<LabelMedium marginLeft="5px">{moment(props.actualEndTime).tz(timezone.id).format("hh:mm a")}</LabelMedium>
					</DisplayLabel>
				</div>
			)}
			{props.notifyTeamLeader && (
				<DisplayLabel label="Notify Team Leader">
					<LabelMedium marginLeft="5px">
						<UserLabel data={props.notifyTeamLeader} />
					</LabelMedium>
				</DisplayLabel>
			)}
			{props.notificationMadeTo && (
				<DisplayLabel label="Notification Made To">
					<LabelMedium marginLeft="5px">
						<UserLabel data={props.notificationMadeTo} />
					</LabelMedium>
				</DisplayLabel>
			)}
			{props.notificationWasSentBy && (
				<DisplayLabel label="Notification Was Sent By">
					<LabelMedium marginLeft="5px">{props.notificationWasSentBy}</LabelMedium>
				</DisplayLabel>
			)}
			{props.actionsTakenToLocateClient && (
				<DisplayLabel label="Actions Taken to Locate Client">
					<LabelMedium marginLeft="5px">{props.actionsTakenToLocateClient}</LabelMedium>
				</DisplayLabel>
			)}
			{props.reasonSessionEndedEarly && (
				<DisplayLabel label="Reason Session Ended Early">
					<LabelMedium marginLeft="5px">{props.reasonSessionEndedEarly}</LabelMedium>
				</DisplayLabel>
			)}
			{props.actionTaken && (
				<DisplayLabel label="Action Taken">
					<LabelMedium marginLeft="5px">{props.actionTaken}</LabelMedium>
				</DisplayLabel>
			)}
			{props.timeOfNotification && (
				<DisplayLabel label="Time of Notification">
					<LabelMedium marginLeft="5px">{moment(props.timeOfNotification).tz(timezone.id).format(`${ZenDateFormat} ${ZenTimeFormat}`)}</LabelMedium>
				</DisplayLabel>
			)}
			{props.timeOfClientContact && (
				<DisplayLabel label="Time of Client Contact">
					<LabelMedium marginLeft="5px">{moment(props.timeOfClientContact).tz(timezone.id).format(`${ZenDateFormat} ${ZenTimeFormat}`)}</LabelMedium>
				</DisplayLabel>
			)}
			{props.timeSessionEndedEarly && (
				<DisplayLabel label="Time Session Ended Early">
					<LabelMedium marginLeft="5px">{moment(props.timeSessionEndedEarly).tz(timezone.id).format(`${ZenDateFormat} ${ZenTimeFormat}`)}</LabelMedium>
				</DisplayLabel>
			)}
			{props.timeIncludedWithSession && (
				<DisplayLabel label="Time include with session">
					<LabelMedium marginLeft="5px">{props.timeIncludedWithSession ? "Yes" : "No"}</LabelMedium>
				</DisplayLabel>
			)}
			{props.dateForCompletingSessionAdmin && (
				<DisplayLabel label="Date for completing session admin">
					<LabelMedium marginLeft="5px">{moment(props.dateForCompletingSessionAdmin).tz(timezone.id).format("DD/MM/YYYY")}</LabelMedium>
				</DisplayLabel>
			)}
			{props.startTimeForSessionAdmin && (
				<DisplayLabel label="Start time for session admin">
					<LabelMedium marginLeft="5px">{moment(props.startTimeForSessionAdmin).tz(timezone.id).format("hh:mm a")}</LabelMedium>
				</DisplayLabel>
			)}
			{props.minutesRequiredForAdminTasks && (
				<DisplayLabel label="Minutes required for admin tasks">
					<LabelMedium marginLeft="5px">{`${props.minutesRequiredForAdminTasks}`}</LabelMedium>
				</DisplayLabel>
			)}
			<Divider />
			{(props.attendanceStatus === AttendanceStatus.Attended ||
				props.attendanceStatus === AttendanceStatus.EndedEarly ||
				props.attendanceStatus === AttendanceStatus.ShortNoticeCancellation ||
				props.attendanceStatus === AttendanceStatus.DNA) &&
				baseFundingSource.label === "NDIA" &&
				props.clients &&
				props.clients.map((c) => {
					let duration = Math.floor(moment.duration(moment(props.endTime).diff(moment(props.startTime))).asMinutes())
					if (props.attendanceStatus === AttendanceStatus.Attended) {
						duration = Math.floor(moment.duration(moment(props.actualEndTime).diff(moment(props.actualStartTime))).asMinutes())
					}
					return <SessionClientDetail key={c.id} {...c} sessionID={props.id} duration={duration} />
				})}
		</div>
	)
}

interface SessionClientDetailProps extends Client {
	duration: number
	allocatePlanLineSessionMinutes?: (planLineID: string, portion: number) => void
	selectedPlanLineList?: SessionNDISPlanLine[]
	sessionID: string
	checkDurationAllocation?: boolean
}

export const SessionClientDetail = (props: SessionClientDetailProps) => {
	const { id: clientID, duration, allocatePlanLineSessionMinutes, sessionID, selectedPlanLineList, checkDurationAllocation } = props
	const { hasPermission } = AuthContainer.useContainer()
	const hasSupportWorkerPerm = hasPermission(RolePermission.SupportWorker)
	const hasSupportCoordinatorPerm = hasPermission(RolePermission.SupportCoordinator)

	const [css] = useStyletron()
	const group = css({
		display: "flex",
		width: "100%",
	})

	const [shortTermGoal, setShortTermGoal] = React.useState("N/A")
	const [midOrLongTermGoal, setMidOrLongTermGoal] = React.useState("N/A")

	const ndisPlan = useQuery<NDISPlan>(fetching.query.getClientActiveNDISPlan(clientID))
	const ndisPlanLine = useQuery(fetching.query.getClientActiveNDISPlanLineItems(clientID, sessionID))
	const [openModal, setOpenModal] = React.useState(false)
	const [selectedPlanLine, setSelectedPlanLine] = React.useState<SessionAllocatedLineItem>()
	const [remainMinutes, setRemainMinutes] = React.useState<number>(duration)
	const [allocatedMinutes, setAllocatedMinutes] = React.useState<number>(0)
	const [remainMinutesError, setRemainMinutesError] = React.useState<boolean>(false)

	// active line items are the line times which have available service date range that cover the session date
	const [activeLineItems, setActiveLineItems] = React.useState<SessionAllocatedLineItem[]>([])

	// allocated line items are the line items which allocated to the session
	const [allocatedLineItems, setAllocatedLineItems] = React.useState<SessionAllocatedLineItem[]>([])

	React.useEffect(() => {
		if (ndisPlanLine.loading || ndisPlanLine.error || !ndisPlanLine.payload) return
		setActiveLineItems(ndisPlanLine.payload.activeLineItems)

		// parse allocated line items
		let list = [...ndisPlanLine.payload.allocatedLineItems]

		// update select plan line list
		if (selectedPlanLineList) {
			// combine remain active plan lines
			const remainActivePlanLines = activeLineItems.filter((ali) => !list.some((li) => li.id === ali.id))

			list.push(...remainActivePlanLines)

			// filter out unallocated options from original allocated plan line
			list = list.filter((li) => selectedPlanLineList.some((spl) => spl.ndisPlanLineID === li.id))

			// update duration time
			list = list.map((li) => {
				const target = selectedPlanLineList.find((spl) => spl.ndisPlanLineID === li.id)
				if (!target) return li
				return {
					...li,
					durationMinutes: target.durationMinutes,
				}
			})
		}
		setAllocatedLineItems(list)

		let totalAllocation = 0
		list.forEach((alt) => {
			if (alt.durationMinutes) totalAllocation += Math.floor(alt.durationMinutes)
		})
		setRemainMinutes(duration - totalAllocation)
		setAllocatedMinutes(totalAllocation)
		setRemainMinutesError(duration < totalAllocation)
	}, [ndisPlanLine.payload, ndisPlanLine.error, ndisPlanLine.loading, duration, selectedPlanLineList]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (ndisPlan.loading || ndisPlan.error || !ndisPlan.payload) return
		setShortTermGoal(ndisPlan.payload.shortTermGoals)
		setMidOrLongTermGoal(ndisPlan.payload.mediumOrLongTermGoals)
	}, [ndisPlan.payload, ndisPlan.error, ndisPlan.loading])

	const displayPlanLineItems = () => {
		// When in view mode
		if (!allocatePlanLineSessionMinutes) {
			// nothing needs to be returned if no time was allocated.
			if (allocatedLineItems.length === 0) return <LabelMedium marginLeft="5px">N/A</LabelMedium>

			// display allocated line items
			return allocatedLineItems.map((alt, i) => (
				<LineItemCard key={i} isAllocated planLine={alt} setOpenModal={setOpenModal} setSelectedPlanLine={setSelectedPlanLine} itemArchived={!!alt.deletedAt} />
			))
		}

		// when in edit mode

		// prepare list
		let list = [...allocatedLineItems]
		list.push(...activeLineItems.filter((ali) => !allocatedLineItems.some((alt) => alt.id === ali.id)))

		if (list.length === 0) return <LabelMedium marginLeft="5px">N/A</LabelMedium>

		return list.map((li, i) => {
			// if its line item is support coordinator code and current user does NOT have support coordinator permission
			if (li.supportLineItems[0].isSupportCoordinatorCode && !hasSupportCoordinatorPerm) return null

			// if its line item is NOT support coordinator code and current user does NOT have support worker permission
			if (!li.supportLineItems[0].isSupportCoordinatorCode && !hasSupportWorkerPerm) return null

			return (
				<LineItemCard
					key={i}
					isAllocated={allocatedLineItems.some((alt) => alt.id === li.id)}
					planLine={li}
					setOpenModal={setOpenModal}
					setSelectedPlanLine={setSelectedPlanLine}
					canAllocate
					itemArchived={!!li.deletedAt}
				/>
			)
		})
	}

	return (
		<React.Fragment>
			<div className={group}>
				<DisplayLabel label="Client">
					<Link to={`/portal/clients/${clientID}`}>
						<SelectedItemCard firstName={props.firstName} lastName={props.lastName} avatarUrl={props.avatarURL} />
					</Link>
				</DisplayLabel>
				<DisplayLabel label="Session Duration">
					<LabelMedium marginLeft="5px">{`${duration} mins`}</LabelMedium>
				</DisplayLabel>
			</div>
			<DisplayLabel label="Short Term Goals">
				<LabelMedium marginLeft="5px" overrides={{ Block: { style: { whiteSpace: "pre-line" } } }}>
					{shortTermGoal}
				</LabelMedium>
			</DisplayLabel>
			<DisplayLabel label="Medium or Long Term Goals">
				<LabelMedium marginLeft="5px" overrides={{ Block: { style: { whiteSpace: "pre-line" } } }}>
					{midOrLongTermGoal}
				</LabelMedium>
			</DisplayLabel>
			{checkDurationAllocation && remainMinutes > 0 && <ErrorNotification messageOrPayload={`There are ${remainMinutes} minutes not being allocated`} />}
			{remainMinutesError && (
				<ErrorNotification messageOrPayload={`The allocated minutes (${allocatedMinutes}) exceeds the maximum session duration (${duration})`} />
			)}
			{ndisPlan.error && <ErrorNotification messageOrPayload={ndisPlan.payload} />}
			{ndisPlanLine.error && <ErrorNotification messageOrPayload={ndisPlanLine.payload} />}

			<DisplayLabel label={allocatePlanLineSessionMinutes ? "Select all applicable NDIS Plan Line Items:" : "Session Time Allocation"}>
				{displayPlanLineItems()}
			</DisplayLabel>
			<Divider style={{ height: "8px", backgroundColor: "transparent" }} />
			{openModal && selectedPlanLine && allocatePlanLineSessionMinutes && (
				<AllocatePlanLineModal
					open={openModal}
					setOpen={setOpenModal}
					planLine={selectedPlanLine}
					remainMinutes={remainMinutes}
					allocatePlanLineMinutes={allocatePlanLineSessionMinutes}
					totalDuration={duration}
					deleteOnly={!!selectedPlanLine.deletedAt || !activeLineItems.some((ali) => ali.id === selectedPlanLine.id)}
				/>
			)}
		</React.Fragment>
	)
}

interface LineItemCardProps {
	isAllocated?: boolean
	planLine: SessionAllocatedLineItem
	setOpenModal: (open: boolean) => void
	setSelectedPlanLine: (planLine: SessionAllocatedLineItem) => void
	canAllocate?: boolean
	itemArchived: boolean
}

const LineItemCard = (props: LineItemCardProps) => {
	const { isAllocated, setSelectedPlanLine, setOpenModal, canAllocate, planLine, itemArchived } = props

	const [css, theme] = useStyletron()
	const colour: string = (() => {
		if (itemArchived && canAllocate) return theme.colors.negative
		if (isAllocated) return ZenTheme.colors.primaryGreen
		return ZenTheme.colors.primaryGrey
	})()
	const ndisLineItemCard = css({
		borderLeft: `3px solid ${colour}`,
		padding: "10px",
		cursor: canAllocate ? "pointer" : undefined,
		marginBottom: "5px",
		":hover": {
			backgroundColor: canAllocate ? ZenTheme.colors.lightGrey : undefined,
		},
	})
	const time = css({
		display: "flex",
		alignItems: "center",
		marginTop: "5px",
	})

	return (
		<div
			className={ndisLineItemCard}
			onClick={() => {
				if (!canAllocate) return
				setOpenModal(true)
				setSelectedPlanLine(planLine)
			}}
		>
			<LabelSmall>{planLine.supportLineItems[0].itemName}</LabelSmall>
			<div className={time}>
				{isAllocated && (
					<>
						<FontAwesomeIcon color={ZenTheme.colors.iconColour} icon={["fal", "clock"]} />
						<LabelSmall marginLeft={"5px"}>{planLine.durationMinutes} minutes</LabelSmall>
					</>
				)}
			</div>
		</div>
	)
}

interface AllocatePlanLineModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	planLine: SessionAllocatedLineItem
	allocatePlanLineMinutes: (planLineID: string, portion: number) => void
	totalDuration: number
	remainMinutes: number
	deleteOnly?: boolean
}
const AllocatePlanLineModal = (props: AllocatePlanLineModalProps) => {
	const { open, setOpen, planLine, allocatePlanLineMinutes, remainMinutes, deleteOnly } = props

	const [css] = useStyletron()
	const buttonBar = css({
		width: "100%",
		display: "flex",
		justifyContent: deleteOnly ? "flex-end" : "space-between",
		marginTop: "20px",
	})

	const { control, errors, trigger, getValues } = useForm()
	const [remainMins, setRemainMins] = React.useState(remainMinutes)

	const handleOnChange = (valueStr: string) => {
		let adjustMins = 0
		if (planLine && planLine.durationMinutes) {
			adjustMins = Math.floor(planLine.durationMinutes)
		}
		if (valueStr === "") setRemainMins(remainMinutes + adjustMins)
		const value = parseInt(valueStr)
		if (isNaN(value)) return
		setRemainMins(remainMinutes + adjustMins - value)
	}
	const onSubmit = async () => {
		const checkError = await trigger()
		if (!checkError) return
		const mins = getValues().minutes
		allocatePlanLineMinutes(planLine.id, mins)
		setOpen(false)
	}

	return (
		<ZenModal isOpen={open} onClose={() => setOpen(false)} size="auto" role="dialog">
			<ModalBody>
				<form>
					<DisplayLabel label="Selected Plan Line">
						<LabelSmall>{planLine.supportLineItems[0].itemName}</LabelSmall>
					</DisplayLabel>
					<DisplayLabel label="Minutes Remaining">
						<LabelSmall>{Math.floor(remainMins)}</LabelSmall>
					</DisplayLabel>
					<ZenInput
						disabled={deleteOnly}
						label="Allocated Minutes"
						formRef={control}
						nameRef="minutes"
						type="number"
						value={planLine ? `${planLine.durationMinutes}` : ""}
						actionOnChange={(e) => handleOnChange(e.currentTarget.value)}
						formRules={{
							required: "Allocated minutes is required",
							validate: {
								isNumber: (value: string) => !isNaN(parseInt(value)) || "Please enter a number",
								isLessThanRemainMinute: () => remainMins >= 0 || "Please enter a number smaller than remaining minutes",
							},
						}}
						inputError={errors.minutes}
					/>
					{deleteOnly && (
						<Notification kind="negative" overrides={{ Body: { style: { width: "100%", maxWidth: "450px" } } }}>
							This line item is from a previous version of the price guide or its service date is out of range. If you remove the allocated time from this line
							item you will not be able to assign it again.
						</Notification>
					)}
					<div className={buttonBar}>
						<ZenButton
							altKind={"danger"}
							type="button"
							disabled={!planLine.durationMinutes}
							onClick={() => {
								allocatePlanLineMinutes(planLine.id, 0)
								setOpen(false)
							}}
						>
							Remove Time
						</ZenButton>
						{!deleteOnly && (
							<ZenButton type="button" onClick={onSubmit}>
								Submit
							</ZenButton>
						)}
					</div>
				</form>
			</ModalBody>
		</ZenModal>
	)
}

const durationOptions: Option[] = [
	{ label: "10", id: 10 },
	{ label: "15", id: 15 },
	{ label: "20", id: 20 },
	{ label: "25", id: 25 },
	{ label: "30", id: 30 },
]

interface PostSessionAttendanceEditProps extends PostSessionDetailProps {
	setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const PostSessionAttendanceEdit = (props: PostSessionAttendanceEditProps) => {
	const { sessionFundingSources } = props
	const [css] = useStyletron()
	const { handleSubmit, control, setValue, watch, errors, getValues, clearErrors } = useForm()
	const watchTimezone = watch("timezone")
	const watchActualStartTime = watch("startTime")
	const watchActualEndTime = watch("endTime")
	const watchNotifiedTime = watch("notifiedTime")
	const watchClientContactTime = watch("clientContactTime")
	const watchAttendanceStatus = watch("attendanceStatus")

	const watchDateForCompletingSessionAdmin = watch("dateForCompletingSessionAdmin")
	const watchStartTimeForSessionAdmin = watch("startTimeForSessionAdmin")
	const watchMinutesRequiredForAdminTasks = watch("minutesRequiredForAdminTasks")

	const { showToast } = useZenToast()
	const [appointmentDate] = React.useState<Date>(moment(props.startTime).toDate())
	const { mutate, error, payload, loading } = useMutation<boolean>(fetching.mutation.sessionPostUpdate)

	const { fetchIncompleteSessionAlert, timezone: globalTimezone } = PortalContainer.useContainer()
	const timezone = React.useMemo(() => {
		const target = TZString.find((tz) => tz.id === props.timezoneID)
		if (!target) return globalTimezone
		return target
	}, [props.timezoneID]) // eslint-disable-line react-hooks/exhaustive-deps

	const [openTravelModal, setOpenTravelModal] = React.useState(false)
	const [cancelConfirmModal, setCancelConfirmModal] = React.useState(false)

	const [placeholder, setPlaceholder] = React.useState<number[]>([0]) // use for tracking the index of session funding source
	const [, setAvailableIndexes] = React.useState<number[]>([2, 1]) // additional index available for new funding sources

	const baseFundingSource = React.useMemo(() => {
		return sessionFundingSources[0].fundingSource
	}, [sessionFundingSources])

	// query selected NDIS plan line
	const [selectedPlanLineSession, setSelectedPlanLineSession] = React.useState<SessionNDISPlanLine[]>([])
	const getSessionAllocatedNDISPlanLines = useQuery(fetching.query.getSessionAllocatedNDISPlanLines(props.id))
	React.useEffect(() => {
		if (getSessionAllocatedNDISPlanLines.loading || getSessionAllocatedNDISPlanLines.error || !getSessionAllocatedNDISPlanLines.payload) return
		setSelectedPlanLineSession(getSessionAllocatedNDISPlanLines.payload)
	}, [getSessionAllocatedNDISPlanLines.loading, getSessionAllocatedNDISPlanLines.error, getSessionAllocatedNDISPlanLines.payload])
	const allocatePlanLineSessionMinutes = (planLineID: string, minutes: number) => {
		const planLineSession = selectedPlanLineSession.find((spl) => spl.ndisPlanLineID === planLineID)
		if (planLineSession) {
			// clear plan line session
			setSelectedPlanLineSession((s) => s.filter((pl) => pl.ndisPlanLineID !== planLineID))

			// insert new plan line session info, if it's just editing portion
			if (minutes > 0) {
				setSelectedPlanLineSession((s) => s.concat({ ndisPlanLineID: planLineID, durationMinutes: minutes }))
			}
			return
		}
		setSelectedPlanLineSession((s) => s.concat({ ndisPlanLineID: planLineID, durationMinutes: minutes }))
	}

	React.useEffect(() => {
		// get offset minutes different
		const localTimezoneOffset = new Date().getTimezoneOffset()
		const setTimezoneOffset = timezone.offsetMinutes
		const offsetDiff = localTimezoneOffset - setTimezoneOffset

		if (props.sessionFundingSources.length > 0) {
			setPlaceholder(props.sessionFundingSources.map((_, i) => i))
			props.sessionFundingSources.forEach((sfs, i) => {
				setAvailableIndexes((ais) => ais.filter((ai) => ai !== i))
				setValue(`fundingSource-${i}`, [sfs.fundingSource])
				setValue(`contractArea-${i}`, sfs.contractArea ? [sfs.contractArea] : [])
				setValue(`supportType-${i}`, sfs.supportType ? [sfs.supportType] : [])
				setValue(`subSupportType-${i}`, sfs.subSupportType ? [sfs.subSupportType] : [])
			})
		}
		setValue("notificationWasSentBy", props.notificationWasSentBy)
		setValue("actionTaken", props.actionTaken)
		setValue("actionsTakenToLocateClient", props.actionsTakenToLocateClient)
		setValue("reasonSessionEndedEarly", props.reasonSessionEndedEarly)
		if (props.actualStartTime) setValue("startTime", moment(props.actualStartTime).add(offsetDiff, "minute").toDate())
		if (props.actualEndTime) setValue("endTime", moment(props.actualEndTime).add(offsetDiff, "minute").toDate())
		if (!!props.actualEndTime && !!props.actualStartTime) {
			setDuration(moment.duration(moment(props.actualEndTime).diff(moment(props.actualStartTime))).asMinutes() || 0)
			if (props.attendanceStatus === AttendanceStatus.EndedEarly) {
				setDuration(moment.duration(moment(props.endTime).diff(moment(props.startTime))).asMinutes() || 0)
			}
		}
		if (!props.attendanceStatus && props.clients) {
			props.clients.forEach((c) => {
				SessionNoteQuestionLabels.forEach((q) => {
					setValue(`notes.${c.id}.${q}`, "")
				})
			})
		}
		setValue("notifiedDate", props.timeOfNotification ? moment(props.timeOfNotification).add(offsetDiff, "minute").toDate() : null)
		setValue("notifiedTime", props.timeOfNotification ? moment(props.timeOfNotification).add(offsetDiff, "minute").toDate() : null)
		setValue("clientContactDate", props.timeOfClientContact ? moment(props.timeOfClientContact).add(offsetDiff, "minute").toDate() : null)
		setValue("clientContactTime", props.timeOfClientContact ? moment(props.timeOfClientContact).add(offsetDiff, "minute").toDate() : null)
		if (props?.startTimeForSessionAdmin) setValue("startTimeForSessionAdmin", moment(props?.startTimeForSessionAdmin).add(offsetDiff, "minute").toDate())
	}, [props, setValue]) // eslint-disable-line react-hooks/exhaustive-deps

	const [duration, setDuration] = React.useState<number>(0)

	// manage time and attendance status
	const changeStartTime = (startTime: Date) => {
		if (!watchActualEndTime || (watchAttendanceStatus.length > 0 && watchAttendanceStatus[0].id === AttendanceStatus.EndedEarly)) return
		if (startTime >= watchActualEndTime) {
			setDuration(0)
			return
		}
		setDuration(moment.duration(moment(watchActualEndTime).diff(moment(startTime))).asMinutes() || 0)
	}
	const changeEndTime = (endTime: Date) => {
		if (!watchActualStartTime || (watchAttendanceStatus.length > 0 && watchAttendanceStatus[0].id === AttendanceStatus.EndedEarly)) return
		if (endTime <= watchActualStartTime) {
			setDuration(0)
			return
		}
		setDuration(moment.duration(moment(endTime).diff(moment(watchActualStartTime))).asMinutes() || 0)
	}
	const [timeIncludedWithSession, setTimeIncludeWithSession] = React.useState<boolean>(!props.attendanceStatus ? true : !!props.timeIncludedWithSession)
	const [attendanceStatus, setAttendanceStatus] = React.useState<string>(props.attendanceStatus || "")

	// onSubmit
	const onSubmit = (formData: any) => {
		if (attendanceStatus === "CANCELLED") {
			setCancelConfirmModal(true)
			return
		}
		submit(formData)
	}

	const [checkDurationAllocation, setCheckDurationAllocation] = React.useState(false)

	const submit = async (formData: any) => {
		if (attendanceStatus !== AttendanceStatus.Cancelled) {
			if (workerAvailability.available === false) return // this condition will pass when availability is true or undefined
			// if it is ndis session check duration is fully allocated
			if (baseFundingSource.label === "NDIA" && props.clients) {
				let totalDuration = duration * props.clients.length
				selectedPlanLineSession.forEach((spl) => {
					totalDuration -= spl.durationMinutes
				})
				if (totalDuration !== 0) {
					setCheckDurationAllocation(true)
					return
				}
			}
		}

		let input: any = {
			sessionID: props.id,
			timezoneID: props.timezoneID,
			attendanceStatus,
			sessionFundingSourcesInput: placeholder
				.sort((a: number, b: number) => a - b)
				.map((p) => {
					// read data from react hook form
					const fundingSource: BasicLabel[] = getValues(`fundingSource-${p}`) || []
					const supportType: BasicLabel[] = getValues(`supportType-${p}`) || []
					const contractArea: BasicLabel[] = getValues(`contractArea-${p}`) || []
					const subSupportType: BasicLabel[] = getValues(`subSupportType-${p}`) || []

					// parse and return valid id
					return {
						fundingSourceID: fundingSource[0].id,
						supportTypeID: supportType.length > 0 ? supportType[0].id : undefined,
						contractAreaID: contractArea.length > 0 ? contractArea[0].id : undefined,
						subSupportTypeID: subSupportType.length > 0 ? subSupportType[0].id : undefined,
					}
				}),
		}

		// parse note
		if (!props.attendanceStatus && props.clients) {
			input.clientNotes = props.clients.map((c) => {
				// parse content
				let content: any = {}
				SessionNoteQuestionLabels.forEach((q) => {
					content[q] = formData[`client-note-${c.id}-${q}`] || ""
				})

				return {
					clientID: c.id,
					content,
				}
			})
		}

		switch (attendanceStatus) {
			case AttendanceStatus.ShortNoticeCancellation:
				input = {
					...input,
					timezoneID: watchTimezone[0].id,
					startTime: setTimezoneToTime(alignDate(appointmentDate, formData.startTime), watchTimezone[0].id),
					endTime: setTimezoneToTime(alignDate(appointmentDate, formData.endTime), watchTimezone[0].id),
					timeOfNotification: setTimezoneToTime(alignDate(formData.notifiedDate, formData.notifiedTime), watchTimezone[0].id),
					notificationWasSentBy: formData.notificationWasSentBy,
					notifiedTeamLeader: formData.notifiedTeamLeader[0].id,
					actionTaken: formData.actionTaken,
				}
				break
			case AttendanceStatus.DNA:
				input = {
					...input,
					timezoneID: watchTimezone[0].id,
					startTime: setTimezoneToTime(alignDate(appointmentDate, formData.startTime), watchTimezone[0].id),
					endTime: setTimezoneToTime(alignDate(appointmentDate, formData.endTime), watchTimezone[0].id),
					actionsTakenToLocateClient: formData.actionsTakenToLocateClient,
					notifiedTeamLeader: formData.notifiedTeamLeader[0].id,
					timeOfClientContact: setTimezoneToTime(alignDate(formData.clientContactDate, formData.clientContactTime), watchTimezone[0].id),
				}
				break
			case AttendanceStatus.EndedEarly:
				input = {
					...input,
					timezoneID: watchTimezone[0].id,
					startTime: setTimezoneToTime(alignDate(appointmentDate, formData.startTime), watchTimezone[0].id),
					endTime: setTimezoneToTime(alignDate(appointmentDate, formData.endTime), watchTimezone[0].id),
					reasonSessionEndedEarly: formData.reasonSessionEndedEarly,
					notificationMadeTo: formData.notificationMadeTo[0].id,
					actionTaken: formData.actionTaken,
				}
				break
			case AttendanceStatus.Attended:
				input = {
					...input,
					timezoneID: watchTimezone[0].id,
					startTime: setTimezoneToTime(alignDate(appointmentDate, formData.startTime), watchTimezone[0].id),
					endTime: setTimezoneToTime(alignDate(appointmentDate, formData.endTime), watchTimezone[0].id),
				}
				break
		}

		if (attendanceStatus !== AttendanceStatus.Cancelled && baseFundingSource.label === "NDIA") {
			input = {
				...input,
				timeIncludedWithSession: timeIncludedWithSession,
				selectedPlanLineSession,
			}
			if (!timeIncludedWithSession) {
				input = {
					...input,
					minutesRequiredForAdminTasks: formData.minutesRequiredForAdminTasks[0].id,
					dateForCompletingSessionAdmin: setTimezoneToTime(moment(formData.dateForCompletingSessionAdmin).startOf("day").toDate(), watchTimezone[0].id),
					startTimeForSessionAdmin: setTimezoneToTime(
						alignDate(formData.dateForCompletingSessionAdmin, formData.startTimeForSessionAdmin),
						watchTimezone[0].id,
					),
				}
			}
		}

		const resp = await mutate(input)
		if (!resp.payload || resp.error) return
		showToast("Post Session Details updated successfully.", "positive")
		props.refetchCurrentSession()
		fetchIncompleteSessionAlert()
		props.triggerQuerySessionList()
		props.setEditMode(false)

		if (!!props.refetchTimesheet) {
			props.refetchTimesheet()
		}
	}

	const rangeTimeStyle = css({
		marginTop: "8px",
		display: "flex",
		justifyContent: "space-evenly",
		width: "100%",
	})
	const group = css({
		display: "flex",
		width: "100%",
	})
	const timeField = css({
		width: "100%",
	})

	const durationContainer = css({
		width: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
	})
	const scrollDiv = css({
		position: "relative",
		width: "100%",
		maxHeight: "100%",
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden",
		paddingRight: "8px",
		scrollBehavior: "smooth",
	})
	const durationText = () => {
		return (
			<div className={durationContainer}>
				<FormControl label={<LabelSmall>Duration</LabelSmall>}>
					<Input disabled value={`${Math.floor(duration)} mins`} />
				</FormControl>
			</div>
		)
	}

	// check worker availability query
	const adminTimeAvailability = useCheckWorkerAvailability({ timezone: watchTimezone })
	const workerAvailability = useCheckWorkerAvailability({ timezone: watchTimezone })

	const checkAdminTimeOverlap = React.useCallback((input: CheckAvailabilityInput) => adminTimeAvailability.check(input), []) // eslint-disable-line react-hooks/exhaustive-deps
	const adminTimeCheckReset = React.useCallback(() => adminTimeAvailability.resetState(), []) // eslint-disable-line react-hooks/exhaustive-deps

	// check admin time is available
	React.useEffect(() => {
		if (timeIncludedWithSession) {
			adminTimeCheckReset()
			return
		}
		if (
			!watchDateForCompletingSessionAdmin ||
			!watchStartTimeForSessionAdmin ||
			!watchMinutesRequiredForAdminTasks ||
			watchMinutesRequiredForAdminTasks.length === 0
		) {
			return
		}

		const startTime = alignDate(watchDateForCompletingSessionAdmin, watchStartTimeForSessionAdmin)
		const endTime = moment(startTime).add(watchMinutesRequiredForAdminTasks[0].label, "minute").toDate()
		const wIDs = [props.workerID]
		if (props.buddySessionMentor) {
			wIDs.push(props.buddySessionMentor.id)
		}

		let input: CheckAvailabilityInput = {
			workerIDs: wIDs,
			timezone: watchTimezone[0],
			sessionID: props.id,
			startTime,
			endTime,
		}
		// calc compare time frame
		switch (attendanceStatus) {
			case AttendanceStatus.Attended:
				input.compareStartTime = watchActualStartTime
				input.compareEndTime = watchActualEndTime
				break
			case AttendanceStatus.EndedEarly:
			case AttendanceStatus.ShortNoticeCancellation:
				input.compareStartTime = moment(props.startTime)
				input.compareEndTime = moment(props.endTime)
				break
		}

		checkAdminTimeOverlap(input)
	}, [
		checkAdminTimeOverlap,
		adminTimeCheckReset,
		timeIncludedWithSession,
		watchDateForCompletingSessionAdmin,
		watchStartTimeForSessionAdmin,
		watchMinutesRequiredForAdminTasks,
		watchActualStartTime,
		watchActualEndTime,
		watchTimezone,
		attendanceStatus,
		props,
	])

	React.useEffect(() => {
		let attendState = ""
		if (!!watchAttendanceStatus && watchAttendanceStatus.length > 0) attendState = watchAttendanceStatus[0].id
		// handle time check, if the session is attended
		if (attendState === AttendanceStatus.Attended || attendState === AttendanceStatus.EndedEarly) {
			const wIDs = [props.workerID]
			if (props.buddySessionMentor) {
				wIDs.push(props.buddySessionMentor.id)
			}

			// check available
			workerAvailability.check({
				workerIDs: wIDs,
				timezone: watchTimezone[0],
				startTime: watchActualStartTime,
				endTime: watchActualEndTime,
				sessionID: props.id,
				billableSession: true,
			})
		} else {
			// otherwise, reset the time availability state
			workerAvailability.resetState()
		}
	}, [watchActualStartTime, watchActualEndTime, watchAttendanceStatus, props, watchTimezone]) // eslint-disable-line react-hooks/exhaustive-deps

	const onAttendanceChange = (v: Value) => {
		clearErrors()
		let attendState = ""
		if (v.length > 0) {
			attendState = v[0].id?.toString() || ""
		}

		// get offset minutes different
		const localTimezoneOffset = new Date().getTimezoneOffset()
		const setTimezoneOffset = timezone.offsetMinutes
		const offsetDiff = localTimezoneOffset - setTimezoneOffset

		// reset values base on the support type
		switch (attendState) {
			case AttendanceStatus.ShortNoticeCancellation:
				setValue("startTime", moment(props.startTime).add(offsetDiff, "minute").toDate())
				setValue("endTime", moment(props.endTime).add(offsetDiff, "minute").toDate())
				setValue("timezone", [{ ...timezone }])
				setDuration(moment.duration(moment(props.endTime).diff(moment(props.startTime))).asMinutes() || 0)
				setValue("notifiedDate", props.timeOfNotification ? moment(props.timeOfNotification).add(offsetDiff, "minute").toDate() : null)
				setValue("notifiedTime", props.timeOfNotification ? moment(props.timeOfNotification).add(offsetDiff, "minute").toDate() : null)
				setValue("actionTaken", props.actionTaken || "")
				setValue("notificationWasSentBy", props.notificationWasSentBy || "")
				setValue(
					"notifiedTeamLeader",
					props.notifyTeamLeader ? [{ ...props.notifyTeamLeader, label: `${props.notifyTeamLeader.firstName} ${props.notifyTeamLeader.lastName}` }] : [],
				)
				break
			case AttendanceStatus.DNA:
				setValue("startTime", moment(props.startTime).add(offsetDiff, "minute").toDate())
				setValue("endTime", moment(props.endTime).add(offsetDiff, "minute").toDate())
				setValue("timezone", [{ ...timezone }])
				setDuration(moment.duration(moment(props.endTime).diff(moment(props.startTime))).asMinutes() || 0)
				setValue("clientContactDate", props.timeOfClientContact ? moment(props.timeOfClientContact).add(offsetDiff, "minute").toDate() : null)
				setValue("clientContactTime", props.timeOfClientContact ? moment(props.timeOfClientContact).add(offsetDiff, "minute").toDate() : null)
				setValue("actionsTakenToLocateClient", props.actionsTakenToLocateClient || "")
				setValue(
					"notifiedTeamLeader",
					props.notifyTeamLeader ? [{ ...props.notifyTeamLeader, label: `${props.notifyTeamLeader.firstName} ${props.notifyTeamLeader.lastName}` }] : [],
				)
				break
			case AttendanceStatus.EndedEarly:
				setDuration(moment.duration(moment(props.endTime).diff(moment(props.startTime))).asMinutes() || 0)
				setValue("startTime", moment(props.startTime).add(offsetDiff, "minute").toDate())
				setValue("endTime", moment(props.endTime).add(offsetDiff, "minute").toDate())
				setValue("timezone", [{ ...timezone }])
				setValue("actionTaken", props.actionTaken)
				setValue("reasonSessionEndedEarly", props.reasonSessionEndedEarly)
				setValue(
					"notificationMadeTo",
					props.notificationMadeTo
						? [{ ...props.notificationMadeTo, label: `${props.notificationMadeTo.firstName} ${props.notificationMadeTo.lastName}` }]
						: [],
				)
				break
			case AttendanceStatus.Attended:
				setValue("startTime", moment(props.startTime).add(offsetDiff, "minute").toDate())
				setValue("endTime", moment(props.endTime).add(offsetDiff, "minute").toDate())
				setValue("timezone", [{ ...timezone }])
				setDuration(moment.duration(moment(props.endTime).diff(moment(props.startTime))).asMinutes() || 0)
				break
		}

		setAttendanceStatus(attendState)
	}

	const displayForm = () => {
		switch (attendanceStatus) {
			case AttendanceStatus.Attended:
				return (
					<>
						<LabelSmall marginTop="15px">Actual time of session</LabelSmall>
						<ZenTimezoneSelect
							formRef={control}
							formName="timezone"
							label="Timezone"
							timezone={[timezone]}
							clearable={false}
							actionOnChange={(v) => {
								const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
								if (watchActualStartTime) setValue("startTime", moment(watchActualStartTime).add(offsetDifferent, "minute"))
								if (watchActualEndTime) setValue("endTime", moment(watchActualEndTime).add(offsetDifferent, "minute"))
							}}
						/>
						<div className={rangeTimeStyle}>
							<ZenTimePicker
								width="100%"
								label="Time Start"
								formName="startTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeStartTime}
								inputError={errors.startTime}
								showTodayButton={false}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "Start time is required"
											}
											if (watchActualEndTime && moment(value).isSameOrAfter(watchActualEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							<ZenTimePicker
								width="100%"
								label="Time End"
								formName="endTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeEndTime}
								inputError={errors.endTime}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "End time is required"
											}
											if (watchActualStartTime && moment(value).isSameOrBefore(watchActualStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
								showTodayButton={false}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							{durationText()}
						</div>
						{!!workerAvailability.state && <AvailableCheck isAvailable={workerAvailability.state} />}
					</>
				)
			case AttendanceStatus.ShortNoticeCancellation:
				return (
					<>
						<LabelSmall marginTop="15px">Actual time of session</LabelSmall>
						<ZenTimezoneSelect
							formRef={control}
							formName="timezone"
							label="Timezone"
							timezone={[timezone]}
							clearable={false}
							actionOnChange={(v) => {
								const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
								if (watchActualStartTime) setValue("startTime", moment(watchActualStartTime).add(offsetDifferent, "minute"))
								if (watchActualEndTime) setValue("endTime", moment(watchActualEndTime).add(offsetDifferent, "minute"))
								if (watchNotifiedTime) setValue("notifiedTime", moment(watchNotifiedTime).add(offsetDifferent, "minute"))
							}}
						/>
						<div className={rangeTimeStyle}>
							<ZenTimePicker
								width="100%"
								label="Time Start"
								formName="startTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeStartTime}
								inputError={errors.startTime}
								showTodayButton={false}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "Start time is required"
											}
											if (watchActualEndTime && moment(value).isSameOrAfter(watchActualEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							<ZenTimePicker
								width="100%"
								label="Time End"
								formName="endTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeEndTime}
								inputError={errors.endTime}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "End time is required"
											}
											if (watchActualStartTime && moment(value).isSameOrBefore(watchActualStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
								showTodayButton={false}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							{durationText()}
						</div>
						<div className={group}>
							<div className={timeField} id={"notifiedDate"}>
								<ZenDatePicker
									label="Notified Date"
									nullDefaultValue
									formName="notifiedDate"
									formRef={control}
									inputError={errors.notifiedDate}
									formRules={{
										required: "Notified Date is required",
									}}
								/>
							</div>
							<Spacer style={{ minWidth: "15px" }} />
							<div className={timeField} id={`notifiedTime`}>
								<ZenTimePicker
									label="Notified Time"
									formName="notifiedTime"
									date={watch("notifiedDate")}
									formRef={control}
									inputError={errors.notifiedTime}
									formRules={{
										required: "Notified Time is required",
									}}
									creatable
									showTodayButton={false}
								/>
							</div>
						</div>
						<ZenInput
							label="Notification was sent by:"
							nameRef="notificationWasSentBy"
							inputError={errors.notificationWasSentBy}
							formRef={control}
							formRules={{ required: "Notification was sent by is required" }}
						/>
						<ZenUserSelect
							label="Notified Team Leader"
							formName="notifiedTeamLeader"
							inputError={errors.notifiedTeamLeader}
							defaultValue={props.notifyTeamLeader ? [props.notifyTeamLeader] : undefined}
							formRef={control}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Notified Team Leader is required",
								},
							}}
						/>
						<ZenInput
							label="Action taken:"
							nameRef="actionTaken"
							inputError={errors.actionTaken}
							formRef={control}
							formRules={{ required: "Action taken is required" }}
						/>
					</>
				)
			case AttendanceStatus.DNA:
				return (
					<>
						<LabelSmall marginTop="15px">Actual time of session</LabelSmall>
						<ZenTimezoneSelect
							formRef={control}
							formName="timezone"
							label="Timezone"
							timezone={[timezone]}
							clearable={false}
							actionOnChange={(v) => {
								const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
								if (watchActualStartTime) setValue("startTime", moment(watchActualStartTime).add(offsetDifferent, "minute"))
								if (watchActualEndTime) setValue("endTime", moment(watchActualEndTime).add(offsetDifferent, "minute"))
								if (watchClientContactTime) setValue("clientContactTime", moment(watchClientContactTime).add(offsetDifferent, "minute"))
							}}
						/>
						<div className={rangeTimeStyle}>
							<ZenTimePicker
								width="100%"
								label="Time Start"
								formName="startTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeStartTime}
								inputError={errors.startTime}
								showTodayButton={false}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "Start time is required"
											}
											if (watchActualEndTime && moment(value).isSameOrAfter(watchActualEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							<ZenTimePicker
								width="100%"
								label="Time End"
								formName="endTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeEndTime}
								inputError={errors.endTime}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "End time is required"
											}
											if (watchActualStartTime && moment(value).isSameOrBefore(watchActualStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
								showTodayButton={false}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							{durationText()}
						</div>
						<ZenInput
							label="Actions Taken to locate Client:"
							nameRef="actionsTakenToLocateClient"
							inputError={errors.actionsTakenToLocateClient}
							formRef={control}
							formRules={{ required: "Actions Taken to locate Client by is required" }}
						/>
						<ZenUserSelect
							label="Notified Team Leader"
							formName="notifiedTeamLeader"
							inputError={errors.notifiedTeamLeader}
							formRef={control}
							defaultValue={props.notifyTeamLeader ? [props.notifyTeamLeader] : undefined}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Notified Team Leader is required",
								},
							}}
						/>
						<div className={group}>
							<div className={timeField} id={`clientContactDate`}>
								<ZenDatePicker
									label="Client Contact Date"
									nullDefaultValue
									formName="clientContactDate"
									formRef={control}
									inputError={errors.clientContactDate}
									value={props.timeOfClientContact ? moment(props.timeOfClientContact).toDate() : undefined}
									formRules={{
										required: "Client Contact Date is required",
									}}
								/>
							</div>
							<Spacer style={{ minWidth: "15px" }} />
							<div className={timeField} id={`clientContactTime`}>
								<ZenTimePicker
									label="Client Contact Time"
									nullable
									formName="clientContactTime"
									date={watch("clientContactDate")}
									formRef={control}
									inputError={errors.clientContactTime}
									value={props.timeOfClientContact ? moment(props.timeOfClientContact).toDate() : undefined}
									formRules={{
										required: "Client Contact Time is required",
									}}
									creatable
									showTodayButton={false}
								/>
							</div>
						</div>
					</>
				)
			case AttendanceStatus.EndedEarly:
				return (
					<>
						<LabelSmall marginTop="15px">Actual time of session</LabelSmall>
						<ZenTimezoneSelect
							formRef={control}
							formName="timezone"
							label="Timezone"
							timezone={[timezone]}
							clearable={false}
							actionOnChange={(v) => {
								const offsetDifferent = watchTimezone[0].offsetMinutes - v[0].offsetMinutes
								if (watchActualStartTime) setValue("startTime", moment(watchActualStartTime).add(offsetDifferent, "minute"))
								if (watchActualEndTime) setValue("endTime", moment(watchActualEndTime).add(offsetDifferent, "minute"))
							}}
						/>
						<div className={rangeTimeStyle}>
							<ZenTimePicker
								width="100%"
								label="Time Start"
								formName="startTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeStartTime}
								showTodayButton={false}
								inputError={errors.startTime}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "Start time is required"
											}
											if (watchActualEndTime && moment(value).isSameOrAfter(watchActualEndTime)) {
												return "Start time must be before end time"
											}
											return null
										},
									},
								}}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							<ZenTimePicker
								width="100%"
								label="Time End"
								formName="endTime"
								date={appointmentDate}
								formRef={control}
								actionOnChange={changeEndTime}
								showTodayButton={false}
								inputError={errors.endTime}
								formRules={{
									validate: {
										required: (value: string) => {
											if (!value) {
												return "End time is required"
											}
											if (watchActualStartTime && moment(value).isSameOrBefore(watchActualStartTime)) {
												return "End time must be after start time"
											}
											return null
										},
									},
								}}
							/>
							<Spacer style={{ minWidth: "10px" }} />
							{durationText()}
						</div>
						{!!workerAvailability.state && <AvailableCheck isAvailable={workerAvailability.state} />}
						<ZenInput
							label="Reason Session Ended early:"
							nameRef="reasonSessionEndedEarly"
							inputError={errors.reasonSessionEndedEarly}
							formRef={control}
							formRules={{ required: "Reason Session Ended early is required" }}
						/>
						<ZenUserSelect
							label="Notification made to"
							formName="notificationMadeTo"
							inputError={errors.notificationMadeTo}
							defaultValue={props.notificationMadeTo ? [props.notificationMadeTo] : undefined}
							formRef={control}
							formRules={{
								validate: {
									required: (value: Value) => (!!value && value.length > 0) || "Notification made to is required",
								},
							}}
						/>
						<ZenInput
							label="Action taken:"
							nameRef="actionTaken"
							inputError={errors.actionTaken}
							formRef={control}
							formRules={{ required: "Action taken is required" }}
						/>
					</>
				)
		}
		return
	}

	return (
		<>
			<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
				<div className={scrollDiv}>
					<ZenSelect
						label="Attendance Status"
						formName="attendanceStatus"
						formRef={control}
						options={Object.values(AttendanceStatus)
							.filter((s) => baseFundingSource.label !== "NDIA" || s !== "DNA")
							.map((s) => ({ id: s, label: snakeToTitle(s) }))}
						value={props.attendanceStatus ? [{ id: props.attendanceStatus, label: snakeToTitle(props.attendanceStatus) }] : []}
						actionOnChange={onAttendanceChange}
						inputError={errors.attendanceStatus}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length > 0) || "Attendance Status is required",
							},
						}}
						clearable={false}
					/>

					{displayForm()}

					{baseFundingSource.label !== "NDIA" && (
						<SessionFundingSourceForm
							control={control}
							errors={errors}
							placeholder={placeholder}
							watch={watch}
							setValue={setValue}
							setAvailableIndexes={setAvailableIndexes}
							setPlaceholder={setPlaceholder}
							isGroupSession
							sessionFundingSources={sessionFundingSources}
							onPostSession
						/>
					)}

					{attendanceStatus !== "" && attendanceStatus !== AttendanceStatus.Cancelled && baseFundingSource.label === "NDIA" && (
						<>
							<Divider style={{ backgroundColor: "transparent" }} />
							<Checkbox
								onChange={(e) => setTimeIncludeWithSession(e.currentTarget.checked)}
								checked={timeIncludedWithSession}
								labelPlacement="left"
								overrides={{
									Root: {
										style: {
											alignItems: "center",
										},
									},
								}}
							>
								<LabelSmall>Note time included in session</LabelSmall>
							</Checkbox>
							{!timeIncludedWithSession && (
								<>
									<div id={`dateForCompletingSessionAdmin`}>
										<ZenDatePicker
											label="Date for completing session admin"
											formName="dateForCompletingSessionAdmin"
											inputError={errors.dateForCompletingSessionAdmin}
											nullDefaultValue
											value={props?.dateForCompletingSessionAdmin ? moment(props.dateForCompletingSessionAdmin).toDate() : null}
											formRef={control}
											formRules={{
												validate: {
													required: (value: Date) => !!value || "Date of session admin is required",
												},
											}}
										/>
									</div>
									<ZenTimePicker
										label="Start time for session admin"
										nullable
										value={props?.startTimeForSessionAdmin ? moment(props?.startTimeForSessionAdmin).toDate() : null}
										formName="startTimeForSessionAdmin"
										date={watch("dateForCompletingSessionAdmin")}
										inputError={errors.startTimeForSessionAdmin}
										formRef={control}
										formRules={{
											validate: {
												required: (value: Date) => !!value || "start time for session admin is required",
											},
										}}
										showTodayButton={false}
									/>
									<ZenSelect
										label="Minutes required for admin tasks"
										formName="minutesRequiredForAdminTasks"
										formRef={control}
										options={durationOptions}
										value={
											props && props.minutesRequiredForAdminTasks
												? [{ id: props.minutesRequiredForAdminTasks, label: `${props.minutesRequiredForAdminTasks}` }]
												: []
										}
										inputError={errors.minutesRequiredForAdminTasks}
										formRules={{
											validate: {
												required: (value: Value) => (!!value && value.length > 0) || "minutes for admin tasks is required",
											},
										}}
									/>
									{!!adminTimeAvailability.state && <AvailableCheck isAvailable={adminTimeAvailability.state} />}
								</>
							)}

							<Divider style={{ height: "10px", backgroundColor: "transparent" }} />
							{props.clients &&
								props.clients.map((c) => (
									<SessionClientDetail
										{...c}
										key={c.id}
										sessionID={props.id}
										duration={Math.floor(duration)}
										allocatePlanLineSessionMinutes={allocatePlanLineSessionMinutes}
										selectedPlanLineList={selectedPlanLineSession}
										checkDurationAllocation={checkDurationAllocation}
									/>
								))}
						</>
					)}

					{!props.attendanceStatus &&
						props.clients &&
						props.clients.map((c) => (
							<SessionNoteQuestionForm
								label={`${c.firstName} ${c.lastName} Notes`}
								key={`client-note-${c.id}`}
								control={control}
								nameRefPrefix={`client-note-${c.id}-`}
							/>
						))}
				</div>
				{error && <ErrorNotification messageOrPayload={payload} />}
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				<CancelAndSaveButtons
					omitCancel={!props.attendanceStatus && !props.inTimesheet}
					buttonWidths="wrap"
					cancelFn={() => {
						props.setEditMode(false)
						if (props.setDisplayMode) props.setDisplayMode(true)
					}}
					isLoading={loading}
				/>
			</form>
			{cancelConfirmModal && (
				<ZenArchiveModal
					open={cancelConfirmModal}
					onClose={() => setCancelConfirmModal(false)}
					loading={loading}
					message={
						<span>
							<span>Selecting 'Cancelled' will result in </span>
							<span style={{ fontWeight: "bold", textDecoration: "underline" }}>{`${
								props.clients && props.clients[0] ? `${props.clients[0].firstName} ${props.clients[0].lastName} NOT ` : ""
							}`}</span>
							<span>being charged.</span>
						</span>
					}
					areYouSureMessage="Are you sure you want to continue?"
					hideHeader
					confirmButtonText="Confirm Cancellation"
					cancelButtonText="Back"
					confirmArchive={async () => {
						submit(getValues())
					}}
				/>
			)}
			{openTravelModal && <AddTravelModal {...props} open={openTravelModal} setOpen={setOpenTravelModal} />}
		</>
	)
}

interface AddTravelModalProps extends Session {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	refetchCurrentSession: () => Promise<QueryResponse<Session>>
}
const AddTravelModal = (props: AddTravelModalProps) => {
	const { open, setOpen, refetchCurrentSession, ...session } = props
	const [css] = useStyletron()
	const onTravelCreateComplete = async () => {
		const resp = await refetchCurrentSession()
		if (resp.error || !resp.payload) return
		setOpen(false)
	}
	const container = css({
		height: "80vh",
		minWidth: "500px",
		maxHeight: "80vh",
		overflowX: "hidden",
		overflowY: "auto",
	})
	return (
		<ZenModal isOpen={open} onClose={() => setOpen(false)} role="dialog" size="auto">
			<ModalHeader>
				<LabelLarge>Add Travel Log</LabelLarge>
			</ModalHeader>
			<ModalBody>
				<div className={container}>
					<TravelForm session={session} onCancel={() => setOpen(false)} onSuccess={onTravelCreateComplete} />
				</div>
			</ModalBody>
		</ZenModal>
	)
}
