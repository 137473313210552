import * as React from "react"
import { useStyletron } from "baseui"
import { LabelLarge } from "baseui/typography"
import { DatePicker } from "baseui/datepicker"
import { FormControl } from "baseui/form-control"
import { ZenDatepickerOverrides, ZenInputBottomOverrides, ZenFormControlOverrides, ZenCheckBoxOverrides } from "../../../themeOverrides"
import { RiskType } from "../../../types/enums"
import { Controller, Control, FieldError } from "react-hook-form"
import { Input } from "baseui/input"
import { Checkbox } from "baseui/checkbox"
import { HazardRiskIdentificationFormData } from "./hazardRiskIdentification"
import { DeepMap } from "react-hook-form/dist/types/utils"
import { FormValuePreview } from "../common"

interface HazardRiskStep1Props {
	control: Control<HazardRiskIdentificationFormData>
	errors: DeepMap<HazardRiskIdentificationFormData, FieldError>
	disabled?: boolean
	infoOnly?: boolean
}

const required = { required: { value: true, message: "Required" } }

const HazardRiskStep1 = (props: HazardRiskStep1Props) => {
	const { control, errors, disabled, infoOnly } = props

	const [css] = useStyletron()

	const riskTypeListStyle = css({
		display: "grid",
		gridTemplateColumns: "50% 50%",
		rowGap: "8px",
	})
	const titleStyle = css({
		fontWeight: "bold",
		marginBottom: "20px",
	})

	return (
		<div>
			<LabelLarge className={titleStyle}>Contact details of risk identifier</LabelLarge>

			<FormControl label="Name of team member reporting" overrides={ZenFormControlOverrides} error={errors.reporterName && errors.reporterName.message}>
				<Controller
					name={`reporterName`}
					control={control}
					as={infoOnly ? undefined : <Input disabled={disabled} overrides={ZenInputBottomOverrides} />}
					render={FormValuePreview(infoOnly)}
					disabled
					error={errors.reporterName !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl label="Position / Title" overrides={ZenFormControlOverrides} error={errors.reporterPosition && errors.reporterPosition.message}>
				<Controller
					name={`reporterPosition`}
					control={control}
					as={infoOnly ? undefined : <Input disabled={disabled} overrides={ZenInputBottomOverrides} />}
					render={FormValuePreview(infoOnly)}
					disabled
					error={errors.reporterPosition !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl label="Location" overrides={ZenFormControlOverrides} error={errors.location && errors.location.message}>
				<Controller
					name={`location`}
					control={control}
					as={infoOnly ? undefined : <Input disabled={disabled} overrides={ZenInputBottomOverrides} />}
					render={FormValuePreview(infoOnly)}
					disabled={disabled}
					error={errors.location !== undefined}
					rules={required}
				/>
			</FormControl>

			<FormControl label="Date risk was identified" overrides={ZenFormControlOverrides}>
				<Controller
					name={`dateRiskWasIdentified`}
					control={control}
					render={
						infoOnly
							? FormValuePreview(infoOnly)
							: ({ value, onChange }) => (
									<DatePicker
										onChange={({ date }) => onChange(date)}
										value={value ? new Date(value) : new Date()}
										disabled={disabled}
										overrides={ZenDatepickerOverrides}
										formatString={"dd/MM/yyyy"}
										placeholder={"DD/MM/YYYY"}
										minDate={new Date("1900-01-01")}
										maxDate={new Date()}
									/>
							  )
					}
				/>
			</FormControl>

			<FormControl
				label="Indicate Risk Type"
				caption={!disabled && "Select the type of risk you are reporting (can be more than one)"}
				overrides={ZenFormControlOverrides}
			>
				<div className={riskTypeListStyle}>
					{Object.values(RiskType).map((riskType, index) => (
						<Controller
							key={`checkbox-riskTypes[${index}]`}
							name={`riskTypes[${index}]`}
							control={control}
							render={({ onChange, onBlur, value, name }) =>
								disabled && !value ? (
									<></>
								) : (
									<Checkbox
										onBlur={onBlur}
										onChange={(e) => onChange(e.currentTarget.checked)}
										checked={value}
										name={name}
										overrides={ZenCheckBoxOverrides}
										disabled={disabled}
									>
										{riskType}
									</Checkbox>
								)
							}
						/>
					))}
				</div>
			</FormControl>
		</div>
	)
}

export default HazardRiskStep1
