import * as React from "react"
import { Modal, ModalProps } from "baseui/modal"
import { LoadingOverlay } from "components/loadingOverlay"

interface ZenModalProps extends ModalProps {
	isLoading?: boolean
}

export const ZenModal: React.FC<ZenModalProps> = ({ isLoading, children, overrides, ...props }: ZenModalProps) => {
	const newOverrides = {
		Root: {
			style: {
				zIndex: 0,
			},
		},
		...overrides,
	}

	return (
		<Modal overrides={newOverrides} {...props} unstable_ModalBackdropScroll={true}>
			{isLoading && <LoadingOverlay />}
			{children}
		</Modal>
	)
}
