import * as React from "react"
import { useStyletron } from "baseui"
import { Button, ButtonProps } from "baseui/button"
import { LabelSmall } from "baseui/typography"
import { StyleObject } from "styletron-react"

import { ZenTheme } from "../../themeOverrides"
import { Divider } from "../common"

export const ZenButtonOverrides: StyleObject = {
	borderTopLeftRadius: "3px",
	borderTopRightRadius: "3px",
	borderBottomLeftRadius: "3px",
	borderBottomRightRadius: "3px",

	borderTopStyle: "solid",
	borderBottomStyle: "solid",
	borderLeftStyle: "solid",
	borderRightStyle: "solid",

	borderTopColor: "white",
	borderBottomColor: "white",
	borderLeftColor: "white",
	borderRightColor: "white",

	borderTopWidth: "1.5px",
	borderBottomWidth: "1.5px",
	borderLeftWidth: "1.5px",
	borderRightWidth: "1.5px",

	boxShadow: "#00000029 0px 0px 6px",
	paddingTop: "8px",
	paddingBottom: "8px",
	paddingLeft: "12px",
	paddingRight: "12px",
	fontSize: "14px",
	fontWeight: 600,
	whiteSpace: "nowrap",
}

interface ExtendedButtonProps extends ButtonProps {
	width?: string
	height?: string
	display?: "inline" | "block"
	marginTop?: string
	marginBottom?: string
	marginLeft?: string
	marginRight?: string
	paddingTop?: string
	paddingBottom?: string
	paddingLeft?: string
	paddingRight?: string
	overrides?: never
	kind?: never
}

export type ButtonKind = "primary" | "secondary" | "danger" | "grey" | "tertiary" | "warning"

interface ZenButtonProps extends ExtendedButtonProps {
	altKind?: ButtonKind
}

export const ZenButton = (props: ZenButtonProps) => {
	const { children, width, height, marginTop, marginLeft, marginRight, paddingTop, marginBottom, paddingLeft, paddingRight, paddingBottom, altKind, ...rest } =
		props
	let backgroundColor = ZenTheme.colors.primaryGreen
	let hoverColor = ZenTheme.colors.buttonPrimaryHover
	let colour = ZenTheme.colors.white
	let boxShadow = "#00000029 0px 0px 6px"
	let textDecoration = "none"

	if (altKind) {
		switch (altKind) {
			case "secondary":
				backgroundColor = ZenTheme.colors.contentTertiary
				hoverColor = ZenTheme.colors.primaryGrey
				break
			case "danger":
				backgroundColor = ZenTheme.colors.red
				hoverColor = ZenTheme.colors.redAlt
				break
			case "warning":
				backgroundColor = ZenTheme.colors.warning500
				hoverColor = ZenTheme.colors.warning
				break
			case "grey":
				backgroundColor = ZenTheme.colors.primaryGrey
				hoverColor = ZenTheme.colors.primaryGrey
				colour = ZenTheme.colors.white

				break
			case "tertiary":
				backgroundColor = ZenTheme.colors.transparent
				hoverColor = ZenTheme.colors.lightGrey
				colour = ZenTheme.colors.black
				boxShadow = ""
				textDecoration = "underline"
				break
		}
	}

	const overrides = {
		...ZenButtonOverrides,
		backgroundColor: backgroundColor,
		color: colour,
		":hover": {
			backgroundColor: props.disabled ? undefined : hoverColor,
		},
		width,
		height,
		marginTop,
		marginLeft,
		marginRight,
		marginBottom,
		paddingTop: paddingTop || "8px",
		paddingLeft: paddingLeft || "8px",
		paddingRight: paddingRight || "8px",
		paddingBottom: paddingBottom || "8px",
		boxShadow,
		textDecoration,
	}

	return (
		<Button {...rest} overrides={{ BaseButton: { style: overrides } }}>
			{children}
		</Button>
	)
}

export const ZenToggleButton = (props: ExtendedButtonProps) => {
	const { children, width, marginTop, marginLeft, marginRight, ...rest } = props

	const overrides = {
		...ZenButtonOverrides,
		width,
		marginTop,
		marginLeft,
		marginRight,
	}

	return (
		<Button
			{...rest}
			overrides={{
				BaseButton: {
					style: ({ $theme, $isSelected }) => ({
						...overrides,
						color: $isSelected ? "white" : $theme.colors.contentPrimary,
						backgroundColor: $isSelected ? $theme.colors.primary : "white",
					}),
				},
			}}
		>
			{children}
		</Button>
	)
}

interface ZenButtonGroupProps {
	data: { id: string; label: string }[]
	currentKey: string
	actionOnSelect: (id: string, label: string) => void
	label?: string
}
export const ZenButtonGroup = (props: ZenButtonGroupProps) => {
	const { data, currentKey, actionOnSelect, label } = props
	const [css] = useStyletron()
	const buttonGroup = css({
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		paddingBottom: "2px",
		marginLeft: "2px",
		marginTop: "-5px",
	})
	return (
		<>
			{label && (
				<>
					<LabelSmall>{label}</LabelSmall>
					<Divider style={{ backgroundColor: "transparent" }} />
				</>
			)}
			<div className={buttonGroup}>
				{data.map((c, i) => (
					<Button
						key={i}
						overrides={{
							BaseButton: {
								style: {
									...ZenButtonOverrides,
									marginRight: "8px",
									marginTop: "5px",
									backgroundColor: c.id === currentKey ? ZenTheme.colors.primaryGreen : ZenTheme.colors.white,
									color: c.id === currentKey ? ZenTheme.colors.white : ZenTheme.colors.black,
								},
							},
						}}
						type="button"
						onClick={() => actionOnSelect(c.id, c.label)}
					>
						{c.label}
					</Button>
				))}
			</div>
		</>
	)
}
