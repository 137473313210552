import { createTheme, lightThemePrimitives } from "baseui"
import { StyleObject } from "styletron-react"
import { ButtonOverrides } from "baseui/button"
import { CheckboxOverrides } from "baseui/checkbox"
import { InputOverrides } from "baseui/input"
import { FormControlOverrides } from "baseui/form-control"
import { SelectOverrides } from "baseui/select"
import { DatepickerOverrides, SharedStylePropsT } from "baseui/datepicker"

// See https://github.com/uber-web/baseui/blob/master/src/themes/creator.js for full list of theme properties

export const ZenTheme = createTheme(
	{
		...lightThemePrimitives,
		primaryFontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
	},
	{
		colors: {
			primary: "#88BB3C",
			primaryGreen: "#88BB3C",
			primaryGrey: "#b2b2b2",
			transparent: "transparent",
			dark: "#222222",
			lightGrey: "#f8f9fd",
			white: "#fff",
			black: "#1A1A1A",
			iconColour: "#a0c865",
			divider: "#F8F9FD",
			red: "#F24E4E",
			redAlt: "#FF6363",
			negative: "#F24E4E",

			backgroundSecondary: "#f8f9fd",
			contentTertiary: "#747675",

			borderOpaque: "#D6DBF0",

			inputBorder: "transparent",
			inputFill: "transparent",
			inputPlaceholder: "#BEBEBE",
			inputPlaceholderDisabled: "transparent",

			buttonPrimaryFill: "#88BB3C",
			buttonPrimaryHover: "#a7e056",
			buttonDisabledFill: "#B5B5B5",
			buttonDisabledText: "#fff",

			tickFillSelected: "#88BB3C",
			tickFillSelectedHover: "#a7e056",
			tickFillDisabled: "#88BB3C",
		},
		sizing: {
			sideBarScale: "250px",
			sideBarScaleSm: "26%",
			scale1000: "36px",
		},
		typography: {
			HeadingLarge: {
				fontSize: "22px",
				fontWeight: 600,
			},
			LabelSmall: {
				lineHeight: "19px",
				fontWeight: 600,
			},
			LabelMedium: {
				fontWeight: 600,
			},
			LabelLarge: {
				fontWeight: 600,
			},
		},
	},
)

export const defaultBorderStyle = {
	borderRight: "1px",
	borderLeft: "1px",
	borderTop: "1px",
	borderBottom: "1px",
}

export const borderRadiusStyle = {
	borderTopLeftRadius: "3px",
	borderTopRightRadius: "3px",
	borderBottomLeftRadius: "3px",
	borderBottomRightRadius: "3px",
}

export const ZenToggleButtonOverrides = (color?: string, overrides?: StyleObject): ButtonOverrides => ({
	BaseButton: {
		style: ({ $theme, $isSelected }) => ({
			borderTopLeftRadius: "3px",
			borderTopRightRadius: "3px",
			borderBottomLeftRadius: "3px",
			borderBottomRightRadius: "3px",

			borderTopColor: color || $theme.colors.primary,
			borderBottomColor: color || $theme.colors.primary,
			borderLeftColor: color || $theme.colors.primary,
			borderRightColor: color || $theme.colors.primary,

			borderTopWidth: "1.5px",
			borderBottomWidth: "1.5px",
			borderLeftWidth: "1.5px",
			borderRightWidth: "1.5px",

			boxShadow: "#00000029 0px 0px 6px",
			paddingTop: "8px",
			paddingBottom: "8px",
			paddingLeft: "12px",
			paddingRight: "12px",
			fontSize: "14px",
			fontWeight: 600,
			whiteSpace: "nowrap",
			color: $isSelected ? "white" : $theme.colors.contentPrimary,

			backgroundColor: $isSelected ? color || $theme.colors.primary : "white",
			...overrides,
		}),
	},
})

export const ZenCheckBoxOverrides: CheckboxOverrides = {
	Root: {
		style: ({ $disabled }: any) => ({
			cursor: $disabled ? "default" : "pointer",
			position: "relative",
		}),
	},
	Checkmark: {
		style: ({ $theme, $checked }) => ({
			borderTopColor: $checked ? $theme.colors.tickFillSelected : "#D6DBF0",
			borderBottomColor: $checked ? $theme.colors.tickFillSelected : "#D6DBF0",
			borderLeftColor: $checked ? $theme.colors.tickFillSelected : "#D6DBF0",
			borderRightColor: $checked ? $theme.colors.tickFillSelected : "#D6DBF0",
			borderTopLeftWidth: "2px",
			borderTopRightWidth: "2px",
			borderBottomLeftWidth: "2px",
			borderBottomRightWidth: "2px",
			borderTopLeftRadius: "3px",
			borderTopRightRadius: "3px",
			borderBottomLeftRadius: "3px",
			borderBottomRightRadius: "3px",
		}),
	},
	Label: {
		style: {
			fontWeight: "normal",
			fontSize: "14px",
		},
	},
}
export const ZenCheckBoxCenteredOverrides: CheckboxOverrides = {
	...ZenCheckBoxOverrides,
	Root: {
		style: ({ $disabled }: any) => ({
			cursor: $disabled ? "default" : "pointer",
			width: "fit-content",
			justifySelf: "center",
			position: "relative",
		}),
	},
}

export const ZenInputOverrides: InputOverrides = {
	Root: {
		style: ({ $theme, $disabled, $error }: any) => ({
			borderTopColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderBottomColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderLeftColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderRightColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderTopStyle: $disabled ? "unset" : "solid",
			borderBottomStyle: $disabled ? "unset" : "solid",
			borderLeftStyle: $disabled ? "unset" : "solid",
			borderRightStyle: $disabled ? "unset" : "solid",
			backgroundColor: $disabled ? "unset" : "solid",
			...borderRadiusStyle,
		}),
	},
	Input: {
		style: {
			cursor: "text",
		},
	},
}
export const ZenInputResizableOverrides: InputOverrides = {
	InputContainer: {
		style: ({ $theme, $disabled, $error }: any) => ({
			borderTopColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderBottomColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderLeftColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderRightColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderTopStyle: $disabled ? "unset" : "solid",
			borderBottomStyle: $disabled ? "unset" : "solid",
			borderLeftStyle: $disabled ? "unset" : "solid",
			borderRightStyle: $disabled ? "unset" : "solid",
			backgroundColor: $disabled ? "unset" : "solid",
			...borderRadiusStyle,
		}),
	},
	Input: {
		style: ({ $disabled }: any) => ({
			cursor: "text",
			resize: $disabled ? "unset" : "vertical",
			height: $disabled ? "unset" : "180px",
			maxHeight: $disabled ? "unset" : "800px",
			minHeight: $disabled ? "unset" : "100px",
		}),
	},
}

export const ZenInputBottomOverrides: InputOverrides = {
	Root: {
		style: ({ $theme, $disabled, $error }: any) => ({
			borderBottomColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderBottomStyle: $disabled ? "unset" : "solid",
			borderTopStyle: "unset",
			borderLeftStyle: "unset",
			borderRightStyle: "unset",
			backgroundColor: "unset",
		}),
	},
	Input: {
		style: {
			cursor: "text",
			lineHeight: "26px",
		},
	},
}

export const ZenDatepickerOverrides: DatepickerOverrides<SharedStylePropsT> = {
	Input: {
		props: {
			overrides: {
				Input: {
					style: ({ $disabled }: any) => ({
						cursor: $disabled ? "not-allowed" : "text",
					}),
				},
				Root: {
					style: ({ $theme, $disabled, $error }: any) => ({
						borderBottomColor: $error ? $theme.colors.negative : $theme.colors.black,
						borderBottomStyle: $disabled ? "unset" : "solid",
						borderTopStyle: $disabled ? "unset" : "inherit",
						borderLeftStyle: $disabled ? "unset" : "inherit",
						borderRightStyle: $disabled ? "unset" : "inherit",
						backgroundColor: $disabled ? "unset" : "inherit",
					}),
				},
			},
		},
	},
}

export const ZenSelectOverrides: SelectOverrides = {
	ControlContainer: {
		style: ({ $theme, $disabled, $error }: any) => ({
			cursor: "text",
			borderBottomColor: $error ? $theme.colors.negative : $theme.colors.black,
			borderTopStyle: $disabled || $error ? "unset" : "solid",
			borderBottomStyle: $disabled ? "unset" : "solid",
			borderLeftStyle: $disabled || $error ? "unset" : "solid",
			borderRightStyle: $disabled || $error ? "unset" : "solid",
			backgroundColor: "unset",
		}),
	},
	IconsContainer: {
		style: ({ $disabled }: any) => ({
			display: $disabled ? "none" : "flex",
		}),
	},
	ValueContainer: {
		style: ({ $theme, $disabled }: any) => ({
			color: $disabled ? $theme.colors.contentSecondary : $theme.colors.contentPrimary,
		}),
	},
}

export const ZenFormControlOverrides: FormControlOverrides = {
	Label: {
		style: {
			fontWeight: 600,
			whiteSpace: "nowrap",
			marginTop: "20px",
		},
	},
}
export const ZenFormControlWrapOverrides: FormControlOverrides = {
	Label: {
		style: {
			fontWeight: 600,
			marginTop: "20px",
		},
	},
}

export const ZenErrorButtonOverrides: ButtonOverrides = {
	BaseButton: {
		style: {
			borderTop: "white solid 1.5px",
			borderBottom: "white solid 1.5px",
			borderLeft: "white solid 1.5px",
			borderRight: "white solid 1.5px",
			boxShadow: "#00000029 0px 0px 6px",
			paddingTop: "8px",
			paddingBottom: "8px",
			paddingLeft: "12px",
			paddingRight: "12px",
			fontSize: "14px",
			fontWeight: 600,
			whiteSpace: "nowrap",
			backgroundColor: ZenTheme.colors.red,
			":hover": {
				backgroundColor: ZenTheme.colors.redAlt,
			},
		},
	},
}

export const ZenLinkStyle = {
	color: ZenTheme.colors.buttonPrimaryFill,
	":hover": {
		color: ZenTheme.colors.buttonPrimaryHover,
	},
	cursor: "pointer",
}
